define('matrix-frontend/pods/components/mx-pagination/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        disabledPrevious: Ember.computed('displayPrevious', function () {
            return !this.get('displayPrevious');
        }),
        disabledNext: Ember.computed('displayNext', function () {
            return !this.get('displayNext');
        }),

        actions: {
            triggerPrevious: function triggerPrevious() {
                this.get('previous')();
            },
            triggerNext: function triggerNext() {
                this.get('next')();
            }
        }
    });
});