define('matrix-frontend/pods/new-checklist/route', ['exports', 'matrix-frontend/routes/base-route', 'matrix-frontend/actions/_types'], function (exports, _baseRoute, _types) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseRoute.default.extend({
        redux: Ember.inject.service(),
        queryParams: {
            hierarchyNodeId: {
                refreshModel: true
            },
            item: {
                refreshModel: true
            }
        },

        beforeModel: function beforeModel(transition) {
            // console.info(transition.queryParams)
            if (transition.queryParams.item != null && transition.queryParams.storeId != null) {
                this.transitionTo(this.routeName, {
                    queryParams: {
                        item: null
                    },
                    replace: true
                });
            }
        },
        model: function model(params, transition) {
            var self = this;
            return this._super(params, transition).then(function () {
                var item = transition.queryParams.item;
                if (item) {
                    item = parseInt(item);
                }

                self.redux.dispatch({
                    type: _types.TODO_SET_FOCUS_ITEM,
                    payload: {
                        focusItemId: item
                    }
                });
            });
        }
    });
});