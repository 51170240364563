define('matrix-frontend/models/contact-info', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Validations = (0, _emberCpValidations.buildValidations)({
        address1: (0, _emberCpValidations.validator)('presence', {
            presence: true,
            message: 'Address 1 is required'
        }),
        city: (0, _emberCpValidations.validator)('presence', {
            presence: true,
            message: 'City is required'
        }),
        country: (0, _emberCpValidations.validator)('presence', {
            presence: true,
            message: 'Country is required'
        }),
        state: (0, _emberCpValidations.validator)('presence', {
            presence: true,
            message: 'State is required'
        }),
        zip: (0, _emberCpValidations.validator)('presence', {
            presence: true,
            message: 'Postal code is required'
        })
    });

    exports.default = _emberData.default.Model.extend(Validations, {
        address1: _emberData.default.attr('string'),
        address2: _emberData.default.attr('string'),
        city: _emberData.default.attr('string'),
        country: _emberData.default.attr('string'),
        email: _emberData.default.attr('string'),
        fax: _emberData.default.attr('string'),
        mobile: _emberData.default.attr('string'),
        pager: _emberData.default.attr('string'),
        phone: _emberData.default.attr('string'),
        state: _emberData.default.attr('string'),
        zip: _emberData.default.attr('string')
    });
});