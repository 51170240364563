define('matrix-frontend/pods/components/schedule-page-section-chart/component', ['exports', 'ember-redux', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/date-helper', 'matrix-frontend/utils/interactions', 'matrix-frontend/utils/state', 'npm:moment', 'matrix-frontend/constants'], function (exports, _emberRedux, _types, _dateHelper, _interactions, _state, _npmMoment, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var stateToComputed = function stateToComputed(state) {
        return {
            date: state.cube.date,
            weekEndDate: state.cube.weekEndDate,
            clients: state.client.clients,
            node: state.cube.node,
            stores: state.node.stores,
            shifts: state.scheduleEdit.shifts,
            status: state.scheduleEdit.status,
            // atRiskData: state.scheduleEdit.atRiskData,
            closedAllDay: state.scheduleEdit.closedAllDay,
            preferences: state.preferences,
            smartAssign: state.scheduleEdit.smartAssign,
            employeeFlags: state.scheduleEdit.employeeFlags,
            // noSchedule: state.scheduleEdit.noSchedule,
            showEmployeeFilter: state.scheduleEdit.showEmployeeFilter,
            published: state.scheduleEdit.published,
            scheduleId: state.scheduleEdit.scheduleId
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {
            concentrateCondense: function concentrateCondense() {
                return dispatch({
                    type: _types.PREFERENCE_SET,
                    payload: { concentration: 0 }
                });
            },
            concentrateNormal: function concentrateNormal() {
                return dispatch({
                    type: _types.PREFERENCE_SET,
                    payload: { concentration: 1 }
                });
            },
            concentrateExpand: function concentrateExpand() {
                return dispatch({
                    type: _types.PREFERENCE_SET,
                    payload: { concentration: 2 }
                });
            },
            timePlus: function timePlus() {
                return dispatch({ type: _types.SE_TIME_PLUS });
            },
            timeMinus: function timeMinus() {
                return dispatch({ type: _types.SE_TIME_MINUS });
            },
            goToDay: function goToDay(dotw) {
                return dispatch({ type: _types.CUBE_GO_TO_DAY, payload: { dotw: dotw } });
            },
            disableSmartAssign: function disableSmartAssign() {
                return dispatch({ type: _types.SE_SET, payload: { smartAssign: 0 } });
            },
            disableFiltering: function disableFiltering() {
                return dispatch({
                    type: _types.SE_SET_EMPLOYEE_FILTER,
                    payload: {
                        type: 'skill',
                        value: 0
                    }
                });
            },
            refreshForecast: function refreshForecast() {
                this.set("refreshForecastClass", "animate-click");
                Ember.run.later(this, function () {
                    this.set("refreshForecastClass", "");
                }, 200);
                dispatch({
                    type: _types.NODE_REFRESH_FORECAST,
                    payload: { smartAssign: 0 }
                });
            },
            refreshTaskHours: function refreshTaskHours() {
                this.set("refreshTaskHoursClass", "animate-click");
                Ember.run.later(this, function () {
                    this.set("refreshTaskHoursClass", "");
                }, 200);
                dispatch({
                    type: _types.NODE_REFRESH_TASK_HOURS,
                    payload: { smartAssign: 0 }
                });
                dispatch({
                    type: _types.NODE_REFRESH_ACTUAL_LABOR,
                    payload: {}
                });
            },
            showOverallFlags: function showOverallFlags() {
                this.modalService.open('overall-flags');
            },
            markAsShared: function markAsShared() {
                var _this = this;

                this.set('inProcess', true);
                var shifts = this.unCommunicated.map(function (x) {
                    return x.meta.id;
                });
                (0, _interactions.send)({
                    header: 'do',
                    body: {
                        service: 'store',
                        namespace: ['shift', 'communicated'],
                        operation: 1,
                        include_channel: 2,
                        extra: [this.weekEndDate.format('YYYY-MM-DD')],
                        payload: { shifts: shifts, scheduleId: this.scheduleId }
                    }
                }, 'mark shifts as communicated', false, function () {
                    _this.set('inProcess', false);
                });
            }
        };
    };

    var Comp = Ember.Component.extend({
        modalService: Ember.inject.service(),
        stateService: Ember.inject.service(),

        refreshForecastClass: "",
        refreshTaskHoursClass: "",

        currentClient: Ember.computed('clients', 'node', function () {
            var node = this.get('node');
            var clients = this.get('clients');
            return node && clients ? clients[node.clientCode] : null;
        }),

        currentStore: Ember.computed('node', 'stores', function () {
            var node = this.get('node');
            var stores = this.get('redux').store.getState().node.stores;
            if (node && stores) {
                return stores[node.clientCode + '.' + node.tag];
            } else {
                return null;
            }
        }),

        hours: Ember.computed('currentStore.weeks', 'weekEndDate', 'dotw', function () {
            var currentStore = this.get('currentStore');
            var weekEndDate = this.get('weekEndDate');
            var dotw = this.get('dotw');
            if (dotw && currentStore && weekEndDate && currentStore.weeks && currentStore.weeks[weekEndDate.format('YYYY-MM-DD')]) {
                var hours = currentStore.weeks[weekEndDate.format('YYYY-MM-DD')].hours[dotw];
                return hours;
            }
            return null;
        }),

        unCommunicated: Ember.computed('shifts', 'published', function () {
            if (this.shifts && this.published) {
                return Object.values(this.shifts).filter(function (x) {
                    return !x.meta.communicated && x.meta.employeeId && x.meta.saved;
                });
            }
            return null;
        }),

        salesAtRiskData: Ember.computed('atRiskData', 'date', 'currentStore', function () {
            var atRiskData = this.get('atRiskData');
            var currentStore = this.get('currentStore');
            var weekEndDate = this.get('weekEndDate').format('YYYY-MM-DD');

            var output = {};

            if (atRiskData && currentStore) {
                var date = this.get('date');
                var values = atRiskData.values;

                if (date) {
                    var dotw = (0, _dateHelper.getDayOfClientWeek)(date) + 1;
                    output.daily = {
                        salesTarget: 0,
                        optimalLabor: 0,
                        underscheduledHours: 0,
                        underscheduledPercent: 0,
                        atRisk: 0
                    };
                    if (values[dotw]) {
                        Object.keys(values[dotw]).forEach(function (hours) {
                            Object.keys(values[dotw][hours]).forEach(function (key) {
                                output.daily[key] += values[dotw][hours][key];
                            });
                        });
                    }
                }

                var data = {
                    salesTarget: 0,
                    optimalLabor: 0,
                    underscheduledHours: 0,
                    underscheduledPercent: 0,
                    atRisk: 0
                };
                Object.keys(values).forEach(function (x) {
                    Object.keys(values[x]).forEach(function (hours) {
                        Object.keys(values[x][hours]).forEach(function (key) {
                            data[key] += values[x][hours][key];
                        });
                    });
                });
                output.weekly = data;
            }

            // console.log('sardata.daily', output.daily)
            return output;
        }),

        forecast1Label: Ember.computed('currentClient.options', 'status', function () {
            var status = this.status;
            var anyNotActive = status && status !== _constants.ScheduleStatus.UNKNOWN && status !== _constants.ScheduleStatus.ACTIVE;
            var label = (0, _state.getClientOption)('forecast1_label', 'label');
            if (anyNotActive) return 'Prelim ' + label;
            return label;
        }),

        useFlippedPWHours: Ember.computed('currentClient.options', function () {
            return (0, _state.getClientOption)('use_flipped_pw_hours', 'bool');
        }),

        // tasks: computed('client', function() {
        //     return this.get('client.tasks')
        // }),


        budget: Ember.computed('weekEndDate', 'currentStore', 'shifts', 'activitiesById', 'currentClient.tasks', 'currentClient.options',
        // 'activities',
        function () {
            // budget: computed('shifts', function () {
            var activitiesById = this.get('activitiesById');
            // const activities = this.get('activities')

            var shifts = this.get('shifts');
            var currentStore = this.get('currentStore');
            var weekEndDate = this.get('weekEndDate').format('YYYY-MM-DD');
            var tasks = this.get('currentClient.tasks');

            var USE_NEW_PW = (0, _state.getClientOption)('use_new_pw', 'bool');

            var todayDate = _npmMoment.default.utc().set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
            var isWeekInPast = todayDate.isAfter(this.get('weekEndDate'), 'day');

            var makeDailyObj = function makeDailyObj(defaultValue) {
                return [1, 2, 3, 4, 5, 6, 7].reduce(function (o, k) {
                    o[k] = defaultValue;
                    return o;
                }, {});
            };

            if (currentStore && activitiesById && weekEndDate && currentStore.weeks[weekEndDate] && currentStore.weeks[weekEndDate].optimalLabor && currentStore.weeks[weekEndDate].actualLabor && currentStore.weeks[weekEndDate].taskHours && tasks) {
                // console.group('budget')
                var actualLabor = currentStore.weeks[weekEndDate].actualLabor;
                var optimalLabor = currentStore.weeks[weekEndDate].optimalLabor;
                var taskHours = currentStore.weeks[weekEndDate].taskHours;
                var dailyOptimal = {};
                var weeklyOptimal = 0;
                var dailyActual = isWeekInPast && USE_NEW_PW ? makeDailyObj(0) : {};
                var weeklyActual = 0;
                var dailySchedule = {};
                var weeklySchedule = 0;
                var dailyScheduledActual = {};
                var weeklyScheduledActual = 0;
                var dailyOverUnder = {};
                var weeklyOverUnder = 0;

                // Compile optimal hours

                var _loop = function _loop(key) {
                    var day = optimalLabor[key];
                    var total = Object.keys(day).map(function (x) {
                        return day[x];
                    }).reduce(function (o, i) {
                        Object.keys(i).forEach(function (x) {
                            return o += i[x].value;
                        });
                        return o;
                    }, 0) / 4;

                    dailyOptimal[key] = total;
                    weeklyOptimal += total;
                };

                for (var key in optimalLabor) {
                    _loop(key);
                }

                // NOTE: AMH 2019-06-27
                // This method is deprecated in favor of calculating in the
                // endpoint using shift_total and segment_total.
                // The below calculation is the same as segment_total
                // Compile actual hours
                // for (let key in actualLabor) {
                //     const day = actualLabor[key]
                //     const total =
                //         Object.keys(day)
                //             .map(x => day[x])
                //             .reduce((o, i) => {
                //                 Object.keys(i).forEach(x => (o += i[x]))
                //                 return o
                //             }, 0) / 4

                //     dailyActual[key] = total
                //     weeklyActual += total
                // }
                for (var key in actualLabor) {
                    dailyActual[key] = actualLabor[key].shiftTotal;
                    weeklyActual += actualLabor[key].shiftTotal;
                }

                // Compile schedule hours
                if (shifts) {
                    var shiftsArr = Object.keys(shifts).map(function (x) {
                        return shifts[x];
                    });
                    shiftsArr.forEach(function (shift) {
                        var t = 0;
                        var dotw = shift.meta.dotw;
                        if (!dailySchedule[dotw]) {
                            dailySchedule[dotw] = 0;
                        }
                        shift.blocks.forEach(function (block) {
                            var activityId = block.activityId;

                            var activity = activitiesById[activityId];
                            if (activity && !activity.isCallin) {
                                t += block.end.diff(block.start, 'hours', true);
                            }
                        });
                        dailySchedule[dotw] += t;
                        weeklySchedule += t;
                    });
                }

                var ibcInitial = void 0;
                // Compile IBC initial
                if (!USE_NEW_PW) {
                    // TODO:
                    // - Could use some DRY cleaning
                    ibcInitial = Object.keys(taskHours).reduce(function (o, i) {
                        if (taskHours[i].storehours) {
                            return o;
                        }
                        var value = Object.values(taskHours[i]).reduce(function (x, y) {
                            x += y.isIbc === 'Y' && y.isIbcByWeek === 'N' ? y.prelimHours : 0;
                            return x;
                        }, 0);
                        o[i] = value;
                        return o;
                    }, {});
                    ibcInitial['wk'] = Object.keys(taskHours).reduce(function (o, i) {
                        if (taskHours[i].storehours) {
                            return o;
                        }
                        var value = Object.values(taskHours[i]).reduce(function (x, y) {
                            x += y.isIbc === 'Y' && y.isIbcByWeek === 'Y' ? y.prelimHours : 0;
                            return x;
                        }, 0);
                        return o + value;
                    }, 0);
                } else {
                    ibcInitial = {
                        wk: 0
                    };
                    Array(7).fill().forEach(function (_, docw) {
                        docw += 1;
                        ibcInitial[docw] = 0;
                        if (taskHours && taskHours[docw] && taskHours[docw].storehours) {
                            taskHours[docw].storehours.forEach(function (sh) {
                                var task = tasks[sh.task];
                                if (task && task.isIbc) {
                                    if (task.isIbcByWeek) {
                                        ibcInitial.wk += sh.hours;
                                    } else {
                                        ibcInitial[docw] += sh.hours;
                                    }
                                }
                            });
                        }
                    });
                }

                var isIbcByWeek = ibcInitial['wk'] > 0;
                var ibcRemaining = isIbcByWeek ? ibcInitial['wk'] : {};
                // console.info({ibcInitial})

                var calcTaskHours = {};
                var variance = {};
                var weeklyTaskHours = 0;
                // console.group('overunder')

                // console.log({ibcRemaining, isIbcByWeek, ibcInitial})
                // Compile Over/Under
                // for (let dotw in taskHours) {
                Array(7).fill().forEach(function (_, dotw) {
                    dotw += 1;
                    var dailyData = taskHours[dotw];
                    var useHours = 0;

                    calcTaskHours[dotw] = 0;
                    variance[dotw] = 0;
                    dailyScheduledActual[dotw] = 0;
                    dailyOverUnder[dotw] = 0;

                    if (!isIbcByWeek) {
                        ibcRemaining[dotw] = ibcInitial[dotw];
                    }
                    if (dailyData) {
                        if (!USE_NEW_PW) {

                            for (var taskId in dailyData) {
                                var taskData = dailyData[taskId];
                                if (taskData.isIbc === 'N') {
                                    useHours = taskData.finalHours !== null ? taskData.finalHours : taskData.scheduleHours !== null ? taskData.scheduleHours : taskData.prelimHours;
                                    if (useHours === null) {
                                        useHours = 0;
                                    }

                                    // if (dotw === 3) {
                                    // console.log('isCanIbc', taskData.isCanIbc, useHours)
                                    // }
                                    if (taskData.isCanIbc === 'Y' && useHours > 0) {
                                        // console.log(`\t Apply IBC. isIbcByWeek: ${isIbcByWeek}`)
                                        if (isIbcByWeek) {
                                            var diff = ibcRemaining - useHours;
                                            // console.log(`\t ${diff} = ${ibcRemaining} - ${useHours}`)
                                            if (diff > 0) {
                                                ibcRemaining -= useHours;
                                                useHours = 0;
                                            } else {
                                                ibcRemaining = 0;
                                                useHours = Math.abs(diff);
                                            }
                                        } else {
                                            var _diff = ibcRemaining[dotw] - useHours;
                                            if (_diff > 0) {
                                                ibcRemaining[dotw] -= useHours;
                                                useHours = 0;
                                            } else {
                                                ibcRemaining[dotw] = 0;
                                                useHours = Math.abs(_diff);
                                            }
                                        }
                                    }
                                    calcTaskHours[dotw] += useHours;
                                    weeklyTaskHours += useHours;
                                }
                            }
                        } else {
                            dailyData.storehours.forEach(function (sh) {
                                var task = tasks[sh.task];
                                if (!task.isIbc) {
                                    useHours = sh.hours;
                                    if (task.isCanIbc && sh.hours > 0) {
                                        var singleIbcRemaining = isIbcByWeek ? ibcRemaining : ibcRemaining[dotw];
                                        var _diff2 = singleIbcRemaining - useHours;
                                        if (_diff2 > 0) {
                                            singleIbcRemaining -= useHours;
                                            useHours = 0;
                                        } else {
                                            singleIbcRemaining = 0;
                                            useHours = -_diff2;
                                        }
                                        if (isIbcByWeek) {
                                            ibcRemaining = singleIbcRemaining;
                                        } else {
                                            ibcRemaining[dotw] = singleIbcRemaining;
                                        }
                                    }
                                    calcTaskHours[dotw] += useHours;
                                    weeklyTaskHours += useHours;
                                }
                            });
                        }
                    }
                    var scheduledActual = USE_NEW_PW && dailyActual[dotw] != null || !USE_NEW_PW && dailyActual[dotw] ? dailyActual[dotw] : dailySchedule[dotw];
                    dailyScheduledActual[dotw] += scheduledActual;
                    weeklyScheduledActual += scheduledActual;

                    // console.log(dotw, !!dailyActual[dotw], dailyActual[dotw], dailySchedule[dotw])

                    dailyOverUnder[dotw] = (dailyScheduledActual[dotw] || 0) - calcTaskHours[dotw];

                    var using = dailyActual[dotw] ? 'actual' : 'schedule';
                    // if (dotw == 2) {
                    // console.log(
                    // `${dotw}: ${dailyScheduledActual[dotw]} - ${
                    // calcTaskHours[dotw]
                    // } = ${dailyOverUnder[dotw]} (using ${using})`,
                    // )
                    // }
                    weeklyOverUnder += dailyOverUnder[dotw];

                    // TODO:
                    // - Multiply by -1 for LaneBryant
                });
                // console.groupEnd('overunder')
                // console.log('dailyScheduledActual', dailyScheduledActual)
                // console.log('calcTaskHours', calcTaskHours)
                // // console.log('weeklyTaskHours', weeklyTaskHours)
                // console.log('dailyOverUnder', dailyOverUnder)
                // console.log('weeklyOptimal', weeklyOptimal)
                // // console.log('weeklyActual', weeklyActual)
                // // console.log('dailyActual', dailyActual)
                // console.log('weeklyOverUnder', weeklyOverUnder)
                // console.log('dailyOverUnder', dailyOverUnder)

                var budget = {
                    optimal: {
                        weekly: weeklyOptimal,
                        daily: dailyOptimal
                    },
                    actual: {
                        weekly: weeklyActual,
                        daily: dailyActual
                    },
                    schedule: {
                        weekly: weeklySchedule,
                        daily: dailySchedule
                    },
                    overUnder: {
                        weekly: weeklyOverUnder,
                        daily: dailyOverUnder
                    },
                    ibcInitial: {
                        daily: ibcInitial,
                        weekly: Object.keys(ibcInitial).reduce(function (o, i) {
                            return o + ibcInitial[i];
                        }, 0)
                    },
                    ibcRemaining: {
                        daily: isIbcByWeek ? null : ibcRemaining,
                        weekly: isIbcByWeek ? ibcRemaining : Object.keys(ibcRemaining).reduce(function (o, i) {
                            return o + ibcRemaining[i];
                        }, 0)
                    }
                    // console.info({budget, calcTaskHours})
                    // console.dir('actual', budget.actual)
                    // console.dir('schedule', budget.schedule)
                    // console.groupEnd('budget')
                };return budget;
            }
        }),

        dotw: Ember.computed('date', function () {
            var date = this.get('date');
            if (!date) {
                return null;
            }
            return (0, _dateHelper.getDayOfClientWeek)(date) + 1;
        }),

        dailyBudget: Ember.computed('budget.overUnder.daily', 'dotw', function () {
            var daily = this.get('budget.overUnder.daily');
            var dotw = this.get('dotw');
            return daily[dotw];
        }),

        // flags: computed('shifts', 'employeeFlags', function() {
        //     const shifts = this.get('shifts')
        //     const employeeFlags = this.get('employeeFlags')
        //     let flags = {
        //         violations: 0,
        //         warnings: 0,
        //         alerts: 0,
        //     }

        //     for (let i in shifts) {
        //         const shift = shifts[i]
        //         if (shift.errors) {
        //             const numAlerts = shift.errors.any(x => x.severity === -1) ? 1 : 0
        //             const numWarnings = shift.errors.any(x => x.severity === 0) ? 1 : 0
        //             const numViolations = shift.errors.any(x => x.severity === 1) ? 1 : 0
        //             flags.alerts += numAlerts
        //             flags.warnings += numWarnings
        //             flags.violations += numViolations
        //             //     shift.errors.forEach(error => {
        //             //         switch (error.severity) {
        //             //             case -1:
        //             //                 flags.alerts += 1;
        //             //             break;
        //             //             case 0:
        //             //                 flags.warnings += 1;
        //             //             break;
        //             //             case 1:
        //             //                 flags.violations += 1;
        //             //             break;
        //             //         }
        //             //     });
        //         }
        //     }

        //     Object.keys(employeeFlags).forEach(y => {
        //         const numAlerts = employeeFlags[y].any(x => x.severity === -1) ? 1 : 0
        //         const numWarnings = employeeFlags[y].any(x => x.severity === 0) ? 1 : 0
        //         const numViolations = employeeFlags[y].any(x => x.severity === 1) ? 1 : 0
        //         flags.alerts += numAlerts
        //         flags.warnings += numWarnings
        //         flags.violations += numViolations
        //     })

        //     return flags
        // }),

        forecast: Ember.computed('currentStore', 'weekEndDate', function () {
            var weekEndDate = this.get('weekEndDate');
            var currentStore = this.get('currentStore');

            if (weekEndDate) {
                weekEndDate = (0, _npmMoment.default)(weekEndDate).format('YYYY-MM-DD');
            }

            if (currentStore && currentStore.weeks && currentStore.weeks[weekEndDate] && currentStore.weeks[weekEndDate].salesForecast) {
                var forecast = currentStore.weeks[weekEndDate].salesForecast;
                return forecast.reduce(function (o, i) {
                    return o + i.total;
                }, 0);
            }
            return null;
        }),

        dailyForecast: Ember.computed('date', 'currentStore', 'weekEndDate', function () {
            var weekEndDate = this.get('weekEndDate');
            var date = this.get('date');
            var currentStore = this.get('currentStore');

            if (weekEndDate) {
                weekEndDate = (0, _npmMoment.default)(weekEndDate).format('YYYY-MM-DD');
            }

            if (currentStore && currentStore.weeks && currentStore.weeks[weekEndDate] && currentStore.weeks[weekEndDate].salesForecast) {
                var forecast = currentStore.weeks[weekEndDate].salesForecast;
                var daily = forecast.find(function (x) {
                    return (0, _npmMoment.default)(x.date).isSame(date, 'day');
                });
                if (daily) {
                    return daily.total;
                }
                // return forecast.reduce((o, i) => o + i.total, 0);
            }
            return null;
        }),

        hoursOfOperation: Ember.computed('date', 'weekEndDate', 'currentStore.weeks', function () {
            var weekEndDate = this.get('weekEndDate').format('YYYY-MM-DD');
            var currentStore = this.get('currentStore');
            var date = this.get('date');
            var dotw = (0, _npmMoment.default)(this.get('date')).weekday() + 1;

            if (!currentStore || !currentStore.weeks[weekEndDate] || !currentStore.weeks[weekEndDate].hours || !date) {
                return null;
            }

            var hours = currentStore.weeks[weekEndDate].hours[dotw];

            var privateOpen = (0, _dateHelper.offsetTime)(date, hours.privateOpen, hours.privateOpenOffset);
            var publicOpen = (0, _dateHelper.offsetTime)(date, hours.publicOpen, hours.publicOpenOffset);
            var publicClose = (0, _dateHelper.offsetTime)(date, hours.publicClose, hours.publicCloseOffset);
            var privateClose = (0, _dateHelper.offsetTime)(date, hours.privateClose, hours.privateCloseOffset);

            return {
                privateOpen: privateOpen,
                publicOpen: publicOpen,
                publicClose: publicClose,
                privateClose: privateClose
            };
        }),

        actions: {
            displayFlags: function displayFlags() {
                var modalService = this.get('modalService');
                modalService.open('all-flags');
            },
            goToReport: function goToReport(report) {
                var USE_NEW_PW = (0, _state.getClientOption)('use_new_pw', 'bool');
                var HAS_OLD_REPORTS = (0, _state.hasScope)('reports:old.view');
                var HAS_NEW_REPORTS = (0, _state.hasScope)('reports:new.view');

                var stateService = this.get('stateService');
                var currentStore = this.get('currentStore');
                var date = this.get('weekEndDate').format('YYYY-MM-DD');

                var getSubPath = function getSubPath() {
                    var defaultQuery = 'date=' + date + '&storeId=' + currentStore.id;
                    if (report === 'payroll-worksheet') {
                        return (USE_NEW_PW ? 'new-payroll-worksheet?' : 'reports/tps?') + defaultQuery;
                    } else if (report === 'sales-vs-forecast' && HAS_NEW_REPORTS) {
                        return 'new-reports?' + defaultQuery + '&report=svf&view=detail';
                    } else if (report === 'hours-vs-optimal' && HAS_NEW_REPORTS) {
                        return 'new-reports?' + defaultQuery + '&report=sar&view=detail';
                    } else if (HAS_OLD_REPORTS) {
                        return 'reports/' + report + '?' + defaultQuery;
                    }
                    return null;
                };

                var subPath = getSubPath();
                if (subPath != null) {
                    var path = '/clients/' + stateService.client.id + '/' + subPath;
                    this.get('router').transitionTo(path);
                }
            }
        }
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});