define('matrix-frontend/pods/components/hoo-modal/component', ['exports', 'ember-redux', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/date-helper', 'matrix-frontend/utils/holidays', 'npm:jquery', 'npm:moment'], function (exports, _emberRedux, _types, _dateHelper, _holidays, _npmJquery, _npmMoment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    // Matching groups:
    // 1 - hours
    // 2 - minutes
    // 3 - am/pm

    var TIMES = ['publicOpen', 'publicClose', 'privateOpen', 'privateClose'];
    var DEFAULT_HOURS = [['publicOpen', '9a'], ['publicClose', '9p']];

    var timeInputToMoment = function timeInputToMoment(date, input) {
        var asClose = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        var setDate = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

        var timeRegex = /^(?:(0[0-9]|1[0-9]|2[0-3]|[0-9])(?:[:\.]([0-5][0-9]))?\s*(?:([ap])m?)?)$/gi;
        var parsed = timeRegex.exec(input);
        var dotw = (0, _dateHelper.getDayOfClientWeek)(date) + 1;

        if (!parsed || !input) {
            return null;
        }

        var hours = parsed[3] && (parsed[3] === 'p' && parseInt(parsed[1]) < 12 || parsed[3] === 'a' && parseInt(parsed[1]) === 12) ? parseInt(parsed[1]) + 12 : parseInt(parsed[1]);
        var minutes = parseInt(parsed[2]) || 0;

        minutes = Math.ceil(minutes / 15) * 15;

        if (asClose && hours >= 4 && hours <= 12 && parsed[3] !== 'a') {
            hours += 12;
        }

        var mmt = date.clone().set({ hours: hours, minutes: minutes });

        // if (dotw === 1 && mmt.hours() < 3 && !asClose) {
        //     console.error("Earlier than 3am on Sunday")
        //     const service = new NotificationsService()
        //     service.warning("Store cannot open earlier than 3am on Sunday")
        //     mmt.set({hours: 3})
        // }
        if (setDate) {
            if (asClose && hours <= 6) {
                mmt.add(1, 'day');
            } else if (!asClose && hours >= 18) {
                if (dotw !== 1) {
                    mmt.subtract(1, 'day');
                }
            }
        }
        return mmt;
    };

    var stateToComputed = function stateToComputed(state) {
        return {
            cubeDate: state.cube.date,
            currentUser: state.user.currentUser
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {
            save: function save() {
                this.forceValidate(false);

                if (this.checkAll()) {
                    this.modalService.changeDone('hoo-modal');
                    dispatch({
                        type: _types.NODE_SAVE_HOURS_OF_OPERATION,
                        payload: {
                            publicOpen: this.isOpen ? this.publicOpen : null,
                            publicClose: this.isOpen ? this.publicClose : null,
                            privateOpen: this.isOpen ? this.privateOpen : null,
                            privateClose: this.isOpen ? this.privateClose : null,
                            isOpen: this.isOpen,
                            dotw: this.dotw,
                            isDefault: (0, _npmJquery.default)('#hoo-repeat').is(':checked'),
                            effectiveDate: this.date,
                            weekEndDate: (0, _dateHelper.getClientWeekEndDate)(this.date),
                            currentStore: this.currentStore
                        }
                    });
                }
                this.modalService.close('hoo-modal');
                return true;
            },
            toggleOpenStatus: function toggleOpenStatus() {
                var _this = this;

                this.set('isOpen', !this.isOpen);
                this.modalService.changeMade('hoo-modal');

                if (this.isOpen) {
                    DEFAULT_HOURS.forEach(function (_ref) {
                        var _ref2 = _slicedToArray(_ref, 2),
                            id = _ref2[0],
                            time = _ref2[1];

                        _this.$('#' + id).find('.mx-modal-input').val(time);
                    });
                    this.$().find('.mx-modal-input').trigger('change').trigger('blur');
                }
                this.forceValidate();
            }
        };
    };

    var Comp = Ember.Component.extend({
        publicOpen: null,
        publicClose: null,
        privateOpen: null,
        privateClose: null,
        isOpen: null,
        setDate: true,
        allowChange: true,
        isDefault: false,
        focus: null,

        modalService: Ember.inject.service(),

        date: Ember.computed('hoo', 'cubeDate', function () {
            if (this.hoo && this.hoo.date) {
                return (0, _npmMoment.default)(this.hoo.date);
            }
            return this.cubeDate;
        }),

        hasChange: Ember.computed('modalService.modals', function () {
            return this.modalService.hasChange('hoo-modal');
        }),

        isValid: Ember.computed('modalService.modals', function () {
            return this.modalService.isValid('hoo-modal');
        }),

        dotw: Ember.computed('date', function () {
            if (!this.date) {
                return null;
            }
            return (0, _dateHelper.getDayOfClientWeek)(this.date) + 1;
        }),

        holidays: Ember.computed('date', function () {
            if (this.get('date')) {
                return (0, _holidays.getHolidays)(this.get('date'));
            }
            return [];
        }),

        prevDay: Ember.computed('date', function () {
            var mmt = this.date ? this.date : (0, _npmMoment.default)();
            return mmt.clone().subtract(1, 'day');
        }),

        nextDay: Ember.computed('date', function () {
            var mmt = this.date ? this.date : (0, _npmMoment.default)();
            return mmt.clone().add(1, 'day');
        }),

        saveText: Ember.computed('isDefault', 'date', function () {
            if (!this.date) {
                return 'Save';
            }
            return this.isDefault ? 'Save for EVERY ' + this.date.format('dddd') + ', starting ' + this.date.format('M/D') : 'Save for ' + this.date.format('dddd, M/D');
        }),

        publicSpan: Ember.computed('publicOpen', 'publicClose', function () {
            if (!this.publicOpen || !this.publicClose) {
                return 0;
            }
            return this.publicClose.diff(this.publicOpen, 'minutes') / 60;
        }),

        privateSpan: Ember.computed('privateOpen', 'privateClose', function () {
            if (!this.privateOpen || !this.privateClose) {
                return 0;
            }
            return this.privateClose.diff(this.privateOpen, 'minutes') / 60;
        }),

        publicOpenSameOrAfterThreshhold: Ember.computed('publicOpen', 'dotw', function () {
            if (!this.publicOpen || this.dotw === 1) {
                return false;
            }
            return this.publicOpen.hours() >= 18;
        }),

        publicCloseSameOrBeforeThreshhold: Ember.computed('publicClose', function () {
            if (!this.publicClose) {
                return false;
            }
            return this.publicClose.hours() <= 6;
        }),

        privateOpenSameOrAfterThreshhold: Ember.computed('privateOpen', 'dotw', function () {
            if (!this.privateOpen || this.dotw === 1) {
                return false;
            }
            return this.privateOpen.hours() >= 18;
        }),

        privateCloseSameOrBeforeThreshhold: Ember.computed('privateClose', function () {
            if (!this.privateClose) {
                return false;
            }
            return this.privateClose.hours() <= 6;
        }),

        publicOpenClass: Ember.computed('publicOpen', function () {
            var hours = this.publicOpen ? this.publicOpen.hours() : null;
            var minutes = this.publicOpen ? this.publicOpen.minutes() : null;
            if (minutes !== 0) {
                return '';
            }
            return hours === 12 ? 'noon' : hours === 0 || hours === 24 ? 'midnight' : '';
        }),

        publicCloseClass: Ember.computed('publicClose', function () {
            var hours = this.publicClose ? this.publicClose.hours() : null;
            var minutes = this.publicClose ? this.publicClose.minutes() : null;
            if (minutes !== 0) {
                return '';
            }
            return hours === 12 ? 'noon' : hours === 0 || hours === 24 ? 'midnight' : '';
        }),

        privateOpenClass: Ember.computed('privateOpen', function () {
            var hours = this.privateOpen ? this.privateOpen.hours() : null;
            var minutes = this.privateOpen ? this.privateOpen.minutes() : null;
            if (minutes !== 0) {
                return '';
            }
            return hours === 12 ? 'noon' : hours === 0 || hours === 24 ? 'midnight' : '';
        }),

        privateCloseClass: Ember.computed('privateClose', function () {
            var hours = this.privateClose ? this.privateClose.hours() : null;
            var minutes = this.privateClose ? this.privateClose.minutes() : null;
            if (minutes !== 0) {
                return '';
            }
            return hours === 12 ? 'noon' : hours === 0 || hours === 24 ? 'midnight' : '';
        }),

        publicOpenDisplay: Ember.computed('publicOpen', function () {
            return this.publicOpen && this.publicOpen.format('LT').replace(':00', '');
        }),

        publicCloseDisplay: Ember.computed('publicClose', function () {
            return this.publicClose && this.publicClose.format('LT').replace(':00', '');
        }),

        privateOpenDisplay: Ember.computed('privateOpen', function () {
            return this.privateOpen && this.privateOpen.format('LT').replace(':00', '');
        }),

        privateCloseDisplay: Ember.computed('privateClose', function () {
            return this.privateClose && this.privateClose.format('LT').replace(':00', '');
        }),

        forceValidate: function forceValidate() {
            var allowChange = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
            var omit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

            this.set('allowChange', false);

            this.$().find('.mx-modal-input').not('#mx-modal-input-' + omit).trigger('change');
            this.forceCheck();

            this.set('allowChange', true);
        },
        forceCheck: function forceCheck() {
            this.modalService.setValid('hoo-modal', this.checkAll());
        },
        checkAll: function checkAll() {
            var allValid = TIMES.map(function (x) {
                return (0, _npmJquery.default)('#' + x).find('input').hasClass('invalid');
            });
            return allValid.every(function (x) {
                return !x;
            });
        },


        hooTypeText: Ember.computed('hoo', function () {
            if (!this.hoo) return null;
            if (this.hoo.newDefault) return 'New Default Hours';
            if (this.hoo.isException) return 'Exception Hours';
            return 'Default Hours';
        }),

        updatedByName: Ember.computed('hoo', 'currentUser', function () {
            if (!this.hoo) return null;
            var _hoo$updatedBy = this.hoo.updatedBy,
                user = _hoo$updatedBy.user,
                firstName = _hoo$updatedBy.firstName,
                lastName = _hoo$updatedBy.lastName;

            if (user != null && firstName != null && lastName != null) {
                return lastName + ', ' + firstName;
            } else if (user == this.currentUser.id) {
                return this.currentUser.username;
            }
            return null;
        }),

        actions: {
            beforeOpen: function beforeOpen() {
                this.set('publicOpen', null);
                this.set('publicClose', null);
                this.set('privateOpen', null);
                this.set('privateClose', null);
                this.set('isOpen', null);
                this.set('setDate', true);
                this.set('allowChange', true);
                this.set('isDefault', false);
                this.set('focus', null);
            },

            afterOpen: function afterOpen() {
                var _this2 = this;

                this.set('isOpen', this.hoo.closedAllDay !== 'Y');
                TIMES.forEach(function (x) {
                    if (_this2.get('hoo.' + x)) {
                        _this2.set(x, _this2.get('hoo.' + x).clone());
                        (0, _npmJquery.default)('#' + x).find('input').val(_this2.get(x).format('LT').replace(':00', ''));
                    }
                });
            },

            validateStart: function validateStart(input) {
                var isPrivateTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
                var validateOnly = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

                //             console.log({input,
                // tethered,
                // isPrivateTime,
                // validateOnly,})
                // TODO:
                // - Do not allow an open time that is earlier than previous
                //   day's last employee leave time
                // - On Sunday, that should just be 3am
                var key = isPrivateTime ? 'privateOpen' : 'publicOpen';

                if (!input) {
                    input = this.get(key).format('HH:mma');
                }

                var setDate = this.setDate; // && this.focus && this.focus === key
                var openMoment = timeInputToMoment(this.date, input, false, setDate);

                if (openMoment) {
                    if (this.allowChange && !validateOnly) {
                        this.set(key, openMoment);
                    }

                    if (!isPrivateTime) {
                        if (this.allowChange && !validateOnly) {
                            this.set('privateOpen', openMoment.clone().subtract(30, 'minutes'));
                            (0, _npmJquery.default)('#privateOpen').find('input').val(this.privateOpen.format('LT').replace(':00', ''));
                        }
                        return this.hoo.prevDayPublicClose === null || this.hoo.prevDayPublicClose.isSameOrBefore(this.publicOpen);
                    } else {
                        return this.privateOpen.isSameOrBefore(this.publicOpen);
                    }
                } else {
                    return false;
                }
            },

            validateEnd: function validateEnd(input) {
                var isPrivateTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
                var validateOnly = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

                var key = isPrivateTime ? 'privateClose' : 'publicClose';

                if (!input) {
                    input = this.get(key).format('HH:mma');
                }

                var setDate = this.setDate; // && this.focus && this.focus === key
                var closeMoment = timeInputToMoment(this.date, input, true, setDate);
                var openKey = isPrivateTime ? 'privateOpen' : 'publicOpen';

                if (closeMoment) {
                    if (this.allowChange && !validateOnly) {
                        this.set(key, closeMoment);
                    }

                    if (!isPrivateTime) {
                        if (this.allowChange && !validateOnly) {
                            this.set('privateClose', closeMoment.clone().add(30, 'minutes'));
                            (0, _npmJquery.default)('#privateClose').find('input').val(this.privateClose.format('LT').replace(':00', ''));
                        }
                    }
                }

                if (!closeMoment || !this.get(openKey)) {
                    return false;
                }

                var checkedPublicPrivate = isPrivateTime ? this.privateClose.isSameOrAfter(this.publicClose) : true;

                var checkednextDayPublicOpen = isPrivateTime ? true : this.hoo.nextDayPublicOpen === null || this.hoo.nextDayPublicOpen.isSameOrAfter(this.publicClose);

                return this.get(key).isAfter(this.get(openKey)) && checkedPublicPrivate && checkednextDayPublicOpen;
            },

            focusIn: function focusIn(id) {
                this.set('focus', id);
            },

            focusOut: function focusOut(id) {
                var isValid = !(0, _npmJquery.default)('#' + id).find('input').hasClass('invalid');
                if (isValid) {
                    var time = this.get(id);
                    (0, _npmJquery.default)('#' + id).find('input').val(time.format('LT').replace(':00', ''));
                    this.set('focus', null);
                }

                // switch(id) {
                //     case 'publicOpen':
                //         this.set(
                //             'privateOpen',
                //             this.publicOpen.clone().subtract(30, 'minutes'),
                //         )
                //         break
                //     case 'publicClose':
                //         this.set(
                //             'privateClose',
                //             this.publicClose.clone().subtract(30, 'minutes'),
                //         )
                //         break
                // }

                this.forceValidate(false);

                // if (this.dotw === 1 && openMoment.hours() < 3) {
                //     console.error("Earlier than 3am on Sunday")
                //     // const service = new NotificationsService()
                //     // service.warning("Store cannot open earlier than 3am on Sunday")
                //     return false
                // }
            },

            toggleStatus: function toggleStatus() {
                this.modalService.changeMade('hoo-modal');
            },

            toggleOffset: function toggleOffset(time) {
                var _this3 = this;

                var mmt = this.get(time);
                var privateKey = void 0;

                if (time.includes('Open')) {
                    privateKey = 'privateOpen';
                    if (mmt.isBefore(this.date, 'day')) {
                        mmt.add(1, 'day');
                    } else {
                        mmt.subtract(1, 'day');
                    }
                } else {
                    privateKey = 'privateClose';
                    if (mmt.isAfter(this.date, 'day')) {
                        mmt.subtract(1, 'day');
                        if (time.includes('public')) {
                            this.privateClose.subtract(1, 'day');
                        }
                    } else {
                        mmt.add(1, 'day');
                        if (time.includes('public')) {
                            this.privateClose.add(1, 'day');
                        }
                    }
                }

                this.set(time, mmt);
                this.notifyPropertyChange(time);

                if (time.includes('public')) {
                    var privateMmt = this.get(privateKey);
                    privateMmt.set({
                        day: mmt.day()
                    });
                    this.set(privateKey, privateMmt);
                    this.notifyPropertyChange(privateKey);
                }

                setTimeout(function () {
                    _this3.forceValidate(false);
                }, 200);
                // // this.send('validate')
                // this.$().trigger('validate')
            }
        }
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});