define('matrix-frontend/pods/components/schedule-store-legacy/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        saveService: Ember.inject.service(),
        legacyService: Ember.inject.service(),
        hideMenu: false,
        dotw: 'w',

        // hideMenu: computed('legacyService.valueChanged', function () {
        //     return !!this.get('legacyService').get('valueChanged');
        // }),

        isScheduleEdit: Ember.computed('legacyService.legacyView', function () {
            return this.get('legacyService').get('legacyView') === 'editSchedule';
        }),
        isSchedulePublish: Ember.computed('legacyService.legacyView', function () {
            return this.get('legacyService').get('legacyView') === 'generate';
        }),
        isSchedulePrint: Ember.computed('legacyService.legacyView', function () {
            return this.get('legacyService').get('legacyView') === 'printSchedule';
        }),
        isScheduleEmail: Ember.computed('legacyService.legacyView', function () {
            return this.get('legacyService').get('legacyView') === 'email';
        }),

        legacyFrameURL: Ember.computed('legacyService.legacyView', function () {
            var page = this.get('legacyService').get('legacyView');
            return '/workforce/application/legacy/schedule/' + page + '.jsf';
        }),

        showMenu: Ember.computed('hideMenu', function () {
            return !this.get('hideMenu');
        }),

        goTo: function goTo(page, hideMenu) {
            this.get('legacyService').set('legacyView', page);
            this.set('hideMenu', hideMenu);
        },

        watchHideMenu: Ember.computed('legacyService.hideMenu', function () {
            var shouldHide = this.get('legacyService').get('hideMenu');
            this.set('hideMenu', shouldHide); // eslint-disable-line ember/no-side-effects
            return shouldHide;
        }),

        didInsertElement: function didInsertElement() {
            var shouldHide = this.get('legacyService').get('hideMenu');
            if (shouldHide) {
                this.set('hideMenu', shouldHide);
            }
        },

        actions: {
            // goToEdit: function () {
            //     this.goTo('editSchedule');
            // },
            goToPublish: function goToPublish() {
                var self = this;
                this.get('saveService').doAction(self.goTo.bind(self), 'generate', true);
                // this.goTo('generate', true);
            },
            goToPrint: function goToPrint() {
                var self = this;
                this.get('saveService').doAction(self.goTo.bind(self), 'printSchedule', true);
                // this.goTo('printSchedule', true);
            },
            goToEmail: function goToEmail() {
                var self = this;
                this.get('saveService').doAction(self.goTo.bind(self), 'email', true);
                // this.goTo('email', true);
            },

            goToDay: function goToDay(day) {
                this.set('dotw', day);
                this.get('legacyService').post({
                    action: 'triggerClick',
                    id: 'day' + day
                });
            },

            goToWeek: function goToWeek() {
                this.set('dotw', 'w');
                this.get('legacyService').post({
                    action: 'toggleWeekView'
                });
            }

            // someAction: function () {
            //     console.log('someAction')
            //     console.log(this)
            // }
        },

        saveAction: Ember.computed(function () {
            return Ember.run.bind(this, function () {
                var legacyService = this.get('legacyService');
                legacyService.post({
                    action: 'triggerClick',
                    id: 'scheduleSaveForm\\:saveButton1'
                });
            });
        }),

        discardAction: Ember.computed(function () {
            return Ember.run.bind(this, function () {
                var legacyService = this.get('legacyService');
                legacyService.post({
                    action: 'triggerClick',
                    id: 'scheduleSaveForm\\:discardButton2'
                });
            });
        }),

        goBackAction: Ember.computed(function () {
            return Ember.run.bind(this, function () {
                this.goTo('editSchedule');
            });
        })
    });
});