define('matrix-frontend/reducers/general', ['exports', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/cleaners'], function (exports, _types, _cleaners) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var cancellableRequests = {};
    var initialState = {
        availabilityActivities: [{ name: 'Available', code: 'A' }, { name: 'Not Available', code: 'N' }],
        roles: [],
        cancellableRequests: cancellableRequests
    };

    var schedule = function schedule(state, action) {
        var type = action.type,
            payload = action.payload;


        switch (type) {
            case _types.GENERAL_INTAKE_OUT_OF_OFFICE_ACTIVITIES:
                {
                    var availabilityActivities = state.availabilityActivities;

                    if (availabilityActivities.length === 2) {
                        availabilityActivities = [].concat(_toConsumableArray(availabilityActivities), _toConsumableArray(payload.results.data));
                    }
                    return _extends({}, state, {
                        availabilityActivities: availabilityActivities
                    });
                }

            // case types.GENERAL_INTAKE_ROLES: {
            //     const {results: roles} = payload
            //     return {
            //         ...state,
            //         roles,
            //     }
            // }

            case _types.GENERAL_RECORD_CANCELLABLE_REQUEST:
                {
                    var cubeLocation = payload.cubeLocation,
                        promise = payload.promise,
                        cancelOn = payload.cancelOn;

                    if (!cancellableRequests[cubeLocation]) {
                        cancellableRequests[cubeLocation] = {};
                    }
                    cancelOn.forEach(function (type) {
                        if (!cancellableRequests[cubeLocation][type]) {
                            cancellableRequests[cubeLocation][type] = [];
                        }

                        cancellableRequests[cubeLocation][type].push(promise);
                    });

                    return _extends({}, state, {
                        cancellableRequests: cancellableRequests
                    });
                }

            default:
                {
                    return state || initialState;
                }
        }
    };

    exports.default = schedule;
});