define('matrix-frontend/pods/users/index/route', ['exports', 'npm:underscore'], function (exports, _npmUnderscore) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        queryParams: {
            storeId: {
                refreshModel: true
            },
            hierarchyNodeId: {
                refreshModel: true
            }
        },

        beforeModel: function beforeModel(transition) {
            var _this = this;

            if (transition.queryParams.hierarchyNodeId) {
                return this.store.query('hierarchyNode', transition.queryParams.hierarchyNodeId).then(function (node) {
                    _this.controllerFor('application').set('hierarchyNode', node);
                });
            } else if (transition.queryParams.storeId) {
                return this.store.query('store', transition.queryParams.storeId).then(function (store) {
                    _this.controllerFor('application').set('store', store);
                });
            }
        },

        model: function model(params) {
            var queryParams = _npmUnderscore.default.pick(params, function (value) {
                return !!value;
            });

            return this.store.query('user', queryParams);
        }
    });
});