define('matrix-frontend/pods/reports/tps/route', ['exports', 'npm:moment', 'matrix-frontend/routes/base-route', 'matrix-frontend/utils/confirm-navigate', 'npm:underscore', 'matrix-frontend/utils/interactions', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/date-helper'], function (exports, _npmMoment, _baseRoute, _confirmNavigate, _npmUnderscore, _interactions, _types, _dateHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseRoute.default.extend({
        queryParams: {
            hierarchyNodeId: {
                refreshModel: true
            }
        },

        saveTaskHourError: false,
        saveForecastError: false,
        ajaxService: Ember.inject.service(),
        flashMessages: Ember.inject.service(),
        stateService: Ember.inject.service(),
        redux: Ember.inject.service(),
        notificationService: Ember.inject.service(),

        beforeModel: function beforeModel(transition) {
            var controller = this.controllerFor('reports.tps');
            _confirmNavigate.default.confirmBeforeModel(controller, transition);
        },
        model: function model(params, transition) {
            var self = this;
            return this._super(params, transition).then(function () {
                var ajaxService = self.get('ajaxService');
                var flexpageName = 'PayrollFlexingWorksheet';
                var businessUnit = void 0;
                var client = self.modelFor('clients.show');
                var date = transition.queryParams.date ? transition.queryParams.date : (0, _dateHelper.getClientWeekEndDate)().format('YYYY-MM-DD');
                var retVal = {};

                if (transition.queryParams.hierarchyNodeId) {
                    businessUnit = {
                        type: 'hierarchyNode',
                        id: transition.queryParams.hierarchyNodeId
                    };
                } else if (transition.queryParams.storeId) {
                    businessUnit = {
                        type: 'store',
                        id: transition.queryParams.storeId
                    };
                }

                var forecastUrl = '/workforce/api/dailyForecasts?' + businessUnit.type + '=' + businessUnit.id + '&date=' + date;
                retVal['dailyForecast'] = ajaxService.ajaxGet(forecastUrl);

                var salesUrl = '/workforce/api/reports/sales-metrics-by-day?' + businessUnit.type + '=' + businessUnit.id + '&date=' + date;

                retVal['salesMetrics'] = ajaxService.ajaxGet(salesUrl);

                var scheduleVsActual = '/workforce/api/reports/scheduled-actual-hours-by-day?' + businessUnit.type + '=' + businessUnit.id + '&date=' + date;
                retVal['scheduleHours'] = ajaxService.ajaxGet(scheduleVsActual);

                retVal['activities'] = self.store.query('activity', {
                    clientId: client.get('id')
                });
                retVal['budgets'] = self.store.query('budget', {
                    clientId: client.get('id')
                });
                retVal['tasks'] = self.store.query('task', {
                    clientId: client.get('id')
                });
                if (businessUnit.type === 'store') {
                    var attachmentsUrl = '/workforce/api/attachments?' + businessUnit.type + '=' + businessUnit.id + '&date=' + date + '&displayName=' + flexpageName;
                    retVal['attachments'] = ajaxService.ajaxGet(attachmentsUrl);
                    retVal['taskHours'] = self.store.query('task-hour', {
                        store: businessUnit.id,
                        date: date
                    });
                } else {
                    var taskHourUrl = '/workforce/api/taskHours?' + businessUnit.type + '=' + businessUnit.id + '&date=' + date;
                    retVal['taskHours'] = ajaxService.ajaxGet(taskHourUrl);
                    console.info('GET TASK HOURS');
                }
                return Ember.RSVP.hash(retVal);
            });
        },
        resetController: function resetController(controller, isExiting) {
            if (isExiting) {
                var _controller = this.controllerFor('reports.tps');
                _controller.set('formUpdated', false);
                _controller.set('isLoading', false);
            }
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('formUpdated', false);
            controller.set('isLoading', false);
        },
        doDiscardChanges: function doDiscardChanges() {
            var forecasts = this.store.peekAll('daily-forecast');

            forecasts.forEach(function (item) {
                if (item.hasDirtyAttributes) {
                    item.rollbackAttributes();
                }
            });
            var budgets = this.store.peekAll('task-hour');

            budgets.forEach(function (item) {
                if (item.hasDirtyAttributes) {
                    item.rollbackAttributes();
                }
            });

            var controller = this.controllerFor('reports.tps');
            controller.set('formUpdated', false);
        },
        isChangingBusinessUnitType: function isChangingBusinessUnitType(transition) {
            var businessUnitType = this.get('stateService').getBusinessUnit().constructor.modelName;
            if (businessUnitType === 'store' && transition.queryParams.storeId) {
                return false;
            } else if (businessUnitType === 'hierarchy-node' && transition.queryParams.hierarchyNodeId) {
                return false;
            } else {
                return true;
            }
        },


        actions: {
            discardChanges: function discardChanges(formUpdated) {
                if (formUpdated === true && confirm("You are discarding Unsaved changes. To continue, press 'OK' To Go Back, press 'Cancel'")) {
                    this.doDiscardChanges();
                    return true;
                } else {
                    return false;
                }
            },
            updateTps: function updateTps(businessUnit, payrollBudget, dailyForecast) {
                var self = this;

                var possibleFloat = function possibleFloat(x) {
                    if (typeof x === 'string') {
                        return parseFloat(x.replace(/,/g, '')) || 0;
                    }
                    return x || 0;
                };

                var payload = {
                    task_hours: [],
                    forecasts: []
                };

                _npmUnderscore.default.each(payrollBudget, function (activities) {
                    _npmUnderscore.default.each(activities, function (item) {
                        _npmUnderscore.default.each(item.values, function (value) {
                            if (value.get('hasDirtyAttributes')) {
                                var taskHourData = {
                                    task_id: parseInt(value.task.get('id')),
                                    task_date: _npmMoment.default.utc(value.get('taskdate')).format('YYYY-MM-DD'),
                                    schedule_units: possibleFloat(value.get('scheduleunits')),
                                    schedule_hours: possibleFloat(value.get('schedulehours'))
                                };
                                payload.task_hours.push(taskHourData);
                            }
                        });
                    });
                });

                _npmUnderscore.default.each(dailyForecast, function (forecast) {
                    if (forecast.get('currentforecast') !== undefined && forecast.get('hasDirtyAttributes')) {
                        var forecastData = {
                            current_forecast: possibleFloat(forecast.get('currentforecast')),
                            demand_date: forecast.get('demanddate')
                        };
                        payload.forecasts.push(forecastData);
                    }
                });

                (0, _interactions.send)({
                    header: 'do',
                    body: {
                        service: 'data',
                        namespace: ['payrollworksheet', 'data'],
                        operation: 1,
                        include_channel: 2,
                        broadcast: false,
                        payload: payload,
                        params: {
                            week_end_date: this.redux.getState().cube.weekEndDate.format('YYYY-MM-DD')
                        }
                    }
                }, 'update payrollworksheet data', false, null, null, function (results) {
                    if (results.ok) {
                        self.notificationService.success();
                        self.refresh();
                        self.redux.dispatch({
                            type: _types.NODE_REFRESH_FORECAST,
                            payload: { smartAssign: 0 }
                        });
                        self.redux.dispatch({
                            type: _types.NODE_REFRESH_TASK_HOURS,
                            payload: { smartAssign: 0 }
                        });
                        self.redux.dispatch({
                            type: _types.NODE_REFRESH_ACTUAL_LABOR,
                            payload: {}
                        });
                    } else {
                        self.notificationService.error();
                    }
                }, true);
            },
            willTransition: function willTransition(transition) {
                var controller = this.controllerFor('reports.tps');
                _confirmNavigate.default.confirmWillTransition(controller, this, transition);
                //clean up model - this is needed when transitioning between unit types
                if (this.isChangingBusinessUnitType(transition)) {
                    this.set('controller.model', null);
                }
            }
        }
    });
});