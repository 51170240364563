define('matrix-frontend/utils/interactions', ['exports', 'matrix-frontend/actions/_types', 'ember-ajax/request', 'matrix-frontend/config/environment', 'matrix-frontend/utils/backoff', 'matrix-frontend/services/modal-service', 'matrix-frontend/utils/state', 'matrix-frontend/utils/cleaners'], function (exports, _types, _request, _environment, _backoff, _modalService, _state, _cleaners) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.send = exports.gatherReport = exports.hasGatheredReport = exports.clearPendingGather = exports.gather = exports.push = exports.fetch = exports.setStore = undefined;

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var store = void 0;
    var setStore = exports.setStore = function setStore(s) {
        store = s;
    };

    var PENDING_REQUESTS = new Set();
    var PENDING_GATHERS = new Set();

    var fetch = exports.fetch = function fetch(path) {
        var headers = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
        var token = store.getState().user.currentUser.token;


        if (token) {
            var url = _environment.default.mxl.replace('${host}', window.location.host) + '/' + path;
            var hdrs = _extends({
                Authorization: 'Bearer ' + token
            }, headers);
            var opts = _extends({
                method: 'GET',
                headers: hdrs
            }, options);

            return (0, _request.default)(url, opts);
        }
    };

    var push = exports.push = function push(payload) {
        store.dispatch({
            type: _types.WS_SEND,
            payload: {
                header: 'push',
                body: payload
            }
        });
    };

    var gather = exports.gather = function gather(description, type, args, response_type) {
        var bundle_size = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 200;

        var proceed = !description ? true : !PENDING_GATHERS.has(description);
        window.PENDING_GATHERS = PENDING_GATHERS;

        if (proceed) {
            if (description) {
                PENDING_GATHERS.add(description);
                console.info('in ' + description, PENDING_GATHERS.size);
            }

            store.dispatch({
                type: _types.WS_SEND,
                payload: {
                    header: 'gather_data',
                    body: {
                        description: description,
                        type: type,
                        args: args,
                        response_type: response_type,
                        bundle_size: bundle_size
                    }
                }
            });
        } else {
            console.debug('skipped: ' + description);
        }
    };

    var clearPendingGather = exports.clearPendingGather = function clearPendingGather(description) {
        PENDING_GATHERS.delete(description);
        console.info('out ' + description, PENDING_GATHERS.size);
        if (PENDING_GATHERS.size === 0) {
            console.debug('~~~~ ALL PENDING_GATHERS COMPLETE ~~~~');
        }
    };

    var hasGatheredReport = exports.hasGatheredReport = function hasGatheredReport(clientCode, tag, reportType, reportFocus, weekEndDate) {
        var reports = store.getState().node.gathered.reports;

        var fullTag = clientCode + '.' + tag;

        var thisReportData = void 0;
        var thisFocusData = void 0;
        var thisWeekData = void 0;
        var exclusions = void 0;
        if ((thisReportData = reports[reportType]) && (thisFocusData = thisReportData[reportFocus]) && (thisWeekData = thisFocusData[weekEndDate]) && (exclusions = thisWeekData.exclusions) && exclusions.has(fullTag)) {
            return true;
        }
        return false;
    };

    var gatherReport = exports.gatherReport = function gatherReport(clientCode, tag, reportType, reportFocus, weekEndDate) {
        var bundleSize = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 200;
        var reports = store.getState().node.gathered.reports;

        var fullTag = clientCode + '.' + tag;

        var thisReportData = void 0;
        var thisFocusData = void 0;
        var thisWeekData = void 0;
        var exclusions = void 0;
        if ((thisReportData = reports[reportType]) && (thisFocusData = thisReportData[reportFocus]) && (thisWeekData = thisFocusData[weekEndDate]) && (exclusions = thisWeekData.exclusions) && exclusions.has(fullTag)) {
            return false;
        }

        // if(weekData = weeklyData[weekEndDate]) {
        //     if(reportData = weekData[reportType]) {
        //         exclusions = reportData.exclusions
        //         if(exclusions.has(fullTag)) {
        //             return
        //         }
        //     }
        // }

        gather('gather report: ' + clientCode + '.' + tag + ' ' + reportType + ':' + reportFocus + ' - ' + weekEndDate, 'StoreReportsGather', {
            client_code: clientCode,
            tag: tag,
            report_type: reportType,
            focus: reportFocus,
            week_end_date: weekEndDate,
            exclude_tags: exclusions
        }, 'NODE_INTAKE_REPORT_DATA', bundleSize);
        return true;
    };

    var send = exports.send = function send(payload, description) {
        var socket = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        var onSuccess = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
        var onFailure = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
        var onComplete = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : null;
        var ignoreNode = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;

        if (socket) {
            // console.log('SOCKET INTERACTION')
            store.dispatch({
                type: _types.WS_SEND,
                payload: payload
            });

            if (onSuccess) {
                onSuccess();
            }
        } else {
            /*
                Do an interaction to another service. The incoming message should be
                in the following form:
                 {
                    'header': 'do',
                    'body': {
                        'version': str,
                        'service': str,
                        'namespace': str or list,
                        'operation': int<0-4>,
                        'include_channel': int<0-2>,
                        'channel_override': list,
                        'broadcast': bool,
                        'response_type': string or list,
                        'extra': list,
                        'params': dict
                        'payload': dict
                    }
                }
                 operations correspond to request methods:
                0 = GET
                1 = POST
                2 = PATCH
                3 = PUT
                4 = DELETE
                 include_channel corresponds to the following:
                0 = nothing
                1 = client_code
                2 = client_code and tag
                3 = explicitly set
            */

            var proceed = !description ? true : !PENDING_REQUESTS.has(description);
            window.PENDING_REQUESTS = PENDING_REQUESTS;

            if (proceed) {
                if (description) {
                    PENDING_REQUESTS.add(description);
                    console.info('in ' + description, PENDING_REQUESTS.size);
                }

                (0, _backoff.generalBackoff)(function () {
                    return store.getState().user.currentUser && store.getState().user.currentUser.token && (ignoreNode ? true : store.getState().cube.node);
                }, function () {
                    var methods = {
                        0: 'GET',
                        1: 'POST',
                        2: 'PATCH',
                        3: 'PUT',
                        4: 'DELETE'
                    };

                    var node = store.getState().cube.node;
                    var token = store.getState().user.currentUser.token;
                    var body = payload.body,
                        _payload$body = payload.body,
                        service = _payload$body.service,
                        operation = _payload$body.operation,
                        response_type = _payload$body.response_type,
                        node_type = _payload$body.node_type;

                    var broadcast = body.broadcast ? body.broadcast : false;
                    var include_channel = body.include_channel ? body.include_channel : 0;
                    var channel_override = body.channel_override ? body.channel_override : [];
                    var extra = body.extra ? body.extra : [];
                    var queryParams = body.params ? body.params : {};
                    var data = body.payload ? body.payload : {};
                    var namespace = body.namespace ? body.namespace : [];
                    var cancellable = body.cancellable ? body.cancellable : false;
                    var channel = include_channel === 2 ? [node.clientCode, node.tag.toLowerCase()] : include_channel === 1 ? [node.clientCode] : include_channel === 3 ? channel_override : [];

                    // Abort request if type is specified and does not match
                    if (node_type === 'store' && node.nodeType !== 'store') {
                        if (description) {
                            console.error('ABORT:', description, { node: node });
                            PENDING_REQUESTS.delete(description);
                        }
                        return;
                    }

                    if (typeof namespace === 'string') {
                        namespace = [namespace];
                    }

                    var path = [service].concat(_toConsumableArray(namespace), _toConsumableArray(channel), _toConsumableArray(extra)).join('/');

                    var query = Object.keys(queryParams).length > 0 ? '?' + Object.keys(queryParams).map(function (key) {
                        return key + '=' + queryParams[key];
                    }).join('&') : '';
                    var url = _environment.default.mxl.replace('${host}', window.location.host) + '/' + path + query;
                    var headers = {
                        // "Access-Control-Request-Headers": "",
                        Authorization: 'Bearer ' + token
                    };
                    var method = methods[operation];

                    var options = {
                        method: method,
                        headers: headers
                    };
                    var jsonData = void 0;

                    if (Object.keys(data).length > 0) {
                        var cleanedData = (0, _cleaners.clean_keys)(data, false);
                        // console.info({data, cleanedData})
                        jsonData = data ? JSON.stringify(cleanedData) : null;
                        options['data'] = jsonData;
                    }

                    // if (description) {
                    //     PENDING_REQUESTS.add(description)
                    //     console.info(`in ${description}`, PENDING_REQUESTS.size)
                    // }

                    // console.time(`>>> ${method} ${url}`)
                    var promise = (0, _request.default)(url, options).then(function (results) {
                        var service = new _modalService.default();
                        if (service.saveInProgress) {
                            service.saveAllCompleted();
                        }

                        if (description) {
                            // PENDING_REQUESTS = [...PENDING_REQUESTS.filter(x => x !== description)];
                            PENDING_REQUESTS.delete(description);
                            // console.info(
                            //     `out ${description}`,
                            //     PENDING_REQUESTS,
                            // )
                            console.info('out ' + description, PENDING_REQUESTS.size);
                            if (PENDING_REQUESTS.size === 0) {
                                console.debug('~~~~ ALL PENDING_REQUESTS COMPLETE ~~~~');
                            }
                        }
                        // console.timeEnd(`>>> ${method} ${url}`)
                        if (response_type) {
                            var response_types = response_type.includes('|') ? response_type.split('|') : [response_type];
                            response_types.forEach(function (x) {
                                var payload = {
                                    results: results,
                                    meta: {
                                        version: 'v2',
                                        service: service,
                                        namespace: namespace,
                                        channel: channel,
                                        operation: operation,
                                        broadcast: broadcast,
                                        include_channel: include_channel,
                                        extra: extra,
                                        params: queryParams
                                    }
                                };
                                store.dispatch({
                                    type: _types[x],
                                    payload: payload
                                });
                            });
                        }

                        if (onComplete) {
                            onComplete(results);
                        }

                        if (onSuccess) {
                            onSuccess(results);
                        }
                    }, function (error) {
                        PENDING_REQUESTS.delete(description);
                        store.dispatch({
                            type: _types.ERROR
                        });

                        if (onFailure) {
                            onFailure({
                                url: url,
                                method: method,
                                headers: headers,
                                jsonData: jsonData,
                                error: error
                            });
                        }

                        if (onComplete) {
                            onComplete(error.payload);
                        }
                        console.group('>>> ERROR: ' + description);
                        console.log('url', url);
                        console.log('method', method);
                        console.log('headers', headers);
                        console.log('jsonData', jsonData);
                        console.error(error);
                        console.groupEnd('>>> ERROR: ' + description);
                    });

                    if (cancellable) {
                        var cubeLocation = (0, _state.getCubeLocation)();
                        store.dispatch({
                            type: _types.GENERAL_RECORD_CANCELLABLE_REQUEST,
                            payload: {
                                cubeLocation: cubeLocation,
                                promise: promise,
                                cancelOn: cancellable
                            }
                        });
                    }
                }, 'interaction >> ' + description);
            } else {
                console.debug('skipped: ' + description);
            }
        }
    };
});