define('matrix-frontend/app', ['exports', 'ember-load-initializers', 'matrix-frontend/utils/logging', 'npm:moment', 'matrix-frontend/config/environment', 'matrix-frontend/resolver'], function (exports, _emberLoadInitializers, _logging, _npmMoment, _environment, _resolver) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    // import Ember from 'ember'

    // const report_error = function(error) {
    //     console.error(`${error.message}\nSee debug for more.`)
    //     console.debug(error)
    // }

    // window.history._pushState = window.history.pushState
    // window.history.pushState = (state, title, url) => {
    //     console.info('PUSHSTATE', {state, title, url})
    //     window.history._pushState(state, title, url)
    // }

    // window.history._replaceState = window.history.replaceState
    // window.history.replaceState = (state, title, url) => {
    //     console.info('REPLACESTATE', {state, title, url})
    //     window.history._replaceState(state, title, url)
    // }

    Ember.Debug.registerWarnHandler(function (message, options) {
        console.warn(message);
        console.warn(options);
    });

    if (_environment.default.environment !== 'development' && _environment.default.environment !== 'test') {
        (0, _logging.disableLogging)();
    }

    _npmMoment.default.defineLocale('mx-en', {
        parentLocale: 'en',
        meridiem: function meridiem(hour, minute, isLowercase) {
            var ampm = 'P';
            if (hour < 12) {
                ampm = 'A';
            }
            if (isLowercase) {
                ampm = ampm.toLowerCase();
            }
            return ampm;
        },
        calendar: {
            lastDay: '[Yesterday at] LT',
            sameDay: '[Today at] LT',
            nextDay: '[Tomorrow at] LT',
            lastWeek: '[last] dddd [at] LT',
            nextWeek: 'dddd [at] LT',
            sameElse: 'L [at] h:mma'
        },
        longDateFormat: {
            LT: 'h:mma',
            LTS: 'h:mm:ssa',
            L: 'MM/DD/YYYY',
            l: 'M/D/YYYY',
            LL: 'MMMM Do YYYY',
            ll: 'MMM D YYYY',
            LLL: 'MMMM Do YYYY',
            lll: 'MMM D YYYY',
            LLLL: 'dddd, MMMM Do YYYY',
            llll: 'ddd, MMM D YYYY'
        }
    });
    _npmMoment.default.locale('mx-en');

    var App = Ember.Application.extend({
        modulePrefix: _environment.default.modulePrefix,
        podModulePrefix: _environment.default.podModulePrefix,
        Resolver: _resolver.default,
        customEvents: {
            // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
            validate: 'validate'
        }
        // rootElement: '#matrix-container',
    });

    (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

    exports.default = App;
});