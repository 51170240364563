define('matrix-frontend/models/v2-user', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        username: _emberData.default.attr('string'),
        nodeId: _emberData.default.attr(),

        firstName: _emberData.default.attr(),
        lastName: _emberData.default.attr(),

        email: _emberData.default.attr(),
        phone: _emberData.default.attr(),
        address1: _emberData.default.attr(),
        address2: _emberData.default.attr(),
        city: _emberData.default.attr(),
        state: _emberData.default.attr(),
        zip: _emberData.default.attr(),

        clientCode: _emberData.default.attr(),
        employeeType: _emberData.default.attr(),
        roleIds: _emberData.default.attr(),

        rolesArray: Ember.computed('roleIds', function () {
            return this.get('roleIds').split(',');
        })
        // roles: DS.hasMany('v2-role', {async: true}),
    });
});