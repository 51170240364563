define('matrix-frontend/pods/user-management/controller', ['exports', 'npm:lodash'], function (exports, _npmLodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var emptyUser = {
        username: '',
        roles: []
    };

    exports.default = Ember.Controller.extend({});
});