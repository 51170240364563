define('matrix-frontend/pods/components/control-panel/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        disabledButtons: true,
        saveService: Ember.inject.service(),

        showSavePanel: Ember.computed('save', 'discard', function () {
            return this.get('save') || this.get('discard');
        })
    });
});