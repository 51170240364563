define('matrix-frontend/actions/client', ['exports', 'matrix-frontend/utils/backoff', 'matrix-frontend/utils/interactions'], function (exports, _backoff, _interactions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.nonstoreUserReload = undefined;
    var nonstoreUserReload = exports.nonstoreUserReload = function nonstoreUserReload(store) {
        (0, _interactions.setStore)(store);
        console.debug('~~nonstoreUserReload~~');

        (0, _backoff.generalBackoff)(function () {
            return store.getState().cube.clientCode && store.getState().user.currentUser;
        }, function () {
            // retrieve users
            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'user',
                    namespace: 'nonstore',
                    operation: 0,
                    include_channel: 3,
                    channel_override: [store.getState().cube.clientCode],
                    broadcast: false,
                    params: {
                        count: 0
                    },
                    response_type: 'CLIENT_USER_INTAKE'
                }
            }, 'retrieve list of nonstore users: ' + store.getState().cube.clientCode, false, null, null, null, true);
        });
    };
});