define('matrix-frontend/components/react-only/employee-contact-row', ['exports', 'react', 'matrix-frontend/actions/_types', 'matrix-frontend/store', 'matrix-frontend/utils/format-helpers', 'matrix-frontend/components/react-only/employee-name-cell'], function (exports, _react, _types, _store, _formatHelpers, _employeeNameCell) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var handleClick = function handleClick(employee, modalService, modalKey) {
        _store.store.dispatch({
            type: _types.EP_SET_EMPLOYEE_PANEL,
            payload: { employee: employee }
        });
        modalService.open(modalKey);
    };

    var comp = function comp(props) {
        var employee = props.employee,
            modalService = props.modalService,
            currentStore = props.currentStore;


        var classes = [employee.restrictedReason ? 'icon-close' : employee.verifiedEmail ? 'icon-check' : 'icon-circle', employee.restrictedReason ? 'red-text' : employee.verifiedEmail ? 'green-text' : 'gray-lighten-1-text', 'breathe-r-5', 'constant-size-icon'];
        var iconTooltip = employee.restrictedReason ? {
            'data-tip': employee.id,
            'data-for': 'emailStatusTooltip',
            'data-place': 'bottom'
        } : null;

        var isLoan = currentStore.id !== employee.store.id;
        var className = classes.join(' ') + ' no-print';
        var emailIcon = employee.email ? _react.default.createElement('i', { className: className }) : '';

        return _react.default.createElement(
            'div',
            { className: 'row' },
            _react.default.createElement(_employeeNameCell.default, { employee: employee, currentStore: currentStore }),
            _react.default.createElement(
                'div',
                {
                    className: 'wrapper f9 pointer',
                    onClick: function onClick() {
                        return handleClick(employee, modalService, 'contactinfo-modal');
                    }
                },
                _react.default.createElement(
                    'div',
                    {
                        className: isLoan ? 'column f2 hide-on-print allow-overflow gray-lighten-1-text' : 'column f2 hide-on-print allow-overflow',
                        'data-tooltip': employee.mobile ? 'mobile' : employee.phone ? 'home' : null
                    },
                    _formatHelpers.default.phoneNumber(employee.mobile || employee.phone || '')
                ),
                _react.default.createElement(
                    'div',
                    {
                        className: isLoan ? 'column f2 flex-on-print gray-lighten-1-text' : 'column f2 flex-on-print'
                    },
                    _formatHelpers.default.phoneNumber(employee.mobile || '')
                ),
                _react.default.createElement(
                    'div',
                    {
                        className: isLoan ? 'column f2 flex-on-print gray-lighten-1-text' : 'column f2 flex-on-print'
                    },
                    _formatHelpers.default.phoneNumber(employee.phone || '')
                ),
                _react.default.createElement(
                    'div',
                    {
                        className: isLoan ? 'column f3 hide-on-print text gray-lighten-1-text' : 'column f3 hide-on-print text'
                    },
                    _react.default.createElement(
                        'div',
                        iconTooltip,
                        emailIcon,
                        employee.email
                    )
                ),
                _react.default.createElement(
                    'div',
                    {
                        className: isLoan ? 'column f2 text gray-lighten-1-text' : 'column f2 text'
                    },
                    employee.emergency.name
                ),
                _react.default.createElement(
                    'div',
                    {
                        className: isLoan ? 'column f2 gray-lighten-1-text' : 'column f2'
                    },
                    _formatHelpers.default.phoneNumber(employee.emergency.phone)
                )
            )
        );
    };

    exports.default = comp;
});