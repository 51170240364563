define('matrix-frontend/pods/components/schedule-page-part-hour/component', ['exports', 'ember-redux'], function (exports, _emberRedux) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var stateToComputed = function stateToComputed(state) {
        return {
            expandedRow: state.scheduleEdit.expandedRow
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {};
    };

    var Comp = Ember.Component.extend({
        isExpanded: Ember.computed('expandedRow', function () {
            var employee = this.get('employee');
            return employee ? this.get('expandedRow') === 'employee-' + employee.id : false;
        }),

        isTerminated: Ember.computed('employee', 'hour.date', function () {
            var employee = this.get('employee');
            var date = this.get('hour.date');
            if (employee && employee.terminationDate) {
                return date.isAfter(employee.terminationDate, 'day');
            }
            return false;
        }),

        notStarted: Ember.computed('employee', 'hour.date', function () {
            var employee = this.get('employee');
            var date = this.get('hour.date');
            if (employee && employee.startDate) {
                return date.isBefore(employee.startDate, 'day');
            }
            return false;
        })
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});