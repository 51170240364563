define('matrix-frontend/reducers/_activities', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var activities = [{
        id: 1,
        name: 'Manage',
        shortName: 'Mng',
        display: true,
        color: '#4ECDC4',
        borderColor: '#4ECDC4'
    }, {
        id: 2,
        name: 'Sell',
        shortName: 'Sll',
        display: true,
        color: '#C8F7C5',
        borderColor: '#C8F7C5'
    }, {
        id: 3,
        name: 'Visual',
        shortName: 'Vis',
        display: true,
        color: '#F5D76E',
        borderColor: '#F5D76E'
    }, {
        id: 4,
        name: 'Freight',
        shortName: 'Fgt',
        display: true,
        color: '#EB9532',
        borderColor: '#EB9532'
    }, {
        id: 5,
        name: 'Cashier',
        shortName: 'Csh',
        display: true,
        color: '#9E6C4B',
        borderColor: '#9E6C4B'
    }, {
        id: 6,
        name: 'MD/Transfer',
        shortName: 'MDT',
        display: true,
        color: '#9B59B6',
        borderColor: '#9B59B6'
    }, {
        id: 7,
        name: 'ShipFromStore',
        shortName: 'SFS',
        display: true,
        color: '#D2527F',
        borderColor: '#D2527F'
    }, {
        id: 8,
        name: 'Other',
        shortName: 'Oth',
        display: true,
        color: '#4183D7',
        borderColor: '#4183D7'
    }, {
        id: 9,
        name: 'ShiftExtention',
        shortName: 'Ext',
        display: true,
        color: '#96281B',
        borderColor: '#96281B'
    }, {
        id: 10,
        name: 'FooBar',
        shortName: 'Foo',
        display: true,
        color: '#1E824C',
        borderColor: '#1E824C'
    }, {
        id: 11,
        name: 'Rest Break',
        shortName: 'Rst',
        display: false,
        color: '#BDC3C7',
        borderColor: '#BDC3C7'
    }, {
        id: 12,
        name: 'Unpaid Break',
        shortName: 'Unp',
        display: false,
        color: 'transparent',
        borderColor: '#eee'
    }];

    exports.default = activities;
});