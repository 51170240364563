define('matrix-frontend/components/schedule-unassigned-row', ['exports', 'react', 'matrix-frontend/components/react-only/mouse-area', 'matrix-frontend/components/react-only/schedule-brush', 'matrix-frontend/components/react-only/schedule-day', 'ember-react-components', 'matrix-frontend/components/react-only/schedule-shift', 'matrix-frontend/store', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/date-helper'], function (exports, _react, _mouseArea, _scheduleBrush, _scheduleDay, _emberReactComponents, _scheduleShift, _store, _types, _dateHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var DailyRow = function DailyRow(props) {
        var shift = props.shift,
            hours = props.hours,
            segmentSize = props.segmentSize,
            segmentOffset = props.segmentOffset,
            leftOffset = props.leftOffset,
            activitiesById = props.activitiesById,
            row = props.row,
            dotw = props.dotw,
            editMode = props.editMode,
            canEdit = props.canEdit,
            employees = props.employees,
            activeShift = props.activeShift;


        // <div
        //     className="row unassigned-shifts-row no-pad"
        // >

        var classes = ['row', 'unassigned-shifts-row', 'no-pad'];
        if (activeShift && shift.meta.id === activeShift.meta.id) {
            classes.push('active');
        }

        return _react.default.createElement(
            'div',
            { className: classes.join(' ') },
            hours.map(function (hour, i) {
                return _react.default.createElement('div', {
                    className: 'column no-pad hour',
                    style: { width: segmentSize * 4 + 'px' },
                    key: row + '-' + i
                });
            }),
            _react.default.createElement(_scheduleShift.default, {
                segmentOffset: segmentOffset,
                segmentSize: segmentSize,
                leftOffset: leftOffset,
                shift: shift,
                activitiesById: activitiesById
            })
        );
    };

    var DailySection = function DailySection(props) {
        var unassignedShifts = props.unassignedShifts,
            date = props.date,
            hours = props.hours,
            segmentSize = props.segmentSize,
            segmentOffset = props.segmentOffset,
            activitiesById = props.activitiesById,
            leftOffset = props.leftOffset,
            editMode = props.editMode,
            canEdit = props.canEdit,
            employees = props.employees,
            activeShift = props.activeShift;


        var dotw = (0, _dateHelper.getDayOfClientWeek)(date) + 1;
        var dailyShifts = unassignedShifts[dotw];

        return dailyShifts ? _react.default.createElement(
            _react.default.Fragment,
            null,
            dailyShifts.map(function (k, i) {
                return _react.default.createElement(DailyRow, {
                    key: i,
                    row: i,
                    shift: k,
                    hours: hours,
                    segmentSize: segmentSize,
                    segmentOffset: segmentOffset,
                    activitiesById: activitiesById,
                    leftOffset: leftOffset,
                    dotw: dotw,
                    editMode: editMode,
                    canEdit: canEdit,
                    employees: employees,
                    activeShift: activeShift
                });
            }),
            _react.default.createElement(
                'div',
                { className: 'row unassigned-shifts-row no-pad' },
                hours.map(function (hour, i) {
                    return _react.default.createElement('div', {
                        className: 'column no-pad hour',
                        style: { width: segmentSize * 4 + 'px' },
                        key: dailyShifts.length + '-' + i
                    });
                })
            )
        ) : null;
    };

    var WeeklyRow = function WeeklyRow(props) {
        var unassignedShifts = props.unassignedShifts,
            activities = props.activities,
            activitiesById = props.activitiesById,
            editMode = props.editMode,
            canEdit = props.canEdit,
            index = props.index,
            weekEndDate = props.weekEndDate,
            activeShift = props.activeShift,
            noSchedule = props.noSchedule;


        var rowShifts = Object.keys(unassignedShifts).map(function (x) {
            return unassignedShifts[x][index - 1] || null;
        }).reduce(function (x, y, i) {
            x[i + 1] = y;
            return x;
        }, {});
        var days = [].concat(_toConsumableArray(Array(7).keys())).map(function (x) {
            return x + 1;
        });

        return _react.default.createElement(
            'div',
            { className: 'wrapper f7 weekly-row' },
            days.map(function (day) {
                return _react.default.createElement(_scheduleDay.default, {
                    'class': 'column f1 no-pad',
                    key: day,
                    dotw: day,
                    employee: null,
                    shifts: rowShifts,
                    weekEndDate: weekEndDate,
                    activities: activities,
                    activitiesById: activitiesById,
                    editMode: editMode,
                    activeShift: activeShift,
                    index: index,
                    unassigned: true,
                    canEdit: canEdit,
                    noSchedule: noSchedule
                });
            })
        );
    };

    var comp = function comp(props) {
        var date = props.date,
            activeShift = props.activeShift;

        var MainSection = date ? DailySection : WeeklyRow;

        return _react.default.createElement(
            'div',
            { className: props.class },
            _react.default.createElement(MainSection, props)
        );
    };

    exports.default = (0, _emberReactComponents.default)(comp);
});