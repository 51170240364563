define('matrix-frontend/actions/reports', ['exports', 'matrix-frontend/actions/_types', 'npm:moment', 'matrix-frontend/utils/backoff', 'matrix-frontend/utils/interactions', 'matrix-frontend/constants', 'matrix-frontend/utils/state'], function (exports, _types, _npmMoment, _backoff, _interactions, _constants, _state) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.reportsReload = undefined;

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var reportsReload = exports.reportsReload = function reportsReload(store) {
        console.info('~~reportsReload~~');
        (0, _interactions.setStore)(store);

        var _store$getState$cube = store.getState().cube,
            clientCode = _store$getState$cube.clientCode,
            node = _store$getState$cube.node;

        if (node && node.nodeType !== 'store') {
            (0, _backoff.generalBackoff)(function () {
                var _store$getState$cube2 = store.getState().cube,
                    clientCode = _store$getState$cube2.clientCode,
                    node = _store$getState$cube2.node;
                var hierarchies = store.getState().node.hierarchies;

                if (node && hierarchies && node.nodeType !== 'store') {
                    var hierarchy = hierarchies[clientCode + '.' + node.tag];
                    if (!(hierarchy && hierarchy.ancestorTags)) return false;
                }
                return store.getState().cube.node && store.getState().user.currentUser && store.getState().cube.weekEndDate && store.getState().websocket.currentSocket && store.getState().websocket.status == 1 && (0, _state.hasScope)('reports:new.view');
            }, function () {
                var _store$getState$cube3 = store.getState().cube,
                    clientCode = _store$getState$cube3.clientCode,
                    node = _store$getState$cube3.node,
                    weekEndDate = _store$getState$cube3.weekEndDate;
                var hierarchies = store.getState().node.hierarchies;
                var report = store.getState().reports.report;


                report.gathers.forEach(function (g) {
                    var gWeekEndDate = g.weekEndDate(weekEndDate).format('YYYY-MM-DD');
                    var didGather = (0, _interactions.gatherReport)(clientCode, node.tag, g.report, 'day', gWeekEndDate, 250);
                });
            });
        }

        (0, _backoff.generalBackoff)(function () {
            return store.getState().cube.node && store.getState().user.currentUser && store.getState().cube.weekEndDate && (0, _state.hasScope)('reports:new.view');
        }, function () {
            var _store$getState$cube4 = store.getState().cube,
                clientCode = _store$getState$cube4.clientCode,
                node = _store$getState$cube4.node,
                weekEndDate = _store$getState$cube4.weekEndDate;
            var _store$getState$repor = store.getState().reports,
                report = _store$getState$repor.report,
                hourlyData = _store$getState$repor.hourlyData;

            var fullTag = clientCode + '.' + node.tag;

            if (!report.views.includes('detail')) return;

            report.gathers.forEach(function (g) {
                var gWeekEndDate = g.weekEndDate(weekEndDate).format('YYYY-MM-DD');
                var thisWeekData = hourlyData[gWeekEndDate] || {};
                var thisReportData = thisWeekData[g.report] || {};
                if (thisReportData[fullTag] === undefined) {
                    var extraParams = g.extraParams || {};
                    (0, _interactions.send)({
                        header: 'do',
                        body: {
                            service: 'data',
                            namespace: g.endpoint,
                            operation: 0,
                            include_channel: 2,
                            broadcast: false,
                            response_type: 'RP_INTAKE_HOURLY',
                            params: _extends({
                                focus: 'hour',
                                week_end_date: gWeekEndDate,
                                aggregate: true
                            }, extraParams),
                            extra: {
                                reportType: g.report
                            }
                        }
                    }, 'retrieve hourly ' + g.report + ' ' + gWeekEndDate + ': ' + fullTag);
                }
            });
        });
    };
});