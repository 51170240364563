define('matrix-frontend/adapters/application', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTAdapter.extend({
        namespace: 'workforce/api',

        headers: Ember.computed('session.token', function () {
            if (this.session.get('user')) {
                return {
                    'Access-Token': this.session.get('token')
                };
            } else {
                return {};
            }
        })
    });
});