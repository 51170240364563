define('matrix-frontend/pods/login/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        /**
         * TODO remove once all legacy iframed pages are removed
         */
        activate: function activate() {
            if (window !== window.top) {
                top.window.location.href = '/login';
            }
        }
    });
});