define('matrix-frontend/models/hierarchy-node', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        parent: _emberData.default.belongsTo('hierarchy-node', { async: true }),
        name: _emberData.default.attr('string'),
        isActive: _emberData.default.attr('boolean'),
        type: _emberData.default.attr('string'),
        code: _emberData.default.attr('string'),
        children: _emberData.default.hasMany('hierarchy-node', { async: true, inverse: 'parent' }),
        stores: _emberData.default.hasMany('store', { async: true }),
        attachments: _emberData.default.hasMany('attachment', { async: true })
    });
});