define('matrix-frontend/pods/components/new-reports-page/component', ['exports', 'ember-redux', 'matrix-frontend/actions/_types', 'matrix-frontend/constants', 'npm:moment', 'matrix-frontend/utils/state', 'matrix-frontend/utils/date-helper'], function (exports, _emberRedux, _types, _constants, _npmMoment, _state, _dateHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var stateToComputed = function stateToComputed(state) {
        return {
            sid: state.websocket.sid,
            date: state.cube.date,
            node: state.cube.node,
            cube: state.cube,
            clientCode: state.cube.clientCode,
            clients: state.client.clients,
            weekEndDate: state.cube.weekEndDate,
            currentUser: state.user.currentUser,
            preferences: state.preferences,
            websocket: state.websocket,
            reports: state.reports,
            report: state.reports.report,
            gatheredReports: state.node.gathered.reports,
            // weeklyData: state.reports.weeklyData,
            hourlyData: state.reports.hourlyData,
            view: state.reports.view,
            subview: state.reports.subview,
            timeColumn: state.reports.timeColumn,
            hierarchies: state.node.gathered.hierarchies,
            nodeStores: state.node.stores,
            nodeHierarchies: state.node.hierarchies
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {
            // selectReport: (report) => dispatch({
            //     type: types.RP_SET_REPORT,
            //     payload: {
            //         report,
            //     },
            // }),
            // selectView: view => dispatch({
            //     type: types.RP_SET_VIEW,
            //     payload: {
            //         view,
            //     },
            // }),
            // selectSubview: subview => dispatch({
            //     type: types.RP_SET_VIEW,
            //     payload: {
            //         subview,
            //     },
            // }),
            selectTimeColumn: function selectTimeColumn(timeColumn) {
                return dispatch({
                    type: _types.RP_SET_TIMECOLUMN,
                    payload: {
                        timeColumn: timeColumn
                    }
                });
            }
        };
    };

    var Comp = Ember.Component.extend({
        stateService: Ember.inject.service(),
        redux: Ember.inject.service(),

        reportTypes: _constants.ReportTypesOrdered,
        timeLevel: 'week',

        viewTypes: [{
            id: 'map',
            text: 'Map'
        }, {
            id: 'summary',
            text: 'Summary'
        }, {
            id: 'detail',
            text: 'Detail'
        }],

        isTimeSelectorVisible: Ember.computed('view', function () {
            return this.view === 'map';
        }),

        currentClient: Ember.computed('clientCode', 'clients', function () {
            var clientCode = this.get('clientCode');
            var clients = this.get('clients');
            if (clients && clientCode) {
                return clients[clientCode];
            }
        }),

        canViewPage: Ember.computed('currentClient', 'currentUser.roles', function () {
            // const roles = this.get('currentUser.roles')
            // if (!roles) {
            //     return false
            // }
            // return roles.any(role => {
            //     return 'matrix_admin' === role.name
            // })
            var currentClient = this.get('currentClient');
            return (0, _state.hasScope)('reports:new.view');
        }),

        dcKey: Ember.computed('timeColumn', function () {
            return this.timeColumn;
        }),

        dataColumns: Ember.computed('weekEndDate', 'timeLevel', function () {
            var _this = this;

            if (this.timeLevel === 'week') {
                var shortDayNames = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
                return [].concat(_toConsumableArray(Array(7).keys())).map(function (dotw) {
                    var dt = (0, _npmMoment.default)(_this.weekEndDate).startOf('day').add(-6 + dotw, 'days');
                    return {
                        shortText: shortDayNames[dt.day()],
                        text: dt.format('ddd') + ' ' + (dt.month() + 1) + '/' + dt.date(),
                        key: dotw
                    };
                });
            }
            return undefined;
        }),

        totalColumn: Ember.computed('timeLevel', function () {
            var key = null;
            switch (this.timeLevel) {
                case 'week':
                    return {
                        shortText: 'Wk',
                        text: 'Week',
                        key: key
                    };
                default:
                    return undefined;
            }
        }),

        columns: Ember.computed('dataColumns', 'totalColumn', function () {
            return [].concat(_toConsumableArray(this.get('dataColumns')), [this.get('totalColumn')]);
        }),

        directChildren: Ember.computed('node', 'hierarchies', function () {
            var _this2 = this;

            var hierarchy = this.hierarchies.data[this.clientCode + '.' + this.node.tag];
            if (hierarchy) {
                if (hierarchy.type === 'store') {
                    return [];
                }
                return hierarchy.child_tags.map(function (tag) {
                    return _this2.hierarchies.data[tag];
                }).filter(function (x) {
                    return x != null;
                });
            }
            return null;
        }),

        thisNodeStore: Ember.computed('node', 'nodeStores', function () {
            var fullTag = this.clientCode + '.' + this.node.tag;
            if (this.node.nodeType === 'store') {
                return this.nodeStores[fullTag];
            }
            return null;
        }),

        stores: Ember.computed('node', 'hierarchies', 'thisNodeStore', function () {
            var _this3 = this;

            var fullTag = this.clientCode + '.' + this.node.tag;
            if (this.node.nodeType === 'store') {
                var nodeStore = this.get('thisNodeStore');
                return nodeStore ? [_extends({}, nodeStore, {
                    tag: fullTag,
                    lat: nodeStore.latitude,
                    lon: nodeStore.longitude
                })] : null;
            }
            var getStores = function getStores(tag) {
                var hierarchy = _this3.hierarchies.data[tag];
                if (hierarchy) {
                    if (hierarchy.type === 'store') {
                        return [hierarchy];
                    }
                    var stores = [];
                    hierarchy.child_tags.forEach(function (childTag) {
                        stores.push.apply(stores, _toConsumableArray(getStores(childTag)));
                    });
                    return stores;
                }
                return null;
            };
            return getStores(fullTag);
        }),

        nodeHierarchy: Ember.computed('nodeHierarchies', 'nodeStores', 'node', function () {
            if (this.node.nodeType === 'store') {
                if (this.nodeStores == null) return null;
                return this.nodeStores[this.clientCode + '.' + this.node.tag];
            } else {
                if (this.nodeHierarchies == null) return null;
                return this.nodeHierarchies[this.clientCode + '.' + this.node.tag];
            }
        }),

        scheduleStatuses: Ember.computed('node', 'nodeHierarchy', 'weekEndDate', function () {
            var nodeHierarchy = this.nodeHierarchy;
            var week = void 0,
                scheduleStatus = null;
            if (!(nodeHierarchy && (week = nodeHierarchy.weeks[this.weekEndDate.format('YYYY-MM-DD')]) && (scheduleStatus = week.scheduleStatus))) return null;
            return this.node.nodeType === 'store' ? _defineProperty({}, 'store' + nodeHierarchy.code, scheduleStatus.status.description) : scheduleStatus.byStore;
        }),

        viewableScheduleStatuses: Ember.computed('currentUser', 'client', function () {
            return new Set(['hidden', 'executive', 'readonly', 'preliminary', 'active'].filter(function (x) {
                return (0, _state.hasScope)('schedule.store:' + x + '.view');
            }));
        }),

        infoRollups: Ember.computed('weekEndDate', 'node', 'hierarchies', 'scheduleStatuses', 'viewableScheduleStatuses', function () {
            var self = this;
            var fullTag = this.clientCode + '.' + this.node.tag;
            var scheduleStatuses = this.scheduleStatuses || {};

            var rollups = {};
            var viewableScheduleStatuses = this.viewableScheduleStatuses;

            var calcStoreRollup = function calcStoreRollup(tag) {
                var status = scheduleStatuses['store' + tag.split('.')[2]];
                var anyNotActive = status && status !== 'unknown' && status !== 'active' && viewableScheduleStatuses && viewableScheduleStatuses.has(status);

                var rollup = {
                    anyNotActive: anyNotActive,
                    allNotVisible: !(viewableScheduleStatuses && viewableScheduleStatuses.has(status)),
                    allStatusKnown: status != null
                };
                rollups[tag] = rollup;
                return rollup;
            };

            var calcRollup = function calcRollup(tag) {
                var rollup = void 0;

                var defaultRollup = {
                    anyNotActive: false,
                    allNotVisible: true,
                    allStatusKnown: true
                };

                var hierarchy = self.hierarchies.data[tag];
                if (hierarchy) {
                    var childRollups = hierarchy.child_tags.map(function (childTag) {
                        var childHierarchy = self.hierarchies.data[childTag];
                        return rollups[childTag] || (childHierarchy.type === 'store' ? calcStoreRollup(childTag) : calcRollup(childTag));
                    });

                    if (childRollups.length == 0) {
                        rollup = defaultRollup;
                    }

                    childRollups.forEach(function (childRollup) {
                        if (childRollup !== undefined) {
                            if (!rollup) rollup = defaultRollup;
                            rollup.anyNotActive = rollup.anyNotActive || childRollup.anyNotActive;
                            rollup.allNotVisible = rollup.allNotVisible && childRollup.allNotVisible;
                            rollup.allStatusKnown = rollup.allStatusKnown && childRollup.allStatusKnown;
                        }
                    });
                }

                rollups[tag] = rollup;
                return rollup;
            };

            // Recursively calculate rollups from current node
            if (this.node.nodeType === 'store') {
                calcStoreRollup(fullTag);
            } else {
                calcRollup(fullTag);
            }
            return rollups;
        }),

        rollups: Ember.computed('gatheredReports', 'hourlyData', 'weekEndDate', 'report', 'node', 'hierarchies', 'columns', 'view', function () {
            var _this4 = this;

            var self = this;
            var useComps = this.report ? this.report.useComps : false;
            var gatherCompA = void 0;
            var gatherCompB = void 0;
            if (useComps) {
                gatherCompA = this.report.gathers[0];
                gatherCompB = this.report.gathers[1];
            }

            var totalColumn = this.get('totalColumn');

            var weekEnd = (0, _npmMoment.default)(this.weekEndDate).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
            var thisWeek = (0, _dateHelper.getClientWeekEndDate)();
            var weekInPast = thisWeek.diff(weekEnd, 'days') > 0;
            var fullTag = this.clientCode + '.' + this.node.tag;
            var rollups = {};

            var rollupFromHourly = function rollupFromHourly(tag) {
                var rollup = void 0;

                var getDataKey = function getDataKey(reportType) {
                    switch (reportType) {
                        case 'salesforecast':
                        case 'salesactual':
                            return 'sales';
                        default:
                            return null;
                    }
                };

                self.report.gathers.forEach(function (g) {
                    var gWeekEndDate = g.weekEndDate(weekEnd).format('YYYY-MM-DD');
                    var thisWeekData = _this4.hourlyData[gWeekEndDate] || {};
                    var thisReportData = thisWeekData[g.report] || {};
                    var data = thisReportData[tag];
                    if (data !== undefined && !rollup) {
                        rollup = {};
                    }
                    if (data) {
                        var daysOfClientWeek = [].concat(_toConsumableArray(Array(7).keys()));
                        var hoursOfDay = [].concat(_toConsumableArray(Array(24).keys()));
                        daysOfClientWeek.forEach(function (docw) {
                            rollup[docw] = rollup[docw] || {};
                            rollup[docw][g.key] = hoursOfDay.reduce(function (agg, hour) {
                                var x = data[docw * 24 + hour] || 0;
                                if (g.dataTransform) x = g.dataTransform(x);
                                return agg + x;
                            }, 0);
                        });
                    }
                });

                if (rollup) {
                    if (useComps) {
                        Object.keys(rollup).forEach(function (dcKey) {
                            var dcData = rollup[dcKey];
                            var xA = dcData[gatherCompA.key];
                            var xB = dcData[gatherCompB.key];
                            if (xA && xB) {
                                dcData.compA = xA;
                                dcData.compB = xB;
                            }
                        });
                    }
                }

                return rollup;
            };

            var dataColumnKeys = {};

            var getDataColumnKeys = function getDataColumnKeys(weekEndDate, weekEndStr) {
                if (dataColumnKeys[weekEndStr]) return dataColumnKeys[weekEndStr];
                var keys = {};
                Array(7).fill().forEach(function (_, i) {
                    keys[weekEndDate.clone().add(-6 + i, 'days').format('YYYY-MM-DD')] = i;
                });
                dataColumnKeys[weekEndStr] = keys;
                return keys;
            };

            var calcStoreRollup = function calcStoreRollup(tag) {
                var rollup = void 0;

                self.report.gathers.forEach(function (g) {
                    var gWeekEndDate = g.weekEndDate(weekEnd);
                    var gWeekEndStr = gWeekEndDate.format('YYYY-MM-DD');
                    var thisReportData = self.gatheredReports[g.report] || {};
                    var thisFocusData = thisReportData['day'] || {};
                    var thisWeekData = thisFocusData[gWeekEndStr] || {};
                    var data = thisWeekData.data || {};
                    var storeData = data[tag];
                    var reportDataKey = _constants.ReportDataKeys[g.report];
                    if (storeData !== undefined) {
                        if (!rollup) rollup = {};
                        if (storeData) {
                            var columnKeys = getDataColumnKeys(gWeekEndDate, gWeekEndStr);
                            storeData.forEach(function (x) {
                                var k = columnKeys[x.date];
                                if (!rollup[k]) rollup[k] = {};
                                var value = x[reportDataKey];
                                if (value != null && g.dataTransform) value = g.dataTransform(value);
                                rollup[k][g.key] = value;
                            });
                        }
                    }
                });

                if (rollup) {
                    if (useComps) {
                        Object.keys(rollup).forEach(function (dcKey) {
                            var dcData = rollup[dcKey];
                            var xA = dcData[gatherCompA.key];
                            var xB = dcData[gatherCompB.key];
                            if (xA && xB) {
                                dcData.compA = xA;
                                dcData.compB = xB;
                            }
                        });
                    }
                }

                rollups[tag] = rollup;
                return rollup;
            };

            var calcRollup = function calcRollup(tag) {
                var rollup = void 0;

                var hierarchy = self.hierarchies.data[tag];
                if (hierarchy) {
                    var childRollups = hierarchy.child_tags.map(function (childTag) {
                        var childHierarchy = self.hierarchies.data[childTag];
                        return rollups[childTag] || (childHierarchy.type === 'store' ? calcStoreRollup(childTag) : calcRollup(childTag));
                    });

                    if (childRollups.length == 0) {
                        rollup = {};
                    }

                    childRollups.forEach(function (childRollup) {
                        if (childRollup !== undefined) {
                            if (!rollup) rollup = {};
                            Object.keys(childRollup || {}).forEach(function (dcKey) {
                                var scDcData = childRollup[dcKey];
                                if (!rollup[dcKey]) rollup[dcKey] = {};
                                var dcData = rollup[dcKey];
                                Object.keys(scDcData).forEach(function (k) {
                                    dcData[k] = scDcData[k] != null ? (dcData[k] || 0) + scDcData[k] : dcData[k] === undefined ? null : dcData[k];
                                });
                            });
                        }
                    });
                }

                rollups[tag] = rollup;
                return rollup;
            };

            // Recursively calculate rollups from current node
            if (this.node.nodeType === 'store') {
                calcStoreRollup(fullTag);

                // try using a rollup based on the hourly data if
                // the user has not gathered data from a higher node
                if (this.view !== 'summary' && !rollups[fullTag]) {
                    var hourlyBasedRollup = rollupFromHourly(fullTag);
                    if (hourlyBasedRollup != null) {
                        rollups[fullTag] = hourlyBasedRollup;
                    }
                }
            } else {
                calcRollup(fullTag);
            }

            // Calculate totals for each rollup
            Object.keys(rollups).forEach(function (tag) {
                var rollup = rollups[tag];
                if (rollup) {
                    if (Object.keys(rollup).length !== 0) {
                        rollup[totalColumn.key] = Object.keys(rollup).map(function (k) {
                            return rollup[k];
                        }).reduce(function (acc, curr) {
                            Object.keys(curr).forEach(function (k) {
                                return acc[k] = (acc[k] || 0) + (curr[k] || 0);
                            });
                            return acc;
                        }, {});
                        Object.keys(rollup).forEach(function (dcKey) {
                            var dcData = rollup[dcKey];
                            if (useComps) {
                                if (_this4.timeLevel === 'week' && dcKey === totalColumn.key && weekInPast) {
                                    dcData.compA = dcData[gatherCompA.key];
                                    dcData.compB = dcData[gatherCompB.key];
                                }
                                if (dcData.compA && dcData.compB) {
                                    // if(dcData.compA === 0 || dcData.compB === 0) {
                                    //     dcData.displayValue = 0
                                    // } else {
                                    dcData.displayValue = dcData.compA - dcData.compB;
                                    // }
                                }
                            } else {
                                var _report;

                                dcData.displayValue = (_report = _this4.report).displayValue.apply(_report, _toConsumableArray(_this4.report.gathers.map(function (g) {
                                    return dcData[g.key];
                                })));
                            }
                        });
                    }
                }
            });
            return rollups;
        }),

        actions: {
            selectReport: function selectReport(report) {
                this.router.transitionTo({
                    queryParams: {
                        report: report
                    }
                });
            },
            selectView: function selectView(view) {
                this.router.transitionTo({
                    queryParams: {
                        view: view
                    }
                });
            },
            selectSubview: function selectSubview(subview) {
                this.router.transitionTo({
                    queryParams: {
                        subview: subview
                    }
                });
            }
        }
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});