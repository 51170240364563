define('matrix-frontend/components/reports-map', ['exports', 'ember-react-components', 'matrix-frontend/store', 'react', 'npm:react-leaflet', 'matrix-frontend/components/react-only/heatmap-layer', 'matrix-frontend/components/react-only/colorblind-toggle', 'matrix-frontend/constants', 'npm:moment', 'matrix-frontend/utils/hooks', 'lodash', 'npm:leaflet', 'matrix-frontend/utils/state'], function (exports, _emberReactComponents, _store, _react, _npmReactLeaflet, _heatmapLayer, _colorblindToggle, _constants, _npmMoment, _hooks, _lodash2, _npmLeaflet, _state) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var map = _lodash2.default.map,
        min = _lodash2.default.min,
        max = _lodash2.default.max;
    var Map = _npmReactLeaflet.default.Map,
        TileLayer = _npmReactLeaflet.default.TileLayer,
        Popup = _npmReactLeaflet.default.Popup,
        CircleMarker = _npmReactLeaflet.default.CircleMarker,
        LayersControl = _npmReactLeaflet.default.LayersControl,
        LayerGroup = _npmReactLeaflet.default.LayerGroup;
    var BaseLayer = LayersControl.BaseLayer,
        Overlay = LayersControl.Overlay;


    var datastuff = "data filler";

    // const dataBox = datastuff => {
    //     return (
    //         <div className='dataBox'>{`Data ${datastuff}`}</div>
    //     )
    // }

    var StoreLinks = function StoreLinks(props) {
        var visitStoreLink = props.visitStoreLink;


        var USE_NEW_PW = (0, _state.getClientOption)('use_new_pw', 'bool');

        var links = [{ text: 'Checklist', route: USE_NEW_PW ? 'new-checklist' : 'legacy.checklist' }, { text: 'Store', route: 'stores.edit' }, { text: 'Employees', route: 'employees' }, { text: 'Schedule', route: 'schedule' }, { text: 'PW', route: USE_NEW_PW ? 'new-payroll-worksheet' : 'reports.tps' }];

        return _react.default.createElement(
            'div',
            { className: 'store-info-links' },
            links.map(function (x, i) {
                return _react.default.createElement(
                    'a',
                    { key: i, onClick: function onClick(_) {
                            return visitStoreLink(x.route);
                        } },
                    x.text
                );
            })
        );
    };

    var comp = function comp(props) {
        var clientCode = props.clientCode,
            dcKey = props.dcKey,
            weekEndDate = props.weekEndDate,
            node = props.node,
            report = props.report,
            stores = props.stores,
            rollups = props.rollups,
            infoRollups = props.infoRollups,
            doTransition = props.doTransition,
            router = props.router;


        var mapRef = (0, _react.useRef)(null);

        // escape key to close popups
        var keyEventHandler = function keyEventHandler(_ref) {
            var key = _ref.key;

            var ESCAPE_KEYS = ['27', 'Escape'];
            if (ESCAPE_KEYS.includes(String(key)) && mapRef.current) {
                mapRef.current.leafletElement.closePopup();
            }
        };
        (0, _hooks.useEventListener)('keyup', keyEventHandler);

        // filter stores to those open at the time currently viewed
        var filteredStores = (stores || []).filter(function (s) {
            return s.lat != null && s.lon != null;
        }).filter(function (s) {
            if (s.close_date != null) {
                var date = (0, _npmMoment.default)(s.close_date).startOf('day');
                var mmt = (0, _npmMoment.default)(weekEndDate).startOf('day').add(-6 + (dcKey == null ? 0 : dcKey), 'days');
                if (date < mmt) {
                    return false;
                }
            }
            if (s.open_date != null) {
                var _date = (0, _npmMoment.default)(s.open_date).startOf('day');
                var _mmt = (0, _npmMoment.default)(weekEndDate).startOf('day').add(dcKey == null ? 0 : -6 + dcKey, 'days');
                if (_date > _mmt) {
                    return false;
                }
            }
            return true;
        });

        // handle auto zoom when stores change
        (0, _react.useLayoutEffect)(function () {
            if (filteredStores && filteredStores.length) {
                var lats = map(filteredStores, function (s) {
                    return s.lat;
                });
                var lngs = map(filteredStores, function (s) {
                    return s.lon;
                });
                var ne = { lng: max(lngs), lat: max(lats) };
                var sw = { lng: min(lngs), lat: min(lats) };
                if (mapRef.current) {
                    mapRef.current.leafletElement.fitBounds(_npmLeaflet.default.latLngBounds(_npmLeaflet.default.latLng(sw), _npmLeaflet.default.latLng(ne)), { maxZoom: 10, padding: [100, 100] });
                }
            }
        }, [props.stores]);

        // go to a route for a store
        var _visitStoreLink = function _visitStoreLink(store, route) {
            router.transitionTo(route, {
                queryParams: {
                    hierarchyNodeId: null,
                    storeId: store,
                    date: weekEndDate.format('YYYY-MM-DD')
                }
            });
        };

        // component Marker that will show up on the map for each store
        var Marker = function Marker(dp) {

            var onTitleClick = function onTitleClick(_) {
                router.transitionTo({
                    queryParams: {
                        hierarchyNodeId: null,
                        storeId: dp.id,
                        view: 'detail'
                    }
                });
            };

            var titleComponent = _react.default.createElement(
                'h4',
                { className: 'text-center pointer', onClick: onTitleClick },
                dp.code.toUpperCase(),
                ' - ',
                dp.name
            );

            var storeLinksComp = _react.default.createElement(StoreLinks, { visitStoreLink: function visitStoreLink(route) {
                    return _visitStoreLink(dp.id, route);
                } });

            if (dp.data !== undefined) {
                var hasValue = dp.data && report.map.value(dp);
                var attrs = hasValue ? {
                    fillColor: "black",
                    fillOpacity: 1,
                    stroke: false,
                    radius: 5
                } : {
                    fillColor: "white",
                    fillOpacity: 1,
                    stroke: true,
                    color: "black",
                    radius: 4
                    // not loading anymore, either does or does not have data
                };return _react.default.createElement(
                    CircleMarker,
                    _extends({ key: dp.tag, center: [dp.lat, dp.lon] }, attrs),
                    _react.default.createElement(
                        Popup,
                        null,
                        titleComponent,
                        storeLinksComp,
                        dp.data ? _react.default.createElement(
                            'div',
                            { className: 'map-marker-popup-report-container' },
                            report.tooltipContentFor(dp.data, 'map', infoRollups[dp.tag])
                        ) : _react.default.createElement(
                            'span',
                            null,
                            'No data'
                        )
                    )
                );
            } else {
                // loading
                return _react.default.createElement(
                    CircleMarker,
                    { key: dp.tag, center: [dp.lat, dp.lon], fillColor: 'white', fillOpacity: 1, stroke: true, color: 'gray', radius: 5 },
                    _react.default.createElement(
                        Popup,
                        null,
                        titleComponent,
                        storeLinksComp,
                        _react.default.createElement(
                            'span',
                            null,
                            'Loading...'
                        )
                    )
                );
            }
        };

        var getData = function getData(s) {
            var rollup = rollups[s.tag];
            if (rollup) {
                return rollup[dcKey] || null;
            }
            return rollup;
        };

        // combine stores with their rollups to create data points
        var dataPoints = filteredStores.map(function (s) {
            return _extends({}, s, {
                data: getData(s)
            });
        });

        // default center on the US
        var lat = 38;
        var lng = -97;
        var zoom = 5;

        // max world map
        var maxBounds = [[75, -175], [-60, 185]];
        var zoomScale = 0.25;

        // // colorblind toggle
        // function colorblindToggle () {
        //     if(+window.localStorage.getItem("colorBlindFriendly")){
        //         window.localStorage.setItem("colorBlindFriendly", 0);
        //         document.getElementById("colorBlindToggleButton").style.color="#187da5";
        //     } else {
        //         localStorage.setItem("colorBlindFriendly", 1);
        //         document.getElementById("colorBlindToggleButton").style.color="#d4dadc";
        //     }
        // }

        return _react.default.createElement(
            'pane',
            null,
            _react.default.createElement(_colorblindToggle.default, null),
            _react.default.createElement(
                'div',
                { className: 'mapBox' },
                _react.default.createElement(
                    Map,
                    { ref: mapRef, center: [lat, lng], zoom: zoom, minZoom: 4, maxBoundsViscosity: 0.6, maxBounds: maxBounds, zoomSnap: zoomScale, zoomDelta: zoomScale, wheelPxPerZoomLevel: 60 / zoomScale },
                    _react.default.createElement(TileLayer, {
                        url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
                        attribution: '\xA9 <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, \xA9 <a href="https://carto.com/attributions">CARTO</a>'
                    }),
                    _react.default.createElement(
                        LayersControl,
                        { position: 'topright' },
                        _react.default.createElement(
                            Overlay,
                            { checked: true, name: 'Stores' },
                            _react.default.createElement(
                                LayerGroup,
                                null,
                                _react.default.createElement(
                                    _react.Fragment,
                                    null,
                                    dataPoints.map(Marker)
                                )
                            )
                        ),
                        _react.default.createElement(
                            Overlay,
                            { checked: true, name: 'Data' },
                            _react.default.createElement(_heatmapLayer.default, {
                                points: dataPoints,
                                latitudeFunc: function latitudeFunc(dp) {
                                    return dp.lat;
                                },
                                longitudeFunc: function longitudeFunc(dp) {
                                    return dp.lon;
                                },
                                valueFunc: report.map.value,
                                radiusFunc: report.map.radius,
                                colorFunc: report.map.color
                            })
                        )
                    )
                )
            )
        );
    };

    exports.default = (0, _emberReactComponents.default)(comp);
});