define('matrix-frontend/pods/legacy/employeeaddedituser/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        controllerName: 'legacy',
        queryParams: {
            storeEmployeeId: {}
        },

        resetController: function resetController(controller, isExiting) {
            if (isExiting) {
                controller.set('storeEmployeeId', undefined);
            }
        }
    });
});