define('matrix-frontend/pods/components/tps-format/component', ['exports', 'npm:numbro'], function (exports, _npmNumbro) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        isValueOne: false,
        negative: false,

        formattedValue: Ember.computed('valueOne', 'valueTwo', 'prefix', 'format', 'negative', function () {
            this.set('isValueOne', false); // eslint-disable-line ember/no-side-effects

            var valueOne = this.get('valueOne');
            var valueTwo = this.get('valueTwo');
            var zeroFormat = this.get('zeroFormat') !== undefined ? this.get('zeroFormat') : '';

            var value = void 0;

            if (!valueOne && valueTwo) {
                value = valueTwo;
            } else {
                value = valueOne;
            }

            if (isNaN(value) && this.get('breakEarlyOnNaN')) {
                return value;
            }

            value = _npmNumbro.default.unformat(value);

            if (this.get('negative')) {
                value = -1 * value;
            }

            if (isNaN(value) || value === null) {
                // 2018-07-09 AMH Changed because of error from numbro upgrade
                // value = '';
                value = 0;
            }

            var prefix = this.get('prefix') && value !== 0 ? this.get('prefix') : '';
            var suffix = this.get('suffix') && value !== 0 ? this.get('suffix') : '';
            var format = this.get('format') ? this.get('format') : '0,0';
            _npmNumbro.default.zeroFormat(zeroFormat);

            if (format === '0,0') {
                value = (0, _npmNumbro.default)(value).format({
                    thousandSeparated: true,
                    mantissa: 0
                });
            } else if (format === '(0,0)') {
                value = (0, _npmNumbro.default)(value).format({
                    negative: 'parenthesis',
                    thousandSeparated: true,
                    mantissa: 0
                });
            } else {
                value = (0, _npmNumbro.default)(value).format(format);
            }
            value = '' + prefix + value + suffix;

            return value;
        }),

        didInsertElement: function didInsertElement() {
            var valueOne = this.get('valueOne');
            var valueTwo = this.get('valueTwo');

            if (!valueOne && valueTwo) {
                this.$().addClass('italics');
            } else {
                this.$().removeClass('italics');
            }

            this._super.apply(this, arguments);
        },
        didUpdateAttrs: function didUpdateAttrs() {
            var valueOne = this.get('valueOne');
            var valueTwo = this.get('valueTwo');

            if (!valueOne && valueTwo) {
                this.$().addClass('italics');
            } else {
                this.$().removeClass('italics');
            }

            this._super.apply(this, arguments);
        }
    });
});