define("matrix-frontend/components/react-only/colorblind-toggle", ["exports", "react"], function (exports, _react) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    // colorblind toggle

    var colorBlindToggleContainer = "";

    function colorblindToggle() {

        colorBlindToggleContainer = document.getElementById("colorBlindToggleButton");
        if (+window.localStorage.getItem("colorBlindFriendly")) {
            window.localStorage.setItem("colorBlindFriendly", 0);
            colorBlindToggleContainer.className = "colorBlindToggleOff";
        } else {
            localStorage.setItem("colorBlindFriendly", 1);
            colorBlindToggleContainer.className = "colorBlindToggleOn";
        }
    }

    function ColorBlindToggle() {

        (0, _react.useEffect)(function () {
            colorBlindToggleContainer = document.getElementById("colorBlindToggleButton");
            if (+window.localStorage.getItem("colorBlindFriendly")) {
                colorBlindToggleContainer.className = "colorBlindToggleOn";
            } else {
                colorBlindToggleContainer.className = "colorBlindToggleOff";
            }
        }, []);

        return _react.default.createElement(
            "div",
            { className: "colorBlindToggle" },
            _react.default.createElement("div", { id: "colorBlindToggleButton", onClick: colorblindToggle })
        );
    }

    exports.default = ColorBlindToggle;
});