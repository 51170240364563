define('matrix-frontend/components/react-only/employee-rollup-totals', ['exports', 'react', 'matrix-frontend/store', 'matrix-frontend/components/react-only/employee-skill-cell', 'npm:numbro'], function (exports, _react, _store, _employeeSkillCell, _npmNumbro) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var comp = function comp(_ref) {
        var activitiesById = _ref.activitiesById,
            items = _ref.items;

        var skills = Object.keys(activitiesById).reduce(function (o, i) {
            o[i] = 0;
            return o;
        }, {});
        var minimumHours = 0;
        var maximumHours = 0;
        var users = 0;
        var employees = 0;

        items.forEach(function (item) {
            if (item) {
                if (item.skills) {
                    Object.keys(item.skills).forEach(function (key) {
                        skills[key] += item.skills[key];
                    });
                }
                if (item.minmax) {
                    minimumHours += item.minmax.minimumHours || 0;
                    maximumHours += item.minmax.maximumHours || 0;
                }
                if (item.statistics) {
                    users += item.statistics ? item.statistics.users.count : 0;
                    employees += item.statistics ? item.statistics.employees.reduce(function (o, i) {
                        return o += i.count;
                    }, 0) : 0;
                }
            }
        });

        return _react.default.createElement(
            'section',
            { id: 'employee-totals', className: 'head totals' },
            _react.default.createElement(
                'div',
                { className: 'row' },
                _react.default.createElement(
                    'div',
                    { className: 'wrapper f2' },
                    _react.default.createElement(
                        'div',
                        { className: 'column text f1' },
                        'Total'
                    )
                ),
                _react.default.createElement(
                    'div',
                    { className: 'wrapper f9' },
                    _react.default.createElement(
                        'div',
                        { className: 'column f2' },
                        (0, _npmNumbro.default)(employees).format({ thousandSeparated: true })
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'column f1' },
                        minimumHours || maximumHours ? _react.default.createElement(
                            'span',
                            null,
                            (0, _npmNumbro.default)(minimumHours).format({
                                thousandSeparated: true
                            }),
                            '\u2013',
                            (0, _npmNumbro.default)(maximumHours).format({
                                thousandSeparated: true
                            })
                        ) : ''
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'column f3' },
                        _react.default.createElement(_employeeSkillCell.default, {
                            skills: skills,
                            activitiesById: activitiesById,
                            displayValue: true
                        })
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'column f2' },
                        (0, _npmNumbro.default)(users).format({ thousandSeparated: true })
                    )
                )
            )
        );
    };
    exports.default = comp;
});