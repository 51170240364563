define('matrix-frontend/pods/password/show/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        queryParams: {
            token: {
                refreshModel: true
            }
        },
        model: function model(params, transition) {
            var _transition$params$pa = transition.params['password.show'],
                email = _transition$params$pa.email,
                clientCode = _transition$params$pa.clientCode,
                token = transition.queryParams.token;

            return { email: email, token: token, clientCode: clientCode };
        }
    });
});