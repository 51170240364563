define('matrix-frontend/pods/reports/show/controller', ['exports', 'matrix-frontend/mixins/base-controller', 'npm:underscore'], function (exports, _baseController, _npmUnderscore) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_baseController.default, {
        displayVariance: true,

        loading: Ember.computed('model', function () {
            return this.model === null;
        }),

        isDataEmpty: Ember.computed('model.{sales-equationData,sales-vs-forecastData,hours-vs-optimalData}', function () {
            if (!_npmUnderscore.default.isEmpty(this.get('model.sales-equationData')) || !_npmUnderscore.default.isEmpty(this.get('model.sales-vs-forecastData.daily')) || !_npmUnderscore.default.isEmpty(this.get('model.hours-vs-optimalData'))) {
                return false;
            } else {
                return true;
            }
        }),

        actions: {
            toggleVariance: function toggleVariance() {
                this.set('displayVariance', !this.get('displayVariance'));
            }
        }
    });
});