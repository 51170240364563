define('matrix-frontend/helpers/unavailable', ['exports', 'npm:moment'], function (exports, _npmMoment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.unavailable = unavailable;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    function unavailable(params /*, hash*/) {
        var _params = _slicedToArray(params, 4),
            availability = _params[0],
            segment = _params[1],
            hourInfo = _params[2],
            hoo = _params[3];

        var dotw = hourInfo.dotw;
        var d = void 0;
        var h = void 0;

        if (availability && availability.exceptions && availability.exceptions[dotw]) {
            d = availability.exceptions[dotw];
        } else if (availability && availability.default && availability.default[dotw]) {
            d = availability.default[dotw];
        } else {
            return false;
        }

        if (!hoo) {
            return false;
        }

        // Block out availability for times when store is closed
        // const s = hourInfo.date.clone().set({hour, minute});
        // if (s.isBefore(hoo.privateOpen)) { return true; }
        // if (s.isAfter(hoo.privateClose)) { return true; }

        var availabilityCheck = d.map(function (i) {
            var start = segment.format('YYYY-MM-DD') + ' ' + i.start;
            var end = segment.format('YYYY-MM-DD') + ' ' + i.end;

            if (segment.isBetween(start, end, null, '[)')) {
                return i.isAvailable;
            }
            return null;
        }).filter(function (x) {
            return x !== null;
        });

        if (availabilityCheck.length > 0) {
            return !availabilityCheck[0];
        }
    }

    exports.default = Ember.Helper.helper(unavailable);
});