define('matrix-frontend/utils/logging', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var HANDLERS = ['log', 'info', 'debug'];
    var logHolder = {};

    var disableLogging = exports.disableLogging = function disableLogging() {
        HANDLERS.forEach(function (x) {
            if (!logHolder[x]) {
                logHolder[x] = window.console[x];
                window.console[x] = function () {};
            }
        });
    };

    var enableLogging = exports.enableLogging = function enableLogging() {
        HANDLERS.forEach(function (x) {
            if (logHolder[x]) {
                window.console[x] = logHolder[x];
                logHolder[x] = null;
            }
        });
    };
});