define('matrix-frontend/reducers/node', ['exports', 'matrix-frontend/constants', 'matrix-frontend/store', 'matrix-frontend/utils/state', 'matrix-frontend/utils/cleaners', 'matrix-frontend/utils/date-helper', 'npm:moment', 'matrix-frontend/actions/_types'], function (exports, _constants, _store6, _state, _cleaners, _dateHelper, _npmMoment, _types) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var initialWeek = {
        employees: [],
        scheduleStatus: null
    };

    var initialStore = {
        id: null,
        name: null,
        code: null,
        weeks: {}
    };

    var initialHierarchy = {
        id: null,
        name: null,
        code: null,
        children: [],
        stores: [],
        weeks: {}
    };

    var initialState = {
        stores: {},
        hierarchies: {},
        gathered: {
            hierarchies: {
                exclusions: new Set(),
                data: {}
            },
            hoursOfOperation: {},
            reports: {}
        }
    };

    var nodeStore = function nodeStore(state, action) {
        var type = action.type,
            payload = action.payload;

        switch (type) {
            case _types.NODE_CLEAR_FORECAST:
                {
                    var storeTag = payload.storeTag,
                        weekEndDate = payload.weekEndDate;
                    var stores = state.stores;

                    var _store = _extends({}, stores[storeTag]);
                    _store.weeks[weekEndDate].salesForecast = [];
                    stores[storeTag] = _store;
                    return _extends({}, state, {
                        stores: stores
                    });
                }
            case _types.NODE_CLEAR_TASK_HOURS:
                {
                    var _storeTag = payload.storeTag,
                        _weekEndDate = payload.weekEndDate;
                    var _stores = state.stores;

                    var _store2 = _extends({}, _stores[_storeTag]);
                    _store2.weeks[_weekEndDate].taskHours = {};
                    _stores[_storeTag] = _store2;
                    return _extends({}, state, {
                        stores: _stores
                    });
                }
            case _types.NODE_CLEAR_ACTUAL_LABOR:
                {
                    var _storeTag2 = payload.storeTag,
                        _weekEndDate2 = payload.weekEndDate;
                    var _stores2 = state.stores;

                    var _store3 = _extends({}, _stores2[_storeTag2]);
                    _store3.weeks[_weekEndDate2].actualLabor = {};
                    _stores2[_storeTag2] = _store3;
                    return _extends({}, state, {
                        stores: _stores2
                    });
                }
            case _types.NODE_SETUP_HOURS:
                {
                    var _storeTag3 = payload.storeTag,
                        _weekEndDate3 = payload.weekEndDate;
                    var _stores3 = state.stores;

                    var _store4 = _extends({}, _stores3[_storeTag3]);
                    if (!_store4.weeks) {
                        _store4.weeks = {};
                    }
                    if (!_store4.weeks[_weekEndDate3]) {
                        _store4.weeks[_weekEndDate3] = {};
                    }
                    _store4.weeks[_weekEndDate3].hours = {};
                    _stores3[_storeTag3] = _store4;
                    return _extends({}, state, {
                        stores: _stores3
                    });
                }

            case _types.NODE_EE_LOAN_SHIFTS:
                {
                    var employeeId = payload.employeeId,
                        records = payload.records,
                        _weekEndDate4 = payload.weekEndDate,
                        fullTag = payload.fullTag;
                    var _stores4 = state.stores;

                    var _store5 = _extends({}, _stores4[fullTag]);

                    _stores4[fullTag].weeks[_weekEndDate4].employees.map(function (ee) {
                        if (ee.id === employeeId) {
                            ee.loanShifts = {};
                            records.forEach(function (record) {
                                ee.loanShifts = _extends({}, ee.loanShifts, _defineProperty({}, record.meta.dotw, record));
                            });
                        }
                        return ee;
                    });

                    return _extends({}, state, {
                        stores: _stores4
                    });
                }

            // case types.NODE_REFRESH_EMPLOYEES: {
            //     const {weekEndDate, fullTag} = payload

            //     let {stores} = state

            //     stores[fullTag].weeks[weekEndDate].employees = null

            //     return {
            //         ...state,
            //         stores,
            //     }
            // }

            case _types.NODE_INTAKE_HIERARCHY:
                {
                    var meta = payload.meta,
                        results = payload.results;
                    var args = meta.args,
                        is_last = meta.is_last;
                    var client_code = args.client_code,
                        tag = args.tag;


                    var _fullTag = client_code + '.' + tag;
                    var hierarchies = _extends({}, state.gathered.hierarchies);

                    var exclusions = hierarchies.exclusions || new Set();
                    var data = hierarchies.data || {};

                    exclusions.add(client_code + '.' + tag);
                    results.forEach(function (hierarchy) {
                        data[hierarchy.tag] = hierarchy;
                    });

                    return _extends({}, state, {
                        gathered: _extends({}, state.gathered, {
                            hierarchies: {
                                exclusions: exclusions,
                                data: data,
                                complete: is_last
                            }
                        })
                    });
                }

            case _types.NODE_INTAKE_HOO_GATHER:
                {
                    var _meta = payload.meta,
                        _results = payload.results;
                    var _args = _meta.args,
                        _is_last = _meta.is_last;
                    var _client_code = _args.client_code,
                        _tag = _args.tag,
                        week_end_date = _args.week_end_date;


                    var _fullTag2 = _client_code + '.' + _tag;
                    var hoo = _extends({}, state.gathered.hoursOfOperation);

                    var weekHoo = hoo[week_end_date] || {
                        data: {},
                        exclusions: new Set()
                    };

                    var _stores5 = _extends({}, state.stores);

                    weekHoo.exclusions.add(_fullTag2);
                    Object.keys(_results).forEach(function (storeTag) {
                        var code = storeTag.split('.')[2];
                        var currentStore = _extends({}, initialStore, _stores5[storeTag], {
                            code: code
                        });
                        var existingWeekData = currentStore.weeks[week_end_date] || {};
                        var week = _extends({}, initialWeek, existingWeekData);
                        week.hours = (0, _cleaners.clean_keys)(_results[storeTag]);
                        // Object.keys(week.hours).forEach(docw => {
                        //     week.hours[docw].exception = week.hours[docw].isException
                        //     delete week.hours[docw].isException
                        // })
                        // weekHoo.data[storeTag] = week.hours
                        currentStore.weeks = _extends({}, currentStore.weeks, _defineProperty({}, week_end_date, week));
                        _stores5[storeTag] = currentStore;
                    });
                    weekHoo.complete = _is_last;
                    weekHoo.timestamp = (0, _npmMoment.default)();

                    hoo[week_end_date] = weekHoo;

                    return _extends({}, state, {
                        gathered: _extends({}, state.gathered, {
                            hoursOfOperation: hoo
                        }),
                        stores: _stores5
                    });
                }

            case _types.NODE_INTAKE_REPORT_DATA:
                {
                    var _meta2 = payload.meta,
                        _results2 = payload.results;
                    var _args2 = _meta2.args,
                        _is_last2 = _meta2.is_last;
                    var _client_code2 = _args2.client_code,
                        _tag2 = _args2.tag,
                        report_type = _args2.report_type,
                        focus = _args2.focus,
                        _week_end_date = _args2.week_end_date;


                    var _fullTag3 = _client_code2 + '.' + _tag2;
                    var reports = _extends({}, state.gathered.reports);

                    var thisReportData = reports[report_type] || {};
                    var thisFocusData = thisReportData[focus] || {};
                    var thisWeekData = thisFocusData[_week_end_date] || {};

                    var _exclusions = thisWeekData.exclusions || new Set();
                    var _data = thisWeekData.data || {};

                    _exclusions.add(_fullTag3);
                    Object.keys(_results2).forEach(function (storeTag) {
                        _data[_client_code2 + '.' + storeTag] = _results2[storeTag];
                    });

                    var complete = _is_last2 != null ? _is_last2 : thisWeekData.complete || false;
                    thisFocusData[_week_end_date] = {
                        exclusions: _exclusions,
                        data: _data,
                        complete: complete
                    };
                    thisReportData[focus] = thisFocusData;
                    reports[report_type] = thisReportData;

                    // console.info(reportData)

                    return _extends({}, state, {
                        gathered: _extends({}, state.gathered, {
                            reports: reports
                        })
                    });
                }

            case _types.PW_INTAKE_COST:
                {
                    var _meta3 = payload.meta,
                        _results3 = payload.results;
                    var _client_code3 = _results3.client_code,
                        reportData = _results3.data,
                        _tag3 = _results3.tag,
                        _focus = _results3.focus,
                        _week_end_date2 = _results3.end_date;

                    var _report_type = _meta3.namespace[0];

                    var _fullTag4 = _client_code3 + '.' + _tag3;
                    var _reports = _extends({}, state.gathered.reports);
                    var isStore = _tag3.match(/^store\..+/) != null;

                    var _thisReportData = _reports[_report_type] || {};
                    var _thisFocusData = _thisReportData[_focus] || {};
                    var _thisWeekData = _thisFocusData[_week_end_date2] || {};

                    var _exclusions2 = _thisWeekData.exclusions || new Set();
                    var _data2 = _thisWeekData.data || {};
                    var computed = _thisWeekData.computed || {};
                    var distribution = reportData.distribution || {};

                    _exclusions2.add(_fullTag4);

                    console.group("PW_INTAKE_COST");
                    console.log("meta", _meta3);
                    console.log("isStore", isStore);
                    console.log("client_code", _client_code3);
                    console.log("tag", _tag3);
                    console.log("focus", _focus);
                    console.log("week_end_date", _week_end_date2);
                    console.log("fullTag", _fullTag4);
                    console.log("report_type", _report_type);
                    console.log("reportData", reportData);
                    console.groupEnd("PW_INTAKE_COST");

                    if (isStore) {
                        var storeData = _data2[_client_code3 + '.' + _tag3] || {};
                        storeData.details = reportData.records;
                        _data2[_client_code3 + '.' + _tag3] = storeData;
                    } else {
                        Object.keys(reportData.records).forEach(function (storeTag) {
                            _data2[_client_code3 + '.' + storeTag] = reportData.records[storeTag];
                        });
                    }

                    var _complete = true;
                    computed[_tag3] = reportData.computed || {};

                    _thisFocusData[_week_end_date2] = {
                        exclusions: _exclusions2,
                        data: _data2,
                        complete: _complete,
                        computed: computed,
                        distribution: distribution
                    };
                    _thisReportData[_focus] = _thisFocusData;
                    _reports[_report_type] = _thisReportData;

                    // console.info(reportData)

                    return _extends({}, state, {
                        gathered: _extends({}, state.gathered, {
                            reports: _reports
                        })
                    });
                }

            case _types.NODE_INTAKE:
                {
                    var _results4 = payload.results,
                        _meta4 = payload.meta;

                    if (
                    // (meta.namespace.find(x => x === 'hierarchy') ||
                    //     !meta.channel[1].match(/^store\..+/)) &&
                    !_meta4.channel[1].match(/^store\..+/) && _meta4.operation === 0) {
                        // const tag = `${meta.channel[0]}.${meta.extra[0]}`;
                        var _tag4 = _meta4.channel.join('.');
                        var metaChannel = _meta4.channel[1] || _meta4.extra[0] || '';
                        var parts = metaChannel.split('.');
                        var code = parts[1];

                        var hierarchy = _extends({}, initialHierarchy, state.hierarchies[_tag4], {
                            code: code

                            // Inject hierarchy details
                        });if (_meta4.namespace.join(' ') == 'hierarchy details' && _meta4.operation === 0) {
                            if (_results4.ancestor_tags != null) {
                                _results4.ancestorTags = _results4.ancestor_tags;
                            }
                            delete _results4.ancestor_tags;
                            hierarchy = _extends({}, hierarchy, _results4);
                        }

                        var _weekEndDate5 = _meta4.extra && _meta4.extra.length > 0 ? _meta4.extra[0] : _meta4.params && _meta4.params.week_end_date ? _meta4.params.week_end_date : null;

                        if (_weekEndDate5) {
                            var existingWeekData = state.hierarchies[_tag4] && state.hierarchies[_tag4].weeks[_weekEndDate5] ? state.hierarchies[_tag4].weeks[_weekEndDate5] : {};

                            var week = _extends({}, initialWeek, existingWeekData);

                            // Sales at Risk
                            if (_meta4.namespace.find(function (x) {
                                return x === 'salesatrisk';
                            }) && _meta4.operation === 0) {
                                // console.group(meta.channel[1])
                                // console.debug('week', week)
                                // console.debug('results', results.data)
                                // console.groupEnd()

                                week = _extends({}, week, {
                                    salesAtRisk: _results4.data
                                });
                            }

                            // Inject schedule info
                            if (_meta4.namespace.find(function (x) {
                                return x === 'schedule';
                            }) && _meta4.namespace.find(function (x) {
                                return x === 'status';
                            }) && _meta4.operation === 0) {
                                var details = (0, _cleaners.clean_keys)(_results4);
                                if (details) {
                                    week.scheduleStatus = details;
                                } else {
                                    week.scheduleStatus = {
                                        status: {
                                            // unknown: 0,
                                        }
                                    };
                                }
                            }

                            // Inject skill rollup
                            if (_meta4.namespace.find(function (x) {
                                return x === 'hierarchy';
                            }) && _meta4.namespace.find(function (x) {
                                return x === 'skills' || 'minmax' || 'statistics';
                            }) && _meta4.operation === 0) {
                                var _details = (0, _cleaners.clean_keys)(_results4);
                                var obj = _meta4.namespace.slice(-1)[0];
                                if (_details) {
                                    week[obj] = _details;
                                } else {
                                    week[obj] = {};
                                }
                            }

                            hierarchy.weeks = _extends({}, hierarchy.weeks);
                            hierarchy.weeks[_weekEndDate5] = week;
                        }

                        var _hierarchies = _extends({}, state.hierarchies);
                        _hierarchies[_tag4] = hierarchy;

                        return _extends({}, state, {
                            hierarchies: _hierarchies
                        });
                    } else {
                        var _tag5 = _meta4.channel.join('.');
                        var _parts = _meta4.channel[1].split('.');
                        var _code = _parts[1];

                        var _weekEndDate6 = _meta4.extra && _meta4.extra.length > 0 ? _meta4.extra[0] : _meta4.params && _meta4.params.week_end_date ? _meta4.params.week_end_date : null;

                        var currentStore = _extends({}, initialStore, state.stores[_tag5], {
                            code: _code
                            // Inject store details
                        });if (_meta4.namespace.find(function (x) {
                            return x === 'details';
                        }) && _meta4.operation === 0) {
                            _results4.ancestorTags = _results4.ancestor_tags;
                            delete _results4.ancestor_tags;
                            currentStore = _extends({}, currentStore, _results4);
                        }

                        if (_weekEndDate6) {
                            var _existingWeekData = state.stores[_tag5] && state.stores[_tag5].weeks[_weekEndDate6] ? state.stores[_tag5].weeks[_weekEndDate6] : {};

                            var _week = _extends({}, initialWeek, _existingWeekData);

                            // Inject employee data
                            if (_meta4.namespace.find(function (x) {
                                return x === 'employee';
                            }) && _meta4.operation === 0 && _results4.data) {
                                console.debug('// Inject employee data');
                                if (_results4) {
                                    var a = ['id', 'availability'];
                                    _week.employees = _results4.data.map(function (x) {
                                        var ee = (0, _cleaners.clean_keys)((0, _cleaners.filter_keys)(x, a));

                                        return ee;
                                    });
                                }
                            }

                            // Inject hours of operation
                            if (_meta4.namespace.find(function (x) {
                                return x === 'hours';
                            }) && (_meta4.operation === 0 || _meta4.operation === 1)) {
                                console.debug('// Inject hours of operation');
                                if (_results4) {
                                    if (_results4.cleaned) {
                                        _week.hours = _results4.cleaned;
                                    } else {
                                        var hours = _results4.default.reduce(function (o, i) {
                                            o[i.dotw] = _extends({}, (0, _cleaners.clean_keys)(i), {
                                                isException: false
                                            });
                                            return o;
                                        }, {});

                                        _results4.exceptions.forEach(function (exception) {
                                            var dotw = (0, _dateHelper.getDayOfClientWeek)(exception.date) + 1;
                                            hours[dotw] = _extends({}, (0, _cleaners.clean_keys)(exception), {
                                                isException: true
                                            });
                                        });
                                        _week.hours = hours;
                                    }
                                }
                            }

                            // Inject checklist info
                            if (_meta4.namespace.find(function (x) {
                                return x === 'checkliststatus';
                            }) && _meta4.operation === 0) {
                                console.debug('// Inject checklist info');
                                if (_results4) {
                                    var _clean_keys = (0, _cleaners.clean_keys)(_results4),
                                        publishReady = _clean_keys.publishReady,
                                        steps = _clean_keys.steps;

                                    _week.checklist = {
                                        publishReady: publishReady,
                                        steps: steps
                                    };
                                }
                            }

                            // Inject schedule info
                            if (_meta4.namespace.find(function (x) {
                                return x === 'schedule';
                            }) && _meta4.namespace.find(function (x) {
                                return x === 'status';
                            }) && _meta4.operation === 0) {
                                console.debug('// Inject schedule info');
                                if (_results4) {
                                    var _details2 = (0, _cleaners.clean_keys)(_results4);
                                    if (_details2.status) {
                                        _details2.status = _constants.ScheduleStatus[_details2.status.toUpperCase()];
                                        _week.scheduleStatus = _details2;
                                    } else {
                                        _week.scheduleStatus = {
                                            status: _constants.ScheduleStatus.UNKNOWN
                                        };
                                    }
                                }
                            }

                            // Sales forecast
                            if (_meta4.namespace.find(function (x) {
                                return x === 'forecasts';
                            })) {
                                console.debug('// Sales forecast');
                                if (_results4) {
                                    _week.salesForecast = _results4.data;
                                }
                            }

                            // Optimal labor
                            if (_meta4.namespace.find(function (x) {
                                return x === 'optimallabor';
                            }) && _meta4.operation === 0) {
                                console.debug('// Optimal labor');
                                if (_results4) {
                                    var optimalLabor = _results4.data ? _results4.data.reduce(function (o, i) {
                                        var dotw = i.dotw + 1;
                                        o[dotw] = {};

                                        var segment = 0;
                                        var currentMoment = (0, _npmMoment.default)().startOf('day');
                                        var endMoment = (0, _npmMoment.default)().endOf('day');
                                        var prev = 0;
                                        var last = null;
                                        while (currentMoment.isBefore(endMoment, 'minutes')) {
                                            var _hours = currentMoment.hours();
                                            var minutes = currentMoment.minutes();
                                            var value = i.labor[_hours] && i.labor[_hours][minutes] ? i.labor[_hours][minutes] : 0;

                                            if (o[dotw][_hours] === undefined) {
                                                o[dotw][_hours] = {};
                                            }

                                            o[dotw][_hours][minutes] = {
                                                value: value,
                                                prev: prev - value,
                                                next: 0
                                            };

                                            if (last) {
                                                last.next = value - prev;
                                            }

                                            currentMoment.add(15, 'minutes');
                                            segment += 1;
                                            prev = value;
                                            last = o[dotw][_hours][minutes];
                                        }

                                        return o;
                                    }, {}) : {};
                                    _week.optimalLabor = optimalLabor;
                                }
                            }

                            // Actual labor
                            if (_meta4.namespace.find(function (x) {
                                return x === 'actuallabor';
                            })) {
                                console.debug('// Actual labor');
                                if (_results4) {
                                    // console.info({actualLabor: results})
                                    var actualLabor = _results4.data ? _results4.data.reduce(function (o, i) {
                                        o[i.dotw] = i.labor;
                                        //   o[i.dotw]['segmentTotal'] = i.segment_total
                                        o[i.dotw]['shiftTotal'] = i.shift_total;
                                        return o;
                                    }, {}) : {};

                                    _week.actualLabor = actualLabor;
                                }
                            }

                            // Sales at Risk
                            if (_meta4.namespace.find(function (x) {
                                return x === 'salesatrisk';
                            }) && _meta4.operation === 0) {
                                console.debug('// Sales at Risk');
                                // console.group(meta.channel[1])
                                // console.debug('week', week)
                                // console.debug('results', results.data)
                                // console.groupEnd()

                                if (_results4) {
                                    _week = _extends({}, _week, {
                                        salesAtRisk: _results4.data
                                    });
                                }
                            }

                            var USE_NEW_PW = (0, _state.getClientOption)('use_new_pw', 'bool');

                            // Task hours
                            if (_meta4.namespace.find(function (x) {
                                return x === 'taskhours';
                            })) {
                                console.debug('// Task hours');
                                if (_results4) {
                                    var _data3 = _results4.data || _results4;
                                    var taskHours = _data3 ? Object.keys(_data3).reduce(function (o, i) {
                                        var datum = _data3[i];
                                        var dotw = USE_NEW_PW ? parseInt(i) : (0, _npmMoment.default)(i).weekday();
                                        o[dotw + 1] = (0, _cleaners.clean_keys)(datum);
                                        return o;
                                    }, {}) : {};
                                    // console.info({USE_NEW_PW, taskHours})
                                    _week.taskHours = taskHours;
                                }
                            }

                            // Inject skill rollup
                            if (_meta4.namespace.find(function (x) {
                                return x === 'hierarchy';
                            }) && _meta4.namespace.find(function (x) {
                                return x === 'skills' || 'minmax' || 'statistics';
                            }) && _meta4.operation === 0) {
                                if (_results4) {
                                    var _details3 = (0, _cleaners.clean_keys)(_results4);
                                    var _obj = _meta4.namespace.slice(-1)[0];

                                    if (_details3) {
                                        _week[_obj] = _details3;
                                    } else {
                                        _week[_obj] = {};
                                    }
                                }
                            }

                            currentStore.weeks = _extends({}, currentStore.weeks);
                            currentStore.weeks[_weekEndDate6] = _week;
                        }

                        var _stores6 = _extends({}, state.stores);
                        _stores6[_tag5] = currentStore;

                        return _extends({}, state, {
                            stores: _stores6
                        });
                    }
                }

            case _types.NODE_EE_UPDATE_AVAILABILITY:
                {
                    var record = payload.record,
                        _weekEndDate7 = payload.weekEndDate,
                        availabilityType = payload.availabilityType,
                        _storeTag4 = payload.storeTag,
                        _employeeId = payload.employeeId,
                        dotw = payload.dotw,
                        loading = payload.loading,
                        failed = payload.failed;

                    try {
                        var employee = _extends({}, state.stores[_storeTag4].weeks[_weekEndDate7].employees.find(function (x) {
                            return x.id === _employeeId;
                        }));
                        var _stores7 = _extends({}, state.stores);

                        var newRecord = record.map(function (x) {
                            Ember.set(x, 'isAllDay', x.fromOpen && x.untilClose);
                            if (x.activityCode && x.activityCode !== 'A' && x.activityCode !== 'N') {
                                var _store$getState = _store6.store.getState(),
                                    availabilityActivities = _store$getState.general.availabilityActivities;

                                var reason = availabilityActivities.find(function (y) {
                                    return y.code === x.activityCode;
                                });
                                if (reason) {
                                    Ember.set(x, 'reasonCode', x.activityCode);
                                    Ember.set(x, 'reason', reason.name);
                                }
                            }
                            if (x.fromOpen) {
                                Ember.set(x, 'start', (0, _npmMoment.default)(x.date).set({ hours: 0, minutes: 0 }));
                            }
                            if (x.untilClose) {
                                Ember.set(x, 'end', (0, _npmMoment.default)(x.date).set({ hours: 23, minutes: 59 }));
                            }
                            return x;
                        });

                        newRecord.loading = loading || false;
                        newRecord.failed = failed || false;

                        Ember.set(employee.availability[availabilityType], dotw, newRecord);
                        _stores7[_storeTag4].weeks[_weekEndDate7].employees = _stores7[_storeTag4].weeks[_weekEndDate7].employees.map(function (x) {
                            return x.id === _employeeId ? employee : x;
                        });

                        return _extends({}, state, {
                            stores: _stores7
                        });
                    } catch (e) {
                        console.error(e);
                        return _extends({}, state);
                    }
                }

            default:
                {
                    return state || initialState;
                }
        }
    };

    exports.default = nodeStore;
});