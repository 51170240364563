define('matrix-frontend/pods/components/rolescope-management-page/component', ['exports', 'ember-redux'], function (exports, _emberRedux) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var stateToComputed = function stateToComputed(state) {
        return {
            currentUser: state.user.currentUser,
            clientCode: state.cube.clientCode,
            client: state.client,
            scopes: state.noclient.scopes
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {};
    };

    var Comp = Ember.Component.extend({
        redux: Ember.inject.service(),
        // modalService: service(),
        router: Ember.inject.service('-routing'),

        currentClient: Ember.computed('clientCode', 'client', function () {
            if (!this.clientCode) {
                return null;
            }
            return this.client[this.clientCode];
        }),

        actions: {
            goToIndex: function goToIndex() {
                this.router.transitionTo('index');
            }
        }

    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});