define('matrix-frontend/utils/confirm-navigate', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        confirmBeforeModel: function confirmBeforeModel(controller, transition) {
            var formUpdated = controller.get('formUpdated');
            if (formUpdated) {
                if (formUpdated && !confirm("You have Unsaved changes.\nTo Discard your changes and continue, press 'OK'\nTo Go Back, press 'Cancel'")) {
                    transition.abort();
                    return false;
                } else {
                    return true;
                }
            }

            return false;
        },
        confirmWillTransition: function confirmWillTransition(controller, route, transition) {
            var formUpdated = controller.get('formUpdated');
            if (formUpdated) {
                var routeName = route.get('routeName');
                if (routeName !== transition.targetName) {
                    if (formUpdated === true && !confirm("You have Unsaved changes.\nTo Discard your changes and continue, press 'OK'\nTo Go Back, press 'Cancel'")) {
                        transition.abort();
                        return false;
                    } else {
                        return true;
                    }
                }
            }
            return false;
        }
    };
});