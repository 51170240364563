define('matrix-frontend/components/swagger-ui', ['exports', 'ember-swagger-ui/components/swagger-ui', 'npm:swagger-ui-dist'], function (exports, _swaggerUi, _npmSwaggerUiDist) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _swaggerUi.default;
    }
  });
});