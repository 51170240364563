define('matrix-frontend/components/react-only/schedule-day', ['exports', 'matrix-frontend/handlers/schedule-edit', 'matrix-frontend/store', 'matrix-frontend/utils/shift', 'npm:moment', 'react'], function (exports, _scheduleEdit, _store, _shift, _npmMoment, _react) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var ShiftFullTime = function ShiftFullTime(props) {
        var shift = props.shift,
            employee = props.employee,
            dotw = props.dotw,
            weekEndDate = props.weekEndDate,
            availability = props.availability,
            currentStore = props.currentStore;

        var notLoan = !employee || availability && currentStore && availability[0] && availability[0].storeCode === currentStore.code;
        var blocks = shift ? shift.blocks : [];
        var shiftBlocks = blocks.sortBy('start');
        var firstBlock = shiftBlocks.length > 0 ? shiftBlocks[0] : null;
        var lastBlock = shiftBlocks.length > 0 ? shiftBlocks.slice(-1)[0] : null;

        var shiftTime = firstBlock && lastBlock && notLoan ? _react.default.createElement(
            'div',
            { className: 'shift-full-time' },
            _react.default.createElement(
                'span',
                null,
                firstBlock.start.format(firstBlock.start.minutes() === 0 ? 'ha' : 'h:mma')
            ),
            _react.default.createElement(
                'span',
                null,
                '-'
            ),
            _react.default.createElement(
                'span',
                null,
                lastBlock.end.format(lastBlock.end.minutes() === 0 ? 'ha' : 'h:mma')
            )
        ) : _react.default.createElement(
            'div',
            { className: 'shift-full-time' },
            notLoan ? (0, _shift.isTerminated)({ shift: shift, employee: employee, dotw: dotw, weekEndDate: weekEndDate }) ? 'Termed' : (0, _shift.notStarted)({ shift: shift, employee: employee, dotw: dotw, weekEndDate: weekEndDate }) ? 'Not started' : (0, _shift.unavailableReason)(availability) : 'On loan'
        );

        return shiftTime;
    };

    var ShiftInfoPanel = function ShiftInfoPanel(props) {
        var prev = null;
        var _props$shift = props.shift,
            blocks = _props$shift.blocks,
            meta = _props$shift.meta,
            errors = _props$shift.errors,
            activitiesById = props.activitiesById,
            published = props.published;

        var currentUser = _store.store.getState().user.currentUser;

        if (!activitiesById) {
            return null;
        }

        var flags = errors && errors.length > 0 ? _react.default.createElement(
            'div',
            { className: 'row' },
            _react.default.createElement(
                'div',
                { className: 'column f3 text' },
                '\xA0'
            ),
            _react.default.createElement(
                'div',
                { className: 'column f4 text' },
                errors.length,
                ' flag',
                errors.length > 1 ? 's' : ''
            )
        ) : '';
        var format = meta.edited && (0, _npmMoment.default)(meta.edited).minutes() === 0 ? 'ha' : 'h:mma';
        var edited = meta.editedByUsername ? _react.default.createElement(
            _react.default.Fragment,
            null,
            _react.default.createElement(
                'div',
                { className: 'row' },
                _react.default.createElement(
                    'div',
                    { className: 'column f3 text' },
                    'Last edit'
                ),
                _react.default.createElement(
                    'div',
                    { className: 'column f4 text' },
                    _react.default.createElement(
                        'em',
                        null,
                        meta.editedByUsername,
                        ' @ ',
                        (0, _npmMoment.default)(meta.edited).format(format)
                    )
                )
            )
        ) : '';

        var communicated = published ? !meta.communicatedBy && meta.saved && meta.communicated ? _react.default.createElement(
            'div',
            { className: 'row' },
            _react.default.createElement(
                'div',
                { className: 'column f3 text' },
                'Emailed'
            ),
            _react.default.createElement(
                'div',
                { className: 'column f4 text' },
                _react.default.createElement(
                    'em',
                    null,
                    (0, _npmMoment.default)(meta.communicated).format(format)
                )
            )
        ) : _react.default.createElement(
            'div',
            { className: 'row' },
            _react.default.createElement(
                'div',
                { className: 'column f4 text' + (meta.communicated ? '' : ' light-purple-text weight-heavy') },
                _react.default.createElement(
                    'em',
                    null,
                    'Not emailed'
                )
            )
        ) : '';

        var shiftLength = blocks.filter(function (x) {
            var activity = activitiesById[x.activityId];
            return !activity.isCallin;
        }).reduce(function (o, i) {
            var length = (i.end - i.start) / 1000 / 60 / 60;
            return o + length;
        }, 0);

        var adminOnlyInfo = currentUser.isMatrixAdmin ? _react.default.createElement(
            _react.default.Fragment,
            null,
            _react.default.createElement(
                'em',
                { style: { color: 'gray', fontSize: '0.7em' }, className: 'breathe-l-10' },
                'Admin only'
            ),
            _react.default.createElement(
                'div',
                { className: 'row' },
                _react.default.createElement(
                    'div',
                    { className: 'column f3 text' },
                    'Shift Id:',
                    _react.default.createElement('br', null),
                    meta.id
                )
            ),
            _react.default.createElement(
                'div',
                { className: 'row' },
                _react.default.createElement(
                    'div',
                    { className: 'column f3 text' },
                    'Updated:',
                    _react.default.createElement('br', null),
                    meta.updated ? meta.updated.format('YYYY-MM-DD h:mma') : ''
                )
            )
        ) : '';

        return _react.default.createElement(
            'div',
            { className: 'shift-info-panel' },
            blocks.map(function (block) {
                var mealBreak = prev && !prev.end.isSame(block.start) ? _react.default.createElement(
                    'div',
                    { className: 'row' },
                    _react.default.createElement(
                        'div',
                        { className: 'column f3 text' },
                        'Meal break'
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'column f4 text' },
                        prev.end.format('h:mma'),
                        ' - ',
                        block.start.format('h:mma')
                    )
                ) : '';
                prev = block;
                var text = block.start.format('h:mma') + ' - ' + block.end.format('h:mma');

                var key = 'shift-' + meta.id + '-shiftBlock-' + block.start.format('HH:mm');
                return _react.default.createElement(
                    _react.default.Fragment,
                    { key: key },
                    mealBreak,
                    _react.default.createElement(
                        'div',
                        { className: 'row' },
                        _react.default.createElement(
                            'div',
                            { className: 'column f3 text' },
                            activitiesById[block.activityId].name
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'column f4 text' },
                            text
                        )
                    )
                );
            }),
            _react.default.createElement(
                _react.default.Fragment,
                null,
                _react.default.createElement(
                    'div',
                    { className: 'row' },
                    _react.default.createElement(
                        'div',
                        { className: 'column f3 text' },
                        'Paid hrs'
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'column f4 text' },
                        _react.default.createElement(
                            'em',
                            null,
                            shiftLength
                        )
                    )
                )
            ),
            edited,
            communicated,
            flags,
            adminOnlyInfo
        );
    };

    var comp = function comp(props) {
        var dotw = props.dotw,
            weekEndDate = props.weekEndDate,
            shifts = props.shifts,
            activities = props.activities,
            activitiesById = props.activitiesById,
            employee = props.employee,
            employees = props.employees,
            canEdit = props.canEdit,
            noSchedule = props.noSchedule,
            editMode = props.editMode,
            printMode = props.printMode,
            activeShift = props.activeShift,
            unassigned = props.unassigned,
            currentStore = props.currentStore;

        var _store$getState$sched = _store.store.getState().scheduleEdit,
            assignableEmployeeIds = _store$getState$sched.assignableEmployeeIds,
            availableEmployeeIds = _store$getState$sched.availableEmployeeIds,
            skilledEmployeeIds = _store$getState$sched.skilledEmployeeIds,
            smartAssignEmployee = _store$getState$sched.smartAssignEmployee,
            published = _store$getState$sched.published;

        var shift = shifts && noSchedule == false ? shifts[dotw] : null;
        var availability = employee ? employee.availability.exceptions && employee.availability.exceptions[dotw] ? employee.availability.exceptions[dotw] : employee.availability.default[dotw] : [];
        var managementActivities = activities && activities.filter(function (x) {
            return x.isManagement;
        });
        // {{#if shift}}
        //     <div class="only-on-small">
        //         {{moment shift.meta.date 'ddd'}}&nbsp;
        //     </div>
        // {{/if}}
        var infoPanel = shift ? _react.default.createElement(ShiftInfoPanel, { shift: shift, activitiesById: activitiesById, published: published }) : '';

        var attrs = {
            shift: shift,
            employee: employee,
            availability: availability,
            weekEndDate: weekEndDate,
            dotw: dotw,
            currentStore: currentStore
        };

        var clickHandler = canEdit ? function (_) {
            (unassigned ? _scheduleEdit.unassignedClickHandler : _scheduleEdit.employeeClickHandler)({
                employee: employee,
                dotw: dotw,
                shift: shift,
                employees: employees,
                editMode: editMode,
                canEdit: canEdit
            });
        } : null;

        return _react.default.createElement(
            'div',
            { className: 'column f1 no-pad' },
            _react.default.createElement(
                'div',
                {
                    className: 'day',
                    onClick: clickHandler
                },
                _react.default.createElement(
                    'div',
                    {
                        className: (0, _shift.getDailyShiftClasses)({
                            currentShift: shift,
                            activeShift: activeShift,
                            employee: employee,
                            employees: employees,
                            availability: availability,
                            weekEndDate: weekEndDate,
                            dotw: dotw,
                            editMode: editMode,
                            assignableEmployeeIds: assignableEmployeeIds,
                            availableEmployeeIds: availableEmployeeIds,
                            skilledEmployeeIds: skilledEmployeeIds,
                            smartAssignEmployee: smartAssignEmployee,
                            managementActivities: managementActivities,
                            currentStore: currentStore,
                            published: published
                        })
                    },
                    _react.default.createElement(
                        'div',
                        { className: 'daily-shift-info' },
                        _react.default.createElement(ShiftFullTime, attrs)
                    )
                ),
                infoPanel
            )
        );
    };

    exports.default = comp;
});