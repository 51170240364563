define('matrix-frontend/components/store-hours-summary', ['exports', 'ember-react-components', 'matrix-frontend/store', 'matrix-frontend/actions/_types', 'npm:react-window', 'npm:moment', 'react', 'react-dom', 'matrix-frontend/components/react-only/store-summary-row', 'npm:react-tooltip', 'matrix-frontend/utils/functional-sort', 'matrix-frontend/utils/format-helpers', 'matrix-frontend/utils/hooks', 'matrix-frontend/utils/colors', 'matrix-frontend/components/react-only/store-details-tooltip'], function (exports, _emberReactComponents, _store, _types, _npmReactWindow, _npmMoment, _react, _reactDom, _storeSummaryRow, _npmReactTooltip, _functionalSort, _formatHelpers, _hooks, _colors, _storeDetailsTooltip) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _objectWithoutProperties(obj, keys) {
        var target = {};

        for (var i in obj) {
            if (keys.indexOf(i) >= 0) continue;
            if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
            target[i] = obj[i];
        }

        return target;
    }

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var FixedSizeList = _npmReactWindow.default.FixedSizeList;


    var wrapNaN = function wrapNaN(x) {
        return isNaN(x) ? 0 : x;
    };

    var HoOChart = function HoOChart(props) {
        var initialState = function initialState() {
            return {
                focusX: null,
                hiddenSeries: {}
            };
        };

        var counts = props.counts,
            style = props.style,
            focusTooltipContentFunc = props.focusTooltipContentFunc;


        var ref = (0, _react.useRef)();

        var _useResize = (0, _hooks.useResize)(ref),
            width = _useResize.width,
            height = _useResize.height;

        var _useState = (0, _react.useState)(initialState()),
            _useState2 = _slicedToArray(_useState, 2),
            state = _useState2[0],
            setState = _useState2[1];

        var isSeriesHidden = function isSeriesHidden(key) {
            return state.hiddenSeries[key] == true;
        };

        var toggleSeriesHidden = function toggleSeriesHidden(key) {
            var x = _extends({}, state.hiddenSeries);
            x[key] = !x[key];
            setState(_extends({}, state, {
                hiddenSeries: x
            }));
        };

        var setFocusPoint = function setFocusPoint(x) {
            setState(_extends({}, state, {
                focusX: x
            }));
        };

        var wrapHidden = function wrapHidden(key, i) {
            return isSeriesHidden(key) ? 0 : counts[key][i];
        };

        var hasData = counts != null;
        var series = hasData ? [{
            name: 'Exception',
            key: 'exception',
            color: _colors.HOO_COLORS.EXCEPTION,
            data: Array(168).fill().map(function (_, i) {
                return wrapHidden('exception', i) + wrapHidden('newDefault', i) + wrapHidden('same', i);
            })
        }, {
            name: 'New Default',
            key: 'newDefault',
            color: _colors.HOO_COLORS.NEW_DEFAULT,
            data: Array(168).fill().map(function (_, i) {
                return wrapHidden('newDefault', i) + wrapHidden('same', i);
            })
        }, {
            name: 'Default',
            key: 'same',
            color: _colors.HOO_COLORS.SAME,
            data: Array(168).fill().map(function (_, i) {
                return wrapHidden('same', i);
            })
        }] : null;

        var maxY = 50;

        if (hasData) {
            for (var i = 0; i < series.length; i++) {
                var max = Math.max.apply(Math, _toConsumableArray(series[i].data));
                if (i == 0 || max > maxY) maxY = max;
            }
            maxY = Math.max(maxY * 1.4, 10);
        }

        var paddingRight = parseFloat(style.paddingRight);

        var scaleY = height / maxY;
        var leftSpace = (width - paddingRight) * 2 / 11;
        var rightSpace = (width - paddingRight) * 9 / 11 / 8 + paddingRight;
        var chartSpace = width - leftSpace - rightSpace;
        var scaleX = chartSpace / (24 * 7);

        var toChartPxX = function toChartPxX(x) {
            return leftSpace + wrapNaN(x) * scaleX;
        };

        var fromChartPx = function fromChartPx(x) {
            return (x - leftSpace) / scaleX;
        };

        var toPxY = function toPxY(y) {
            return height + wrapNaN(y) * -scaleY;
        };

        var Axis = function Axis(_ref) {
            var x1 = _ref.x1,
                y1 = _ref.y1,
                x2 = _ref.x2,
                y2 = _ref.y2;
            return _react.default.createElement('polyline', { fill: 'none', stroke: '#aaa', strokeWidth: '0.5px', points: x1 + ',' + y1 + ' ' + x2 + ',' + y2 });
        };

        var YAxis = function YAxis() {
            return _react.default.createElement(Axis, { key: 'y', x1: leftSpace, y1: 0, x2: leftSpace, y2: height });
        };
        var XAxis = function XAxis() {
            return _react.default.createElement(Axis, { key: 'x', x1: leftSpace, y1: toPxY(0), x2: width - rightSpace, y2: toPxY(0) });
        };

        var tickSpaces = [1, 2, 5, 6, 8, 10, 12, 15, 20, 30, 40, 50, 80, 100, 150, 200, 250, 300, 400, 500, 1000, 1500, 2000, 3000, 4000, 5000, 10000, 20000, 50000, 100000, 150000, 200000, 250000, 500000, 800000, 1000000, 1500000, 2000000];

        var getTickSpace = function getTickSpace() {
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = tickSpaces[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var _tickSpace = _step.value;

                    if (_tickSpace * scaleY >= 20) {
                        return _tickSpace;
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }
        };
        var tickSpace = getTickSpace();

        var getTicks = function getTicks(tickSpace) {
            var tickYs = [0];
            var y = tickSpace;
            while (y < maxY) {
                tickYs.push(y);
                y += tickSpace;
            }
            return tickYs.map(function (y) {
                return {
                    value: y,
                    pxY: toPxY(y)
                };
            }).filter(function (t) {
                return t.pxY >= 10 && t.pxY <= height - 10;
            });
        };
        var ticks = getTicks(tickSpace).map(function (t, i) {
            return _react.default.createElement(
                _react.Fragment,
                { key: i },
                _react.default.createElement(
                    'text',
                    { key: 'text', x: leftSpace - 15, y: t.pxY, textAnchor: 'end', dominantBaseline: 'central', fill: '#aaa', fontSize: 12, fontFamily: 'Open Sans' },
                    t.value.toLocaleString()
                ),
                _react.default.createElement('polyline', { key: 'dash', fill: 'none', stroke: '#aaa', strokeWidth: '0.5px', points: leftSpace - 10 + ',' + t.pxY + ' ' + leftSpace + ',' + t.pxY })
            );
        });

        var StepSeries = function StepSeries(_ref2) {
            var style = _ref2.style,
                data = _ref2.data;

            if (data) {
                var points = [];
                var x0 = 0;
                data.forEach(function (x, i) {
                    if (x != x0) {
                        points.push([i, x0], [i, x]);
                    }
                    x0 = x;
                });
                if (x0 > 0) {
                    points.push([168, x0], [168, 0]);
                }
                return _react.default.createElement('polygon', _extends({}, style, { points: points.map(function (_ref3) {
                        var _ref4 = _slicedToArray(_ref3, 2),
                            x = _ref4[0],
                            y = _ref4[1];

                        return toChartPxX(x) + ',' + toPxY(y);
                    }).join(' ') }));
            }
            return null;
        };

        var loadingMessage = function loadingMessage() {
            var innerText = void 0;
            if (!hasData) {
                innerText = "Loading graph...";
            } else if (maxY == 0) {
                innerText = "No data";
            }
            if (innerText !== undefined) {
                return _react.default.createElement(
                    'text',
                    { key: 'loadingText', x: leftSpace + chartSpace / 2, y: height / 2, textAnchor: 'middle', dominantBaseline: 'central', fill: '#ccc', fontSize: 30, fontWeight: 700, fontFamily: 'Open Sans' },
                    innerText
                );
            }
            return null;
        };

        var AreaLegendEntry = function AreaLegendEntry(_ref5) {
            var series = _ref5.series;

            var elemStyle = {
                background: series.color
            };
            return _react.default.createElement(
                'div',
                { className: 'legend-entry', onClick: function onClick(_) {
                        return toggleSeriesHidden(series.key);
                    }, style: { opacity: isSeriesHidden(series.key) ? 0.5 : 1 } },
                _react.default.createElement('div', { className: 'series-icon-area', style: elemStyle }),
                _react.default.createElement(
                    'div',
                    { className: 'series-text' },
                    series.name
                )
            );
        };

        var FocusMarker = function FocusMarker() {
            if (state.focusX != null && hasData) {
                var tX = toChartPxX(state.focusX + 0.5);
                return _react.default.createElement(
                    _react.Fragment,
                    null,
                    _react.default.createElement('line', { x1: tX, y1: 0, x2: tX, y2: height, stroke: 'rgba(0,0,0,0.3)', strokeWidth: '0.5px' })
                );
            }
            return null;
        };

        var mouseMove = function mouseMove(e) {
            var target = e.target || e.srcElement;
            var rect = target.getBoundingClientRect();
            var x = e.clientX - rect.left;
            var dataX = Math.round(fromChartPx(x));
            if (dataX < 0 || dataX >= 168) {
                dataX = null;
            }
            if (dataX != state.focusX) {
                setFocusPoint(dataX);
            }
        };

        var focusTooltipContent = function focusTooltipContent() {
            if (state.focusX == null || focusTooltipContentFunc == null) {
                return null;
            }
            var data = series.reduce(function (agg, s) {
                agg[s.key] = wrapHidden(s.key, state.focusX);
                return agg;
            }, {});
            return focusTooltipContentFunc(state.focusX, data);
        };

        return _react.default.createElement(
            'div',
            { className: 'chart-container' },
            _react.default.createElement(
                'svg',
                { ref: ref, height: '120px', onMouseMove: mouseMove, onMouseLeave: function onMouseLeave(_) {
                        return setFocusPoint(null);
                    }, 'data-for': 'focusTooltip', 'data-place': 'bottom', 'data-tip': '' },
                _react.default.createElement(YAxis, null),
                _react.default.createElement(XAxis, null),
                ticks,
                hasData ? series.map(function (s) {
                    return isSeriesHidden(s.key) ? null : _react.default.createElement(StepSeries, { key: s.key, data: s.data, style: {
                            fill: s.color,
                            shapeRendering: 'crispEdges'
                        } });
                }) : null,
                _react.default.createElement(FocusMarker, null),
                loadingMessage()
            ),
            _react.default.createElement(
                'div',
                { className: 'chart-legend' },
                hasData ? series.map(function (s) {
                    return _react.default.createElement(AreaLegendEntry, { key: s.key, series: s });
                }) : null
            ),
            _react.default.createElement(_npmReactTooltip.default, {
                key: 'focusTooltip',
                id: 'focusTooltip',
                overridePosition: function overridePosition(_ref6, currentEvent, currentTarget, node) {
                    var left = _ref6.left,
                        top = _ref6.top;

                    var rect = currentTarget.getBoundingClientRect();
                    top = rect.bottom;
                    return { top: top, left: left };
                },
                getContent: hasData ? focusTooltipContent : function () {
                    return null;
                }
            })
        );
    };

    var TableContainer = function TableContainer(props) {
        var itemData = props.itemData,
            itemCount = props.itemCount,
            itemSize = props.itemSize,
            loading = props.loading,
            outerRef = props.outerRef,
            innerRef = props.innerRef,
            groups = props.groups,
            innerChildren = props.innerChildren;

        var _useState3 = (0, _react.useState)(0),
            _useState4 = _slicedToArray(_useState3, 2),
            height = _useState4[0],
            setHeight = _useState4[1];

        var _useState5 = (0, _react.useState)(),
            _useState6 = _slicedToArray(_useState5, 2),
            docElement = _useState6[0],
            setDocElement = _useState6[1];

        var resizeHandler = function resizeHandler() {
            setHeight(docElement.clientHeight);
        };

        (0, _react.useEffect)(function () {
            window.addEventListener('resize', resizeHandler);
            return function (_) {
                return window.removeEventListener('resize', resizeHandler);
            };
        });

        var infoAtIndex = {};

        var totalRows = groups ? groups.reduce(function (agg, x, i) {
            infoAtIndex[agg] = {
                indexOffset: i,
                isHeader: true,
                group: x
            };
            for (var j = 0; j < x.numRows; j++) {
                infoAtIndex[agg + j + 1] = {
                    indexOffset: i + 1,
                    inGroupIndex: j,
                    group: x
                };
            }return agg + x.numRows + 1;
        }, 0) : itemCount;

        var Row = loading ? _storeSummaryRow.FakeRow : function (props) {
            if (groups) {
                var info = infoAtIndex[props.index];
                if (info.isHeader) {
                    var className = 'f-row group-header';
                    if (props.index == 0) {
                        className += ' no-top-border';
                    }
                    return _react.default.createElement(
                        'div',
                        { className: className, style: props.style },
                        info.group.name
                    );
                } else {
                    return _react.default.createElement(_storeSummaryRow.SummaryRow, _extends({}, props, { index: props.index - info.indexOffset }, info));
                }
            }
            return _react.default.createElement(_storeSummaryRow.SummaryRow, props);
        };

        var innerElementType = (0, _react.forwardRef)(function (_ref7, ref) {
            var style = _ref7.style,
                children = _ref7.children,
                rest = _objectWithoutProperties(_ref7, ['style', 'children']);

            /* const paddingHeight = 50 */
            return _react.default.createElement(
                'div',
                _extends({ ref: ref, style: _extends({}, style, {
                        height: parseFloat(style.height) + 'px'
                        /* paddingBottom: `${paddingHeight}px`, */
                    }) }, rest),
                innerChildren,
                children
            );
        });

        return _react.default.createElement(
            'div',
            { className: 'body content-container', ref: function ref(e) {
                    if (e) {
                        setHeight(e.clientHeight);
                        setDocElement(e);
                    }
                } },
            _react.default.createElement(
                FixedSizeList,
                { style: {
                        position: 'absolute',
                        width: '100%'
                    },
                    height: height - 15,
                    itemData: itemData,
                    itemCount: loading ? 6 : totalRows,
                    itemSize: itemSize,
                    innerElementType: innerElementType,
                    outerRef: outerRef,
                    innerRef: innerRef
                },
                Row
            )
        );
    };

    var HoOTooltip = function HoOTooltip(props) {
        var weekEndDate = props.weekEndDate,
            id = props.id,
            rollups = props.rollups,
            currentUser = props.currentUser;


        var getContent = function getContent(value) {
            if (!value || !rollups) return null;

            var _value$split = value.split('|'),
                _value$split2 = _slicedToArray(_value$split, 2),
                tag = _value$split2[0],
                docw = _value$split2[1];

            var rollup = rollups[tag];
            if (!rollup) return null;
            var dayHoo = rollup.hoo[docw];

            var getUserDisplay = function getUserDisplay(_ref8) {
                var user = _ref8.user,
                    firstName = _ref8.firstName,
                    lastName = _ref8.lastName;

                console;
                if (user != null && firstName != null && lastName != null) {
                    return lastName + ', ' + firstName;
                } else if (user == currentUser.id) {
                    return currentUser.username;
                }
                return null;
            };
            var userDisplay = getUserDisplay(dayHoo.updatedBy);

            var content = void 0;
            if (dayHoo.closedAllDay) {
                content = _react.default.createElement(
                    'span',
                    { className: 'text-duration' },
                    'Closed'
                );
            } else {
                var showDays = dayHoo.privateOpenOffset != 0 || dayHoo.privateCloseOffset != 0 || dayHoo.publicOpenOffset != 0 || dayHoo.publicCloseOffset != 0;
                var newHours = dayHoo.isException || dayHoo.newDefault;
                content = _react.default.createElement(
                    _react.Fragment,
                    null,
                    newHours ? _react.default.createElement(
                        'span',
                        { className: 'text-duration weight-heavy' },
                        dayHoo.isException ? 'Exception Hours' : 'New Default Hours'
                    ) : null,
                    _react.default.createElement(
                        'div',
                        { className: 'text-duration x-labels' + (newHours ? ' pad-above' : '') },
                        'Open ' + (0, _formatHelpers.numberFormat)(dayHoo.publicClose.diff(dayHoo.publicOpen, 'hours', true), { decimals: 2 }) + ' hours:'
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'flex direction-row' },
                        _react.default.createElement(
                            'span',
                            { className: 'even-flex' },
                            dayHoo.publicOpen.format('h:mma')
                        ),
                        _react.default.createElement(
                            'span',
                            { className: 'even-flex' },
                            dayHoo.publicClose.format('h:mma')
                        )
                    ),
                    showDays ? _react.default.createElement(
                        'div',
                        { className: 'flex direction-row' },
                        _react.default.createElement(
                            'span',
                            { className: 'even-flex' + (dayHoo.publicOpenOffset != 0 ? ' italics' : '') },
                            dayHoo.publicOpen.format('ddd')
                        ),
                        _react.default.createElement(
                            'span',
                            { className: 'even-flex' + (dayHoo.publicCloseOffset != 0 ? ' italics' : '') },
                            dayHoo.publicClose.format('ddd')
                        )
                    ) : null,
                    _react.default.createElement(
                        'div',
                        { className: 'text-duration x-labels pad-above' },
                        'Occupied ' + (0, _formatHelpers.numberFormat)(dayHoo.privateClose.diff(dayHoo.privateOpen, 'hours', true), { decimals: 2 }) + ' hours:'
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'flex direction-row' },
                        _react.default.createElement(
                            'span',
                            { className: 'even-flex' },
                            dayHoo.privateOpen.format('h:mma')
                        ),
                        _react.default.createElement(
                            'span',
                            { className: 'even-flex' },
                            dayHoo.privateClose.format('h:mma')
                        )
                    ),
                    showDays ? _react.default.createElement(
                        'div',
                        { className: 'flex direction-row' },
                        _react.default.createElement(
                            'span',
                            { className: 'even-flex' + (dayHoo.privateOpenOffset != 0 ? ' italics' : '') },
                            dayHoo.privateOpen.format('ddd')
                        ),
                        _react.default.createElement(
                            'span',
                            { className: 'even-flex' + (dayHoo.privateCloseOffset != 0 ? ' italics' : '') },
                            dayHoo.privateClose.format('ddd')
                        )
                    ) : null,
                    dayHoo.updatedAt ? _react.default.createElement(
                        _react.Fragment,
                        null,
                        _react.default.createElement(
                            'div',
                            { className: 'text-duration x-labels pad-above' },
                            userDisplay ? 'Updated by:' : 'Updated:'
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'text-center' },
                            userDisplay
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'text-center' },
                            _npmMoment.default.utc(dayHoo.updatedAt).local().format('YYYY-MM-DD h:mma[m]')
                        )
                    ) : null
                );
            }

            return _react.default.createElement(
                'div',
                { className: 'hoo-tooltip' },
                content
            );
        };

        return _react.default.createElement(_npmReactTooltip.default, { id: id, effect: 'solid', getContent: getContent });
    };

    var comp = function comp(props) {
        var clientCode = props.clientCode,
            weekEndDate = props.weekEndDate,
            dataType = props.dataType,
            view = props.view,
            node = props.node,
            visibleNodes = props.visibleNodes,
            columns = props.columns,
            rollups = props.rollups,
            selectBusinessUnit = props.selectBusinessUnit,
            openHoOModal = props.openHoOModal,
            hierarchies = props.hierarchies,
            currentUser = props.currentUser;


        var loading = visibleNodes == null;

        var scrollOuterRef = (0, _react.useRef)();
        var scrollInnerRef = (0, _react.useRef)();

        var _useState7 = (0, _react.useState)('node'),
            _useState8 = _slicedToArray(_useState7, 2),
            sortKey = _useState8[0],
            setSortKey = _useState8[1];

        var _useState9 = (0, _react.useState)(false),
            _useState10 = _slicedToArray(_useState9, 2),
            reverseSort = _useState10[0],
            setReverseSort = _useState10[1];

        var _useState11 = (0, _react.useState)(0),
            _useState12 = _slicedToArray(_useState11, 2),
            scrollbarWidth = _useState12[0],
            setScrollbarWidth = _useState12[1];

        var alignScrollbar = function alignScrollbar() {
            if (scrollInnerRef.current && scrollOuterRef.current) {
                var scrollWidth = scrollOuterRef.current.offsetWidth - scrollInnerRef.current.offsetWidth;
                if (scrollbarWidth != scrollWidth) {
                    setScrollbarWidth(scrollWidth);
                }
            }
        };

        (0, _react.useEffect)(function () {
            alignScrollbar();
            window.addEventListener('resize', alignScrollbar);
            return function (_) {
                return window.removeEventListener('resize', alignScrollbar);
            };
        });

        // useEffect(() => {
        //     ReactTooltip.rebuild()
        // })

        var updateSort = function updateSort(key) {
            if (key === sortKey) {
                setReverseSort(!reverseSort);
            } else {
                setSortKey(key);
                setReverseSort(false);
            }
        };

        var weekEnd = (0, _npmMoment.default)(weekEndDate).hours(0).minutes(0).seconds(0);

        var itemData = void 0;

        var minDisplayValue = 0;
        var maxDisplayValue = 0;

        var rowNodes = loading ? [] : visibleNodes.stores;

        var items = rowNodes.map(function (n) {
            return {
                node: n,
                rollup: rollups[n.tag]
            };
        });

        var counts = null;
        if (!loading) {
            counts = {
                same: Array(168).fill().map(function (_) {
                    return new Set();
                }),
                exception: Array(168).fill().map(function (_) {
                    return new Set();
                }),
                newDefault: Array(168).fill().map(function (_) {
                    return new Set();
                })
            };
            items.forEach(function (x) {
                if (x.rollup && x.rollup.hoo) {
                    for (var docw = 1; docw <= 7; docw++) {
                        var hourOffset = (docw - 1) * 24;
                        var date = weekEnd.clone().add(-7 + docw, 'days');
                        var dayHoo = x.rollup.hoo[docw];
                        if (!dayHoo.closedAllDay) {
                            var open = dayHoo[dataType === 'public' ? 'publicOpen' : 'privateOpen'];
                            var close = dayHoo[dataType === 'public' ? 'publicClose' : 'privateClose'];
                            var openHour = Math.max(Math.floor(open.diff(date, 'hours', true)) + hourOffset, 0);
                            var closeHour = Math.min(Math.ceil(close.diff(date, 'hours', true)) + hourOffset, 168);
                            for (var h = openHour; h < closeHour; h++) {
                                if (dayHoo.isException) counts.exception[h].add(x.node.tag);else if (dayHoo.newDefault) counts.newDefault[h].add(x.node.tag);else counts.same[h].add(x.node.tag);
                            }
                        }
                    }
                }
            });
            counts.same = counts.same.map(function (x) {
                return x.size;
            });
            counts.exception = counts.exception.map(function (x) {
                return x.size;
            });
            counts.newDefault = counts.newDefault.map(function (x) {
                return x.size;
            });
        }

        var auxSorts = [];
        if (sortKey !== 'node') {
            auxSorts.push((0, _functionalSort.funcSort)(function (x) {
                return x.rollup == null;
            }, { reverse: reverseSort }), (0, _functionalSort.funcSort)(function (x) {
                return x.rollup ? 0 : x.node.code;
            }));
            if (sortKey === 'week') {
                auxSorts.push((0, _functionalSort.funcSort)(function (x) {
                    return x.rollup[dataType + 'WeeklyHours'];
                }), (0, _functionalSort.funcSort)(function (x) {
                    return x.rollup.numDaysOpen;
                }));
            } else {
                var docw = sortKey[0];
                var isOpen = sortKey.substr(1) == 'open';
                auxSorts.push((0, _functionalSort.funcSort)(function (x) {
                    return !x.rollup.hoo[docw].closedAllDay;
                }), (0, _functionalSort.funcSort)(function (x) {
                    return x.rollup.hoo[docw]['' + dataType + (isOpen ? 'Open' : 'Close')];
                }, { numeric: true }), (0, _functionalSort.funcSort)(function (x) {
                    return x.rollup.hoo[docw]['' + dataType + (isOpen ? 'Close' : 'Open')];
                }, { numeric: true }));
            }
        }

        if (sortKey == 'node') {
            auxSorts.unshift((0, _functionalSort.funcSort)(function (x) {
                return x.node.parent;
            }));
        }

        var sortFunc = (0, _functionalSort.tieredSort)([].concat(auxSorts, [(0, _functionalSort.funcSort)(function (x) {
            return x.node.code;
        })]), reverseSort);

        var sortedItems = items.sort(sortFunc);

        itemData = {
            selectBusinessUnit: selectBusinessUnit,
            openHoOModal: openHoOModal,
            clientCode: clientCode,
            weekEndDate: weekEndDate,
            dataType: dataType,
            view: view,
            columns: columns,
            items: sortedItems,
            rollups: rollups
        };

        var SortIndicator = function SortIndicator(props) {
            var targetKey = props.targetKey,
                mainClass = props.mainClass;

            if (targetKey == sortKey) {
                var classes = [mainClass, !reverseSort ? 'icon-arrow-down' : 'icon-arrow-up'];
                return _react.default.createElement('span', { className: classes.join(' ') });
            }
            return null;
        };

        var BasicColumn = function BasicColumn(props) {
            var text = props.text,
                _props$classes = props.classes,
                classes = _props$classes === undefined ? [] : _props$classes,
                columnKey = props.columnKey;


            var classList = [].concat(_toConsumableArray(classes), ['sortable', sortKey == columnKey ? 'weight-semi' : '']);

            return _react.default.createElement(
                'div',
                { className: classList.join(' '), onClick: function onClick(_) {
                        return updateSort(columnKey);
                    } },
                text,
                _react.default.createElement(SortIndicator, { mainClass: 'sort-indicator', targetKey: columnKey })
            );
        };

        var DayColumn = function DayColumn(props) {
            var text = props.text,
                _props$classes2 = props.classes,
                classes = _props$classes2 === undefined ? [] : _props$classes2,
                docw = props.docw;


            var keys = [docw + 'open', docw + 'close'];

            var classList = [].concat(_toConsumableArray(classes), ['day-header', 'sortable', keys.find(function (x) {
                return x == sortKey;
            }) ? 'weight-semi' : '']);

            return _react.default.createElement(
                'div',
                { className: classList.join(' ') },
                _react.default.createElement(
                    'span',
                    null,
                    text
                ),
                _react.default.createElement(
                    'div',
                    { className: 'partial-container' },
                    _react.default.createElement(
                        'div',
                        { onClick: function onClick(_) {
                                return updateSort(docw + 'open');
                            } },
                        _react.default.createElement(SortIndicator, { mainClass: 'day-sort-indicator', targetKey: keys[0] })
                    ),
                    _react.default.createElement(
                        'div',
                        { onClick: function onClick(_) {
                                return updateSort(docw + 'close');
                            } },
                        _react.default.createElement(SortIndicator, { mainClass: 'day-sort-indicator', targetKey: keys[1] })
                    )
                )
            );
        };

        var scrollbarAdjustStyle = {
            paddingRight: scrollbarWidth + 'px'
        };

        var getChartFocusTooltipContent = function getChartFocusTooltipContent(x, data) {
            var day = Math.floor(x / 24);
            var hour = x % 24;
            var mmt = weekEnd.clone().add(-6 + day, 'days').hours(hour).minutes(0).seconds(0).milliseconds(0);
            return _react.default.createElement(
                'div',
                { className: 'hoo-tooltip', style: { width: '140px' } },
                _react.default.createElement(
                    'div',
                    { className: 'x-heading' },
                    _react.default.createElement(
                        'span',
                        null,
                        mmt.format('dddd, MMM Do')
                    ),
                    _react.default.createElement(
                        'span',
                        null,
                        mmt.format('h:mma'),
                        ' - ',
                        mmt.clone().add(1, 'hours').format('h:mma')
                    )
                ),
                _react.default.createElement(
                    'div',
                    { className: 'flex-table flex-row-table' },
                    _react.default.createElement(
                        'div',
                        { className: 'column no-pad-left left x-labels' },
                        _react.default.createElement(
                            'span',
                            null,
                            'Stores ',
                            dataType === 'public' ? 'open' : 'occupied'
                        )
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'zero-flex-column no-pad-right right x-values' },
                        _react.default.createElement(
                            'span',
                            null,
                            (0, _formatHelpers.numberFormat)(Object.values(data).reduce(function (agg, count) {
                                return agg + count;
                            }, 0))
                        )
                    )
                )
            );
        };

        return _react.default.createElement(
            _react.Fragment,
            null,
            _react.default.createElement(HoOChart, { counts: counts, style: scrollbarAdjustStyle, focusTooltipContentFunc: getChartFocusTooltipContent }),
            _react.default.createElement(
                'div',
                { className: 'full-flex flex-table' },
                _react.default.createElement(
                    'div',
                    { className: 'head', style: scrollbarAdjustStyle },
                    _react.default.createElement(
                        'div',
                        { className: 'row' },
                        _react.default.createElement(
                            'div',
                            { className: 'wrapper f2' },
                            _react.default.createElement(BasicColumn, { text: 'Name', classes: ['column', 'f1', 'text'], columnKey: 'node' })
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'wrapper f9' },
                            columns.map(function (c, i) {
                                return _react.default.createElement(DayColumn, { key: i, classes: ['column', 'f1'], text: c.text, docw: i + 1 });
                            }),
                            _react.default.createElement(BasicColumn, { text: 'Week', classes: ['column', 'f1'], columnKey: 'week' })
                        )
                    )
                ),
                _react.default.createElement(TableContainer, {
                    itemData: itemData,
                    itemCount: sortedItems.length,
                    itemSize: 25,
                    loading: loading,
                    outerRef: scrollOuterRef,
                    innerRef: scrollInnerRef,
                    groups: sortKey == 'node' && visibleNodes && visibleNodes.parents.length > 1 ? visibleNodes.parents.sort(function (a, b) {
                        return (a.code > b.code ? 1 : -1) * (reverseSort ? -1 : 1);
                    }).map(function (x) {
                        return {
                            name: x.name,
                            numRows: x.numStores
                        };
                    }) : null,
                    innerChildren: !loading && view == 'visual' ? _react.default.createElement(
                        'div',
                        { className: 'hours-grid-container' },
                        _react.default.createElement(
                            'div',
                            { className: 'hours-grid' },
                            _react.default.createElement('div', { className: 'flex f2' }),
                            _react.default.createElement(
                                'div',
                                { className: 'flex f9' },
                                _react.default.createElement(
                                    'div',
                                    { className: 'flex f7' },
                                    [].concat(_toConsumableArray(Array(24 / 2 * 7))).map(function (_, i) {
                                        return _react.default.createElement('div', { key: i, className: 'flex f1', style: {
                                                background: !(i % 12 >= 3 && i % 12 < 9) ? '#f5f5f5' : null
                                            } });
                                    })
                                ),
                                _react.default.createElement('div', { className: 'flex f1' })
                            )
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'hours-grid' },
                            _react.default.createElement('div', { className: 'flex f2' }),
                            _react.default.createElement(
                                'div',
                                { className: 'flex f9' },
                                _react.default.createElement(
                                    'div',
                                    { className: 'flex f7' },
                                    [].concat(_toConsumableArray(Array(24 / 2 * 7))).map(function (_, i) {
                                        return _react.default.createElement('div', { key: i, className: 'flex f1 hour-line', style: {
                                                borderColor: i % 12 == 0 ? 'rgba(0,0,0,1)' : i % 3 == 0 ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0.1)'
                                            } });
                                    })
                                ),
                                _react.default.createElement('div', { className: 'flex f1' })
                            )
                        )
                    ) : null
                })
            ),
            _react.default.createElement(_storeDetailsTooltip.default, { hierarchies: hierarchies }),
            _react.default.createElement(_npmReactTooltip.default, { id: 'basicTooltip', effect: 'solid' }),
            _react.default.createElement(HoOTooltip, { id: 'hooTooltip', weekEndDate: weekEnd, rollups: rollups, currentUser: currentUser })
        );
    };

    exports.default = (0, _emberReactComponents.default)(comp);
});