define('matrix-frontend/reducers/cube', ['exports', 'npm:moment', 'matrix-frontend/actions/_types'], function (exports, _npmMoment, _types) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var initialState = {
        node: null,
        page: null,
        view: null,
        targetName: null,
        weekEndDate: null,
        clientCode: null,
        clientId: null,
        clientWeekStartDay: null,
        // weekEndDate: moment().set({
        //     year: 2018, month: 5, date: 16,
        //     hour: 6, minute: 0, seconds: 0
        // }),
        date: null,
        dotw: null,
        deferDOTW: null,
        pageInProgress: null,
        multiUserSaveMode: false,
        targetWeek: null,
        targetDOTW: null
        // date: moment().set({
        //     year: 2018, month: 4, date: 27,
        // }),
    };

    var cube = function cube(state, action) {
        var type = action.type,
            payload = action.payload;


        switch (type) {
            case _types.CUBE_SET_PAGE_IN_PROGRESS:
                var pageInProgress = payload.pageInProgress;

                return _extends({}, state, {
                    pageInProgress: pageInProgress
                });
                break;

            case _types.CUBE_GO_TO_DAY:
                var dotw = payload.dotw;

                var date = null;

                if (dotw) {
                    var offset = 7 - dotw;
                    date = state.weekEndDate.clone().subtract(offset, 'days');
                }

                var deferDOTW = payload.deferDOTW ? payload.deferDOTW : null;
                return _extends({}, state, {
                    date: date,
                    dotw: dotw,
                    deferDOTW: deferDOTW
                });
                break;

            case _types.CUBE_GO_TO_WEEK:
                return _extends({}, state, {
                    date: null
                });
                break;

            case _types.CUBE_SET_TARGET:
                var targetWeek = payload.targetWeek,
                    targetDOTW = payload.targetDOTW;

                return _extends({}, state, {
                    targetWeek: targetWeek,
                    targetDOTW: targetDOTW
                });
                break;

            case _types.CUBE_SET_WEEK_END_DATE:
                var dateString = payload.dateString;

                var weekEndDate = (0, _npmMoment.default)(dateString);
                weekEndDate.set({
                    hour: 6,
                    minute: 0,
                    seconds: 0
                });
                return _extends({}, state, {
                    weekEndDate: weekEndDate
                });
                break;

            case _types.CUBE_SET_NODE:
                {
                    var tag = payload.tag;
                    var clientCode = payload.clientCode,
                        nodeType = payload.nodeType,
                        nodeId = payload.nodeId,
                        storeId = payload.storeId,
                        _name = payload.name;

                    var parts = tag.split('.');
                    var code = nodeType === null ? 'corp' : parts[1];
                    tag = nodeType === null ? 'corp' : tag;
                    return _extends({}, state, {
                        node: {
                            clientCode: clientCode,
                            tag: tag,
                            code: code,
                            name: _name,
                            nodeType: nodeType,
                            nodeId: nodeId ? parseInt(nodeId) : null,
                            storeId: storeId ? parseInt(storeId) : null
                        }
                    });
                    break;
                }

            case _types.CUBE_SET_PAGE:
                var name = payload.name,
                    view = payload.view,
                    targetName = payload.targetName;

                return _extends({}, state, {
                    page: name,
                    view: view || null,
                    targetName: targetName
                });
                break;

            case _types.CUBE_SET_PARTIAL:
                return _extends({}, state, payload);
                break;

            case _types.CUBE_TOGGLE_MULTI_USER_SAVE_MODE:
                var _ref = payload || {},
                    force = _ref.force;

                var multiUserSaveMode = force !== undefined ? force : !state.multiUserSaveMode;
                return _extends({}, state, {
                    multiUserSaveMode: multiUserSaveMode
                });
                break;

            default:
                {
                    return state || initialState;
                }
        }
    };

    exports.default = cube;
});