define('matrix-frontend/pods/password/forgot/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        queryParams: {
            email: {
                refreshModel: true
            }
        },
        model: function model(params, transition) {
            var email = transition.queryParams.email;

            return { email: email };
        }
    });
});