define('matrix-frontend/pods/components/control-panel-save/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        saveService: Ember.inject.service(),

        showSaveButton: Ember.computed('save', function () {
            return !!this.get('save');
        }),
        showDiscardButton: Ember.computed('discard', function () {
            return !!this.get('discard');
        }),
        saveText: Ember.computed('disabledButtons', 'saveService.valueChanged', function () {
            if (this.get('isLegacy')) {
                return this.get('saveService').get('valueChanged') ? 'Save' : 'Saved';
            }
            return 'Save';
        }),
        saveDisabled: Ember.computed('disabledButtons', 'saveService.valueChanged', function () {
            if (this.get('isLegacy')) {
                return !this.get('saveService').get('valueChanged');
            }
            return this.get('disabledButtons');
        }),
        discardDisabled: Ember.computed('disabledButtons', 'saveService.valueChanged', function () {
            if (this.get('isLegacy')) {
                return !this.get('saveService').get('valueChanged');
            }
            return this.get('disabledButtons');
        }),
        showConfirmPanel: Ember.computed('saveService.awaitingConfirm', function () {
            return this.get('saveService').get('awaitingConfirm');
        }),

        actions: {
            saveAction: function saveAction() {
                if (!this.get('saveDisabled')) {
                    this.get('save')();
                }
            },
            discardAction: function discardAction() {
                if (!this.get('discardDisabled')) {
                    this.get('discard')();
                }
            },
            confirmGoBack: function confirmGoBack() {
                this.get('saveService').set('awaitingConfirm', false);
            },
            confirmGoAnyway: function confirmGoAnyway() {
                this.get('saveService').set('awaitingConfirm', false);
                this.get('saveService').set('valueChanged', false);
                this.get('saveService').proceedMethod();
            }
        }
    });
});