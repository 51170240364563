define('matrix-frontend/mixins/base-controller', ['exports', 'npm:moment'], function (exports, _npmMoment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        stateService: Ember.inject.service(),
        loadingService: Ember.inject.service(),
        redux: Ember.inject.service(),

        queryParams: ['hierarchyNodeId', 'storeId'],

        date: '',
        hierarchyNodeId: '',
        storeId: '',

        init: function init() {
            this._super.apply(this, arguments);
        },


        businessUnit: Ember.computed('storeId', 'hierarchyNodeId', function () {
            if (this.get('storeId')) {
                return this.get('stateService').getStore();
            } else {
                return this.get('stateService').getHierarchyNode();
            }
        }),

        dateString: Ember.computed('date', function () {
            return (0, _npmMoment.default)(this.get('date'));
        }),

        node: Ember.computed('storeId', 'hierarchyNodeId', function () {
            var state = this.get('redux').getState();
            return state.cube.node;
        }),

        clearDaySelection: function () {
            // eslint-disable-line ember/no-function-prototype-extensions
            this.set('selectedDate', null);
        }.observes('date'),

        dateOrStoreChange: function () {
            // eslint-disable-line ember/no-function-prototype-extensions
            // this.get('loadingService').startLoading()
        }.observes('date', 'hierarchyNodeId', 'storeId'),

        actions: {
            selectBusinessUnit: function selectBusinessUnit(id, type) {
                if (type === 'store') {
                    this.set('storeId', id);
                    this.set('hierarchyNodeId', '');
                    this.get('stateService').setStore(id, true);
                } else {
                    this.set('storeId', '');
                    this.set('hierarchyNodeId', id);
                    this.get('stateService').setHierarchyNode(id);
                }
            },
            selectDate: function selectDate(date) {
                var selectedDate = this.get('selectedDate');
                if (selectedDate && selectedDate.isSame(date)) {
                    this.set('selectedDate', null);
                } else {
                    this.set('selectedDate', date);
                    this.get('stateService').set('weekEndDate', date);
                }
            }
        }
    });
});