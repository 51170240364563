define('matrix-frontend/components/email-compose', ['exports', 'ember-react-components', 'matrix-frontend/actions/_types', 'matrix-frontend/store', 'react', 'matrix-frontend/components/react-only/multi-checker'], function (exports, _emberReactComponents, _types, _store, _react, _multiChecker) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var NUM_TO_SHOW = 4;

    var Subject = function Subject(_ref) {
        var subjectHandler = _ref.subjectHandler,
            subject = _ref.subject,
            doCheck = _ref.doCheck;

        return _react.default.createElement('input', {
            placeholder: 'Enter your email subject',
            onChange: subjectHandler,
            defaultValue: subject,
            onKeyUp: doCheck
        });
    };

    var comp = function comp(_ref2) {
        var email = _ref2.email,
            editing = _ref2.editing,
            editRecipients = _ref2.editRecipients,
            objects = _ref2.objects,
            modalService = _ref2.modalService,
            onOpen = _ref2.onOpen,
            showSave = _ref2.showSave,
            recipientType = _ref2.recipientType,
            _ref2$recipientText = _ref2.recipientText,
            recipientText = _ref2$recipientText === undefined ? 'Recipients' : _ref2$recipientText;

        var subjectRef = (0, _react.useRef)();
        var messageRef = (0, _react.useRef)();

        var _useState = (0, _react.useState)(subject),
            _useState2 = _slicedToArray(_useState, 2),
            subject = _useState2[0],
            setSubject = _useState2[1];

        var _useState3 = (0, _react.useState)(message),
            _useState4 = _slicedToArray(_useState3, 2),
            message = _useState4[0],
            setMessage = _useState4[1];

        var _useState5 = (0, _react.useState)(new Set()),
            _useState6 = _slicedToArray(_useState5, 2),
            recipients = _useState6[0],
            setRecipients = _useState6[1];

        var _useState7 = (0, _react.useState)([]),
            _useState8 = _slicedToArray(_useState7, 2),
            recipientList = _useState8[0],
            setRecipientList = _useState8[1];

        var resetToAll = function resetToAll() {
            if (objects && objects.length) {
                var ids = objects.filter(function (x) {
                    return x.email && !x.disabled || x.precheck;
                }).map(function (x) {
                    return '' + x.id;
                });
                var r = new Set(ids);
                doSetRecipients(r);
            }
        };

        var doSetRecipients = function doSetRecipients(x) {
            setRecipients(x);
            _store.store.dispatch({
                type: _types.EE_PREP_EMAIL,
                payload: { recipients: Array.from(x) }
            });
        };

        var doCheck = function doCheck() {
            if (email) {
                var ok = email.recipients && email.recipients.length > 0 && email.subject && email.subject.length > 0 && email.message && email.message.length > 0 && editing !== 'recipients';

                showSave(ok);
                modalService.setValid('email-modal', ok);
            }
        };

        var subjectHandler = function subjectHandler(e) {
            _store.store.dispatch({
                type: _types.EE_PREP_EMAIL,
                payload: { subject: e.target.value }
            });
        };
        var messageHandler = function messageHandler(e) {
            _store.store.dispatch({
                type: _types.EE_PREP_EMAIL,
                payload: { message: e.target.value }
            });
        };

        (0, _react.useEffect)(function () {
            setRecipientList([].concat(_toConsumableArray(recipients)).filter(function (x) {
                return x;
            }).map(function (x) {
                return (objects || []).find(function (y) {
                    return '' + x === '' + y.id;
                });
            }));
        }, [recipients, objects]);

        (0, _react.useEffect)(function () {
            doCheck();
            if (email) {
                setSubject(email.subject || '');
                setMessage(email.message || '');
            }
        }, [editing, email]);

        modalService.modals['email-modal'].beforeOpen = function () {
            resetToAll();
            onOpen();
            subjectRef.current.value = '';
            messageRef.current.value = '';
            _store.store.dispatch({
                type: _types.EE_PREP_EMAIL,
                payload: { recipientType: recipientType }
            });
        };

        return _react.default.createElement(
            'div',
            { className: 'flex-table no-border comfort' },
            _react.default.createElement(
                'div',
                { className: 'body' },
                editing === 'recipients' ? _react.default.createElement(
                    'div',
                    { className: 'row' },
                    _react.default.createElement(
                        'div',
                        { className: 'column f1 text align-start' },
                        _react.default.createElement(
                            'strong',
                            null,
                            recipientText,
                            ' (',
                            recipients.size,
                            ')'
                        )
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'column f3 text' },
                        _react.default.createElement(_multiChecker.default, { objects: objects || [], setter: doSetRecipients, initial: recipients })
                    )
                ) : _react.default.createElement(
                    _react.default.Fragment,
                    null,
                    _react.default.createElement(
                        'div',
                        { className: 'row' },
                        _react.default.createElement(
                            'div',
                            { className: 'column f3 text align-start' },
                            'To:',
                            _react.default.createElement('br', null),
                            recipientList.filter(function (x) {
                                return x;
                            }).length > 0 ? recipientList.filter(function (x) {
                                return x;
                            }).length + ' selected' : ''
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'column f9 text flex stretch-children' },
                            _react.default.createElement(
                                'strong',
                                null,
                                recipientList.filter(function (x) {
                                    return x;
                                }).length === 0 ? 'No recipients' : recipientList.filter(function (x) {
                                    return x;
                                }).length > NUM_TO_SHOW ? recipientList.filter(function (x) {
                                    return x;
                                }).map(function (x) {
                                    return x.name;
                                }).slice(0, NUM_TO_SHOW).join('; ') + (' and ' + (recipientList.length - NUM_TO_SHOW) + ' more') : recipientList.filter(function (x) {
                                    return x;
                                }).map(function (x) {
                                    return x.name;
                                }).join('; ')
                            )
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'column f1 number' },
                            _react.default.createElement(
                                'a',
                                { href: '#', onClick: editRecipients },
                                'edit'
                            )
                        )
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'row' },
                        _react.default.createElement(
                            'div',
                            { className: 'column f3 text' },
                            'Subject:'
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'column f10 text flex stretch-children' },
                            _react.default.createElement('input', {
                                placeholder: 'Enter your email subject',
                                ref: subjectRef,
                                onChange: subjectHandler,
                                defaultValue: subject,
                                onKeyUp: doCheck
                            })
                        )
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'row' },
                        _react.default.createElement(
                            'div',
                            { className: 'column f3 text' },
                            'Message:'
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'column f10 text flex stretch-children' },
                            _react.default.createElement('textarea', {
                                placeholder: 'Enter your email message',
                                ref: messageRef,
                                onChange: messageHandler,
                                defaultValue: message,
                                onKeyUp: doCheck
                            })
                        )
                    )
                )
            )
        );
    };

    exports.default = (0, _emberReactComponents.default)(comp);
});