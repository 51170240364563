define('matrix-frontend/components/schedule-status-bar', ['exports', 'react', 'ember-react-components'], function (exports, _react, _emberReactComponents) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var comp = function comp(props) {
        var total = props.total;


        var blockDefs = [{ key: 'activePublished', classes: 'green' }, { key: 'activeInProgress', classes: 'purple' }, { key: 'activeNotStarted', classes: 'gray-lighten-3' }, { key: 'preliminary', classes: 'yellow' }, { key: 'readonly', classes: 'orange' }, { key: 'executive', classes: 'blue' }, { key: 'hidden', classes: 'dark' }, { key: 'noSchedule', classes: 'gray-lighten-1' }];

        var blocks = total ? blockDefs.map(function (b) {
            var count = total[b.key];
            if (!count) return null;
            return _react.default.createElement('div', { key: b.key, className: 'progress ' + b.classes, style: {
                    width: count / total.numStores * 100 + '%'
                } });
        }) : null;

        return _react.default.createElement(
            'div',
            { className: 'progress-bar' },
            blocks
        );
    };

    exports.default = (0, _emberReactComponents.default)(comp);
});