define('matrix-frontend/pods/components/hoo-display/component', ['exports', 'ember-redux', 'matrix-frontend/utils/date-helper', 'npm:moment', 'matrix-frontend/utils/state'], function (exports, _emberRedux, _dateHelper, _npmMoment, _state) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var stateToComputed = function stateToComputed(state) {
        return {
            _date: state.cube.date,
            currentUser: state.user.currentUser
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {};
    };

    var Comp = Ember.Component.extend({
        modalService: Ember.inject.service(),
        dateOverride: null,
        prefix: '',

        canEdit: Ember.computed('currentUser', function () {
            return (0, _state.hasScope)('store.hoo:edit');
        }),

        publicOpen: Ember.computed('hours', 'date', function () {
            var hours = this.get('hours');
            var date = this.get('date');
            var mmt = (0, _dateHelper.offsetTime)(date, hours.publicOpen, hours.publicOpenOffset);
            return mmt;
        }),
        publicClose: Ember.computed('hours', 'date', function () {
            var hours = this.get('hours');
            var date = this.get('date');
            var mmt = (0, _dateHelper.offsetTime)(date, hours.publicClose, hours.publicCloseOffset);
            return mmt;
        }),

        date: Ember.computed('_date', 'dateOverride', function () {
            return this.dateOverride ? this.dateOverride : this._date;
        }),

        actions: {
            openModal: function openModal() {
                this.get('modalService').open('hoo-modal');
            }
        }
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});