define('matrix-frontend/components/react-only/checklist-item-row', ['exports', 'react', 'npm:moment', 'npm:react-tooltip', 'matrix-frontend/constants'], function (exports, _react, _npmMoment, _npmReactTooltip, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.ChecklistStoreItemRow = exports.ChecklistItemRow = exports.ChecklistHierarchyItemRow = exports.FlagsCell = exports.StoreItemStatusCell = exports.getStatusAttrs = undefined;

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var formatDate = function formatDate(d) {
        return (0, _npmMoment.default)(d).format('ddd MMM D');
    };

    var tooltipAttrs = function tooltipAttrs(dataFor, dataTip, dataPlace) {
        return {
            'data-for': dataFor,
            'data-tip': dataTip,
            'data-place': dataPlace
        };
    };

    var getStatusAttrs = exports.getStatusAttrs = function getStatusAttrs(status) {
        var style = {};
        var text = "";
        switch (status) {
            case _constants.ChecklistItemStatus.COMPLETED:
                {
                    style = {
                        background: '#58d77c',
                        color: 'white'
                    };
                    text = 'Completed';
                    break;
                }
            case _constants.ChecklistItemStatus.LATE:
                {
                    style = {
                        background: '#9e7a24',
                        color: 'white'
                    };
                    text = 'Completed Late';
                    break;
                }
            case _constants.ChecklistItemStatus.OVERDUE:
                {
                    style = {
                        background: '#ed1111',
                        color: 'white'
                    };
                    text = 'Overdue';
                    break;
                }
            case _constants.ChecklistItemStatus.INCOMPLETE:
                {
                    style = {
                        background: '#d0d0d0',
                        color: 'black'
                    };
                    text = 'Incomplete';
                    break;
                }
            case _constants.ChecklistItemStatus.UPCOMING:
                {
                    style = {
                        background: '#e0e0e0',
                        color: '#444'
                    };
                    text = 'Upcoming';
                    break;
                }
        }

        return {
            style: style,
            text: text
        };
    };

    var DateCell = function DateCell(props) {
        var date = props.date,
            exceptionDate = props.exceptionDate,
            showException = props.showException,
            tooltipFunc = props.tooltipFunc;


        var classList = ['column', 'f1', 'date-cell'];

        var isSame = exceptionDate == null || exceptionDate - date == 0;
        if (showException && isSame) {
            classList.push('gray-lighten-1-text');
        }
        var tooltip = {};
        if (tooltipFunc && !isSame) {
            tooltip = tooltipFunc(exceptionDate);
        }

        return _react.default.createElement(
            'div',
            _extends({ className: classList.join(' ') }, tooltip),
            formatDate(exceptionDate != null && showException ? exceptionDate : date)
        );
    };

    var ItemStatusCell = function ItemStatusCell(props) {
        var statusCounts = props.statusCounts;


        var values = Object.keys(statusCounts).map(function (k) {
            return {
                status: _constants.ChecklistItemStatus[k],
                value: statusCounts[k]
            };
        }).filter(function (x) {
            return x.value > 0;
        }).sort(function (a, b) {
            return a.status.value - b.status.value;
        });

        return _react.default.createElement(
            'div',
            _extends({ className: 'column f2' }, tooltipAttrs('statusTooltip', JSON.stringify(statusCounts), 'bottom')),
            _react.default.createElement(
                'div',
                { className: 'status-cell-content' },
                values.map(function (v, i) {
                    // const contentAttrs = {
                    //     'data-for': 'basicTooltip',
                    //     'data-tip': `${getStatusText(v.status)}: ${v.value}`,
                    //     'data-place': 'bottom',
                    // }
                    return _react.default.createElement('div', { key: i, style: _extends({
                            flexGrow: v.value
                        }, getStatusAttrs(v.status).style) });
                })
            )
        );
    };

    var StoreItemStatusCell = exports.StoreItemStatusCell = function StoreItemStatusCell(props) {
        var status = props.status,
            big = props.big;

        var _getStatusAttrs = getStatusAttrs(status),
            style = _getStatusAttrs.style,
            text = _getStatusAttrs.text;

        var classList = ['store-status-cell', big ? 'big' : ''];

        // if(status != ChecklistItemStatus.INCOMPLETE) {
        //     classList.push('light-text')
        // }

        return _react.default.createElement(
            'div',
            { className: 'column f2' },
            _react.default.createElement(
                'div',
                { className: classList.join(' '), style: style },
                text
            )
        );
    };

    var FlagsCell = exports.FlagsCell = function FlagsCell(props) {
        var numRed = props.numRed,
            numOrange = props.numOrange,
            numYellow = props.numYellow,
            hideZeros = props.hideZeros;


        var countText = function countText(x) {
            return x ? x.toLocaleString() : '-';
        };

        var FlagColumn = function FlagColumn(_ref) {
            var color = _ref.color,
                num = _ref.num;

            var classList = ['flex-table', 'f1', color + '-text', hideZeros && !num ? 'hide-flag' : ''];
            return _react.default.createElement(
                'div',
                { className: classList.join(' ') },
                _react.default.createElement('span', { className: 'icon-flag' }),
                _react.default.createElement(
                    'span',
                    { className: 'count' },
                    countText(num)
                )
            );
        };

        return _react.default.createElement(
            'div',
            { className: 'flags-cell column f2' },
            _react.default.createElement(FlagColumn, { color: 'red', num: numRed }),
            _react.default.createElement(FlagColumn, { color: 'orange', num: numOrange }),
            _react.default.createElement(FlagColumn, { color: 'yellow', num: numYellow })
        );
    };

    var ChecklistHierarchyItemRow = exports.ChecklistHierarchyItemRow = function (_Component) {
        _inherits(ChecklistHierarchyItemRow, _Component);

        function ChecklistHierarchyItemRow() {
            _classCallCheck(this, ChecklistHierarchyItemRow);

            return _possibleConstructorReturn(this, (ChecklistHierarchyItemRow.__proto__ || Object.getPrototypeOf(ChecklistHierarchyItemRow)).apply(this, arguments));
        }

        _createClass(ChecklistHierarchyItemRow, [{
            key: 'componentDidMount',
            value: function componentDidMount() {
                _npmReactTooltip.default.rebuild();
            }
        }, {
            key: 'componentDidUpdate',
            value: function componentDidUpdate() {
                _npmReactTooltip.default.rebuild();
            }
        }, {
            key: 'render',
            value: function render() {
                var _props = this.props,
                    hierarchy = _props.hierarchy,
                    item = _props.item,
                    focusItem = _props.focusItem,
                    clickFunc = _props.clickFunc,
                    clickable = _props.clickable,
                    navigable = _props.navigable;


                var doneContent = void 0;
                if (hierarchy.type === 'store') {
                    doneContent = item.completed_at != null ? _react.default.createElement(
                        'div',
                        _extends({ className: 'column f1' }, tooltipAttrs('basicTooltip', _npmMoment.default.utc(item.completed_at).local().format('ddd, MMM Do YYYY, h:mma[m]'), 'bottom')),
                        _react.default.createElement(
                            'span',
                            null,
                            formatDate(_npmMoment.default.utc(item.completed_at).local())
                        )
                    ) : _react.default.createElement('div', { className: 'column f1' });
                } else {
                    var numCompleted = ['COMPLETED', 'LATE'].map(function (s) {
                        return item.statusCounts[s] || 0;
                    }).reduce(function (agg, x) {
                        return agg + x;
                    }, 0);
                    var numTotal = Object.keys(item.statusCounts).map(function (s) {
                        return item.statusCounts[s];
                    }).reduce(function (agg, x) {
                        return agg + x;
                    }, 0);
                    doneContent = numCompleted == numTotal ? _react.default.createElement(
                        'div',
                        { className: 'column f1' },
                        _react.default.createElement(
                            'span',
                            { style: { color: '#5fca7d', paddingRight: '0.25rem' } },
                            numCompleted
                        ),
                        _react.default.createElement('span', { className: 'icon-check', style: { color: '#5fca7d' } })
                    ) : _react.default.createElement(
                        'div',
                        { className: 'column f1' },
                        _react.default.createElement(
                            'span',
                            { style: { color: '#5fca7d', paddingRight: '0.25rem' } },
                            numCompleted
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            ' / ' + numTotal
                        )
                    );
                }

                var tooltip = navigable ? tooltipAttrs('basicTooltip', hierarchy.name, 'bottom') : {};

                var _onClick = function _onClick(e, key) {
                    clickFunc(hierarchy, item, key);
                    e.stopPropagation();
                };

                return _react.default.createElement(
                    'div',
                    { className: 'row ' + (clickable ? 'pointer' : null), style: this.props.style, onClick: clickable ? function (e) {
                            return _onClick(e, 'row');
                        } : null },
                    _react.default.createElement(
                        'div',
                        { className: 'wrapper f1' },
                        _react.default.createElement(
                            'div',
                            _extends({ className: 'column f3 text' }, tooltip, { onClick: function onClick(e) {
                                    return navigable ? _onClick(e, 'name') : e.stopPropagation();
                                }, style: navigable ? {
                                    cursor: 'pointer'
                                } : {
                                    cursor: 'default',
                                    color: '#ccc'
                                } }),
                            hierarchy.type === 'store' ? hierarchy.code + ' - ' + hierarchy.name : hierarchy.name
                        ),
                        hierarchy.type === 'store' ? _react.default.createElement(
                            _react.Fragment,
                            null,
                            _react.default.createElement(DateCell, { date: _npmMoment.default.utc(focusItem.start_date), exceptionDate: item.start_date_exception, showException: true }),
                            _react.default.createElement(DateCell, { date: _npmMoment.default.utc(focusItem.due_date), exceptionDate: item.due_date_exception, showException: true })
                        ) : _react.default.createElement(
                            _react.Fragment,
                            null,
                            _react.default.createElement(DateCell, { date: _npmMoment.default.utc(focusItem.start_date), exceptionDate: item.minStartDate, showException: true }),
                            _react.default.createElement(DateCell, { date: _npmMoment.default.utc(focusItem.due_date), exceptionDate: item.maxDueDate, showException: true, tooltipFunc: function tooltipFunc(d) {
                                    return tooltipAttrs('basicTooltip', 'Latest exception date: ' + d.format('ddd MMM Do'), 'bottom');
                                } })
                        ),
                        doneContent,
                        _react.default.createElement(
                            'div',
                            { className: 'column f1' },
                            Math.round(item.hours).toLocaleString()
                        ),
                        hierarchy.type === 'store' ? _react.default.createElement(StoreItemStatusCell, { status: _constants.ChecklistItemStatus[item.status] }) : _react.default.createElement(ItemStatusCell, { statusCounts: item.statusCounts }),
                        _react.default.createElement(FlagsCell, { hideZeros: true })
                    )
                );
            }
        }]);

        return ChecklistHierarchyItemRow;
    }(_react.Component);

    var ChecklistItemRow = exports.ChecklistItemRow = function (_PureComponent) {
        _inherits(ChecklistItemRow, _PureComponent);

        function ChecklistItemRow() {
            _classCallCheck(this, ChecklistItemRow);

            return _possibleConstructorReturn(this, (ChecklistItemRow.__proto__ || Object.getPrototypeOf(ChecklistItemRow)).apply(this, arguments));
        }

        _createClass(ChecklistItemRow, [{
            key: 'componentDidMount',
            value: function componentDidMount() {
                _npmReactTooltip.default.rebuild();
            }
        }, {
            key: 'componentDidUpdate',
            value: function componentDidUpdate() {
                _npmReactTooltip.default.rebuild();
            }
        }, {
            key: 'render',
            value: function render() {
                var _props2 = this.props,
                    item = _props2.item,
                    weekStart = _props2.weekStart,
                    clickFunc = _props2.clickFunc,
                    clickable = _props2.clickable;


                var statusCounts = {};
                var numCompleted = (item.status_count['completed'] || 0) + (item.status_count['late'] || 0);
                var numTotal = Object.keys(item.status_count).map(function (k) {
                    return item.status_count[k];
                }).reduce(function (agg, x) {
                    return agg + x;
                }, 0);
                Object.keys(item.status_count).forEach(function (status) {
                    statusCounts[status.toUpperCase()] = item.status_count[status];
                });

                var startTooltipAttrs = item.start_date != item.min_start_date ? tooltipAttrs('basicTooltip', 'Earliest exception: ' + formatDate(item.min_start_date), 'bottom') : null;
                var dueTooltipAttrs = item.due_date != item.max_due_date ? tooltipAttrs('basicTooltip', 'Latest exception: ' + formatDate(item.max_due_date), 'bottom') : null;

                var doneContent = numCompleted == numTotal ? _react.default.createElement(
                    _react.default.Fragment,
                    null,
                    _react.default.createElement(
                        'span',
                        { style: { color: '#5fca7d', paddingRight: '0.25rem' } },
                        numCompleted
                    ),
                    _react.default.createElement('span', { className: 'icon-check', style: { color: '#5fca7d' } })
                ) : _react.default.createElement(
                    _react.default.Fragment,
                    null,
                    _react.default.createElement(
                        'span',
                        { style: { color: '#5fca7d', paddingRight: '0.25rem' } },
                        numCompleted
                    ),
                    _react.default.createElement(
                        'span',
                        null,
                        ' / ' + numTotal
                    )
                );

                var nameClickFunc = clickable ? function (_) {
                    return clickFunc(item.id);
                } : null;

                var fromPreviousWeek = !(0, _npmMoment.default)(item.max_due_date).isSameOrAfter(weekStart);
                var hasOverdue = statusCounts['OVERDUE'] > 0;
                var showingBecauseOverdue = fromPreviousWeek && hasOverdue;

                return _react.default.createElement(
                    'div',
                    { className: 'row', style: this.props.style },
                    _react.default.createElement(
                        'div',
                        { className: 'wrapper f1' },
                        _react.default.createElement(
                            'div',
                            _extends({ className: 'column f3 text ' + (clickable ? 'pointer' : '') + ' ellipsis-text' }, tooltipAttrs('itemDescriptionTooltip', item.id, 'bottom'), { onClick: nameClickFunc }),
                            item.name
                        ),
                        _react.default.createElement(
                            'div',
                            _extends({ className: 'column f1 date-cell' }, startTooltipAttrs),
                            formatDate(item.start_date)
                        ),
                        _react.default.createElement(
                            'div',
                            _extends({ className: 'column f1 date-cell' }, dueTooltipAttrs, { style: showingBecauseOverdue ? { fontWeight: 'bold', color: '#f00' } : null }),
                            formatDate(item.due_date)
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'column f1' },
                            doneContent
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'column f1' },
                            Math.round(item.total_hours).toLocaleString()
                        ),
                        _react.default.createElement(ItemStatusCell, { statusCounts: statusCounts }),
                        _react.default.createElement(FlagsCell, { hideZeros: true })
                    )
                );
            }
        }]);

        return ChecklistItemRow;
    }(_react.PureComponent);

    var ChecklistStoreItemRow = exports.ChecklistStoreItemRow = function (_PureComponent2) {
        _inherits(ChecklistStoreItemRow, _PureComponent2);

        function ChecklistStoreItemRow() {
            _classCallCheck(this, ChecklistStoreItemRow);

            return _possibleConstructorReturn(this, (ChecklistStoreItemRow.__proto__ || Object.getPrototypeOf(ChecklistStoreItemRow)).apply(this, arguments));
        }

        _createClass(ChecklistStoreItemRow, [{
            key: 'componentDidMount',
            value: function componentDidMount() {
                _npmReactTooltip.default.rebuild();
            }
        }, {
            key: 'componentDidUpdate',
            value: function componentDidUpdate() {
                _npmReactTooltip.default.rebuild();
            }
        }, {
            key: 'render',
            value: function render() {
                var _props3 = this.props,
                    item = _props3.item,
                    storeitem = _props3.storeitem,
                    weekStart = _props3.weekStart,
                    clickFunc = _props3.clickFunc,
                    clickable = _props3.clickable;


                var doneContent = storeitem.completed_at != null ? _react.default.createElement(
                    'div',
                    _extends({ className: 'column f1' }, tooltipAttrs('basicTooltip', _npmMoment.default.utc(storeitem.completed_at).local().format('ddd, MMM Do YYYY, h:mma[m]'), 'bottom')),
                    _react.default.createElement(
                        'span',
                        null,
                        formatDate(_npmMoment.default.utc(storeitem.completed_at).local())
                    )
                ) : _react.default.createElement('div', { className: 'column f1' });

                var rowClasses = ['row'];
                if (clickable) rowClasses.push('pointer');
                var onClick = clickable ? function (_) {
                    return clickFunc(item, storeitem);
                } : null;

                var fromPreviousWeek = !(0, _npmMoment.default)(storeitem.due_date_exception != null ? storeitem.due_date_exception : item.due_date).isSameOrAfter(weekStart);
                var isOverdue = storeitem.status == 'OVERDUE';
                var showingBecauseOverdue = fromPreviousWeek && isOverdue;

                return _react.default.createElement(
                    'div',
                    { className: rowClasses.join(' '), style: this.props.style, onClick: onClick },
                    _react.default.createElement(
                        'div',
                        { className: 'wrapper f1' },
                        _react.default.createElement(
                            'div',
                            _extends({ className: 'column f3 text' }, tooltipAttrs('itemDescriptionTooltip', item.id, 'bottom')),
                            item.name
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'column f1 date-cell ' + (storeitem.start_date_exception == null ? 'gray-lighten-1-text' : '') },
                            formatDate(storeitem.start_date_exception != null ? storeitem.start_date_exception : item.start_date)
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'column f1 date-cell ' + (storeitem.due_date_exception == null && !showingBecauseOverdue ? 'gray-lighten-1-text' : ''), style: showingBecauseOverdue ? { fontWeight: 'bold', color: '#f00' } : null },
                            formatDate(storeitem.due_date_exception != null ? storeitem.due_date_exception : item.due_date)
                        ),
                        doneContent,
                        _react.default.createElement(
                            'div',
                            { className: 'column f1' },
                            Math.round(storeitem.hours).toLocaleString()
                        ),
                        _react.default.createElement(StoreItemStatusCell, { status: _constants.ChecklistItemStatus[storeitem.status] }),
                        _react.default.createElement(FlagsCell, { hideZeros: true })
                    )
                );
            }
        }]);

        return ChecklistStoreItemRow;
    }(_react.PureComponent);
});