define('matrix-frontend/pods/components/schedule-page-section-totals/component', ['exports', 'matrix-frontend/actions/_types', 'ember-redux', 'npm:moment', 'matrix-frontend/utils/date-helper'], function (exports, _types, _emberRedux, _npmMoment, _dateHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var stateToComputed = function stateToComputed(state) {
        return {
            clients: state.client.clients,
            node: state.cube.node,
            weekEndDate: state.cube.weekEndDate,
            stores: state.node.stores,
            shifts: state.scheduleEdit.shifts,
            atRiskData: state.scheduleEdit.atRiskData,
            date: state.cube.date,
            displayStart: state.scheduleEdit.displayStart,
            displayEnd: state.scheduleEdit.displayEnd,
            closedAllDay: state.scheduleEdit.closedAllDay
            // noSchedule: state.scheduleEdit.noSchedule,
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {};
    };

    var Comp = Ember.Component.extend({
        currentClient: Ember.computed('clients', 'node', function () {
            var node = this.get('node');
            var clients = this.get('clients');
            return node && clients ? clients[node.clientCode] : null;
        }),

        activities: Ember.computed('currentClient.activities', function () {
            var currentClient = this.get('currentClient');
            if (currentClient && currentClient.activities) {
                return currentClient.activities.reduce(function (a, v) {
                    a[v.id] = v;
                    return a;
                }, {});
            }
        }),

        currentStore: Ember.computed('node', 'stores', function () {
            var node = this.get('node');
            var stores = this.get('redux').store.getState().node.stores;
            if (node && stores) {
                return stores[node.clientCode + '.' + node.tag];
            } else {
                return null;
            }
        }),

        dotw: Ember.computed('date', function () {
            var date = this.get('date');
            if (!date) {
                return null;
            }
            return (0, _dateHelper.getDayOfClientWeek)(date) + 1;
        }),

        // TODO:
        // - Calculate moments for each dotw for display in "Go to ___" links
        dates: Ember.computed('weekEndDate', function () {
            var week = (0, _dateHelper.getWeek)(this.get('weekEndDate'));
            return week;
        }),

        hours: Ember.computed('displayStart', 'displayEnd', function () {
            var displayStart = this.get('displayStart');
            var displayEnd = this.get('displayEnd');
            var hoursToSpan = displayEnd.diff(displayStart, 'hours') + 1;
            var raw = [].concat(_toConsumableArray(Array(hoursToSpan).keys()));
            return raw.map(function (x) {
                var t = displayStart.clone().add(x, 'hours');
                return {
                    hour: t.format('H'),
                    ampm: t.format('a')
                };
            });
        }),

        displayedEmployeeIds: Ember.computed('employees', function () {
            var employees = this.get('employees') || [];
            return new Set(employees.map(function (x) {
                return x.id;
            }));
        }),

        numEmployeesDisplayed: Ember.computed('displayedEmployeeIds', function () {
            var employees = this.get('employees') || new Set();
            return employees.length;
        }),

        numEmployees: Ember.computed('node', 'weekEndDate', 'stores', function () {
            var store = this.get('currentStore');
            if (this.weekEndDate && store) {
                var weekEndDate = this.get('weekEndDate').format('YYYY-MM-DD');
                if (store && store.weeks && store.weeks[weekEndDate]) {
                    // console.log('weekEndDate', weekEndDate)
                    // console.log('store', store.weeks)
                    return store.weeks[weekEndDate].employees.length;
                }
            }
            return 0;
        }),

        storeIsOpen: Ember.computed('currentStore', 'weekEndDate', function () {
            var currentStore = this.get('currentStore');
            if (this.weekEndDate && currentStore) {
                var weekEndDate = this.get('weekEndDate').format('YYYY-MM-DD');

                var storeIsOpen = [].concat(_toConsumableArray(Array(7).keys())).reduce(function (o, i) {
                    if (currentStore && currentStore.weeks && currentStore.weeks[weekEndDate] && currentStore.weeks[weekEndDate].hours) {
                        var hours = currentStore.weeks[weekEndDate].hours[i + 1];
                        o[i + 1] = hours.closedAllDay !== 'Y';
                    } else {
                        o[i + 1] = null;
                    }
                    return o;
                }, {});

                return storeIsOpen;
            }
            return null;
        }),

        statistics: Ember.computed('shifts', 'date', 'currentStore', 'activities', 'displayedEmployeeIds', function () {
            var date = this.get('date');
            var shifts = this.noSchedule == false && this.get('shifts') || {};
            var activities = this.get('activities');
            var displayedEmployeeIds = this.get('displayedEmployeeIds');

            // Calculate total hours for week
            var weeklyHours = Object.keys(shifts).map(function (x) {
                return shifts[x];
            }).filter(function (x) {
                return x.meta.employeeId && displayedEmployeeIds.has(x.meta.employeeId);
            }).reduce(function (o, i) {
                i.blocks.forEach(function (block) {
                    if (activities) {
                        var activityId = block.activityId;

                        var activity = activities[activityId];
                        if (!activity.isCallin) {
                            o += block.end.diff(block.start, 'hours', true);
                        }
                    }
                });
                return o;
            }, 0);

            var vacationHours = this.weekEndDate && this.currentStore && this.currentStore.weeks && this.currentStore.weeks[this.weekEndDate.format('YYYY-MM-DD')] && this.currentStore.weeks[this.weekEndDate.format('YYYY-MM-DD')].employees ? this.currentStore.weeks[this.weekEndDate.format('YYYY-MM-DD')].employees.reduce(function (o, i) {
                if (displayedEmployeeIds.has(i.id)) {
                    var defaultAvailability = i.availability.default || {};
                    var exceptions = i.availability.exceptions || {};
                    var merged = _extends({}, defaultAvailability, exceptions);

                    var totalHours = Object.keys(merged).reduce(function (o, i) {
                        var subtotal = merged[i].reduce(function (x, y) {
                            x += y.creditHours || 0;
                            return x;
                        }, 0);
                        o += subtotal;
                        return o;
                    }, 0);

                    o += totalHours;
                }
                return o;
            }, 0) : 0;

            // Calculate total hours for day (at daily level for a single employee)
            var dailyHours = Object.keys(shifts).map(function (x) {
                return shifts[x];
            }).filter(function (x) {
                return x.meta.employeeId && displayedEmployeeIds.has(x.meta.employeeId);
            }).filter(function (x) {
                return (0, _npmMoment.default)(x.meta.date).isSame(date, 'day');
            }).reduce(function (o, i) {
                i.blocks.forEach(function (block) {
                    if (activities) {
                        var activityId = block.activityId;

                        var activity = activities[activityId];
                        if (!activity.isCallin) {
                            o += block.end.diff(block.start, 'hours', true);
                        }
                    }
                });
                return o;
            }, 0);

            // Calculate total hours per day (at weekly level)
            var calculatedHoursByDayHour = {};
            var calculatedHoursByDay = Object.keys(shifts).map(function (x) {
                return shifts[x];
            }).reduce(function (o, i) {
                var dotw = (0, _dateHelper.getDayOfClientWeek)(i.meta.date) + 1;
                if (date) {
                    if (!o[dotw]) {
                        o[dotw] = {};
                    }
                } else {
                    if (!o[dotw]) {
                        o[dotw] = 0;
                    }
                }

                // Only include assigned hours
                if (i.meta.employeeId && displayedEmployeeIds.has(i.meta.employeeId)) {
                    i.blocks.forEach(function (block) {
                        if (activities) {
                            var activityId = block.activityId;

                            var activity = activities[activityId];
                            if (!activity.isCallin) {
                                if (date) {
                                    var currentMoment = block.start.clone();
                                    while (currentMoment.isBefore(block.end, 'minutes')) {
                                        var hour = currentMoment.hours();
                                        if (!o[dotw][hour]) {
                                            o[dotw][hour] = 0;
                                        }
                                        o[dotw][hour] += 0.25;
                                        currentMoment.add(15, 'minutes');
                                    }
                                } else {
                                    o[dotw] += block.end.diff(block.start, 'hours', true);
                                }
                            }
                        }
                    });
                }

                return o;
            }, {});

            var arrayFill = date ? 24 : 7;
            var hoursByDay = [].concat(_toConsumableArray(Array(arrayFill).keys())).reduce(function (o, i) {
                o[i + 1] = 0;

                return o;
            }, {});

            hoursByDay = _extends({}, hoursByDay, calculatedHoursByDay);

            var stats = {
                count: Object.keys(shifts).filter(function (x) {
                    return shifts[x].meta.employeeId && displayedEmployeeIds.has(shifts[x].meta.employeeId);
                }).length,
                weeklyHours: weeklyHours,
                vacationHours: vacationHours,
                dailyHours: dailyHours,
                hoursByDay: hoursByDay
            };
            return stats;
        })
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});