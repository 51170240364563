define('matrix-frontend/pods/components/mx-table/component', ['exports', 'npm:underscore', 'npm:lodash'], function (exports, _npmUnderscore, _npmLodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        sortBy: null,
        lastSortBy: null,
        reverseSort: false,
        reverseSortHeaders: [], // eslint-disable-line ember/avoid-leaking-state-in-ember-objects

        headers: Ember.computed('data', 'reverseSort', 'sortBy', function () {
            var self = this;
            return _npmUnderscore.default.map(self.get('headerDefs'), function (header) {
                header.labels = _npmUnderscore.default.map(header.labels, function (label) {
                    label = Ember.Object.create(label);
                    label.set('sortBy', self.get('sortBy') === label.key);
                    return Ember.Object.create(label);
                });
                return Ember.Object.create(header);
            });
        }),

        setSort: function setSort(key) {
            var shouldReverse = void 0;
            var doOpposite = _npmLodash.default.includes(this.reverseSortHeaders, key);

            if (doOpposite) {
                if (key === this.get('lastSortBy')) {
                    shouldReverse = !this.get('reverseSort');
                } else {
                    shouldReverse = true;
                }
            } else {
                shouldReverse = key === this.get('lastSortBy') && !this.get('reverseSort');
            }

            this.set('reverseSort', shouldReverse);
            this.set('sortBy', key);
            this.sendAction('setSortParams', {
                // eslint-disable-line ember/closure-actions
                sortBy: this.get('sortBy'),
                lastSortBy: this.get('lastSortBy'),
                reverseSort: this.get('reverseSort')
            });
            this.set('lastSortBy', this.get('sortBy'));
        },

        actions: {
            changeSortBy: function changeSortBy(key) {
                this.setSort(key);
            }
        }
    });
});