define('matrix-frontend/helpers/escape-css', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.escapeCss = escapeCss;

    function _toArray(arr) {
        return Array.isArray(arr) ? arr : Array.from(arr);
    }

    function escapeCss(_ref) {
        var _ref2 = _toArray(_ref),
            incoming = _ref2[0],
            replacementValues = _ref2.slice(1);

        // Usage: Within styleString, include {replace} where desired and (in sequence) the replacementValues will be set in place.
        var cssString = incoming;
        replacementValues.forEach(function (replacementValue) {
            cssString = cssString.replace('{replace}', replacementValue);
        });
        return Ember.String.htmlSafe(cssString);
    }
    exports.default = Ember.Helper.helper(escapeCss);
});