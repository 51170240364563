define('matrix-frontend/components/reports-detail', ['exports', 'ember-react-components', 'matrix-frontend/store', 'react', 'matrix-frontend/constants', 'matrix-frontend/components/react-only/detail-chart', 'matrix-frontend/utils/format-helpers'], function (exports, _emberReactComponents, _store, _react, _constants, _detailChart, _formatHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var RollupTable = function RollupTable(props) {
        var title = props.title,
            columns = props.columns,
            rows = props.rows,
            rollup = props.rollup;


        return _react.default.createElement(
            'div',
            { className: 'full-flex flex-table rollup-table' },
            _react.default.createElement(
                'div',
                { className: 'head' },
                _react.default.createElement(
                    'div',
                    { className: 'row' },
                    _react.default.createElement(
                        'div',
                        { className: 'wrapper f2' },
                        _react.default.createElement(
                            'div',
                            { className: 'column text weight-heavy' },
                            title
                        )
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'wrapper f9' },
                        columns.map(function (dc, i) {
                            return _react.default.createElement(
                                'div',
                                { key: i, className: 'column f1 text weight-heavy center' },
                                dc.text
                            );
                        })
                    )
                )
            ),
            _react.default.createElement(
                'div',
                { className: 'body' },
                rows.map(function (row, ri) {
                    return _react.default.createElement(
                        'div',
                        { key: ri, className: 'row' },
                        _react.default.createElement(
                            'div',
                            { className: 'wrapper f2' },
                            _react.default.createElement(
                                'div',
                                { className: 'column text' },
                                row.text
                            )
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'wrapper f9 ' + (row.classes || '') },
                            columns.map(function (dc, i) {
                                return rollup ? _react.default.createElement(
                                    'div',
                                    { key: i, className: 'column f1 right' },
                                    row.displayValue(rollup[dc.key] || {})
                                ) : _react.default.createElement('div', { key: i, className: 'column f1 fake-text gray-lighten-2-text' });
                            })
                        )
                    );
                })
            )
        );
    };

    var comp = function comp(props) {
        var clientCode = props.clientCode,
            weekEndDate = props.weekEndDate,
            node = props.node,
            report = props.report,
            columns = props.columns,
            rollups = props.rollups,
            hourlyData = props.hourlyData,
            infoRollups = props.infoRollups;


        var fullTag = clientCode + '.' + node.tag;
        var rollup = rollups[fullTag];
        var infoRollup = infoRollups[fullTag];
        var anyNotActive = infoRollup && infoRollup.anyNotActive || false;
        var allNotVisible = infoRollup && infoRollup.allNotVisible || false;
        var allStatusKnown = infoRollup && infoRollup.allStatusKnown || false;

        var CONSIDER_STATUSES = new Set(['salesforecast', 'salesscheduleforecast', 'salesatrisk']);

        var series = report.gathers.filter(function (g) {
            return g.series || g.seriesFunc;
        }).map(function (g) {
            var gSeries = g.series || g.seriesFunc && g.seriesFunc(infoRollup);
            var gWeekEndDate = g.weekEndDate(weekEndDate).format('YYYY-MM-DD');
            var weekData = void 0;
            var reportData = void 0;
            var nodeData = void 0;
            var data = void 0;
            if (weekData = hourlyData[gWeekEndDate]) {
                if (reportData = weekData[g.report]) {
                    if (nodeData = reportData[fullTag]) {
                        data = nodeData.map(function (x, i) {
                            return [i, g.dataTransform ? g.dataTransform(x) : x];
                        });
                    }
                }
            }
            var obj = _extends({
                key: g.key
            }, gSeries, {
                data: data,
                loaded: nodeData !== undefined
            });
            if (CONSIDER_STATUSES.has(g.report)) {
                obj.loaded = obj.loaded && allStatusKnown;
                if (allNotVisible || !allStatusKnown) {
                    obj.data = null;
                }
            }
            return obj;
        });

        var getChartFocusTooltipContent = function getChartFocusTooltipContent(x, seriesData) {
            var day = Math.floor(x / 24);
            var hour = x % 24;
            var mmt = weekEndDate.clone().subtract(6 - day, 'days').hours(hour).minutes(0).seconds(0).milliseconds(0);

            return _react.default.createElement(
                'div',
                { className: 'report-data-tooltip light-content', style: { width: '200px' } },
                _react.default.createElement(
                    'div',
                    { className: 'hourly-time' },
                    mmt.format('dddd, MMM Do h:mm a[m]')
                ),
                report.tooltipContentFor(seriesData, 'detail', infoRollup)
            );
        };

        var chart = _react.default.createElement(_detailChart.DetailChart, { weekEndDate: weekEndDate, reportKey: report.key, minX: 0, maxX: 24 * 7, series: series, focusTooltipContentFunc: getChartFocusTooltipContent });

        var getDetailContent = function getDetailContent() {
            var forecastTextComp = anyNotActive ? _react.default.createElement(
                'span',
                { className: 'yellow white-text italics', style: {
                        padding: '0px 4px 0px 4px',
                        borderRadius: 6,
                        fontWeight: 'bold'
                    } },
                'Prelim Fcst'
            ) : _react.default.createElement(
                'span',
                { className: 'italics' },
                'Forecast'
            );

            switch (report) {
                case _constants.ReportTypes.SALES_VS_FORECAST:
                    return _react.default.createElement(
                        _react.Fragment,
                        null,
                        chart,
                        _react.default.createElement(RollupTable, { title: 'Sales', columns: columns, rows: [{
                                text: 'Actual',
                                displayValue: function displayValue(dcData) {
                                    return (0, _formatHelpers.numberFormat)(dcData.actual);
                                }
                            }, {
                                text: forecastTextComp,
                                classes: 'italics',
                                displayValue: function displayValue(dcData) {
                                    return (0, _formatHelpers.numberFormat)(dcData.forecast);
                                }
                            }, {
                                text: 'Var',
                                displayValue: function displayValue(dcData) {
                                    return (0, _formatHelpers.numberFormat)(dcData.compA && dcData.compB ? dcData.compA - dcData.compB : 0);
                                }
                            }, {
                                text: 'Var %',
                                displayValue: function displayValue(dcData) {
                                    return (0, _formatHelpers.percentFormat)(dcData.compA && dcData.compB ? dcData.compA / dcData.compB - 1 : 0);
                                }
                            }], rollup: rollup })
                    );
                case _constants.ReportTypes.SALES_VS_LY:
                    return _react.default.createElement(
                        _react.Fragment,
                        null,
                        chart,
                        _react.default.createElement(RollupTable, { title: 'Sales', columns: columns, rows: [{
                                text: 'Actual',
                                displayValue: function displayValue(dcData) {
                                    return (0, _formatHelpers.numberFormat)(dcData.actual);
                                }
                            }, {
                                text: 'Actual LY',
                                displayValue: function displayValue(dcData) {
                                    return (0, _formatHelpers.numberFormat)(dcData.actualLY);
                                }
                            }, {
                                text: 'Var',
                                displayValue: function displayValue(dcData) {
                                    return (0, _formatHelpers.numberFormat)(dcData.compA && dcData.compB ? dcData.compA - dcData.compB : 0);
                                }
                            }, {
                                text: 'Var %',
                                displayValue: function displayValue(dcData) {
                                    return (0, _formatHelpers.percentFormat)(dcData.compA && dcData.compB ? dcData.compA / dcData.compB - 1 : 0);
                                }
                            }], rollup: rollup })
                    );
                case _constants.ReportTypes.SALES_AT_RISK:
                    return _react.default.createElement(
                        _react.Fragment,
                        null,
                        chart,
                        _react.default.createElement(RollupTable, { title: 'Sales', columns: columns, rows: [{
                                text: forecastTextComp,
                                classes: 'italics',
                                displayValue: function displayValue(dcData) {
                                    return (0, _formatHelpers.numberFormat)(dcData.forecast);
                                }
                            }, {
                                text: '@ Risk',
                                displayValue: function displayValue(dcData) {
                                    return (0, _formatHelpers.numberFormat)(dcData.salesatrisk);
                                }
                            }, {
                                text: '@ Risk %',
                                displayValue: function displayValue(dcData) {
                                    return (0, _formatHelpers.percentFormat)(dcData.salesatrisk / dcData.forecast, { withSign: false });
                                }
                            }], rollup: rollup })
                    );
                case _constants.ReportTypes.HOURS_VS_OPTIMAL:
                    return _react.default.createElement(
                        _react.Fragment,
                        null,
                        chart,
                        _react.default.createElement(RollupTable, { title: 'Hours', columns: columns, rows: [{
                                text: 'Actual',
                                displayValue: function displayValue(dcData) {
                                    return (0, _formatHelpers.numberFormat)(dcData.actual, { decimals: 2 });
                                }
                            }, {
                                text: 'Optimal',
                                displayValue: function displayValue(dcData) {
                                    return (0, _formatHelpers.numberFormat)(dcData.optimal, { decimals: 2 });
                                }
                            }, {
                                text: 'Var',
                                displayValue: function displayValue(dcData) {
                                    return (0, _formatHelpers.numberFormat)(dcData.compA && dcData.compB ? dcData.compA - dcData.compB : 0, { decimals: 2 });
                                }
                            }, {
                                text: 'Var %',
                                displayValue: function displayValue(dcData) {
                                    return (0, _formatHelpers.percentFormat)(dcData.compA && dcData.compB ? dcData.compA / dcData.compB - 1 : 0);
                                }
                            }], rollup: rollup })
                    );
                default:
                    return null;
            }
        };

        return getDetailContent();
    };

    exports.default = (0, _emberReactComponents.default)(comp);
});