define('matrix-frontend/router', ['exports', 'matrix-frontend/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Router = Ember.Router.extend({
        location: _environment.default.locationType,
        rootURL: _environment.default.rootURL,

        redirects: {
            'poassword.forgot': 'password.forgot'
        }

        // notifyGoogleAnalytics: function() {
        //     return ga('send', 'pageview', {
        //     'page': this.get('url'),
        //     'title': this.get('url')
        //     });
        // }.on('didTransition')
    });

    Router.map(function () {
        this.route('clients', { resetNamespace: true }, function () {
            this.route('show', { path: ':client_id' }, function () {
                this.route('transport', function () {});
                this.route('attachments', { resetNamespace: true }, function () {});

                this.route('hierarchyNodes', { resetNamespace: true }, function () {
                    // eslint-disable-line ember/no-capital-letters-in-routes
                    this.route('show', { path: ':hierarchyNode_id' }); // eslint-disable-line ember/routes-segments-snake-case
                });

                this.route('reports', { resetNamespace: true }, function () {
                    this.route('show', { path: ':report_name' });
                    this.route('tps');
                });

                this.route('new-reports', { resetNamespace: true }, function () {});

                this.route('stores', { resetNamespace: true }, function () {
                    this.route('edit');
                });

                this.route('users', { resetNamespace: true }, function () {});

                this.route('employees', { resetNamespace: true }, function () {});
                this.route('new-checklist', { resetNamespace: true }, function () {});
                this.route('new-payroll-worksheet', { resetNamespace: true }, function () {});

                this.route('schedule', { resetNamespace: true }, function () {});
                this.route('schedule-print', { resetNamespace: true }, function () {});

                this.route('legacy', { resetNamespace: true }, function () {
                    this.route('checklist');
                    this.route('employeeavailability');
                    this.route('employeeaddedit');
                    this.route('employeeaddedituser');
                    this.route('employeeminmaxhours');
                    this.route('employeerehirelist');
                    this.route('employeerehirestartdate');
                    this.route('employeeroster');
                    this.route('employeeskills');
                    this.route('financialprinttrackingsheet');
                    this.route('financialstorebudgets');
                    this.route('scheduleedit');
                    this.route('scheduleemail');
                    this.route('schedulegenerate');
                    this.route('scheduleprint');
                    this.route('storehours');
                });

                this.route('user-management', { resetNamespace: true }, function () {});
                this.route('rolescope-management', { resetNamespace: true }, function () {});
            });
        });
        this.route('application_error', { path: '*path' });
        this.route('claim', { resetNamespace: true }, function () {
            this.route('show', { path: ':claim_token' });
        });
        // This is for forwarding and can be deprecated
        this.route('poassword', { resetNamespace: true }, function () {
            this.route('forgot');
        });
        this.route('password', { resetNamespace: true }, function () {
            this.route('show', { path: '/:action/:clientCode/:email' });
            this.route('forgot');
        });
        this.route('email', { resetNamespace: true }, function () {
            this.route('show', { path: '/:action/:clientCode/:email' });
        });
        this.route('login');
        this.route('logged-out');
        this.route('user-profile', { resetNamespace: true }, function () {});
        this.route('apidocs', { resetNamespace: true }, function () {});
    });

    exports.default = Router;
});