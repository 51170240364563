define('matrix-frontend/services/permission-service', ['exports', 'ember-data', 'npm:underscore'], function (exports, _emberData, _npmUnderscore) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        isMatrixAdmin: Ember.computed(function () {
            return _emberData.default.PromiseObject.create({
                promise: this.isHasRole(['matrix_admin']).then(function (result) {
                    return result;
                })
            });
        }),

        isCanEditContactInfo: Ember.computed(function () {
            return _emberData.default.PromiseObject.create({
                promise: this.isHasRole(['admin', 'matrix_admin', 'schedule3']).then(function (result) {
                    return result;
                })
            });
        }),

        isCanEditForecasts: Ember.computed(function () {
            return _emberData.default.PromiseObject.create({
                promise: this.isHasRole(['admin', 'matrix_admin', 'schedule1', 'schedule2', 'schedule3']).then(function (result) {
                    return result;
                })
            });
        }),

        getRoles: function getRoles() {
            return this.session.get('user.roles').then(function (roles) {
                return roles.mapBy('name');
            });
        },
        isHasRole: function isHasRole(roleName) {
            return this.getRoles().then(function (roles) {
                if (Array.isArray(roleName)) {
                    var intersection = _npmUnderscore.default.intersection(roles, roleName);
                    if (intersection.length > 0) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    if (_npmUnderscore.default.contains(roles, roleName)) {
                        return true;
                    } else {
                        return false;
                    }
                }
            });
        }
    });
});