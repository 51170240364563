define('matrix-frontend/utils/format-helpers', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    exports.default = {
        formatPercent: function formatPercent(numerator, denominator) {
            var percent;

            if (denominator === 0 || numerator === 0) {
                percent = '-';
            } else {
                percent = Math.round(numerator / denominator * 100);

                if (percent > 1000 || percent < -100) {
                    percent = 'n/m';
                } else {
                    percent = percent + '%';
                }
            }

            return percent;
        },

        fixAllCaps: function fixAllCaps(text) {
            if (text === text.toUpperCase()) {
                return text.split(' ').map(function (word) {
                    return word.toLowerCase().capitalize();
                }).join(' ');
            } else {
                return text;
            }
        },

        phoneNumber: function phoneNumber(text) {
            if (!text) {
                return '';
            }
            var regex = /(1?)(\d{3})(\d{3})(\d{4})/gm;
            var subst = '($2) $3-$4';
            return text.replace(/\D/g, '').replace(regex, subst);
        }
    };
    var numberFormat = exports.numberFormat = function numberFormat(x, options) {
        options = _extends({
            decimals: 0,
            showZero: false
        }, options);
        if (x == null) return null;
        var rounded = Math.round(x * Math.pow(10, options.decimals));
        if (!rounded && !options.showZero || !isFinite(rounded)) return '-';

        var f = Intl.NumberFormat('en-US', {
            minimumFractionDigits: options.decimals,
            maximumFractionDigits: options.decimals,
            signDisplay: 'never'
        });
        var prefix = options.prefix || '';
        return x >= 0 ? '' + prefix + f.format(x) : '(' + prefix + f.format(-x) + ')';
    };

    var percentFormat = exports.percentFormat = function percentFormat(x, options) {
        options = _extends({
            decimals: 1,
            withSign: true
        }, options);
        if (x == null) return null;
        var rounded = Math.round(x * 100 * Math.pow(10, options.decimals));
        if (!rounded || !isFinite(x)) return '-';

        var f = Intl.NumberFormat('en-US', {
            style: 'percent',
            signDisplay: options.withSign ? 'always' : 'never',
            minimumFractionDigits: options.decimals,
            maximumFractionDigits: options.decimals
        });
        return f.format(x);
    };
});