define('matrix-frontend/pods/email/show/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        queryParams: {
            token: {
                refreshModel: true
            }
        },
        model: function model(params, transition) {
            var _transition$params$em = transition.params['email.show'],
                action = _transition$params$em.action,
                email = _transition$params$em.email,
                clientCode = _transition$params$em.clientCode,
                token = transition.queryParams.token;

            return { action: action, email: email, token: token, clientCode: clientCode };
        }
    });
});