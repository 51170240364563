define('matrix-frontend/helpers/icon-circle-shift-status', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.iconCircleShiftStatus = iconCircleShiftStatus;

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    function _toArray(arr) {
        return Array.isArray(arr) ? arr : Array.from(arr);
    }

    function iconCircleShiftStatus(_ref, _ref2) {
        var _ref3 = _toArray(_ref);

        var employeeId = _ref2.employeeId,
            assignableEmployeeIds = _ref2.assignableEmployeeIds,
            availableEmployeeIds = _ref2.availableEmployeeIds,
            skilledEmployeeIds = _ref2.skilledEmployeeIds,
            smartAssignEmployee = _ref2.smartAssignEmployee;

        var classes = new Set(['icon-circle']);
        var cont = true;

        if (assignableEmployeeIds && assignableEmployeeIds.has(employeeId) && availableEmployeeIds && availableEmployeeIds.has(employeeId) && skilledEmployeeIds && skilledEmployeeIds.has(employeeId)) {
            classes.add('green-text');
            cont = false;
        }

        if (cont && availableEmployeeIds && availableEmployeeIds.has(employeeId) && skilledEmployeeIds && skilledEmployeeIds.has(employeeId)) {
            classes.add('orange-text');
            cont = false;
        }

        if (cont && availableEmployeeIds && availableEmployeeIds.has(employeeId)) {
            classes.add('yellow-text');
            cont = false;
        }

        if (cont) {
            classes.add('gray-text');
        }

        return [].concat(_toConsumableArray(classes)).join(' ');
    }

    exports.default = Ember.Helper.helper(iconCircleShiftStatus);
});