define('matrix-frontend/components/report-navigation-bar', ['exports', 'ember-react-components', 'react', 'npm:react-tooltip'], function (exports, _emberReactComponents, _react, _npmReactTooltip) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var reportViewEnabled = function reportViewEnabled(report, nodeType, view) {
        if (!report) {
            return false;
        }
        if (nodeType === 'store' && view === 'summary') {
            return false;
        }
        return report.views.includes(view);
    };

    var comp = function (_Component) {
        _inherits(comp, _Component);

        function comp(props) {
            _classCallCheck(this, comp);

            var _this = _possibleConstructorReturn(this, (comp.__proto__ || Object.getPrototypeOf(comp)).call(this, props));

            _this.state = {
                playing: false,
                intervalId: null
            };
            _this.nextFrame = _this.nextFrame.bind(_this);
            _this.setPlaying = _this.setPlaying.bind(_this);
            return _this;
        }

        _createClass(comp, [{
            key: 'nextFrame',
            value: function nextFrame() {
                var _props = this.props,
                    timeColumn = _props.timeColumn,
                    selectTimeColumn = _props.selectTimeColumn;

                selectTimeColumn(timeColumn == null || timeColumn == 6 ? 0 : timeColumn + 1);
            }
        }, {
            key: 'setPlaying',
            value: function setPlaying(playing) {
                if (playing == this.state.playing) return;
                if (!playing) {
                    clearInterval(this.state.intervalId);
                    this.setState({
                        playing: playing,
                        intervalId: null
                    });
                } else {
                    this.setState({
                        playing: playing,
                        intervalId: setInterval(this.nextFrame, 1000)
                    });
                }
            }
        }, {
            key: 'componentWillUnmount',
            value: function componentWillUnmount() {
                this.setPlaying(false);
            }
        }, {
            key: 'render',
            value: function render() {
                var _this2 = this;

                var _props2 = this.props,
                    node = _props2.node,
                    reportTypes = _props2.reportTypes,
                    timeSelectorVisible = _props2.timeSelectorVisible,
                    columns = _props2.columns,
                    viewTypes = _props2.viewTypes,
                    report = _props2.report,
                    timeColumn = _props2.timeColumn,
                    view = _props2.view,
                    selectReport = _props2.selectReport,
                    selectTimeColumn = _props2.selectTimeColumn,
                    selectView = _props2.selectView;


                // if(view !== 'map' && this.state.playing) {
                //     this.setPlaying(false)
                // }

                return _react.default.createElement(
                    _react.Fragment,
                    null,
                    _react.default.createElement(
                        'div',
                        { className: 'left report-select' },
                        reportTypes.map(function (reportType, i) {
                            var classes = 'thin';
                            if (reportType == report) {
                                classes += ' selected';
                            }
                            return _react.default.createElement(
                                'button',
                                { key: i, className: classes, onClick: function onClick(_) {
                                        return selectReport(reportType.key);
                                    }, 'data-for': 'basicNavTooltip', 'data-place': 'bottom', 'data-tip': reportType.name },
                                reportType.text
                            );
                        })
                    ),
                    timeSelectorVisible ? _react.default.createElement(
                        'div',
                        { className: 'button-group no-margin day-buttons no-print' },
                        _react.default.createElement(
                            'button',
                            { key: 'play-control', className: 'tiny thin secondary', onClick: function onClick(_) {
                                    return _this2.setPlaying(!_this2.state.playing);
                                } },
                            _react.default.createElement('span', { className: this.state.playing ? 'icon-pause' : 'icon-play', style: { color: '#666' } })
                        ),
                        columns.map(function (tc, i) {
                            var classes = 'tiny thin secondary';
                            if (timeColumn == tc.key) {
                                classes += ' selected';
                            }
                            return _react.default.createElement(
                                'button',
                                { key: i, className: classes, onClick: function onClick(_) {
                                        _this2.setPlaying(false);
                                        selectTimeColumn(tc.key);
                                    } },
                                tc.shortText
                            );
                        })
                    ) : null,
                    _react.default.createElement(
                        'div',
                        { className: 'right report-select' },
                        viewTypes.map(function (viewType, i) {
                            var classes = 'thin';
                            if (viewType.id == view) {
                                classes += ' selected';
                            }
                            var disabled = !reportViewEnabled(report, node.nodeType, viewType.id);
                            if (disabled) {
                                classes += ' disabled2';
                            }
                            return _react.default.createElement(
                                'button',
                                { key: i, className: classes, onClick: function onClick(_) {
                                        _this2.setPlaying(false);
                                        selectView(viewType.id);
                                    } },
                                viewType.text
                            );
                        })
                    ),
                    _react.default.createElement(_npmReactTooltip.default, { id: 'basicNavTooltip', effect: 'solid' })
                );
            }
        }]);

        return comp;
    }(_react.Component);

    exports.default = (0, _emberReactComponents.default)(comp);
});