define('matrix-frontend/pods/components/tps-row/component', ['exports', 'npm:underscore', 'matrix-frontend/utils/custom-functions', 'npm:numbro'], function (exports, _npmUnderscore, _customFunctions, _npmNumbro) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        attributeBindings: ['data-parent'],
        editModal: '',
        tagName: 'tbody',
        ibcByWeek: false,
        visible: false,
        isRowChange: false,

        didRender: function didRender() {
            this._super.apply(this, arguments);
            var visible = this.get('visible');
            if (visible === true) {
                this.$().show();
            } else {
                this.$().hide();
            }
        },


        formattedActivityName: Ember.computed(function () {
            var activityName = this.get('activity.name');
            activityName = activityName.replace(/\s/g, '_');
            return '' + activityName;
        }),

        ibcPrelim: Ember.computed('ibcHours', function () {
            var ibcValues = this.get('ibcHours');

            return _npmUnderscore.default.reduce(ibcValues.values, function (memo, value) {
                return memo + value.get('prelimhours');
            }, 0);
        }),

        values: Ember.computed('activity.values{day1.currentHours,day1.currentUnits,day3.currentHours,day3.currentUnits,day5.currentHours,day5.currentUnits,day7.currentHours,day7.currentUnits,day2.currentHours,day2.currentUnits,day4.currentHours,day4.currentUnits,day6.currentHours,day6.currentUnits}', 'isRowChange', function () {
            var isIbc = this.get('activity.isIbc');
            if (isIbc === true) {
                var values = this.get('activity.values');
                var budget = this.get('budget');
                var aggregateValues = {
                    day1: 0,
                    day2: 0,
                    day3: 0,
                    day4: 0,
                    day5: 0,
                    day6: 0,
                    day7: 0
                };
                budget.forEach(function (b) {
                    if (b.isIbc !== true) {
                        _npmUnderscore.default.each(b.values, function (value, day) {
                            aggregateValues[day] += _npmNumbro.default.unformat(value.get('currentHours') ? value.get('currentHours') : 0);
                        });
                    }
                });
                var ibcPrelim = this.get('ibcPrelim');
                _npmUnderscore.default.each(values, function (value, day) {
                    var hours = _npmNumbro.default.unformat(value.get('currentHours'));
                    //TODO this is only for IBC by week make sure to update
                    if (aggregateValues[day] <= ibcPrelim) {
                        hours = aggregateValues[day];
                        ibcPrelim -= hours;
                    } else {
                        hours = ibcPrelim;
                        ibcPrelim = 0;
                    }

                    value.set('schedulehours', hours);
                });

                return values;
            } else {
                //check for a custom function to process complex labor standard logic
                var customFunction = this.get('activity.customFunction');

                if (customFunction) {
                    return _customFunctions.default[customFunction](this.get('activity'));
                }
                return this.get('activity.values');
            }
        }),

        weekTotal: Ember.computed(
        // 'activity.values{day1.currentHours,day2.currentHours,day3.currentHours,day4.currentHours,day5.currentHours,day6.currentHours,day7.currentHours}',
        'activity', 'values', 'budget', 'isRowChange', function () {
            var data = this.get('activity.values');
            var keyname = this.get('keyname');

            return _npmUnderscore.default.reduce(data, function (memo, item) {
                return memo + Number(item.get(keyname));
            }, 0);
        }),

        updateIbc: function updateIbc() {
            var data = this.get('activity');
            var ibcHours = this.get('ibcHours');
            var ibcTotal = this.get('ibcPrelim');
            _npmUnderscore.default.each(ibcHours.values, function (value, day) {
                var hours = data.values[day].get('currentHours');
                if (ibcTotal - hours >= 0) {
                    value.set('schedulehours', hours ? hours : 0);
                    ibcTotal -= hours;
                } else {
                    value.set('schedulehours', ibcTotal ? ibcTotal : 0);
                    ibcTotal = 0;
                }
                Ember.set(ibcHours.values, day, value);
            });
            this.set('ibcHours', ibcHours);
        },


        actions: {
            closeModal: function closeModal(formUpdated) {
                var day = this.get('editModal');
                var tdId = '#' + this.get('formattedActivityName') + '_' + day;
                Ember.$(tdId).removeClass('tps-td-editing');
                this.set('editModal', '');
                if (formUpdated) {
                    if (this.get('activity.isCanIbc') && this.get('ibcByWeek')) {
                        this.updateIbc();
                    }
                    this.attrs.updateTotals(); // eslint-disable-line ember/no-attrs-in-components
                }
            },
            editModal: function editModal(day) {
                this.set('editModal', day);
                var tdId = '#' + this.get('formattedActivityName') + '_' + day;
                Ember.$(tdId).addClass('tps-td-editing');
            }
        }
    });
});