define('matrix-frontend/routes/index', ['exports', 'matrix-frontend/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        ajax: Ember.inject.service(),
        redux: Ember.inject.service(),

        beforeModel: function beforeModel() {
            var clientId = this.session.get('user.client.id');
            if (clientId) {
                this.transitionTo('clients.show.transport', clientId);
            } else {
                this.transitionTo('clients');
            }
        }
    });
});