define('matrix-frontend/adapters/v2-role', ['exports', 'ember-data', 'matrix-frontend/config/environment'], function (exports, _emberData, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTAdapter.extend({
        redux: Ember.inject.service(),
        host: _environment.default.mxl.replace('${host}', window.location.host),
        // namespace: 'v2',
        defaultSerializer: 'v2-role',

        headers: Ember.computed('session.token', function () {
            var store = this.get('redux').store;
            var token = store.getState().user.currentUser.token;

            if (token) {
                return {
                    Authorization: 'Bearer ' + token
                };
            } else {
                return {};
            }
        }),

        pathForType: function pathForType() {
            return 'user/role';
        },
        sortQueryParams: function sortQueryParams(params) {
            delete params.clientCode;
            delete params.username;
            return params;
        }
    }

    // urlForUpdateRecord(id, modelName, snapshot) {
    //     let url = this._super(...arguments).split('/');
    //     url.pop();
    //     const record = snapshot.record;
    //     url.push(record.get('clientCode'));
    //     url.push(record.get('username'));
    //     url = url.join('/');
    //     return url;
    // }
    );
});