define('matrix-frontend/pods/components/logged-out/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        cookies: Ember.inject.service(),

        actions: {
            clearLoggedOut: function clearLoggedOut() {
                this.get('cookies').write('loggedOut', 0);
                this.transitionToRoute('login');
            }
        }
    });
});