define('matrix-frontend/pods/employees/controller', ['exports', 'matrix-frontend/mixins/base-controller'], function (exports, _baseController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_baseController.default, {
        queryParams: ['hierarchyNodeId', 'storeId', 'view'],
        view: 'overview'
    });
});