define('matrix-frontend/components/react-only/employee-rollup-head', ['exports', 'react', 'npm:moment'], function (exports, _react, _npmMoment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var VIEWS = [{
        display: 'Employees',
        f: 2,
        on: true
    }, {
        display: 'Availability',
        f: 2,
        on: false
    }, {
        display: 'Hrs/Wk',
        f: 1,
        on: true
    }, {
        display: 'Skills',
        f: 3,
        on: true
    }, {
        display: 'Users',
        f: 2,
        on: true
    }];

    var Header = function Header(_ref) {
        var pageView = _ref.pageView,
            display = _ref.display,
            f = _ref.f,
            view = _ref.view;

        var viewDisplay = view ? _react.default.createElement('span', {
            className: 'breathe-l-5 icon-' + (view === pageView ? 'minus' : 'plus') + '-circle'
        }) : '';
        var classes = ['column', 'f' + f];

        if (view) {
            classes.push('pointer');
        }
        // if (pageView !== 'overview' && view !== pageView) {
        //     classes.push('gray-lighten-2-text')
        // }

        return _react.default.createElement(
            'div',
            {
                className: classes.join(' '),
                onClick: function onClick() {
                    return view ? headerClick(pageView, view) : null;
                }
            },
            display,
            viewDisplay
        );
    };

    var comp = function comp(_ref2) {
        var items = _ref2.items;

        return _react.default.createElement(
            'section',
            { id: 'employee-head', className: 'head' },
            _react.default.createElement(
                'div',
                { className: 'row' },
                _react.default.createElement(
                    'div',
                    { className: 'wrapper f2' },
                    _react.default.createElement(
                        'div',
                        { className: 'column text f1 no-left' },
                        '\xA0'
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'column text f6 no-left' },
                        'Name'
                    )
                ),
                _react.default.createElement(
                    'div',
                    { className: 'wrapper f9' },
                    VIEWS.filter(function (x) {
                        return x.on;
                    }).map(function (x, i) {
                        return _react.default.createElement(Header, _extends({ key: i, pageView: null }, x));
                    })
                )
            )
        );
    };

    exports.default = comp;
});