define('matrix-frontend/transforms/formatted-number', ['exports', 'ember-data/transform', 'npm:numbro'], function (exports, _transform, _npmNumbro) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _transform.default.extend({
        format: '0,0.00',

        deserialize: function deserialize(serialized) {
            return (0, _npmNumbro.default)(serialized).format('0,0.00');
        },
        serialize: function serialize(deserialized) {
            return _npmNumbro.default.unformat(deserialized);
        }
    });
});