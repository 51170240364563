define('matrix-frontend/reducers/store-summary', ['exports', 'matrix-frontend/actions/_types', 'npm:moment'], function (exports, _types, _npmMoment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var initialState = {
        dataType: 'public',
        view: 'visual'
    };

    var store = function store(state, action) {
        var type = action.type,
            payload = action.payload;


        switch (type) {
            case _types.SHS_SET:
                {
                    return _extends({}, state, payload);
                }

            default:
                {
                    return state || initialState;
                }
        }
    };

    exports.default = store;
});