define('matrix-frontend/serializers/v2-hierarchy-node', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTSerializer.extend({
        // extractArray: function(store, type, payload) {
        //     return payload.data;
        // }
        modelNameFromPayloadKey: function modelNameFromPayloadKey() {
            return 'v2-hierarchy-node';
        },
        normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
            delete payload.start;
            delete payload.count;
            delete payload.length;
            return this._normalizeResponse(store, primaryModelClass, payload, id, requestType, false);
        }
    });
});