define('matrix-frontend/components/react-only/mouse-area', ['exports', 'react'], function (exports, _react) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var initialState = {
        mouse: {
            state: {},
            event: null
        }

        // Handlers that can be passed:
        // onHold
        // onLeave
        // onMove
        // onMoveOnHold
        // onRelease

    };var comp = function comp(props) {
        var context = props.context;

        var _useState = (0, _react.useState)(initialState),
            _useState2 = _slicedToArray(_useState, 2),
            state = _useState2[0],
            setState = _useState2[1];

        var _useState3 = (0, _react.useState)(null),
            _useState4 = _slicedToArray(_useState3, 2),
            eventProps = _useState4[0],
            setEventProps = _useState4[1];

        var _useState5 = (0, _react.useState)(false),
            _useState6 = _slicedToArray(_useState5, 2),
            present = _useState6[0],
            setPresent = _useState6[1];

        var _useState7 = (0, _react.useState)(false),
            _useState8 = _slicedToArray(_useState7, 2),
            holding = _useState8[0],
            setHolding = _useState8[1];

        var _useState9 = (0, _react.useState)(false),
            _useState10 = _slicedToArray(_useState9, 2),
            touchEvent = _useState10[0],
            setTouchEvent = _useState10[1];

        var _useState11 = (0, _react.useState)(props.enableActions === undefined ? true : props.enableActions),
            _useState12 = _slicedToArray(_useState11, 2),
            enableActions = _useState12[0],
            setEnableActions = _useState12[1];

        (0, _react.useEffect)(function () {
            setEnableActions(props.enableActions);
        }, [props.enableActions]);

        (0, _react.useEffect)(function () {
            if (eventProps) {
                var altKey = eventProps.altKey,
                    clientX = eventProps.clientX,
                    clientY = eventProps.clientY,
                    ctrlKey = eventProps.ctrlKey,
                    offsetX = eventProps.offsetX,
                    offsetY = eventProps.offsetY,
                    shiftKey = eventProps.shiftKey,
                    target = eventProps.target,
                    touches = eventProps.touches,
                    eventName = eventProps.eventName;


                var useTarget = Ember.$(target).hasClass('yield-to-parent') ? target.parentElement : target;

                var mouseState = {
                    altKey: altKey,
                    clientX: clientX,
                    clientY: clientY,
                    ctrlKey: ctrlKey,
                    offsetX: offsetX,
                    offsetY: offsetY,
                    shiftKey: shiftKey,
                    touches: touches,
                    eventName: eventName,
                    present: present,
                    holding: holding,
                    touchEvent: touchEvent,
                    target: useTarget
                };
                setState(_extends({}, state, { state: mouseState }));
            }
        }, [eventProps, present]);

        var setEvent = function setEvent(e, eventName) {
            var altKey = e.altKey,
                clientX = e.clientX,
                clientY = e.clientY,
                ctrlKey = e.ctrlKey,
                offsetX = e.offsetX,
                offsetY = e.offsetY,
                shiftKey = e.shiftKey,
                target = e.target,
                touches = e.touches;

            setEventProps({
                altKey: altKey,
                clientX: clientX,
                clientY: clientY,
                ctrlKey: ctrlKey,
                offsetX: offsetX,
                offsetY: offsetY,
                shiftKey: shiftKey,
                target: target,
                touches: touches,
                eventName: eventName
            });
        };

        var triggerMove = function triggerMove(_) {
            if (props.onMove) {
                props.onMove(context);
            }
        };
        var triggerMoveOnHold = function triggerMoveOnHold(_) {
            if (props.onMoveOnHold && holding) {
                props.onMoveOnHold(context);
            }
        };
        var triggerHold = function triggerHold(_) {
            setHolding(true);
            if (props.onHold) {
                props.onHold(context);
            }
        };
        var triggerRelease = function triggerRelease(_) {
            setHolding(false);
            if (props.onRelease) {
                props.onRelease(context);
            }
        };
        var triggerLeave = function triggerLeave(_) {
            if (props.onLeave) {
                props.onLeave(context);
            }
        };
        var triggerEnter = function triggerEnter(_) {
            if (props.onEnter) {
                props.onEnter(context);
            }
        };

        var mouseMove = function mouseMove(e) {
            if (enableActions) {
                setEvent(e, 'mouseMove');
                triggerMove();
                triggerMoveOnHold();
                return false;
            }
        };

        var touchMove = function touchMove(e) {
            if (enableActions) {
                // console.log({changedTouches: e.changedTouches, touches: e.touches})
                var touch = e.changedTouches[0];
                var target = document.elementFromPoint(touch.clientX, touch.clientY);
                var evnt = _extends({}, e, {
                    target: target,
                    clientX: touch.clientX,
                    clientY: touch.clientY
                });
                setEvent(evnt, 'touchMove');
                triggerMove();
                triggerMoveOnHold();
                return false;
            }
        };

        var mouseDown = function mouseDown(e) {
            if (enableActions) {
                setEvent(e, 'mouseDown');
                triggerHold();
                return false;
            }
        };

        var touchStart = function touchStart(e) {
            if (enableActions) {
                var touch = e.changedTouches[0];
                var target = document.elementFromPoint(touch.clientX, touch.clientY);
                var evnt = _extends({}, e, {
                    target: target,
                    clientX: touch.clientX,
                    clientY: touch.clientY
                });
                setEvent(evnt, 'touchStart');
                setPresent(true);
                setTouchEvent(true);
                // setEvent(e)
                triggerHold();
                return false;
            }
        };

        var mouseUp = function mouseUp(e) {
            if (enableActions) {
                setEvent(e, 'mouseUp');
                triggerRelease();
                return false;
            }
        };

        var touchEnd = function touchEnd(e) {
            if (enableActions) {
                var touch = e.changedTouches[0];
                var target = document.elementFromPoint(touch.clientX, touch.clientY);
                var evnt = _extends({}, e, {
                    target: target,
                    clientX: touch.clientX,
                    clientY: touch.clientY
                });
                setEvent(evnt, 'touchEnd');
                setTouchEvent(false);
                // setEvent(e)
                triggerRelease();
                return false;
            }
        };

        var mouseEnter = function mouseEnter(e) {
            if (enableActions) {
                setEvent(e, 'mouseEnter');
                triggerEnter();
                setPresent(true);
                return false;
            }
        };

        var mouseLeave = function mouseLeave(e) {
            if (enableActions) {
                setPresent(false);
                setEvent(e, 'mouseLeave');
                if (holding) {
                    triggerRelease();
                } else {
                    triggerLeave();
                }
                return false;
            }
        };

        return _react.default.createElement(
            'div',
            {
                onMouseMove: mouseMove,
                onTouchMove: touchMove,
                onMouseDown: mouseDown,
                onTouchStart: touchStart,
                onMouseUp: mouseUp,
                onTouchEnd: touchEnd,
                onMouseEnter: mouseEnter,
                onMouseLeave: mouseLeave,
                className: props.className
            },
            _react.default.Children.map(props.children, function (child) {
                if (!child) return null;
                var use = child.props.useMouse ? state : {};
                return _react.default.cloneElement(child, use);
            })
        );
    };

    exports.default = comp;
});