define('matrix-frontend/pods/components/tps-report/component', ['exports', 'ember-redux', 'matrix-frontend/utils/interactions', 'npm:underscore', 'matrix-frontend/utils/date-helper', 'npm:moment', 'npm:numbro'], function (exports, _emberRedux, _interactions, _npmUnderscore, _dateHelper, _npmMoment, _npmNumbro) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    // 2017-07-06 || AMH
    // - TEMPORARY
    var doHardCodeReverse = true;

    var stateToComputed = function stateToComputed(state) {
        return {
            node: state.cube.node,
            stores: state.node.stores,
            weekEndDate: state.cube.weekEndDate
        };
    };
    var dispatchToActions = function dispatchToActions(dispatch) {
        return {};
    };

    var Comp = Ember.Component.extend({
        permissionService: Ember.inject.service(),
        store: Ember.inject.service(),
        stateService: Ember.inject.service(),
        redux: Ember.inject.service(),

        taskVisible: {}, // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
        classNames: ['tps-report-container'], // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
        forecastUpdatedToTrend: false,
        ibcByWeek: false,
        fetchScheduleStatus: null,
        ibcByWeekAvailable: 0,
        numeralDefaultFormat: '0,0.00',
        payrollBudgetTotal: {
            // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
            total: {
                daily: {
                    day1: 0,
                    day2: 0,
                    day3: 0,
                    day4: 0,
                    day5: 0,
                    day6: 0,
                    day7: 0
                },
                week: 0
            },
            budgetRollups: {} // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
        },

        init: function init() {
            var taskVisible = {};
            var payrollBudget = this.get('payrollBudget');
            _npmUnderscore.default.each(payrollBudget, function (item) {
                _npmUnderscore.default.each(item, function (item) {
                    if (item.isVisible) {
                        var id = Ember.String.dasherize(item.name);
                        taskVisible[id] = false;
                    }
                });
            });
            this.set('taskVisible', taskVisible);
            this._super();
        },
        calcCurrentForecastWeek: function calcCurrentForecastWeek() {
            var dailyForecast = this.get('dailyForecast');
            var week = 0;
            _npmUnderscore.default.each(dailyForecast, function (forecast) {
                week += forecast.get('currentforecast');
            });
            return (0, _npmNumbro.default)(week).format('0,0.00');
        },
        calcPayRollTotals: function calcPayRollTotals() {
            var payrollBudget = this.get('payrollBudget');
            var payrollBudgetTotal = this.get('payrollBudgetTotal');

            Ember.setProperties(payrollBudgetTotal.total, this.initTotals());

            var self = this;

            var isIbcByWeek = false;
            var canIbcAggregate = {
                day1: 0,
                day2: 0,
                day3: 0,
                day4: 0,
                day5: 0,
                day6: 0,
                day7: 0
            };
            var canIbcBudgetId = null;

            _npmUnderscore.default.each(payrollBudget, function (budget, id) {
                Ember.set(payrollBudgetTotal.budgetRollups, id, self.initTotals());
                _npmUnderscore.default.each(budget, function (activity) {
                    _npmUnderscore.default.each(activity.values, function (dayValue, day) {
                        var canBeNegative = dayValue.get('task').get('activity').get('budget').get('canBeNegative');

                        var hours = Number(dayValue.get('currentHours'));

                        var isCanIbc = dayValue.get('task').get('isCanIbc');

                        if (canIbcBudgetId === null && isCanIbc) {
                            canIbcBudgetId = activity.budget;
                        }

                        if (!isIbcByWeek) {
                            isIbcByWeek = dayValue.get('task').get('isIbcByWeek');
                        }

                        var isIbc = dayValue.get('task').get('isIbc');

                        if (isCanIbc) {
                            canIbcAggregate[day] += hours;
                        }

                        if (isIbc === true) {
                            hours = -Math.abs(hours);
                        }

                        //update budget rollup for the activity
                        var rollupDayTotal = payrollBudgetTotal.budgetRollups[activity.budget].daily[day];
                        var rollupWeekTotal = payrollBudgetTotal.budgetRollups[activity.budget].week;

                        rollupDayTotal += hours;
                        rollupWeekTotal += hours;

                        Ember.set(payrollBudgetTotal.budgetRollups[activity.budget].daily, day, rollupDayTotal < 0 && canBeNegative === false ? 0 : rollupDayTotal);
                        Ember.set(payrollBudgetTotal.budgetRollups[activity.budget], 'week', rollupWeekTotal < 0 && canBeNegative === false ? 0 : rollupWeekTotal);
                    });
                });
            });

            //recompute budget rollup affected by ibcByWeek
            if (isIbcByWeek) {
                var ibcPrelimHoursTotals = this.get('ibcPrelimHoursTotals');
                canIbcAggregate = _npmUnderscore.default.mapObject(canIbcAggregate, function (item) {
                    if (item <= ibcPrelimHoursTotals) {
                        ibcPrelimHoursTotals -= item;
                        item = 0;
                    } else {
                        item -= ibcPrelimHoursTotals;
                        ibcPrelimHoursTotals = 0;
                    }
                    return Math.round(item * 100) / 100;
                });
                this.set('ibcByWeekAvailable', ibcPrelimHoursTotals);
                Ember.set(payrollBudgetTotal.budgetRollups[canIbcBudgetId], 'daily', canIbcAggregate);
                Ember.set(payrollBudgetTotal.budgetRollups[canIbcBudgetId], 'week', _npmUnderscore.default.reduce(canIbcAggregate, function (memo, item) {
                    return memo + item;
                }, 0));
            }

            var approvedHours = _npmUnderscore.default.reduce(payrollBudgetTotal.budgetRollups, function (approvedHours, value) {
                approvedHours.daily.day1 += value.daily.day1;
                approvedHours.daily.day2 += value.daily.day2;
                approvedHours.daily.day3 += value.daily.day3;
                approvedHours.daily.day4 += value.daily.day4;
                approvedHours.daily.day5 += value.daily.day5;
                approvedHours.daily.day6 += value.daily.day6;
                approvedHours.daily.day7 += value.daily.day7;
                approvedHours.week += value.week;

                return approvedHours;
            }, {
                daily: {
                    day1: 0,
                    day2: 0,
                    day3: 0,
                    day4: 0,
                    day5: 0,
                    day6: 0,
                    day7: 0
                },
                week: 0
            });

            Ember.set(payrollBudgetTotal, 'total', approvedHours);

            this.set('payrollBudgetTotal', payrollBudgetTotal);
        },
        crunchData: function crunchData(data) {
            var returnData = {};
            var salesMetrics = data.salesMetrics;
            var dailyForecast = data.dailyForecast;
            var scheduleHours = data.scheduleHours;
            var week = { day: 'Week' };

            _npmUnderscore.default.map(dailyForecast, function (item, index) {
                var date = (0, _npmMoment.default)(item.demanddate);
                var day = {};

                _npmUnderscore.default.mapObject(item, function (value, property) {
                    if (property !== 'demanddate') {
                        var v = Math.round(value * 100) / 100;
                        day[property] = v;

                        if (week[property] === undefined) {
                            week[property] = 0;
                        }
                        week[property] += v;
                    }
                });

                _npmUnderscore.default.mapObject(salesMetrics[index], function (value, property) {
                    if (property !== 'demanddate') {
                        var v = Math.round(value * 100) / 100;
                        day[property] = v;

                        if (week[property] === undefined) {
                            week[property] = 0;
                        }

                        week[property] += v;
                    }
                });

                _npmUnderscore.default.mapObject(scheduleHours[index], function (value, property) {
                    if (property !== 'labordate') {
                        day[property] = value;

                        if (week[property] === undefined) {
                            week[property] = 0;
                        }

                        week[property] += value;
                    }
                });

                day['scheduleActual'] = day['actualhours'] !== 0 ? day['actualhours'] : day['scheduledhours'];

                if (week['scheduleActual'] === undefined) {
                    week['scheduleActual'] = day['scheduleActual'];
                } else {
                    week['scheduleActual'] += day['scheduleActual'];
                }

                day.demanddate = item.demanddate;
                day.day = date.format('ddd');
                day.date = date.format('MM/DD');
                var dayText = 'day' + (index + 1);
                returnData[dayText] = day;
            });

            week = _npmUnderscore.default.mapObject(week, function (item, key) {
                if (key === 'day') {
                    return item;
                } else {
                    return Math.round(item * 100) / 100;
                }
            });

            return {
                daily: returnData,
                week: week
            };
        },
        initTotals: function initTotals() {
            var values = {
                daily: {},
                week: 0
            };

            for (var i = 1; i <= 7; i++) {
                var day = 'day' + i;
                values.daily[day] = 0;
            }
            values.week = 0;
            return values;
        },
        traverseAndClose: function traverseAndClose(id) {
            var element = '#' + id;
            var children = Ember.$(element).data().children;
            var self = this;
            var taskVisible = this.get('taskVisible');
            _npmUnderscore.default.each(children, function (child) {
                var e = '#' + child;
                if (Ember.$(e).is(':visible')) {
                    if (taskVisible.hasOwnProperty(child)) {
                        Ember.set(taskVisible, child, false);
                    }
                    Ember.$(e).toggle();
                    var checkbox = e + '-toggle';
                    Ember.$(checkbox).prop('checked', false);
                }
                self.traverseAndClose(child);
            });

            this.set('taskVisible', taskVisible);
        },


        currentStore: Ember.computed('node', 'stores', function () {
            var node = this.get('node');
            var stores = this.get('redux').store.getState().node.stores;
            if (node && stores) {
                return stores[node.clientCode + '.' + node.tag];
            } else {
                return null;
            }
        }),

        scheduleStatus: Ember.computed('weekEndDate', 'currentStore', function () {
            if (this.weekEndDate) {
                var weekEndDate = this.weekEndDate.format('YYYY-MM-DD');
                if (this.currentStore && this.currentStore.weeks && this.currentStore.weeks[weekEndDate]) {
                    return this.currentStore.weeks[weekEndDate].scheduleStatus;
                }
            }
            return null;
        }),

        actualSalesAndCurrentForecast: Ember.computed('dailyForecast.{day1.currentforecast,day2.currentforecast,day3.currentforecast,day4.currentforecast,day5.currentforecast,day6.currentforecast,day7.currentforecast}', 'crunchedData', 'date', function () {
            var crunchedData = this.get('crunchedData.daily');
            var currentForecast = this.get('dailyForecast');
            var actualSalesAndCurrentForecast = {
                daily: {},
                week: 0
            };
            _npmUnderscore.default.map(crunchedData, function (item, day) {
                var forecast = _npmNumbro.default.unformat(currentForecast[day].get('currentforecast'));
                actualSalesAndCurrentForecast.daily[day] = item.salesvalue ? item.salesvalue : forecast;
                actualSalesAndCurrentForecast.week += actualSalesAndCurrentForecast.daily[day];
            });
            return actualSalesAndCurrentForecast;
        }),

        budgetsAsChildIds: Ember.computed('sortedBudgets', function () {
            var sortedBudgetArray = this.get('sortedBudgets').map(function (item) {
                var dasherizedName = Ember.String.dasherize(item.get('name'));
                return '"' + dasherizedName + '-budget"';
            });
            return '[' + sortedBudgetArray.join() + ']';
        }),

        budgetNameMap: Ember.computed('data.budgets', function () {
            var budgets = this.get('data.budgets');
            var budgetMap = {};
            budgets.forEach(function (budget) {
                budgetMap[budget.get('id')] = budget.get('name');
            });

            return budgetMap;
        }),

        budgetTasksAsChildIds: Ember.computed('payrollBudget', function () {
            var payrollBudget = this.get('payrollBudget');
            var budgets = {};

            _npmUnderscore.default.each(payrollBudget, function (item) {
                _npmUnderscore.default.each(item, function (i) {
                    if (i.isVisible === true) {
                        if (budgets[i.budget] === undefined) {
                            budgets[i.budget] = [];
                        }
                        budgets[i.budget].push(Ember.String.dasherize(i.name));
                    }
                });
            });
            return budgets;
        }),

        crunchedData: Ember.computed('data', function () {
            var data = this.get('data');
            // console.log(data)
            return this.crunchData(data);
        }),

        currentForecastWeek: Ember.computed('dailyForecast{day1.currentforecast,day2.currentforecast,day3.currentforecast,day4.currentforecast,day5.currentforecast,day6.currentforecast,day7.currentforecast}', 'crunchedData.daily{day1.salesvalue,day2.salesvalue,day3.salesvalue,day4.salesvalue,day5.salesvalue,day6.salesvalue,day7.salesvalue}', 'lastUpdate', function () {
            var dailyForecast = this.get('dailyForecast');
            var actualSales = this.get('crunchedData.daily');

            var week = 0;

            _npmUnderscore.default.each(dailyForecast, function (forecast, day) {
                var df = _npmNumbro.default.unformat(forecast.get('currentforecast'));
                if (df === undefined) {
                    df = 0;
                }
                var sv = actualSales[day]['salesvalue'];
                week += sv ? sv : df;
            });
            return Math.round(week * 100) / 100;
        }),

        dailyForecast: Ember.computed('crunchedData', function () {
            var data = this.get('data.dailyForecast');
            var store = this.get('store');
            var dailyForecast = {};

            _npmUnderscore.default.each(data, function (forecast) {
                var df = void 0;
                var day = _dateHelper.default.getDay(forecast.demanddate);

                if (store.hasRecordForId('daily-forecast', forecast.id) === false) {
                    df = store.createRecord('daily-forecast', forecast);
                    df.set('currentforecast', (0, _npmNumbro.default)(df.get('currentforecast')).format('0.00'));
                    df.save();
                } else {
                    df = store.peekRecord('daily-forecast', forecast.id);
                }
                dailyForecast[day] = df;
            });

            return dailyForecast;
        }),

        flexPage: Ember.computed('data', function () {
            var attachments = this.get('data.attachments.attachments');

            if (attachments && attachments[0] !== undefined) {
                return attachments[0].id;
            } else {
                return false;
            }
        }),

        forecastPlusSalesWeek: Ember.computed('currentForecastWeek', function () {
            var daily = this.get('crunchedData.daily');
            var dailyForecast = this.get('dailyForecast');
            var week = 0;

            _npmUnderscore.default.each(daily, function (item, day) {
                var forecast = _npmNumbro.default.unformat(dailyForecast[day].get('currentforecast'));
                var sales = item.salesvalue;

                if (isNaN(sales) === false && sales !== undefined && sales !== 0) {
                    week += sales;
                } else if (isNaN(forecast) === false && forecast !== undefined) {
                    week += forecast;
                }
            });

            return week;
        }),

        forecastTrend: Ember.computed('crunchedData', function () {
            var data = this.get('crunchedData.daily');
            var rawTrend = this.get('forecastTrendRaw');

            var forecastTrend = {
                daily: {},
                week: 0
            };

            forecastTrend.week = _npmUnderscore.default.reduce(data, function (memo, item, day) {
                if (item.salesvalue !== 0) {
                    forecastTrend.daily[day] = item.salesvalue;
                } else {
                    forecastTrend.daily[day] = item.scheduleforecast + item.scheduleforecast * rawTrend;
                }

                return memo += forecastTrend.daily[day];
            }, 0);

            forecastTrend.week = Math.round(forecastTrend.week * 100) / 100;

            return forecastTrend;
        }),

        forecastTrendPct: Ember.computed('crunchedData', function () {
            var data = this.get('crunchedData.daily');

            var trendValues = _npmUnderscore.default.reduce(data, function (memo, item) {
                if (item.salesvalue !== 0) {
                    memo.salesvalue += item.salesvalue;
                    memo.scheduleforecast += item.scheduleforecast;
                }
                return memo;
            }, { salesvalue: 0, scheduleforecast: 0 });

            var diff = Math.round((trendValues.salesvalue - trendValues.scheduleforecast) * 100) / 100;
            return diff === 0 ? 0 : Math.round(diff / trendValues.scheduleforecast * 100 * 10) / 10;
        }),

        forecastTrendRaw: Ember.computed('crunchedData', function () {
            var data = this.get('crunchedData.daily');

            var trendValues = _npmUnderscore.default.reduce(data, function (memo, item) {
                if (item.salesvalue !== 0) {
                    memo.salesvalue += item.salesvalue;
                    memo.scheduleforecast += item.scheduleforecast;
                }
                return memo;
            }, { salesvalue: 0, scheduleforecast: 0 });

            var diff = Math.round((trendValues.salesvalue - trendValues.scheduleforecast) * 100) / 100;
            return diff === 0 ? 0 : diff / trendValues.scheduleforecast;
        }),

        ibcPrelimHoursTotals: Ember.computed('ibcTaskHours', function () {
            var ibcTaskHours = this.get('ibcTaskHours.values');

            return _npmUnderscore.default.reduce(ibcTaskHours, function (memo, item) {
                return memo + item.get('prelimhours');
            }, 0);
        }),

        ibcTaskHours: Ember.computed('payrollBudget', function () {
            var payrollBudget = this.get('payrollBudget');
            var flattenedBudgets = [];

            _npmUnderscore.default.each(payrollBudget, function (data) {
                flattenedBudgets = flattenedBudgets.concat(data);
            });
            return _npmUnderscore.default.find(flattenedBudgets, function (budget) {
                if (budget.isIbc === true) {
                    return budget;
                }
            });
        }),

        isSalesComplete: Ember.computed('crunchedData', function () {
            var data = this.get('crunchedData.daily');
            var salesMissing = false;

            _npmUnderscore.default.each(data, function (item) {
                if (item.salesvalue === 0 && item.scheduleforecast !== 0) {
                    salesMissing = true;
                }
            });

            if (salesMissing === false) {
                return true;
            } else {
                return false;
            }
        }),

        lySalesVariance: Ember.computed('dailyForecast{day1.currentforecast,day2.currentforecast,day3.currentforecast,day4.currentforecast,day5.currentforecast,day6.currentforecast,day7.currentforecast}', 'crunchedData', function () {
            var data = this.get('crunchedData.daily');
            var salesOrForecast = this.get('actualSalesAndCurrentForecast');
            var dailyVariance = {};
            var weeksales = 0;
            var weekcurrentValue = 0;
            var weekdiff = 0;
            var weekpct = 0;

            _npmUnderscore.default.each(data, function (item, day) {
                var pct = 0;
                var diff = 0;
                var variance = {};
                var currentValue = salesOrForecast.daily[day];
                var lySales = item.salesvaluely;
                if (lySales !== 0 && lySales !== undefined) {
                    diff = Math.round((currentValue - lySales) * 100) / 100;
                    pct = Math.round(diff / lySales * 1000) / 10;
                    variance = { diff: diff, pct: pct };
                    weeksales += lySales;
                    weekcurrentValue += currentValue;
                } else {
                    variance = { diff: 0, pct: 0 };
                }

                dailyVariance[day] = variance;
            });

            if (weeksales !== 0 && weeksales !== undefined) {
                weekdiff = weekcurrentValue - weeksales;
                weekpct = Math.round(weekdiff / weeksales * 1000) / 10;
            }
            return {
                daily: dailyVariance,
                week: { diff: weekdiff, pct: weekpct }
            };
        }),

        optimizedHours: Ember.computed('data', function () {
            var taskHours = this.get('data.taskHours');
            var optimizedHours = {
                daily: {
                    day1: 0,
                    day2: 0,
                    day3: 0,
                    day4: 0,
                    day5: 0,
                    day6: 0,
                    day7: 0
                },
                week: 0
            };

            taskHours.forEach(function (item) {
                var task = item.get('task');

                if (task.get('isOptimizedHours') === true) {
                    var dayOfWeek = (0, _npmMoment.default)(item.get('taskdate')).day();
                    var day = 'day' + (dayOfWeek + 1);
                    var schedulehours = item.get('currentHours');

                    if (schedulehours !== undefined || schedulehours !== null) {
                        optimizedHours.daily[day] += schedulehours;
                        optimizedHours.week += schedulehours;
                    }
                }
            });

            return optimizedHours;
        }),

        payrollBudget: Ember.computed('data', 'date', function () {
            var activities = this.get('data.activities');
            var store = this.get('store');
            var taskHours = this.get('data.taskHours');
            var endDate = (0, _npmMoment.default)(this.get('date'));
            var self = this;

            var payrollBudget = {};
            var taskHoursByDay = {};
            var taskHourMap = {};

            taskHours.forEach(function (item) {
                var date = (0, _npmMoment.default)(item.get('taskdate'));
                var day = 'day' + (date.day() + 1);
                var taskId = item.get('task').get('id');

                if (taskHourMap[taskId] === undefined) {
                    taskHourMap[taskId] = {};
                }

                taskHourMap[taskId][day] = item;
            });

            activities.forEach(function (activity) {
                var tasks = activity.get('tasks');
                var budget = activity.get('budget');

                if (payrollBudget[budget.get('id')] === undefined) {
                    payrollBudget[budget.get('id')] = [];
                }

                tasks.forEach(function (task) {
                    taskHoursByDay[task.get('id')] = {};

                    for (var i = 1; i <= 7; i++) {
                        var day = 'day' + i;
                        var d = endDate.clone();
                        d = d.subtract(7 - i, 'days');

                        if (taskHourMap[task.get('id')] !== undefined && taskHourMap[task.get('id')][day] !== undefined) {
                            taskHoursByDay[task.get('id')][day] = taskHourMap[task.get('id')][day];
                        } else {
                            taskHoursByDay[task.get('id')][day] = store.createRecord('task-hour', {
                                task: task,
                                prelimhours: 0.0,
                                prelimunits: 0.0,
                                schedulehours: 0.0,
                                scheduleunits: 0.0,
                                finalhours: null,
                                finalunits: null,
                                taskdate: d.toDate(),
                                storeid: self.get('businessUnit.id')
                            });
                        }
                    }

                    payrollBudget[budget.get('id')].push({
                        id: task.get('id'),
                        activity: activity.get('id'),
                        budget: budget.get('id'),
                        customFunction: task.get('customFunction'),
                        isVisible: task.get('isVisible'),
                        isIbc: task.get('isIbc'),
                        isIbcByWeek: task.get('isIbcByWeek'),
                        isShowPrelim: task.get('isShowPrelim'),
                        isCanIbc: task.get('isCanIbc'),
                        laborStandard: task.get('laborStandard'),
                        name: task.get('name'),
                        order: task.get('orderIndex'),
                        values: taskHoursByDay[task.get('id')]
                    });

                    if (task.get('isIbcByWeek') === true) {
                        self.set('ibcByWeek', true);
                    }
                });
            });

            _npmUnderscore.default.each(payrollBudget, function (budget, id) {
                payrollBudget[id] = _npmUnderscore.default.sortBy(budget, function (task) {
                    return task.order;
                });
            });

            return payrollBudget;
        }),

        payrollBudgetTotals: Ember.computed('payrollBudget', 'payrollBudgetTotal.total.daily{day1,day2,day3,day4,day5,day6,day7}', function () {
            this.calcPayRollTotals();
            // console.log(this.get('payrollBudgetTotal'))

            return this.get('payrollBudgetTotal');
        }),

        scheduleVsBudgetVariance: Ember.computed('payrollBudgetTotals.total.daily', 'lastUpdate', function () {
            var self = this;

            var data = this.get('crunchedData.daily');
            var payrollBudget = this.get('payrollBudgetTotals.total.daily');
            var variance = {};

            variance.daily = {};

            _npmUnderscore.default.each(data, function (item, day) {
                var dailyVariance = {
                    actualvsschedulediff: 0,
                    schedulevsbudgetdiff: 0,
                    hoursvsschedulediff: 0,
                    variance: 0
                };

                var actualhours = item.actualhours ? item.actualhours : 0;
                var budgethours = payrollBudget[day] ? payrollBudget[day] : 0;
                var scheduledhours = item.scheduledhours ? item.scheduledhours : 0;

                dailyVariance.actualvsschedulediff = actualhours ? actualhours - scheduledhours : 0;
                dailyVariance.schedulevsbudgetdiff = scheduledhours - budgethours;
                dailyVariance.variance = dailyVariance.actualvsschedulediff + dailyVariance.schedulevsbudgetdiff;

                if (actualhours) {
                    dailyVariance.hoursvsschedulediff = actualhours - scheduledhours;
                } else if (scheduledhours) {
                    dailyVariance.hoursvsschedulediff = scheduledhours - budgethours;
                }

                variance.daily[day] = dailyVariance;

                // 2017-07-06 || AMH
                // Hard code -1 multiplier for LB only
                // Per instructions from MG on 2017-07-05 Meeting
                // Intention is to create a ClientSettings module in future to handle
                if (self.get('isLaneBryant')) {
                    _npmUnderscore.default.each(variance.daily[day], function (value, key) {
                        variance.daily[day][key] = value * -1;
                    });
                }
            });

            var memo = {
                actualvsschedulediff: 0,
                schedulevsbudgetdiff: 0,
                hoursvsschedulediff: 0,
                variance: 0
            };

            variance.week = _npmUnderscore.default.reduce(variance.daily, function (memo, variance) {
                memo.actualvsschedulediff += variance.actualvsschedulediff;
                memo.schedulevsbudgetdiff += variance.schedulevsbudgetdiff;
                memo.hoursvsschedulediff += variance.hoursvsschedulediff;
                memo.variance += variance.variance;
                return memo;
            }, memo);

            return variance;
        }),

        salesVsForecastVariance: Ember.computed('dailyForecast{day1.currentforecast,day2.currentforecast,day3.currentforecast,day4.currentforecast,day5.currentforecast,day6.currentforecast,day7.currentforecast}', 'crunchedData', 'lastUpdate', function () {
            var data = this.get('crunchedData.daily');
            var salesOrForecast = this.get('actualSalesAndCurrentForecast');
            var dailyVariance = {};
            var weeksales = 0;
            var weekforecast = 0;
            var weekdiff = 0;
            var weekpct = 0;

            _npmUnderscore.default.each(data, function (item, day) {
                var pct = 0;
                var diff = 0;
                var variance = {};
                var itemValue = salesOrForecast.daily[day];
                var forecast = item.scheduleforecast; //numeral.unformat(item.get('currentforecast'));
                if (itemValue !== 0 && itemValue !== undefined) {
                    diff = Math.round((itemValue - forecast) * 100) / 100;
                    pct = forecast !== 0 ? Math.round(diff / forecast * 1000) / 10 : 100;
                    variance = { diff: diff, pct: pct };
                    weeksales += itemValue;
                    weekforecast += forecast;
                } else {
                    variance = { diff: 0, pct: 0 };
                }

                dailyVariance[day] = variance;
            });

            if (weeksales !== 0 && weeksales !== undefined) {
                weekdiff = weeksales - weekforecast;
                weekpct = weekforecast !== 0 ? Math.round(weekdiff / weekforecast * 1000) / 10 : 100;
            }
            return {
                daily: dailyVariance,
                week: { diff: weekdiff, pct: weekpct }
            };
        }),

        sortedBudgets: Ember.computed('data.budgets', function () {
            return this.get('data.budgets');
        }),

        // 2017-07-06 || AMH
        isLaneBryant: Ember.computed('client', function () {
            var stateService = this.get('stateService');
            return (parseInt(stateService.client.id) === 12 || parseInt(stateService.client.id) === 17) && doHardCodeReverse;
        }),

        planHoursText: Ember.computed('node.clientCode', function () {
            var clientCode = this.get('node.clientCode');
            if (clientCode === 'jjill') {
                return 'Earned Hours';
            }
            return 'Plan Hours';
        }),

        scheduleForecastText: Ember.computed('node.clientCode', function () {
            var clientCode = this.get('node.clientCode');
            if (clientCode === 'jjill') {
                return 'Store Ops Goal';
            }
            return 'Schedule Forecast';
        }),

        actions: {
            cancel: function cancel() {
                if (this.get('discardAction')(this.get('formUpdated'))) {
                    this.notifyPropertyChange('payrollBudget');
                    this.calcPayRollTotals();
                    return true;
                } else {
                    return false;
                }
            },
            save: function save() {
                var businessUnit = this.get('businessUnit');
                var dailyForecast = this.get('dailyForecast');
                var payrollBudget = this.get('payrollBudget');
                this.sendAction('saveAction', businessUnit, payrollBudget, dailyForecast); // eslint-disable-line ember/closure-actions
                this.set('formUpdated', false);
            },
            toggleFormUpdated: function toggleFormUpdated() {
                var dailyForecast = this.get('dailyForecast.day1');
                this.set('formUpdated', true);
                this.set('lastUpdate', new Date());
            },
            toggleRow: function toggleRow(id) {
                var element = '#' + id;
                var children = Ember.$(element).data().children;
                var self = this;
                _npmUnderscore.default.each(children, function (child) {
                    var e = '#' + child;
                    if (Ember.$(e).is(':visible')) {
                        var checkbox = e + '-toggle';
                        Ember.$(checkbox).prop('checked', false);
                        self.traverseAndClose(child);
                    }

                    var taskVisible = self.get('taskVisible');
                    if (taskVisible.hasOwnProperty(child)) {
                        var isVisible = taskVisible[child] ? false : true;
                        Ember.set(taskVisible, child, isVisible);
                        self.set('taskVisible', taskVisible);
                    } else {
                        Ember.$(e).toggle();
                    }
                });
            },
            updateForecastToTrend: function updateForecastToTrend() {
                var crunchedData = this.get('crunchedData.daily');
                var self = this;
                var rawTrend = this.get('forecastTrendRaw');

                var forecastUpdated = false;

                _npmUnderscore.default.each(crunchedData, function (daily, day) {
                    if (daily.salesvalue === undefined || daily.salesvalue === 0) {
                        var forecast = self.get('dailyForecast.' + day);
                        var raw = forecast.serialize();
                        var updatedForecast = Math.round((raw.scheduleforecast + raw.scheduleforecast * rawTrend) * 100) / 100;
                        self.set('dailyForecast.' + day + '.currentforecast', (0, _npmNumbro.default)(updatedForecast).format('0,0.00'));
                        forecastUpdated = true;
                    }
                });

                if (forecastUpdated === true) {
                    this.set('formUpdated', forecastUpdated);
                }
            },
            updatePayrollBudgetTotals: function updatePayrollBudgetTotals() {
                this.calcPayRollTotals();
                this.set('formUpdated', true);
            }
        },

        didRender: function didRender() {
            Ember.$('[data-level]').each(function () {
                var children = Ember.$(this).data().children;
                var level = Ember.$(this).data('level');
                var indent = level * 20 + 'px';
                var titleIndex = level * 20 + 20 + 7 + 'px';
                var id = Ember.$(this).attr('id');
                var toggleId = id + '-toggle';
                var label = Ember.$('label[for=\'' + toggleId + '\']');
                Ember.$(label).css('margin-left', indent);
                _npmUnderscore.default.each(children, function (child) {
                    Ember.$('#' + child).find('td.row-title').each(function () {
                        Ember.$(this).css('padding-left', titleIndex);
                    });
                });
            });
            // },
            // fetchScheduleStatus: observer('cube.weekEndDate', function() {
            var fwe = this.redux.getState().cube.weekEndDate.format('YYYY-MM-DD');
            if (this.scheduleStatus === null && this.fetchingScheduleStatus !== fwe) {
                (0, _interactions.setStore)(this.redux);
                (0, _interactions.send)({
                    header: 'do',
                    body: {
                        service: 'store',
                        namespace: ['schedule', 'status'],
                        operation: 0,
                        include_channel: 2,
                        broadcast: false,
                        response_type: 'NODE_INTAKE',
                        extra: [fwe]
                    }
                }, 'retrieve schedule status');

                this.set('fetchingScheduleStatus', fwe);
            }
        },


        disabledButtons: Ember.computed('formUpdated', function () {
            return !this.get('formUpdated');
        }),

        saveButtonAction: Ember.computed(function () {
            var redux = this.get('redux');
            (0, _interactions.setStore)(redux);

            return Ember.run.bind(this, function () {
                this.get('actions').save.bind(this).call();
                // TODO:
                // - Call API to refresh tash hours here
                // send({
                //     header: 'do',
                //     body: {
                //         service: 'data',
                //         namespace: 'taskhours',
                //         operation: 2,
                //         include_channel: 2,
                //         params: {
                //             week_end_date: redux.getState().cube.weekEndDate.format("YYYY-MM-DD")
                //         },
                //         response_type: 'NODE_INTAKE',
                //         broadcast: true
                //     },
                // }, 'refresh taskHours', true)
            });
        }),

        discardButtonAction: Ember.computed(function () {
            return Ember.run.bind(this, function () {
                this.get('actions').cancel.bind(this).call();
            });
        })
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});