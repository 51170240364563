define('matrix-frontend/adapters/v2-hierarchy-node', ['exports', 'ember-data', 'matrix-frontend/config/environment'], function (exports, _emberData, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTAdapter.extend({
        host: _environment.default.mxl.replace('${host}', window.location.host),
        redux: Ember.inject.service(),
        // namespace: 'v2',
        defaultSerializer: 'v2-hierarchy-node',

        headers: Ember.computed('session.token', function () {
            var store = this.get('redux').store;
            var token = store.getState().user.currentUser.token;

            if (token) {
                return {
                    Authorization: 'Bearer ' + token
                };
            } else {
                return {};
            }
        }),

        pathForType: function pathForType() {
            return 'client/hierarchy';
        },
        sortQueryParams: function sortQueryParams(params) {
            delete params.clientCode;
            return params;
        },
        buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
            var url = this._super.apply(this, arguments).split('/');
            url.push(query.clientCode);
            return url.join('/');
        }
    }

    // handleResponse(status, headers, payload, requestData) {
    //   console.log('.....................')
    //   return [1,2,3];
    // }

    // handleResponse (status, headers, payload, requestData) {
    //   console.log('status', status)
    //   console.log('headers', headers)
    //   console.log('payload', payload)
    //   console.log('requestData', requestData)
    //   arguments[2] = payload.data;
    //   console.log('payload', payload)
    //   console.log('arguments', arguments)
    //   return this._super(...arguments);
    // }
    );
});