define('matrix-frontend/handlers/schedule-edit', ['exports', 'matrix-frontend/store', 'matrix-frontend/actions/_types', 'matrix-frontend/constants', 'matrix-frontend/utils/availability'], function (exports, _store, _types, _constants, _availability) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.unassignedClickHandler = exports.employeeClickHandler = undefined;
    var employeeClickHandler = exports.employeeClickHandler = function employeeClickHandler(_ref) {
        var dotw = _ref.dotw,
            employee = _ref.employee,
            shift = _ref.shift,
            employees = _ref.employees,
            editMode = _ref.editMode,
            canEdit = _ref.canEdit;

        // - shift is the shift that was selected and is active
        // - employee is the employee for the row that was clicked second, the
        //      row that the shift is going into

        var _store$getState$sched = _store.store.getState().scheduleEdit,
            status = _store$getState$sched.status,
            printMode = _store$getState$sched.printMode,
            activeShift = _store$getState$sched.activeShift,
            availableEmployeeIds = _store$getState$sched.availableEmployeeIds;

        if (!editMode || !canEdit) {
            return false;
        }

        var activeEmployee = activeShift && activeShift.meta.dotw === dotw && activeShift.meta.employeeId ? employees.find(function (x) {
            return x.id === activeShift.meta.employeeId;
        }) : null;

        // const doSwap =
        //     !printMode &&
        //     editMode &&
        //     (employee
        //         ? (shift
        //               ? !activeShift ||
        //                 (availableEmployeeIds &&
        //                     availableEmployeeIds.has(employee.id))
        //               : false) ||
        //           (availableEmployeeIds && availableEmployeeIds.has(employee.id))
        //         : true) &&
        //     (activeShift ? activeShift.meta.dotw === dotw : true)

        var doSwap = true;

        // TODO:
        // - Candidate for some refactoring and speed improvements
        if (printMode || !editMode) {
            doSwap = false;
        }
        if (doSwap && activeShift) {
            doSwap = activeShift.meta.dotw === dotw;
        }
        if (doSwap && employee) {
            if (activeShift && availableEmployeeIds && !availableEmployeeIds.has(employee.id)) {
                doSwap = false;
            }
            if (doSwap) {
                if (shift) {
                    if (activeShift && activeEmployee) {
                        doSwap = (0, _availability.employeeAvailableForShift)(activeEmployee, shift, dotw);
                    }
                }
            }
        }

        if (doSwap) {
            _store.store.dispatch({
                type: _types.SE_DO_SWAP,
                payload: { employee: employee, dotw: dotw, activeShift: shift }
            });
            _store.store.dispatch({
                type: _types.EP_SET_EMPLOYEE_PANEL,
                payload: { employee: null, displayPanel: false }
            });
        } else {
            _store.store.dispatch({
                type: _types.SE_SET,
                payload: { activeShift: null }
            });
            _store.store.dispatch({
                type: _types.EP_SET_EMPLOYEE_PANEL,
                payload: { employee: null, displayPanel: false }
            });
        }
    };

    var unassignedClickHandler = exports.unassignedClickHandler = function unassignedClickHandler(_ref2) {
        var dotw = _ref2.dotw,
            employee = _ref2.employee,
            shift = _ref2.shift;

        var _store$getState$sched2 = _store.store.getState().scheduleEdit,
            editMode = _store$getState$sched2.editMode,
            printMode = _store$getState$sched2.printMode,
            activeShift = _store$getState$sched2.activeShift,
            availableEmployeeIds = _store$getState$sched2.availableEmployeeIds;

        var doSwap = !printMode && editMode && (employee ? (shift ? !activeShift || availableEmployeeIds && availableEmployeeIds.has(employee.id) : false) || availableEmployeeIds && availableEmployeeIds.has(employee.id) : true) && (activeShift ? activeShift.meta.dotw === dotw : true);

        if (doSwap) {
            _store.store.dispatch({
                type: _types.SE_DO_SWAP,
                payload: { employee: employee, dotw: dotw, activeShift: shift }
            });
            _store.store.dispatch({
                type: _types.EP_SET_EMPLOYEE_PANEL,
                payload: { employee: null, displayPanel: false }
            });
        } else {
            _store.store.dispatch({
                type: _types.SE_SET,
                payload: { activeShift: null }
            });
            _store.store.dispatch({
                type: _types.EP_SET_EMPLOYEE_PANEL,
                payload: { employee: null, displayPanel: false }
            });
        }
    };
});