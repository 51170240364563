define('matrix-frontend/pods/components/abstract-vs-report/component', ['exports', 'ember-redux', 'npm:underscore', 'matrix-frontend/constants'], function (exports, _emberRedux, _npmUnderscore, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var stateToComputed = function stateToComputed(state) {
        return {
            nodeType: state.cube.node.nodeType,
            node: state.cube.node,
            stores: state.node.stores,
            hierarchies: state.node.hierarchies,
            weekEndDate: state.cube.weekEndDate
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {};
    };

    var Comp = Ember.Component.extend({
        ScheduleStatus: _constants.ScheduleStatus,
        classNames: ['line-area-report-container'],

        actions: {
            selectDate: function selectDate(date) {
                this.sendAction('action', date); // eslint-disable-line ember/closure-actions
            },
            changeDataView: function changeDataView() {
                if (this.get('selectedDate')) {
                    var currentView = this.get('currentDataView');
                    var newView = currentView === 'table' ? 'graph' : 'table';
                    this.set('currentDataView', newView);
                }
            }
        },

        layoutName: 'components/abstract-vs-report',

        swipeLeftAction: 'changeDataView',
        swipeRightAction: 'changeDataView',

        currentDataView: 'table',

        currentStore: Ember.computed('node', 'stores', function () {
            var node = this.get('node');
            var stores = this.get('redux').store.getState().node.stores;
            if (node && stores) {
                return stores[node.clientCode + '.' + node.tag];
            } else {
                return null;
            }
        }),

        currentNode: Ember.computed('node', 'hierarchies', function () {
            var node = this.get('node');
            var hierarchies = this.get('redux').store.getState().node.hierarchies;
            if (node && hierarchies) {
                return hierarchies[node.clientCode + '.' + node.tag];
            } else {
                return null;
            }
        }),

        scheduleStatus: Ember.computed('weekEndDate', 'currentStore', 'currentNode', 'nodeType', function () {
            var current = this.nodeType === 'store' ? this.currentStore : this.currentNode;
            var weekEndDate = this.weekEndDate.format('YYYY-MM-DD');
            if (current && current.weeks && current.weeks[weekEndDate]) {
                return current.weeks[weekEndDate].scheduleStatus;
            }
            return null;
        }),

        viewGraph: Ember.computed('currentDataView', 'selectedDate', function () {
            var currentView = this.get('currentDataView');
            var selectedDate = this.get('selectedDate');

            if (selectedDate && currentView && currentView === 'graph') {
                return true;
            } else {
                return false;
            }
        }),

        shouldHide: Ember.computed('scheduleStatus', function () {
            if (this.nodeType === 'store') {
                if (!this.scheduleStatus || this.scheduleStatus.status < _constants.ScheduleStatus.PRELIMINARY) {
                    return true;
                }
            } else {
                if (!this.scheduleStatus || this.scheduleStatus.status.hidden || this.scheduleStatus.status.executive || this.scheduleStatus.status.readonly) {
                    return true;
                }
            }
            return false;
        }),

        hasPreliminary: Ember.computed('scheduleStatus', function () {
            var hasPreliminary = this.scheduleStatus && this.nodeType === 'store' ? this.scheduleStatus.status === _constants.ScheduleStatus.PRELIMINARY : this.scheduleStatus.status.preliminary;
            return hasPreliminary;
        }),

        //     (or
        //   (and
        //     scheduleStatus
        //     (not-eq nodeType "store")
        //   )
        //   (and
        //     scheduleStatus
        //     (eq nodeType "store")
        //     (gte scheduleStatus.status.value 3)
        //   )
        // )

        selectedCrunchedData: Ember.computed('crunchedData', 'selectedDate', function () {
            var crunchedData = this.get('crunchedData');
            var selectedDate = this.get('selectedDate');
            var selectedData, alreadySelected;

            if (crunchedData && !_npmUnderscore.default.isEmpty(crunchedData)) {
                if (selectedDate) {
                    selectedData = _npmUnderscore.default.find(crunchedData, function (data) {
                        return data.date.isSame(selectedDate);
                    });
                    alreadySelected = selectedData.selected;
                }

                _npmUnderscore.default.each(crunchedData, function (data) {
                    data.selected = false;
                });

                if (selectedData && !alreadySelected) {
                    selectedData.selected = true;
                }
            }

            return crunchedData;
        }),

        graphData: Ember.computed('normalizedData', 'selectedDate', 'media.isMediumUp', function () {
            var data = this.get('normalizedData');
            var selectedDate = this.get('selectedDate');
            var selectedDateString = selectedDate ? selectedDate.toDate().toDateString() : selectedDate;

            if (data) {
                if (this.get('media.isMediumUp') || !selectedDate) {
                    return data;
                } else {
                    return data.filter(function (item) {
                        var date = item.date ? new Date(item.date) : null;
                        return selectedDateString === date.toDateString();
                    });
                }
            } else {
                return null;
            }
        }),

        barChartData: Ember.computed('selectedCrunchedData', function () {
            var data = this.get('selectedCrunchedData');
            if (data) {
                return this.get('selectedCrunchedData').slice(0, 7);
            }
        }),

        selectionData: Ember.computed('selectedDate', 'crunchedData', 'media.isSmallOnly', function () {
            var data = this.get('crunchedData');
            var selectedDate = this.get('selectedDate');

            if (data) {
                if (selectedDate && this.get('media.isSmallOnly')) {
                    var selectionData = _npmUnderscore.default.find(data, function (day) {
                        if (day.date) {
                            return day.date.isSame(selectedDate);
                        } else {
                            return false;
                        }
                    });
                    return _npmUnderscore.default.isArray(selectionData) ? selectionData : [selectionData];
                } else {
                    return this.get('media.isSmallOnly') ? this.get('crunchedData').slice(7) : this.get('crunchedData');
                }
            }
        })
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});