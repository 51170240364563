define('matrix-frontend/components/react-only/employee-overview-row', ['exports', 'react', 'matrix-frontend/actions/_types', 'matrix-frontend/components/react-only/employee-availability-cell', 'matrix-frontend/components/react-only/employee-skill-cell', 'matrix-frontend/store', 'matrix-frontend/utils/format-helpers', 'matrix-frontend/utils/state', 'npm:react-redux'], function (exports, _react, _types, _employeeAvailabilityCell, _employeeSkillCell, _store, _formatHelpers, _state, _npmReactRedux) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var ACCEPTABLE = ['matrix_admin', 'field_admin'];
    var handleClick = function handleClick(employee, modalService, modalKey) {
        _store.store.dispatch({
            type: _types.EP_SET_EMPLOYEE_PANEL,
            payload: { employee: employee }
        });
        modalService.open(modalKey);
    };

    var changeView = function changeView(view) {
        _store.store.dispatch({
            type: _types.CUBE_SET_PARTIAL,
            payload: {
                view: view
            }
        });
    };

    var UsernameColumn = function UsernameColumn(_ref) {
        var employee = _ref.employee,
            modalService = _ref.modalService,
            userRoles = _ref.userRoles,
            generalRoles = _ref.generalRoles,
            currentStore = _ref.currentStore;

        // const {
        //     user: {
        //         currentUser: {roles},
        //     },
        //     general: {
        //         roles: generalRoles
        //     }
        // } = store.getState()
        var roleNames = userRoles.map(function (x) {
            return x.name;
        });
        var generalRoleNames = generalRoles.map(function (x) {
            return x.name;
        });
        if (employee.username) {
            var displayRoles = [].concat(_toConsumableArray(employee.roles)).filter(function (x) {
                return (generalRoleNames.length === 0 || generalRoleNames.includes(x)) && x !== 'dashboard';
            });

            if (
            // ACCEPTABLE.some(x => roleNames.includes(x)) &&
            (0, _state.hasScope)("employee.user:edit") && currentStore.id === employee.store.id) {
                return _react.default.createElement(
                    'div',
                    {
                        className: 'column f2 pointer text space-between',
                        onClick: function onClick() {
                            return handleClick(employee, modalService, 'user-modal');
                        }
                    },
                    displayRoles.length ? employee.username : _react.default.createElement(
                        'em',
                        null,
                        _react.default.createElement(
                            'strong',
                            { className: 'gray-lighten-1-text' },
                            'Disabled'
                        )
                    ),
                    (0, _state.hasRole)('matrix_dev') ? _react.default.createElement(
                        'div',
                        { className: 'badge' },
                        employee.roles.length,
                        ' roles'
                    ) : ''
                );
            } else {
                // <div className="column f2 fake-text gray-lighten-2-text" />
                return _react.default.createElement(
                    'div',
                    { className: 'column f2 gray-lighten-1-text' },
                    '************'
                );
            }
        } else {
            if (
            // ACCEPTABLE.some(x => roleNames.includes(x)) &&
            (0, _state.hasScope)("employee.user:edit") && currentStore.id === employee.store.id) {
                return _react.default.createElement(
                    'div',
                    {
                        className: 'column f2 pointer gray-lighten-2-text text',
                        onClick: function onClick() {
                            return handleClick(employee, modalService, 'user-modal');
                        }
                    },
                    _react.default.createElement(
                        'em',
                        null,
                        'Create new user'
                    )
                );
            } else {
                return _react.default.createElement('div', { className: 'column f2' });
            }
        }
    };

    var comp = function comp(props) {
        var employee = props.employee,
            activitiesById = props.activitiesById,
            openAvailabilityModal = props.openAvailabilityModal,
            modalService = props.modalService,
            shiftsByEmployee = props.shiftsByEmployee,
            userRoles = props.userRoles,
            generalRoles = props.generalRoles,
            currentStore = props.currentStore;


        var availability = _extends({}, employee.availability.default, employee.availability.exceptions);
        var isLoan = currentStore.id !== employee.store.id;

        var _useState = (0, _react.useState)(0),
            _useState2 = _slicedToArray(_useState, 2),
            numShifts = _useState2[0],
            setNumShifts = _useState2[1];

        (0, _react.useEffect)(function () {
            var shifts = shiftsByEmployee && shiftsByEmployee[employee.id] || {};
            var numShifts = Object.keys(shifts).length;
            setNumShifts(Object.keys(shifts).length);
        }, [shiftsByEmployee]);

        var storeCode = employee.store.code.toUpperCase() !== currentStore.code.toUpperCase() ? '(' + employee.store.code.toUpperCase() + ') ' : '';
        var name = ('' + storeCode + employee.lastName + ', ' + employee.firstName).substring(0, 20);

        return _react.default.createElement(
            'div',
            { className: 'row' },
            _react.default.createElement(
                'div',
                {
                    className: 'wrapper f2 compound pointer',
                    onClick: function onClick() {
                        return handleClick(employee, modalService, 'employee-details-modal');
                    }
                },
                _react.default.createElement(
                    'div',
                    { className: 'column text f1 no-left gray-lighten-1-text' },
                    _react.default.createElement(
                        'small',
                        null,
                        employee.position.code
                    )
                ),
                _react.default.createElement(
                    'div',
                    { className: 'column text f6 no-left space-between' },
                    _react.default.createElement(
                        'span',
                        { className: isLoan ? 'gray-lighten-1-text' : '' },
                        name
                    ),
                    (0, _state.hasRole)('matrix_dev') ? _react.default.createElement(
                        'div',
                        { className: 'badge' },
                        numShifts
                    ) : ''
                )
            ),
            _react.default.createElement(
                'div',
                { className: 'wrapper f9' },
                isLoan ? _react.default.createElement(
                    'div',
                    {
                        className: 'column f2 pointer gray-lighten-1-text',
                        onClick: function onClick() {
                            return handleClick(employee, modalService, 'contactinfo-modal');
                        }
                    },
                    'Borrowed from ',
                    employee.store.code.toUpperCase()
                ) : employee.mobile || employee.phone ? _react.default.createElement(
                    'div',
                    {
                        className: 'column f2 pointer allow-overflow',
                        onClick: function onClick() {
                            return handleClick(employee, modalService, 'contactinfo-modal');
                        },
                        'data-tooltip': employee.mobile ? 'mobile' : employee.phone ? 'home' : null
                    },
                    _formatHelpers.default.phoneNumber(employee.mobile || employee.phone)
                ) : _react.default.createElement(
                    'div',
                    {
                        className: 'column f2 pointer gray-lighten-2-text',
                        onClick: function onClick() {
                            return handleClick(employee, modalService, 'contactinfo-modal');
                        }
                    },
                    _react.default.createElement(
                        'em',
                        null,
                        'Edit contact info'
                    )
                ),
                _react.default.createElement(
                    'div',
                    { className: 'column f2 allow-overflow' },
                    _react.default.createElement(_employeeAvailabilityCell.default, {
                        employeeId: employee.id,
                        availability: availability,
                        openAvailabilityModal: openAvailabilityModal,
                        startDate: employee.startDate,
                        terminationDate: employee.terminationDate,
                        isLoan: isLoan,
                        currentStoreCode: currentStore.code
                    })
                ),
                _react.default.createElement(
                    'div',
                    {
                        className: isLoan ? 'column f1 pointer gray-lighten-1-text' : 'column f1 pointer',
                        onClick: function onClick() {
                            return changeView('minmax');
                        }
                    },
                    employee.minimumHours,
                    '\u2013',
                    employee.maximumHours
                ),
                _react.default.createElement(
                    'div',
                    {
                        className: 'column f3 pointer allow-overflow',
                        onClick: function onClick() {
                            return changeView('skills');
                        }
                    },
                    _react.default.createElement(_employeeSkillCell.default, {
                        skills: employee.skillsByActivity,
                        activitiesById: activitiesById,
                        tooltipValue: true,
                        isLoan: isLoan
                    })
                ),
                _react.default.createElement(UsernameColumn, {
                    employee: employee,
                    modalService: modalService,
                    userRoles: userRoles,
                    generalRoles: generalRoles,
                    currentStore: currentStore
                })
            )
        );
    };

    exports.default = comp;
});