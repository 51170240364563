define('matrix-frontend/helpers/availability-display', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.availabilityDisplay = availabilityDisplay;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var getTime = function getTime(time) {
        var hours = time.hours();
        var minutes = time.minutes();
        var ampm = hours >= 12 ? 'p' : 'a';
        hours = hours % 12;
        hours = hours === 0 ? 12 : hours;
        minutes = minutes === 0 ? '' : ':' + minutes;
        return '' + hours + minutes + ampm;
    };

    function availabilityDisplay(params /*, hash*/) {
        var _params = _slicedToArray(params, 4),
            start = _params[0],
            end = _params[1],
            fromOpen = _params[2],
            untilClose = _params[3];

        // if (fromOpen || start.hours() === 0 && start.minutes() === 0) {
        if (fromOpen) {
            start = 'Open';
        } else {
            start = getTime(start);
        }
        // if (untilClose || end.hours() === 23 && end.minutes() === 59) {
        if (untilClose) {
            end = 'Close';
        } else {
            end = getTime(end);
        }

        return start + ' - ' + end;
    }

    exports.default = Ember.Helper.helper(availabilityDisplay);
});