define('matrix-frontend/actions/preferences', ['exports', 'matrix-frontend/actions/_types'], function (exports, _types) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.concentrateExpand = exports.concentrateNormal = exports.concentrateCondense = undefined;
    var concentrateCondense = exports.concentrateCondense = function concentrateCondense() {
        return function (dispatch) {
            return dispatch({ type: _types.PREFERENCE_SET, payload: { concentration: 0 } });
        };
    };
    var concentrateNormal = exports.concentrateNormal = function concentrateNormal() {
        return function (dispatch) {
            return dispatch({ type: _types.PREFERENCE_SET, payload: { concentration: 1 } });
        };
    };
    var concentrateExpand = exports.concentrateExpand = function concentrateExpand() {
        return function (dispatch) {
            return dispatch({ type: _types.PREFERENCE_SET, payload: { concentration: 2 } });
        };
    };
});