define('matrix-frontend/pods/components/store-page/component', ['exports', 'ember-redux', 'matrix-frontend/utils/state', 'npm:moment'], function (exports, _emberRedux, _state, _npmMoment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var stateToComputed = function stateToComputed(state) {
        return {
            targetDOTW: state.cube.targetDOTW,
            targetWeek: state.cube.targetWeek,
            node: state.cube.node,
            clients: state.client.clients,
            stores: state.node.stores,
            weekEndDate: state.cube.weekEndDate,
            currentUser: state.user.currentUser
            // --
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {
            openStoreInfo: function openStoreInfo() {
                this.modalService.open('storeinfo-modal');
            }
        };
    };

    var Comp = Ember.Component.extend({
        modalService: Ember.inject.service(),
        userCanEdit: false,

        clientsObserver: Ember.observer('clients', 'node', function () {
            var node = this.node;
            var clients = this.clients;
            var currentUser = this.currentUser;

            if (node) {
                var currentClient = node && clients ? clients[node.clientCode] : null;

                if (currentClient && currentUser && currentUser.roles) {
                    this.set('userCanEdit', (0, _state.hasScope)('store.hoo:edit'));
                }
            }
        }),

        currentStore: Ember.computed('node', 'stores', function () {
            var node = this.node;
            var stores = this.stores;
            if (node && stores) {
                return stores[node.clientCode + '.' + node.tag];
            } else {
                return null;
            }
        }),

        // currentClient: computed('clients', 'node', function() {
        //     const node = this.node
        //     const clients = this.clients
        //     return node && clients ? clients[node.clientCode] : null
        // }),

        canEdit: Ember.computed('userCanEdit', function () {
            // TODO this is one ugly hack ...
            return this.userCanEdit || (0, _state.hasScope)('store.hoo:edit');
        }),

        hoo: Ember.computed('currentStore.weeks', 'weekEndDate', function () {
            var currentStore = this.currentStore;
            var weekEndDate = this.weekEndDate;

            if (currentStore && weekEndDate && currentStore.weeks) {
                var hoursOfOperation = [];

                // TODO ONLY DO FUTURE WEEKS FOR X WEEKS
                var viewingWeekEndDate = weekEndDate;
                Object.keys(currentStore.weeks).forEach(function (weekEndDate) {
                    if (viewingWeekEndDate.isSameOrBefore((0, _npmMoment.default)(weekEndDate), 'week')) {
                        var rawHOO = _extends({}, currentStore.weeks[weekEndDate]).hours;

                        if (rawHOO) {
                            var hoo = Object.keys(rawHOO).reduce(function (output, i) {
                                var parts = void 0;
                                var dotw = parseInt(i);
                                var hours = rawHOO[dotw];
                                hours.date = (0, _npmMoment.default)(weekEndDate).subtract(7 - dotw, 'days');

                                if (hours.privateOpen && !_npmMoment.default.isMoment(hours.privateOpen)) {
                                    var privateOpen = (0, _npmMoment.default)(hours.date);
                                    if (hours.privateOpenOffset > -2) {
                                        privateOpen.add(hours.privateOpenOffset, 'day');
                                    }
                                    parts = hours.privateOpen.split(':');
                                    privateOpen.set({
                                        hour: parts[0],
                                        minute: parts[1]
                                    });
                                    hours.privateOpen = privateOpen;
                                }

                                if (hours.privateClose && !_npmMoment.default.isMoment(hours.privateClose)) {
                                    var privateClose = (0, _npmMoment.default)(hours.date);
                                    if (hours.privateCloseOffset > -2) {
                                        privateClose.add(hours.privateCloseOffset, 'day');
                                    }
                                    parts = hours.privateClose.split(':');
                                    privateClose.set({
                                        hour: parts[0],
                                        minute: parts[1]
                                    });
                                    hours.privateClose = privateClose;
                                }

                                if (hours.publicOpen && !_npmMoment.default.isMoment(hours.publicOpen)) {
                                    var publicOpen = (0, _npmMoment.default)(hours.date);
                                    if (hours.publicOpenOffset > -2) {
                                        publicOpen.add(hours.publicOpenOffset, 'day');
                                    }
                                    parts = hours.publicOpen.split(':');
                                    publicOpen.set({
                                        hour: parts[0],
                                        minute: parts[1]
                                    });
                                    hours.publicOpen = publicOpen;
                                }

                                if (hours.publicClose && !_npmMoment.default.isMoment(hours.publicClose)) {
                                    var publicClose = (0, _npmMoment.default)(hours.date);
                                    if (hours.publicCloseOffset > -2) {
                                        publicClose.add(hours.publicCloseOffset, 'day');
                                    }
                                    parts = hours.publicClose.split(':');
                                    publicClose.set({
                                        hour: parts[0],
                                        minute: parts[1]
                                    });
                                    hours.publicClose = publicClose;
                                }

                                if (dotw > 1) {
                                    var prevDay = rawHOO[dotw - 1];
                                    if (prevDay.publicClose) {
                                        var prevDayPublicClose = (0, _npmMoment.default)(hours.date).subtract(1, 'day');
                                        if (prevDay.publicCloseOffset > -2) {
                                            prevDayPublicClose.add(prevDay.publicCloseOffset, 'day');
                                        }
                                        if (!_npmMoment.default.isMoment(prevDay.publicClose)) {
                                            parts = prevDay.publicClose.split(':');
                                            prevDayPublicClose.set({
                                                hour: parts[0],
                                                minute: parts[1]
                                            });
                                        } else {
                                            prevDayPublicClose.set({
                                                hour: prevDay.publicClose.hours(),
                                                minute: prevDay.publicClose.minutes()
                                            });
                                        }
                                        hours.prevDayPublicClose = prevDayPublicClose;
                                    } else {
                                        hours.prevDayPublicClose = null;
                                    }
                                } else {
                                    hours.prevDayPublicClose = (0, _npmMoment.default)(hours.date).set({
                                        hour: 3,
                                        minute: 0
                                    });
                                }

                                if (dotw < 7) {
                                    var nextDay = rawHOO[dotw + 1];
                                    if (nextDay.publicOpen) {
                                        var nextDayPublicOpen = (0, _npmMoment.default)(hours.date).add(1, 'day');
                                        if (nextDay.publicOpenOffset > -2) {
                                            nextDayPublicOpen.add(nextDay.publicOpenOffset, 'day');
                                        }
                                        if (!_npmMoment.default.isMoment(nextDay.publicOpen)) {
                                            parts = nextDay.publicOpen.split(':');
                                            nextDayPublicOpen.set({
                                                hour: parts[0],
                                                minute: parts[1]
                                            });
                                        } else {
                                            nextDayPublicOpen.set({
                                                hour: nextDay.publicOpen.hours(),
                                                minute: nextDay.publicOpen.minutes()
                                            });
                                        }
                                        hours.nextDayPublicOpen = nextDayPublicOpen;
                                    } else {
                                        hours.nextDayPublicOpen = null;
                                    }
                                } else {
                                    hours.nextDayPublicOpen = (0, _npmMoment.default)(weekEndDate).add(1, 'day').set({
                                        hour: 6,
                                        minute: 0
                                    });
                                }

                                output[dotw] = hours;

                                return output;
                            }, {});

                            hoursOfOperation.push({
                                weekEndDate: weekEndDate,
                                hoo: hoo
                            });
                        } else {
                            hoursOfOperation.push({
                                weekEndDate: weekEndDate
                            });
                        }
                    }
                });

                hoursOfOperation = hoursOfOperation.sortBy('weekEndDate');

                return hoursOfOperation;
            }
            return null;
        }),

        targetHOO: Ember.computed('hoo', 'targetWeek', 'targetDOTW', function () {
            var _this = this;

            if (this.targetWeek && this.targetDOTW && this.hoo && this.hoo.find(function (x) {
                return x.weekEndDate === _this.targetWeek;
            }) && this.hoo.find(function (x) {
                return x.weekEndDate === _this.targetWeek;
            }).hoo && this.hoo.find(function (x) {
                return x.weekEndDate === _this.targetWeek;
            }).hoo[this.targetDOTW]) {
                return this.hoo.find(function (x) {
                    return x.weekEndDate === _this.targetWeek;
                }).hoo[this.targetDOTW];
            }
            return null;
            // return this.hoo[this.targetWeek]
        })
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});