define('matrix-frontend/models/task-hour', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        finalhours: _emberData.default.attr('number', { defaultValue: 0 }),
        finalunits: _emberData.default.attr('number', { defaultValue: 0 }),
        laborStandard: _emberData.default.attr('number', { defaultValue: null }),
        prelimhours: _emberData.default.attr('number', { defaultValue: 0 }),
        prelimunits: _emberData.default.attr('number', { defaultValue: 0 }),
        schedulehours: _emberData.default.attr('number', { defaultValue: 0 }),
        scheduleunits: _emberData.default.attr('number', { defaultValue: 0 }),
        storeid: _emberData.default.attr('number'),
        task: _emberData.default.belongsTo('task'),
        taskdate: _emberData.default.attr('isodate'),

        /**
         * Get what are the "currentHours"
         */
        currentHours: Ember.computed('finalhours', 'prelimhours', 'schedulehours', function () {
            if (this.get('finalhours') != null) {
                return this.get('finalhours');
            } else if (this.get('schedulehours') != null) {
                return this.get('schedulehours');
            } else {
                return this.get('prelimhours');
            }
        }),

        currentUnits: Ember.computed('finalunits', 'prelimunits', 'scheduleunits', function () {
            if (this.get('finalunits') != null) {
                return this.get('finalunits');
            } else if (this.get('scheduleunits') != null) {
                return this.get('scheduleunits');
            } else {
                return this.get('prelimunits');
            }
        }),

        currentLaborStandard: Ember.computed('laborStandard', 'task.laborStandard', function () {
            return this.get('laborStandard') != null ? this.get('laborStandard') : this.get('task.laborStandard');
        })
    });
});