define('matrix-frontend/pods/components/self-serve-claim/component', ['exports', 'ember-redux'], function (exports, _emberRedux) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var stateToComputed = function stateToComputed(state) {
        return {
            claim: state.selfServe.claim
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {};
    };

    var Comp = Ember.Component.extend({});

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});