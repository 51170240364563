define('matrix-frontend/utils/cleaners', ['exports', 'npm:lodash', 'npm:moment', 'matrix-frontend/utils/date-helper'], function (exports, _npmLodash, _npmMoment, _dateHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.prepareShiftsByEmployeeForSave = exports.clean_shifts = exports.demomentize = exports.momentize = exports.filter_keys = exports.sanitize = exports.clean_keys = exports.clean_tag = undefined;

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    var clean_tag = exports.clean_tag = function clean_tag(tag) {
        var regex = /^\.?(.*)$/gm;
        return tag.replace(regex, '$1');
    };

    var clean_keys = exports.clean_keys = function clean_keys(obj) {
        var camelCase = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        var output = void 0;
        // for (let key in obj) {
        //     const value = _key_cleaner(obj[key])
        //     const operation = camelCase ? camelize : decamelize
        //     output[operation(key)] = value
        // }
        // return output

        if (obj instanceof Array) {
            output = obj.map(function (x) {
                return clean_keys(x, camelCase);
            });
        } else if (obj instanceof Object && !_npmMoment.default.isMoment(obj)) {
            output = {};
            if (Object.keys(obj).length) {
                for (var key in obj) {
                    var value = clean_keys(obj[key], camelCase);
                    var operation = camelCase ? Ember.String.camelize : Ember.String.decamelize;
                    output[operation(key)] = value;
                }
            }
        } else {
            output = obj;
        }
        return output;
    };

    var sanitize = exports.sanitize = function sanitize(input) {
        if (!input) {
            return '';
        }
        return input.trim().toLowerCase().replace(/[^\w-]+/g, '-').replace(/-+/g, '-').replace(/^-+/g, '').replace(/-+$/g, '');
    };

    // def _convert_case(item):
    //     s = re.sub("(.)([A-Z][a-z]+)", r"\1_\2", item)
    //     return re.sub("([a-z0-9])([A-Z])", r"\1_\2", s).lower()

    // def clean_keys(data):
    //     if isinstance(data, dict):
    //         data = {_convert_case(k): clean_keys(v) for k, v in data.items()}
    //     elif isinstance(data, list):
    //         data = [clean_keys(k) for k in data]
    //     return data

    var filter_keys = exports.filter_keys = function filter_keys(obj, keys) {
        return Object.keys(obj).filter(function (y) {
            return keys.includes(y);
        }).reduce(function (o, i) {
            o[i] = obj[i];
            return o;
        }, {});
    };

    var TRIGGERS = new Set(['date', 'created', 'communicated', 'updated', 'start', 'end', 'startDate', 'terminationDate']);

    var _makeMoment = function _makeMoment(val) {
        if (!val) {
            return null;
        }
        return typeof val === 'number' ? _npmMoment.default.unix(val) : (0, _npmMoment.default)(val);
    };

    var momentize = exports.momentize = function momentize(obj) {
        if (obj && (typeof obj === 'undefined' ? 'undefined' : _typeof(obj)) === 'object') {
            if (Array.isArray(obj)) {
                obj = obj.map(function (x) {
                    return momentize(x);
                });
            } else {
                if (!_npmMoment.default.isMoment(obj)) {
                    Object.keys(obj).map(function (x) {
                        var value = TRIGGERS.has(x) && !_npmMoment.default.isMoment(obj[x]) ? _makeMoment(obj[x]) : momentize(obj[x]);
                        obj[x] = value;
                    });
                }
            }
        }
        return obj;
    };

    var demomentize = exports.demomentize = function demomentize(obj) {
        var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'YYYY-MM-DD HH:mm';

        if (obj && (typeof obj === 'undefined' ? 'undefined' : _typeof(obj)) === 'object') {
            if (Array.isArray(obj)) {
                obj = obj.map(function (x) {
                    return demomentize(x, format);
                });
            } else {
                if (_npmMoment.default.isMoment(obj)) {
                    return obj.format(format);
                } else {
                    var output = {};
                    Object.keys(obj).map(function (x) {
                        output[x] = demomentize(obj[x], format);
                    });
                    return output;
                }
            }
        }
        return obj;
    };

    var clean_shifts = exports.clean_shifts = function clean_shifts(shifts) {
        Object.keys(shifts).map(function (x) {
            shifts[x].meta.dotw = (0, _dateHelper.getDayOfClientWeek)(shifts[x].meta.date) + 1;
            shifts[x].blocks = _npmLodash.default.orderBy(shifts[x].blocks, 'start');

            // console.log('....')
            // shifts[x].blocks.forEach(x => console.log(`\t${x.start.format('HH:mm')}`))
        });
        return shifts;
    };

    var prepareShiftsByEmployeeForSave = exports.prepareShiftsByEmployeeForSave = function prepareShiftsByEmployeeForSave(shiftsByEmployee) {
        var shifts = {};
        Object.keys(shiftsByEmployee).map(function (x) {
            if (x !== 'unassigned') {
                var week = shiftsByEmployee[x];
                Object.values(week).forEach(function (y) {
                    if (y.meta.saved === false) {
                        shifts[y.meta.id] = y;
                    }
                });
            } else {
                shiftsByEmployee[x].forEach(function (y) {
                    if (y.meta.saved === false) {
                        shifts[y.meta.id] = y;
                    }
                });
            }
        });

        return shifts;
    };
});