define('matrix-frontend/pods/new-payroll-worksheet/route', ['exports', 'matrix-frontend/routes/base-route', 'matrix-frontend/actions/_types'], function (exports, _baseRoute, _types) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    exports.default = _baseRoute.default.extend({
        redux: Ember.inject.service(),
        queryParams: {
            hierarchyNodeId: {
                refreshModel: true
            },
            view: {
                refreshModel: true
            },
            subview: {
                refreshModel: true
            }
        },

        beforeModel: function beforeModel(transition) {
            var queryParams = transition.queryParams;
            var defaultParams = {};
            if (queryParams.storeId != null) {
                defaultParams.hierarchyNodeId = null;
            } else {
                defaultParams.storeId = null;
            }
            switch (queryParams.view) {
                case 'summary':
                    {
                        if (queryParams.storeId !== undefined) {
                            this.replaceWith({
                                queryParams: _extends({}, defaultParams, {
                                    view: 'detail'
                                })
                            });
                        }
                        break;
                    }
                case 'detail':
                    {
                        break;
                    }
                default:
                    {
                        this.replaceWith({
                            queryParams: _extends({}, defaultParams, {
                                view: 'summary'
                            })
                        });
                        break;
                    }
            }
            switch (queryParams.subview) {
                case 'store':
                case 'node':
                    break;
                default:
                    {
                        this.replaceWith({
                            queryParams: _extends({}, defaultParams, {
                                subview: 'node'
                            })
                        });
                        break;
                    }
            }
        },
        model: function model(params, transition) {
            var self = this;
            return this._super(params, transition).then(function () {
                var view = transition.queryParams.view;
                var subview = transition.queryParams.subview;
                self.redux.dispatch({
                    type: _types.PW_SET_VIEW,
                    payload: {
                        view: view,
                        subview: subview
                    }
                });
            });
        }
    });
});