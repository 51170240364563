define('matrix-frontend/models/task', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        activity: _emberData.default.belongsTo('activity', { async: true }),
        customFunction: _emberData.default.attr('string'),
        customHoursLabel: _emberData.default.attr('string'),
        customUnitsLabel: _emberData.default.attr('string'),
        description: _emberData.default.attr('string'),
        isCanIbc: _emberData.default.attr('boolean'),
        isEditable: _emberData.default.attr('boolean'),
        isIbc: _emberData.default.attr('boolean'),
        isIbcByWeek: _emberData.default.attr('boolean'),
        isShowPrelim: _emberData.default.attr('boolean'),
        isOptimizedHours: _emberData.default.attr('boolean'),
        isVisible: _emberData.default.attr('boolean'),
        laborStandard: _emberData.default.attr('number'),
        name: _emberData.default.attr('string'),
        orderIndex: _emberData.default.attr('number')
    });
});