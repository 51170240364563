define('matrix-frontend/utils/payroll-worksheet', ['exports', 'matrix-frontend/utils/colors'], function (exports, _colors) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.getKeyedHoursVarianceCategory = exports.getHoursVarianceCategory = exports.getHoursVarianceCategories = exports.getHoursIndicatorColor = exports.getKeyedSalesVarianceCategory = exports.getSalesVarianceCategory = exports.getSalesVarianceCategories = exports.getSalesIndicatorColor = exports.hoursOverGradient = exports.hoursUnderGradient = undefined;


    var salesPositiveGradient = new _colors.Gradient([{
        value: 0,
        color: 0x40ed68
    }, {
        value: 1,
        color: 0x09611c
    }]);

    var salesNegativeGradient = new _colors.Gradient([{
        value: 0,
        color: 0xfefa9a
    }, {
        value: 0.33,
        color: 0xfa5b16
    }, {
        value: 0.66,
        color: 0xfa1111
    }, {
        value: 1,
        color: 0x6c0000
    }]);

    var hoursUnderGradient = exports.hoursUnderGradient = new _colors.Gradient([{
        value: 0,
        color: 0x72b3ff
    }, {
        value: 1,
        color: 0x002a87
    }]);

    var hoursOverGradient = exports.hoursOverGradient = new _colors.Gradient([{
        value: 0,
        color: 0xfefa9a
    }, {
        value: 0.33,
        color: 0xfa5b16
    }, {
        value: 0.66,
        color: 0xfa1111
    }, {
        value: 1,
        color: 0x6c0000
    }]);

    var SALES_VARIANCE_CATEGORIES;
    var HOURS_VARIANCE_CATEGORIES;

    var getSalesIndicatorColor = exports.getSalesIndicatorColor = function getSalesIndicatorColor(variancePercent) {
        if (variancePercent < 0) {
            return salesNegativeGradient.colorFor(-variancePercent / 0.5);
        } else if (variancePercent > 0) {
            return salesPositiveGradient.colorFor(variancePercent);
        }
        return '#ddd';
    };

    var getSalesVarianceCategories = exports.getSalesVarianceCategories = function getSalesVarianceCategories() {
        if (!SALES_VARIANCE_CATEGORIES) {
            var stops = void 0;

            stops = [{ value: 0, sample: 0.1 }, { value: 0.1, sample: 0.5 }, { value: 0.3, sample: 0.9 }];
            var positive = stops.map(function (stop, i) {
                // lower < x <= upper
                return {
                    key: '+' + i,
                    lower: stop.value,
                    upper: (stops[i + 1] || {}).value || Infinity,
                    color: salesPositiveGradient.colorFor(stop.sample),
                    positive: true
                };
            });

            stops = [{ value: 0, sample: 0 }, { value: 0.1, sample: 0.3 }, { value: 0.3, sample: 0.8 }];
            var negative = stops.map(function (stop, i) {
                // lower < -x <= upper
                return {
                    key: '-' + i,
                    lower: stop.value,
                    upper: (stops[i + 1] || {}).value || Infinity,
                    color: salesNegativeGradient.colorFor(stop.sample),
                    positive: false
                };
            });

            var keyed = {};
            positive.forEach(function (c) {
                keyed[c.key] = c;
            });
            negative.forEach(function (c) {
                keyed[c.key] = c;
            });
            keyed[null] = null;

            SALES_VARIANCE_CATEGORIES = {
                negative: negative,
                positive: positive,
                keyed: keyed
            };
        }
        return SALES_VARIANCE_CATEGORIES;
    };

    var getSalesVarianceCategory = exports.getSalesVarianceCategory = function getSalesVarianceCategory(a, b) {
        if (a === undefined || b === undefined) return undefined;
        if (a == null || !b || a == b) return null;

        var percent = a / b - 1;
        var categories = getSalesVarianceCategories();

        var applicable = percent > 0 ? categories.positive : categories.negative;
        var x = percent > 0 ? percent : -percent;
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
            for (var _iterator = applicable[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var category = _step.value;

                if (x > category.lower && x <= category.upper) {
                    return category;
                }
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally {
            try {
                if (!_iteratorNormalCompletion && _iterator.return) {
                    _iterator.return();
                }
            } finally {
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
    };

    var getKeyedSalesVarianceCategory = exports.getKeyedSalesVarianceCategory = function getKeyedSalesVarianceCategory(key) {
        var categories = getSalesVarianceCategories();
        return categories.keyed[key];
    };

    var getHoursIndicatorColor = exports.getHoursIndicatorColor = function getHoursIndicatorColor(variancePercent) {
        if (variancePercent < 0) {
            return hoursUnderGradient.colorFor(-variancePercent / 0.5);
        } else if (variancePercent > 0) {
            return hoursOverGradient.colorFor(variancePercent);
        }
        return '#ddd';
    };

    var getHoursVarianceCategories = exports.getHoursVarianceCategories = function getHoursVarianceCategories() {
        if (!HOURS_VARIANCE_CATEGORIES) {
            var stops = void 0;

            stops = [{ value: 0, sample: 0 }, { value: 0.1, sample: 0.3 }, { value: 0.3, sample: 0.8 }];
            var positive = stops.map(function (stop, i) {
                // lower < x <= upper
                return {
                    key: '+' + i,
                    lower: stop.value,
                    upper: (stops[i + 1] || {}).value || Infinity,
                    color: hoursOverGradient.colorFor(stop.sample),
                    positive: true
                };
            });

            stops = [{ value: 0, sample: 0.1 }, { value: 0.1, sample: 0.5 }, { value: 0.3, sample: 0.9 }];
            var negative = stops.map(function (stop, i) {
                // lower < -x <= upper
                return {
                    key: '-' + i,
                    lower: stop.value,
                    upper: (stops[i + 1] || {}).value || Infinity,
                    color: hoursUnderGradient.colorFor(stop.sample),
                    positive: false
                };
            });

            var keyed = {};
            positive.forEach(function (c) {
                keyed[c.key] = c;
            });
            negative.forEach(function (c) {
                keyed[c.key] = c;
            });
            keyed[null] = null;

            HOURS_VARIANCE_CATEGORIES = {
                negative: negative,
                positive: positive,
                keyed: keyed
            };
        }
        return HOURS_VARIANCE_CATEGORIES;
    };

    var getHoursVarianceCategory = exports.getHoursVarianceCategory = function getHoursVarianceCategory(a, b) {
        if (a === undefined || b === undefined) return undefined;
        if (a == null || !b || a == b) return null;

        var percent = a / b - 1;
        var categories = getHoursVarianceCategories();

        var applicable = percent > 0 ? categories.positive : categories.negative;
        var x = percent > 0 ? percent : -percent;
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
            for (var _iterator2 = applicable[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                var category = _step2.value;

                if (x > category.lower && x <= category.upper) {
                    return category;
                }
            }
        } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
        } finally {
            try {
                if (!_iteratorNormalCompletion2 && _iterator2.return) {
                    _iterator2.return();
                }
            } finally {
                if (_didIteratorError2) {
                    throw _iteratorError2;
                }
            }
        }
    };

    var getKeyedHoursVarianceCategory = exports.getKeyedHoursVarianceCategory = function getKeyedHoursVarianceCategory(key) {
        var categories = getHoursVarianceCategories();
        return categories.keyed[key];
    };
});