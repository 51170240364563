define('matrix-frontend/middleware/checklist', ['exports', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/interactions', 'matrix-frontend/utils/backoff'], function (exports, _types, _interactions, _backoff) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var beforeListeners = {};
    var afterListeners = {};

    afterListeners[_types.TODO_SET_FOCUS_ITEM] = function (store, payload) {
        var _store$getState$cube = store.getState().cube,
            clientCode = _store$getState$cube.clientCode,
            node = _store$getState$cube.node;
        var _store$getState$check = store.getState().checklist,
            focusItemId = _store$getState$check.focusItemId,
            itemStoreitems = _store$getState$check.itemStoreitems;


        if (focusItemId != null) {
            var storeitemData = void 0;
            if (storeitemData = itemStoreitems[focusItemId]) {
                if (storeitemData.exclusions.has(clientCode + '.' + node.tag)) {
                    return;
                }
            }

            // get storeitems
            (0, _backoff.generalBackoff)(function () {
                return store.getState().cube.node && store.getState().cube.clientCode && store.getState().user.currentUser && store.getState().cube.weekEndDate && store.getState().websocket.currentSocket && store.getState().websocket.status == 1;
            }, function () {
                (0, _interactions.gather)('gather storeitems: ' + clientCode + '.' + node.tag, 'ChecklistStoreItemsGather', {
                    'client_code': clientCode,
                    'tag': node.tag,
                    'exclude_tags': [],
                    'item_id': focusItemId
                }, 'TODO_INTAKE_STOREITEMS');
            });
        }
    };

    afterListeners[_types.TODO_INTAKE_ITEMS] = function (store, payload) {
        // const {meta, results} = payload
        // const storeitems = results.map(item => item.storeitem).filter(x => x != null)
        // if(storeitems.length > 0) {
        //     console.info(storeitems)
        // }
    };

    exports.default = function (store) {
        return function (next) {
            return function (action) {
                var type = action.type,
                    payload = action.payload;

                // Before listeners

                if (beforeListeners[type]) {
                    var ret = beforeListeners[type](store, payload);
                    if (ret !== undefined) {
                        if (ret === false) {
                            return;
                        } else {
                            action.payload = ret;
                        }
                    }
                }

                next(action);

                // After listeners
                if (afterListeners[type]) {
                    afterListeners[type](store, payload);
                }
            };
        };
    };
});