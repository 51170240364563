define('matrix-frontend/components/reports-summary', ['exports', 'ember-react-components', 'matrix-frontend/store', 'matrix-frontend/actions/_types', 'npm:react-window', 'npm:moment', 'react', 'matrix-frontend/components/react-only/summary-row', 'matrix-frontend/constants', 'npm:react-tooltip', 'matrix-frontend/components/react-only/store-details-tooltip'], function (exports, _emberReactComponents, _store, _types, _npmReactWindow, _npmMoment, _react, _summaryRow, _constants, _npmReactTooltip, _storeDetailsTooltip) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var FixedSizeList = _npmReactWindow.default.FixedSizeList;


    var TableContainer = function TableContainer(props) {
        var itemData = props.itemData,
            itemCount = props.itemCount,
            itemSize = props.itemSize,
            loading = props.loading,
            outerRef = props.outerRef,
            innerRef = props.innerRef;

        var _useState = (0, _react.useState)(0),
            _useState2 = _slicedToArray(_useState, 2),
            height = _useState2[0],
            setHeight = _useState2[1];

        var _useState3 = (0, _react.useState)(),
            _useState4 = _slicedToArray(_useState3, 2),
            docElement = _useState4[0],
            setDocElement = _useState4[1];

        var resizeHandler = function resizeHandler() {
            setHeight(docElement.clientHeight);
        };

        (0, _react.useEffect)(function () {
            window.addEventListener('resize', resizeHandler);
            return function (_) {
                return window.removeEventListener('resize', resizeHandler);
            };
        });

        var Row = loading ? _summaryRow.FakeRow : _summaryRow.SummaryRow;

        return _react.default.createElement(
            'div',
            { className: 'body content-container', ref: function ref(e) {
                    if (e) {
                        setHeight(e.clientHeight);
                        setDocElement(e);
                    }
                } },
            _react.default.createElement(
                FixedSizeList,
                { style: {
                        position: 'absolute',
                        width: '100%'
                    },
                    height: height,
                    itemData: itemData,
                    itemCount: loading ? 6 : itemCount,
                    itemSize: itemSize,
                    outerRef: outerRef,
                    innerRef: innerRef
                },
                Row
            )
        );
    };

    var comp = function comp(props) {
        var clientCode = props.clientCode,
            weekEndDate = props.weekEndDate,
            node = props.node,
            report = props.report,
            subview = props.subview,
            columns = props.columns,
            directChildren = props.directChildren,
            stores = props.stores,
            rollups = props.rollups,
            infoRollups = props.infoRollups,
            selectBusinessUnit = props.selectBusinessUnit,
            selectSubview = props.selectSubview,
            hierarchies = props.hierarchies;


        var loading = stores == null;

        var onlyStores = directChildren ? !directChildren.some(function (x) {
            return x.type !== 'store';
        }) : false;

        var scrollOuterRef = (0, _react.useRef)();
        var scrollInnerRef = (0, _react.useRef)();

        var _useState5 = (0, _react.useState)('node'),
            _useState6 = _slicedToArray(_useState5, 2),
            sortKey = _useState6[0],
            setSortKey = _useState6[1];

        var _useState7 = (0, _react.useState)(false),
            _useState8 = _slicedToArray(_useState7, 2),
            reverseSort = _useState8[0],
            setReverseSort = _useState8[1];

        var _useState9 = (0, _react.useState)(0),
            _useState10 = _slicedToArray(_useState9, 2),
            scrollbarWidth = _useState10[0],
            setScrollbarWidth = _useState10[1];

        var alignScrollbar = function alignScrollbar() {
            if (scrollInnerRef.current && scrollOuterRef.current) {
                var scrollWidth = scrollOuterRef.current.offsetWidth - scrollInnerRef.current.offsetWidth;
                if (scrollbarWidth != scrollWidth) {
                    setScrollbarWidth(scrollWidth);
                }
            }
        };

        (0, _react.useEffect)(function () {
            _npmReactTooltip.default.rebuild();
        }, []);

        (0, _react.useEffect)(function () {
            alignScrollbar();
            window.addEventListener('resize', alignScrollbar);
            return function (_) {
                return window.removeEventListener('resize', alignScrollbar);
            };
        });

        var updateSort = function updateSort(key) {
            if (key === sortKey) {
                setReverseSort(!reverseSort);
            } else {
                setSortKey(key);
                setReverseSort(true);
            }
        };

        var weekEnd = (0, _npmMoment.default)(weekEndDate).hours(0).minutes(0).seconds(0);

        var itemData = void 0;
        var cells = void 0;

        var minDisplayValue = 0;
        var maxDisplayValue = 0;

        var rowNodes = loading ? [] : !onlyStores && subview === 'node' ? directChildren : stores;

        var SubviewToggleComp = function SubviewToggleComp(_) {
            if (onlyStores) {
                return null;
            }
            if (subview === 'node') {
                return _react.default.createElement(
                    'a',
                    { onClick: function onClick(_) {
                            return selectSubview('store');
                        } },
                    'View by store'
                );
            } else {
                return _react.default.createElement(
                    'a',
                    { onClick: function onClick(_) {
                            return selectSubview('node');
                        } },
                    'View by hierarchy'
                );
            }
        };

        var applyDisplayValue = function applyDisplayValue(value) {
            if (value !== undefined) {
                minDisplayValue = Math.min(minDisplayValue, value);
                maxDisplayValue = Math.max(maxDisplayValue, value);
            }
        };

        var fullTag = clientCode + '.' + node.tag;

        var rollup = rollups[fullTag];
        var infoRollup = infoRollups[fullTag];
        var allSchedulesActive = infoRollup && infoRollup.active;

        var shownRollups = [rollup].concat(_toConsumableArray(rowNodes.map(function (n) {
            return rollups[n.tag];
        })));

        shownRollups.forEach(function (r) {
            if (r) {
                Object.keys(r).forEach(function (dcKey) {
                    applyDisplayValue(r[dcKey].displayValue);
                });
            }
        });

        var sortedNodes = [].concat(_toConsumableArray(rowNodes)).sort(function (a, b) {
            if (sortKey === 'node') {
                return a.code - b.code;
            } else {
                var aValue = ((rollups[a.tag] || {})[sortKey] || {}).displayValue;
                var bValue = ((rollups[b.tag] || {})[sortKey] || {}).displayValue;
                if (aValue === undefined && bValue === undefined) {
                    return 0;
                } else if (aValue === undefined) {
                    return -1;
                } else if (bValue === undefined) {
                    return 1;
                } else {
                    return aValue - bValue;
                }
            }
        });

        if (reverseSort) {
            sortedNodes.reverse();
        }

        itemData = {
            selectBusinessUnit: selectBusinessUnit,
            columns: columns,
            nodes: sortedNodes,
            rollups: rollups,
            minDisplayValue: minDisplayValue,
            maxDisplayValue: maxDisplayValue
        };

        cells = columns.map(function (dc) {
            if (rollup) {
                var dcData = rollup[dc.key];
                if (dcData) {
                    if (dcData.displayValue !== undefined) {
                        var v = Math.round(dcData.displayValue);
                        var rV = void 0;

                        if (v > 0) {
                            rV = v / maxDisplayValue;
                        } else if (v < 0) {
                            rV = v / -minDisplayValue;
                        }
                        v = v > 0 ? v.toLocaleString() : v < 0 ? '(' + (-v).toLocaleString() + ')' : '-';

                        var color = report.displayColor(rV) || {
                            border: '#ccc'
                        };
                        return _react.default.createElement(_summaryRow.SummaryCell, { key: dc.key, value: v, fill: color.fill, border: color.border, dataFor: 'dataTooltip', dataTip: clientCode + '.' + node.tag + '|' + dc.key });
                    }
                }
                return _react.default.createElement(_summaryRow.SummaryCell, { key: dc.key, value: 'N/A', border: '#ccc', dataFor: 'dataTooltip', dataTip: clientCode + '.' + node.tag + '|' + dc.key });
            }
            return _react.default.createElement(_summaryRow.FakeCell, { key: dc.key });
        });

        var sortIndicator = function sortIndicator(key) {
            if (key === sortKey) {
                var classes = ['sort-indicator', !reverseSort ? 'icon-arrow-down' : 'icon-arrow-up'];
                return _react.default.createElement('span', { className: classes.join(' ') });
            }
        };

        var BasicColumn = function BasicColumn(props) {
            var text = props.text,
                _props$classes = props.classes,
                classes = _props$classes === undefined ? [] : _props$classes,
                columnKey = props.columnKey;


            var classList = [].concat(_toConsumableArray(classes), ['sortable', sortKey == columnKey ? 'weight-semi' : '']);

            return _react.default.createElement(
                'div',
                { className: classList.join(' '), onClick: function onClick(_) {
                        return updateSort(columnKey);
                    } },
                text,
                sortIndicator(columnKey)
            );
        };

        var scrollbarAdjustStyle = {
            paddingRight: scrollbarWidth + 'px'
        };

        return _react.default.createElement(
            _react.Fragment,
            null,
            _react.default.createElement(
                'div',
                { className: 'full-flex flex-table' },
                _react.default.createElement(
                    'div',
                    { className: 'head', style: scrollbarAdjustStyle },
                    _react.default.createElement(
                        'div',
                        { className: 'row' },
                        _react.default.createElement(
                            'div',
                            { className: 'wrapper f2' },
                            _react.default.createElement(BasicColumn, { text: 'Name', classes: ['column', 'f1', 'text'], columnKey: 'node' })
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'wrapper f9' },
                            columns.map(function (dc, i) {
                                return _react.default.createElement(BasicColumn, { key: i, classes: ['column', 'f1'], text: dc.text, columnKey: dc.key });
                            })
                        )
                    )
                ),
                _react.default.createElement(
                    'div',
                    { className: 'head totals', style: scrollbarAdjustStyle },
                    _react.default.createElement(
                        'div',
                        { className: 'row' },
                        _react.default.createElement(
                            'div',
                            { className: 'wrapper f2' },
                            _react.default.createElement(
                                'div',
                                { className: 'column wrapper' },
                                _react.default.createElement(
                                    'div',
                                    { className: 'column text', style: {
                                            justifyContent: 'center'
                                        } },
                                    'Total',
                                    SubviewToggleComp()
                                )
                            )
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'wrapper f9' },
                            cells
                        )
                    )
                ),
                _react.default.createElement(TableContainer, {
                    itemData: itemData,
                    itemCount: sortedNodes.length,
                    itemSize: 45,
                    loading: loading,
                    outerRef: scrollOuterRef,
                    innerRef: scrollInnerRef
                })
            ),
            _react.default.createElement(_npmReactTooltip.default, { id: 'basicTooltip', effect: 'solid' }),
            _react.default.createElement(_storeDetailsTooltip.default, { hierarchies: hierarchies }),
            _react.default.createElement(_npmReactTooltip.default, { id: 'dataTooltip', effect: 'solid', getContent: function getContent(dataTip) {
                    if (dataTip && report.tooltipContentFor) {
                        var _dataTip$split = dataTip.split('|'),
                            _dataTip$split2 = _slicedToArray(_dataTip$split, 2),
                            tag = _dataTip$split2[0],
                            dcKey = _dataTip$split2[1];

                        var _rollup = rollups[tag];
                        var _infoRollup = infoRollups[tag];
                        var dcData = _rollup && _rollup[dcKey] || {};
                        return _react.default.createElement(
                            'div',
                            { className: 'report-data-tooltip light-content' },
                            report.tooltipContentFor(dcData, 'summary', _infoRollup)
                        );
                    }
                    return null;
                } })
        );
    };

    exports.default = (0, _emberReactComponents.default)(comp);
});