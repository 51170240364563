define('matrix-frontend/pods/reports/tps/controller', ['exports', 'matrix-frontend/mixins/base-controller', 'npm:underscore'], function (exports, _baseController, _npmUnderscore) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_baseController.default, {
        formUpdated: false,
        loading: Ember.computed('model', function () {
            return this.model === null;
        }),
        isDataEmpty: Ember.computed('model', function () {
            var dailyForecast = this.get('model.dailyForecast');
            if (_npmUnderscore.default.isEmpty(dailyForecast)) {
                return true;
            }
        })
    });
});