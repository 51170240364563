define('matrix-frontend/pods/components/employees-rollup-page/component', ['exports', 'ember-redux', 'matrix-frontend/utils/state'], function (exports, _emberRedux, _state) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var stateToComputed = function stateToComputed(state) {
        return {
            node: state.cube.node,
            view: state.cube.view,
            clients: state.client.clients,
            hierarchies: state.node.hierarchies,
            stores: state.node.stores,
            weekEndDate: state.cube.weekEndDate,
            currentUser: state.user.currentUser
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {
            email: function email() {
                this.modalService.open('email-modal');
            }
        };
    };

    var Comp = Ember.Component.extend({
        redux: Ember.inject.service(),
        stateService: Ember.inject.service(),
        modalService: Ember.inject.service(),

        currentNode: Ember.computed('node', 'hierarchies', function () {
            var node = this.node;
            var hierarchies = this.hierarchies;
            if (node && hierarchies) {
                return hierarchies[node.clientCode + '.' + node.tag];
            } else {
                return null;
            }
        }),

        currentClient: Ember.computed('clients', 'node', function () {
            var node = this.get('node');
            var clients = this.get('clients');
            return node && clients ? clients[node.clientCode] : null;
        }),

        canEmail: Ember.computed('currentUser', 'currentClient', function () {
            return (0, _state.hasScope)('employee.rollup:email');
        }),

        activitiesById: Ember.computed('currentClient.activities', function () {
            var currentClient = this.get('currentClient');
            if (currentClient && currentClient.activities) {
                var a = currentClient.activities.reduce(function (o, i) {
                    o[i.id] = i;
                    return o;
                }, {});
                return a;
            } else {
                return {};
            }
        }),

        positions: Ember.computed('currentClient.positions', function () {
            if (this.currentClient && this.currentClient.positions) {
                return this.currentClient.positions.map(function (x) {
                    return _extends({}, x, { precheck: true });
                });
            }
            return [];
        }),

        items: Ember.computed('currentClient', 'currentNode', 'hierarchies', 'stores', 'weekEndDate', 'activitiesById', function () {
            var _this = this;

            var items = [];

            var blankSkills = Object.keys(this.activitiesById).reduce(function (o, i) {
                o[i] = 0;
                return o;
            }, {});

            if (this.currentNode && this.stores && this.hierarchies) {
                items = [].concat(_toConsumableArray(this.currentNode.children), _toConsumableArray(this.currentNode.stores)).filter(function (x) {
                    return x.tag.startsWith('store') || x.is_active;
                }).sortBy('code');

                if (this.currentClient && this.weekEndDate) {
                    var weekEndDate = this.weekEndDate.format('YYYY-MM-DD');
                    items = items.map(function (node) {
                        var nodeStore = node.tag.startsWith('store') ? _this.stores : _this.hierarchies;
                        var tag = _this.currentClient.code + '.' + node.tag;
                        var nodeData = nodeStore[tag] || {};
                        var skills = _extends({}, blankSkills);
                        var minmax = null;
                        var statistics = null;

                        if (nodeData && nodeData.weeks && nodeData.weeks[weekEndDate]) {
                            if (nodeData.weeks[weekEndDate].skills) {
                                try {
                                    skills = nodeData.weeks[weekEndDate].skills.reduce(function (o, i) {
                                        o[i.activity] = i.count;
                                        return o;
                                    }, skills);
                                } catch (e) {
                                    console.error(e);
                                }
                            }
                            minmax = nodeData.weeks[weekEndDate].minmax;
                            statistics = nodeData.weeks[weekEndDate].statistics;
                        }

                        return _extends({}, node, {
                            skills: skills,
                            minmax: minmax,
                            statistics: statistics
                        });
                    });
                }
            }

            return items;
        }),

        actions: {
            selectBusinessUnit: function selectBusinessUnit(id, type) {
                this.sendAction('selectBusinessUnit', id, type); // eslint-disable-line ember/closure-actions
            }
        }
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});