define('matrix-frontend/reducers/timeout', ['exports', 'matrix-frontend/actions/_types'], function (exports, _types) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var initialState = { ttl: 0 };

    var timeout = function timeout(state, action) {
        switch (action.type) {
            case _types.TIMEOUT_INCREMENT:
                {
                    return _extends({}, state, { ttl: state.ttl + 1 });
                }
            case _types.TIMEOUT_RESET:
                {
                    return _extends({}, state, initialState);
                }
            default:
                {
                    return state || initialState;
                }
        }
    };

    exports.default = timeout;
});