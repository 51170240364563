define('matrix-frontend/actions/_types', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var FOOBAR = exports.FOOBAR = 'FOOBAR';
  var PING = exports.PING = 'PING';

  var ERROR = exports.ERROR = 'ERROR';
  var SUCCESS = exports.SUCCESS = 'SUCCESS';
  var SAVED = exports.SAVED = 'SAVED';
  var MODAL_SAVE_COMPLETE = exports.MODAL_SAVE_COMPLETE = 'MODAL_SAVE_COMPLETE';

  var NOCLIENT_SCOPE_INTAKE = exports.NOCLIENT_SCOPE_INTAKE = 'NOCLIENT:SCOPE_INTAKE';
  var NOCLIENT_RELOAD_RSMANAGEMENT = exports.NOCLIENT_RELOAD_RSMANAGEMENT = 'NOCLIENT:RELOAD_RSMANAGEMENT';

  var CLIENT_INTAKE = exports.CLIENT_INTAKE = 'CLIENT:INTAKE';
  var CLIENT_BASE = exports.CLIENT_BASE = 'CLIENT:BASE';
  var CLIENT_USER_INTAKE = exports.CLIENT_USER_INTAKE = 'CLIENT:USER_INTAKE';
  var CLIENT_ROLE_INTAKE = exports.CLIENT_ROLE_INTAKE = 'CLIENT:ROLE_INTAKE';
  var CLIENT_NONSTORE_USER_SAVE = exports.CLIENT_NONSTORE_USER_SAVE = 'CLIENT:NONSTORE_USER_SAVE';
  var CLIENT_NONSTORE_USER_UPDATE = exports.CLIENT_NONSTORE_USER_UPDATE = 'CLIENT:NONSTORE_USER_UPDATE';

  var CUBE_GO_TO_DAY = exports.CUBE_GO_TO_DAY = 'CUBE:GO_TO_DAY';
  var CUBE_GO_TO_WEEK = exports.CUBE_GO_TO_WEEK = 'CUBE:GO_TO_WEEK';
  var CUBE_SET_PARTIAL = exports.CUBE_SET_PARTIAL = 'CUBE:SET_PARTIAL';
  var CUBE_SET_WEEK_END_DATE = exports.CUBE_SET_WEEK_END_DATE = 'CUBE:SET_WEEK_END_DATE';
  var CUBE_SET_NODE = exports.CUBE_SET_NODE = 'CUBE:SET_NODE';
  var CUBE_SET_PAGE = exports.CUBE_SET_PAGE = 'CUBE:SET_PAGE';
  var CUBE_SET_DATE = exports.CUBE_SET_DATE = 'CUBE:SET_DATE';
  var CUBE_SET_PAGE_IN_PROGRESS = exports.CUBE_SET_PAGE_IN_PROGRESS = 'CUBE:SET_PAGE_IN_PROGRESS';
  var CUBE_SET_TARGET = exports.CUBE_SET_TARGET = 'CUBE:SET_TARGET';
  var CUBE_TOGGLE_MULTI_USER_SAVE_MODE = exports.CUBE_TOGGLE_MULTI_USER_SAVE_MODE = 'CUBE:TOGGLE_MULTI_USER_SAVE_MODE';

  var EE_SAVE_SKILL = exports.EE_SAVE_SKILL = 'EMPLOYEE:SAVE_SKILL';
  var EE_SAVE_EMPLOYEE = exports.EE_SAVE_EMPLOYEE = 'EMPLOYEE:SAVE_EMPLOYEE';
  var EE_SAVE_AVAILABILITY = exports.EE_SAVE_AVAILABILITY = 'EMPLOYEE:SAVE_AVAILABILITY';
  var EE_RELOAD = exports.EE_RELOAD = 'EMPLOYEE:RELOAD';
  var EE_INTAKE = exports.EE_INTAKE = 'EMPLOYEE:INTAKE';
  var EE_UPDATE_SKILL = exports.EE_UPDATE_SKILL = 'EMPLOYEE:UPDATE_SKILL';
  var EE_UPDATE_EMPLOYEE = exports.EE_UPDATE_EMPLOYEE = 'EMPLOYEE:UPDATE_EMPLOYEE';
  var EE_PREP_EMAIL = exports.EE_PREP_EMAIL = 'EMPLOYEE:PREP_EMAIL';
  var EE_SEND_EMAIL = exports.EE_SEND_EMAIL = 'EMPLOYEE:SEND_EMAIL';

  var EP_SET_EMPLOYEE_PANEL = exports.EP_SET_EMPLOYEE_PANEL = 'EMPLOYEE_PANEL:SET_EMPLOYEE_PANEL';
  var EP_PIN = exports.EP_PIN = 'EMPLOYEE_PANEL:PIN';
  var EP_UNPIN = exports.EP_UNPIN = 'EMPLOYEE_PANEL:EP_UNPIN';
  var EP_SET = exports.EP_SET = 'EMPLOYEE_PANEL:SET';

  var GENERAL_RECORD_CANCELLABLE_REQUEST = exports.GENERAL_RECORD_CANCELLABLE_REQUEST = 'GENERAL:RECORD_CANCELLABLE_REQUEST';
  var GENERAL_CANCEL_REQUESTS = exports.GENERAL_CANCEL_REQUESTS = 'GENERAL:CANCEL_REQUESTS';
  var GENERAL_INTAKE_ROLES = exports.GENERAL_INTAKE_ROLES = 'GENERAL:INTAKE_ROLES';
  var GENERAL_INTAKE_OUT_OF_OFFICE_ACTIVITIES = exports.GENERAL_INTAKE_OUT_OF_OFFICE_ACTIVITIES = 'GENERAL:INTAKE_OUT_OF_OFFICE_ACTIVITIES';

  var NODE_INTAKE = exports.NODE_INTAKE = 'NODE:INTAKE';
  var NODE_MULTI_INTAKE = exports.NODE_MULTI_INTAKE = 'NODE:MULTI_INTAKE';
  var NODE_REFRESH_FORECAST = exports.NODE_REFRESH_FORECAST = 'NODE:REFRESH_FORECAST';
  var NODE_CLEAR_FORECAST = exports.NODE_CLEAR_FORECAST = 'NODE:CLEAR_FORECAST';
  var NODE_REFRESH_TASK_HOURS = exports.NODE_REFRESH_TASK_HOURS = 'NODE:REFRESH_TASK_HOURS';
  var NODE_REFRESH_ACTUAL_LABOR = exports.NODE_REFRESH_ACTUAL_LABOR = 'NODE:REFRESH_ACTUAL_LABOR';
  var NODE_REFRESH_EMPLOYEES = exports.NODE_REFRESH_EMPLOYEES = 'NODE:REFRESH_EMPLOYEES';
  var NODE_CLEAR_TASK_HOURS = exports.NODE_CLEAR_TASK_HOURS = 'NODE:CLEAR_TASK_HOURS';
  var NODE_CLEAR_ACTUAL_LABOR = exports.NODE_CLEAR_ACTUAL_LABOR = 'NODE:CLEAR_ACTUAL_LABOR';
  var NODE_SAVE_HOURS_OF_OPERATION = exports.NODE_SAVE_HOURS_OF_OPERATION = 'NODE:SAVE_HOURS_OF_OPERATION';
  var NODE_EE_UPDATE_AVAILABILITY = exports.NODE_EE_UPDATE_AVAILABILITY = 'NODE:EE_UPDATE_AVAILABILITY';
  var NODE_SETUP_HOURS = exports.NODE_SETUP_HOURS = 'NODE:SETUP_HOURS';
  var NODE_SAVE_STORE_INFO = exports.NODE_SAVE_STORE_INFO = 'NODE:SAVE_STORE_INFO';
  var NODE_EE_LOAN_SHIFTS = exports.NODE_EE_LOAN_SHIFTS = 'NODE:EE_LOAN_SHIFTS';
  var NODE_INTAKE_HIERARCHY = exports.NODE_INTAKE_HIERARCHY = 'NODE:INTAKE_HIERARCHY';
  var NODE_INTAKE_REPORT_DATA = exports.NODE_INTAKE_REPORT_DATA = 'NODE:INTAKE_REPORT_DATA';
  var NODE_INTAKE_HOO_GATHER = exports.NODE_INTAKE_HOO_GATHER = 'NODE:INTAKE_HOO_GATHER';

  var PREFERENCE_SET = exports.PREFERENCE_SET = 'PREFERENCE:SET';

  var SE_SET = exports.SE_SET = 'SCHEDULE_EDIT:SET';
  var SE_RELOAD = exports.SE_RELOAD = 'SCHEDULE_EDIT:RELOAD';
  var SE_SET_RESULTS = exports.SE_SET_RESULTS = 'SCHEDULE_EDIT:SET_RESULTS';
  var SE_COLLAPSE = exports.SE_COLLAPSE = 'SCHEDULE_EDIT:COLLAPSE';
  var SE_COMPILE_BRUSH = exports.SE_COMPILE_BRUSH = 'SCHEDULE_EDIT:COMPILE_BRUSH';
  var SE_DO_SWAP = exports.SE_DO_SWAP = 'SCHEDULE_EDIT:DO_SWAP';
  var SE_EXPAND_ROW = exports.SE_EXPAND_ROW = 'SCHEDULE_EDIT:EXPAND_ROW';
  var SE_FLASH_SHIFTS = exports.SE_FLASH_SHIFTS = 'SCHEDULE_EDIT:FLASH_SHIFTS';
  var SE_GO_TO_DAY = exports.SE_GO_TO_DAY = 'SCHEDULE_EDIT:GO_TO_DAY';
  var SE_INTAKE = exports.SE_INTAKE = 'SCHEDULE_EDIT:INTAKE';
  var SE_INTAKE_PARTIAL = exports.SE_INTAKE_PARTIAL = 'SCHEDULE_EDIT:INTAKE_PARTIAL';
  var SE_CHART_DRAW = exports.SE_CHART_DRAW = 'SCHEDULE_EDIT:CHART_DRAW';
  var SE_PAINTING = exports.SE_PAINTING = 'SCHEDULE_EDIT:PAINTING';
  var SE_RESET = exports.SE_RESET = 'SCHEDULE_EDIT:RESET';
  var SE_SET_ACTIVE_SHIFT = exports.SE_SET_ACTIVE_SHIFT = 'SCHEDULE_EDIT:SET_ACTIVE_SHIFT';
  var SE_SET_AVAILABLE_EMPLOYEE_IDS = exports.SE_SET_AVAILABLE_EMPLOYEE_IDS = 'SCHEDULE_EDIT:SET_AVAILABLE_EMPLOYEE_IDS';
  var SE_SET_ASSISNABLE_EMPLOYEE_IDS = exports.SE_SET_ASSISNABLE_EMPLOYEE_IDS = 'SCHEDULE_EDIT:SET_ASSISNABLE_EMPLOYEE_IDS';
  var SE_SET_EMPLOYEE_FILTER = exports.SE_SET_EMPLOYEE_FILTER = 'SCHEDULE_EDIT:SET_EMPLOYEE_FILTER';
  var SE_SET_SKILLED_EMPLOYEE_IDS = exports.SE_SET_SKILLED_EMPLOYEE_IDS = 'SCHEDULE_EDIT:SET_SKILLED_EMPLOYEE_IDS';
  var SE_SET_PAINTBRUSH = exports.SE_SET_PAINTBRUSH = 'SCHEDULE_EDIT:SET_PAINTBRUSH';
  var SE_SET_SAR = exports.SE_SET_SAR = 'SCHEDULE_EDIT:SET_SAR';
  var SE_SET_SMART_ASSIGN_EMPLOYEE = exports.SE_SET_SMART_ASSIGN_EMPLOYEE = 'SCHEDULE_EDIT:SET_SMART_ASSIGN_EMPLOYEE';
  var SE_SUNSET_SHIFT = exports.SE_SUNSET_SHIFT = 'SCHEDULE_EDIT:SUNSET_SHIFT';
  var SE_SET_SHIFTS = exports.SE_SET_SHIFTS = 'SCHEDULE_EDIT:SET_SHIFTS';
  var SE_SET_STARTLEFT = exports.SE_SET_STARTLEFT = 'SCHEDULE_EDIT:SET_STARTLEFT';
  var SE_SET_STARTTOP = exports.SE_SET_STARTTOP = 'SCHEDULE_EDIT:SET_STARTTOP';
  // export const SE_SET_EMPLOYEE_FLAGS = 'SCHEDULE_EDIT:SET_EMPLOYEE_FLAGS';
  var SE_SHIFTS_UPDATED = exports.SE_SHIFTS_UPDATED = 'SCHEDULE_EDIT:SHIFTS_UPDATED';
  var SE_SCHEDULE_SAVE = exports.SE_SCHEDULE_SAVE = 'SCHEDULE_EDIT:SCHEDULE_SAVE';
  var SE_SMART_ASSIGN = exports.SE_SMART_ASSIGN = 'SCHEDULE_EDIT:SMART_ASSIGN';
  var SE_SMART_ASSIGN_IGNORE = exports.SE_SMART_ASSIGN_IGNORE = 'SCHEDULE_EDIT:SMART_ASSIGN_IGNORE';
  var SE_START_PAINTING = exports.SE_START_PAINTING = 'SCHEDULE_EDIT:START_PAINTING';
  var SE_STOP_PAINTING = exports.SE_STOP_PAINTING = 'SCHEDULE_EDIT:STOP_PAINTING';
  var SE_SWAP_SHIFTS = exports.SE_SWAP_SHIFTS = 'SCHEDULE_EDIT:SWAP_SHIFTS';
  var SE_TIME_LEFT = exports.SE_TIME_LEFT = 'SCHEDULE_EDIT:TIME_LEFT';
  var SE_TIME_RIGHT = exports.SE_TIME_RIGHT = 'SCHEDULE_EDIT:TIME_RIGHT';
  var SE_TIME_PLUS = exports.SE_TIME_PLUS = 'SCHEDULE_EDIT:TIME_PLUS';
  var SE_TIME_MINUS = exports.SE_TIME_MINUS = 'SCHEDULE_EDIT:TIME_MINUS';
  var SE_TIME_DISPLAY = exports.SE_TIME_DISPLAY = 'SCHEDULE_EDIT:TIME_DISPLAY';
  var SE_TOGGLE_EMPLOYEE_FILTER = exports.SE_TOGGLE_EMPLOYEE_FILTER = 'SCHEDULE_EDIT:TOGGLE_EMPLOYEE_FILTER';
  var SE_TOGGLE_EDIT_MODE = exports.SE_TOGGLE_EDIT_MODE = 'SCHEDULE_EDIT:TOGGLE_EDIT_MODE';
  var SE_TOGGLE_EDIT_MODE_COMPLETE = exports.SE_TOGGLE_EDIT_MODE_COMPLETE = 'SCHEDULE_EDIT:TOGGLE_EDIT_MODE_COMPLETE';
  var SE_TOGGLE_SAVE_MODE = exports.SE_TOGGLE_SAVE_MODE = 'SCHEDULE_EDIT:TOGGLE_SAVE_MODE';
  var SE_VALIDATE_SHIFTS = exports.SE_VALIDATE_SHIFTS = 'SCHEDULE_EDIT:VALIDATE_SHIFTS';
  var SE_EMAIL_SCHEDULES = exports.SE_EMAIL_SCHEDULES = 'SCHEDULE_EDIT:EMAIL_SCHEDULES';

  var SS_CLAIM_INTAKE = exports.SS_CLAIM_INTAKE = 'SELF_SERVE:CLAIM_INTAKE';

  var TIMEOUT_INCREMENT = exports.TIMEOUT_INCREMENT = 'TIMEOUT_INCREMENT';
  var TIMEOUT_RESET = exports.TIMEOUT_RESET = 'TIMEOUT_RESET';

  var USER_LOGIN = exports.USER_LOGIN = 'USER:LOGIN';
  var USER_REDIRECT_LOCATION = exports.USER_REDIRECT_LOCATION = 'USER:REDIRECT_LOCATION';
  var USER_EMAIL_PASSWORD = exports.USER_EMAIL_PASSWORD = 'USER:EMAIL_PASSWORD';
  var USER_SEND_VERIFICATION_EMAIL = exports.USER_SEND_VERIFICATION_EMAIL = 'USER:SEND_VERIFICATION_EMAIL';
  var USER_SEND_RESET_EMAIL = exports.USER_SEND_RESET_EMAIL = 'USER:SEND_RESET_EMAIL';
  var USER_SAVE = exports.USER_SAVE = 'USER:SAVE';

  var TODO_SET_FOCUS_ITEM = exports.TODO_SET_FOCUS_ITEM = 'TODO:SET_FOCUS_ITEM';
  var TODO_SET_SHOWSTORES = exports.TODO_SET_SHOWSTORES = 'TODO:SET_SHOWSTORES';
  var TODO_SET_INCLUDE_OVERDUE = exports.TODO_SET_INCLUDE_OVERDUE = 'TODO:SET_INCLUDE_OVERDUE';
  var TODO_INTAKE_ITEMS = exports.TODO_INTAKE_ITEMS = 'TODO:INTAKE_ITEMS';
  var TODO_INTAKE_STOREITEMS = exports.TODO_INTAKE_STOREITEMS = 'TODO:INTAKE_STOREITEMS';
  var TODO_SET_MODAL_DATA = exports.TODO_SET_MODAL_DATA = 'TODO:SET_MODAL_DATA';
  var TODO_EDIT_STOREITEM = exports.TODO_EDIT_STOREITEM = 'TODO:EDIT_STOREITEM';

  var PW_SET_VIEW = exports.PW_SET_VIEW = 'PAYROLL_WORKSHEET:SET_VIEW';
  var PW_INTAKE_ITEMS = exports.PW_INTAKE_ITEMS = 'PAYROLL_WORKSHEET:INTAKE_ITEMS';
  var PW_SET_OPT = exports.PW_SET_OPT = 'PAYROLL_WORKSHEET:SET_OPT';
  var PW_HANDLE_SAVE = exports.PW_HANDLE_SAVE = 'PAYROLL_WORKSHEET:HANDLE_SAVE';
  var PW_INTAKE_COST = exports.PW_INTAKE_COST = 'PAYROLL_WORKSHEET:INTAKE_COST';

  var RP_SET_GATHERING_IN_PROGRESS = exports.RP_SET_GATHERING_IN_PROGRESS = 'REPORTS:SET_GATHERING_IN_PROGRESS';
  var RP_SET_REPORT = exports.RP_SET_REPORT = 'REPORTS:SET_REPORT';
  var RP_SET_VIEW = exports.RP_SET_VIEW = 'REPORTS:SET_VIEW';
  var RP_SET_TIMECOLUMN = exports.RP_SET_TIMECOLUMN = 'REPORTS:SET_TIMECOLUMN';
  // export const RP_INTAKE_HIERARCHY = 'REPORTS:INTAKE_HIERARCHY'
  // export const RP_INTAKE_DATA = 'REPORTS:INTAKE_DATA'
  var RP_INTAKE_HOURLY = exports.RP_INTAKE_HOURLY = 'REPORTS:INTAKE_HOURLY';

  var SHS_SET = exports.SHS_SET = 'STORE_HOURS_SUMMARY:SET';

  var WS_CONNECT = exports.WS_CONNECT = 'WEBSOCKET:CONNECT';
  var WS_DISCONNECT = exports.WS_DISCONNECT = 'WEBSOCKET:DISCONNECT';
  var WS_STATUS = exports.WS_STATUS = 'WEBSOCKET:STATUS';
  var WS_CLOSED = exports.WS_CLOSED = 'WEBSOCKET:CLOSED';
  var WS_OPENED = exports.WS_OPENED = 'WEBSOCKET:OPENED';
  var WS_SEND = exports.WS_SEND = 'WEBSOCKET:SEND';
  var WS_RECEIVE = exports.WS_RECEIVE = 'WEBSOCKET:RECEIVE';
  var WS_RECEIVE_GATHER = exports.WS_RECEIVE_GATHER = 'WEBSOCKET:RECEIVE_GATHER';
  var WS_INTAKE = exports.WS_INTAKE = 'WEBSOCKET:INTAKE';
  var WS_REGISTER = exports.WS_REGISTER = 'WEBSOCKET:REGISTER';
  var WS_UNREGISTER = exports.WS_UNREGISTER = 'WEBSOCKET:UNREGISTER';
  var WS_NOTICE = exports.WS_NOTICE = 'WEBSOCKET:NOTICE';
  var WS_REGISTER_VIEWERS = exports.WS_REGISTER_VIEWERS = 'WEBSOCKET:REGISTER_VIEWERS';
  var WS_RESET_FEED = exports.WS_RESET_FEED = 'WEBSOCKET:RESET_FEED';
  var WS_SWITCH_TO_FEED = exports.WS_SWITCH_TO_FEED = 'WEBSOCKET:SWITCH_TO_FEED';

  // export const WS_CONNECTING = 'WEBSOCKET:CONNECTING'
  // export const WS_CLOSE = 'WEBSOCKET:CLOSE'
  // export const WS_FOOBAR = 'WEBSOCKET:FOOBAR'
  // export const WS_SUBSCRIBE = 'WEBSOCKET:SUBSCRIBE'
  // export const WS_UNSUBSCRIBE = 'WEBSOCKET:UNSUBSCRIBE'
});