define('matrix-frontend/components/checklist-storeitem-edit', ['exports', 'ember-react-components', 'matrix-frontend/store', 'matrix-frontend/actions/_types', 'npm:moment', 'react', 'npm:react-tooltip', 'matrix-frontend/components/react-only/checklist-item-row', 'matrix-frontend/constants', 'matrix-frontend/utils/format-helpers', 'matrix-frontend/utils/helpers', 'lodash', 'matrix-frontend/services/notification-service'], function (exports, _emberReactComponents, _store, _types, _npmMoment, _react, _npmReactTooltip, _checklistItemRow, _constants, _formatHelpers, _helpers, _lodash, _notificationService) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var formatDate = function formatDate(d) {
        if (!d) {
            return '-';
        }
        return (0, _npmMoment.default)(d).format('ddd MMM Do');
    };

    var formatDateTime = function formatDateTime(dt) {
        if (!dt) {
            return '-';
        }
        return (0, _npmMoment.default)(dt).format('MMM D, YYYY h:mm A[M]');
    };

    var statusIsDone = function statusIsDone(status) {
        return status === 'COMPLETED' || status === 'LATE';
    };

    var oppositeStatus = function oppositeStatus(status, now, start, due) {
        if (statusIsDone(status)) return now.isSameOrAfter(due) ? 'OVERDUE' : now.isSameOrAfter(start) ? 'INCOMPLETE' : 'UPCOMING';
        return now.isSameOrAfter(due) ? 'LATE' : 'COMPLETED';
    };

    var calcStatus = function calcStatus(now, completedAt, start, due) {
        if (completedAt != null) return completedAt.isSameOrAfter(due) ? 'LATE' : 'COMPLETED';else if (now.isSameOrAfter(due)) return 'OVERDUE';
        return now.isSameOrAfter(start) ? 'INCOMPLETE' : 'UPCOMING';
    };

    var getStatusAttrs = function getStatusAttrs(status) {
        var style = {};
        var text = "";
        switch (status) {
            case _constants.ChecklistItemStatus.COMPLETED:
                {
                    style = {
                        background: '#58d77c',
                        color: 'white'
                    };
                    text = 'Completed';
                    break;
                }
            case _constants.ChecklistItemStatus.LATE:
                {
                    style = {
                        background: '#9e7a24',
                        color: 'white'
                    };
                    text = 'Completed Late';
                    break;
                }
            case _constants.ChecklistItemStatus.OVERDUE:
                {
                    style = {
                        background: '#ed1111',
                        color: 'white'
                    };
                    text = 'Overdue';
                    break;
                }
            case _constants.ChecklistItemStatus.INCOMPLETE:
                {
                    style = {
                        background: '#d0d0d0',
                        color: 'black'
                    };
                    text = 'Incomplete';
                    break;
                }
            case _constants.ChecklistItemStatus.UPCOMING:
                {
                    style = {
                        background: '#e0e0e0',
                        color: '#444'
                    };
                    text = 'Upcoming';
                    break;
                }
        }

        return {
            style: style,
            text: text
        };
    };

    var StatusFlipper = function StatusFlipper(props) {
        var fromStatus = props.fromStatus,
            toStatus = props.toStatus,
            flipping = props.flipping,
            onClick = props.onClick,
            onFinishFlip = props.onFinishFlip;


        var fromAttrs = getStatusAttrs(fromStatus);
        var toAttrs = getStatusAttrs(toStatus);

        return _react.default.createElement(
            'div',
            { className: 'column f1' },
            _react.default.createElement(
                'div',
                { className: 'status-flipper', onClick: onClick },
                _react.default.createElement(
                    'div',
                    { className: 'inner ' + (flipping == true ? 'flipping' : ''), onAnimationEnd: onFinishFlip },
                    _react.default.createElement(
                        'div',
                        { className: 'front', style: fromAttrs.style },
                        fromAttrs.text
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'back', style: toAttrs.style },
                        toAttrs.text
                    )
                )
            )
        );
    };

    var ValueEdit = (0, _react.forwardRef)(function (props, ref) {
        var value = props.value,
            classes = props.classes,
            onValueChange = props.onValueChange,
            onTabOut = props.onTabOut,
            formatFunc = props.formatFunc,
            allowNegative = props.allowNegative;

        var _useState = (0, _react.useState)({
            value: '',
            // initialValue: '',
            madeEdit: false
        }),
            _useState2 = _slicedToArray(_useState, 2),
            state = _useState2[0],
            setState = _useState2[1];

        (0, _react.useLayoutEffect)(function () {
            setState(_extends({}, state, {
                value: formatFunc(value)
            }));
        }, [value]);

        var onFocus = function onFocus(e) {
            setState(_extends({}, state, {
                madeEdit: false
            }));
            e.target.select();
        };

        var onKeyDown = function onKeyDown(e) {
            if ((e.key === 'Tab' || e.key === 'Enter') && onTabOut) {
                onTabOut(e);
            } else if (e.key === 'Enter') {
                e.target.blur();
            }
        };

        var onChange = function onChange(e) {
            setState(_extends({}, state, {
                madeEdit: true,
                value: e.target.value
            }));
        };

        var getParsedValue = function getParsedValue(s) {
            var x = parseFloat(s.replace(/,/g, ''));
            if (isNaN(x) || !allowNegative && x < 0) return null;
            return x;
        };

        var onBlur = function onBlur(e) {
            var newValue = getParsedValue(state.value);
            if (state.madeEdit && onValueChange && newValue != null) onValueChange(newValue);
            if (newValue == null) newValue = value;
            setState(_extends({}, state, {
                value: formatFunc(newValue)
            }));
        };

        return _react.default.createElement('input', { ref: ref, className: classes, onFocus: onFocus, onKeyDown: onKeyDown, onChange: onChange, onBlur: onBlur, value: state.value || '' });
    });

    var DayColumn = function DayColumn(props) {
        var index = props.index,
            date = props.date,
            hours = props.hours,
            enabled = props.enabled,
            withBorder = props.withBorder,
            onHoursChange = props.onHoursChange,
            onTabOut = props.onTabOut,
            shouldSelect = props.shouldSelect,
            allowNegative = props.allowNegative,
            laborStandard = props.laborStandard,
            editUnits = props.editUnits;


        var isFirstOfMonth = date.date() == 1;

        var classes = ['column'];
        if (withBorder) classes.push('border-l');
        if (!enabled) classes.push('day-disabled');

        var ref = (0, _react.useRef)();

        (0, _react.useLayoutEffect)(function () {
            if (shouldSelect) {
                window.requestAnimationFrame(function () {
                    if (ref.current) {
                        ref.current.focus();
                    }
                });
            }
        }, [shouldSelect]);

        return _react.default.createElement(
            'div',
            { className: classes.join(' ') },
            _react.default.createElement(
                'div',
                { className: 'column f2 text-center' },
                _react.default.createElement(
                    'div',
                    { className: 'top-text' },
                    date.format('ddd')
                ),
                _react.default.createElement(
                    'div',
                    { className: 'main-text' },
                    isFirstOfMonth || index == 0 ? date.format('MMM D') : date.date()
                )
            ),
            _react.default.createElement(
                'div',
                { className: 'column f2' },
                enabled ? editUnits ? _react.default.createElement(
                    _react.Fragment,
                    null,
                    _react.default.createElement(ValueEdit, {
                        ref: ref,
                        classes: 'flex f1 no-style-input text-center text-editable',
                        value: hours / laborStandard,
                        allowNegative: allowNegative,
                        formatFunc: function formatFunc(x) {
                            return (0, _formatHelpers.numberFormat)(x || 0, { decimals: 0 });
                        },
                        onValueChange: function onValueChange(x) {
                            onHoursChange(x * laborStandard);
                        },
                        onTabOut: onTabOut
                    }),
                    _react.default.createElement(
                        'div',
                        { className: 'flex f1 items-center' },
                        (0, _formatHelpers.numberFormat)(hours || 0, { decimals: 2 })
                    )
                ) : _react.default.createElement(ValueEdit, {
                    ref: ref,
                    classes: 'flex f1 no-style-input text-center text-editable',
                    value: hours,
                    allowNegative: allowNegative,
                    formatFunc: function formatFunc(x) {
                        return (0, _formatHelpers.numberFormat)(x || 0, { decimals: 2 });
                    },
                    onValueChange: function onValueChange(x) {
                        onHoursChange(x);
                    },
                    onTabOut: onTabOut
                }) : null
            )
        );
    };

    var comp = function comp(props) {
        var currentUser = props.currentUser,
            currentWeekEndDate = props.currentWeekEndDate,
            weekEndDate = props.weekEndDate,
            task = props.task,
            budget = props.budget,
            item = props.item,
            storeitem = props.storeitem,
            editedStoreitem = props.editedStoreitem,
            showSave = props.showSave,
            offset = props.offset,
            transitionTo = props.transitionTo;


        var canBeNegative = budget ? budget.canBeNegative == 'Y' : false;

        var _useState3 = (0, _react.useState)(false),
            _useState4 = _slicedToArray(_useState3, 2),
            statusFlipping = _useState4[0],
            setStatusFlipping = _useState4[1];

        var _useState5 = (0, _react.useState)(null),
            _useState6 = _slicedToArray(_useState5, 2),
            autoSelectI = _useState6[0],
            setAutoSelectI = _useState6[1];

        // const [numClicks, setNumClicks] = useState(0)

        var setOffset = function setOffset(x) {
            return _store.store.dispatch({
                type: _types.TODO_SET_MODAL_DATA,
                payload: {
                    modalOffset: x
                }
            });
        };

        (0, _react.useLayoutEffect)(function () {
            setAutoSelectI(null);
        }, [item, storeitem]);

        if (item && storeitem) {

            var pairLaborStandard = function pairLaborStandard(name) {
                var units = storeitem[name + '_units'];
                var hours = storeitem[name + '_hours'];
                if (units != null && units != 0 && hours != null) return hours / units;
                return null;
            };

            var laborStandard = (0, _helpers.coalesce)(pairLaborStandard('final'), pairLaborStandard('schedule'), pairLaborStandard('prelim'), storeitem.store_labor_standard_override, item.labor_standard_override, task.laborStandard);

            var unitOfMeasure = item.unit_of_measure;
            var hasFinal = storeitem.final_hours != null || storeitem.final_units != null;
            var editUnits = unitOfMeasure != null;

            // get info from checklist items
            var now = _npmMoment.default.utc();
            var startDate = _npmMoment.default.utc(storeitem.start_date_exception ? storeitem.start_date_exception : item.start_date);
            var dueDate = _npmMoment.default.utc(storeitem.due_date_exception ? storeitem.due_date_exception : item.due_date);
            var countedStartDate = startDate.clone().add(10, 'hours');
            var countedDueDate = dueDate.clone().add(34, 'hours');
            var numDays = dueDate.diff(startDate, 'days') + 1;

            var dateStr = function dateStr(dayI) {
                return startDate.clone().add(dayI, 'days').format('YYYY-MM-DD');
            };

            var lastDate = weekEndDate.clone().utc().add(weekEndDate.utcOffset(), 'm').set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).add(offset, 'days');
            var canGoEarlier = lastDate.diff(startDate, 'days') - 6 > 0;
            var canGoLater = lastDate.diff(dueDate, 'days') < 0;

            var allocatedHours = _extends({}, storeitem.allocated_hours || {}, editedStoreitem.allocated_hours || {});
            var numAllocated = Object.keys(allocatedHours).reduce(function (agg, key) {
                return agg + allocatedHours[key];
            }, 0);
            var numRequired = storeitem.final_hours;
            var numRemaining = numRequired != null ? numRequired - numAllocated : 0;
            var allHoursAllocated = numRequired != null ? Math.abs(numRemaining) < 0.01 : true;

            var setAllocatedHours = function setAllocatedHours(hours) {
                var total = Object.values(_extends({}, allocatedHours, hours)).reduce(function (agg, x) {
                    return agg + x;
                }, 0);
                var edit = _extends({}, editedStoreitem, {
                    allocated_hours: hours
                });
                if (numRequired == null) {
                    edit.schedule_hours = total;
                    edit.schedule_units = total / laborStandard;
                }
                _store.store.dispatch({
                    type: _types.TODO_EDIT_STOREITEM,
                    payload: {
                        edit: edit
                    }
                });
            };

            // calculate current information shown based on user interaction
            var completedAt = editedStoreitem.completedAt === undefined ? storeitem.completed_at : editedStoreitem.completedAt;
            var status = calcStatus(now, completedAt == null ? null : _npmMoment.default.utc(completedAt), countedStartDate, countedDueDate);
            var toStatus = oppositeStatus(status, now, countedStartDate, countedDueDate);

            // determine if any changes
            var statusChanged = status !== storeitem.status;
            var completedAtChanged = statusChanged && completedAt == null && storeitem.completed_at != null || statusChanged && completedAt != null && storeitem.completed_at == null || completedAt != null && storeitem.completed_at != null && !_npmMoment.default.utc(completedAt).isSame(_npmMoment.default.utc(storeitem.completed_at));
            var allocatedHoursChanged = Object.keys(editedStoreitem.allocated_hours || {}).length != 0;

            var hasChange = completedAtChanged || statusChanged || allocatedHoursChanged;

            if (editedStoreitem.schedule_hours != null && editedStoreitem.schedule_hours != storeitem.schedule_hours) hasChange |= true;
            if (editedStoreitem.schedule_units != null && editedStoreitem.schedule_units != storeitem.schedule_units) hasChange |= true;

            if (item.are_hours_allocated && numDays > 1) hasChange &= allHoursAllocated;

            // notify modalService of any changes
            showSave(hasChange);

            // handle interaction with status flipper
            var onStatusClick = function onStatusClick(_) {
                if (status === 'UPCOMING') {
                    var service = new _notificationService.default();
                    service.warning('Items can\'t be completed before their start date');
                    // some fun ;)
                    // if(numClicks < 2) {
                    //     service.warning('Items can\'t be completed before their start date')
                    // } else if(numClicks == 2) {
                    //     service.warning('LISTEN HERE...')
                    // } else if(numClicks == 3) {
                    //     service.error('Items CAN\'T be completed before their start date')
                    // } else if(numClicks == 4) {
                    //     service.error('Do it one more time...')
                    // } else if(numClicks == 5) {
                    //     service.success()
                    //     setTimeout(() => {
                    //         window.location.href = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ'
                    //     }, 2000)
                    // }
                    // setNumClicks(numClicks + 1)
                } else {
                    setStatusFlipping(true);
                }
            };

            var onStatusDidFlip = function onStatusDidFlip(_) {
                setStatusFlipping(false);
                _store.store.dispatch({
                    type: _types.TODO_EDIT_STOREITEM,
                    payload: {
                        edit: _extends({}, editedStoreitem, {
                            completedAt: statusIsDone(toStatus) ? _npmMoment.default.utc().format() : null,
                            status: toStatus,
                            completed_by: {
                                user: currentUser.id
                            }
                        })
                    }
                });
            };

            var ArrowButton = function ArrowButton(_ref) {
                var left = _ref.left,
                    enabled = _ref.enabled,
                    onClick = _ref.onClick;

                var classes = 'arrow-button';
                if (!enabled) return null;
                if (enabled) classes += ' enabled';
                var style = {};
                if (left) style.left = 0;else style.right = 0;
                return _react.default.createElement(
                    'div',
                    { className: classes, style: style, onClick: enabled ? onClick : null, onMouseDown: function onMouseDown(e) {
                            return e.preventDefault();
                        } },
                    _react.default.createElement('span', { className: left ? 'icon-arrow-left' : 'icon-arrow-right' })
                );
            };

            var correctionOptions = [function (_) {
                // spread evenly
                if (allHoursAllocated || numRemaining < 0 && !canBeNegative) return null;
                var amount = numRemaining / numDays;
                var absAmount = Math.abs(amount);
                var numberText = editUnits ? (0, _formatHelpers.numberFormat)(absAmount / laborStandard, { decimals: 0 }) : (0, _formatHelpers.numberFormat)(absAmount, { decimals: 2 });
                return {
                    text: amount > 0 ? 'Add ' + numberText + ' to all days' : 'Remove ' + numberText + ' from all days',
                    key: 'addAll',
                    onClick: function onClick(_) {
                        var newHours = {};
                        for (var i = 0; i < numDays; i++) {
                            var key = dateStr(i);
                            newHours[key] = (allocatedHours[key] || 0) + amount;
                        }
                        setAllocatedHours(newHours);
                    }
                };
            }(), function (_) {
                // scale all days
                if (allHoursAllocated || numAllocated == 0) return null;
                var factor = numRequired / numAllocated;
                return {
                    text: 'Scale all days ' + (0, _formatHelpers.percentFormat)(factor - 1, { decimals: 0 }) + ' ',
                    key: 'scaleAll',
                    onClick: function onClick(_) {
                        var newHours = Object.keys(allocatedHours).reduce(function (o, key) {
                            o[key] = allocatedHours[key] * factor;
                            return o;
                        }, {});
                        setAllocatedHours(newHours);
                    }
                };
            }(), function (_) {
                // all on last day
                var lastDayI = numDays - 1;
                if (allHoursAllocated || (allocatedHours[lastDayI] || 0) + numRemaining < 0 && !canBeNegative) return null;
                var absRemaining = Math.abs(numRemaining);
                var numberText = editUnits ? (0, _formatHelpers.numberFormat)(absRemaining / laborStandard, { decimals: 0 }) : (0, _formatHelpers.numberFormat)(absRemaining, { decimals: 2 });
                return {
                    text: numRemaining > 0 ? 'Add ' + numberText + ' to last day' : 'Remove ' + numberText + ' from last day',
                    key: 'allLast',
                    onClick: function onClick(_) {
                        var key = dateStr(lastDayI);
                        setAllocatedHours(_extends({}, allocatedHours, _defineProperty({}, key, (allocatedHours[key] || 0) + numRemaining)));
                    }
                };
            }()].filter(function (x) {
                return x != null;
            });

            /*  const ItemHoverOutline = () => {
                
                  // get day indices relative to current week
                 const weekStart = lastDate.clone().add(-6, 'days')
                 const dotw = date => moment(date).diff(weekStart, 'days')
                 const startDotw = dotw(startDate)
                 const dueDotw = dotw(dueDate)
                  const borderWidth = '2px'
                  const limit = x => Math.min(Math.max(x, 0), 6)
                  return (
                     <div className="hover-outline" style={{
                         left: `${limit(startDotw) / 7 * 100}%`,
                         width: `${(limit(dueDotw) - limit(startDotw) + 1) / 7 * 100}%`,
                         height: '50%',
                         position: 'absolute',
                         borderStyle: 'solid',
                         borderColor: '#2490b2',
                         // top borders always show
                         borderTopWidth: borderWidth,
                         borderBottomWidth: borderWidth,
                         // edge borders only show when not extending beyond the week
                         borderLeftWidth: startDotw >= 0 ? borderWidth : 0,
                         borderRightWidth: dueDotw <= 6 ? borderWidth : 0,
                         pointerEvents: 'none',
                         bottom: '0%',
                     }}/>
                 )
             } */
            var AllocatedHoursWidget = function AllocatedHoursWidget(_) {
                return _react.default.createElement(
                    _react.Fragment,
                    null,
                    _react.default.createElement(
                        'div',
                        { className: 'day-hours' },
                        _react.default.createElement(
                            'div',
                            { className: 'day-area' },
                            _react.default.createElement(
                                'div',
                                { className: 'column f1 breathe-r-10' },
                                _react.default.createElement('div', { className: 'column f2' }),
                                _react.default.createElement(
                                    'div',
                                    { className: 'column f2' },
                                    editUnits ? _react.default.createElement(
                                        'div',
                                        { className: 'flex f1 text-right w-full', style: {
                                                justifyContent: 'flex-end',
                                                alignItems: 'center'
                                            } },
                                        unitOfMeasure
                                    ) : null,
                                    _react.default.createElement(
                                        'div',
                                        { className: 'flex f1 text-right w-full', style: {
                                                justifyContent: 'flex-end',
                                                alignItems: 'center'
                                            } },
                                        'Hours'
                                    )
                                )
                            ),
                            _react.default.createElement(
                                'div',
                                { className: 'flex f6 border-l border-r', style: {
                                        position: 'relative'
                                    } },
                                [].concat(_toConsumableArray(Array(7).keys())).map(function (i) {
                                    var date = lastDate.clone().add(-6 + i, 'days');
                                    var dayI = date.diff(startDate, 'days');
                                    var key = dateStr(dayI);
                                    var onHoursChange = function onHoursChange(dayHours) {
                                        setAllocatedHours(_extends({}, editedStoreitem.allocated_hours || {}, _defineProperty({}, key, dayHours)));
                                    };

                                    var onTabOut = function onTabOut(e) {
                                        var delta = e.shiftKey ? -1 : 1;
                                        var major = i == 0 && e.shiftKey && canGoEarlier || i == 6 && !e.shiftKey && canGoLater;
                                        if (major) setOffset(offset + 7 * delta);
                                        setAutoSelectI(dayI + delta);
                                        e.target.blur();
                                        e.preventDefault();
                                    };

                                    return _react.default.createElement(DayColumn, {
                                        key: dayI,
                                        index: i,
                                        date: date,
                                        hours: allocatedHours[key],
                                        laborStandard: laborStandard,
                                        enabled: dayI >= 0 && dayI < numDays,
                                        withBorder: i > 0,
                                        onHoursChange: onHoursChange,
                                        onTabOut: onTabOut,
                                        shouldSelect: dayI == autoSelectI,
                                        allowNegative: canBeNegative,
                                        editUnits: editUnits
                                    });
                                }),
                                _react.default.createElement(ArrowButton, { left: true, enabled: canGoEarlier, onClick: function onClick(_) {
                                        setOffset(offset - 7);
                                    } }),
                                _react.default.createElement(ArrowButton, { enabled: canGoLater, onClick: function onClick(_) {
                                        setOffset(offset + 7);
                                    } })
                            ),
                            _react.default.createElement(
                                'div',
                                { className: 'flex f2' },
                                _react.default.createElement(
                                    'div',
                                    { className: 'column text-center' },
                                    _react.default.createElement(
                                        'div',
                                        { className: 'column f2' },
                                        'Total'
                                    ),
                                    _react.default.createElement(
                                        'div',
                                        { className: 'column f2' },
                                        editUnits ? _react.default.createElement(
                                            _react.Fragment,
                                            null,
                                            _react.default.createElement(ValueEdit, { classes: 'flex f1 text-center no-style-input text-editable', value: numAllocated / laborStandard, formatFunc: function formatFunc(x) {
                                                    return (0, _formatHelpers.numberFormat)(x || 0, { decimals: 0 });
                                                }, onValueChange: function onValueChange(x) {
                                                    var currentTotal = numAllocated / laborStandard;
                                                    var newTotal = x * laborStandard;
                                                    if (currentTotal) {
                                                        var scalar = x / currentTotal;
                                                        setAllocatedHours(Object.keys(allocatedHours).reduce(function (agg, key) {
                                                            agg[key] = allocatedHours[key] * scalar;
                                                            return agg;
                                                        }, {}));
                                                    } else {
                                                        setAllocatedHours([].concat(_toConsumableArray(Array(numDays).keys())).reduce(function (agg, i) {
                                                            agg[dateStr(i)] = newTotal / numDays;
                                                            return agg;
                                                        }, {}));
                                                    }
                                                }, allowNegative: canBeNegative }),
                                            _react.default.createElement(
                                                'div',
                                                { className: 'flex f1 items-center' },
                                                (0, _formatHelpers.numberFormat)(numAllocated, { decimals: 2 })
                                            )
                                        ) : _react.default.createElement(
                                            _react.Fragment,
                                            null,
                                            _react.default.createElement(ValueEdit, { classes: 'flex f1 text-center no-style-input text-editable', value: numAllocated, formatFunc: function formatFunc(x) {
                                                    return (0, _formatHelpers.numberFormat)(x || 0, { decimals: 2 });
                                                }, onValueChange: function onValueChange(x) {
                                                    var currentTotal = numAllocated;
                                                    var newTotal = x;
                                                    if (currentTotal) {
                                                        var scalar = x / currentTotal;
                                                        setAllocatedHours(Object.keys(allocatedHours).reduce(function (agg, key) {
                                                            agg[key] = allocatedHours[key] * scalar;
                                                            return agg;
                                                        }, {}));
                                                    } else {
                                                        setAllocatedHours([].concat(_toConsumableArray(Array(numDays).keys())).reduce(function (agg, i) {
                                                            agg[dateStr(i)] = newTotal / numDays;
                                                            return agg;
                                                        }, {}));
                                                    }
                                                }, allowNegative: canBeNegative })
                                        )
                                    )
                                ),
                                _react.default.createElement(
                                    'div',
                                    { className: 'column text-center' },
                                    _react.default.createElement(
                                        'div',
                                        { className: 'column f2' },
                                        'Prelim'
                                    ),
                                    _react.default.createElement(
                                        'div',
                                        { className: 'column f2' },
                                        editUnits ? _react.default.createElement(
                                            'div',
                                            { className: 'flex f1 items-center' },
                                            (0, _formatHelpers.numberFormat)(storeitem.prelim_units, { decimals: 0 })
                                        ) : null,
                                        _react.default.createElement(
                                            'div',
                                            { className: 'flex f1 items-center' },
                                            (0, _formatHelpers.numberFormat)(storeitem.prelim_hours, { decimals: 2 })
                                        )
                                    )
                                ),
                                _react.default.createElement(
                                    'div',
                                    { className: 'column text-center' },
                                    _react.default.createElement(
                                        'div',
                                        { className: 'column f2' },
                                        'Final'
                                    ),
                                    _react.default.createElement(
                                        'div',
                                        { className: 'column f2' },
                                        editUnits ? _react.default.createElement(
                                            'div',
                                            { className: 'flex f1 items-center' },
                                            (0, _formatHelpers.numberFormat)(storeitem.final_units, { decimals: 0 })
                                        ) : null,
                                        _react.default.createElement(
                                            'div',
                                            { className: 'flex f1 items-center' },
                                            (0, _formatHelpers.numberFormat)(storeitem.final_hours, { decimals: 2 })
                                        )
                                    )
                                )
                            )
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'flex breathe-t-10' },
                            _react.default.createElement(
                                'div',
                                { className: 'column f1 breathe-r-10 ', style: {
                                        justifyContent: 'flex-end',
                                        alignItems: 'flex-end'
                                    } },
                                numAllocated != 0 ? _react.default.createElement(
                                    'a',
                                    { onClick: function onClick() {
                                            setAllocatedHours(Object.keys(allocatedHours).reduce(function (o, key) {
                                                o[key] = 0;
                                                return o;
                                            }, {}));
                                        } },
                                    'Clear all'
                                ) : null
                            ),
                            _react.default.createElement('div', { className: 'flex f6' }),
                            _react.default.createElement(
                                'div',
                                { className: 'column flex f2', style: {
                                        alignItems: 'flex-start',
                                        flexDirection: 'column'
                                    } },
                                allHoursAllocated ? null : _react.default.createElement(
                                    'span',
                                    { className: 'save-error' },
                                    (numRemaining > 0 ? 'Under' : 'Over') + ' ' + (editUnits ? (0, _formatHelpers.numberFormat)(Math.abs(numRemaining / laborStandard), { decimals: 0 }) : (0, _formatHelpers.numberFormat)(Math.abs(numRemaining), { decimals: 2 })) + ' ' + (editUnits ? 'units' : 'hours')
                                ),
                                correctionOptions.map(function (opt, i) {
                                    return _react.default.createElement(
                                        'a',
                                        { key: i, className: !opt.onClick ? 'disabled' : null, onClick: opt.onClick },
                                        opt.text
                                    );
                                })
                            )
                        )
                    )
                );
            };

            var LinkToComponent = function LinkToComponent(_) {
                var link = item.link_to;
                if (link == null) return null;

                var text = void 0;
                var onClick = void 0;

                var baseQueryParams = {
                    date: currentWeekEndDate.format('YYYY-MM-DD'),
                    storeId: storeitem.store
                };

                var _link$split = link.split('_'),
                    _link$split2 = _slicedToArray(_link$split, 2),
                    page = _link$split2[0],
                    date = _link$split2[1];

                if (date != null && date.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)) {
                    baseQueryParams.date = date;
                }

                var dateMmt = (0, _npmMoment.default)(baseQueryParams.date);

                if (page === 'schedule') {
                    text = date ? 'Edit ' + dateMmt.format('MM/D') + ' Schedule' : 'Edit Schedule';
                    onClick = function onClick(_) {
                        transitionTo('schedule', {
                            queryParams: baseQueryParams
                        });
                    };
                } else if (page === 'store-info') {
                    text = date ? 'Edit ' + dateMmt.format('MM/D') + ' Store Info' : 'Edit Store Info';
                    onClick = function onClick(_) {
                        transitionTo('stores.edit', {
                            queryParams: baseQueryParams
                        });
                    };
                } else if (page === 'employees') {
                    text = date ? 'Edit ' + dateMmt.format('MM/D') + ' Employees' : 'Edit Employees';
                    onClick = function onClick(_) {
                        transitionTo('employees', {
                            queryParams: baseQueryParams
                        });
                    };
                } else if (page === 'payroll-worksheet') {
                    text = date ? 'View ' + dateMmt.format('MM/D') + ' Payroll Worksheet' : 'View Payroll Worksheet';
                    onClick = function onClick(_) {
                        transitionTo('new-payroll-worksheet', {
                            queryParams: _extends({}, baseQueryParams, {
                                view: 'detail'
                            })
                        });
                    };
                }
                return _react.default.createElement(
                    'a',
                    { onClick: onClick },
                    text
                );
            };

            var CompletedByLabel = function CompletedByLabel() {
                if (item && storeitem && completedAt) {
                    var completedBy = _extends({}, storeitem.completed_by, editedStoreitem.completed_by);
                    var user = completedBy.user,
                        first_name = completedBy.first_name,
                        last_name = completedBy.last_name;

                    var text = null;
                    if (user != null && first_name != null && last_name != null) {
                        text = last_name + ', ' + first_name;
                    } else if (user == currentUser.id) {
                        text = currentUser.username;
                    }
                    if (text) return _react.default.createElement(
                        'label',
                        null,
                        text
                    );
                }
                return null;
            };

            return _react.default.createElement(
                'div',
                { className: 'storeitem-modal' },
                _react.default.createElement(
                    'div',
                    { className: 'flex-table' },
                    _react.default.createElement(
                        'div',
                        { className: 't-row' },
                        _react.default.createElement(
                            'div',
                            { className: 'flex-table f2' },
                            _react.default.createElement(
                                'div',
                                { className: 'row text-center' },
                                _react.default.createElement(
                                    'div',
                                    { className: 't-column f1' },
                                    _react.default.createElement(
                                        'div',
                                        { className: 'field' },
                                        _react.default.createElement(
                                            'label',
                                            null,
                                            'Start'
                                        ),
                                        _react.default.createElement(
                                            'div',
                                            { className: 'basic-text' },
                                            formatDate(startDate)
                                        )
                                    ),
                                    _react.default.createElement(
                                        'div',
                                        { className: 'field' },
                                        _react.default.createElement(
                                            'label',
                                            null,
                                            'Due'
                                        ),
                                        _react.default.createElement(
                                            'div',
                                            { className: 'basic-text' },
                                            formatDate(dueDate)
                                        )
                                    )
                                ),
                                _react.default.createElement(
                                    'div',
                                    { className: 't-column f1' },
                                    _react.default.createElement(
                                        'div',
                                        { className: 'field' },
                                        _react.default.createElement(
                                            'label',
                                            null,
                                            'Store ',
                                            storeitem.store_code
                                        ),
                                        item.show_in_checklist ? _react.default.createElement(StatusFlipper, { fromStatus: _constants.ChecklistItemStatus[status], toStatus: _constants.ChecklistItemStatus[toStatus], flipping: statusFlipping, onClick: onStatusClick, onFinishFlip: onStatusDidFlip }) : null
                                    )
                                )
                            ),
                            item.show_in_checklist ? _react.default.createElement(
                                'div',
                                { className: 'row' },
                                _react.default.createElement(
                                    'div',
                                    { className: 't-column f1' },
                                    _react.default.createElement(
                                        'div',
                                        { className: 'field text-center' },
                                        _react.default.createElement(_checklistItemRow.FlagsCell, null)
                                    )
                                ),
                                _react.default.createElement(
                                    'div',
                                    { className: 't-column f1' },
                                    _react.default.createElement(
                                        'div',
                                        { className: 'field text-center completed-info' },
                                        completedAt ? _react.default.createElement(
                                            _react.Fragment,
                                            null,
                                            _react.default.createElement(CompletedByLabel, null),
                                            formatDateTime(_npmMoment.default.utc(completedAt).local())
                                        ) : _react.default.createElement(LinkToComponent, null)
                                    )
                                )
                            ) : null
                        ),
                        _react.default.createElement('div', { className: 'flex-table f1' })
                    ),
                    item.are_hours_allocated ? _react.default.createElement(AllocatedHoursWidget, null) : null
                )
            );
        }
        return null;
    };

    exports.default = (0, _emberReactComponents.default)(comp);
});