define('matrix-frontend/pods/components/validated-input/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['validated-input'],
        classNameBindings: ['showErrorClass:has-error', 'isValid:has-success', 'showErrorMessage:has-error'],
        model: null,
        value: null,
        type: 'text',
        valuePath: '',
        placeholder: '',
        validation: null,
        isTyping: false,

        init: function init() {
            this._super.apply(this, arguments);
            var valuePath = this.get('valuePath');
            Ember.defineProperty(this, 'validation', Ember.computed.oneWay('model.validations.attrs.' + valuePath));
            Ember.defineProperty(this, 'value', Ember.computed.alias('model.' + valuePath));
        },


        notValidating: Ember.computed.not('validation.isValidating'),
        didValidate: Ember.computed.oneWay('targetObject.didValidate'),
        hasContent: Ember.computed.notEmpty('value'),
        isValid: Ember.computed.and('hasContent', 'validation.isValid', 'notValidating'),
        isInvalid: Ember.computed.oneWay('validation.isInvalid'),
        showErrorClass: Ember.computed.and('notValidating', 'showMessage', 'hasContent', 'validation'),
        showErrorMessage: Ember.computed('validation.isDirty', 'isInvalid', 'didValidate', function () {
            return (this.get('validation.isDirty') || this.get('didValidate')) && this.get('isInvalid');
        }),

        showWarningMessage: Ember.computed('validation.{isDirty,warnings.[]}', 'isValid', 'didValidate', function () {
            return (this.get('validation.isDirty') || this.get('didValidate')) && this.get('isValid') && !Ember.isEmpty(this.get('validation.warnings'));
        })
    });
});