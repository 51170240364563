define('matrix-frontend/pods/user-profile/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        ajax: Ember.inject.service(),
        flashMessages: Ember.inject.service(),
        stateService: Ember.inject.service(),
        formUpdated: false,

        username: Ember.computed('username', function () {
            return this.get('username');
        }),

        actions: {
            updateForm: function updateForm() {
                return true;
            },

            save: function save() {
                var flashMessages = this.get('flashMessages');
                var user = this.get('model');

                user.save().then(function () {
                    flashMessages.success('Your password has been updated!');
                }, function () {
                    var errors = user.get('errors');
                    errors.forEach(function (error) {
                        flashMessages.alert(error.message);
                    });
                });
            },
            cancel: function cancel() {
                return false;
            },
            selectBusinessUnit: function selectBusinessUnit() {},
            setFormUpdated: function setFormUpdated() {
                if (this.get('model.validations.isValid')) {
                    this.set('formUpdated', true);
                } else {
                    this.set('formUpdated', false);
                }
            }
        }
    });
});