define('matrix-frontend/initializers/router', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    function initialize(application) {
        application.inject('route', 'router', 'router:main');
        application.inject('component', 'router', 'router:main');
    }

    exports.default = {
        name: 'router',
        initialize: initialize
    };
});