define('matrix-frontend/reducers/client', ['exports', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/cleaners', 'npm:lodash'], function (exports, _types, _cleaners, _npmLodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var initialClient = {
        id: null,
        name: null,
        code: null,
        weekStartDay: null,
        activities: [],
        users: {},
        shiftRules: {},
        shiftErrors: {},
        positions: [],
        roles: [],
        budgets: {},
        tasks: {},
        options: null
    };

    var initialState = {
        clients: {}
    };

    var store = function store(state, action) {
        var type = action.type,
            payload = action.payload;

        switch (type) {
            case _types.CLIENT_BASE:
                {
                    var clientCode = payload.clientCode,
                        clientId = payload.clientId,
                        clientWeekStartDay = payload.clientWeekStartDay;

                    var client = _extends({}, initialClient, state.clients[clientCode], {
                        code: clientCode,
                        id: clientId,
                        weekStartDay: clientWeekStartDay

                        // TODO:
                        // - the {[clientCode]: client} is a mistake
                        // - Left in for compatability, but it should be removed
                    });return _extends({}, state, _defineProperty({
                        clientBase: _extends({}, state.clientBase, _defineProperty({}, clientCode, client))
                    }, clientCode, client));
                }
            case _types.CLIENT_INTAKE:
                {
                    var results = payload.results,
                        meta = payload.meta;

                    var _clientCode = meta.channel[0];
                    var _client = _extends({}, state.clientBase[_clientCode], state.clients[_clientCode]);

                    // Inject activities
                    if (meta.namespace.find(function (x) {
                        return x === 'activities';
                    }) && meta.operation === 0) {
                        _client.activities = results.data.map(function (x) {
                            var activity = (0, _cleaners.clean_keys)(x);
                            if (activity.activityPriority <= 0) {
                                activity.activityPriority += 1000;
                            }
                            return activity;
                        });
                        _client.activities = _npmLodash.default.sortBy(_client.activities, ['activityPriority']);
                    }

                    // Inject roles
                    // TODO this is somewhat clumsy with another type
                    // ! called type.CLIENT_ROLE_INTAKE to work in the
                    // ! nonstore user management page
                    if (meta.namespace.find(function (x) {
                        return x === 'role';
                    }) && meta.operation === 0) {
                        _client.roles = results;
                    }

                    // Inject options
                    if (meta.namespace.find(function (x) {
                        return x === 'options';
                    }) && meta.operation === 0) {
                        // key the options for easier fetching
                        _client.options = results.reduce(function (o, opt) {
                            o[opt.code] = opt;
                            return o;
                        }, {});
                    }

                    // Inject positions
                    if (meta.namespace.find(function (x) {
                        return x === 'positions';
                    }) && meta.operation === 0) {
                        _client.positions = results.data.map(function (x) {
                            var position = (0, _cleaners.clean_keys)(x);
                            return position;
                        });
                        _client.positions = _npmLodash.default.sortBy(_client.positions, ['orderIndex']);
                    }

                    // Inject shift-rules
                    if (meta.namespace.find(function (x) {
                        return x === 'shift-rules';
                    }) && meta.operation === 0) {
                        var shiftRules = results.data.map(function (x) {
                            return (0, _cleaners.clean_keys)(x);
                        });
                        _client.shiftRules = shiftRules.reduce(function (o, i) {
                            var rule = i.stateRule ? i.stateRule : 'DEFAULT';
                            o[rule] = i;
                            return o;
                        }, {});
                    }

                    // Inject error-messages
                    if (meta.namespace.find(function (x) {
                        return x === 'error-messages';
                    }) && meta.operation === 0) {
                        _client.shiftErrors = results.data.reduce(function (o, i) {
                            o[i.number] = i.severity;
                            return o;
                        }, {});
                    }

                    // Inject budgets
                    if (meta.namespace.find(function (x) {
                        return x === 'budgets';
                    }) && meta.operation === 0) {
                        _client.budgets = {};
                        results.data.forEach(function (x) {
                            var budget = (0, _cleaners.clean_keys)(x);
                            _client.budgets[budget.id] = budget;
                        });
                    }

                    // Inject tasks
                    if (meta.namespace.find(function (x) {
                        return x === 'tasks';
                    }) && meta.operation === 0) {
                        _client.tasks = {};
                        results.data.forEach(function (x) {
                            var task = (0, _cleaners.clean_keys)(x);
                            _client.tasks[task.id] = task;
                        });
                    }

                    var clients = _extends({}, state.clients);
                    clients[_clientCode] = _client;

                    return _extends({}, state, {
                        clients: clients
                    });
                }

            case _types.CLIENT_USER_INTAKE:
                {
                    var _results = payload.results,
                        _meta = payload.meta;

                    var _clientCode2 = _meta.channel[0];
                    var users = {};

                    if (_results && _results.data) {
                        users = (0, _cleaners.clean_keys)(_results.data).reduce(function (o, i) {
                            o[i.id] = i;
                            return o;
                        }, {});
                    }
                    return _extends({}, state, _defineProperty({}, _clientCode2, _extends({}, state[_clientCode2], {
                        users: users
                    })));
                }

            case _types.CLIENT_ROLE_INTAKE:
                {
                    var _results2 = payload.results,
                        _meta2 = payload.meta;

                    var _clientCode3 = _meta2.channel[0];
                    return _extends({}, state, _defineProperty({}, _clientCode3, _extends({}, state[_clientCode3], {
                        roles: _results2
                    })));
                }

            case _types.CLIENT_NONSTORE_USER_UPDATE:
                {
                    var user = payload.user,
                        _clientCode4 = payload.clientCode;

                    delete payload.user;
                    delete payload.clientCode;

                    user = _extends({}, user, payload);
                    return _extends({}, state, _defineProperty({}, _clientCode4, _extends({}, state[_clientCode4], {
                        users: _extends({}, state[_clientCode4].users, _defineProperty({}, user.id, user))
                    })));
                }

            default:
                {
                    return state || initialState;
                }
        }
    };

    exports.default = store;
});