define('matrix-frontend/utils/colors', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    var HOO_COLORS = exports.HOO_COLORS = {
        EXCEPTION: '#947cb0',
        NEW_DEFAULT: '#587d8b',
        SAME: '#7fb5ca'
    };

    var textColor = exports.textColor = function textColor(hex) {
        hex = hex.trim();
        if (hex.startsWith('#')) {
            hex = hex.slice(1);
        }

        if (hex.length === 3) {
            hex = '' + hex[0].repeat(2) + hex[1].repeat(2) + hex[2].repeat(2);
        }
        if (hex.length !== 6) {
            throw new Error('Invalid HEX color.');
        }
        var r = Number.parseInt(hex.slice(0, 2), 16);
        var g = Number.parseInt(hex.slice(2, 4), 16);
        var b = Number.parseInt(hex.slice(4, 6), 16);
        var compound = r * 0.299 + g * 0.587 + b * 0.114;

        return compound > 154 ? '#000000' : '#FFFFFF';
    };

    var mixComponent = function mixComponent(mix, from, to) {
        return Math.round(Math.sqrt(from * from * (1 - mix) + to * to * mix));
    };

    var hexIntToColor = function hexIntToColor(hex) {
        return '#' + ('000000' + hex.toString(16)).substr(-6);
    };

    var Gradient = exports.Gradient = function () {

        // colorStops: [{value: 0, color: 0xffffff}, ...]
        function Gradient(colorStops) {
            _classCallCheck(this, Gradient);

            this.colorStops = colorStops.sort(function (a, b) {
                return a.value - b.value;
            });
            Object.freeze(this);
        }

        _createClass(Gradient, [{
            key: 'colorFor',
            value: function colorFor(x, options) {
                var _ref = options || {},
                    _ref$format = _ref.format,
                    format = _ref$format === undefined ? '#rgb' : _ref$format,
                    _ref$alpha = _ref.alpha,
                    alpha = _ref$alpha === undefined ? 1.0 : _ref$alpha;

                var hex = 0;
                var prevStop = void 0;
                for (var i = 0; i < this.colorStops.length; i++) {
                    var thisStop = this.colorStops[i];
                    if (x === thisStop.value || this.colorStops.length == 1) {
                        hex = thisStop.color;
                        break;
                    } else if (x < thisStop.value) {
                        if (i == 0) {
                            hex = thisStop.color;
                            break;
                        } else {
                            var mix = Math.min((x - prevStop.value) / (thisStop.value - prevStop.value), 1);
                            var r = mixComponent(mix, prevStop.color >> 16 & 0xff, thisStop.color >> 16 & 0xff);
                            var g = mixComponent(mix, prevStop.color >> 8 & 0xff, thisStop.color >> 8 & 0xff);
                            var b = mixComponent(mix, prevStop.color & 0xff, thisStop.color & 0xff);
                            hex = (r << 16) + (g << 8) + b;
                            break;
                        }
                    } else if (i == this.colorStops.length - 1) {
                        hex = thisStop.color;
                        break;
                    }
                    prevStop = thisStop;
                }
                if (format === '#rgb') {
                    return hexIntToColor(hex);
                } else if (format === 'rgba') {
                    return 'rgba(' + (hex >> 16 & 255) + ',' + (hex >> 8 & 255) + ',' + (hex & 255) + ',' + alpha * 100 + '%)';
                }
            }
        }]);

        return Gradient;
    }();

    var hsv2hsl = exports.hsv2hsl = function hsv2hsl(a, b, c) {
        return [a, b * c / ((a = (2 - b) * c) < 1 ? a : 2 - a), a / 2];
    };

    var steppedColorFunc = exports.steppedColorFunc = function steppedColorFunc(x, step, colors) {
        for (var i = 0; i < colors.length; i++) {
            var iStop = colors.length - 1 - i;
            if (x > step * iStop) return colors[iStop];
        }
    };
});