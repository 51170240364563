define('matrix-frontend/components/react-only/store-summary-row', ['exports', 'matrix-frontend/store', 'matrix-frontend/actions/_types', 'react', 'npm:react-tooltip', 'npm:moment', 'matrix-frontend/utils/format-helpers', 'matrix-frontend/utils/colors'], function (exports, _store, _types, _react, _npmReactTooltip, _npmMoment, _formatHelpers, _colors) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.SummaryRow = exports.FakeRow = undefined;

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var FakeRow = exports.FakeRow = function FakeRow(props) {
        return _react.default.createElement(
            'div',
            { className: 'f-row ' + (props.index == 0 ? 'no-top-border' : null), style: props.style },
            _react.default.createElement(
                'div',
                { className: 'wrapper f2' },
                _react.default.createElement('div', { className: 'column thin-column text f1 fake-text gray-text' })
            ),
            _react.default.createElement(
                'div',
                { className: 'wrapper f9' },
                _react.default.createElement('div', { className: 'column thin-column text f1 fake-text gray-lighten-2-text' }),
                _react.default.createElement('div', { className: 'column thin-column text f1 fake-text gray-lighten-2-text' }),
                _react.default.createElement('div', { className: 'column thin-column text f1 fake-text gray-lighten-2-text' }),
                _react.default.createElement('div', { className: 'column thin-column text f1 fake-text gray-lighten-2-text' }),
                _react.default.createElement('div', { className: 'column thin-column text f1 fake-text gray-lighten-2-text' }),
                _react.default.createElement('div', { className: 'column thin-column text f1 fake-text gray-lighten-2-text' }),
                _react.default.createElement('div', { className: 'column thin-column text f1 fake-text gray-lighten-2-text' }),
                _react.default.createElement('div', { className: 'column thin-column text f1 fake-text gray-lighten-2-text' })
            )
        );
    };

    var truncate = function truncate(text, maxLength) {
        var actual = text.trim();
        if (actual.length <= maxLength) return actual;
        return actual.substring(0, maxLength - 2).trim() + '...';
    };

    var momentFromTime = function momentFromTime(date, x) {
        if (_npmMoment.default.isMoment(x)) {
            return x;
        }
        var parts = x.split(':');
        return (0, _npmMoment.default)(date).set({
            hours: parts[0],
            minutes: parts[1]
        });
    };

    var boundRelativeHourToWeek = function boundRelativeHourToWeek(docw, x) {
        var offset = (docw - 1) * 24;
        return Math.min(Math.max(x + offset, 0), 168) - offset;
    };

    var HoOCell = function (_Component) {
        _inherits(HoOCell, _Component);

        function HoOCell() {
            _classCallCheck(this, HoOCell);

            return _possibleConstructorReturn(this, (HoOCell.__proto__ || Object.getPrototypeOf(HoOCell)).apply(this, arguments));
        }

        _createClass(HoOCell, [{
            key: 'render',
            value: function render() {
                var _this2 = this;

                var _props = this.props,
                    tag = _props.tag,
                    docw = _props.docw,
                    dataType = _props.dataType,
                    view = _props.view,
                    date = _props.date,
                    hoo = _props.hoo,
                    onClick = _props.onClick;


                var content = void 0;
                var classes = ['column thin-column f1 hoo-cell'];
                var tooltipAttrs = {};
                if (view !== 'visual') classes.push('left-border');
                if (hoo) {
                    tooltipAttrs = {
                        'data-tip': tag + '|' + docw,
                        'data-for': 'hooTooltip',
                        'data-place': 'bottom'
                    };
                    if (hoo.closedAllDay) {
                        // content = view === 'numeric' ? 'CLOSED' : null
                    } else {
                        var HoursBlock = function HoursBlock(props) {
                            var open = props.open,
                                close = props.close,
                                color = props.color;

                            var start = boundRelativeHourToWeek(docw, open.diff(date, 'hours', true));
                            var end = boundRelativeHourToWeek(docw, close.diff(date, 'hours', true));
                            return _react.default.createElement('div', { className: 'hours-block', style: {
                                    left: start / 24 * 100 + '%',
                                    width: (end - start) / 24 * 100 + '%',
                                    backgroundColor: color
                                } });
                        };

                        var open = dataType === 'public' ? hoo.publicOpen : hoo.privateOpen;
                        var close = dataType === 'public' ? hoo.publicClose : hoo.privateClose;

                        var duration = close.diff(open, 'hours', true);
                        if ((duration <= 4 || duration >= 16) && view !== 'visual') classes.push('odd-hours');

                        if (view === 'visual') {
                            content = _react.default.createElement(HoursBlock, { open: open, close: close, color: hoo.isException ? _colors.HOO_COLORS.EXCEPTION : hoo.newDefault ? _colors.HOO_COLORS.NEW_DEFAULT : _colors.HOO_COLORS.SAME });
                        } else {
                            if (hoo.isException || hoo.newDefault) classes.push('weight-heavy');
                            content = _react.default.createElement(
                                'div',
                                { className: 'time-container' },
                                _react.default.createElement(
                                    'span',
                                    { className: 'hoo-time' },
                                    open.format('h:mma')
                                ),
                                _react.default.createElement(
                                    'span',
                                    { className: 'hoo-time' },
                                    close.format('h:mma')
                                )
                            );
                        }
                    }
                }

                return _react.default.createElement(
                    'div',
                    _extends({ ref: function ref(_ref) {
                            return _this2.ref = _ref;
                        }, className: classes.join(' '), onMouseEnter: function onMouseEnter(_) {
                            return _npmReactTooltip.default.show(_this2.ref);
                        }, onMouseLeave: function onMouseLeave(_) {
                            return _npmReactTooltip.default.hide(_this2.ref);
                        }, onClick: onClick }, tooltipAttrs),
                    content
                );
            }
        }]);

        return HoOCell;
    }(_react.Component);

    var SummaryRow = exports.SummaryRow = function (_PureComponent) {
        _inherits(SummaryRow, _PureComponent);

        function SummaryRow() {
            _classCallCheck(this, SummaryRow);

            return _possibleConstructorReturn(this, (SummaryRow.__proto__ || Object.getPrototypeOf(SummaryRow)).apply(this, arguments));
        }

        _createClass(SummaryRow, [{
            key: 'render',
            value: function render() {
                var _this4 = this;

                var _props$data = this.props.data,
                    selectBusinessUnit = _props$data.selectBusinessUnit,
                    openHoOModal = _props$data.openHoOModal,
                    clientCode = _props$data.clientCode,
                    weekEndDate = _props$data.weekEndDate,
                    dataType = _props$data.dataType,
                    view = _props$data.view,
                    nodes = _props$data.nodes,
                    items = _props$data.items,
                    minDisplayValue = _props$data.minDisplayValue,
                    maxDisplayValue = _props$data.maxDisplayValue;


                var cleanWeekEndDate = weekEndDate.clone().set({ hours: 0, minutes: 0, seconds: 0, milliseoncds: 0 });
                var thisWeek = cleanWeekEndDate.format('YYYY-MM-DD');
                var item = items[this.props.index];
                if (!item) return null;
                var node = item.node,
                    rollup = item.rollup;


                var handleClickName = function handleClickName() {
                    selectBusinessUnit(node.id, node.type);
                };

                var handleClickDay = function handleClickDay(docw) {
                    openHoOModal(node.tag, docw);
                };

                var nameText = node.code.toUpperCase() + ' ' + _formatHelpers.default.fixAllCaps(node.name);

                var rowClassName = 'f-row';
                var auxTopBorder = '';
                if (view === 'visual') {
                    if (this.props.inGroupIndex != 0) {
                        rowClassName += ' no-top-border';
                        if (this.props.index != 0) auxTopBorder = ' top-border';
                    }
                } else {
                    if (this.props.inGroupIndex == null && this.props.index == 0) rowClassName += ' no-top-border';
                }

                return _react.default.createElement(
                    'div',
                    { className: rowClassName, style: this.props.style },
                    _react.default.createElement(
                        'div',
                        { className: 'wrapper f2 pointer', onClick: function onClick(_) {
                                return handleClickName();
                            } },
                        _react.default.createElement(
                            'div',
                            { ref: function ref(_ref2) {
                                    return _this4.ref = _ref2;
                                }, className: 'column thin-column text f6 no-left' + auxTopBorder, onMouseEnter: function onMouseEnter(_) {
                                    return _npmReactTooltip.default.show(_this4.ref);
                                }, onMouseLeave: function onMouseLeave(_) {
                                    return _npmReactTooltip.default.hide(_this4.ref);
                                }, 'data-for': 'storeDetailsTooltip', 'data-place': 'bottom', 'data-tip': node.tag },
                            _react.default.createElement(
                                'span',
                                { className: 'ellipsis-text' },
                                nameText
                            )
                        )
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'wrapper f9' },
                        [].concat(_toConsumableArray(Array(7))).map(function (_, i) {
                            return rollup !== undefined ? _react.default.createElement(HoOCell, {
                                key: i,
                                tag: node.tag,
                                docw: i + 1,
                                date: cleanWeekEndDate.clone().add(-6 + i, 'days'),
                                hoo: rollup ? rollup.hoo[i + 1] : null,
                                dataType: dataType,
                                view: view,
                                onClick: function onClick(_) {
                                    return handleClickDay(i + 1);
                                }
                            }) : _react.default.createElement('div', { key: i, className: 'column thin-column text f1 fake-text gray-lighten-2-text' });
                        }),
                        rollup !== undefined ? _react.default.createElement(
                            'div',
                            { className: 'column thin-column f1 left-border' + auxTopBorder },
                            rollup !== null ? (0, _formatHelpers.numberFormat)(dataType === 'public' ? rollup.publicWeeklyHours : rollup.privateWeeklyHours, { decimals: 2 }) : null
                        ) : _react.default.createElement('div', { className: 'column thin-column text f1 fake-text gray-lighten-2-text' + auxTopBorder })
                    )
                );
            }
        }]);

        return SummaryRow;
    }(_react.PureComponent);
});