define('matrix-frontend/utils/hooks', ['exports', 'react'], function (exports, _react) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.useResize = exports.useEventListener = undefined;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var useEventListener = exports.useEventListener = function useEventListener(eventName, handler) {
        var element = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : window;

        // Create a ref that stores handler
        var savedHandler = (0, _react.useRef)();

        // Update ref.current value if handler changes.
        // This allows our effect below to always get latest handler ...
        // ... without us needing to pass it in effect deps array ...
        // ... and potentially cause effect to re-run every render.
        (0, _react.useEffect)(function () {
            savedHandler.current = handler;
        }, [handler]);

        (0, _react.useEffect)(function () {
            // Make sure element supports addEventListener
            // On 
            var isSupported = element && element.addEventListener;
            if (!isSupported) return;

            // Create event listener that calls handler function stored in ref
            var eventListener = function eventListener(event) {
                return savedHandler.current(event);
            };

            // Add event listener
            element.addEventListener(eventName, eventListener);

            // Remove event listener on cleanup
            return function () {
                element.removeEventListener(eventName, eventListener);
            };
        }, [eventName, element] // Re-run if eventName or element changes
        );
    };

    var useResize = exports.useResize = function useResize(ref) {
        var _useState = (0, _react.useState)(0),
            _useState2 = _slicedToArray(_useState, 2),
            width = _useState2[0],
            setWidth = _useState2[1];

        var _useState3 = (0, _react.useState)(0),
            _useState4 = _slicedToArray(_useState3, 2),
            height = _useState4[0],
            setHeight = _useState4[1];

        (0, _react.useEffect)(function () {
            var handleResize = function handleResize() {
                setWidth(ref.current.clientWidth);
                setHeight(ref.current.clientHeight);
            };
            if (ref.current) {
                handleResize();
            }
            window.addEventListener('resize', handleResize);
            return function () {
                window.removeEventListener('resize', handleResize);
            };
        }, [ref]);

        return { width: width, height: height };
    };
});