define('matrix-frontend/utils/keyboard-shortcuts', ['exports', 'npm:mousetrap'], function (exports, _npmMousetrap) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.bindKeyboardShortcuts = bindKeyboardShortcuts;
    exports.unbindKeyboardShortcuts = unbindKeyboardShortcuts;
    function bindKeyboardShortcuts(context) {
        var shortcuts = context.get('keyboardShortcuts');
        if (Ember.typeOf(shortcuts) !== 'object') {
            return;
        }

        context._mousetraps = [];

        Object.keys(shortcuts).forEach(function (shortcut) {
            var actionObject = shortcuts[shortcut];
            var mousetrap = void 0;
            var preventDefault = true;

            function invokeAction(action, eventType) {
                var type = Ember.typeOf(action);
                var callback = void 0;
                if (type === 'string') {
                    callback = function callback() {
                        context.send(action);
                        return preventDefault !== true;
                    };
                } else if (type === 'function') {
                    callback = action.bind(context);
                } else {
                    throw new Error('Invalid value for keyboard shortcut: ' + action);
                }
                mousetrap.bind(shortcut, callback, eventType);
            }

            if (Ember.typeOf(actionObject) === 'object') {
                if (actionObject.global === false) {
                    mousetrap = new _npmMousetrap.default(document);
                } else if (actionObject.scoped) {
                    if (Ember.typeOf(actionObject.scoped) === 'boolean') {
                        mousetrap = new _npmMousetrap.default(context.get('element'));
                    } else if (Ember.typeOf(actionObject.scoped) === 'string') {
                        mousetrap = new _npmMousetrap.default(document.querySelector(actionObject.scoped));
                    }
                } else if (actionObject.targetElement) {
                    mousetrap = new _npmMousetrap.default(actionObject.targetElement);
                } else {
                    mousetrap = new _npmMousetrap.default(document.body);
                }

                if (actionObject.preventDefault === false) {
                    preventDefault = false;
                }

                // AMH - Adding ability to loop over array of eventType
                if (Ember.typeOf(actionObject.eventType) === 'array') {
                    var _iteratorNormalCompletion = true;
                    var _didIteratorError = false;
                    var _iteratorError = undefined;

                    try {
                        for (var _iterator = actionObject.eventType[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                            var et = _step.value;

                            invokeAction(actionObject.action, et);
                        }
                    } catch (err) {
                        _didIteratorError = true;
                        _iteratorError = err;
                    } finally {
                        try {
                            if (!_iteratorNormalCompletion && _iterator.return) {
                                _iterator.return();
                            }
                        } finally {
                            if (_didIteratorError) {
                                throw _iteratorError;
                            }
                        }
                    }
                } else {
                    invokeAction(actionObject.action, actionObject.eventType);
                }
            } else {
                mousetrap = new _npmMousetrap.default(document.body);
                invokeAction(actionObject);
            }

            context._mousetraps.push(mousetrap);
        });
    }

    function unbindKeyboardShortcuts(context) {
        var _removeEvent = function _removeEvent(object, type, callback) {
            if (object.removeEventListener) {
                object.removeEventListener(type, callback, false);
                return;
            }
            object.detachEvent('on' + type, callback);
        };
        context._mousetraps.forEach(function (mousetrap) {
            // manually unbind JS event
            _removeEvent(mousetrap.target, 'keypress', mousetrap._handleKeyEvent);
            _removeEvent(mousetrap.target, 'keydown', mousetrap._handleKeyEvent);
            _removeEvent(mousetrap.target, 'keyup', mousetrap._handleKeyEvent);
            mousetrap.reset();
        });
        context._mousetraps = [];
    }
});