define('matrix-frontend/pods/reports/show/route', ['exports', 'npm:moment', 'matrix-frontend/routes/base-route', 'matrix-frontend/utils/date-helper'], function (exports, _npmMoment, _baseRoute, _dateHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseRoute.default.extend({
        queryParams: {
            hierarchyNodeId: {
                refreshModel: true
            }
        },

        model: function model(params, transition) {
            var self = this;
            return this._super(params, transition).then(function () {
                var businessUnit = void 0;
                var reportType = transition.params['reports.show'].report_name;
                var retVal = { reportType: reportType };
                var date = transition.queryParams.date ? transition.queryParams.date : (0, _dateHelper.getClientWeekEndDate)().format('YYYY-MM-DD');

                if (transition.queryParams.hierarchyNodeId) {
                    businessUnit = {
                        type: 'hierarchyNode',
                        id: transition.queryParams.hierarchyNodeId
                    };
                } else if (transition.queryParams.storeId) {
                    businessUnit = {
                        type: 'store',
                        id: transition.queryParams.storeId
                    };
                }

                var url = '/workforce/api/reports/' + reportType + '?' + businessUnit.type + '=' + businessUnit.id + '&date=' + date;

                retVal[reportType + 'Data'] = Ember.$.ajax(url, {
                    dataType: 'json',
                    headers: {
                        'Access-Token': self.session.get('token')
                    }
                });

                return Ember.RSVP.hash(retVal);
            });
        }
    });
});