define('matrix-frontend/utils/object-cp', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        objectCp: function objectCp(context, propertyName, func) {
            var object = context.get(propertyName);

            var args = [];

            Object.keys(object).map(function (item) {
                args.push(propertyName + '.' + item);
            });

            return Ember.computed.apply(undefined, args.concat([func]));
        }
    };
});