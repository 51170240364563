define('matrix-frontend/actions/cube', ['exports', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/backoff', 'matrix-frontend/utils/interactions', 'matrix-frontend/actions/client', 'matrix-frontend/actions/store-summary', 'matrix-frontend/utils/state', 'npm:moment'], function (exports, _types, _backoff, _interactions, _client, _storeSummary, _state, _npmMoment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.cubeReload = exports.initialClientLoad = exports.storeReload = exports.rolescopeManagementReload = exports.userManagementReload = undefined;
    var userManagementReload = exports.userManagementReload = function userManagementReload(store) {
        (0, _interactions.setStore)(store);
        console.debug('~~userManagementReload~~');

        (0, _backoff.generalBackoff)(function () {
            return store.getState().cube.clientCode && store.getState().user.currentUser;
        }, function () {
            // Retrieve Users
            (0, _client.nonstoreUserReload)(store);

            var clients = store.getState().client.clients;
            var clientCode = store.getState().cube.clientCode;

            // Retrieve Roles

            if (!clients[clientCode]) {
                (0, _interactions.send)({
                    header: 'do',
                    body: {
                        service: 'user',
                        namespace: ['role'],
                        operation: 0,
                        include_channel: 3,
                        channel_override: [store.getState().cube.clientCode],
                        broadcast: false,
                        response_type: 'CLIENT_ROLE_INTAKE'
                    }
                }, 'retrieve client roles for: ' + store.getState().cube.clientCode, false, null, null, null, true);
            }

            // Retrieve Nodes
            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'client',
                    namespace: 'hierarchy',
                    operation: 0,
                    include_channel: 3,
                    channel_override: [store.getState().cube.clientCode],
                    broadcast: false,
                    response_type: 'NODE_MULTI_INTAKE',
                    params: {
                        count: 0
                    }
                }
            }, 'retrieve hierarchy details: ' + store.getState().cube.clientCode, false, null, null, null, true);
        });
    };

    var rolescopeManagementReload = exports.rolescopeManagementReload = function rolescopeManagementReload(store) {
        (0, _interactions.setStore)(store);
        console.debug('~~rolescopeManagementReload~~');

        (0, _backoff.generalBackoff)(function () {
            return store.getState().cube.clientCode && store.getState().user.currentUser;
        }, function () {

            // Retrieve Roles
            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'user',
                    namespace: ['role'],
                    operation: 0,
                    include_channel: 3,
                    channel_override: [store.getState().cube.clientCode],
                    broadcast: false,
                    response_type: 'CLIENT_ROLE_INTAKE'
                }
            }, 'retrieve client roles for: ' + store.getState().cube.clientCode, false, null, null, null, true);

            // Retreive Scopes
            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'user',
                    namespace: ['scopes'],
                    operation: 0,
                    include_channel: 0,
                    broadcast: false,
                    response_type: 'NOCLIENT_SCOPE_INTAKE'
                }
            }, 'retrieve scopes', false, null, null, null, true);
        });
    };

    var storeReload = exports.storeReload = function storeReload(store) {
        console.debug('+++ storeReload +++');
        (0, _interactions.setStore)(store);

        (0, _storeSummary.storeSummaryReload)(store);

        (0, _backoff.generalBackoff)(function () {
            return store.getState().cube.node && store.getState().user.currentUser && store.getState().cube.weekEndDate;
            // && store.getState().websocket.status === 2;
        }, function () {
            var currentNode = store.getState().cube.node;
            if (currentNode.nodeType === 'store') {
                var currentDate = (0, _npmMoment.default)(store.getState().cube.weekEndDate);
                var endDate = (0, _npmMoment.default)(currentDate).add(7, 'weeks');
                while (currentDate.isSameOrBefore(endDate, 'day')) {
                    currentDate = currentDate.add(7, 'days');
                    store.dispatch({
                        type: _types.NODE_SETUP_HOURS,
                        payload: {
                            storeTag: currentNode.clientCode + '.' + currentNode.tag,
                            weekEndDate: currentDate.format('YYYY-MM-DD')
                        }
                    });
                    (0, _interactions.send)({
                        header: 'do',
                        body: {
                            service: 'store',
                            namespace: 'hours',
                            operation: 0,
                            include_channel: 2,
                            broadcast: false,
                            response_type: 'NODE_INTAKE',
                            extra: [currentDate.format('YYYY-MM-DD')]
                        }
                    }, 'retrieve hours of operation: ' + store.getState().cube.node.clientCode + '.' + store.getState().cube.node.tag + '/' + currentDate.format('YYYY-MM-DD'));
                }
            }
        });
    };

    var initialClientLoad = exports.initialClientLoad = function initialClientLoad(store) {
        console.debug('+++ initialClientLoad +++');
        // setStore(store);

        (0, _backoff.generalBackoff)(function () {
            return true;
        },
        // () => {
        //     return store.getState().websocket.status === 2;
        // },
        function () {
            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'client',
                    namespace: 'options',
                    operation: 0,
                    include_channel: 1,
                    broadcast: false,
                    response_type: 'CLIENT_INTAKE'
                }
            }, 'retrieve client options for: ' + store.getState().cube.clientCode);

            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'client',
                    namespace: 'activities',
                    operation: 0,
                    include_channel: 1,
                    broadcast: false,
                    params: { count: 0 },
                    response_type: 'CLIENT_INTAKE'
                }
            }, 'retrieve client activities');

            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'client',
                    namespace: 'positions',
                    operation: 0,
                    include_channel: 1,
                    broadcast: false,
                    params: { count: 0 },
                    response_type: 'CLIENT_INTAKE'
                }
            }, 'retrieve client positions');

            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'client',
                    namespace: ['validation', 'shift-rules'],
                    operation: 0,
                    include_channel: 1,
                    broadcast: false,
                    response_type: ['CLIENT_INTAKE']
                }
            }, 'retrieve shift rules');

            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'client',
                    namespace: ['validation', 'error-messages'],
                    operation: 0,
                    include_channel: 1,
                    broadcast: false,
                    response_type: 'CLIENT_INTAKE'
                }
            }, 'retrieve shift error messages');

            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'client',
                    namespace: ['out-of-office-activities'],
                    operation: 0,
                    include_channel: 0,
                    broadcast: false,
                    response_type: 'GENERAL_INTAKE_OUT_OF_OFFICE_ACTIVITIES'
                }
            }, 'retrieve out of office activites');

            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'user',
                    namespace: 'role',
                    operation: 0,
                    include_channel: 1,
                    broadcast: false,
                    response_type: 'CLIENT_INTAKE'
                    // response_type: 'CLIENT_ROLE_INTAKE',
                }
            }, 'retrieve client roles for: ' + store.getState().cube.clientCode);

            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'client',
                    namespace: 'budgets',
                    operation: 0,
                    include_channel: 1,
                    broadcast: false,
                    params: { count: 0 },
                    response_type: 'CLIENT_INTAKE'
                }
            }, 'retrieve client budgets');

            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'client',
                    namespace: 'tasks',
                    operation: 0,
                    include_channel: 1,
                    broadcast: false,
                    params: { count: 0 },
                    response_type: 'CLIENT_INTAKE'
                }
            }, 'retrieve client tasks');

            // if (isFieldAdmin()) {
            //     send(
            //         {
            //             header: 'do',
            //             body: {
            //                 service: 'user',
            //                 namespace: ['role'],
            //                 operation: 0,
            //                 include_channel: 0,
            //                 broadcast: false,
            //                 response_type: 'GENERAL_INTAKE_ROLES',
            //             },
            //         },
            //         'retrieve roles',
            //     )
            // }
            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'store',
                    namespace: 'schedule',
                    operation: 0,
                    include_channel: 2,
                    broadcast: false,
                    response_type: 'SE_INTAKE',
                    extra: [store.getState().cube.weekEndDate.format('YYYY-MM-DD')],
                    node_type: 'store'
                }
            }, 'retrieve schedule details');
        }, 'initialClientLoad');
    };

    var cubeReload = exports.cubeReload = function cubeReload(store) {
        console.debug('+++ cubeReload +++');
        (0, _interactions.setStore)(store);

        // socketBackoff(
        //     () => {
        //         return store.getState().cube.node
        //             && store.getState().cube.node.nodeType === 'store'
        //             && store.getState().user.currentUser;
        //             // && store.getState().websocket.status === 2;
        //     },
        //     () => {
        //         // When changing to the store level,
        //         // let's preload some data into
        //         send(
        //             {
        //                 header: 'do',
        //                 body: {
        //                     service: 'store',
        //                     namespace: 'employee',
        //                     operation: 0,
        //                     include_channel: 2,
        //                     broadcast: false,
        //                     extra: [
        //                         store.getState().cube.weekEndDate.format('YYYY-MM-DD')
        //                     ],
        //                     params: {
        //                         active: true
        //                     },
        //                     response_type: 'NODE_INTAKE'
        //                 }
        //             },
        //             `retrieve list of employees: ${store.getState().cube.node.clientCode}.${store.getState().cube.node.tag}/${store.getState().cube.weekEndDate.format('YYYY-MM-DD')}`,
        //             true
        //         );
        //     },
        //     'cubeReload.store'
        // );

        (0, _backoff.generalBackoff)(function () {
            return store.getState().cube.node && store.getState().cube.clientCode && store.getState().user.currentUser && store.getState().cube.weekEndDate && store.getState().websocket.currentSocket && store.getState().websocket.status == 1;
        }, function () {
            var _store$getState$cube = store.getState().cube,
                clientCode = _store$getState$cube.clientCode,
                node = _store$getState$cube.node;
            var _store$getState$node$ = store.getState().node.gathered.hierarchies,
                data = _store$getState$node$.data,
                exclusions = _store$getState$node$.exclusions;

            // get hierarchies

            if (node.nodeType !== 'store' && data[clientCode + '.' + node.tag] === undefined) {
                (0, _interactions.gather)('gather hierarchy: ' + clientCode + '.' + node.tag, 'ChildrenNodesGather', {
                    client_code: clientCode,
                    tag: node.tag,
                    exclude_tags: exclusions
                }, 'NODE_INTAKE_HIERARCHY');
            }
        });

        (0, _backoff.generalBackoff)(function () {
            var currentClient = store.getState().client.clients[store.getState().cube.clientCode];

            return store.getState().cube.node && store.getState().user.currentUser && store.getState().cube.weekEndDate && currentClient && currentClient.options;
            // && store.getState().websocket.status === 2;
        }, function () {
            if (store.getState().cube.node.nodeType === 'store') {
                // When changing to the store level,
                // let's preload some data into
                (0, _interactions.send)({
                    header: 'do',
                    body: {
                        service: 'store',
                        namespace: ['schedule', 'status'],
                        operation: 0,
                        include_channel: 2,
                        broadcast: false,
                        response_type: 'NODE_INTAKE',
                        extra: [store.getState().cube.weekEndDate.format('YYYY-MM-DD')]
                    }
                }, 'retrieve schedule status');

                (0, _interactions.send)({
                    header: 'do',
                    body: {
                        service: 'store',
                        namespace: 'hours',
                        operation: 0,
                        include_channel: 2,
                        broadcast: false,
                        response_type: 'NODE_INTAKE',
                        extra: [store.getState().cube.weekEndDate.format('YYYY-MM-DD')]
                    }
                }, 'retrieve hours of operation: ' + store.getState().cube.node.clientCode + '.' + store.getState().cube.node.tag + '/' + store.getState().cube.weekEndDate.format('YYYY-MM-DD'));

                // send(
                //     {
                //         header: 'do',
                //         body: {
                //             service: 'store',
                //             namespace: 'checkliststatus',
                //             operation: 0,
                //             include_channel: 2,
                //             broadcast: false,
                //             response_type: 'NODE_INTAKE',
                //             extra: [
                //                 store
                //                     .getState()
                //                     .cube.weekEndDate.format('YYYY-MM-DD'),
                //             ],
                //         },
                //     },
                //     `retrieve checklist status: ${
                //         store.getState().cube.node.clientCode
                //     }.${
                //         store.getState().cube.node.tag
                //     }/${store.getState().cube.weekEndDate.format('YYYY-MM-DD')}`,
                // )

                (0, _interactions.send)({
                    header: 'do',
                    body: {
                        service: 'store',
                        namespace: 'details',
                        operation: 0,
                        include_channel: 2,
                        broadcast: false,
                        response_type: 'NODE_INTAKE'
                    }
                }, 'retrieve store details: ' + store.getState().cube.node.clientCode + '.' + store.getState().cube.node.tag + '/' + store.getState().cube.weekEndDate.format('YYYY-MM-DD'));

                (0, _interactions.send)({
                    header: 'do',
                    body: {
                        service: 'data',
                        namespace: 'forecasts',
                        operation: 0,
                        include_channel: 2,
                        broadcast: false,
                        response_type: 'NODE_INTAKE',
                        params: {
                            week_end_date: store.getState().cube.weekEndDate.format('YYYY-MM-DD'),
                            focus: 'hour',
                            forecast_type: 'original'
                        }
                    }
                }, 'retrieve sales forecast: ' + store.getState().cube.node.clientCode + '.' + store.getState().cube.node.tag + '/' + store.getState().cube.weekEndDate.format('YYYY-MM-DD'));

                (0, _interactions.send)({
                    header: 'do',
                    body: {
                        service: 'data',
                        namespace: 'optimallabor',
                        operation: 0,
                        include_channel: 2,
                        broadcast: false,
                        response_type: 'NODE_INTAKE',
                        params: {
                            week_end_date: store.getState().cube.weekEndDate.format('YYYY-MM-DD'),
                            focus: 'segment'
                        }
                    }
                }, 'retrieve optimal labor: ' + store.getState().cube.node.clientCode + '.' + store.getState().cube.node.tag + '/' + store.getState().cube.weekEndDate.format('YYYY-MM-DD'));

                (0, _interactions.send)({
                    header: 'do',
                    body: {
                        service: 'data',
                        namespace: 'actuallabor',
                        operation: 0,
                        include_channel: 2,
                        broadcast: false,
                        response_type: 'NODE_INTAKE',
                        params: {
                            week_end_date: store.getState().cube.weekEndDate.format('YYYY-MM-DD'),
                            focus: 'segment'
                        }
                    }
                }, 'retrieve actual labor: ' + store.getState().cube.node.clientCode + '.' + store.getState().cube.node.tag + '/' + store.getState().cube.weekEndDate.format('YYYY-MM-DD'));

                // send(
                //     {
                //         header: 'do',
                //         body: {
                //             service: 'data',
                //             namespace: 'salesatrisk',
                //             operation: 0,
                //             include_channel: 2,
                //             broadcast: false,
                //             response_type: 'NODE_INTAKE',
                //             params: {
                //                 week_end_date: store
                //                     .getState()
                //                     .cube.weekEndDate.format('YYYY-MM-DD'),
                //                 focus: 'hour',
                //             },
                //         },
                //     },
                //     `retrieve salesatrisk: ${
                //         store.getState().cube.node.clientCode
                //     }.${
                //         store.getState().cube.node.tag
                //     }/${store
                //         .getState()
                //         .cube.weekEndDate.format('YYYY-MM-DD')}`,
                // )

                (0, _interactions.send)({
                    header: 'do',
                    body: {
                        service: 'data',
                        namespace: 'salesatrisk',
                        operation: 0,
                        include_channel: 2,
                        broadcast: false,
                        response_type: 'RP_INTAKE_HOURLY',
                        params: {
                            focus: 'hour',
                            week_end_date: store.getState().cube.weekEndDate.format('YYYY-MM-DD'),
                            aggregate: true
                        },
                        extra: {
                            reportType: 'salesatrisk'
                        }
                    }
                }, 'retrieve salesatrisk: ' + store.getState().cube.node.clientCode + '.' + store.getState().cube.node.tag + '/' + store.getState().cube.weekEndDate.format('YYYY-MM-DD'));

                (0, _interactions.send)({
                    header: 'do',
                    body: {
                        service: 'data',
                        namespace: 'taskhours',
                        operation: 0,
                        include_channel: 2,
                        broadcast: false,
                        response_type: 'NODE_INTAKE',
                        params: {
                            week_end_date: store.getState().cube.weekEndDate.format('YYYY-MM-DD'),
                            focus: 'day'
                        }
                    }
                }, 'retrieve task hours: ' + store.getState().cube.node.clientCode + '.' + store.getState().cube.node.tag + '/' + store.getState().cube.weekEndDate.format('YYYY-MM-DD'));
            } else {
                // Or, get some hierarchy details

                (0, _interactions.send)({
                    header: 'do',
                    body: {
                        service: 'store',
                        namespace: ['schedule', 'status'],
                        operation: 0,
                        include_channel: 2,
                        broadcast: false,
                        response_type: 'NODE_INTAKE',
                        extra: [store.getState().cube.weekEndDate.format('YYYY-MM-DD')]
                    }
                }, 'retrieve schedule status');

                (0, _interactions.send)({
                    header: 'do',
                    body: {
                        service: 'client',
                        namespace: ['hierarchy', 'details'],
                        operation: 0,
                        include_channel: 2,
                        broadcast: false,
                        response_type: 'NODE_INTAKE'
                    }
                }, 'retrieve hierarchy details: ' + store.getState().cube.node.clientCode + '.' + store.getState().cube.node.tag);
            }
        }, 'cubeReload.node');

        (0, _backoff.socketBackoff)(function () {
            return store.getState().websocket.level;
        }, function () {
            if (store.getState().websocket.level !== 'store') {
                // When changing to the store level,
                // let's preload some data into
                (0, _interactions.send)({
                    header: 'do',
                    body: {
                        service: 'client',
                        namespace: ['hierarchy', 'details'],
                        operation: 0,
                        include_channel: 2,
                        broadcast: false,
                        params: {
                            active: true,
                            cascade: true
                        },
                        response_type: 'NODE_INTAKE'
                    }
                }, 'get hierarchy details');
            }
        }, 'cubeReload.hierarchy');
    };
});