define('matrix-frontend/pods/schedule-print/route', ['exports', 'matrix-frontend/routes/base-route'], function (exports, _baseRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseRoute.default.extend({
        queryParams: {
            pages: {}
        }
    });
});