define('matrix-frontend/pods/components/employee-panel-daily-availability/component', ['exports', 'ember-redux', 'matrix-frontend/actions/_types', 'npm:moment'], function (exports, _emberRedux, _types, _npmMoment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var stateToComputed = function stateToComputed(state) {
        return {
            weekEndDate: state.cube.weekEndDate
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {};
    };

    var Comp = Ember.Component.extend({
        _availability: Ember.computed('default', 'exceptions', function () {
            var exceptions = this.get('exceptions');
            var defaults = this.get('default');
            return exceptions ? exceptions : defaults;
        }),

        availability: Ember.computed('_availability', 'isSplitAvailability', function () {
            var _availability = this.get('_availability');
            return _availability;
        }),

        isSingleAvailability: Ember.computed('_availability', function () {
            var _availability = this.get('_availability') || [];
            return _availability.length === 1;
        }),

        isTerminated: Ember.computed('terminationDate', 'dotw', 'weekEndDate', function () {
            var terminationDate = this.get('terminationDate');
            var dotw = this.get('dotw');
            var weekEndDate = this.get('weekEndDate');
            if (terminationDate) {
                var day = (0, _npmMoment.default)(weekEndDate).day(dotw - 1);
                return day.isAfter(terminationDate, 'day');
            }
            return false;
        }),

        isNotStarted: Ember.computed('terminationDate', 'dotw', 'weekEndDate', function () {
            var startDate = this.get('startDate');
            var dotw = this.get('dotw');
            var weekEndDate = this.get('weekEndDate');
            if (startDate) {
                var day = (0, _npmMoment.default)(weekEndDate).day(dotw - 1);
                return day.isBefore(startDate, 'day');
            }
            return false;
        }),

        shiftInOtherStore: Ember.computed('dotw', 'employee.loanShifts', function () {
            if (this.employee.loanShifts) {
                return this.employee.loanShifts[this.dotw];
            }
            return null;
        })

        // actions: {
        //     displayModal() {
        //         this.get('modalService').open("availability-modal")
        //     },
        // }
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});