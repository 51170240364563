define('matrix-frontend/reducers/noclient', ['exports', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/cleaners', 'npm:lodash'], function (exports, _types, _cleaners, _npmLodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var initialState = {
        scopes: null
    };

    var store = function store(state, action) {
        var type = action.type,
            payload = action.payload;

        switch (type) {
            case _types.NOCLIENT_SCOPE_INTAKE:
                {
                    var results = payload.results,
                        meta = payload.meta;

                    return _extends({}, state, {
                        scopes: results
                    });
                }
            default:
                {
                    return state || initialState;
                }
        }
    };

    exports.default = store;
});