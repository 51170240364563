define('matrix-frontend/components/payroll-worksheet-summary', ['exports', 'ember-react-components', 'matrix-frontend/store', 'matrix-frontend/actions/_types', 'npm:react-window', 'npm:moment', 'react', 'npm:react-tooltip', 'matrix-frontend/utils/format-helpers', 'matrix-frontend/components/react-only/payroll-worksheet-summary-row', 'matrix-frontend/components/react-only/payroll-worksheet-tooltip', 'matrix-frontend/utils/functional-sort', 'matrix-frontend/utils/payroll-worksheet', 'matrix-frontend/utils/state', 'matrix-frontend/components/react-only/store-details-tooltip'], function (exports, _emberReactComponents, _store, _types, _npmReactWindow, _npmMoment, _react, _npmReactTooltip, _formatHelpers, _payrollWorksheetSummaryRow, _payrollWorksheetTooltip, _functionalSort, _payrollWorksheet, _state, _storeDetailsTooltip) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var FixedSizeList = _npmReactWindow.default.FixedSizeList;

    var TableRow = function (_PureComponent) {
        _inherits(TableRow, _PureComponent);

        function TableRow() {
            _classCallCheck(this, TableRow);

            return _possibleConstructorReturn(this, (TableRow.__proto__ || Object.getPrototypeOf(TableRow)).apply(this, arguments));
        }

        _createClass(TableRow, [{
            key: 'render',
            value: function render() {
                var _props = this.props,
                    data = _props.data,
                    index = _props.index,
                    style = _props.style;
                var RowComponent = data.RowComponent,
                    rowsProps = data.rowsProps,
                    allProps = data.allProps;

                var rowProps = (rowsProps || {})[index] || {};
                return _react.default.createElement(RowComponent, _extends({}, rowProps, allProps, { style: style }));
            }
        }]);

        return TableRow;
    }(_react.PureComponent);

    var TableContainer = function TableContainer(props) {
        var itemData = props.itemData,
            itemCount = props.itemCount,
            itemSize = props.itemSize,
            outerRef = props.outerRef,
            innerRef = props.innerRef;

        var _useState = (0, _react.useState)(0),
            _useState2 = _slicedToArray(_useState, 2),
            height = _useState2[0],
            setHeight = _useState2[1];

        var _useState3 = (0, _react.useState)(),
            _useState4 = _slicedToArray(_useState3, 2),
            docElement = _useState4[0],
            setDocElement = _useState4[1];

        var resizeHandler = function resizeHandler() {
            setHeight(docElement.clientHeight);
        };

        (0, _react.useEffect)(function () {
            window.addEventListener('resize', resizeHandler);
            return function (_) {
                return window.removeEventListener('resize', resizeHandler);
            };
        });

        return _react.default.createElement(
            'div',
            { className: 'body content-container', ref: function ref(e) {
                    if (e) {
                        setHeight(e.clientHeight);
                        setDocElement(e);
                    }
                } },
            _react.default.createElement(
                FixedSizeList,
                { style: {
                        position: 'absolute',
                        width: '100%'
                    },
                    height: height,
                    itemData: itemData,
                    itemCount: itemCount,
                    itemSize: itemSize,
                    outerRef: outerRef,
                    innerRef: innerRef
                },
                TableRow
            )
        );
    };

    var SortIndicator = function SortIndicator(props) {
        var sortKey = props.sortKey,
            currentKey = props.currentKey,
            reverseSort = props.reverseSort;

        if (sortKey === currentKey) {
            var classes = ['sort-indicator', !reverseSort ? 'icon-arrow-down' : 'icon-arrow-up'];
            return _react.default.createElement('span', { className: classes.join(' ') });
        }
        return null;
    };

    var getSortFunc = function getSortFunc(tc, sortKey, reverseSort) {
        var sorts = [];

        if (sortKey === 'sales') {
            sorts = [(0, _functionalSort.funcSort)(function (a, b) {
                if (a.hierarchy.type === 'store') {
                    var value = function value(x) {
                        return x.rollup.sales.actualOrCurrentVsOriginalForecast.percentVariance() || 0;
                    };
                    return value(a) - value(b);
                } else {
                    return (0, _functionalSort.tieredSort)([(0, _functionalSort.funcSort)(function (x) {
                        return !x.rollup.sales.numValid ? 0 : ['+0', '+1', '+2'].reduce(function (agg, k) {
                            return agg + (x.rollup.sales.categoryCounts[k] || 0);
                        }, 0) / x.rollup.sales.numValid;
                    }), (0, _functionalSort.funcSort)(function (x) {
                        return !x.rollup.sales.numValid ? 0 : ['+0', '+1', '+2'].reduce(function (agg, k) {
                            return agg + (x.rollup.sales.categoryCounts[k] || 0);
                        }, 0);
                    }), (0, _functionalSort.funcSort)(function (x) {
                        return 1 - x.rollup.sales.categoryCounts['-2'] / x.rollup.sales.numValid;
                    }), (0, _functionalSort.funcSort)(function (x) {
                        return 1 - x.rollup.sales.categoryCounts['-1'] / x.rollup.sales.numValid;
                    }), (0, _functionalSort.funcSort)(function (x) {
                        return 1 - x.rollup.sales.categoryCounts['-0'] / x.rollup.sales.numValid;
                    })])(a, b);
                }
            }, {
                useAB: true
            })];
        } else if (sortKey === 'sales-a') {
            sorts = [(0, _functionalSort.funcSort)(function (x) {
                return (tc === 'total' || x.hierarchy.type === 'store' ? x.rollup.sales.actualOrCurrent : x.rollup.sales.actualOrCurrent()) || 0;
            })];
        } else if (sortKey === 'sales-b') {
            sorts = [(0, _functionalSort.funcSort)(function (x) {
                return (tc === 'total' || x.hierarchy.type === 'store' ? x.rollup.sales.originalForecast : x.rollup.sales.originalForecast.total) || 0;
            })];
        } else if (sortKey === 'sales-diff') {
            sorts = [(0, _functionalSort.funcSort)(function (x) {
                return x.rollup.sales.actualOrCurrentVsOriginalForecast.variance();
            })];
        } else if (sortKey === 'hours') {
            sorts = [(0, _functionalSort.funcSort)(function (a, b) {
                if (a.hierarchy.type === 'store') {
                    var value = function value(x) {
                        return x.rollup.hours.actualOrScheduleVsPlan.percentVariance() || 0;
                    };
                    return value(a) - value(b);
                } else {
                    var _value = function _value(x, k) {
                        return x.rollup.hours.categoryCounts[k] || 0;
                    };
                    return (0, _functionalSort.tieredSort)([(0, _functionalSort.funcSort)(function (x) {
                        return !x.rollup.hours.numValid ? 0 : -['-0', '-1', '-2'].reduce(function (agg, k) {
                            return agg + _value(x, k);
                        }, 0) / x.rollup.hours.numValid;
                    }), (0, _functionalSort.funcSort)(function (x) {
                        return -_value(x, '-2') / x.rollup.hours.numValid;
                    }), (0, _functionalSort.funcSort)(function (x) {
                        return -_value(x, '-1') / x.rollup.hours.numValid;
                    }), (0, _functionalSort.funcSort)(function (x) {
                        return -_value(x, '-0') / x.rollup.hours.numValid;
                    })])(a, b);
                }
            }, {
                useAB: true
            })];
        } else if (sortKey === 'hours-a') {
            sorts = [(0, _functionalSort.funcSort)(function (x) {
                return (tc === 'total' || x.hierarchy.type === 'store' ? x.rollup.hours.actualOrSchedule : x.rollup.hours.actualOrSchedule()) || 0;
            })];
        } else if (sortKey === 'hours-b') {
            sorts = [(0, _functionalSort.funcSort)(function (x) {
                return x.rollup.hours.planTest.total || 0;
            })];
        } else if (sortKey === 'hours-diff') {
            sorts = [(0, _functionalSort.funcSort)(function (x) {
                return x.rollup.hours.actualOrScheduleVsPlan.variance();
            })];
        }

        return (0, _functionalSort.tieredSort)([(0, _functionalSort.funcSort)(function (x) {
            return x.hierarchy.type !== 'store';
        })].concat(_toConsumableArray(sorts), [(0, _functionalSort.funcSort)(function (x) {
            return x.hierarchy.code;
        })]), reverseSort);
    };

    var Body = function Body(props) {
        var client = props.client,
            clientCode = props.clientCode,
            weekEndDate = props.weekEndDate,
            node = props.node,
            nodeHierarchy = props.nodeHierarchy,
            directChildren = props.directChildren,
            stores = props.stores,
            subview = props.subview,
            rollups = props.rollups,
            infoRollup = props.infoRollup,
            timeColumn = props.timeColumn,
            selectSubview = props.selectSubview,
            selectBusinessUnit = props.selectBusinessUnit,
            hierarchies = props.hierarchies;


        var fullTag = clientCode + '.' + node.tag;
        var loadingHierarchies = directChildren == null || stores == null;
        var onlyStores = directChildren ? directChildren.filter(function (x) {
            return x.type !== 'store';
        }).length == 0 : false;
        var rowNodes = loadingHierarchies && client && client.options ? null : onlyStores || subview === 'store' ? stores : directChildren;

        if (rowNodes) rowNodes = rowNodes.filter(function (x) {
            return rollups[x.tag];
        });

        var weekStart = weekEndDate.clone().add(-6, 'days');

        var scrollOuterRef = (0, _react.useRef)();
        var scrollInnerRef = (0, _react.useRef)();

        var _useState5 = (0, _react.useState)('name'),
            _useState6 = _slicedToArray(_useState5, 2),
            sortKey = _useState6[0],
            setSortKey = _useState6[1];

        var _useState7 = (0, _react.useState)(false),
            _useState8 = _slicedToArray(_useState7, 2),
            reverseSort = _useState8[0],
            setReverseSort = _useState8[1];

        var _useState9 = (0, _react.useState)(0),
            _useState10 = _slicedToArray(_useState9, 2),
            scrollbarWidth = _useState10[0],
            setScrollbarWidth = _useState10[1];

        var alignScrollbar = function alignScrollbar() {
            if (scrollInnerRef.current && scrollOuterRef.current) {
                var scrollWidth = scrollOuterRef.current.offsetWidth - scrollInnerRef.current.offsetWidth;
                if (scrollbarWidth != scrollWidth) {
                    setScrollbarWidth(scrollWidth);
                }
            }
        };

        (0, _react.useEffect)(function () {
            alignScrollbar();
            window.addEventListener('resize', alignScrollbar);
            return function (_) {
                return window.removeEventListener('resize', alignScrollbar);
            };
        });

        var updateSort = function updateSort(key) {
            if (key === sortKey) {
                setReverseSort(!reverseSort);
            } else {
                setSortKey(key);
                setReverseSort(false);
            }
        };

        var itemData = void 0;
        var itemCount = 0;

        if (loadingHierarchies) {
            itemData = {
                RowComponent: _payrollWorksheetSummaryRow.FakeRow
            };
            itemCount = 6;
        } else {
            // console.info(rollups)
            var items = rowNodes.map(function (hierarchy) {
                return {
                    hierarchy: hierarchy,
                    rollup: rollups[hierarchy.tag]
                };
            });

            var sortFunc = getSortFunc(timeColumn, sortKey, reverseSort);
            var sortedItems = items.sort(sortFunc);

            // console.info(items)
            itemData = {
                RowComponent: _payrollWorksheetSummaryRow.HierarchyRow,
                rowsProps: sortedItems,
                allProps: {
                    selectBusinessUnit: selectBusinessUnit,
                    timeColumn: timeColumn
                }
            };
            itemCount = sortedItems.length;
        }

        var BasicColumn = function BasicColumn(props) {
            var text = props.text,
                classes = props.classes,
                columnKey = props.columnKey,
                contentWrapper = props.contentWrapper;


            var classList = [].concat(_toConsumableArray(classes), [columnKey ? 'sortable' : '', sortKey == columnKey ? 'weight-semi' : '']);

            var ContentWrapper = contentWrapper || function (props) {
                return _react.default.createElement(
                    'div',
                    { className: 'vertical-column' },
                    props.children
                );
            };

            var content = text instanceof Array ? _react.default.createElement(
                ContentWrapper,
                null,
                text.map(function (line, i) {
                    return _react.default.createElement(
                        'div',
                        { key: i },
                        line
                    );
                })
            ) : text;

            return _react.default.createElement(
                'div',
                { className: classList.join(' '), onClick: columnKey ? function (_) {
                        return updateSort(columnKey);
                    } : null },
                content,
                columnKey ? _react.default.createElement(SortIndicator, { sortKey: columnKey, currentKey: sortKey, reverseSort: reverseSort }) : null
            );
        };

        var thisRollup = rollups[fullTag];

        var onSubviewToggle = function onSubviewToggle() {
            selectSubview(subview === 'node' ? 'store' : 'node');
        };

        var USE_FLIPPED_PW_HOURS = (0, _state.getClientOption)('use_flipped_pw_hours', 'bool');
        var PLAN_HOURS_LABEL = (0, _state.getClientOption)('planhours_label');

        var forecast1Split = (0, _state.getClientOption)('forecast1_label', 'label', { split: true });
        var isPrelimForecast = infoRollup && infoRollup.anyNotActive;

        return _react.default.createElement(
            _react.Fragment,
            null,
            _react.default.createElement(
                'div',
                { className: 'summary-table flex-table' },
                _react.default.createElement(
                    'div',
                    { className: 'head', style: {
                            paddingRight: scrollbarWidth + 'px'
                        } },
                    _react.default.createElement(
                        'div',
                        { className: 'row' },
                        _react.default.createElement(
                            'div',
                            { className: 'wrapper f1' },
                            _react.default.createElement(BasicColumn, { text: 'Name', classes: ['text', 'column', 'f1'], columnKey: 'name' })
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'wrapper f2' },
                            _react.default.createElement(BasicColumn, { text: 'Sales', classes: ['weight-semi', 'column', 'f2'], columnKey: 'sales' }),
                            _react.default.createElement(BasicColumn, {
                                text: _react.default.createElement(
                                    'div',
                                    { className: 'vertical-column' },
                                    _react.default.createElement(
                                        'div',
                                        null,
                                        'Actual'
                                    ),
                                    _react.default.createElement(
                                        'div',
                                        { className: 'italics' },
                                        (0, _state.getClientOption)('forecast3_label', 'label', { short: true, target: 'pw_summary' })
                                    )
                                ),
                                classes: ['column', 'f1'],
                                columnKey: 'sales-a'
                            }),
                            _react.default.createElement(BasicColumn, {
                                text: isPrelimForecast ? ['Prelim'].concat(_toConsumableArray(forecast1Split)) : forecast1Split,
                                contentWrapper: isPrelimForecast ? function (_ref) {
                                    var children = _ref.children;

                                    return _react.default.createElement(
                                        'div',
                                        { className: 'vertical-column yellow white-text', style: {
                                                padding: '2px 5px 2px 5px',
                                                borderRadius: 6,
                                                fontWeight: 'bold',
                                                fontSize: '0.75rem'
                                            } },
                                        children
                                    );
                                } : null,
                                classes: ['column', 'f1', 'italics'],
                                columnKey: 'sales-b'
                            }),
                            _react.default.createElement(BasicColumn, { text: 'Variance', classes: ['column', 'f1'], columnKey: 'sales-diff' })
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'wrapper f2' },
                            _react.default.createElement(BasicColumn, { text: 'Hours', classes: ['weight-semi', 'column', 'f2'], columnKey: 'hours' }),
                            _react.default.createElement(BasicColumn, { text: ['Actual', 'Scheduled'], classes: ['column', 'f1'], columnKey: 'hours-a' }),
                            _react.default.createElement(BasicColumn, { text: [PLAN_HOURS_LABEL], classes: ['column', 'f1'], columnKey: 'hours-b' }),
                            _react.default.createElement(BasicColumn, { text: USE_FLIPPED_PW_HOURS ? ['Hours', 'Under', '(Over)'] : ['Hours', 'Over', '(Under)'], classes: ['column', 'f1'], columnKey: 'hours-diff' })
                        )
                    )
                ),
                _react.default.createElement(
                    'div',
                    { className: 'head total', style: { paddingRight: scrollbarWidth + 'px' } },
                    thisRollup !== undefined && !loadingHierarchies ? _react.default.createElement(_payrollWorksheetSummaryRow.HeaderRow, { showToggle: !onlyStores, subview: subview, onToggle: onSubviewToggle, rollup: thisRollup, timeColumn: timeColumn }) : _react.default.createElement(_payrollWorksheetSummaryRow.FakeRow, null)
                ),
                itemCount > 0 ? _react.default.createElement(TableContainer, {
                    itemData: itemData,
                    itemCount: itemCount,
                    itemSize: 40,
                    outerRef: scrollOuterRef,
                    innerRef: scrollInnerRef
                }) : null
            ),
            _react.default.createElement(_npmReactTooltip.default, { id: 'basicTooltip', effect: 'solid' }),
            _react.default.createElement(_storeDetailsTooltip.default, { hierarchies: hierarchies }),
            _react.default.createElement(_payrollWorksheetTooltip.AggregateVarianceTooltip, { id: 'aggregateSalesVariance', categories: (0, _payrollWorksheet.getSalesVarianceCategories)(), reverse: true }),
            _react.default.createElement(_payrollWorksheetTooltip.AggregateVarianceTooltip, { id: 'aggregateHoursVariance', categories: (0, _payrollWorksheet.getHoursVarianceCategories)(), reverse: true })
        );
    };

    var Wrapper = function (_Component) {
        _inherits(Wrapper, _Component);

        function Wrapper() {
            _classCallCheck(this, Wrapper);

            return _possibleConstructorReturn(this, (Wrapper.__proto__ || Object.getPrototypeOf(Wrapper)).apply(this, arguments));
        }

        _createClass(Wrapper, [{
            key: 'shouldComponentUpdate',
            value: function shouldComponentUpdate(nextProps) {
                var _this3 = this;

                return Object.keys(nextProps).reduce(function (agg, k) {
                    var changed = nextProps[k] !== _this3.props[k];
                    if (k != 'children' && changed) {
                        return true;
                    }
                    return agg;
                }, false);
            }
        }, {
            key: 'render',
            value: function render() {
                return _react.default.createElement(Body, this.props);
            }
        }]);

        return Wrapper;
    }(_react.Component);

    exports.default = (0, _emberReactComponents.default)(Wrapper);
});