define('matrix-frontend/pods/components/new-checklist-page/component', ['exports', 'ember-redux', 'matrix-frontend/actions/_types', 'npm:moment', 'matrix-frontend/middleware/checklist', 'matrix-frontend/utils/helpers'], function (exports, _emberRedux, _types, _npmMoment, _checklist, _helpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var stateToComputed = function stateToComputed(state) {
        return {
            date: state.cube.date,
            node: state.cube.node,
            nodeStores: state.node.stores,
            nodeHierarchies: state.node.hierarchies,
            clientCode: state.cube.clientCode,
            weekEndDate: state.cube.weekEndDate,
            currentUser: state.user.currentUser,
            checklist: state.checklist,
            weeklyItems: state.checklist.weeklyItems,
            itemStoreitems: state.checklist.itemStoreitems,
            hierarchies: state.node.gathered.hierarchies
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {
            // selectTimeColumn: timeColumn => dispatch({
            //     type: types.RP_SET_TIMECOLUMN,
            //     payload: {
            //         timeColumn,
            //     },
            // }),
        };
    };

    var Comp = Ember.Component.extend({
        stateService: Ember.inject.service(),
        redux: Ember.inject.service(),
        modalService: Ember.inject.service(),
        saveService: Ember.inject.service(),

        nodeHierarchy: Ember.computed('nodeHierarchies', 'nodeStores', 'node', function () {
            if (this.node.nodeType === 'store') {
                if (this.nodeStores == null) return null;
                return this.nodeStores[this.clientCode + '.' + this.node.tag];
            } else {
                if (this.nodeHierarchies == null) return null;
                return this.nodeHierarchies[this.clientCode + '.' + this.node.tag];
            }
        }),

        hierarchy: Ember.computed('node', 'hierarchies', function () {
            var hierarchyData = this.get('hierarchies.data') || {};
            return hierarchyData[this.clientCode + '.' + this.node.tag];
        }),

        childStoreTags: Ember.computed('hierarchies', 'hierarchy', function () {
            if (!this.hierarchy || this.hierarchy.type === 'store') return null;
            var fullTag = this.clientCode + '.' + this.node.tag;
            var hierarchies = this.hierarchies.data;
            var childStoreTags = [];

            var findStoreTags = function findStoreTags(x) {
                var h = hierarchies[x];
                if (!h) return;
                if (h.type === 'store') {
                    childStoreTags.push(x);
                } else {
                    h.child_tags.forEach(findStoreTags);
                }
            };

            findStoreTags(fullTag);
            return childStoreTags;
        }),

        checklistItems: Ember.computed('itemStoreitems', 'weeklyItems', 'weekEndDate', 'node', 'childStoreTags', function () {
            var _this = this;

            var fullTag = this.clientCode + '.' + this.node.tag;
            var weekItems = this.weeklyItems[this.weekEndDate.format('YYYY-MM-DD')] || {};
            var tagItems = weekItems[fullTag];

            var items = tagItems == null ? tagItems : Object.keys(tagItems).reduce(function (o, itemId) {
                var item = tagItems[itemId];
                if (item.show_in_checklist) o[itemId] = item;
                return o;
            }, {});

            if (items && this.node.nodeType !== 'store' && this.hierarchy) {
                var childStoreTags = this.get('childStoreTags');

                Object.keys(items).forEach(function (itemId) {
                    var item = items[itemId];
                    var container = _this.itemStoreitems[itemId];

                    // calculate aggregates manually if we have the storeitems, so changes get reflected without refreshing
                    if (container && container.storeitems) {
                        var exclusions = container.exclusions,
                            storeitems = container.storeitems;


                        var gatheredAnyParent = [].concat(_toConsumableArray(_this.nodeHierarchy.ancestorTags), [_this.nodeHierarchy.tag]).find(function (x) {
                            return exclusions.has(_this.clientCode + '.' + x);
                        });
                        if (!gatheredAnyParent) return;

                        // base aggregates
                        var agg = {
                            status_count: {},
                            total_hours: 0,
                            min_start_date: (0, _npmMoment.default)(item.min_start_date),
                            max_due_date: (0, _npmMoment.default)(item.max_due_date)

                            // apply each storeitem
                        };childStoreTags.forEach(function (x) {
                            var storeitem = storeitems[x];
                            if (!storeitem) return;
                            agg.total_hours += (0, _helpers.coalesce)(storeitem.final_hours, storeitem.schedule_hours, storeitem.prelim_hours, 0);
                            var status = storeitem.status.toLowerCase();
                            agg.status_count[status] = (agg.status_count[status] || 0) + 1;
                            if (storeitem.start_date_exception) agg.min_start_date = _npmMoment.default.min((0, _npmMoment.default)(storeitem.start_date_exception), agg.min_start_date);
                            if (storeitem.due_date_exception) agg.max_due_date = _npmMoment.default.min((0, _npmMoment.default)(storeitem.due_date_exception), agg.max_due_date);
                        });

                        // correct date back to YYYY-MM-DD str
                        agg.min_start_date = agg.min_start_date.format('YYYY-MM-DD');
                        agg.max_due_date = agg.max_due_date.format('YYYY-MM-DD');

                        // apply calculated aggregates
                        items[itemId] = _extends({}, item, agg);
                    }
                });
            }
            if (items == null) return items;
            return _extends({}, items);
        }),

        focusItem: Ember.computed('checklistItems', 'checklist.focusItemId', function () {
            var checklistItems = this.get('checklistItems');
            var focusItemId = this.checklist.focusItemId;
            if (!checklistItems || focusItemId == null) {
                return null;
            }
            return checklistItems[focusItemId];
        }),

        storeitems: Ember.computed('itemStoreitems', 'focusItem', 'checklistItems', function () {
            if (this.node.nodeType === 'store') {
                var fullTag = this.clientCode + '.' + this.node.tag;
                var now = _npmMoment.default.utc(); //.set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0})
                var checklistItems = this.get('checklistItems');

                if (!checklistItems) return null;

                var value = {};

                var itemStoreitems = this.itemStoreitems;
                Object.keys(itemStoreitems).forEach(function (itemId) {
                    var item = checklistItems[itemId];
                    var storeitem = itemStoreitems[itemId].storeitems[fullTag];
                    if (item && storeitem) {

                        var startDate = _npmMoment.default.utc(storeitem.start_date_exception ? storeitem.start_date_exception : item.start_date);
                        var dueDate = _npmMoment.default.utc(storeitem.due_date_exception ? storeitem.due_date_exception : item.due_date);
                        var countedStartDate = startDate.clone().add(10, 'hours');
                        var countedDueDate = dueDate.clone().add(34, 'hours');
                        var completedAt = storeitem.completed_at ? _npmMoment.default.utc(storeitem.completed_at) : null;

                        var status = void 0;
                        if (completedAt) {
                            status = !completedAt.isSameOrAfter(countedDueDate) ? 'COMPLETED' : 'LATE';
                        } else if (now.isSameOrAfter(countedDueDate)) {
                            status = 'OVERDUE';
                        } else {
                            status = !now.isSameOrAfter(countedStartDate) ? 'UPCOMING' : 'INCOMPLETE';
                        }
                        storeitem.status = status;
                        console.info(status);

                        storeitem.hours = storeitem.final_hours != null ? storeitem.final_hours : storeitem.schedule_hours != null ? storeitem.schedule_hours : storeitem.prelim_hours != null ? storeitem.prelim_hours : 0;

                        value[itemId] = storeitem;
                    }
                });
                return value;
            } else {
                var focusItem = this.get('focusItem');
                if (focusItem) {
                    var storeitemData = this.itemStoreitems[focusItem.id];
                    if (storeitemData) {
                        var storeitems = storeitemData.storeitems;

                        return storeitems;
                    }
                }
            }
            return null;
        }),

        shownNodeItems: Ember.computed('storeitems', 'focusItem', 'hierarchies', 'checklist.showstores', function () {
            if (this.node.nodeType === 'store') {
                return null;
            }
            var storeitems = this.get('storeitems');
            if (storeitems != null) {
                var focusItem = this.get('focusItem');
                var hierarchyData = this.get('hierarchies.data');

                var now = _npmMoment.default.utc();

                var nodeItems = {};
                var calcNodeItem = function calcNodeItem(tag) {
                    var hierarchy = hierarchyData[tag];
                    if (hierarchy) {
                        if (hierarchy.type === 'store') {
                            var item = storeitems[tag];
                            if (!item) return null;

                            var startDate = (0, _npmMoment.default)(item.start_date_exception ? item.start_date_exception : focusItem.start_date);
                            var dueDate = _npmMoment.default.utc(item.due_date_exception ? item.due_date_exception : focusItem.due_date);
                            var countedStartDate = startDate.clone().add(10, 'hours');
                            var countedDueDate = dueDate.clone().add(34, 'hours');
                            var completedAt = item.completed_at ? _npmMoment.default.utc(item.completed_at) : null;

                            var status = void 0;
                            if (completedAt) {
                                status = !completedAt.isSameOrAfter(countedDueDate) ? 'COMPLETED' : 'LATE';
                            } else if (now.isSameOrAfter(countedDueDate)) {
                                status = 'OVERDUE';
                            } else {
                                status = !now.isSameOrAfter(countedStartDate) ? 'UPCOMING' : 'INCOMPLETE';
                            }
                            item.status = status;

                            item.hours = item.final_hours != null ? item.final_hours : item.schedule_hours != null ? item.schedule_hours : item.prelim_hours != null ? item.prelim_hours : 0;

                            return nodeItems[tag] = {
                                hierarchy: hierarchy,
                                item: item
                            };
                        } else {
                            var children = hierarchy.child_tags.map(calcNodeItem).filter(function (c) {
                                return c != null;
                            });
                            if (children.length > 0) {
                                var _item = children.reduce(function (agg, child) {
                                    var statusCounts = agg.statusCounts || {};
                                    var hours = agg.hours || 0;
                                    var minStartDate = agg.maxStartDate || null;
                                    var maxDueDate = agg.maxDueDate || null;

                                    var childHiearchy = child.hierarchy,
                                        childItem = child.item;


                                    if (childHiearchy.type === 'store') {
                                        statusCounts[childItem.status] = (statusCounts[childItem.status] || 0) + 1;
                                        if (childItem.due_date_exception != null) {
                                            var childDate = _npmMoment.default.utc(childItem.due_date_exception);
                                            maxDueDate = childDate > maxDueDate ? childDate : maxDueDate;
                                        }
                                        if (childItem.start_date_exception != null) {
                                            var _childDate = _npmMoment.default.utc(childItem.start_date_exception);
                                            minStartDate = _childDate < minStartDate ? _childDate : minStartDate;
                                        }
                                    } else {
                                        Object.keys(childItem.statusCounts).forEach(function (status) {
                                            statusCounts[status] = (statusCounts[status] || 0) + childItem.statusCounts[status];
                                        });
                                        maxDueDate = childItem.maxDueDate > maxDueDate ? childItem.maxDueDate : maxDueDate;
                                        minStartDate = childItem.minStartDate < minStartDate ? childItem.minStartDate : minStartDate;
                                    }
                                    hours += childItem.hours;

                                    return _extends({}, agg, {
                                        statusCounts: statusCounts,
                                        hours: hours,
                                        minStartDate: minStartDate,
                                        maxDueDate: maxDueDate
                                    });
                                }, {});

                                return nodeItems[tag] = {
                                    hierarchy: hierarchy,
                                    item: _item
                                };
                            }
                            return null;
                        }
                    }
                };
                var fullTag = this.clientCode + '.' + this.node.tag;
                calcNodeItem(fullTag);

                if (!hierarchyData[fullTag]) {
                    return null;
                }
                if (!this.checklist.showstores) {
                    var hierarchy = hierarchyData[fullTag];
                    if (hierarchy) {
                        return hierarchy.child_tags.map(function (childTag) {
                            return nodeItems[childTag];
                        }).filter(function (i) {
                            return i != null;
                        });
                    }
                } else {
                    var getStoreItems = function getStoreItems(tag) {
                        var hierarchy = hierarchyData[tag];
                        if (hierarchy) {
                            return hierarchy.type === 'store' ? [nodeItems[tag]] : hierarchy.child_tags.filter(function (childTag) {
                                return nodeItems[childTag] != null;
                            }).map(function (childTag) {
                                return getStoreItems(childTag);
                            }).reduce(function (agg, o) {
                                return [].concat(_toConsumableArray(agg), _toConsumableArray(o));
                            }, []);
                        }
                        return [];
                    };
                    return getStoreItems(fullTag).filter(function (h) {
                        return h != null;
                    });
                }
            }

            return null;
        }),

        actions: {
            selectItem: function selectItem(itemId) {
                this.router.transitionTo({
                    queryParams: {
                        item: itemId
                    }
                });
            },
            selectWeek: function selectWeek(weekEnd) {
                this.saveService.doTransition(this.router.transitionTo({
                    queryParams: {
                        date: weekEnd.format('YYYY-MM-DD')
                    }
                }));
            }
        }
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});