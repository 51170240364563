define('matrix-frontend/pods/components/attachments-page/component', ['exports', 'npm:underscore'], function (exports, _npmUnderscore) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        attachments: null,

        weeklyAttachments: function () {
            // eslint-disable-line ember/no-function-prototype-extensions
            var attachments = [];
            var data = this.get('attachments');
            data.forEach(function (item) {
                if (!_npmUnderscore.default.isNull(item.get('weekEndDate'))) {
                    attachments.push(item);
                }
            });
            return attachments;
        }.property('attachments'),

        otherAttachments: function () {
            // eslint-disable-line ember/no-function-prototype-extensions
            var attachments = [];
            var data = this.get('attachments');
            data.forEach(function (item) {
                if (_npmUnderscore.default.isNull(item.get('weekEndDate'))) {
                    attachments.push(item);
                }
            });
            return attachments;
        }.property('attachments'),

        isWeeklyEmpty: function () {
            // eslint-disable-line ember/no-function-prototype-extensions
            if (_npmUnderscore.default.isEmpty(this.get('weeklyAttachments'))) {
                return true;
            } else {
                return false;
            }
        }.property('weeklyAttachments'),

        isOtherEmpty: function () {
            // eslint-disable-line ember/no-function-prototype-extensions
            if (_npmUnderscore.default.isEmpty(this.get('otherAttachments'))) {
                return true;
            } else {
                return false;
            }
        }.property('weeklyAttachments')
    });
});