define('matrix-frontend/helpers/record-noon-midnight-class', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.recordNoonMidnightClass = recordNoonMidnightClass;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    function recordNoonMidnightClass(_ref) {
        var _ref2 = _slicedToArray(_ref, 3),
            availabilityRecord = _ref2[0],
            index = _ref2[1],
            part = _ref2[2];

        var classes = ['field'];
        var record = availabilityRecord[index];

        var cont = part === 'start' ? !record.fromOpen : !record.untilClose;
        if (cont) {
            var block = part === 'start' ? record.start : record.end;
            var hours = block.hours();
            var minutes = block.minutes();

            if (minutes === 0) {
                if (hours === 12) {
                    classes.push('noon');
                } else if (hours === 0 || hours === 24) {
                    classes.push('midnight');
                }
            }
        }

        return classes.join(' ');
    }

    exports.default = Ember.Helper.helper(recordNoonMidnightClass);
});