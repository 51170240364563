define('matrix-frontend/middleware/reports', ['exports', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/interactions', 'matrix-frontend/utils/backoff', 'npm:moment'], function (exports, _types, _interactions, _backoff, _npmMoment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var beforeListeners = {};
    var afterListeners = {};

    // afterListeners[types.RP_SET_VIEW] = (store, payload) => {
    //     const {view, subview} = store.getState().reports
    //     if(view === 'summary' && !subview) {
    //         store.dispatch({
    //             type: types.RP_SET_SUBVIEW,
    //             payload: {
    //                 subview: 'node',
    //             },
    //         })
    //     } else if(view === 'detail') {
    //         generalBackoff(
    //             () => {
    //                 return (
    //                     store.getState().cube.node &&
    //                     store.getState().user.currentUser &&
    //                     store.getState().cube.weekEndDate
    //                 )
    //             },
    //             () => {
    //                 const {weekEndDate, clientCode, node} = store.getState().cube
    //                 const {report, view, hourlyData} = store.getState().reports

    //                 report.gathers.forEach(g => {
    //                     const gWeekEndDate = g.weekEndDate(weekEndDate).format('YYYY-MM-DD')

    //                     let weekData
    //                     let reportData
    //                     let alreadyGathered = {}
    //                     if(weekData = hourlyData[gWeekEndDate])
    //                         if(reportData = weekData[g.report])
    //                             alreadyGathered = reportData.alreadyGathered || {}

    //                     if(!alreadyGathered[`${clientCode}.${node.tag}`]) {
    //                         const getDataKey = () => {
    //                             switch(g.report) {
    //                                 case 'salesforecast':
    //                                 case 'salesactual':
    //                                     return 'sales'
    //                                 default:
    //                                     return null
    //                             }
    //                         }
    //                         send(
    //                             {
    //                                 header: 'do',
    //                                 body: {
    //                                     service: 'data',
    //                                     namespace: g.endpoint,
    //                                     operation: 0,
    //                                     include_channel: 2,
    //                                     broadcast: false,
    //                                     response_type: 'RP_INTAKE_HOURLY',
    //                                     params: {
    //                                         focus: 'hour',
    //                                         week_end_date: gWeekEndDate,
    //                                         aggregate: true,
    //                                     },
    //                                     extra: {
    //                                         report: g.report,
    //                                         dataKey: getDataKey(),
    //                                     }
    //                                 },
    //                             },
    //                             `[reports] retrieve hourly ${g.report} ${gWeekEndDate}: ${clientCode}.${node.tag}`,
    //                         )
    //                     }
    //                 }) 
    //             }
    //         )
    //     }
    // }

    // afterListeners[types.RP_SET_REPORT] = (store, payload) => {

    //     if(store.getState().reports) {
    //         store.dispatch({
    //             type: types.RP_SET_VIEW,
    //             payload: {
    //                 view: store.getState().reports.view,
    //             },
    //         })
    //     }

    //     generalBackoff(
    //         () => {
    //             return (
    //                 store.getState().cube.node
    //             )
    //         },
    //         () => {
    //             const {weekEndDate, clientCode, node} = store.getState().cube
    //             // const {hierarchies, report, view} = store.getState().reports

    //             // const hierarchy = hierarchies[`${clientCode}.${node.tag}`]
    //             // if(!hierarchy) {
    //             //     send(
    //             //         {
    //             //             header: 'do',
    //             //             body: {
    //             //                 service: node.nodeType ==='store' ? 'store' : 'client',
    //             //                 namespace: node.nodeType ==='store' ? ['details'] : ['hierarchy', 'details'],
    //             //                 operation: 0,
    //             //                 include_channel: 3,
    //             //                 channel_override: [clientCode, node.tag],
    //             //                 broadcast: false,
    //             //                 response_type: 'RP_INTAKE_HIERARCHY',
    //             //             },
    //             //         },
    //             //         `[reports] retrieve hierarchy details: ${
    //             //             clientCode
    //             //         }.${
    //             //             node.tag
    //             //         }`,
    //             //     )
    //             // }

    //             // if(node.nodeType === 'store') {
    //             //     store.dispatch({
    //             //         type: types.RP_SET_VIEW,
    //             //         payload: {
    //             //             view: 'detail',
    //             //         },
    //             //     })
    //             // } else {
    //             //     if(!report.views.includes(view)) {
    //             //         store.dispatch({
    //             //             type: types.RP_SET_VIEW,
    //             //             payload: {
    //             //                 view: 'summary',
    //             //             },
    //             //         })
    //             //     }
    //             // }
    //         }
    //     )


    //     generalBackoff(
    //         () => {
    //             const {clientCode, node} = store.getState().cube
    //             const {hierarchies} = store.getState().node
    //             // if(node && hierarchies && node.nodeType !== 'store')
    //             //     if(!hierarchies[`${clientCode}.${node.tag}`])
    //             //         return false
    //             console.info(node, hierarchies)
    //             return (
    //                 store.getState().cube.node &&
    //                 store.getState().user.currentUser &&
    //                 store.getState().cube.weekEndDate &&
    //                 store.getState().websocket.currentSocket &&
    //                 store.getState().websocket.status == 1
    //             )
    //         },
    //         () => {
    //             // const {clientCode, node} = store.getState().cube
    //             // const {report, view, subview, hierarchies, weeklyData} = store.getState().reports
    //             // const weekEndDate = moment(store.getState().cube.weekEndDate)

    //             // const fullTag = `${clientCode}.${node.tag}`
    //             // const hierarchy = hierarchies[fullTag]

    //             // report.gathers.forEach(g => {
    //             //     const gWeekEndDate = g.weekEndDate(weekEndDate).format('YYYY-MM-DD')
    //             //     gatherReport(clientCode, node.tag, report, 'day', gWeekEndDate, bundleSize=250)


    //             //     // let weekData
    //             //     // let reportData
    //             //     // let alreadyGathered = {}
    //             //     // if(weekData = weeklyData[gWeekEndDate])
    //             //     //     if(reportData = weekData[g.report])
    //             //     //         alreadyGathered = reportData.alreadyGathered || {}

    //             //     // const hasGatheredForTag = [...hierarchy.ancestor_tags, node.tag].map(tag => alreadyGathered[`${clientCode}.${tag}`])
    //             //     // if(!hasGatheredForTag.any(has => has)) {
    //             //     //     gather(
    //             //     //         {
    //             //     //             report: g.report,
    //             //     //             week_end: gWeekEndDate,
    //             //     //             client_code: clientCode,
    //             //     //             tag: node.tag,
    //             //     //             exclude_tags: Object.keys(alreadyGathered),
    //             //     //             response_type: 'RP_INTAKE_DATA',
    //             //     //             focus: 'day',
    //             //     //         },
    //             //     //         `gather ${g.report} ${gWeekEndDate} ${node.tag}`,
    //             //     //     )
    //             //     // }
    //             // })
    //         },
    //     )
    // }

    // afterListeners[types.RP_INTAKE_HIERARCHY] = (store, payload) => {
    //     const {clientCode} = store.getState().cube
    //     const {results, meta} = payload
    //     const hierarchy = results
    //     const tag = meta.channel.join('.')

    //     if(hierarchy && hierarchy.children) {
    //         const {hierarchies} = store.getState().reports
    //         hierarchy.children.forEach(child => {
    //             const fullTag = `${clientCode}.${child.tag}`
    //             if(child.is_active && !hierarchies[fullTag]) {
    //                 send(
    //                     {
    //                         header: 'do',
    //                         body: {
    //                             service: 'client',
    //                             namespace: ['hierarchy', 'details'],
    //                             operation: 0,
    //                             include_channel: 3,
    //                             channel_override: [clientCode, child.tag],
    //                             broadcast: false,
    //                             response_type: 'RP_INTAKE_HIERARCHY',
    //                         },
    //                     },
    //                     `[reports] retrieve hierarchy details: ${
    //                         clientCode
    //                     }.${
    //                         child.tag
    //                     }`,
    //                 )
    //             }
    //         })
    //     }
    // }


    exports.default = function (store) {
        return function (next) {
            return function (action) {
                var type = action.type,
                    payload = action.payload;


                if (beforeListeners[type]) {
                    var ret = beforeListeners[type](store, payload);
                    if (ret !== undefined) {
                        if (ret === false) {
                            return;
                        } else {
                            action.payload = ret;
                        }
                    }
                }

                next(action);

                if (afterListeners[type]) {
                    afterListeners[type](store, payload);
                }
            };
        };
    };
});