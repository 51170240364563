define('matrix-frontend/components/employees-body', ['exports', 'ember-react-components', 'react', 'matrix-frontend/components/react-only/employee-availability-row', 'matrix-frontend/components/react-only/employee-contact-row', 'matrix-frontend/components/react-only/employee-minmax-row', 'matrix-frontend/components/react-only/employee-overview-row', 'matrix-frontend/components/react-only/employee-skill-row', 'npm:react-tooltip', 'matrix-frontend/components/react-only/email-status-tooltip'], function (exports, _emberReactComponents, _react, _employeeAvailabilityRow, _employeeContactRow, _employeeMinmaxRow, _employeeOverviewRow, _employeeSkillRow, _npmReactTooltip, _emailStatusTooltip) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var FakeRow = function FakeRow() {
        return _react.default.createElement(
            'div',
            { className: 'row' },
            _react.default.createElement(
                'div',
                { className: 'wrapper f2' },
                _react.default.createElement('div', { className: 'column text f1' }),
                _react.default.createElement('div', { className: 'column text f6 no-left fake-text' })
            ),
            _react.default.createElement(
                'div',
                { className: 'wrapper f9' },
                _react.default.createElement('div', { className: 'column f2 fake-text gray-lighten-3-text' }),
                _react.default.createElement('div', { className: 'column f2 fake-text gray-lighten-3-text' }),
                _react.default.createElement('div', { className: 'column f1 fake-text gray-lighten-3-text' }),
                _react.default.createElement('div', { className: 'column f3 fake-text gray-lighten-3-text' }),
                _react.default.createElement('div', { className: 'column f2 fake-text gray-lighten-3-text' })
            )
        );
    };

    var Loading = function Loading(props) {
        return _react.default.createElement(
            _react.default.Fragment,
            null,
            [].concat(_toConsumableArray(Array(10).keys())).map(function (x) {
                return _react.default.createElement(FakeRow, { key: x });
            })
        );
    };

    var getDisplayRow = function getDisplayRow(view) {
        switch (view) {
            case 'availability':
                return _employeeAvailabilityRow.default;
            case 'contact':
                return _employeeContactRow.default;
            case 'minmax':
                return _employeeMinmaxRow.default;
            case 'skills':
                return _employeeSkillRow.default;
            default:
                return _employeeOverviewRow.default;
        }
    };

    var EmployeeRows = function EmployeeRows(props) {
        var passthru = _extends({}, props);
        delete passthru.employees;

        var Row = getDisplayRow(props.view);

        (0, _react.useEffect)(function () {
            _npmReactTooltip.default.rebuild();
        });

        var employeesById = {};
        if (props.employees) {
            props.employees.forEach(function (e) {
                employeesById[e.id] = e;
            });
        }

        return _react.default.createElement(
            _react.default.Fragment,
            null,
            props.employees.map(function (ee, idx) {
                return _react.default.createElement(Row, _extends({ key: ee.id, employee: ee, row: idx }, passthru));
            }),
            _react.default.createElement(_emailStatusTooltip.default, { employeesById: employeesById })
        );
    };

    var comp = function comp(props) {
        var Display = props.employees === null ? Loading : EmployeeRows;
        return _react.default.createElement(Display, props);
    };
    exports.default = (0, _emberReactComponents.default)(comp);
});