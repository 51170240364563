define('matrix-frontend/helpers/dateCompare', ['exports', 'npm:moment'], function (exports, _npmMoment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.dateCompare = dateCompare;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var toDate = function toDate(d) {
        var retval = _npmMoment.default.isMoment(d) ? new Date(d.year(), d.month(), d.date(), d.hours(), d.minutes(), d.seconds()) : 'today' ? new Date() : d;
        if (d === 'today') {
            retval.setHours(0, 0, 0, 0);
        }
        return retval;
    };

    function dateCompare(params /*, hash*/) {
        var _params = _slicedToArray(params, 3),
            d0 = _params[0],
            d1 = _params[1],
            operation = _params[2];

        d0 = toDate(d0);
        d1 = toDate(d1);
        var retval = operation === 'before' ? d0 < d1 : operation === 'sameBefore' ? d0 <= d1 : operation === 'after' ? d0 > d1 : operation === 'sameAfter' ? d0 >= d1 : false;
        return retval;
    }

    exports.default = Ember.Helper.helper(dateCompare);
});