define('matrix-frontend/actions/store-summary', ['exports', 'matrix-frontend/utils/backoff', 'matrix-frontend/utils/interactions', 'matrix-frontend/utils/state'], function (exports, _backoff, _interactions, _state) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.storeSummaryReload = undefined;
    var storeSummaryReload = exports.storeSummaryReload = function storeSummaryReload(store) {
        (0, _interactions.setStore)(store);
        console.debug('~~storeSummaryReload~~');

        (0, _backoff.generalBackoff)(function () {
            return store.getState().cube.node && store.getState().cube.node.nodeType !== 'store' && store.getState().cube.clientCode && store.getState().user.currentUser && store.getState().cube.weekEndDate && store.getState().websocket.currentSocket && store.getState().websocket.status == 1 && (0, _state.hasScope)('store.summary:view') && (0, _state.hasScope)('store.hoo:view');
        }, function () {
            var _store$getState$cube = store.getState().cube,
                clientCode = _store$getState$cube.clientCode,
                node = _store$getState$cube.node,
                weekEndDate = _store$getState$cube.weekEndDate;

            var hoo = store.getState().node.gathered.hoursOfOperation;
            var thisWeek = weekEndDate.format('YYYY-MM-DD');
            var weekHoo = hoo[thisWeek] || {
                exclusions: new Set()
            };
            if (weekHoo.exclusions.has(clientCode + '.' + node.tag)) {
                return;
            }
            (0, _interactions.gather)('gather hours of operation: ' + clientCode + '.' + node.tag, 'HoursOfOperationGather', {
                client_code: clientCode,
                tag: node.tag,
                week_end_date: thisWeek,
                exclude_tags: Array.from(weekHoo.exclusions)
            }, 'NODE_INTAKE_HOO_GATHER');
        });
    };
});