define('matrix-frontend/helpers/calc-variance', ['exports', 'npm:numbro'], function (exports, _npmNumbro) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.calcVariance = calcVariance;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    function calcVariance(_ref, hash) {
        var _ref2 = _slicedToArray(_ref, 2),
            value1 = _ref2[0],
            value2 = _ref2[1];

        var zeroFormat = hash['zeroFormat'] ? hash['zeroFormat'] : '';

        _npmNumbro.default.zeroFormat(zeroFormat);

        value1 = _npmNumbro.default.unformat(value1);
        value2 = _npmNumbro.default.unformat(value2);

        var varValue = value1 - value2;
        var prefix = hash['prefix'] === undefined ? '' : hash['prefix'];
        var suffix = hash['suffix'] === undefined ? '' : hash['suffix'];

        var numeralFormat = '+0,0.0';

        if (hash['format'] === 'diff') {
            numeralFormat = '(0,0)';
            var output = value1 === 0 ? '' : (0, _npmNumbro.default)(varValue).format({
                thousandSeparated: true,
                mantissa: 0,
                negative: 'parenthesis'
            });
            return output;
        } else {
            var varPct = void 0;

            if (value2 !== 0) {
                varPct = value1 === 0 ? '' : Math.round(varValue / value2 * 100 * 10) / 10;
            } else {
                varPct = 100;
            }

            var returnValue = value1 === 0 ? '' : (0, _npmNumbro.default)(varPct).format(numeralFormat);

            if (returnValue !== '') {
                return '' + prefix + returnValue + suffix;
            } else {
                return '';
            }
        }
    }

    exports.default = Ember.Helper.helper(calcVariance);
});