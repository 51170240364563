define('matrix-frontend/components/react-only/employee-name-cell', ['exports', 'react', 'matrix-frontend/actions/_types', 'matrix-frontend/store'], function (exports, _react, _types, _store) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var comp = function comp(props) {
        var employee = props.employee,
            currentStore = props.currentStore;


        var storeCode = employee.store.code.toUpperCase() !== currentStore.code.toUpperCase() ? '(' + employee.store.code.toUpperCase() + ') ' : '';
        var name = ('' + storeCode + employee.lastName + ', ' + employee.firstName).substring(0, 20);
        var isLoan = currentStore.id !== employee.store.id;

        return _react.default.createElement(
            'div',
            { className: 'wrapper f2 compound' },
            _react.default.createElement(
                'div',
                { className: 'column text f1 no-left gray-lighten-1-text' },
                _react.default.createElement(
                    'small',
                    null,
                    employee.position.code
                )
            ),
            _react.default.createElement(
                'div',
                { className: 'column text f6 no-left' },
                _react.default.createElement(
                    'span',
                    { className: isLoan ? 'gray-lighten-1-text' : '' },
                    name
                )
            )
        );
    };

    exports.default = comp;
});