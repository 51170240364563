define('matrix-frontend/pods/components/tps-task-total/component', ['exports', 'npm:underscore', 'npm:numbro'], function (exports, _npmUnderscore, _npmNumbro) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        taskTotalAmount: Ember.computed('budget.values.{day1,day2,day3,day4,day5,day6,day7}', function () {
            var budget = this.get('budget');
            var values = budget.values;
            var keyname = this.get('keyname');
            var value = _npmUnderscore.default.reduce(values, function (memo, value) {
                return memo + value.get(keyname);
            }, 0);

            if (!isNaN(value)) {
                return (0, _npmNumbro.default)(value).format('0');
            } else {
                return null;
            }
        })
    });
});