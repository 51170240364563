define('matrix-frontend/helpers/shift-swap-classes', ['exports', 'npm:moment', 'matrix-frontend/utils/date-helper'], function (exports, _npmMoment, _dateHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.shiftSwapClasses = shiftSwapClasses;

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    // const getCanSwapClass = (shift, employee) => {
    //     // Employee has all skills
    //     let canSwapClass = 'can-swap-match';

    //     if (employee) {
    //         // Employee has no skills at all
    //         if (employee.skills.length === 0) {
    //             return 'can-swap';
    //         }
    //         const shiftActivities = shift.blocks.map(x => x.activityId).filter((v, i, a) => a.indexOf(v) === i);
    //         shiftActivities.forEach(activityId => {
    //             const skill = employee.skills.find(x => x.activity === activityId && x.level === 0);

    //             // Employee has a 0 for a required skill
    //             if (skill) {
    //                 canSwapClass = 'can-swap';
    //             }
    //         });
    //     }
    //     return canSwapClass;
    // }
    var eeIsMissingSkill = function eeIsMissingSkill(shift, employee) {
        // Employee has all skills
        var isMissing = false;

        if (employee) {
            // Employee has no skills at all
            if (employee.skills.length === 0) {
                return true;
            }
            var shiftActivities = shift.blocks.map(function (x) {
                return x.activityId;
            }).filter(function (v, i, a) {
                return a.indexOf(v) === i;
            }).filter(function (x) {
                return x.activityId > 1;
            });
            shiftActivities.forEach(function (activityId) {
                var skill = employee.skills.find(function (x) {
                    return x.activity === activityId && x.level === 0;
                });

                // Employee has a 0 for a required skill
                if (skill) {
                    isMissing = true;
                }
            });
        }
        return isMissing;
    };

    function shiftSwapClasses(params /*, hash*/) {
        var classes = new Set(['daily-shift']);

        var _params = _slicedToArray(params, 11),
            activeShift = _params[0],
            currentShift = _params[1],
            dotw = _params[2],
            editMode = _params[3],
            isManagement = _params[4],
            isSmartAssignEmployee = _params[5],
            isUnassigned = _params[6],
            assignableEmployees = _params[7],
            availableEmployees = _params[8],
            employee = _params[9],
            weekEndDate = _params[10];

        console.time('shiftSwapClasses');

        // availableEmployees - passes KNOCKOUT 1
        // assignableEmployees - passes KNOCKOUT 4
        //
        // KNOCKOUT CRITERIA:
        // KNOCKOUT 1. EE must be available during the entire shift
        // KNOCKOUT 2. EE must not have more than maximumShifts
        // KNOCKOUT 3. EE must have skill > 0 for each activity in the shift
        // KNOCKOUT 4. EE's hours scheduled + shift hours must be less than EE's max weekly hours

        // Apply styles for when a shift is selected
        if (activeShift) {
            var activeDOTW = (0, _dateHelper.getDayOfClientWeek)(activeShift.meta.date) + 1;
            // const canSwapClass = getCanSwapClass(activeShift, employee);
            var assignableEmployeeIDArray = assignableEmployees.map(function (x) {
                return x.id;
            });
            var availableEmployeeIDArray = availableEmployees ? availableEmployees.map(function (x) {
                return x.id;
            }) : [];

            // If the employee has a currently assigned shift
            if (currentShift) {
                if (activeShift.meta.id === currentShift.meta.id) {
                    classes.add('active');
                } else if (activeShift.meta.date.isSame(currentShift.meta.date, 'day')) {
                    // TODO:
                    // - Need to add logic that will verify that employee is able
                    //   to work currentShift. If not => classes.add('disabled');

                    // If the employee is assignable
                    if (employee && assignableEmployeeIDArray.includes(employee.id)) {
                        // classes.add(canSwapClass);
                        classes.add('available-employee');
                        classes.add('can-swap-match');
                    } else {
                        if (employee && availableEmployeeIDArray.includes(employee.id)) {
                            if (eeIsMissingSkill(activeShift, employee)) {
                                classes.add('can-swap');
                            }
                            classes.add('available-employee');
                        } else {
                            classes.add('disabled');
                        }
                    }
                }
                if (dotw !== activeDOTW) {
                    classes.add('disabled');
                } else {
                    classes.add('same-dotw');
                }
            }

            // Employees without a shift
            if (dotw === activeDOTW) {
                classes.add('same-dotw');
                if (employee && assignableEmployeeIDArray.includes(employee.id) || !employee) {
                    // classes.add(canSwapClass);
                    classes.add('can-swap-match');
                    classes.add('available-employee');

                    if (isSmartAssignEmployee) {
                        classes.add('can-swap-best-match');
                    }
                } else {
                    if (employee && availableEmployeeIDArray.includes(employee.id)) {
                        if (eeIsMissingSkill(activeShift, employee)) {
                            classes.add('can-swap');
                        }
                        classes.add('available-employee');
                    } else {
                        classes.add('disabled');
                    }
                }
            }

            // Other days of the week
            else {
                    classes.add('disabled');
                }
        }

        if (employee) {
            var availability = employee.availability.exceptions && employee.availability.exceptions[dotw] ? employee.availability.exceptions[dotw] : employee.availability.default[dotw];
            var isUnvailable = function isUnvailable(x) {
                return !x.isAvailable;
            };

            if (availability.every(isUnvailable) || employee.terminationDate && (0, _npmMoment.default)(weekEndDate).day(dotw - 1).isAfter(employee.terminationDate, 'day') || employee.startDate && (0, _npmMoment.default)(weekEndDate).day(dotw - 1).isBefore(employee.startDate, 'day')) {
                classes.add('unavailable-employee');
                classes.add('disabled');
                classes.delete('available-employee');
                classes.delete('can-swap-match');
                classes.delete('can-swap');
            } else if (availability.some(isUnvailable)) {
                if (activeShift) {
                    // classes.delete('available-employee');
                    // classes.delete('can-swap');
                    // if (!availableEmployeeIDArray.includes(employee.id)) {
                    //     classes.delete('can-swap-match');
                    //     classes.delete('can-swap');
                    // }
                }
                classes.add('partial-available-employee');
            }
        } else {
            classes.add('unassigned');
        }

        // if (currentShift && currentShift.meta.employeeId === 2 || currentShift && currentShift.meta.employeeId === 3) {
        //     classes.add('warning');
        // }
        // if (currentShift && currentShift.meta.employeeId === 5) {
        //     classes.add('alert');
        // }
        // if (currentShift && currentShift.meta.employeeId === 1 && dotw === 1) {
        //     classes.add('violation');
        // }
        if (!editMode) {
            classes.add('disabled');
        }

        if (isManagement && !employee) {
            classes.add('activity-color-' + isManagement.livecolor.id);
        }

        if (currentShift) {
            classes.add('has-shift');
            if (currentShift.errors !== undefined && currentShift.errors.length > 0) {
                var severities = currentShift.errors.map(function (x) {
                    return x.severity;
                });
                var mostSevere = Math.max.apply(Math, _toConsumableArray(severities));
                switch (mostSevere) {
                    case -1:
                        classes.add('has-alert');
                        break;
                    case 0:
                        classes.add('has-warning');
                        break;
                    case 1:
                        classes.add('has-violation');
                        break;
                }
            }
        }

        console.timeEnd('shiftSwapClasses');
        return [].concat(_toConsumableArray(classes)).join(' ');
    }

    exports.default = Ember.Helper.helper(shiftSwapClasses);
});