define('matrix-frontend/pods/components/mx-modal/component', ['exports', 'npm:jquery'], function (exports, _npmJquery) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Component.extend({
        modalService: Ember.inject.service(),

        discardText: 'Discard',
        saveText: 'Save',
        canPerformActions: true,
        startOpen: false,
        forceChange: false,
        showMore: false,
        showMoreText: 'Show more',
        showLessText: 'Show less',
        showText: null,

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            var modalService = this.get('modalService');

            var _beforeOpen = this.get('beforeOpen');
            var afterOpen = this.get('afterOpen');
            var beforeClose = this.get('beforeClose');
            var afterClose = this.get('afterClose');
            var key = this.get('key');

            modalService.register({
                component: this,
                key: key,
                beforeOpen: function beforeOpen() {
                    if (_this.forceChange) {
                        _this.modalService.setValid(_this.key, true);
                        _this.modalService.changeMade(_this.key);
                    }
                    if (_beforeOpen) {
                        _beforeOpen.call();
                    }
                },
                afterOpen: afterOpen,
                beforeClose: beforeClose,
                afterClose: afterClose
            });
            if (this.get('startOpen')) {
                modalService.open(this.get('key'));
            }
        },


        isValid: Ember.computed('modalService.modals', function () {
            var modalService = this.get('modalService');
            var key = this.get('key');

            return modalService.isValid(key);
        }),

        isOpen: Ember.computed('modalService.modals', function () {
            var modalService = this.get('modalService');
            var key = this.get('key');

            return modalService.isOpen(key);
        }),

        isClosing: Ember.computed('modalService.modals', function () {
            var modalService = this.get('modalService');
            var key = this.get('key');

            return modalService.isClosing(key);
        }),

        isSaving: Ember.computed('modalService.saveInProgress', function () {
            return this.get('modalService.saveInProgress');
        }),

        errors: Ember.computed('modalService.modals', function () {
            var modalService = this.get('modalService');
            var key = this.get('key');
            return [].concat(_toConsumableArray(modalService.get('modals.' + key + '.errors')));
        }),

        warnings: Ember.computed('modalService.modals', function () {
            var modalService = this.get('modalService');
            var key = this.get('key');
            return [].concat(_toConsumableArray(modalService.get('modals.' + key + '.warnings')));
        }),

        alerts: Ember.computed('modalService.modals', function () {
            var modalService = this.get('modalService');
            var key = this.get('key');
            return [].concat(_toConsumableArray(modalService.get('modals.' + key + '.alerts')));
        }),

        infos: Ember.computed('modalService.modals', function () {
            var modalService = this.get('modalService');
            var key = this.get('key');
            return [].concat(_toConsumableArray(modalService.get('modals.' + key + '.infos')));
        }),

        hasChange: Ember.computed('modalService.modals', function () {
            var modalService = this.get('modalService');
            var key = this.get('key');

            return modalService.hasChange(key);
        }),

        isLoading: Ember.computed('modalService.modals', function () {
            var modalService = this.get('modalService');
            var key = this.get('key');

            return modalService.isLoading(key);
        }),

        saveDisabled: Ember.computed('hasChange', 'isLoading', 'isValid', function () {
            return !this.get('hasChange') && !this.get('isLoading') || !this.get('isValid');
        }),

        didInsertElement: function didInsertElement() {
            var modalService = this.get('modalService');
            if (this.get('forceChange')) {
                modalService.changeMade(this.get('key'));
            }

            this.actions.setShowText.bind(this).call();
            this.actions.setShow.bind(this).call();
        },


        actions: {
            close: function close() {
                var modalService = this.get('modalService');
                var key = this.get('key');
                modalService.close(key);
            },
            doSaveAction: function doSaveAction() {
                var modalService = this.get('modalService');
                var saveAction = this.get('saveAction');
                var action = typeof saveAction === 'function' ? saveAction : this.get(saveAction);
                modalService.runSave(action);
            },
            doSecondaryAction: function doSecondaryAction() {
                var modalService = this.get('modalService');
                var secondaryAction = this.get('secondaryAction');
                var action = typeof secondaryAction === 'function' ? secondaryAction : this.get(secondaryAction);
                action();
            },
            setShow: function setShow() {
                if (this.showMoreId) {
                    if (this.showMore) {
                        (0, _npmJquery.default)('#' + this.showMoreId).removeClass('hide');
                    } else {
                        (0, _npmJquery.default)('#' + this.showMoreId).addClass('hide');
                    }
                }
            },
            setShowText: function setShowText() {
                this.set('showText', this.showMore ? this.showLessText : this.showMoreText);
            },
            toggleShow: function toggleShow() {
                this.set('showMore', !this.showMore);
                this.actions.setShowText.bind(this).call();
                this.actions.setShow.bind(this).call();
            }
        }
    });
});