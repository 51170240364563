// import {useContext, useState, useRef, useEffect, useMemo, useCallback } from 'react';
// import {store} from 'matrix-frontend/store'

// const hasOwnProperty = Object.prototype.hasOwnProperty;

// // /**
// //  * inlined Object.is polyfill to avoid requiring consumers ship their own
// //  * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/is
// //  */
// function is(x, y) {
//   // SameValue algorithm
//   if (x === y) {
//     // Steps 1-5, 7-10
//     // Steps 6.b-6.e: +0 != -0
//     // Added the nonzero y check to make Flow happy, but it is redundant
//     return x !== 0 || y !== 0 || 1 / x === 1 / y;
//   } else {
//     // Step 6.a: NaN == NaN
//     return x !== x && y !== y;
//   }
// }

// // /**
// //  * Performs equality by iterating through keys on an object and returning false
// //  * when any key has values which are not strictly equal between the arguments.
// //  * Returns true when the values of all keys are strictly equal.
// //  */
// function shallowEqual(objA, objB) {
//   if (is(objA, objB)) {
//     return true;
//   }

//   if (
//     typeof objA !== "object" ||
//     objA === null ||
//     typeof objB !== "object" ||
//     objB === null
//   ) {
//     return false;
//   }

//   const keysA = Object.keys(objA);
//   const keysB = Object.keys(objB);

//   if (keysA.length !== keysB.length) {
//     return false;
//   }

//   // Test for A's keys different from B.
//   for (let i = 0; i < keysA.length; i++) {
//     if (
//       !hasOwnProperty.call(objB, keysA[i]) ||
//       !is(objA[keysA[i]], objB[keysA[i]])
//     ) {
//       return false;
//     }
//   }

//   return true;
// }

// const useRefState = initialValue => {

//     const [state, setState] = useState(initialValue);
//     const stateRef = useRef();

//     useEffect(() => {
//         stateRef.current = state;
//     }, [state]);

//     return [state, setState, stateRef];
// };

// export default function (selector, memoArray) {
//     const selectorCb = useCallback(selector, memoArray);
//     const selectorCbFn = () => selectorCb;
//     const select = () => selectorCb(store.getState());
//     const [state, setState, stateRef] = useRefState(select);
//     const [prevStore, setPrevStore] = useState(store);
//     const [prevSelectorCb, setPrevSelectorCb] = useState(selectorCbFn);
//     if(store !== prevStore || selectorCb !== prevSelectorCb) {
//         setPrevStore(store);
//         setPrevSelectorCb(selectorCbFn);
//         setState(select());
//     }
//     useEffect(() => {
//         const unsubscribe = store.subscribe(() => {
//             const newState = selectorCb(store.getState());
//             console.log(!shallowEqual(stateRef.current, newState))
//             if(!shallowEqual(stateRef.current, newState)) {
//                 setState(newState);
//             }
//         });
//         return unsubscribe;
//     },[store, selectorCb]);
//     return state;
// };
define("matrix-frontend/utils/use-redux", [], function () {
  "use strict";
});