define('matrix-frontend/actions/employee', ['exports', 'matrix-frontend/utils/backoff', 'matrix-frontend/utils/interactions', 'matrix-frontend/actions/_types'], function (exports, _backoff, _interactions, _types) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.employeeForceReload = exports.employeeReload = undefined;
    var employeeReload = exports.employeeReload = function employeeReload(store) {
        (0, _interactions.setStore)(store);
        console.debug('~~employeeReload~~');

        (0, _backoff.generalBackoff)(function () {
            return store.getState().cube.node && store.getState().cube.node.nodeType === 'store' && store.getState().user.currentUser && store.getState().cube.weekEndDate;
            // && store.getState().websocket.status === 2;
        }, function () {
            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'store',
                    namespace: 'employee',
                    operation: 0,
                    include_channel: 2,
                    broadcast: false,
                    extra: [store.getState().cube.weekEndDate.format('YYYY-MM-DD')],
                    params: {
                        active: true
                    },
                    response_type: 'NODE_INTAKE'
                }
            }, 'retrieve list of employees: ' + store.getState().cube.node.clientCode + '.' + store.getState().cube.node.tag + '/' + store.getState().cube.weekEndDate.format('YYYY-MM-DD'));

            if (!Object.keys(store.getState().scheduleEdit.shifts).length) {
                (0, _interactions.send)({
                    header: 'do',
                    body: {
                        service: 'store',
                        namespace: 'schedule',
                        operation: 0,
                        include_channel: 2,
                        broadcast: false,
                        response_type: 'SE_INTAKE',
                        extra: [store.getState().cube.weekEndDate.format('YYYY-MM-DD')],
                        node_type: 'store'
                    }
                }, 'retrieve schedule details');
            }
        });
    };

    var employeeForceReload = exports.employeeForceReload = function employeeForceReload(store) {
        (0, _interactions.setStore)(store);
        console.debug('~~employeeReload~~');

        (0, _backoff.generalBackoff)(function () {
            return store.getState().cube.node && store.getState().cube.node.nodeType === 'store' && store.getState().user.currentUser && store.getState().cube.weekEndDate;
            // && store.getState().websocket.status === 2;
        }, function () {
            var fullTag = store.getState().cube.node.clientCode + '.' + store.getState().cube.node.tag;
            var weekEndDate = store.getState().cube.weekEndDate.format('YYYY-MM-DD');
            store.dispatch({ type: _types.NODE_REFRESH_EMPLOYEES, payload: { fullTag: fullTag, weekEndDate: weekEndDate } });
            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'store',
                    namespace: 'employee',
                    operation: 4,
                    include_channel: 2,
                    broadcast: false,
                    extra: [store.getState().cube.weekEndDate.format('YYYY-MM-DD')],
                    params: {
                        active: true
                    },
                    response_type: 'NODE_INTAKE'
                }
            }, 'retrieve list of employees: ' + store.getState().cube.node.clientCode + '.' + store.getState().cube.node.tag + '/' + store.getState().cube.weekEndDate.format('YYYY-MM-DD'));
        });
    };
});