define('matrix-frontend/pods/components/store-summary/component', ['exports', 'ember-redux', 'matrix-frontend/actions/_types', 'npm:moment', 'matrix-frontend/utils/state', 'matrix-frontend/utils/date-helper'], function (exports, _emberRedux, _types, _npmMoment, _state, _dateHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var stateToComputed = function stateToComputed(state) {
        return {
            sid: state.websocket.sid,
            date: state.cube.date,
            node: state.cube.node,
            cube: state.cube,
            clientCode: state.cube.clientCode,
            clients: state.client.clients,
            weekEndDate: state.cube.weekEndDate,
            currentUser: state.user.currentUser,
            preferences: state.preferences,
            websocket: state.websocket,
            hierarchies: state.node.gathered.hierarchies,
            gatheredHoo: state.node.gathered.hoursOfOperation,
            view: state.storeSummary.view,
            dataType: state.storeSummary.dataType,
            nodeStores: state.node.stores
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {};
    };

    var correctOffset = function correctOffset(x) {
        return x == -2 ? 0 : x;
    };

    var momentFromTime = function momentFromTime(date, x, offset) {
        if (x == null) return null;
        if (_npmMoment.default.isMoment(x)) {
            return x;
        }
        var parts = x.split(':');
        return (0, _npmMoment.default)(date).set({
            hours: parts[0],
            minutes: parts[1]
        }).add(correctOffset(offset), 'days');
    };

    var Comp = Ember.Component.extend({
        modalService: Ember.inject.service(),
        stateService: Ember.inject.service(),
        saveService: Ember.inject.service(),
        redux: Ember.inject.service(),

        targetHOO: null,

        currentClient: Ember.computed('clientCode', 'clients', function () {
            var clientCode = this.get('clientCode');
            var clients = this.get('clients');
            if (clients && clientCode) {
                return clients[clientCode];
            }
        }),

        canViewPage: Ember.computed('currentClient', 'currentUser', function () {
            return (0, _state.hasScope)('store.summary:view') && (0, _state.hasScope)('store.hoo:view');
        }),

        canEditHoO: Ember.computed('currentClient', 'currentUser', function () {
            return (0, _state.hasScope)('store.hoo:edit');
        }),

        columns: Ember.computed('weekEndDate', function () {
            var _this = this;

            var shortDayNames = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
            var dayColumns = [].concat(_toConsumableArray(Array(7).keys())).map(function (docw) {
                var dt = (0, _npmMoment.default)(_this.weekEndDate).startOf('day').add(-6 + docw, 'days');
                return {
                    // shortText: shortDayNames[dt.day()],
                    text: dt.format('ddd') + ' ' + (dt.month() + 1) + '/' + dt.date(),
                    key: docw + 1
                };
            });
            return dayColumns;
        }),

        directChildren: Ember.computed('node', 'hierarchies', function () {
            var _this2 = this;

            var hierarchy = this.hierarchies.data[this.clientCode + '.' + this.node.tag];
            if (hierarchy) {
                if (hierarchy.type === 'store') {
                    return [];
                }
                return hierarchy.child_tags.map(function (tag) {
                    return _this2.hierarchies.data[tag];
                }).filter(function (x) {
                    return x != null;
                });
            }
            return null;
        }),

        thisNodeStore: Ember.computed('node', 'nodeStores', function () {
            var fullTag = this.clientCode + '.' + this.node.tag;
            if (this.node.nodeType === 'store') {
                return this.nodeStores[fullTag];
            }
            return null;
        }),

        visibleNodes: Ember.computed('node', 'hierarchies', function () {
            var _this3 = this;

            var fullTag = this.clientCode + '.' + this.node.tag;
            var rootObj = {
                stores: [],
                parents: []
            };

            var search = function search(tag, root, parentTag) {
                var hierarchy = _this3.hierarchies.data[tag];
                if (hierarchy) {
                    if (hierarchy.type === 'store') {
                        root.stores.push(_extends({}, hierarchy, {
                            parent: parentTag
                        }));
                        return true;
                    }
                    var numStores = 0;
                    hierarchy.child_tags.forEach(function (childTag) {
                        var isStore = search(childTag, root, tag);
                        if (isStore) {
                            numStores += 1;
                        }
                    });
                    if (numStores > 0) {
                        root.parents.push(_extends({}, hierarchy, {
                            numStores: numStores
                        }));
                    }
                }
                return null;
            };

            search(fullTag, rootObj);
            return this.hierarchies.data[fullTag] ? rootObj : null;
        }),

        storeHours: Ember.computed('weekEndDate', 'nodeStores', function () {
            var _this4 = this;

            var weekEnd = this.weekEndDate.format('YYYY-MM-DD');
            var storeHours = {};
            Object.keys(this.nodeStores).forEach(function (fullTag) {
                var store = _this4.nodeStores[fullTag];
                var week;
                if (week = store.weeks[weekEnd]) storeHours[fullTag] = week.hours;
            });
            return storeHours;
        }),

        rollups: Ember.computed('weekEndDate', 'node', 'hierarchies', 'gatheredHoo', 'storeHours', function () {
            var self = this;
            var weekEnd = this.weekEndDate.format('YYYY-MM-DD');
            var fullTag = this.clientCode + '.' + this.node.tag;
            var storeHours = this.storeHours;
            var weekHoo = this.gatheredHoo[weekEnd];
            var rollups = {};
            var dates = {};
            for (var i = 0; i < 7; i++) {
                dates[i + 1] = this.weekEndDate.clone().set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).add(-6 + i, 'days');
            }var calcStoreRollup = function calcStoreRollup(tag) {
                var rollup = undefined;
                if (weekHoo) {
                    var hoo = storeHours[tag];
                    if (!hoo) {
                        if (weekHoo.complete) rollup = null;
                    } else {
                        rollup = {
                            hoo: {},
                            publicWeeklyHours: 0,
                            privateWeeklyHours: 0,
                            numDaysOpen: 0
                        };
                        Object.keys(hoo).forEach(function (docw) {
                            var date = dates[docw];
                            var dayHoo = hoo[docw];
                            var dst = {
                                closedAllDay: dayHoo.closedAllDay == 'Y',
                                isException: dayHoo.isException,
                                newDefault: dayHoo.newDefault,
                                updatedAt: dayHoo.updatedAt,
                                updatedBy: dayHoo.updatedBy
                            };
                            if (!dst.closedAllDay) {
                                dst.publicOpenOffset = correctOffset(dayHoo.publicOpenOffset);
                                dst.publicCloseOffset = correctOffset(dayHoo.publicCloseOffset);
                                dst.privateOpenOffset = correctOffset(dayHoo.privateOpenOffset);
                                dst.privateCloseOffset = correctOffset(dayHoo.privateCloseOffset);
                                dst.publicOpen = momentFromTime(date, dayHoo.publicOpen, dayHoo.publicOpenOffset);
                                dst.publicClose = momentFromTime(date, dayHoo.publicClose, dayHoo.publicCloseOffset);
                                dst.privateOpen = momentFromTime(date, dayHoo.privateOpen, dayHoo.privateOpenOffset);
                                dst.privateClose = momentFromTime(date, dayHoo.privateClose, dayHoo.privateCloseOffset);
                                rollup.publicWeeklyHours += dst.publicClose.diff(dst.publicOpen, 'hours', true);
                                rollup.privateWeeklyHours += dst.privateClose.diff(dst.privateOpen, 'hours', true);
                            }
                            rollup.hoo[docw] = dst;
                            if (!dst.closedAllDay) rollup.numDaysOpen++;
                        });
                    }
                }
                rollups[tag] = rollup;
                return rollup;
            };

            var calcNodeRollup = function calcNodeRollup(tag) {
                var rollup = void 0;
                var hierarchy = self.hierarchies.data[tag];
                if (hierarchy) {
                    rollup = {
                        publicWeeklyHours: 0,
                        privateWeeklyHours: 0
                    };

                    hierarchy.child_tags.forEach(function (childTag) {
                        var childHierarchy = self.hierarchies.data[childTag];
                        var isStore = childHierarchy.type === 'store';
                        var childRollup = isStore ? calcStoreRollup(childTag) : calcNodeRollup(childTag);
                        if (childRollup) {
                            rollup.publicWeeklyHours += childRollup.publicWeeklyHours;
                            rollup.privateWeeklyHours += childRollup.privateWeeklyHours;
                        }
                    });
                }
                rollups[tag] = rollup;
                return rollup;
            };

            if (this.node.nodeType !== 'store') calcNodeRollup(fullTag);
            return rollups;
        }),

        actions: {
            selectDataType: function selectDataType(dataType) {
                this.router.transitionTo({
                    queryParams: {
                        dataType: dataType
                    }
                });
            },
            selectView: function selectView(view) {
                this.router.transitionTo({
                    queryParams: {
                        view: view
                    }
                });
            },
            // navigateToBusinessUnit: function(id, type) {
            //     const self = this
            //     // this.get('saveService').doAction(
            //     //     self.sendAction.bind(self),
            //     //     'selectBusinessUnit',
            //     //     id,
            //     //     type,
            //     // )
            //     console.info(id, type)
            //     this.router.transitionTo({
            //         queryParams: type == 'store' ? {
            //             storeId: id,
            //             hierarchyNodeId: null,
            //             _noReplace: true,
            //         } : {
            //             hierarchyNodeId: id,
            //             storeId: null,
            //             _noReplace: true,
            //         },
            //     })
            // },
            openHoOModal: function openHoOModal(fullTag, docw) {
                if (!this.canEditHoO) return;
                var weekEnd = this.weekEndDate.format('YYYY-MM-DD');
                var hoo = this.storeHours[fullTag];
                var dayHoo = hoo[docw];
                var date = this.weekEndDate.clone().add(-7 + docw, 'days').startOf('day');
                var hooData = {
                    id: dayHoo.id,
                    date: date,
                    closedAllDay: dayHoo.closedAllDay,
                    publicOpen: momentFromTime(date, dayHoo.publicOpen, dayHoo.publicOpenOffset),
                    publicClose: momentFromTime(date, dayHoo.publicClose, dayHoo.publicCloseOffset),
                    privateOpen: momentFromTime(date, dayHoo.privateOpen, dayHoo.privateOpenOffset),
                    privateClose: momentFromTime(date, dayHoo.privateClose, dayHoo.privateCloseOffset),
                    updatedAt: dayHoo.updatedAt,
                    updatedBy: dayHoo.updatedBy,
                    newDefault: dayHoo.newDefault,
                    isException: dayHoo.isException
                };
                if (docw > 1) {
                    var xhoo = hoo[docw - 1];
                    hooData.prevDayPublicClose = momentFromTime((0, _npmMoment.default)(date).add(-1, 'day'), xhoo.publicClose, xhoo.publicCloseOffset);
                } else {
                    hooData.prevDayPublicClose = (0, _npmMoment.default)(date).set({ hour: 3, minute: 0, second: 0 });
                }
                if (docw < 7) {
                    var _xhoo = hoo[docw + 1];
                    hooData.nextDayPublicOpen = momentFromTime((0, _npmMoment.default)(date).add(1, 'day'), _xhoo.publicOpen, _xhoo.publicOpenOffset);
                } else {
                    hooData.nextDayPublicOpen = (0, _npmMoment.default)(date).add(1, 'day').set({ hour: 6, minute: 0, second: 0 });
                }
                var currentStore = _extends({}, this.nodeStores[fullTag], {
                    tag: fullTag.split('.').slice(1).join('.')
                });
                this.set('targetHOO', hooData);
                this.set('currentStore', currentStore);
                Ember.run.next(this, function () {
                    this.modalService.open('hoo-modal');
                });
            }
        }
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});