define('matrix-frontend/components/employee-rollup', ['exports', 'ember-react-components', 'matrix-frontend/store', 'react', 'matrix-frontend/components/react-only/employee-rollup-body', 'matrix-frontend/components/react-only/employee-rollup-head', 'matrix-frontend/components/react-only/employee-rollup-totals'], function (exports, _emberReactComponents, _store, _react, _employeeRollupBody, _employeeRollupHead, _employeeRollupTotals) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var comp = function comp(_ref) {
        var node = _ref.node,
            currentNode = _ref.currentNode,
            currentClient = _ref.currentClient,
            activitiesById = _ref.activitiesById,
            selectBusinessUnit = _ref.selectBusinessUnit,
            weekEndDate = _ref.weekEndDate,
            items = _ref.items,
            canEmail = _ref.canEmail,
            openEmailModal = _ref.openEmailModal;

        // const [items, setItems] = useState([])
        // const [nodeData, setNodeData] = useState([])
        // useEffect(() => {
        //     if (currentNode) {
        // useEffect(() => {
        //         let nodes = [...currentNode.children, ...currentNode.stores].sortBy('code')
        //         const nodeData = store.getState().node

        //         // nodes = nodes.map(node => {
        //         //     const nodeStore = nodeData[node.tag.startsWith("store") ? "stores" : "hierarchies"]
        //         //     let weeks = {}

        //         //     if (currentClient && node && nodeStore[`${currentClient.code}.${node.tag}`]) {
        //         //         weeks = nodeStore[`${currentClient.code}.${node.tag}`].weeks
        //         //     }
        //         //     return {
        //         //         ...node,
        //         //         weeks,
        //         //     }
        //         // })
        //         setNodeData(nodeData)
        //         setItems(nodes)
        //     }
        // }, [currentNode])

        return _react.default.createElement(
            'div',
            { className: 'employees flex-table breathe-t-20' },
            _react.default.createElement(
                'div',
                { className: 'control-panel-wrapper' },
                _react.default.createElement(
                    'div',
                    { className: 'control-panel thin' },
                    _react.default.createElement('div', null),
                    canEmail ? _react.default.createElement(
                        'div',
                        { className: 'nav-link' },
                        _react.default.createElement(
                            'a',
                            { className: 'button thin secondary hide-on-print', onClick: openEmailModal },
                            _react.default.createElement('span', { className: 'icon-envelope' })
                        )
                    ) : _react.default.createElement('div', null)
                )
            ),
            _react.default.createElement(_employeeRollupHead.default, null),
            _react.default.createElement(_employeeRollupTotals.default, { items: items, activitiesById: activitiesById }),
            _react.default.createElement(_employeeRollupBody.default, {
                node: node,
                items: items,
                weekEndDate: weekEndDate,
                selectBusinessUnit: selectBusinessUnit,
                activitiesById: activitiesById,
                currentClient: currentClient
            })
        );
    };

    exports.default = (0, _emberReactComponents.default)(comp);
});