define('matrix-frontend/pods/components/mx-modal-checkbox/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    exports.default = Ember.Component.extend({
        modalService: Ember.inject.service(),
        isValid: null,
        validateOnClick: true,
        bubble: true,
        requireAll: true,
        requires: null,
        additionalRequirement: null,

        doValidation: function doValidation() {
            if (this.get('validate')) {
                // let params = [
                //     this.$()
                //         .find('input')
                //         .val(),
                // ]
                // let validateParams = this.get('validateParams') || []
                // let tetheredInputs = []

                // if (!Array.isArray(validateParams)) {
                //     validateParams = [validateParams]
                // }

                // if (this.get('tether')) {
                //     let tethers = []
                //     // const tether =
                //     if (this.tether.includes('|')) {
                //         this.tether.split('|').forEach(x => {
                //             tethers.push($(`#${x}`))
                //         })
                //     } else {
                //         tethers.push($(`#${this.tether}`))
                //     }
                //     tetheredInputs = tethers.map(x => x.find('input'))
                //     tetheredInputs.forEach(x => {
                //         params.push(x.val())
                //     })
                //     if (this.triggerTether) {
                //         tetheredInputs.forEach(x => {
                //             x.trigger('keyup')
                //         })
                //     }
                // }

                // params = [...params, ...validateParams]
                var params = [];

                var validity = this.get('validate').apply(undefined, params);
                this.set('isValid', validity);

                if (this.bubble) {
                    var modalService = this.get('modalService');
                    var key = this.get('key');
                    modalService.bubbleValidation(key, this.requires, this.requireAll, this.additionalRequirement);
                }
            }
        },


        actions: {
            changeMade: function changeMade() {
                var modalService = this.get('modalService');
                var key = this.get('key');
                var entity = this.get('entity');
                var attribute = this.get('attribute');
                var name = this.get('name');

                if (entity && attribute) {
                    var isFunction = _typeof(entity.get);
                    if (isFunction === 'function') {
                        if (!entity.get(attribute)) {
                            entity.set(attribute, []);
                        }
                        var exists = entity.get(attribute).find(function (x) {
                            return x === name;
                        });
                        if (!exists) {
                            entity.get(attribute).pushObject(name);
                        } else {
                            entity.get(attribute).removeObject(name);
                        }
                    }
                }

                if (!this.validateOnClick) {
                    this.doValidation();
                }

                if (this.onChange) {
                    this.onChange(name);
                }

                modalService.changeMade(key);

                if (this.get('changeAction')) {
                    this.get('changeAction')(entity);
                }
            }
        }
    });
});