define('matrix-frontend/middleware/cube', ['exports', 'matrix-frontend/actions/_types', 'npm:moment', 'matrix-frontend/actions/schedule-edit', 'matrix-frontend/actions/employee', 'matrix-frontend/actions/cube', 'matrix-frontend/actions/checklist', 'matrix-frontend/actions/payroll-worksheet', 'matrix-frontend/actions/reports', 'matrix-frontend/utils/state', 'matrix-frontend/store'], function (exports, _types, _npmMoment, _scheduleEdit, _employee, _cube, _checklist, _payrollWorksheet, _reports, _state, _store) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var doCancellation = function doCancellation(type) {
        var cubeLocation = (0, _state.getCubeLocation)();
        if (cubeLocation) {
            var cancellableRequests = _store.store.getState().general.cancellableRequests;

            var toCancel = Object.keys(cancellableRequests).reduce(function (o, i) {
                if (i !== cubeLocation && cancellableRequests[i] && cancellableRequests[i][type]) {
                    return [].concat(_toConsumableArray(o), _toConsumableArray(cancellableRequests[i][type]));
                }
                return o;
            }, []);
            toCancel.forEach(function (x) {
                return x.xhr.abort('foobar');
            });
        }
    };

    var beforeListeners = {};
    beforeListeners[_types.CUBE_SET_NODE] = function (store, payload) {
        store.dispatch({ type: _types.SE_RESET });
    };
    beforeListeners[_types.CUBE_SET_WEEK_END_DATE] = function (store, payload) {
        store.dispatch({ type: _types.SE_RESET });
    };
    beforeListeners[_types.CUBE_SET_PAGE] = function (store, payload) {};

    var afterListeners = {};
    afterListeners[_types.CUBE_GO_TO_DAY] = function (store, payload) {
        var _store$getState$cube = store.getState().cube,
            node = _store$getState$cube.node,
            page = _store$getState$cube.page;
        var _store$getState$cube2 = store.getState().cube,
            date = _store$getState$cube2.date,
            weekEndDate = _store$getState$cube2.weekEndDate;

        var deferDOTW = payload.deferDOTW;
        if (!deferDOTW) {
            store.dispatch({
                type: _types.SE_GO_TO_DAY,
                payload: { date: date, weekEndDate: weekEndDate }
            });
        }
    };

    afterListeners[_types.CUBE_SET_NODE] = function (store, payload) {
        var _store$getState$cube3 = store.getState().cube,
            node = _store$getState$cube3.node,
            page = _store$getState$cube3.page,
            pageInProgress = _store$getState$cube3.pageInProgress,
            targetName = _store$getState$cube3.targetName;
        var clients = store.getState().client.clients;


        if (!clients[node.clientCode]) {
            (0, _cube.initialClientLoad)(store);
        }

        (0, _cube.cubeReload)(store);
        // if (page === 'scheduleEdit' && pageInProgress !== 'scheduleEdit') {
        if (targetName === 'schedule.index') {
            (0, _scheduleEdit.scheduleEditReload)(store);
        } else if (targetName === 'employees.index') {
            (0, _employee.employeeReload)(store);
        } else if (targetName === 'new-checklist.index') {
            (0, _checklist.checklistReload)(store);
        } else if (targetName === 'new-payroll-worksheet.index') {
            (0, _payrollWorksheet.payrollWorksheetReload)(store);
        } else if (targetName === 'new-reports.index') {
            (0, _reports.reportsReload)(store);
        } else if (targetName === 'stores.edit') {
            (0, _cube.storeReload)(store);
        } /* else if (targetName === 'user-management.index') {
            userManagementReload(store)
          } */
        doCancellation('node');
    };

    // // TODO:
    // // - Figure out best places to call scheduleEditReload

    afterListeners[_types.CUBE_SET_WEEK_END_DATE] = function (store, payload) {
        var _store$getState$cube4 = store.getState().cube,
            node = _store$getState$cube4.node,
            page = _store$getState$cube4.page,
            pageInProgress = _store$getState$cube4.pageInProgress,
            targetName = _store$getState$cube4.targetName;


        (0, _cube.cubeReload)(store);
        // if (page === 'scheduleEdit' && pageInProgress !== 'scheduleEdit') {
        if (targetName === 'schedule.index') {
            (0, _scheduleEdit.scheduleEditReload)(store);
        } else if (targetName === 'employees.index') {
            (0, _employee.employeeReload)(store);
        } else if (targetName === 'new-checklist.index') {
            (0, _checklist.checklistReload)(store);
        } else if (targetName === 'new-payroll-worksheet.index') {
            (0, _payrollWorksheet.payrollWorksheetReload)(store);
        } else if (targetName === 'new-reports.index') {
            (0, _reports.reportsReload)(store);
        }
        doCancellation('date');
    };

    afterListeners[_types.CUBE_SET_PAGE] = function (store, payload) {
        var _store$getState$cube5 = store.getState().cube,
            node = _store$getState$cube5.node,
            page = _store$getState$cube5.page,
            pageInProgress = _store$getState$cube5.pageInProgress,
            targetName = _store$getState$cube5.targetName;

        if (targetName === 'schedule.index') {
            (0, _scheduleEdit.scheduleEditReload)(store);
            store.dispatch({ type: _types.SE_CHART_DRAW });
        } else if (targetName === 'employees.index') {
            (0, _employee.employeeReload)(store);
        } else if (targetName === 'user-management.index') {
            (0, _cube.userManagementReload)(store);
        } else if (targetName === 'rolescope-management.index') {
            (0, _cube.rolescopeManagementReload)(store);
        } else if (targetName === 'stores.edit') {
            (0, _cube.storeReload)(store);
        } else if (targetName === 'new-checklist.index') {
            (0, _checklist.checklistReload)(store);
        } else if (targetName === 'new-payroll-worksheet.index') {
            (0, _payrollWorksheet.payrollWorksheetReload)(store);
        } else if (targetName === 'new-reports.index') {
            (0, _reports.reportsReload)(store);
        }
        doCancellation('page');
    };

    afterListeners[_types.NOCLIENT_RELOAD_RSMANAGEMENT] = function (store, payload) {
        (0, _cube.rolescopeManagementReload)(store);
    };

    exports.default = function (store) {
        return function (next) {
            return function (action) {
                var type = action.type,
                    payload = action.payload;

                // Before listeners

                if (beforeListeners[type]) {
                    var ret = beforeListeners[type](store, payload);
                    if (ret !== undefined) {
                        if (ret === false) {
                            return;
                        } else {
                            action.payload = ret;
                        }
                    }
                }

                next(action);

                // After listeners
                if (afterListeners[type]) {
                    afterListeners[type](store, payload);
                }
            };
        };
    };
});