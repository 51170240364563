define('matrix-frontend/pods/components/websocket-notifier/component', ['exports', 'ember-redux'], function (exports, _emberRedux) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var stateToComputed = function stateToComputed(state) {
        return {
            status: state.websocket.status
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {};
    };

    var Comp = Ember.Component.extend({
        modalService: Ember.inject.service(),

        statusChanges: Ember.observer('status', function () {
            var status = this.get('status');
            // if (status === 0) {
            //     const modalService = this.get('modalService');
            //     modalService.open('websocket-connection');
            // }
        }),

        actions: {
            doReconnect: function doReconnect() {
                location.reload();
            }
        }
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});