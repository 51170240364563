define('matrix-frontend/components/schedule-unassigned-row-total', ['exports', 'react', 'matrix-frontend/actions/_types', 'ember-react-components', 'matrix-frontend/utils/time', 'matrix-frontend/config/environment', 'matrix-frontend/handlers/schedule-edit', 'matrix-frontend/store'], function (exports, _react, _types, _emberReactComponents, _time, _environment, _scheduleEdit, _store) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var deleteShift = function deleteShift(shift) {
        _store.store.dispatch({
            type: _types.SE_SUNSET_SHIFT,
            payload: { shift: shift, currentUser: _store.store.getState().user.currentUser }
        });
    };

    var ShiftTotal = function ShiftTotal(_ref) {
        var activitiesById = _ref.activitiesById,
            shift = _ref.shift,
            dotw = _ref.dotw,
            employees = _ref.employees,
            editMode = _ref.editMode,
            canEdit = _ref.canEdit,
            activeShift = _ref.activeShift;

        var total = shift.blocks.map(function (block) {
            var activityId = block.activityId;

            var activity = activitiesById[activityId];
            if (activity && !activity.isCallin) {
                return block.end.diff(block.start, 'hours', true);
            }
            return 0;
        }).reduce(function (o, i) {
            return o + i;
        }, 0);

        var rowClasses = ['row', 'unassigned-shifts-row', 'no-pad', 'total'];
        if (activeShift && shift.meta.id === activeShift.meta.id) {
            rowClasses.push('active');
        }
        var colClasses = ['column', 'number', 'f1', 'column-total', 'pointer', 'daily-shift'];
        if (activeShift && shift.meta.id !== activeShift.meta.id) {
            colClasses.push('no-employee');
        }

        var onTotalClick = canEdit ? function (_) {
            return (0, _scheduleEdit.unassignedClickHandler)({
                employee: null,
                dotw: dotw,
                shift: shift,
                employees: employees,
                editMode: editMode,
                canEdit: canEdit
            });
        } : null;

        return _react.default.createElement(
            'div',
            { className: rowClasses.join(' ') },
            _react.default.createElement(
                'div',
                {
                    className: colClasses.join(' '),
                    onClick: onTotalClick
                },
                total
            ),
            activeShift && canEdit ? _react.default.createElement(
                'div',
                { className: 'column f1 column-total' },
                _react.default.createElement(
                    'a',
                    {
                        href: '#',
                        className: 'button inline secondary',
                        onClick: function onClick() {
                            deleteShift(activeShift);
                        }
                    },
                    _react.default.createElement('i', { className: 'icon-trash' }),
                    ' Delete'
                )
            ) : _react.default.createElement('div', { className: 'column f1 column-total' })
        );
    };

    var unassignShift = function unassignShift(emoployee, dotw, activeShift) {
        _store.store.dispatch({
            type: _types.SE_DO_SWAP,
            payload: { employee: null, dotw: dotw, activeShift: activeShift }
        });
    };

    var comp = function comp(props) {
        var unassignedShifts = props.unassignedShifts,
            dotw = props.dotw,
            date = props.date,
            activeShift = props.activeShift;


        var dailyShifts = dotw && unassignedShifts && unassignedShifts[dotw] ? [].concat(_toConsumableArray(unassignedShifts[dotw])) : [];

        return _react.default.createElement(
            _react.default.Fragment,
            null,
            dailyShifts.map(function (k, i) {
                return _react.default.createElement(ShiftTotal, _extends({ key: i, shift: k }, props));
            }),
            dotw ? _react.default.createElement(
                'div',
                { className: 'row unassigned-shifts-row no-pad total' },
                activeShift ? _react.default.createElement('div', {
                    className: 'column f1 column-total pointer daily-shift no-employee no-border',
                    onClick: function onClick(_) {
                        return unassignShift(dotw, activeShift);
                    }
                }) : _react.default.createElement('div', { className: 'column f1 column-total daily-shift no-border' }),
                _react.default.createElement('div', { className: 'column f1 column-total' })
            ) : ''
        );
    };

    exports.default = (0, _emberReactComponents.default)(comp);
});