define('matrix-frontend/services/random-service', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _seed: 1,
        generate: function generate() {
            var seed = this.get('_seed');
            var value = Math.sin(seed) * 10000;
            this.setSeed(seed + 1);
            return value - Math.floor(value);
        },
        setSeed: function setSeed(x) {
            this.set('_seed', x);
        },
        choose: function choose(choices) {
            if (choices.length > 0) {
                var q = this.generate();
                return choices.sort(function () {
                    return q - 0.5;
                })[0];
            }
            return null;
        }
    });
});