define('matrix-frontend/pods/components/new-payroll-worksheet-page/component', ['exports', 'ember-redux', 'matrix-frontend/actions/_types', 'npm:moment', 'matrix-frontend/middleware/checklist', 'matrix-frontend/utils/payroll-worksheet', 'matrix-frontend/utils/state'], function (exports, _emberRedux, _types, _npmMoment, _checklist, _payrollWorksheet, _state) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var stateToComputed = function stateToComputed(state) {
        return {
            date: state.cube.date,
            node: state.cube.node,
            nodeStores: state.node.stores,
            nodeHierarchies: state.node.hierarchies,
            clientCode: state.cube.clientCode,
            weekEndDate: state.cube.weekEndDate,
            currentUser: state.user.currentUser,
            view: state.payrollWorksheet.view,
            subview: state.payrollWorksheet.subview,
            timeColumn: state.payrollWorksheet.timeColumn,
            hierarchies: state.node.gathered.hierarchies,
            reports: state.node.gathered.reports,
            clients: state.client.clients,
            pwWeeklyInfo: state.payrollWorksheet.weeklyInfo,
            todayIndex: state.payrollWorksheet.todayIndex,
            weeklyItems: state.checklist.weeklyItems,
            itemStoreitems: state.checklist.itemStoreitems,
            pwWeeklyInfoTimestamp: state.payrollWorksheet.payrollWorksheetTimestamp
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {
            // selectView: view => dispatch({
            //     type: types.PW_SET_VIEW,
            //     payload: {
            //         view,
            //     },
            // }),
            selectTimeColumn: function selectTimeColumn(timeColumn) {
                return dispatch({
                    type: _types.PW_SET_VIEW,
                    payload: {
                        timeColumn: timeColumn
                    }
                });
            }
        };
    };

    var formatTimestampToView = function formatTimestampToView(ts) {
        if (ts === null || ts === undefined) {
            return null;
        }
        var dt = ts.toLocaleString().split(', ')[1];

        var _dt$split = dt.split(' '),
            _dt$split2 = _slicedToArray(_dt$split, 2),
            time = _dt$split2[0],
            meridian = _dt$split2[1];

        var _time$split = time.split(':'),
            _time$split2 = _slicedToArray(_time$split, 3),
            hour = _time$split2[0],
            minute = _time$split2[1],
            second = _time$split2[2];

        var mtag = meridian[0].toLocaleLowerCase();
        return hour + ':' + minute + mtag;
    };

    var Comp = Ember.Component.extend({
        stateService: Ember.inject.service(),
        modalService: Ember.inject.service(),
        redux: Ember.inject.service(),

        viewTypes: [{
            id: 'summary',
            text: 'Summary'
        }, {
            id: 'detail',
            text: 'Detail'
        }],

        client: Ember.computed('clientCode', 'clients', function () {
            var client = this.clients[this.clientCode];
            return client ? client : null;
        }),

        weekInfo: Ember.computed('weekEndDate', 'pwWeeklyInfo', function () {
            var thisWeekEnd = this.weekEndDate.format('YYYY-MM-DD');
            return this.pwWeeklyInfo[thisWeekEnd];
        }),

        activitiesById: Ember.computed('client.activities', function () {
            var activities = this.get('client.activities');
            if (!activities) return null;
            return activities.reduce(function (o, a) {
                o[a.id] = a;
                return o;
            }, {});
        }),

        checklistItems: Ember.computed('itemStoreitems', 'weeklyItems', 'weekEndDate', 'node', function () {
            var fullTag = this.clientCode + '.' + this.node.tag;
            var weekItems = this.weeklyItems[this.weekEndDate.format('YYYY-MM-DD')] || {};
            var items = weekItems[fullTag];
            return items;
        }),

        items: Ember.computed('weekInfo', 'checklistItems', 'node', function () {
            var weekItems = this.get('weekInfo.items') || {};
            var checklistItems = this.get('checklistItems') || {};
            // console.info({checklistItems})
            return _extends({}, weekItems, checklistItems);
        }),

        storeitems: Ember.computed('itemStoreitems', 'checklistItems', function () {
            if (this.node.nodeType === 'store') {
                var fullTag = this.clientCode + '.' + this.node.tag;
                var now = _npmMoment.default.utc();
                var checklistItems = this.get('checklistItems');

                if (!checklistItems) return null;

                var value = {};

                var itemStoreitems = this.itemStoreitems;
                Object.keys(itemStoreitems).forEach(function (itemId) {
                    var item = checklistItems[itemId];
                    var storeitem = itemStoreitems[itemId].storeitems[fullTag];
                    if (item && storeitem) {

                        var startDate = _npmMoment.default.utc(storeitem.start_date_exception ? storeitem.start_date_exception : item.start_date);
                        var dueDate = _npmMoment.default.utc(storeitem.due_date_exception ? storeitem.due_date_exception : item.due_date);
                        var countedDueDate = dueDate.clone().add(34, 'hours');
                        var completedAt = storeitem.completed_at ? _npmMoment.default.utc(storeitem.completed_at) : null;

                        var status = void 0;
                        if (completedAt) {
                            status = !completedAt.isSameOrAfter(countedDueDate) ? 'COMPLETED' : 'LATE';
                        } else {
                            status = !now.isSameOrAfter(countedDueDate) ? 'INCOMPLETE' : 'OVERDUE';
                        }
                        storeitem.status = status;

                        storeitem.hours = storeitem.final_hours != null ? storeitem.final_hours : storeitem.schedule_hours != null ? storeitem.schedule_hours : storeitem.prelim_hours != null ? storeitem.prelim_hours : 0;

                        value[itemId] = storeitem;
                    }
                });
                return value;
            }
            return null;
        }),

        nodeHierarchy: Ember.computed('nodeHierarchies', 'nodeStores', 'node', function () {
            if (this.node.nodeType === 'store') {
                if (this.nodeStores == null) return null;
                return this.nodeStores[this.clientCode + '.' + this.node.tag];
            } else {
                if (this.nodeHierarchies == null) return null;
                return this.nodeHierarchies[this.clientCode + '.' + this.node.tag];
            }
        }),

        directChildren: Ember.computed('node', 'hierarchies', function () {
            var hierarchyData = this.get('hierarchies.data') || {};
            var hierarchy = hierarchyData[this.clientCode + '.' + this.node.tag];
            if (hierarchy) {
                return hierarchy.child_tags.map(function (childTag) {
                    return hierarchyData[childTag];
                }).filter(function (child) {
                    return child != undefined;
                });
            }
            return null;
        }),

        viewableScheduleStatuses: Ember.computed('currentUser', 'client', function () {
            return new Set(['hidden', 'executive', 'readonly', 'preliminary', 'active'].filter(function (x) {
                return (0, _state.hasScope)('schedule.store:' + x + '.view');
            }));
        }),

        scheduleStatuses: Ember.computed('node', 'nodeHierarchy', 'weekEndDate', function () {
            var nodeHierarchy = this.nodeHierarchy;
            var week = void 0,
                scheduleStatus = null;
            if (!(nodeHierarchy && (week = nodeHierarchy.weeks[this.weekEndDate.format('YYYY-MM-DD')]) && (scheduleStatus = week.scheduleStatus))) return null;
            return this.node.nodeType === 'store' ? _defineProperty({}, 'store' + nodeHierarchy.code, scheduleStatus.status.description) : scheduleStatus.byStore;
        }),

        stores: Ember.computed('node', 'hierarchies', 'viewableScheduleStatuses', 'scheduleStatuses', function () {
            var _this = this;

            var scheduleStatuses = this.scheduleStatuses;
            if (!scheduleStatuses) return null;
            var hierarchyData = this.get('hierarchies.data') || {};
            var getStores = function getStores(tag) {
                var hierarchy = hierarchyData[tag];
                if (hierarchy) {
                    if (hierarchy.type === 'store') {
                        var status = scheduleStatuses['store' + hierarchy.code];
                        if (_this.viewableScheduleStatuses.has(status)) return [hierarchy];
                    } else {
                        var stores = [];
                        hierarchy.child_tags.forEach(function (childTag) {
                            var childStores = getStores(childTag);
                            if (childStores) stores.push.apply(stores, _toConsumableArray(childStores));
                        });
                        return stores;
                    }
                }
                return null;
            };
            return getStores(this.clientCode + '.' + this.node.tag);
        }),

        // salesRollups: computed('client', 'node', 'hierarchies', 'weekEndDate', 'reports.salesactual', 'reports.salesforecast', 'reports.originalsalesforecast', function() {
        //     this.get('reports.salesactual')
        //     this.get('reports.salesforecast')
        //     this.get('reports.originalsalesforecast')

        //     // Must compute the following:
        //     // ==========================

        //     // each store
        //     // - actual sales
        //     // - ly sales
        //     // - trend in actual sales
        //     // - current forecast
        //     // - original forecast

        //     // each node
        //     // - sum actual sales
        //     // - sum trend
        //     // - sum current forecast
        //     // - sum ly sales
        //     // - sum original forecast
        //     // - variance actual sales vs original forecast)
        //     // - variance (trend vs original forecast)
        //     // - variance (current forecast vs original forecast)
        //     // - variance ((actual sales or current forecast) vs ly sales)
        //     // - variance ((actual sales or current forecast) vs original forecast)
        //     console.info('salesRollups CHANGED')

        //     return {}
        // }),

        // hoursRollups: computed('client', 'node', 'hierarchies', 'weekEndDate', 'reports.actuallabor', 'reports.schedulehours', 'reports.taskhours', function() {
        //     // Must compute the following:
        //     // ==========================
        //     this.get('reports.actuallabor')
        //     this.get('reports.schedulehours')
        //     this.get('reports.taskhours')
        //     // each store
        //     // - actual sales
        //     // - ly sales
        //     // - trend in actual sales
        //     // - current forecast
        //     // - original forecast

        //     // each node
        //     // - sum actual sales
        //     // - sum trend
        //     // - sum current forecast
        //     // - sum ly sales
        //     // - sum original forecast
        //     // - variance actual sales vs original forecast)
        //     // - variance (trend vs original forecast)
        //     // - variance (current forecast vs original forecast)
        //     // - variance ((actual sales or current forecast) vs ly sales)
        //     // - variance ((actual sales or current forecast) vs original forecast)
        //     console.info('hoursRollups CHANGED')

        //     return {}
        // }),


        reportsObserver: Ember.observer('reports', function () {
            // console.info('NEW REPORT DATA')
            var MIN_PERIOD = 200;

            // const currentTime = new Date().getTime()
            // const scheduledRefreshTime = (this.scheduledRefreshTime || currentTime)

            if (this.scheduledRefreshId != null) {
                Ember.run.cancel(this.scheduledRefreshId);
                // console.info('CANCELLING PREVIOUSLY SCHEDULED')
            }

            var self = this;
            var scheduledRefreshId = Ember.run.later(function () {
                // console.info(new Date().getTime())
                self.set('scheduledRefreshId', null);
                self.set('rollupDummyValue', Math.random());
            }, MIN_PERIOD);

            // this.set('scheduledRefreshTime', currentTime + MIN_PERIOD)
            this.set('scheduledRefreshId', scheduledRefreshId);
        }),

        infoRollups: Ember.computed('weekEndDate', 'node', 'hierarchies', 'scheduleStatuses', 'viewableScheduleStatuses', function () {
            var self = this;
            var fullTag = this.clientCode + '.' + this.node.tag;
            var scheduleStatuses = this.scheduleStatuses || {};

            var rollups = {};
            var viewableScheduleStatuses = this.viewableScheduleStatuses;

            var calcStoreRollup = function calcStoreRollup(tag) {
                var status = scheduleStatuses['store' + tag.split('.')[2]];
                var anyNotActive = status && status !== 'unknown' && status !== 'active' && viewableScheduleStatuses && viewableScheduleStatuses.has(status);

                var rollup = {
                    anyNotActive: anyNotActive
                };
                rollups[tag] = rollup;
                return rollup;
            };

            var calcRollup = function calcRollup(tag) {
                var rollup = void 0;

                var defaultRollup = {
                    anyNotActive: false
                };

                var hierarchy = self.hierarchies.data[tag];
                if (hierarchy) {
                    var childRollups = hierarchy.child_tags.map(function (childTag) {
                        var childHierarchy = self.hierarchies.data[childTag];
                        return rollups[childTag] || (childHierarchy.type === 'store' ? calcStoreRollup(childTag) : calcRollup(childTag));
                    });

                    if (childRollups.length == 0) {
                        rollup = defaultRollup;
                    }

                    childRollups.forEach(function (childRollup) {
                        if (childRollup !== undefined) {
                            if (!rollup) rollup = defaultRollup;
                            rollup.anyNotActive = rollup.anyNotActive || childRollup.anyNotActive;
                        }
                    });
                }

                rollups[tag] = rollup;
                return rollup;
            };

            // Recursively calculate rollups from current node
            if (this.node.nodeType === 'store') {
                calcStoreRollup(fullTag);
            } else {
                calcRollup(fullTag);
            }
            return rollups;
        }),

        currentPayrollcost: {},

        rollups: Ember.computed('client', 'node', 'hierarchies', 'rollupDummyValue', 'timeColumn', 'weekEndDate', 'scheduleStatuses', 'viewableScheduleStatuses', function () {
            var _this2 = this;

            var self = this;
            var rollups = {};

            var client = this.get('client') || {};

            var todayIndex = this.timeColumn == 'total' ? 7 : this.timeColumn;

            var todayDate = _npmMoment.default.utc().set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
            var isWeekInPast = todayDate.isAfter(this.weekEndDate, 'day');

            var thisWeekEnd = this.weekEndDate.format('YYYY-MM-DD');
            var lyWeekEnd = this.weekEndDate.clone().add(-52, 'weeks').format('YYYY-MM-DD');

            // undefined = loading
            // null = non-existent
            // otherwise = the data

            // console.info(this.reports)

            var docws = [].concat(_toConsumableArray(Array(7).keys()));

            var scheduleStatuses = this.scheduleStatuses;
            var viewableScheduleStatuses = this.viewableScheduleStatuses;
            if (!scheduleStatuses) return rollups;

            // SUPPORT OBJECTS / FUNCS
            // =======================

            var getStoreReportValue = function getStoreReportValue(fullTag, reportType, weekEnd, dataKey) {
                var thisReportData = void 0;
                var thisFocusData = void 0;
                var thisWeekData = void 0;
                if (reportType === 'payrollcost') {
                    if (Object.hasOwn(_this2.reports, reportType)) {
                        var reportTypeData = _this2.reports[reportType];
                        var _reportTypeData$day = reportTypeData.day,
                            day = _reportTypeData$day === undefined ? {} : _reportTypeData$day;
                        var _day$weekEnd = day[weekEnd],
                            weekEndData = _day$weekEnd === undefined ? {} : _day$weekEnd;
                        var _weekEndData$computed = weekEndData.computed,
                            computed = _weekEndData$computed === undefined ? {} : _weekEndData$computed;
                        var _weekEndData$distribu = weekEndData.distribution,
                            distribution = _weekEndData$distribu === undefined ? {} : _weekEndData$distribu;
                        var _computed$node$tag = computed[_this2.node.tag],
                            ret = _computed$node$tag === undefined ? null : _computed$node$tag;

                        if (dataKey === 'distribution') return distribution;
                        return ret;
                    }
                    return null;
                }
                try {
                    if (thisReportData = _this2.reports[reportType]) {
                        if (thisFocusData = thisReportData.day) {
                            if (thisWeekData = thisFocusData[weekEnd]) {
                                var thisTagData = thisWeekData.data[fullTag];
                                // has a value
                                if (thisTagData != null) {
                                    var mapped = {};
                                    thisTagData.forEach(function (x) {
                                        var docw = (0, _npmMoment.default)(x.date).diff(weekEnd, "days") + 6;
                                        mapped[docw] = x[dataKey];
                                    });
                                    return mapped;
                                }
                                // data has been loaded, but still no value
                                if (thisWeekData.complete || thisTagData !== undefined) {
                                    return null;
                                }
                            }
                        }
                    }
                } catch (e) {
                    console.error('error in getStoreReportValue :' + e);
                    return undefined;
                }
                // still loading
                return undefined;
            };

            // add taskhours to the nested structure 
            // and update totals at each point
            // - root.byBudget[budgetId].byTask[taskId].byItem[itemId]
            var addTaskHours = function addTaskHours(rootRef, budgetId, taskId, itemId, hours, hoursAfterIbc) {
                var budgetRef = rootRef.byBudget[budgetId] || {
                    total: 0,
                    byTask: {}
                };
                var taskRef = budgetRef.byTask[taskId] || {
                    total: 0,
                    byItem: {}
                };
                var itemRef = taskRef.byItem[itemId] || {
                    total: 0
                };
                itemRef.total += hours;
                taskRef.byItem[itemId] = itemRef;
                taskRef.total += hours;
                budgetRef.byTask[taskId] = taskRef;
                budgetRef.total += hoursAfterIbc;
                rootRef.byBudget[budgetId] = budgetRef;
                rootRef.total += hoursAfterIbc;
                return itemRef;
            };

            // combine/aggregate objects by their keys recursively
            // used for adding together multiple nested store hours objects
            var addObj = function addObj(src, dst) {
                // console.info(src, dst)
                // if(!src || !dst)
                //     return
                Object.keys(src).forEach(function (k) {
                    var type = _typeof(src[k]);
                    if (type === 'number') {
                        dst[k] = (dst[k] || 0) + src[k];
                    } else if (src[k] === null) {
                        if (dst[k] === undefined) {
                            dst[k] = null;
                        }
                    } else if (type === 'object') {
                        if (!dst[k]) {
                            dst[k] = {};
                        }
                        addObj(src[k], dst[k]);
                    }
                });
            };

            // for getting specific day value from report
            // report loading: src is undefined --> undefined
            // report loaded, missing: src is null --> null
            // report loaded, no data for day: src[key] is undefined or src[key] is null --> null
            // report loaded, has data for day: src[key] is X ---> X
            var extractValue = function extractValue(src, key) {
                // x == null returns true for both null and undefined... so shorthand way of doing (x === null || x === undefined)
                return src == null ? src : src[key] == null ? null : src[key];
            };

            // add value to another dict, keeping note of undefined and null
            var applyValue = function applyValue(dst, src, key) {
                if (src[key] === undefined) {
                    return;
                }
                if (src[key] === null && dst[key] === undefined) {
                    dst[key] = null;
                } else if (src[key] !== null) {
                    dst[key] = (dst[key] || 0) + src[key];
                }
            };

            // base object for comparing two metrics a and b...

            var Comparison = function () {
                function Comparison(a, b) {
                    _classCallCheck(this, Comparison);

                    this.reset();
                    if (a !== undefined && b !== undefined) this.add(a, b);
                }

                _createClass(Comparison, [{
                    key: 'reset',
                    value: function reset() {
                        this.a = null;
                        this.b = null;
                        this.excludedA = null;
                        this.excludedB = null;
                        this.countIncluded = 0;
                        this.countExcluded = 0;
                        this.flipped = false;
                    }
                }, {
                    key: 'add',
                    value: function add(a, b) {
                        if (a != null && b != null) {
                            if (this.a == null) this.a = 0;
                            if (this.b == null) this.b = 0;
                            this.a += a;
                            this.b += b;
                            this.countIncluded++;
                        } else {
                            if (a != null) {
                                if (this.excludedA == null) this.excludedA = 0;
                                this.excludedA += a;
                            }
                            if (b != null) {
                                if (this.excludedB == null) this.excludedB = 0;
                                this.excludedB += b;
                            }
                            this.countExcluded++;
                        }
                    }
                }, {
                    key: 'addFrom',
                    value: function addFrom(comp) {
                        applyValue(this, comp, 'a');
                        applyValue(this, comp, 'b');
                        applyValue(this, comp, 'excludedA');
                        applyValue(this, comp, 'excludedB');
                        applyValue(this, comp, 'countIncluded');
                        applyValue(this, comp, 'countExcluded');
                    }
                }, {
                    key: 'count',
                    value: function count() {
                        return this.countIncluded + this.countExcluded;
                    }
                }, {
                    key: 'factor',
                    value: function factor() {
                        return this.flipped ? -1 : 1;
                    }
                }, {
                    key: 'variance',
                    value: function variance() {
                        return this.a == null || this.b == null ? null : (this.a - this.b) * this.factor();
                    }
                }, {
                    key: 'percentVariance',
                    value: function percentVariance() {
                        if (this.a == null || this.b == null) return null;
                        return Math.min(this.a / this.b - 1, 99.999);
                    }
                }]);

                return Comparison;
            }();

            var CountedSum = function () {
                function CountedSum() {
                    _classCallCheck(this, CountedSum);

                    this.total = null;
                    this.count = 0;
                }

                _createClass(CountedSum, [{
                    key: 'add',
                    value: function add(x) {
                        var count = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

                        if (x != null) {
                            this.total = (this.total || 0) + x;
                            this.count += count;
                        }
                    }
                }, {
                    key: 'addFrom',
                    value: function addFrom(sum) {
                        this.add(sum.total, sum.count);
                    }
                }]);

                return CountedSum;
            }();

            // DATA STRUCTURE (FOR ALL DAYS AND WEEK)
            // ==============

            // EACH STORE
            // - sales
            //   - actual sales or current forecast
            //   - actual sales
            //   - ly sales
            //   - trend
            //   - current forecast
            //   - original forecast
            //   - actual or current VS original forecast
            //   - actual or current VS ly sales
            //   - current VS original forecast
            //   - actual VS original forecast
            //   - trend VS original forecast
            //   - category
            // - hours
            //   - actual
            //   - scheduled
            //   - actual or scheduled
            //   - planTest
            //     - total
            //     - byBudget
            //       - total
            //       - byTask
            //         - total
            //         - byItem
            //           - total
            //   - actual or scheduled VS plan
            //   - category
            // - payroll cost
            //   - cost
            //   - hours


            // EACH NODE ABOVE STORE
            // - sales
            //   - SUM actual sales
            //   - SUM ly sales
            //   - trend
            //     - SUM and # with actual
            //     - SUM and # with estimated trend
            //     - SUM of both categories above
            //   - SUM current forecast
            //   - SUM ly sales
            //   - SUM original forecast
            //   - SUM actual actual sales or original forecast
            //   - COMP (actual sales, original forecast)
            //   - COMP (current forecast, original forecast)
            //   - COMP (actual sales or current forecast, ly sales)
            //   - COMP (actual sales or current forecast, original forecast)
            //   - count of stores in each category
            // - payroll cost
            //   - SUM cost
            //   - SUM hours

            var USE_FLIPPED_PW_HOURS = (0, _state.getClientOption)('use_flipped_pw_hours', 'bool');
            // const HOURS_FACTOR = USE_FLIPPED_PW_HOURS ? -1 : 1


            var canViewStore = function canViewStore(tag) {
                var tagParts = tag.split('.');
                var key = tagParts.slice(1).join('');
                var status = scheduleStatuses[key];
                return scheduleStatuses && viewableScheduleStatuses.has(status);
            };

            var calcStoreRollup = function calcStoreRollup(tag) {
                var canView = canViewStore(tag);

                // GET STORE DATA FOR THE WEEK
                // ===========================
                var actual = canView ? getStoreReportValue(tag, 'salesactual', thisWeekEnd, 'sales') : null;
                var lyActual = canView ? getStoreReportValue(tag, 'salesactual', lyWeekEnd, 'sales') : null;
                var currentForecast = canView ? getStoreReportValue(tag, 'salesforecast', thisWeekEnd, 'sales') : null;
                var originalForecast = canView ? getStoreReportValue(tag, 'originalsalesforecast', thisWeekEnd, 'sales') : null;
                var actualLabor = canView ? getStoreReportValue(tag, 'actuallabor', thisWeekEnd, 'shift_total') : null;
                var scheduleHours = canView ? getStoreReportValue(tag, 'schedulehours', thisWeekEnd, 'hours') : null;
                var scheduleActivityHours = canView ? getStoreReportValue(tag, 'schedulehours', thisWeekEnd, 'hours_by_activity') : null;
                var taskHours = canView ? getStoreReportValue(tag, 'taskhours', thisWeekEnd, 'storehours') : null;
                var payrollcost = getStoreReportValue(tag, 'payrollcost', thisWeekEnd, 'payrollcost');
                var payrollCostActuals = getStoreReportValue(tag, 'payrollcost', thisWeekEnd, 'distribution');
                var misc = {};
                misc[(0, _npmMoment.default)(thisWeekEnd).format('YYYY-MM-DD').toString()] = {
                    reportTimeStamp: formatTimestampToView(_this2.pwWeeklyInfoTimestamp),
                    payrollCostActuals: payrollCostActuals
                };

                var rollup = {};
                var total = {
                    sales: {
                        trend: null,
                        actualOrCurrentVsOriginalForecast: new Comparison(),
                        actualOrCurrentVsLy: new Comparison(),
                        actualVsOriginalForecast: new Comparison(),
                        currentVsOriginalForecast: new Comparison(),
                        trendVsOriginalForecast: new Comparison()
                    },
                    hours: {
                        planTest: {
                            byActivity: {}
                        },
                        ibcApplied: null,
                        ibcAvailable: null,
                        ibcRemaining: null,
                        actualOrScheduleVsPlan: new Comparison(),
                        actualVsSchedule: new Comparison(),
                        scheduleVsPlan: new Comparison(),
                        scheduleByActivity: {}
                    },
                    payrollcost: {
                        cost: null,
                        hours: null
                    }

                    // CALCULATE IBC HOURS
                    // ===================

                };var ibcInitial = {
                    week: 0

                    // count up initial IBC hours
                };docws.forEach(function (docw) {
                    ibcInitial[docw] = 0;
                    if (taskHours && taskHours[docw]) {
                        taskHours[docw].forEach(function (sh) {
                            var task = client.tasks[sh.task];
                            if (task && task.isIbc) {
                                ibcInitial[task.isIbcByWeek ? 'week' : docw] += sh.hours;
                            }
                        });
                    }
                });

                var ibcRemaining = null;
                var isIbcByWeek = ibcInitial.week > 0;
                if (isIbcByWeek) {
                    ibcRemaining = ibcInitial.week;
                    total.hours.ibcAvailable = ibcRemaining;
                }

                var salesTrend = new Comparison();

                // COMBINE DATA FOR EACH DAY
                // =========================

                docws.forEach(function (docw) {

                    // SALES DATA
                    // ==========

                    // extract the report data for this day
                    var sales = {
                        actual: extractValue(actual, docw),
                        lyActual: extractValue(lyActual, docw),
                        currentForecast: extractValue(currentForecast, docw),
                        originalForecast: extractValue(originalForecast, docw)
                        // console.info(docw, sales)
                        // temporary to simulate calcuation for each day in the week
                        // if(docw >= todayIndex && sales.actual) {
                        //     sales.actual = null
                        // }

                        // missing data for previous weeks should be replaced with 0
                    };if (sales.actual === null && isWeekInPast && canView) {
                        sales.actual = 0;
                    }

                    // add to the trend
                    salesTrend.add(sales.actual, sales.originalForecast);

                    // get the actual sales or current forecast
                    sales.actualOrCurrent = sales.actual === null ? sales.currentForecast : sales.actual;

                    // create comps
                    sales.actualOrCurrentVsOriginalForecast = new Comparison(sales.actualOrCurrent, sales.originalForecast);
                    sales.actualOrCurrentVsLy = new Comparison(sales.actualOrCurrent, sales.lyActual);
                    sales.actualVsOriginalForecast = new Comparison(sales.actual, sales.originalForecast);
                    sales.currentVsOriginalForecast = new Comparison(sales.currentForecast, sales.originalForecast);

                    // apply to week total
                    applyValue(total.sales, sales, 'actual');
                    applyValue(total.sales, sales, 'lyActual');
                    applyValue(total.sales, sales, 'currentForecast');
                    applyValue(total.sales, sales, 'originalForecast');
                    applyValue(total.sales, sales, 'actualOrCurrent');

                    // apply to week comps
                    total.sales.actualOrCurrentVsOriginalForecast.add(sales.actualOrCurrent, sales.originalForecast);
                    total.sales.actualOrCurrentVsLy.add(sales.actualOrCurrent, sales.lyActual);
                    total.sales.actualVsOriginalForecast.add(sales.actual, sales.originalForecast);
                    total.sales.currentVsOriginalForecast.add(sales.currentForecast, sales.originalForecast);

                    // HOURS DATA
                    // ==========

                    // package hours data
                    var hours = {
                        actual: extractValue(actualLabor, docw),
                        schedule: extractValue(scheduleHours, docw),
                        planTest: {
                            total: null,
                            byBudget: {},
                            byActivity: {}
                        },
                        scheduleByActivity: {},
                        ibcApplied: null,
                        ibcAvailable: null,
                        ibcRemaining: null
                    };

                    var dayScheduleActivityHours = extractValue(scheduleActivityHours, docw);
                    if (dayScheduleActivityHours != null) {
                        hours.scheduleByActivity = _extends({}, dayScheduleActivityHours);
                    }

                    // temporary to simulate calcuation for each day in the week
                    // if(docw >= todayIndex && hours.actual) {
                    //     hours.actual = null
                    // }

                    // missing data for previous weeks should be replaced with 0
                    if (hours.actual === null && isWeekInPast && canView) {
                        hours.actual = 0;
                    }

                    // get actual hours or schedule hours 
                    hours.actualOrSchedule = hours.actual === null ? hours.schedule : hours.actual;

                    // determine IBC
                    if (isIbcByWeek) {
                        hours.ibcAvailable = ibcRemaining;
                        hours.ibcRemaining = ibcRemaining;
                    } else {
                        hours.ibcAvailable = ibcInitial[docw];
                        hours.ibcRemaining = ibcInitial[docw];
                    }
                    // console.info(docw, hours.scheduleVsPlanByActivity)

                    // apply hours on this day
                    if (taskHours && taskHours[docw]) {
                        // console.info(taskHours[docw])
                        if (client.budgets) {
                            hours.planTest.total = 0;
                            Object.keys(client.budgets).forEach(function (budgetId) {
                                hours.planTest.byBudget[budgetId] = {
                                    total: 0,
                                    byTask: {}
                                };
                            });
                        }
                        taskHours[docw].forEach(function (sh) {
                            var task = client.tasks[sh.task];
                            if (!task) return;
                            if (!task.isIbc) {
                                var useHours = sh.hours;
                                if (task.isCanIbc && sh.hours > 0) {
                                    var diff = hours.ibcRemaining - sh.hours;
                                    if (diff > 0) {
                                        hours.ibcRemaining -= sh.hours;
                                        useHours = 0;
                                    } else {
                                        hours.ibcRemaining = 0;
                                        useHours = -diff;
                                    }
                                }
                                addTaskHours(hours.planTest, sh.budget, sh.task, sh.item, sh.hours, useHours);
                                if (sh.activity != null) hours.planTest.byActivity[sh.activity] = (hours.planTest.byActivity[sh.activity] || 0) + useHours;
                                // console.info('remove', sh.activity, useHours)
                            } else {
                                addTaskHours(hours.planTest, sh.budget, sh.task, sh.item, sh.hours, 0);
                            }
                        });
                    }

                    // ibc calculations
                    hours.ibcApplied = hours.ibcAvailable - hours.ibcRemaining;
                    if (isIbcByWeek) {
                        ibcRemaining = hours.ibcRemaining;
                    }

                    // create comps
                    hours.actualOrScheduleVsPlan = new Comparison(hours.actualOrSchedule, hours.planTest.total);
                    hours.actualVsSchedule = new Comparison(hours.actual, hours.schedule);
                    hours.scheduleVsPlan = new Comparison(hours.schedule, hours.planTest.total);

                    // apply to week total
                    applyValue(total.hours, hours, 'actual');
                    applyValue(total.hours, hours, 'schedule');
                    applyValue(total.hours, hours, 'actualOrSchedule');
                    addObj(hours.planTest, total.hours.planTest);
                    addObj(hours.scheduleByActivity, total.hours.scheduleByActivity);
                    if (!isIbcByWeek) {
                        applyValue(total.hours, hours, 'ibcApplied');
                        applyValue(total.hours, hours, 'ibcAvailable');
                        applyValue(total.hours, hours, 'ibcRemaining');
                    }

                    // apply to week comps
                    total.hours.actualOrScheduleVsPlan.add(hours.actualOrSchedule, hours.planTest.total);
                    total.hours.actualVsSchedule.add(hours.actual, hours.schedule);
                    total.hours.scheduleVsPlan.add(hours.schedule, hours.planTest.total);

                    // PAYROLL COST DATA
                    // =================

                    var payrollcost = {
                        cost: 0,
                        hours: 0

                        // apply to week total
                    };applyValue(total.payrollcost, payrollcost, 'cost');
                    applyValue(total.payrollcost, payrollcost, 'hours');

                    // FINAL OUTPUT
                    // ============
                    rollup[docw] = {
                        sales: sales,
                        hours: hours,
                        payrollcost: payrollcost

                        //console.log(rollup)
                    };
                });

                // set IBC info for the week total
                if (isIbcByWeek) {
                    total.hours.ibcRemaining = ibcRemaining;
                    total.hours.ibcApplied = total.hours.ibcAvailable - ibcRemaining;
                }

                // trend factor = actual / original forecast (for days with both)
                var salesTrendFactor = salesTrend.a && salesTrend.b ? salesTrend.a / salesTrend.b : null;
                if (salesTrendFactor != null && !isWeekInPast) {
                    docws.forEach(function (docw) {
                        var sales = rollup[docw].sales;


                        // calc this day trend
                        var trend = sales.actual;
                        if (sales.actual == null) {
                            trend = salesTrendFactor * sales.originalForecast;
                        }
                        sales.trend = trend;
                        sales.trendVsOriginalForecast = new Comparison(trend, sales.originalForecast);

                        // apply to week trend
                        applyValue(total.sales, { trend: trend }, 'trend');
                        total.sales.trendVsOriginalForecast.add(trend, sales.originalForecast);
                    });
                }

                rollup.total = total;
                rollup.misc = {};

                // FINAL CATEGORIZATION FOR SUMMARY
                // ================================

                Object.keys(rollup).forEach(function (k) {
                    if (k !== 'misc') {
                        var _rollup$k = rollup[k],
                            sales = _rollup$k.sales,
                            hours = _rollup$k.hours;

                        sales.category = (0, _payrollWorksheet.getSalesVarianceCategory)(sales.actualOrCurrent, sales.originalForecast);
                        hours.category = (0, _payrollWorksheet.getHoursVarianceCategory)(hours.actualOrSchedule, hours.planTest.total);
                        // payrollcost.category = null;
                        hours.actualOrScheduleVsPlan.flipped = USE_FLIPPED_PW_HOURS;
                        hours.actualVsSchedule.flipped = USE_FLIPPED_PW_HOURS;
                        hours.scheduleVsPlan.flipped = USE_FLIPPED_PW_HOURS;
                    }
                });

                // ADD PAYROLLCOST DATA

                if (rollup !== null) {

                    // ADD PAYROLLCOST DATA
                    if (payrollcost !== null && payrollcost !== undefined && /^store\..*$/.test(_this2.node.tag)) {
                        var iteratePayrollcost = function iteratePayrollcost(item, payrollIterationIndex) {

                            if (payrollIterationIndex > 7) {
                                rollup.misc = misc;
                                return;
                            }
                            if (payrollIterationIndex === 7) {
                                rollup["total"].payrollcost.cost = payrollcostTotal;
                                // console.log("applying total hours")
                                rollup["total"].payrollcost.hours = payrollHoursTotal;
                                return;
                            }

                            if (payrollcost[newIndexKeys[payrollIterationIndex]].cost !== null && payrollcost[newIndexKeys[payrollIterationIndex]].cost !== undefined) {
                                applyValue(rollup[payrollIterationIndex].payrollcost, payrollcost[newIndexKeys[payrollIterationIndex]], "cost");

                                applyValue(rollup[payrollIterationIndex].payrollcost, payrollcost[newIndexKeys[payrollIterationIndex]], "hours");

                                payrollcostTotal = payrollcostTotal + payrollcost[newIndexKeys[payrollIterationIndex]].cost;

                                payrollHoursTotal = payrollHoursTotal + payrollcost[newIndexKeys[payrollIterationIndex]].hours;

                                payrollIterationIndex++;
                            } else {
                                applyValue(rollup[payrollIterationIndex].payrollcost, 0, "cost");
                                applyValue(rollup[payrollIterationIndex].payrollcost, 0, "hours");
                                payrollIterationIndex++;
                            }
                        };

                        var payrollIterationIndex = 0;
                        var payrollcostTotal = 0;
                        var payrollHoursTotal = 0;

                        var newIndexKeys = [];
                        for (var i = 0; i < 7; i++) {
                            var dt = (0, _npmMoment.default)(_this2.weekEndDate, "YYYY-MM-DD").startOf('day').add(-6 + i, 'days').format("YYYY-MM-DD");
                            newIndexKeys.push(dt.toString());
                        }

                        Object.keys(rollup).forEach(iteratePayrollcost);
                    }
                }

                misc.timeStamp = (0, _npmMoment.default)().format('h:mma').toString();

                // OUTPUT
                // ======
                rollups[tag] = rollup;
                return rollup;
            };

            var hierarchyData = this.get('hierarchies.data') || {};

            var calcNodeRollup = function calcNodeRollup(tag) {
                var rollup = null;
                var hierarchy = hierarchyData[tag];
                if (hierarchy) {

                    var defaultEntry = function defaultEntry() {
                        var isTotal = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

                        return !isTotal ? {
                            sales: {
                                // basic sums
                                currentForecast: null,
                                originalForecast: null,

                                // counted sums
                                actual: new CountedSum(),
                                currentForecastNoActual: new CountedSum(),
                                trend: new CountedSum(),
                                lyActual: new CountedSum(),

                                actualOrCurrent: function actualOrCurrent() {
                                    if (this.actual.total == null && this.currentForecastNoActual.total == null) return null;
                                    return (this.actual.total || 0) + (this.currentForecastNoActual.total || 0);
                                },
                                trendValue: function trendValue() {
                                    if (isWeekInPast) return null;
                                    if (this.actual.total == null && this.trend.total == null) return null;
                                    return (this.actual.total || 0) + (this.trend.total || 0);
                                },


                                // comparisons
                                actualOrCurrentVsOriginalForecast: new Comparison(),
                                actualVsOriginalForecast: new Comparison(),
                                trendVsOriginalForecast: new Comparison(),
                                currentVsOriginalForecast: new Comparison(),
                                actualOrCurrentVsLy: new Comparison(),

                                categoryCounts: {}
                            },
                            hours: {
                                schedule: null,
                                actual: new CountedSum(),
                                scheduleNoActual: new CountedSum(),

                                actualOrSchedule: function actualOrSchedule() {
                                    if (this.actual.total == null && this.scheduleNoActual.total == null) return null;
                                    return (this.actual.total || 0) + (this.scheduleNoActual.total || 0);
                                },


                                planTest: {},
                                scheduleByActivity: {},
                                actualOrScheduleVsPlan: new Comparison(),
                                actualVsSchedule: new Comparison(),
                                scheduleVsPlan: new Comparison(),
                                categoryCounts: {}
                            },
                            payrollcost: {
                                // basic sums
                                cost: 0,
                                hours: 0
                            },
                            storeCount: 0
                        } : {
                            sales: {
                                // basic sums
                                currentForecast: null,
                                originalForecast: null,
                                actual: null,
                                actualOrCurrent: null,
                                trend: null,
                                lyActual: null,

                                // comparisons
                                actualOrCurrentVsOriginalForecast: new Comparison(),
                                actualVsOriginalForecast: new Comparison(),
                                trendVsOriginalForecast: new Comparison(),
                                currentVsOriginalForecast: new Comparison(),
                                actualOrCurrentVsLy: new Comparison(),

                                categoryCounts: {}
                            },
                            hours: {
                                actual: null,
                                schedule: null,
                                actualOrSchedule: null,

                                planTest: {},
                                scheduleByActivity: {},
                                actualOrScheduleVsPlan: new Comparison(),
                                actualVsSchedule: new Comparison(),
                                scheduleVsPlan: new Comparison(),
                                categoryCounts: {}
                            },
                            payrollcost: {
                                // basic sums
                                cost: 0,
                                hours: 0
                            },
                            storeCount: 0
                        };
                    };

                    var applyChildData = function applyChildData(parent, child, childIsStore) {
                        // basic sums
                        applyValue(parent.sales, child.sales, 'currentForecast');
                        applyValue(parent.sales, child.sales, 'originalForecast');

                        if (childIsStore) {
                            // actual sales
                            if (child.sales.actual != null) {
                                parent.sales.actual.add(child.sales.actual);
                            } else {
                                parent.sales.currentForecastNoActual.add(child.sales.currentForecast);
                                if (!isWeekInPast) {
                                    parent.sales.trend.add(child.sales.trend);
                                }
                            }
                            parent.sales.lyActual.add(child.sales.lyActual);

                            // actual hours
                            if (child.hours.actual != null) {
                                parent.hours.actual.add(child.hours.actual);
                            } else {
                                parent.hours.scheduleNoActual.add(child.hours.schedule);
                            }
                        } else {
                            // actual sales
                            parent.sales.actual.addFrom(child.sales.actual);
                            parent.sales.currentForecastNoActual.addFrom(child.sales.currentForecastNoActual);
                            if (!isWeekInPast) {
                                parent.sales.trend.addFrom(child.sales.trend);
                            }
                            parent.sales.lyActual.addFrom(child.sales.lyActual);

                            // actual hours
                            parent.hours.actual.addFrom(child.hours.actual);
                            parent.hours.scheduleNoActual.addFrom(child.hours.scheduleNoActual);
                        }

                        // comparisons
                        parent.sales.actualOrCurrentVsOriginalForecast.addFrom(child.sales.actualOrCurrentVsOriginalForecast);
                        parent.sales.actualVsOriginalForecast.addFrom(child.sales.actualVsOriginalForecast);
                        parent.sales.currentVsOriginalForecast.addFrom(child.sales.currentVsOriginalForecast);
                        parent.sales.actualOrCurrentVsLy.addFrom(child.sales.actualOrCurrentVsLy);

                        if (child.sales.trendVsOriginalForecast) {
                            parent.sales.trendVsOriginalForecast.addFrom(child.sales.trendVsOriginalForecast);
                        }

                        applyValue(parent.hours, child.hours, 'ibcApplied');
                        applyValue(parent.hours, child.hours, 'ibcAvailable');
                        applyValue(parent.hours, child.hours, 'ibcRemaining');
                        applyValue(parent.hours, child.hours, 'schedule');
                        addObj(child.hours.planTest, parent.hours.planTest);
                        addObj(child.hours.scheduleByActivity, parent.hours.scheduleByActivity);
                        parent.hours.actualOrScheduleVsPlan.addFrom(child.hours.actualOrScheduleVsPlan);
                        parent.hours.actualVsSchedule.addFrom(child.hours.actualVsSchedule);
                        parent.hours.scheduleVsPlan.addFrom(child.hours.scheduleVsPlan);
                    };

                    var applyCategories = function applyCategories(parent, child, childIsStore) {
                        if (childIsStore) {
                            parent.storeCount += 1;
                            if (child.sales.category !== undefined) {
                                var categoryKey = child.sales.category ? child.sales.category.key : null;
                                parent.sales.categoryCounts[categoryKey] = (parent.sales.categoryCounts[categoryKey] || 0) + 1;
                            }
                            if (child.hours.category !== undefined) {
                                var _categoryKey = child.hours.category ? child.hours.category.key : null;
                                parent.hours.categoryCounts[_categoryKey] = (parent.hours.categoryCounts[_categoryKey] || 0) + 1;
                            }
                            //if(child.payrollcost.category !== undefined) {
                            //    const categoryKey = child.payrollcost.category ? child.payrollcost.category.key : null
                            //    parent.payrollcost.categoryCounts[categoryKey] = (parent.payrollcost.categoryCounts[categoryKey] || 0) + 1
                            //}
                        } else {
                            parent.storeCount += child.storeCount;
                            Object.keys(child.sales.categoryCounts).forEach(function (category) {
                                applyValue(parent.sales.categoryCounts, child.sales.categoryCounts, category);
                            });
                            Object.keys(child.hours.categoryCounts).forEach(function (category) {
                                applyValue(parent.hours.categoryCounts, child.hours.categoryCounts, category);
                            });
                            //Object.keys(child.payrollcost.categoryCounts).forEach(category => {
                            //    applyValue(child.payrollcost.categoryCounts, child.payrollcost.categoryCounts, category)
                            //})
                        }
                    };

                    rollup = {};
                    var total = defaultEntry(true);

                    // make default entries for each day
                    docws.forEach(function (docw) {
                        rollup[docw] = defaultEntry();
                    });

                    // apply children to this rollup
                    hierarchy.child_tags.forEach(function (childTag) {
                        var childHierarchy = hierarchyData[childTag];
                        var childIsStore = childHierarchy.type === 'store';
                        if (childIsStore && !canViewStore(childTag)) return;
                        var childRollup = childIsStore ? calcStoreRollup(childTag) : calcNodeRollup(childTag);
                        if (!childRollup) return;
                        // add child information by day
                        docws.forEach(function (docw) {
                            var thisDay = rollup[docw] || defaultEntry();
                            applyChildData(thisDay, childRollup[docw], childIsStore);
                            applyCategories(thisDay, childRollup[docw], childIsStore);
                            rollup[docw] = thisDay;
                        });
                        applyCategories(total, childRollup.total, childIsStore);
                        applyValue(total.hours, childRollup.total.hours, 'ibcApplied');
                        applyValue(total.hours, childRollup.total.hours, 'ibcAvailable');
                        applyValue(total.hours, childRollup.total.hours, 'ibcRemaining');
                    });

                    // apply to week total
                    docws.forEach(function (docw) {
                        var data = rollup[docw];
                        total.storeCount = data.storeCount;

                        // SALES
                        // =====

                        // basic sums
                        applyValue(total.sales, data.sales, 'currentForecast');
                        applyValue(total.sales, data.sales, 'originalForecast');
                        applyValue(total.sales, { actual: data.sales.actual.total }, 'actual');
                        applyValue(total.sales, { actualOrCurrent: data.sales.actualOrCurrent() }, 'actualOrCurrent');
                        applyValue(total.sales, { lyActual: data.sales.lyActual.total }, 'lyActual');
                        applyValue(total.sales, { trend: data.sales.trendValue() }, 'trend');

                        // comparisons
                        total.sales.actualOrCurrentVsOriginalForecast.addFrom(data.sales.actualOrCurrentVsOriginalForecast);
                        total.sales.actualVsOriginalForecast.addFrom(data.sales.actualVsOriginalForecast);
                        total.sales.currentVsOriginalForecast.addFrom(data.sales.currentVsOriginalForecast);
                        total.sales.actualOrCurrentVsLy.addFrom(data.sales.actualOrCurrentVsLy);
                        total.sales.trendVsOriginalForecast.addFrom(data.sales.trendVsOriginalForecast);

                        // HOURS
                        // =====

                        // basic sums
                        applyValue(total.hours, data.hours, 'schedule');
                        applyValue(total.hours, { actual: data.hours.actual.total }, 'actual');
                        applyValue(total.hours, { actualOrSchedule: data.hours.actualOrSchedule() }, 'actualOrSchedule');

                        // nested plan hours
                        addObj(data.hours.planTest, total.hours.planTest);
                        addObj(data.hours.scheduleByActivity, total.hours.scheduleByActivity);

                        // comparisons
                        total.hours.actualOrScheduleVsPlan.addFrom(data.hours.actualOrScheduleVsPlan);
                        total.hours.actualVsSchedule.addFrom(data.hours.actualVsSchedule);
                        total.hours.scheduleVsPlan.addFrom(data.hours.scheduleVsPlan);
                    });

                    rollup.total = total;
                    rollup["misc"] = {};
                    Object.keys(rollup).forEach(function (k) {
                        if (k !== "misc") {
                            var _rollup$k2 = rollup[k],
                                sales = _rollup$k2.sales,
                                hours = _rollup$k2.hours;


                            sales.numValid = rollup[k].storeCount - (sales.categoryCounts.total || 0);
                            hours.numValid = rollup[k].storeCount - (hours.categoryCounts.total || 0);
                            hours.actualOrScheduleVsPlan.flipped = USE_FLIPPED_PW_HOURS;
                            hours.actualVsSchedule.flipped = USE_FLIPPED_PW_HOURS;
                            hours.scheduleVsPlan.flipped = USE_FLIPPED_PW_HOURS;
                        }
                    });
                }

                if (rollup && rollup.total && rollup.total.storeCount == 0) {
                    rollup = null;
                }

                var payrollcost = getStoreReportValue(tag, 'payrollcost', thisWeekEnd, 'payrollcost');
                var payrollCostActuals = getStoreReportValue(tag, 'payrollcost', thisWeekEnd, 'distribution');

                var misc = {};
                misc[(0, _npmMoment.default)(thisWeekEnd).format('YYYY-MM-DD').toString()] = {
                    reportTimeStamp: formatTimestampToView(_this2.pwWeeklyInfoTimestamp),
                    payrollCostActuals: payrollCostActuals
                };

                if (rollup !== null) {

                    // ADD PAYROLLCOST DATA

                    if (payrollcost !== null && payrollcost !== undefined) {
                        var iteratePayrollcost = function iteratePayrollcost(item, payrollIterationIndex) {

                            if (payrollIterationIndex > 7) {
                                rollup.misc = misc;
                                return;
                            }
                            if (payrollIterationIndex === 7) {
                                rollup["total"].payrollcost.cost = payrollcostTotal;
                                rollup["total"].payrollcost.hours = payrollHoursTotal;
                                return;
                            }

                            if (payrollcost[newIndexKeys[payrollIterationIndex]] !== null && payrollcost[newIndexKeys[payrollIterationIndex]] !== undefined) {
                                applyValue(rollup[payrollIterationIndex].payrollcost, payrollcost[newIndexKeys[payrollIterationIndex]], "cost");

                                applyValue(rollup[payrollIterationIndex].payrollcost, payrollcost[newIndexKeys[payrollIterationIndex]], "hours");

                                payrollcostTotal = payrollcostTotal + payrollcost[newIndexKeys[payrollIterationIndex]].cost;

                                payrollHoursTotal = payrollHoursTotal + payrollcost[newIndexKeys[payrollIterationIndex]].hours;

                                payrollIterationIndex++;
                            } else {
                                console.info('report does not exist for ' + newIndexKeys[payrollIterationIndex] + '. Inserting default value 0.');
                                applyValue(rollup[payrollIterationIndex].payrollcost, 0, "cost");
                                applyValue(rollup[payrollIterationIndex].payrollcost, 0, "hours");
                                payrollIterationIndex++;
                            }
                        };

                        var payrollIterationIndex = 0;
                        var payrollcostTotal = 0;
                        var payrollHoursTotal = 0;
                        var newIndexKeys = [];
                        for (var i = 0; i < 7; i++) {
                            var dt = (0, _npmMoment.default)(_this2.weekEndDate, "YYYY-MM-DD").startOf('day').add(-6 + i, 'days').format("YYYY-MM-DD");
                            newIndexKeys.push(dt.toString());
                        }

                        Object.keys(rollup).forEach(iteratePayrollcost);
                    }
                }

                misc.timeStamp = (0, _npmMoment.default)().format('h:mma').toString();

                rollups[tag] = rollup;
                return rollup;
            };

            if (this.node.nodeType !== 'store') {
                calcNodeRollup(this.clientCode + '.' + this.node.tag);
            } else {
                calcStoreRollup(this.clientCode + '.' + this.node.tag);
            }

            return rollups;
        }),

        singleDayRollups: Ember.computed('rollups', 'timeColumn', function () {
            var _this3 = this;

            var rollups = this.get('rollups');
            var cleaned = {};
            Object.keys(rollups).forEach(function (tag) {
                cleaned[tag] = rollups[tag] ? rollups[tag][_this3.timeColumn] : rollups[tag];
            });
            return cleaned;
        }),

        rollup: Ember.computed('rollups', 'node', function () {
            var rollups = this.get('rollups');
            return rollups[this.clientCode + '.' + this.node.tag];
        }),

        infoRollup: Ember.computed('infoRollups', 'node', function () {
            var infoRollups = this.get('infoRollups');
            return infoRollups[this.clientCode + '.' + this.node.tag];
        }),

        timeColumns: Ember.computed('weekEndDate', function () {
            var _this4 = this;

            var shortDayNames = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
            var columns = [].concat(_toConsumableArray(Array(7).keys())).map(function (dotw) {
                var dt = (0, _npmMoment.default)(_this4.weekEndDate).startOf('day').add(-6 + dotw, 'days');
                return {
                    shortText: shortDayNames[dt.day()],
                    text: dt.format('ddd') + ' ' + (dt.month() + 1) + '/' + dt.date(),
                    key: dotw
                };
            });
            return [].concat(_toConsumableArray(columns), [{
                shortText: 'Wk',
                text: 'Week',
                key: 'total'
            }]);
        }),

        isTimeSelectorVisible: Ember.computed('view', function () {
            return this.view === 'summary';
        }),

        actions: {
            selectView: function selectView(view) {
                this.router.transitionTo({
                    queryParams: {
                        view: view
                    }
                });
            },
            selectSubview: function selectSubview(subview) {
                this.router.transitionTo({
                    queryParams: {
                        subview: subview
                    }
                });
            }
        }
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});