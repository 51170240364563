define('matrix-frontend/utils/employee-rules', ['exports', 'npm:moment', 'matrix-frontend/utils/cleaners', 'matrix-frontend/utils/date-helper'], function (exports, _npmMoment, _cleaners, _dateHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.employeeMissingSkill = exports.shiftAvailabilityConflict = undefined;


    var checkAvailability = function checkAvailability(availability, shift) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
            for (var _iterator = availability[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var a = _step.value;

                if (a.isAllDay) {
                    return false;
                }
                var _iteratorNormalCompletion2 = true;
                var _didIteratorError2 = false;
                var _iteratorError2 = undefined;

                try {
                    for (var _iterator2 = shift.blocks[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                        var b = _step2.value;

                        // Using moment.js
                        // const aStart = moment(activeShift.meta.date)
                        //     .hours(a.start.hours())
                        //     .minutes(a.start.minutes())
                        // const aEnd = moment(activeShift.meta.date)
                        //     .hours(a.end.hours())
                        //     .minutes(a.end.minutes())
                        // if (
                        //     ((a.fromOpen || aStart.isBefore(b.end)) &&
                        //         (a.untilClose || aEnd.isAfter(b.start)))
                        // ) {
                        //     return false
                        // }

                        // Using raw date objects
                        var aStart = new Date(shift.meta.date.year(), shift.meta.date.month(), shift.meta.date.date(), a.start.hours(), a.start.minutes());
                        var aEnd = new Date(shift.meta.date.year(), shift.meta.date.month(), shift.meta.date.date(), a.end.hours(), a.end.minutes());

                        if ((a.fromOpen || aStart < b.end._d) && (a.untilClose || aEnd > b.start._d)) {
                            return false;
                        }
                    }
                } catch (err) {
                    _didIteratorError2 = true;
                    _iteratorError2 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion2 && _iterator2.return) {
                            _iterator2.return();
                        }
                    } finally {
                        if (_didIteratorError2) {
                            throw _iteratorError2;
                        }
                    }
                }
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally {
            try {
                if (!_iteratorNormalCompletion && _iterator.return) {
                    _iterator.return();
                }
            } finally {
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }

        return true;
    };

    /////////////////////////////////////////
    ///             Rule #101             ///
    /////////////////////////////////////////
    var shiftAvailabilityConflict = exports.shiftAvailabilityConflict = function shiftAvailabilityConflict(shift, employee) {
        var error = null;
        var isAvailable = true;
        var dotw = (0, _dateHelper.getDayOfClientWeek)(shift.meta.date) + 1;

        var availability = employee.availability.exceptions && employee.availability.exceptions[dotw] ? employee.availability.exceptions[dotw] : employee.availability.default[dotw];
        // if (employee.id ===  95217 && dotw === 1) {
        //     // console.group(`shiftAvailabilityConflict ${dotw}`)
        //     console.log("shift date", shift.meta.date.format('YYYY-MM-DD'))
        //     console.log("employee", employee)
        //     console.log("availability", availability)
        //     // console.log("isAvailable", isAvailable)
        //     console.groupEnd(`shiftAvailabilityConflict ${dotw}`)
        // }
        // availability = availability.map(a => {
        //     // // const start = moment(`${shift.meta.date.format('YYYY-MM-DD')} ${a.start}`);
        //     // // const end = moment(`${shift.meta.date.format('YYYY-MM-DD')} ${a.end}`);
        //     // const start = `${shift.meta.date.format('YYYY-MM-DD')} ${a.start}`
        //     // const end = `${shift.meta.date.format('YYYY-MM-DD')} ${a.end}`
        //     const start = shift.meta.date.clone().set({
        //         hours: a.start.hours(),
        //         minutes: a.start.minutes(),
        //     })
        //     const end = shift.meta.date.clone().set({
        //         hours: a.end.hours(),
        //         minutes: a.end.minutes(),
        //     })
        //     a = {
        //         ...a,
        //         start,
        //         end,
        //     }
        //     return a
        // })

        // availability = momentize(availability)
        //     .filter(x => x.isAvailable)
        //     .sort((j, k) => k.start.isBefore(j.start))
        availability = availability.filter(function (x) {
            return !x.isAvailable;
        }).sort(function (j, k) {
            return k.start.isBefore(j.start);
        });

        if (employee.terminationDate) {
            if (shift.meta.date.isAfter(employee.terminationDate, 'day')) {
                isAvailable = false;
            }
        }
        if (employee.startDate) {
            if (shift.meta.date.isBefore(employee.startDate, 'day')) {
                isAvailable = false;
            }
        }
        if (isAvailable) {
            if (availability.length === 0) {
                return null;
            }
            // if (!availability || availability.length === 0) {
            //     isAvailable = false
            // } else {
            //     const blockAvailability = shift.blocks.map(x => {
            //         let isOkay = true
            //         availability.forEach(a => {
            //             if (
            //                 (!a.fromOpen && x.start.isBefore(a.start)) ||
            //                 (!a.untilClose && x.end.isAfter(a.end))
            //             ) {
            //                 isOkay = false
            //             } else {
            //                 isOkay = a.isAvailable
            //             }
            //         })
            //         return isOkay
            //     })
            //     // if (blockAvailability.includes(false)) {
            //     //     console.log(blockAvailability)
            //     //     console.log(availability)
            //     //     console.log(shift)
            //     // }
            //     isAvailable = !blockAvailability.includes(false)
            // }
            isAvailable = checkAvailability(availability, shift);
        }

        if (!isAvailable) {
            error = {
                number: 101,
                severity: 1,
                msg: 'Employee has an availability conflict with an assigned shift'
            };
        }

        return error;
    };

    /////////////////////////////////////////
    ///             Rule #102             ///
    /////////////////////////////////////////
    var employeeMissingSkill = exports.employeeMissingSkill = function employeeMissingSkill(shift, employee) {
        var error = null;
        var eeSkills = employee.skills.reduce(function (o, i) {
            o[i.activity] = i.level;
            return o;
        }, {});
        var hasSkills = eeSkills.length === 0 ? false : shift.blocks.every(function (block) {
            // console.log(eeSkills[block.activityId] && eeSkills[block.activityId] > 0)
            return block.activityId <= 1 ? true : eeSkills[block.activityId] && eeSkills[block.activityId] > 0;
        });

        if (!hasSkills) {
            error = {
                number: 102,
                severity: -1,
                msg: 'Employee does not meet skill requirements for the shift'
            };
        }

        return error;
    };
});