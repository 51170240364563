define('matrix-frontend/models/activity', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        nameShort: _emberData.default.attr('string'),
        budget: _emberData.default.belongsTo('budget'),
        colorHexRGB: _emberData.default.attr('string'),
        orderIndex: _emberData.default.attr('number'),
        activityPriority: _emberData.default.attr('number'),
        isScheduled: _emberData.default.attr('string'),
        tasks: _emberData.default.hasMany('task', { async: true })
    });
});