define('matrix-frontend/services/save-service', ['exports', 'matrix-frontend/actions/_types'], function (exports, _types) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Service.extend({
        valueChanged: false,
        awaitingConfirm: false,
        loadingService: Ember.inject.service(),
        redux: Ember.inject.service(),
        onDeck: {}, // eslint-disable-line ember/avoid-leaking-state-in-ember-objects

        doTransition: function doTransition(transition) {
            this.set('onDeck.transition', transition);
            if (this.get('valueChanged')) {
                transition.abort();
                this.set('awaitingConfirm', true);
                this.get('loadingService').endLoading();
            } else {
                this.set('valueChanged', false);
                this.set('awaitingConfirm', false);
            }
        },
        doAction: function doAction(method) {
            this.set('onDeck.method', method);

            for (var _len = arguments.length, params = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
                params[_key - 1] = arguments[_key];
            }

            this.set('onDeck.params', params);
            if (this.get('valueChanged')) {
                this.get('redux').dispatch({
                    type: _types.CUBE_TOGGLE_MULTI_USER_SAVE_MODE,
                    payload: {
                        force: true
                    }
                });
                this.set('awaitingConfirm', true);
            } else {
                this.set('valueChanged', false);
                this.set('awaitingConfirm', false);
                this.proceed(true);
            }
        },
        proceed: function proceed() {
            var forceAction = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
            var forceMethod = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

            if (this.get('onDeck.transition') && !forceAction) {
                this.proceedTransition();
            } else if (this.get('onDeck.method') && !forceMethod) {
                this.proceedMethod();
            } else {
                if (this.get('onDeck.method')) {
                    this.proceedMethod();
                } else {
                    this.proceedTransition();
                }
            }
            this.reset();
        },
        proceedMethod: function proceedMethod() {
            var _get;

            (_get = this.get('onDeck')).method.apply(_get, _toConsumableArray(this.get('onDeck').params));
            this.reset();
        },
        proceedTransition: function proceedTransition() {
            this.get('onDeck.transition').retry();
            this.reset();
        },
        reset: function reset() {
            this.set('onDeck', {});
        }
    });
});