define('matrix-frontend/components/react-only/heatmap-layer', ['exports', 'react', 'lodash', 'npm:leaflet', 'npm:react-leaflet'], function (exports, _react, _lodash2, _npmLeaflet, _npmReactLeaflet) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    var _get = function get(object, property, receiver) {
        if (object === null) object = Function.prototype;
        var desc = Object.getOwnPropertyDescriptor(object, property);

        if (desc === undefined) {
            var parent = Object.getPrototypeOf(object);

            if (parent === null) {
                return undefined;
            } else {
                return get(parent, property, receiver);
            }
        } else if ("value" in desc) {
            return desc.value;
        } else {
            var getter = desc.get;

            if (getter === undefined) {
                return undefined;
            }

            return getter.call(receiver);
        }
    };

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var map = _lodash2.default.map,
        min = _lodash2.default.min,
        max = _lodash2.default.max,
        isNumber = _lodash2.default.isNumber,
        reduce = _lodash2.default.reduce,
        filter = _lodash2.default.filter;
    var MapLayer = _npmReactLeaflet.default.MapLayer,
        withLeaflet = _npmReactLeaflet.default.withLeaflet;


    function isInvalid(num) {
        return !isNumber(num) && !num;
    }

    function isValid(num) {
        return !isInvalid(num);
    }

    function isValidLatLngArray(arr) {
        return filter(arr, isValid).length === arr.length;
    }

    function isInvalidLatLngArray(arr) {
        return !isValidLatLngArray(arr);
    }

    function safeRemoveLayer(leafletMap, el) {
        var _leafletMap$getPanes = leafletMap.getPanes(),
            overlayPane = _leafletMap$getPanes.overlayPane;

        if (overlayPane && overlayPane.contains(el)) {
            overlayPane.removeChild(el);
        }
    }

    function shouldIgnoreLocation(loc) {
        return isInvalid(loc.lng) || isInvalid(loc.lat);
    }

    exports.default = withLeaflet(function (_MapLayer) {
        _inherits(HeatmapLayer, _MapLayer);

        function HeatmapLayer() {
            _classCallCheck(this, HeatmapLayer);

            return _possibleConstructorReturn(this, (HeatmapLayer.__proto__ || Object.getPrototypeOf(HeatmapLayer)).apply(this, arguments));
        }

        _createClass(HeatmapLayer, [{
            key: 'createLeafletElement',
            value: function createLeafletElement() {
                return null;
            }
        }, {
            key: 'componentDidMount',
            value: function componentDidMount() {
                var _this2 = this;

                var canAnimate = this.props.leaflet.map.options.zoomAnimation && _npmLeaflet.default.Browser.any3d;
                var zoomClass = 'leaflet-zoom-' + (canAnimate ? 'animated' : 'hide');
                var mapSize = this.props.leaflet.map.getSize();
                var transformProp = _npmLeaflet.default.DomUtil.testProp(['transformOrigin', 'WebkitTransformOrigin', 'msTransformOrigin']);

                this._mainCanvas = _npmLeaflet.default.DomUtil.create('canvas', zoomClass);
                this._mainCanvas.style[transformProp] = '50% 50%';
                this._mainCanvas.width = mapSize.x;
                this._mainCanvas.height = mapSize.y;
                this._mainCtx = this._mainCanvas.getContext('2d');

                var mainCanvas = this._mainCanvas;

                this._auxCanvas = document.createElement('canvas');
                this._auxCtx = this._auxCanvas.getContext('2d');

                this.valueScale = 100;

                var Heatmap = _npmLeaflet.default.Layer.extend({
                    onAdd: function onAdd(leafletMap) {
                        return leafletMap.getPanes().overlayPane.appendChild(mainCanvas);
                    },
                    addTo: function addTo(leafletMap) {
                        leafletMap.addLayer(_this2);
                        return _this2;
                    },
                    onRemove: function onRemove(leafletMap) {
                        return safeRemoveLayer(leafletMap, mainCanvas);
                    }
                });

                this.leafletElement = new Heatmap();
                _get(HeatmapLayer.prototype.__proto__ || Object.getPrototypeOf(HeatmapLayer.prototype), 'componentDidMount', this).call(this);
                this.reset();

                if (this.props.fitBoundsOnLoad) {
                    this.fitBounds();
                }
                this.attachEvents();
            }
        }, {
            key: 'componentWillUnmount',
            value: function componentWillUnmount() {
                safeRemoveLayer(this.props.leaflet.map, this._mainCanvas);
            }
        }, {
            key: 'fitBounds',
            value: function fitBounds() {
                var points = this.props.points;
                var lngs = map(points, this.props.longitudeFunc);
                var lats = map(points, this.props.latitudeFunc);
                var ne = { lng: max(lngs), lat: max(lats) };
                var sw = { lng: min(lngs), lat: min(lats) };

                if (shouldIgnoreLocation(ne) || shouldIgnoreLocation(sw)) {
                    return;
                }

                this.props.leaflet.map.fitBounds(_npmLeaflet.default.latLngBounds(_npmLeaflet.default.latLng(sw), _npmLeaflet.default.latLng(ne)), { maxZoom: 10 });
            }
        }, {
            key: 'componentDidUpdate',
            value: function componentDidUpdate() {
                this.props.leaflet.map.invalidateSize();
                this.reset();
            }
        }, {
            key: 'shouldComponentUpdate',
            value: function shouldComponentUpdate() {
                return true;
            }
        }, {
            key: 'attachEvents',
            value: function attachEvents() {
                var _this3 = this;

                var leafletMap = this.props.leaflet.map;
                leafletMap.on('viewreset', function () {
                    return _this3.reset();
                });
                leafletMap.on('moveend', function () {
                    return _this3.reset();
                });
                if (leafletMap.options.zoomAnimation && _npmLeaflet.default.Browser.any3d) {
                    leafletMap.on('zoomanim', this._animateZoom, this);
                }
            }
        }, {
            key: '_animateZoom',
            value: function _animateZoom(e) {
                var scale = this.props.leaflet.map.getZoomScale(e.zoom);
                var offset = this.props.leaflet.map._getCenterOffset(e.center)._multiplyBy(-scale).subtract(this.props.leaflet.map._getMapPanePos());

                if (_npmLeaflet.default.DomUtil.setTransform) {
                    _npmLeaflet.default.DomUtil.setTransform(this._mainCanvas, offset, scale);
                } else {
                    this._mainCanvas.style[_npmLeaflet.default.DomUtil.TRANSFORM] = _npmLeaflet.default.DomUtil.getTranslateString(offset) + ' scale(' + scale + ')';
                }
            }
        }, {
            key: 'reset',
            value: function reset() {
                var topLeft = this.props.leaflet.map.containerPointToLayerPoint([0, 0]);
                _npmLeaflet.default.DomUtil.setPosition(this._mainCanvas, topLeft);

                var size = this.props.leaflet.map.getSize();

                // if (!this._frame && !this.props.leaflet.map._animating) {
                //     this._frame = L.Util.requestAnimFrame(this.redraw, this)
                // }

                this.redraw();
            }
        }, {
            key: 'redraw',
            value: function redraw() {
                var _this4 = this;

                // const t0 = Date.now()

                var leafletMap = this.props.leaflet.map;
                var size = leafletMap.getSize();

                var mainCtx = this._mainCtx;
                var auxCtx = this._auxCtx;

                this._mainCanvas.width = size.x;
                this._mainCanvas.height = size.y;
                this._auxCanvas.width = size.x;
                this._auxCanvas.height = size.y;

                mainCtx.clearRect(0, 0, size.x, size.y);
                auxCtx.clearRect(0, 0, size.x, size.y);

                var self = this;

                var drawCircle = function drawCircle(ctx, x, y, r, a) {
                    var grad = ctx.createRadialGradient(x, y, 0, x, y, r);
                    grad.addColorStop(0, 'rgba(0,0,0,' + a + ')');
                    grad.addColorStop(1, 'rgba(0,0,0,0.0)');
                    ctx.fillStyle = grad;
                    ctx.beginPath();
                    ctx.arc(x, y, r, 0, Math.PI * 2);
                    ctx.closePath();
                    ctx.fill();
                };

                var strokeCircle = function strokeCircle(ctx, x, y, r, a) {
                    ctx.beginPath();
                    ctx.arc(x, y, r, 0, Math.PI * 2);
                    ctx.closePath();
                    ctx.stroke();
                };

                var circles = this.props.points.map(function (p) {
                    var lat = self.props.latitudeFunc(p);
                    var lng = self.props.longitudeFunc(p);
                    var value = self.props.valueFunc(p);
                    var r = self.props.radiusFunc(p);
                    if (!value || !r) return;

                    var pos = leafletMap.latLngToContainerPoint([lat, lng]);
                    return {
                        x: pos.x,
                        y: pos.y,
                        r: r,
                        v: value
                    };
                }).filter(function (p) {
                    return p;
                });

                this.valueScale = Math.max.apply(Math, _toConsumableArray(circles.map(function (c) {
                    return Math.abs(c.v);
                }))) * 0.75;

                var minRadiusPx = 10;
                var maxRadiusPx = 60;
                var oneRadiusPx = 45;

                var circleRs = circles.map(function (c) {
                    return c.r;
                });
                var minCircleR = Math.min.apply(Math, _toConsumableArray(circleRs));
                var maxCircleR = Math.max.apply(Math, _toConsumableArray(circleRs));
                var rangeCircleR = maxCircleR - minCircleR;

                circles.forEach(function (c) {
                    var ctx = c.v > 0 ? mainCtx : auxCtx;
                    var a = Math.min(Math.max(Math.abs(c.v / _this4.valueScale), 0.01), 1);
                    if (rangeCircleR == 0) {
                        drawCircle(ctx, c.x, c.y, oneRadiusPx, a);
                    } else {
                        var rNorm = (c.r - minCircleR) / rangeCircleR;
                        var r = (1 - rNorm) * minRadiusPx + rNorm * maxRadiusPx;
                        drawCircle(ctx, c.x, c.y, r, a);
                    }
                });

                var mainImage = mainCtx.getImageData(0, 0, size.x, size.y);
                var auxImage = auxCtx.getImageData(0, 0, size.x, size.y);
                this._colorize(mainImage.data, auxImage.data);
                mainCtx.putImageData(mainImage, 0, 0);

                // DEBUG TO SEE OUTLINE CIRCLE OF WHERE THE DATA IS DISPLAYED
                // circles.forEach(c => {
                //     const ctx = mainCtx
                //     if(rangeCircleR == 0) {
                //         strokeCircle(ctx, c.x, c.y, oneRadiusPx)
                //     } else {
                //         const rNorm = (c.r - minCircleR) / rangeCircleR
                //         const r = (1 - rNorm) * minRadiusPx + rNorm * maxRadiusPx
                //         strokeCircle(ctx, c.x, c.y, r)
                //     }
                // })

                // const t = Date.now()
                // console.info(t - t0)
            }
        }, {
            key: '_colorize',
            value: function _colorize(mainData, auxData) {
                for (var i = 0; i < mainData.length; i += 4) {
                    var v = mainData[i + 3] - auxData[i + 3];
                    if (Math.abs(v) != 0) {
                        var col = this.props.colorFunc(v / 255);
                        if (col) {
                            mainData[i] = col[0];
                            mainData[i + 1] = col[1];
                            mainData[i + 2] = col[2];
                            mainData[i + 3] = 180;
                            continue;
                        }
                    }
                    mainData[i + 3] = 0;
                }
            }
        }, {
            key: 'render',
            value: function render() {
                return null;
            }
        }]);

        return HeatmapLayer;
    }(MapLayer));
});