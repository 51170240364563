define('matrix-frontend/components/react-only/payroll-worksheet-tooltip', ['exports', 'react', 'npm:react-tooltip', 'matrix-frontend/utils/format-helpers', 'matrix-frontend/utils/payroll-worksheet', 'matrix-frontend/utils/functional-sort'], function (exports, _react, _npmReactTooltip, _formatHelpers, _payrollWorksheet, _functionalSort) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.AggregateVarianceTooltip = undefined;


    var percentFormat = Intl.NumberFormat('en-US', {
        style: 'percent',
        signDisplay: 'always',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    var mxPercentFormat = function mxPercentFormat(x) {
        if (x == 0) {
            return '0%';
        }
        return percentFormat.format(x);
    };

    var AggregateVarianceTooltip = exports.AggregateVarianceTooltip = function AggregateVarianceTooltip(props) {
        var id = props.id,
            categories = props.categories,
            reverse = props.reverse;


        var content = function content(data) {
            data = JSON.parse(data);
            if (!data) return null;

            var _data = data,
                counts = _data.counts,
                total = _data.total;

            var numInvalid = counts[null];

            // const categories = getSalesVarianceCategories()

            var sortedItems = Object.keys(true ? categories.keyed : counts).map(function (k) {
                return {
                    key: k,
                    category: categories.keyed[k],
                    count: counts[k]
                };
            }).sort((0, _functionalSort.tieredSort)([(0, _functionalSort.funcSort)(function (x) {
                return x.category != null ? x.category.positive ? 1 : -1 : 0;
            }), (0, _functionalSort.funcSort)(function (x) {
                return x.category.positive ? x.category.lower : -x.category.lower;
            })], reverse));

            return _react.default.createElement(
                'div',
                { className: 'aggregate-variance-tooltip flex-table' },
                _react.default.createElement(
                    'div',
                    { className: 'f-col f1' },
                    sortedItems.map(function (item) {
                        var key = item.key,
                            category = item.category;

                        return _react.default.createElement(
                            'div',
                            { key: key, className: 'row' },
                            _react.default.createElement('div', { className: 'color-dot', style: {
                                    background: category ? category.color : '#ddd'
                                } })
                        );
                    })
                ),
                _react.default.createElement(
                    'div',
                    { className: 'f-col signs' },
                    sortedItems.map(function (item) {
                        var key = item.key,
                            category = item.category;

                        var text = category ? isFinite(category.upper) ? category.positive ? '+' : '-' : category.positive ? '>' : '<' : '';
                        return _react.default.createElement(
                            'div',
                            { key: key, className: 'row' },
                            _react.default.createElement(
                                'div',
                                { className: '' },
                                text
                            )
                        );
                    })
                ),
                _react.default.createElement(
                    'div',
                    { className: 'f-col f4' },
                    sortedItems.map(function (item) {
                        var key = item.key,
                            category = item.category,
                            count = item.count;

                        var text = void 0;
                        var style = void 0;
                        if (category) {
                            if (!isFinite(category.upper)) {
                                text = '' + mxPercentFormat(category.positive ? category.lower : -category.lower);
                            } else {
                                text = Math.round(category.lower * 100) + '-' + Math.round(category.upper * 100) + '%';
                            }
                        } else {
                            text = 'N/A';
                        }
                        return _react.default.createElement(
                            'div',
                            { key: key, className: 'row' },
                            _react.default.createElement(
                                'div',
                                { className: 'column text weight-heavy padding-l0', style: style },
                                text
                            )
                        );
                    }),
                    _react.default.createElement(
                        'div',
                        { key: 'total', className: 'row' },
                        _react.default.createElement(
                            'div',
                            { className: 'column text weight-heavy padding-l0' },
                            'Total'
                        )
                    )
                ),
                _react.default.createElement(
                    'div',
                    { className: 'f-col f1' },
                    sortedItems.map(function (item) {
                        var key = item.key,
                            count = item.count;

                        return _react.default.createElement(
                            'div',
                            { key: key, className: 'row' },
                            _react.default.createElement(
                                'div',
                                { className: 'column padding-r0' },
                                count ? count : '-'
                            )
                        );
                    }),
                    _react.default.createElement(
                        'div',
                        { key: 'total', className: 'row' },
                        _react.default.createElement(
                            'div',
                            { className: 'column padding-r0' },
                            total
                        )
                    )
                ),
                _react.default.createElement(
                    'div',
                    { className: 'f-col f2', style: { marginLeft: '10px' } },
                    sortedItems.map(function (item) {
                        var key = item.key,
                            count = item.count;

                        return _react.default.createElement(
                            'div',
                            { key: key, className: 'row' },
                            _react.default.createElement(
                                'div',
                                { className: 'column padding-r0' },
                                count ? Math.round(count / total * 100) + '%' : '-'
                            )
                        );
                    })
                )
            );
        };

        return _react.default.createElement(_npmReactTooltip.default, { id: id, effect: 'solid', getContent: content });
    };
});