define('matrix-frontend/pods/components/mx-modal-input/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Component.extend({
        modalService: Ember.inject.service(),
        isValid: null,
        triggerTether: true,
        bubble: true,
        requireAll: true,
        requires: null,
        additionalRequirement: null,
        submitOnEnter: false,
        onEnter: null,
        onValid: null,
        validateOnFocusOut: false,
        debounce: 0,

        additionalFocus: function additionalFocus() {},

        didInsertElement: function didInsertElement() {
            var modalService = this.get('modalService');
            modalService.addInput(this.key, this);
        },
        doValidation: function doValidation() {
            if (this.get('validate')) {
                var params = [this.$().find('input').val()];
                var validateParams = this.get('validateParams') || [];
                var tetheredInputs = [];

                if (!Array.isArray(validateParams)) {
                    validateParams = [validateParams];
                }

                if (this.get('tether')) {
                    var tethers = [];
                    // const tether =
                    if (this.tether.includes('|')) {
                        this.tether.split('|').forEach(function (x) {
                            tethers.push(Ember.$('#' + x));
                        });
                    } else {
                        tethers.push(Ember.$('#' + this.tether));
                    }
                    tetheredInputs = tethers.map(function (x) {
                        return x.find('input');
                    });
                    tetheredInputs.forEach(function (x) {
                        params.push(x.val());
                    });
                    if (this.triggerTether) {
                        tetheredInputs.forEach(function (x) {
                            x.trigger('keyup');
                        });
                    }
                }

                params = [].concat(_toConsumableArray(params), _toConsumableArray(validateParams));

                var validity = this.get('validate').apply(undefined, _toConsumableArray(params));
                this.set('isValid', validity);

                if (this.onValid) {
                    this.onValid(validity);
                }

                if (this.bubble) {
                    var modalService = this.get('modalService');
                    var key = this.get('key');
                    modalService.bubbleValidation(key, this.requires, this.requireAll, this.additionalRequirement);
                }
            } else {
                if (this.bubble) {
                    var _modalService = this.get('modalService');
                    var _key = this.get('key');
                    _modalService.setValid(_key, true);
                }
            }
        },


        classes: Ember.computed('isValid', function () {
            var classes = ['mx-modal-input'];

            if (this.get('isValid')) {
                classes.push('valid');
                // return 'valid'
            } else if (this.get('isValid') === false) {
                classes.push('invalid');
                // return 'invalid'
            }

            return classes.join(' ');
        }),

        doChangeMade: function doChangeMade(e) {
            var modalService = this.get('modalService');
            var key = this.get('key');

            if (!this.validateOnFocusOut) {
                this.doValidation();
            } else if (!this.isValid) {
                this.set('isValid', null);
            }
            modalService.changeMade(key);

            if (e && e.keyCode === 13) {
                if (this.onEnter) {
                    this.onEnter();
                }
                if (this.submitOnEnter) {
                    modalService.doSave(key);
                }
            }
        },


        actions: {
            changeMade: function changeMade(_, e) {
                if (this.debounce > 0) {
                    this.set('isValid', null);
                    if (this.debounceCleanup) {
                        this.debounceCleanup();
                    }
                    Ember.run.debounce(this, 'doChangeMade', e, this.debounce);
                } else {
                    this.doChangeMade(e);
                }
            },
            doFocusOut: function doFocusOut() {
                if (this.validateOnFocusOut) {
                    this.doValidation();
                }
                if (this.focusOut) {
                    this.focusOut();
                }
            },
            clear: function clear() {
                var modalService = this.get('modalService');
                var key = this.get('key');
                modalService.resetNotices(key);
                // const modalService = this.get('modalService')
                // const key = this.get('key')
                // modalService.changeMade(key)
                // modalService.set(`modals.${key}.errors`, [])
                // modalService.set(`modals.${key}.warnings`, [])
                // modalService.set(`modals.${key}.alerts`, [])
            }
        }
    });
});