define('matrix-frontend/services/loading-service', ['exports', 'matrix-frontend/actions/_types'], function (exports, _types) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _loading: false,
        redux: Ember.inject.service(),
        // saveService: service(),

        startLoading: function startLoading() {
            this.set('_loading', true);
            this.notifyPropertyChange('loading');
            this.notifyPropertyChange('_loading');
            var redux = this.get('redux');
            redux.dispatch({
                type: _types.TIMEOUT_RESET
            });
        },
        endLoading: function endLoading() {
            // console.log('called endLoading')
            this.set('_loading', false);
            this.notifyPropertyChange('loading');
            this.notifyPropertyChange('_loading');
        },


        loading: Ember.computed('_loading', function () {
            return this.get('_loading');
        })
    });
});