define('matrix-frontend/pods/components/store-map/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var ContainerController = function (_EmberController) {
        _inherits(ContainerController, _EmberController);

        function ContainerController() {
            var _ref;

            var _temp, _this, _ret;

            _classCallCheck(this, ContainerController);

            for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
                args[_key] = arguments[_key];
            }

            return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ContainerController.__proto__ || Object.getPrototypeOf(ContainerController)).call.apply(_ref, [this].concat(args))), _this), _this.lat = 45.519743, _this.lng = -122.680522, _this.zoom = 10, _temp), _possibleConstructorReturn(_this, _ret);
        }

        return ContainerController;
    }(Ember.Controller);

    exports.default = ContainerController;
});