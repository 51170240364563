define('matrix-frontend/pods/components/schedule-page-section-row-modals/component', ['exports', 'ember-redux', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/interactions'], function (exports, _emberRedux, _types, _interactions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var stateToComputed = function stateToComputed(state) {
        return {
            shifts: state.scheduleEdit.shifts,
            showEmployeeFlagsId: state.scheduleEdit.showEmployeeFlagsId
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {
            afterClose: function afterClose() {
                return dispatch({
                    type: _types.SE_SET,
                    payload: { showEmployeeFlagsId: null }
                });
            },
            markAsShared: function markAsShared() {
                var _this = this;

                this.set('inProcess', true);
                var shifts = this.unCommunicated.map(function (x) {
                    return x.meta.id;
                });

                (0, _interactions.send)({
                    header: 'do',
                    body: {
                        service: 'store',
                        namespace: ['shift', 'communicated'],
                        operation: 1,
                        include_channel: 2,
                        extra: [this.weekEndDate.format('YYYY-MM-DD')],
                        payload: { shifts: shifts, scheduleId: this.scheduleId }
                    }
                }, 'mark shifts as communicated', false, function () {
                    _this.set('inProcess', false);
                });
            }
        };
    };

    var Comp = Ember.Component.extend({
        modalService: Ember.inject.service(),
        inProcess: false,

        employeeShifts: Ember.computed('shiftsByEmployee', 'showEmployeeFlagsId', function () {
            var id = this.get('showEmployeeFlagsId');
            var shifts = this.get('shifts');
            var shiftsArr = Object.keys(shifts).map(function (x) {
                return shifts[x];
            });
            var shiftsByEmployee = this.get('shiftsByEmployee');
            if (id && shiftsByEmployee) {
                var es = shiftsByEmployee[id];
                if (es) {
                    return es;
                }
            }
            return null;
        }),

        unCommunicated: Ember.computed('employeeShifts', 'published', function () {
            if (this.employeeShifts && this.published) {
                return Object.values(this.employeeShifts).filter(function (x) {
                    return !x.meta.communicated && x.meta.saved;
                });
            }
            return null;
        }),

        flags: Ember.computed('showEmployeeFlagsId', 'employeeShifts', 'shift', 'date', 'employeeFlags', function () {
            var daily = {
                alert: [],
                warning: [],
                violation: []
            };
            var weekly = {
                alert: [],
                warning: [],
                violation: []
            };

            var shift = this.get('shift');
            var date = this.get('date');
            var id = this.get('showEmployeeFlagsId');
            var employeeShifts = this.get('employeeShifts');
            var employeeFlags = this.get('employeeFlags');

            // Shift level flags
            if (date && shift && shift.blocks && shift.blocks.length > 0 && shift.errors) {
                daily = shift.errors.reduce(function (o, i) {
                    var severity = i.severity === -1 ? 'alert' : i.severity === 0 ? 'warning' : 'violation';

                    o[severity].push(i);

                    return o;
                }, daily);
            } else if (!date && employeeShifts) {
                Object.keys(employeeShifts).forEach(function (id) {
                    var shift = employeeShifts[id];
                    if (shift && shift.blocks && shift.blocks.length > 0 && shift.errors) {
                        shift.errors.forEach(function (i) {
                            var severity = i.severity === -1 ? 'alert' : i.severity === 0 ? 'warning' : 'violation';
                            if (daily[severity] === undefined) {
                                daily[severity] = [];
                            }

                            daily[severity].push(i);
                        });
                    }
                });
            }

            // Employee level flags
            if (employeeFlags[id] && employeeFlags[id].length > 0) {
                employeeFlags[id].forEach(function (i) {
                    var severity = i.severity === -1 ? 'alert' : i.severity === 0 ? 'warning' : 'violation';
                    weekly[severity].push(i);
                });
            }

            return {
                daily: daily,
                weekly: weekly
            };
        }),

        shiftsByDOTW: Ember.computed('employeeShifts', 'showEmployeeFlagsId', function () {
            var shiftsByDOTW = {};
            var employeeShifts = this.get('employeeShifts');
            var id = this.get('showEmployeeFlagsId');
            var shiftsRaw = this.get('shifts');
            var shifts = Object.keys(shiftsRaw).map(function (x) {
                return shiftsRaw[x];
            }).filter(function (x) {
                return x.meta.employeeId === id;
            });

            shifts.forEach(function (x) {
                shiftsByDOTW[x.meta.dotw] = x;
            });
            return shiftsByDOTW;
        }),

        shift: Ember.computed('dotw', 'shiftsByDOTW', 'activitiesById', function () {
            var shiftsByDOTW = this.get('shiftsByDOTW');
            var dotw = this.get('dotw');
            if (dotw) {
                return shiftsByDOTW[dotw];
            } else {
                return null;
            }
        })
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});