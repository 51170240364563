define('matrix-frontend/pods/components/control-panel-controls/component', ['exports', 'matrix-frontend/actions/_types', 'ember-redux', 'npm:moment', 'matrix-frontend/constants', 'matrix-frontend/utils/state'], function (exports, _types, _emberRedux, _npmMoment, _constants, _state) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var CAN_CONTROL_ROLES = ['matrix_admin', 'schedule1', 'schedule2', 'schedule3'];

    var stateToComputed = function stateToComputed(state) {
        return {
            page: state.cube.page,
            editMode: state.scheduleEdit.editMode,
            hasError: state.scheduleEdit.hasError,
            status: state.scheduleEdit.status,
            multiUserSaveMode: state.cube.multiUserSaveMode,
            currentUser: state.user.currentUser,
            websocket: state.websocket
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {
            toggleMultiUserSaveMode: function toggleMultiUserSaveMode() {
                return dispatch({ type: _types.CUBE_TOGGLE_MULTI_USER_SAVE_MODE });
            }
        };
    };

    var Comp = Ember.Component.extend({
        _text: null,
        // multiUserSaveMode: false,
        saveService: Ember.inject.service(),
        modalService: Ember.inject.service(),

        setValueChanged: Ember.observer('userHasEdits', 'numDirty', function () {
            var userHasEdits = this.get('userHasEdits');
            var numDirty = this.get('numDirty');
            var saveService = this.get('saveService');
            var valueChanged = numDirty > 0 && userHasEdits;
            saveService.set('valueChanged', valueChanged);
        }),

        editors: Ember.computed('objects', function () {
            var objects = this.get('objects');
            if (!objects) {
                return new Set();
            }
            var editors = new Set(Object.keys(objects).map(function (x) {
                return objects[x].meta;
            }).filter(function (x) {
                return x.saved !== true;
            }).map(function (x) {
                return x.changedByUsername;
            }).filter(function (x) {
                return x;
            }));
            return editors;
        }),

        editorCounts: Ember.computed('objects', function () {
            var objects = this.get('objects');
            if (!objects) {
                return {};
            }

            var editorCounts = Object.keys(objects).map(function (x) {
                return objects[x].meta;
            }).filter(function (x) {
                return x.changedByUsername;
            }).reduce(function (o, i) {
                if (!(i.changedByUsername in o)) {
                    o[i.changedByUsername] = { edits: 0, last: null };
                }
                var changed = (0, _npmMoment.default)(i.changed);
                o[i.changedByUsername].edits += 1;
                if (o[i.changedByUsername].last === null || o[i.changedByUsername].last.isBefore(i.changed)) {
                    o[i.changedByUsername].last = changed;
                }
                return o;
            }, {});
            return editorCounts;
        }),

        numDirty: Ember.computed('objects', function () {
            var objects = this.get('objects');
            if (!objects) {
                return 0;
            }
            var dirty = Object.keys(objects).map(function (x) {
                return objects[x];
            }).filter(function (x) {
                return x.meta.saved !== true;
            });
            return dirty.length;
        }),

        numEditors: Ember.computed('editors', function () {
            var editors = this.get('editors');
            return editors.size;
        }),

        pageIsDirty: Ember.computed('numEditors', function () {
            var numEditors = this.get('numEditors');
            return numEditors > 0;
        }),

        processDisplay: Ember.computed('objects', '_text', 'processText', 'noSchedule', function () {
            var objects = this.get('objects');
            var processText = this.get('processText');
            var _text = this.get('_text');
            return (!objects || objects.length === 0) && this.noSchedule != true ? 'Loading' : processText || _text;
        }),

        showConfirmPanel: Ember.computed('saveService.awaitingConfirm', function () {
            return this.get('saveService.awaitingConfirm');
        }),

        soloEditorUsername: Ember.computed('editors', function () {
            var editors = this.get('editors').values();
            return editors.next().value || '';
        }),

        // userCanControl: computed(
        //     'currentUser.roles',
        //     'page',
        //     'editMode',
        //     function() {
        //         const browser = bowser.detect(window.navigator.userAgent)
        //         const mode = browser.mobile
        //         const roles = this.get('currentUser.roles')
        //         const additional =
        //             this.page === 'scheduleEdit'
        //                 ? this.editMode && !browser.mobile
        //                 : true
        //         if (roles) {
        //             return (
        //                 additional &&
        //                 roles
        //                     .map(x => x.name)
        //                     .some(x => CAN_CONTROL_ROLES.includes(x))
        //             )
        //         }
        //         console.error(
        //             'Cannot determine if user can control because no roles received',
        //         )
        //         return false
        //     },
        // ),

        statusInfo: Ember.computed('currentUser.roles', 'status', 'noSchedule', function () {
            var currentUser = this.currentUser;
            var status = this.status;
            if (!status || this.noSchedule != false) return null;

            var info = {
                canChange: (0, _state.hasScope)('schedule.store:status.edit')
            };
            if (status === _constants.ScheduleStatus.HIDDEN) {
                info.text = 'Hidden';
                info.classes = 'dark white-text';
            } else if (status === _constants.ScheduleStatus.EXECUTIVE) {
                info.text = 'Executive';
                info.classes = 'blue white-text';
            } else if (status === _constants.ScheduleStatus.READONLY) {
                info.text = 'Read-only';
                info.classes = 'orange white-text';
            } else if (status === _constants.ScheduleStatus.PRELIMINARY) {
                info.text = 'Preliminary';
                info.classes = 'yellow white-text';
            } else if (status === _constants.ScheduleStatus.ACTIVE) {
                if (!info.canChange) return null;
                info.text = 'Active';
                info.classes = 'green white-text';
            } else if (status === _constants.ScheduleStatus.UNKNOWN) {
                return null;
            }

            if (info.canChange) info.classes += ' fixed-width';
            return info;
        }),

        controlStatus: Ember.computed('currentUser.roles', 'page', 'editMode', 'status', 'pageIsDirty', function () {
            var browser = bowser.detect(window.navigator.userAgent);
            var mode = browser.mobile;
            var roles = this.get('currentUser.roles');
            var status = this.get('status');
            var pageIsDirty = this.get('pageIsDirty');

            var additionalClause = this.page === 'scheduleEdit' ? this.editMode : true;
            var mobileClause = !(this.page === 'scheduleEdit' && browser.mobile);

            var response = {
                reason: null
            };

            if (!additionalClause) {
                response.reason = '';
            }

            if (!roles) {
                response.reason = 'Waiting for roles';
            } else if (!mobileClause) {
                response.reason = 'Cannot edit on mobile';
            }

            response.canControl = response.reason === null;
            return response;
        }),

        userHasEdits: Ember.computed('editors', 'currentUser.username', function () {
            var editors = this.get('editors');
            var username = this.get('currentUser.username');
            return editors.has(username);
        }),

        userIsActive: Ember.computed('userHasEdits', 'multiUserSaveMode', function () {
            var multiUserSaveMode = this.get('multiUserSaveMode');
            var userHasEdits = this.get('userHasEdits');
            return userHasEdits || multiUserSaveMode;
        }),

        lastChange: Ember.computed('objects', function () {
            var objects = this.get('objects');
            var latest = Object.keys(objects).map(function (x) {
                return objects[x].meta;
            }).reduce(function (o, i) {
                if (o) {
                    if (i.updated.isAfter(o)) {
                        return i.updated;
                    }
                    return o;
                } else {
                    return i.updated;
                }
            }, null);
            return latest || null;
        }),

        actions: {
            saveAction: function saveAction() {
                var action = this.get('save');
                action.call();
            },
            deleteAction: function deleteAction() {
                var action = this.get('delete');
                action.call();
            },
            // toggleMultiUserSaveMode: function() {
            //     this.set('multiUserSaveMode', !this.get('multiUserSaveMode'))
            // },
            confirmGoBack: function confirmGoBack() {
                this.get('saveService').set('awaitingConfirm', false);
            },
            confirmGoAnyway: function confirmGoAnyway() {
                this.get('saveService').set('awaitingConfirm', false);
                this.get('saveService').set('valueChanged', false);
                this.get('saveService').proceed({ forceMethod: true });
            },
            displayModal: function displayModal(key) {
                var modalService = this.get('modalService');
                modalService.open(key);
            }
        }
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});