define('matrix-frontend/components/employee-filter', ['exports', 'react', 'ember-react-components', 'matrix-frontend/actions/_types', 'matrix-frontend/store'], function (exports, _react, _emberReactComponents, _types, _store) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var doChange = function doChange(e) {
        _store.store.dispatch({
            type: _types.SE_SET_EMPLOYEE_FILTER,
            payload: {
                type: 'position',
                value: Number(e.target.value)
            }
        });
    };

    var doClick = function doClick(e) {
        if (Number(e) > 1) {
            _store.store.dispatch({
                type: _types.SE_SET_EMPLOYEE_FILTER,
                payload: {
                    type: 'skill',
                    value: Number(e)
                }
            });
        }
    };

    var doToggle = function doToggle(e) {
        _store.store.dispatch({
            type: _types.SE_TOGGLE_EMPLOYEE_FILTER,
            payload: { force: false }
        });
    };

    var doClear = function doClear(e) {
        _store.store.dispatch({
            type: _types.SE_SET_EMPLOYEE_FILTER,
            payload: {
                type: 'skill',
                value: 0
            }
        });
        _store.store.dispatch({
            type: _types.SE_SET_EMPLOYEE_FILTER,
            payload: {
                type: 'position',
                value: 0
            }
        });
    };

    var comp = function comp(props) {
        var filterClasses = ['pointer', 'activity-selector'];
        var activities = props.currentClient.activities,
            activitiesById = props.activitiesById,
            employeeFilters = props.employeeFilters;


        if (employeeFilters.skill) {
            filterClasses.push('activity-color-' + activitiesById[employeeFilters.skill].livecolor.id);
        }
        // <div
        //     className={'pointer activity-selector activity-color-112'}
        //     style={{borderColor: '#eee'}}
        //     onClick={_ => doClick(0)}
        // >
        //     All
        // </div>
        return _react.default.createElement(
            'div',
            { className: 'activity-selector-wrapper' },
            activities
            // .filter(x => x.shouldDisplay && x.id > 1)
            .filter(function (x) {
                return x.shouldDisplay;
            }).map(function (activity) {
                var classes = ['pointer', 'activity-selector', 'activity-color-' + activity.livecolor.id];
                if (activity.id <= 1) {
                    classes.push('disabled');
                }
                if (employeeFilters.skill && activity.id === employeeFilters.skill) {
                    classes.push('current-paint');
                }
                return _react.default.createElement(
                    'div',
                    {
                        key: 'activity-selector-id-' + activity.id,
                        className: classes.join(' '),
                        style: { borderColor: activity.livecolor.primary },
                        onClick: function onClick(_) {
                            return doClick(activity.id);
                        }
                    },
                    activity.shortName
                );
            }),
            _react.default.createElement(
                'div',
                {
                    className: filterClasses.join(' '),
                    style: { borderColor: '#fff' }
                },
                _react.default.createElement('span', { className: 'breathe-l-10 icon-close', onClick: doToggle })
            )
        );
    };

    exports.default = (0, _emberReactComponents.default)(comp);
});