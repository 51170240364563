define('matrix-frontend/reducers/payroll-worksheet', ['exports', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/cleaners', 'npm:lodash'], function (exports, _types, _cleaners, _npmLodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var initialState = {
        view: null,
        subview: null,
        timeColumn: 'total',
        weeklyInfo: {},
        todayIndex: 4
    };

    var store = function store(state, action) {
        var type = action.type,
            payload = action.payload;

        switch (type) {
            case _types.PW_SET_VIEW:
                {
                    return _extends({}, state, payload);
                }
            case _types.PW_INTAKE_ITEMS:
                {
                    var results = payload.results,
                        meta = payload.meta;

                    var weekEndDate = meta.params.week_end_date;

                    var items = {};
                    results.data.forEach(function (item) {
                        items[item.id] = item;
                    });

                    var weeklyInfo = _extends({}, state.weeklyInfo);
                    weeklyInfo[weekEndDate] = _extends({}, weeklyInfo[weekEndDate], {
                        items: items
                    });

                    return _extends({}, state, {
                        weeklyInfo: weeklyInfo,
                        payrollWorksheetTimestamp: new Date()
                    });
                }
            case _types.PW_SET_OPT:
                {
                    return _extends({}, state, payload);
                }
            // case types.PW_HANDLE_SAVE: {
            //     const {results, meta} = payload
            //     console.info("REDUCER")
            //     console.info(results, meta)

            //     if(results.ok) {
            //         if(results.cached.forecast) {

            //         }
            //     }

            //     return {
            //         ...state,
            //     }
            // }

            default:
                {
                    return state || initialState;
                }
        }
    };

    exports.default = store;
});