define('matrix-frontend/pods/clients/show/transport/route', ['exports', 'matrix-frontend/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _EmberRoute$extend;

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    exports.default = Ember.Route.extend((_EmberRoute$extend = {
        stateService: Ember.inject.service(),
        ajax: Ember.inject.service(),
        redux: Ember.inject.service()
    }, _defineProperty(_EmberRoute$extend, 'stateService', Ember.inject.service()), _defineProperty(_EmberRoute$extend, 'beforeModel', function beforeModel() {
        var self = this;
        this._super();

        var client = this.modelFor('clients/show');

        var _self$get$getState$us = self.get('redux').getState().user.currentUser,
            isMatrixAdmin = _self$get$getState$us.isMatrixAdmin,
            token = _self$get$getState$us.token;

        // get client options so we can know to go to new or old checklist

        var baseUrl = _environment.default.mxl.replace('${host}', window.location.host);
        self.get('ajax').request(baseUrl + '/client/options/' + client.get('code'), {
            crossDomain: true,
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(function (response) {
            var USE_NEW_PW = (response.find(function (x) {
                return x.code === 'use_new_pw';
            }) || { value: 'F' }).value == 'T';
            var hierarchyNodeId = self.session.get('user.hierarchyNode.id');
            var storeId = self.session.get('user.homeStore.id');

            var queryParams = void 0;
            if (isMatrixAdmin) {
                queryParams = {
                    hierarchyNodeId: client.hierarchyNode.get('id')
                };
            } else if (hierarchyNodeId) {
                queryParams = {
                    hierarchyNodeId: hierarchyNodeId
                };
            } else if (storeId) {
                queryParams = {
                    storeId: storeId
                };
            }
            if (USE_NEW_PW) {
                self.replaceWith('new-checklist', client.get('id'), {
                    queryParams: queryParams
                });
            } else {
                self.replaceWith('reports.show', client, 'sales-equation', {
                    queryParams: queryParams
                });
            }
        });
    }), _EmberRoute$extend));
});