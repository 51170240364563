define('matrix-frontend/components/react-only/employee-rollup-row', ['exports', 'react', 'matrix-frontend/components/react-only/employee-skill-cell', 'npm:numbro', 'matrix-frontend/utils/format-helpers'], function (exports, _react, _employeeSkillCell, _npmNumbro, _formatHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var LENGTH = 20;

    var comp = function comp(_ref) {
        var item = _ref.item,
            currentClient = _ref.currentClient,
            activitiesById = _ref.activitiesById,
            selectBusinessUnit = _ref.selectBusinessUnit,
            weekEndDate = _ref.weekEndDate;

        var blankSkills = Object.keys(activitiesById).reduce(function (o, i) {
            o[i] = 0;
            return o;
        }, {});

        var type = item.type && item.type !== 'store' ? 'hierarchyNode' : 'store';
        var nodeLevel = item.type && item.type !== 'store' ? 'hierarchies' : 'stores';
        var name = item.type && item.type !== 'store' ? item.name : item.code.toUpperCase() + ' - ' + item.name;
        var users = item.statistics ? (0, _npmNumbro.default)(item.statistics.users.count).format({ thousandSeparated: true }) : '-';
        var employees = item.statistics ? (0, _npmNumbro.default)(item.statistics.employees.reduce(function (o, i) {
            return o += i.count;
        }, 0)).format({ thousandSeparated: true }) : '-';
        var displayName = _formatHelpers.default.fixAllCaps(name.substring(0, LENGTH));

        return _react.default.createElement(
            'div',
            { className: 'row' },
            _react.default.createElement(
                'div',
                { className: 'wrapper f2' },
                _react.default.createElement(
                    'div',
                    {
                        className: 'column text f1 no-left pointer allow-overflow',
                        onClick: function onClick() {
                            return selectBusinessUnit(item.id, type);
                        },
                        'data-tooltip': name
                    },
                    displayName,
                    name.length > LENGTH ? '...' : ''
                )
            ),
            _react.default.createElement(
                'div',
                { className: 'wrapper f9' },
                _react.default.createElement(
                    'div',
                    { className: 'column f2' },
                    employees
                ),
                _react.default.createElement(
                    'div',
                    { className: 'column f1' },
                    item.minmax ? _react.default.createElement(
                        'span',
                        null,
                        (0, _npmNumbro.default)(item.minmax.minimumHours || 0).format({
                            thousandSeparated: true
                        }),
                        '\u2013',
                        (0, _npmNumbro.default)(item.minmax.maximumHours || 0).format({
                            thousandSeparated: true
                        })
                    ) : '-'
                ),
                _react.default.createElement(
                    'div',
                    { className: 'column f3 allow-overflow' },
                    _react.default.createElement(_employeeSkillCell.default, {
                        skills: item.skills,
                        activitiesById: activitiesById,
                        tooltipValue: true
                    })
                ),
                _react.default.createElement(
                    'div',
                    { className: 'column f2' },
                    users
                )
            )
        );
    };

    exports.default = comp;
});