define('matrix-frontend/pods/login/controller', ['exports', 'ember-redux', 'matrix-frontend/actions/_types', 'matrix-frontend/config/environment'], function (exports, _emberRedux, _types, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var stateToComputed = function stateToComputed() {
        return {};
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {
            login: function login(userData) {
                return dispatch({ type: _types.USER_LOGIN, payload: userData });
            }
        };
    };

    var Comp = Ember.Controller.extend({
        ajax: Ember.inject.service(),
        cookies: Ember.inject.service(),
        redux: Ember.inject.service(),

        compoundUsername: Ember.computed('username', 'company', function () {
            return this.get('username') + '|' + this.get('company');
        }),

        // company: this.get('cookies').read('mxCompany'),
        // username: this.get('cookies').read('mxUsername'),
        company: Ember.computed(function () {
            return this.get('cookies').read('mxCompany');
        }),
        username: Ember.computed(function () {
            return this.get('cookies').read('mxCompany');
        }),

        actions: {
            signIn: function signIn() {
                var self = this;
                var password = this.get('password');
                var compoundUsername = this.get('compoundUsername');
                var company = this.get('company');
                var username = this.get('username');
                var token = void 0;

                this.get('ajax').request('/workforce/j_spring_security_check', {
                    method: 'POST',
                    data: {
                        j_username: compoundUsername,
                        j_password: password
                    }
                }).then(function (data) {
                    if (data) {
                        token = data.token;
                        var rawCurrentUser = self.store.normalize('user', data.user.users[0]);
                        var currentUser = self.store.push(rawCurrentUser);
                        self.session.set('user', currentUser);
                        self.session.set('token', token);

                        Ember.$(window).on('storage', function (event) {
                            if (event.originalEvent.key === 'logged-out') {
                                window.location.replace('/workforce/logout');
                            }
                        });

                        // self.get('ajax').request('http://localhost:7004/v2/auth/verify/').then(function(response) {
                        //   console.log(response)
                        // });

                        currentUser.get('roles').then(function (data) {
                            var isMatrixAdmin = data.any(function (role) {
                                return 'matrix_admin' === role.get('name');
                            });
                            var isDashboardUser = true; /* data.any((role) => {
                                                        return 'dashboard' === role.get('name');
                                                        }); */

                            var isEmployeeAdmin = data.any(function (role) {
                                return 'matrix_admin' === role.get('name') || role.get('name') == 'employee_admin';
                            });

                            if (!(isMatrixAdmin || isDashboardUser)) {
                                if (rawCurrentUser.hierarchyNode) {
                                    window.location.replace('/workforce/application/storeSelector.jsf');
                                } else {
                                    window.location.replace('/workforce/application/index.jsf');
                                }
                            } else {}

                            self.get('cookies').write('mxCompany', company, {
                                maxAge: 60 * 60 * 24 * 365
                            });
                            self.get('cookies').write('mxUsername', username, {
                                maxAge: 60 * 60 * 24 * 365
                            });

                            var redux = self.get('redux');
                            redux.dispatch({
                                type: _types.TIMEOUT_RESET
                            });

                            var url = _environment.default.liveAuthURL.replace('${host}', window.location.host);
                            var ajax = self.get('ajax');

                            ajax.request(url, {
                                crossDomain: true,
                                method: 'POST',
                                data: {
                                    user: currentUser.get('id'),
                                    token: token
                                }
                            }).then(function (response) {
                                var payload = {
                                    roles: data.map(function (q) {
                                        return {
                                            id: q.get('id'),
                                            name: q.get('name')
                                        };
                                    }),
                                    token: response.access_token,
                                    mxUsername: self.get('cookies').read('mxUsername'),
                                    mxCompany: self.get('cookies').read('mxCompany'),
                                    isMatrixAdmin: isMatrixAdmin,
                                    isEmployeeAdmin: isEmployeeAdmin
                                };
                                self.actions.login(payload);

                                Ember.RSVP.hash({
                                    currentUser: currentUser,
                                    hierarchyNode: currentUser.get('hierarchyNode'),
                                    homeStore: currentUser.get('homeStore'),
                                    client: currentUser.get('client'),
                                    roles: currentUser.get('roles')
                                }).then(function () {
                                    self.transitionToRoute('index');
                                });
                            });
                        });

                        var redux = self.get('redux');
                        var redirectLocation = redux.getState().user.redirectLocation;
                        if (redirectLocation && redirectLocation !== '/' && redirectLocation !== '/login' && redirectLocation !== '/logged-out' && !redirectLocation.startsWith('/claim')) {
                            window.location.replace(redirectLocation);
                        }
                    } else {
                        self.set('unsuccessfulLogin', true);
                    }
                });

                return false;
            }
        }
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});