define('matrix-frontend/services/state-service', ['exports', 'npm:moment', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/backoff', 'matrix-frontend/utils/date-helper'], function (exports, _npmMoment, _types, _backoff, _dateHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        client: null,
        hierarchyNode: null,
        storeLocation: null,
        weekEndDate: null,
        store: Ember.inject.service(),
        redux: Ember.inject.service(),

        init: function init() {
            this._super.apply(this, arguments);
            // if (!this.get('weekEndDate')) {
            //     this.setWeekEndDate()
            // }
        },
        getBusinessUnit: function getBusinessUnit() {
            if (this.get('storeLocation') !== null) {
                return this.get('storeLocation');
            } else {
                return this.get('hierarchyNode');
            }
        },
        getClient: function getClient() {
            return this.get('client');
        },
        getHierarchyNode: function getHierarchyNode() {
            return this.get('hierarchyNode');
        },
        getStore: function getStore() {
            return this.get('storeLocation');
        },
        getWeekEndDate: function getWeekEndDate() {
            return this.get('weekEndDate');
        },
        setClient: function setClient(client) {
            this.set('client', client);
            if (client) {
                this.redux.dispatch({
                    type: _types.CUBE_SET_PARTIAL,
                    payload: {
                        clientCode: client.get('code'),
                        clientId: client.get('id'),
                        clientWeekStartDay: client.get('weekStartDay')
                    }
                });

                this.redux.dispatch({
                    type: _types.CLIENT_BASE,
                    payload: {
                        clientCode: client.get('code'),
                        clientId: client.get('id'),
                        clientWeekStartDay: client.get('weekStartDay')
                    }
                });

                var weekEndDate = this.get('weekEndDate');
                if (!(weekEndDate && (0, _dateHelper.getDayOfClientWeek)(weekEndDate) == 6)) {
                    var newWeekEndDate = (0, _dateHelper.getClientWeekEndDate)((0, _npmMoment.default)()).format('YYYY-MM-DD');
                    this.setWeekEndDate(newWeekEndDate);
                }
            }
        },
        setHierarchyNode: function setHierarchyNode(hierarchyNodeId) {
            var _this = this;

            console.debug('setHierarchyNode called on ' + hierarchyNodeId);
            var store = this.get('store');
            var self = this;
            var redux = this.get('redux');
            return store.find('hierarchyNode', hierarchyNodeId).then(function (node) {
                self.set('storeLocation', null);
                if (_this.get('hierarchyNode') !== node) {
                    self.set('hierarchyNode', node);

                    console.debug('dispatching subscribe');
                    redux.dispatch({
                        type: _types.CUBE_SET_NODE,
                        payload: {
                            clientCode: self.get('client.code'),
                            tag: node.get('type') + '.' + node.get('code').toLowerCase(),
                            nodeType: node.get('type'),
                            nodeId: hierarchyNodeId,
                            name: node.get('name')
                        }
                    });

                    redux.dispatch({
                        type: _types.WS_REGISTER
                    });
                }
                self.notifyPropertyChange('hierarchyNode');
                self.notifyPropertyChange('storeLocation');
            });
        },
        setStore: function setStore(storeId) {
            var _this2 = this;

            var doSocket = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

            console.debug('setStore called on ' + storeId);
            var store = this.get('store');
            var redux = this.get('redux');
            var self = this;
            return store.find('store', storeId).then(function (store) {
                self.set('hierarchyNode', null);
                if (_this2.get('storeLocation') !== store) {
                    self.set('storeLocation', store);

                    // redux.dispatch({
                    //       type: types.CUBE_GO_TO_DAY,
                    //       payload: {
                    //           dotw: null
                    //       }
                    //   });
                    redux.dispatch({
                        type: _types.CUBE_SET_NODE,
                        payload: {
                            clientCode: self.get('client.code'),
                            tag: 'store.' + store.get('code').toLowerCase(),
                            nodeType: 'store',
                            storeId: storeId,
                            name: store.get('name')
                        }
                    });

                    if (doSocket) {
                        console.debug('dispatching subscribe from setStore');
                        redux.dispatch({
                            type: _types.WS_REGISTER
                        });
                    }
                }
                self.notifyPropertyChange('hierarchyNode');
                self.notifyPropertyChange('storeLocation');
            });
        },
        setWeekEndDate: function setWeekEndDate(dateString) {
            var doSocket = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

            console.debug('setWeekEndDate called on ' + dateString, doSocket);
            var redux = this.get('redux');
            if (!dateString) {
                // this.setWeekEndDate(
                //     moment()
                //         .endOf('week')
                //         .format('YYYY-MM-DD'),
                // )
            } else {
                if (this.get('weekEndDate') !== dateString) {
                    this.set('weekEndDate', dateString);

                    // Potential issue here on Reports pages
                    // when intake of wrong date scenario occurs (clicking)arrows
                    // outpaces loading)
                    redux.dispatch({
                        type: _types.CUBE_SET_WEEK_END_DATE,
                        payload: { dateString: dateString }
                    });

                    if (doSocket) {
                        console.debug('dispatching subscribe from setWeekEndDate');
                        redux.dispatch({
                            type: _types.WS_REGISTER
                        });
                    }
                }
            }
        }
    });
});