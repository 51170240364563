define('matrix-frontend/middleware/employee', ['exports', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/interactions', 'matrix-frontend/utils/cleaners', 'matrix-frontend/utils/date-helper', 'npm:moment', 'matrix-frontend/actions/employee', 'matrix-frontend/utils/time', 'matrix-frontend/services/notification-service'], function (exports, _types, _interactions, _cleaners, _dateHelper, _npmMoment, _employee, _time, _notificationService) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var beforeListeners = {};
    var afterListeners = {};

    beforeListeners[_types.EE_RELOAD] = function (store, payload) {
        (0, _employee.employeeReload)(store);
        return false;
    };

    beforeListeners[_types.EE_SEND_EMAIL] = function (store, payload) {
        var _store$getState = store.getState(),
            email = _store$getState.employee.email,
            weekEndDate = _store$getState.cube.weekEndDate;

        var service = new _notificationService.default();

        (0, _interactions.send)({
            header: 'do',
            body: {
                service: 'client',
                namespace: ['email'],
                operation: 1,
                include_channel: 2,
                payload: {
                    email: email
                },
                extra: [weekEndDate.format('YYYY-MM-DD')]
            }
        }, 'Email send', false, function (results) {
            service.success();
        }, function () {
            service.error('Your message was not sent.');
        });
    };

    beforeListeners[_types.EE_SAVE_AVAILABILITY] = function (store, payload) {
        console.debug('EE_SAVE_AVAILABILITY');
        var record = payload.record;
        var storeCode = payload.storeCode,
            employee = payload.employee,
            activityCode = payload.activityCode,
            dotw = payload.dotw;
        var isDefault = payload.isDefault;

        var date = (0, _dateHelper.dotwToMoment)(dotw).format('YYYY-MM-DD');
        // console.log({activityCode, isDefault})

        var _store$getState2 = store.getState(),
            _store$getState2$cube = _store$getState2.cube,
            node = _store$getState2$cube.node,
            weekEndDate = _store$getState2$cube.weekEndDate,
            stores = _store$getState2.node.stores;

        // store.dispatch({
        //     type: types.SE_RELOAD,
        // })
        var storeTag = node.clientCode + '.' + node.tag;
        var employeeRecord = _extends({}, stores[storeTag].weeks[weekEndDate.format('YYYY-MM-DD')].employees.find(function (x) {
            return x.id === employee.id;
        }));

        if (activityCode !== 'A') {
            // Remove any availability records after the first
            record.splice(1);

            // Mark the EE as unavailable
            Ember.set(record[0], 'fromOpen', true);
            Ember.set(record[0], 'untilClose', true);
            Ember.set(record[0], 'isAvailable', false);

            if (activityCode !== 'N') {
                Ember.set(record[0], 'activityCode', activityCode);
                Ember.set(record[0], 'reasonCode', activityCode);
            }

            if (activityCode === 'J' || activityCode === 'V') {
                Ember.set(record[0], 'hours', parseFloat(Ember.$('#availability-hours').find('input').val()));
            }
            if (activityCode === 'O') {
                // Note: From AMH 2020-01-28
                // Per request from Mark and Bob, I am disabling the logic
                // to add 8 hours to Request Off record. I informed them that
                // adding 8 hours is the operation of the existing Legacy
                // availability page, and until this point ever request off
                // record in the database includes 8 hours.
                // set(record[0], 'hours', 8.0)
                Ember.set(record[0], 'hours', 0);
            }
        }

        // loadingRecord is the existing record
        var loadingRecord = [].concat(_toConsumableArray(record));

        // Check if record needs to have "non available" blocks added
        var pushOpen = record.every(function (x) {
            return !x.fromOpen;
        });
        var pushClose = record.every(function (x) {
            return !x.untilClose;
        });
        if (pushOpen) {
            var load = record[0];
            var cp = _extends({}, load);

            cp.start = (0, _npmMoment.default)(date);
            cp.start.set({ hours: 0, minutes: 0, seconds: 0 });
            cp.end = load.start.clone();
            cp.untilClose = false;
            cp.isAvailable = false;
            record = [cp].concat(_toConsumableArray(record));
        }
        if (pushClose) {
            var _record$slice = record.slice(-1),
                _record$slice2 = _slicedToArray(_record$slice, 1),
                _load = _record$slice2[0];

            var _cp = _extends({}, _load);

            _cp.start = _load.end.clone();
            _cp.end = (0, _npmMoment.default)(date);
            _cp.end.set({ hours: 23, minutes: 59, seconds: 59 });
            _cp.fromOpen = false;
            _cp.isAvailable = false;
            record = [].concat(_toConsumableArray(record), [_cp]);
        }
        if (record.length > 1) {
            record.forEach(function (item, index) {
                if (index + 1 < record.length && item.isAvailable && !item.untilClose && record[index + 1].isAvailable) {
                    var _cp2 = _extends({}, item);
                    _cp2.start = item.end.clone();
                    _cp2.end = record[index + 1].start.clone();
                    _cp2.fromOpen = false;
                    _cp2.untilClose = false;
                    _cp2.isAvailable = false;
                    _cp2.isAllDay = false;
                    record.splice(index + 1, 0, _cp2);
                }
            });
        }

        // reduxRecord is used in pushing to REDUX (not to API)
        // apiRecord is used in pushing to API
        var reduxRecord = [].concat(_toConsumableArray(record));
        var apiRecord = [].concat(_toConsumableArray(record));

        // Data normalization
        reduxRecord.forEach(function (x) {
            if (x.fromOpen && x.start === null) {
                Ember.set(x, 'start', (0, _npmMoment.default)(date).set({ hours: 0, minutes: 0, seconds: 0 }));
            }
            if (x.untilClose && x.end === null) {
                Ember.set(x, 'end', (0, _npmMoment.default)(date).set({ hours: 23, minutes: 59, seconds: 59 }));
            }
            x.isDefault = isDefault;
        });

        if (activityCode !== 'A' && activityCode !== 'N' && activityCode !== 'X' && isDefault) {
            isDefault = false;
        }
        // console.log({activityCode, isDefault})

        // Send to REDUX store
        store.dispatch({
            type: _types.NODE_EE_UPDATE_AVAILABILITY,
            payload: {
                record: loadingRecord,
                weekEndDate: weekEndDate.format('YYYY-MM-DD'),
                employeeId: employee.id,
                availabilityType: isDefault ? 'default' : 'exceptions',
                loading: true,
                storeTag: storeTag,
                dotw: dotw
            }
        });

        // Prep for API
        apiRecord.forEach(function (x) {
            Ember.set(x, 'storeCode', storeCode);
            Ember.set(x, 'employeeCode', employee.code);
            Ember.set(x, 'isDefault', isDefault);
            Ember.set(x, 'homeStore', employee.store.code);

            delete x.employee;
            delete x.id;

            if ('reason' in x) {
                delete x.reason;
            }

            if ('reasonCode' in x) {
                delete x.reasonCode;
            }

            if ('creditHours' in x) {
                delete x.creditHours;
            }

            Ember.set(x, 'start', x.fromOpen ? null : x.start);
            Ember.set(x, 'end', x.untilClose ? null : x.end);
            Ember.set(x, 'date', (0, _cleaners.demomentize)(date, 'YYYY-MM-DD'));

            return x;
        });

        var availability = (0, _cleaners.clean_keys)((0, _cleaners.demomentize)(apiRecord, 'HH:mm'), false);
        var service = new _notificationService.default();

        // Send to API
        (0, _interactions.send)({
            header: 'do',
            body: {
                service: 'data',
                namespace: ['availability', 'load'],
                operation: 1,
                include_channel: 1,
                payload: {
                    availability: availability
                },
                params: {
                    complete: false
                }
                // response_type: 'SUCCESS',
            }
        }, 'EE Availability save', false, function (results) {
            store.dispatch({
                type: _types.NODE_EE_UPDATE_AVAILABILITY,
                payload: {
                    record: reduxRecord,
                    weekEndDate: store.getState().cube.weekEndDate.format('YYYY-MM-DD'),
                    employeeId: employee.id,
                    storeTag: node.clientCode + '.' + node.tag,
                    availabilityType: isDefault ? 'default' : 'exceptions',
                    loading: false,
                    dotw: dotw
                }
            });

            service.success();
            if (store.getState().cube.page === 'scheduleEdit') {
                store.dispatch({ type: _types.SE_VALIDATE_SHIFTS });
            }
            (0, _interactions.push)({
                type: 'NODE_EE_UPDATE_AVAILABILITY',
                record: reduxRecord,
                weekEndDate: store.getState().cube.weekEndDate.format('YYYY-MM-DD'),
                employeeId: employee.id,
                storeTag: node.clientCode + '.' + node.tag,
                availabilityType: 'default',
                dotw: store.getState().employeePanel.dotw
            });
        }, function () {
            service.error('You cannot make that change right now.');
            store.dispatch({
                type: _types.NODE_EE_UPDATE_AVAILABILITY,
                payload: {
                    record: loadingRecord,
                    weekEndDate: store.getState().cube.weekEndDate.format('YYYY-MM-DD'),
                    employeeId: employee.id,
                    storeTag: node.clientCode + '.' + node.tag,
                    availabilityType: isDefault ? 'default' : 'exceptions',
                    loading: false,
                    failed: true,
                    dotw: dotw
                }
            });
        });

        if (store.getState().cube.page === 'scheduleEdit') {
            store.dispatch({ type: _types.SE_VALIDATE_SHIFTS });
        }

        return false;
    };

    beforeListeners[_types.EE_SAVE_SKILL] = function (store, payload) {
        var employee = payload.employee,
            activity = payload.activity,
            value = payload.value,
            onSuccess = payload.onSuccess,
            onFailure = payload.onFailure;

        var _store$getState3 = store.getState(),
            sid = _store$getState3.websocket.sid;

        (0, _interactions.setStore)(store);

        store.dispatch({
            type: _types.EE_UPDATE_SKILL,
            payload: {
                employeeId: employee.id,
                activityId: activity.id,
                level: value
            }
        });

        (0, _interactions.send)({
            header: 'do',
            body: {
                service: 'store',
                namespace: ['employee', 'details'],
                operation: 3,
                include_channel: 1,
                extra: ['store.' + employee.store.code.toLowerCase(), employee.code],
                payload: {
                    skills: [{
                        activity_code: activity.code,
                        level: value || '0.0'
                    }]
                },
                params: {
                    weekEndDate: store.getState().cube.weekEndDate.format('YYYY-MM-DD')
                }
                // response_type: 'SAVED',
            }
        }, 'EE Skill save ' + employee.code + ' - ' + activity.name, false, function () {
            // send(
            //     {
            //         header: 'do',
            //         body: {
            //             service: 'store',
            //             namespace: 'employee',
            //             operation: 0,
            //             include_channel: 2,
            //             broadcast: true,
            //             ignore: [sid],
            //             extra: [
            //                 store
            //                     .getState()
            //                     .cube.weekEndDate.format('YYYY-MM-DD'),
            //             ],
            //             params: {
            //                 active: true,
            //             },
            //             response_type: 'NODE_INTAKE',
            //         },
            //     },
            //     `retrieve list of employees: ${
            //         store.getState().cube.node.clientCode
            //     }.${
            //         store.getState().cube.node.tag
            //     }/${store.getState().cube.weekEndDate.format('YYYY-MM-DD')}`,
            //     true,
            // )
            (0, _interactions.push)({
                type: 'EE_UPDATE_SKILL',
                employeeId: employee.id,
                activityId: activity.id,
                level: value
            });
            if (onSuccess) {
                onSuccess();
            }
        }, onFailure);

        if (store.getState().cube.page === 'scheduleEdit') {
            store.dispatch({ type: _types.SE_VALIDATE_SHIFTS });
        }

        return false;
    };

    beforeListeners[_types.EE_SAVE_EMPLOYEE] = function (store, payload) {
        var employee = payload.employee,
            field = payload.field,
            value = payload.value,
            onSuccess = payload.onSuccess,
            onFailure = payload.onFailure,
            _payload$allowNull = payload.allowNull,
            allowNull = _payload$allowNull === undefined ? [] : _payload$allowNull;

        var _store$getState4 = store.getState(),
            sid = _store$getState4.websocket.sid;

        (0, _interactions.setStore)(store);

        store.dispatch({
            type: _types.EE_UPDATE_EMPLOYEE,
            payload: {
                employeeId: employee.id,
                value: value,
                field: field
            }
        });

        var dispatchPayload = {};

        if ([field, value].every(function (x) {
            return Array.isArray(x);
        })) {
            field.forEach(function (x, idx) {
                if (value[idx] !== null || value[idx] === null && allowNull.includes(x)) {
                    dispatchPayload[x] = value[idx];
                }
            });
        } else if ([field, value].some(function (x) {
            return Array.isArray(x);
        })) {
            console.error('Cannot process non-matching field and value types');
        } else {
            dispatchPayload[field] = value;
        }

        // console.log({dispatchPayload})

        (0, _interactions.send)({
            header: 'do',
            body: {
                service: 'store',
                namespace: ['employee', 'details'],
                operation: 3,
                include_channel: 1,
                extra: ['store.' + employee.store.code.toLowerCase(), 'EEID.' + employee.id],
                payload: dispatchPayload
                // params: {debug: 'true'},
            }
        }, 'EE field save ' + employee.code + ' - ' + field, false, function () {
            (0, _interactions.push)({
                type: 'EE_UPDATE_EMPLOYEE',
                employeeId: employee.id,
                value: value,
                field: field
            });
            if (onSuccess) {
                onSuccess();
            }
        }, onFailure);

        if (store.getState().cube.page === 'scheduleEdit') {
            store.dispatch({ type: _types.SE_VALIDATE_SHIFTS });
        }

        return false;
    };

    afterListeners[_types.EE_SEND_EMAIL] = function (store, payload) {
        store.dispatch({
            type: _types.EE_PREP_EMAIL,
            payload: {
                subject: '',
                message: '',
                recipients: new Set()
            }
        });
    };

    afterListeners[_types.EE_UPDATE_SKILL] = function (store, payload) {
        if (store.getState().cube.page === 'scheduleEdit') {
            store.dispatch({ type: _types.SE_VALIDATE_SHIFTS });
        }
    };

    afterListeners[_types.EE_UPDATE_EMPLOYEE] = function (store, payload) {
        if (store.getState().cube.page === 'scheduleEdit') {
            store.dispatch({ type: _types.SE_VALIDATE_SHIFTS });
        }
    };

    exports.default = function (store) {
        return function (next) {
            return function (action) {
                var type = action.type,
                    payload = action.payload;

                // Before listeners

                if (beforeListeners[type]) {
                    var ret = beforeListeners[type](store, payload);
                    if (ret !== undefined) {
                        if (ret === false) {
                            return;
                        } else {
                            action.payload = ret;
                        }
                    }
                }

                next(action);

                // After listeners
                if (afterListeners[type]) {
                    afterListeners[type](store, payload);
                }
            };
        };
    };
});