define('matrix-frontend/pods/components/sales-equation-report/component', ['exports', 'npm:underscore', 'npm:d3', 'ember-redux'], function (exports, _npmUnderscore, _npmD, _emberRedux) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var stateToComputed = function stateToComputed(state) {
        return {
            currentUser: state.user.currentUser,
            node: state.cube.node
        };
    };

    var Comp = Ember.Component.extend({
        classNames: ['sales-equation-report-container'],

        actions: {
            toggleVariance: function toggleVariance() {
                this.sendAction('action'); // eslint-disable-line ember/closure-actions
            }
        },

        didInsertElement: function didInsertElement() {
            this.draw();
        },

        currentStoreId: Ember.computed('node', function () {
            return this.node.storeId || '';
        }),

        currentHierarchyNodeId: Ember.computed('node', function () {
            return this.node.nodeId || '';
        }),

        draw: function () {
            // eslint-disable-line ember/no-function-prototype-extensions
            try {
                var data = this.get('crunchedData');
                var displayVariance = this.get('displayVariance');

                //clean up any existing
                _npmD.default.select('svg').remove();

                if (data) {
                    //tree structure
                    var treeData = [{
                        name: 'Sales',
                        parent: null,
                        value: data.sales.value,
                        color: data.sales.varToLy ? data.sales.color : 'LightGray',
                        fill: data.sales.varToLy ? data.sales.fill : '#fff',
                        varToLy: data.sales.varToLy,
                        children: [{
                            name: 'Trans',
                            parent: 'Sales',
                            value: data.transactions.value,
                            color: data.transactions.varToLy ? data.transactions.color : 'LightGray',
                            fill: data.transactions.varToLy ? data.transactions.fill : '#fff',
                            varToLy: data.transactions.varToLy,
                            children: [{
                                name: 'Traffic',
                                parent: 'Trans',
                                value: data.traffic.value,
                                color: data.traffic.varToLy ? data.traffic.color : 'LightGray',
                                fill: data.traffic.varToLy ? data.traffic.fill : '#fff',
                                varToLy: data.traffic.varToLy
                            }, {
                                name: 'Conv',
                                parent: 'Trans',
                                value: data.conv.value,
                                color: data.conv.varToLy ? data.conv.color : 'LightGray',
                                fill: data.conv.varToLy ? data.conv.fill : '#fff',
                                varToLy: data.conv.varToLy
                            }]
                        }, {
                            name: 'ADS',
                            parent: 'Sales',
                            value: data.ads.value,
                            color: data.ads.varToLy ? data.ads.color : 'LightGray',
                            fill: data.ads.varToLy ? data.ads.fill : '#fff',
                            varToLy: data.ads.varToLy,
                            children: [{
                                name: 'AUR',
                                parent: 'ADS',
                                value: data.aur.value,
                                color: data.aur.varToLy ? data.aur.color : 'LightGray',
                                fill: data.aur.varToLy ? data.aur.fill : '#fff',
                                varToLy: data.aur.varToLy
                            }, {
                                name: 'UPT',
                                parent: 'ADS',
                                value: data.upt.value,
                                color: data.upt.varToLy ? data.upt.color : 'LightGray',
                                fill: data.upt.varToLy ? data.upt.fill : '#fff',
                                varToLy: data.upt.varToLy
                            }]
                        }]
                    }];

                    // ************** Generate the tree diagram  *****************

                    var margin = { top: -15, right: 100, bottom: 0, left: 0 },
                        width = 305 - margin.right - margin.left,
                        height = 330 - margin.top - margin.bottom;

                    var i = 0;

                    var tree = _npmD.default.layout.tree().size([height, width]);

                    var line = _npmD.default.svg.line().interpolate('step').x(function (d) {
                        return d.x;
                    }).y(function (d) {
                        return d.y;
                    });

                    var svg = _npmD.default.select('#' + this.get('elementId')).insert('svg', ':first-child').attr('width', width + margin.right + margin.left).attr('height', height + margin.top + margin.bottom).append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

                    var root = treeData[0];

                    // Compute the new tree layout.
                    var nodes = tree.nodes(root).reverse(),
                        links = tree.links(nodes);

                    // Normalize for fixed-depth.
                    nodes.forEach(function (d) {
                        d.y = width - d.depth * 90;
                    });

                    // Declare the nodes…
                    var node = svg.selectAll('g.node').data(nodes, function (d) {
                        return d.id || (d.id = ++i);
                    });

                    // Enter the nodes.
                    var nodeEnter = node.enter().append('g').attr('class', 'node').attr('transform', function (d) {
                        return 'translate(' + d.y + ',' + (d.x - 20) + ')';
                    });

                    //draw the rectangle of each node
                    nodeEnter.append('rect').attr('width', 65).attr('height', 40).attr('stroke', function (d) {
                        return d.color;
                    }).attr('rx', 3).style('fill', function (d) {
                        return d.fill;
                    });

                    //render text inside each node with the value
                    nodeEnter.append('text').text(function (d) {
                        if (displayVariance) {
                            return d.varToLy ? d.varToLy : '-';
                        } else {
                            return d.value;
                        }
                    }).attr('y', 22.5).attr('x', 3).attr('class', 'nodeText').style('font-size', '12px').style('pointer-events', 'none');

                    //render text labels for each item name
                    nodeEnter.append('text').attr('x', 40).attr('y', -10).attr('dy', '.35em').style('font-size', '12px').attr('text-anchor', 'end').text(function (d) {
                        return d.name;
                    }).style('fill-opacity', 1);

                    // Declare the links…
                    var link = svg.selectAll('path.link').data(links, function (d) {
                        return d.target.id;
                    });

                    // Enter the links.
                    link.enter().insert('path', 'g').attr('class', 'link').attr('d', function (d) {
                        return line([{
                            y: d.source.x,
                            x: d.source.y
                        }, {
                            y: d.target.x,
                            x: d.target.y
                        }]);
                    });
                }
            } catch (e) {
                console.error(e);
            }
        }.observes('crunchedData', 'displayVariance'),

        crunchedData: Ember.computed('data', function () {
            var values = {};
            var data = this.get('data');

            if (data && !_npmUnderscore.default.isEmpty(data)) {
                data = this.normalizeData(data);

                var aggregateData = this.aggregateData(data);

                //calc data

                //transactions
                values.transactions = {};
                var transVar = aggregateData.transLy && aggregateData.trans ? (aggregateData.transTyComp - aggregateData.transLy) / aggregateData.transLy * 100 : null;
                values.transactions.varToLy = transVar ? this.percentFilter(Math.round(transVar)) : null;
                values.transactions.value = aggregateData.trans ? this.smallNumberFilter(aggregateData.trans) : null;
                values.transactions.color = transVar < 0 ? 'DarkRed' : 'ForestGreen';
                values.transactions.fill = values.transactions.color === 'DarkRed' ? '#E8CCCC' : '#D3E8D3';

                values.units = {};
                values.units.value = aggregateData.units ? this.smallNumberFilter(aggregateData.units) : null;

                //sales
                values.sales = {};
                var salesVar = aggregateData.salesLy && aggregateData.sales ? (aggregateData.salesTyComp - aggregateData.salesLy) / aggregateData.salesLy * 100 : null;
                values.sales.value = aggregateData.sales ? '$' + this.smallNumberFilter(aggregateData.sales) : null;
                values.sales.color = salesVar < 0 ? 'DarkRed' : 'ForestGreen';
                values.sales.fill = values.sales.color === 'DarkRed' ? '#E8CCCC' : '#D3E8D3';
                values.sales.varToLy = salesVar ? this.percentFilter(Math.round(salesVar)) : null;

                //traffic
                values.traffic = {};
                var trafficVar = aggregateData.trafficLy && aggregateData.traffic && aggregateData.transForConvLy < aggregateData.trafficLy ? (aggregateData.trafficTyComp - aggregateData.trafficLy) / aggregateData.trafficLy * 100 : null;
                values.traffic.value = aggregateData.traffic ? this.smallNumberFilter(aggregateData.traffic) : null;
                values.traffic.color = trafficVar < 0 ? 'DarkRed' : 'ForestGreen';
                values.traffic.fill = values.traffic.color === 'DarkRed' ? '#E8CCCC' : '#D3E8D3';
                values.traffic.varToLy = trafficVar ? this.percentFilter(Math.round(trafficVar)) : null;

                //avg unit of retail
                var aur = Math.round(aggregateData.sales / aggregateData.units * 100) / 100;
                var aurLc = Math.round(aggregateData.salesForAurTy / aggregateData.unitsTyComp * 100) / 100;
                var aurLy = Math.round(aggregateData.salesForAurLy / aggregateData.unitsLy * 100) / 100;
                values.aur = {};
                values.aur.varToLy = aurLc && aurLy ? (aurLc - aurLy) / aurLc * 100 : null;
                values.aur.color = values.aur.varToLy < 0 ? 'DarkRed' : 'ForestGreen';
                values.aur.varToLy = aurLc && aurLy ? this.percentFilter(Math.round(values.aur.varToLy)) : null;
                values.aur.value = aur ? '$' + aur : '';
                values.aur.fill = values.aur.color === 'DarkRed' ? '#E8CCCC' : '#D3E8D3';

                //units per trans
                var upt = Math.round(aggregateData.units / aggregateData.trans * 100) / 100;
                var uptLc = Math.round(aggregateData.unitsForUptTy / aggregateData.transTyComp * 100) / 100;
                var uptLy = Math.round(aggregateData.unitsForUptLy / aggregateData.transLy * 100) / 100;
                values.upt = {};
                values.upt.value = aggregateData.trans ? upt : null;
                values.upt.color = uptLc < uptLy ? 'DarkRed' : 'ForestGreen';
                values.upt.fill = values.upt.color === 'DarkRed' ? '#E8CCCC' : '#D3E8D3';
                values.upt.varToLy = this.percentFilter(Math.round((uptLc - uptLy) / uptLc * 100));

                //avg dollar sale
                var ads = Math.round(aggregateData.sales / aggregateData.trans * 100) / 100;
                var adsLc = Math.round(aggregateData.salesForAdsTy / aggregateData.transTyComp * 100) / 100;
                var adsLy = Math.round(aggregateData.salesForAdsLy / aggregateData.transLy * 100) / 100;
                values.ads = {};
                values.ads.value = aggregateData.trans ? '$' + ads : null;
                values.ads.color = adsLc < adsLy ? 'DarkRed' : 'ForestGreen';
                values.ads.fill = values.ads.color === 'DarkRed' ? '#E8CCCC' : '#D3E8D3';
                values.ads.varToLy = this.percentFilter(Math.round((adsLc - adsLy) / adsLc * 100));

                //conversion
                var conv = Math.round(aggregateData.transForConv / aggregateData.traffic * 1000) / 10;
                var convLc = Math.round(aggregateData.transForConvTy / aggregateData.trafficTyComp * 1000) / 10;
                var convLy = Math.round(aggregateData.transForConvLy / aggregateData.trafficLy * 1000) / 10;
                values.conv = {};
                values.conv.value = conv ? conv + '%' : null;
                values.conv.color = convLc < convLy ? 'DarkRed' : 'ForestGreen';
                values.conv.fill = values.conv.color === 'DarkRed' ? '#E8CCCC' : '#D3E8D3';
                values.conv.varToLy = convLc && convLy && aggregateData.transForConvLy < aggregateData.trafficLy ? this.percentFilter(Math.round(convLc - convLy)) : null;

                return values;
            } else {
                return null;
            }
        }),

        normalizeData: function normalizeData(data) {
            var types = ['LC', 'LY', 'current'];
            var keys = ['Trans', 'Units', 'Sales', 'TrafficIn'];

            _npmUnderscore.default.each(types, function (type) {
                if (!data[type]) {
                    data[type] = {};
                } else {
                    _npmUnderscore.default.each(keys, function (key) {
                        if (!data[type][key]) {
                            data[type][key] = null;
                        }
                    });
                }
            });

            return data;
        },

        aggregateData: function aggregateData(data) {
            var aggregateData = {
                sales: 0,
                salesLy: 0,
                salesTyComp: 0,

                traffic: 0,
                trafficLy: 0,
                trafficTyComp: 0,

                trans: 0,
                transLy: 0,
                transTyComp: 0,
                transForConv: 0,

                units: 0,
                unitsLy: 0,
                unitsTyComp: 0,

                transForConvLy: 0,
                salesForAurLy: 0,
                unitsForUptLy: 0,
                salesForAdsLy: 0,

                transForConvTy: 0,
                salesForAurTy: 0,
                unitsForUptTy: 0,
                salesForAdsTy: 0
            };

            for (var i = 0; i < data.length; i++) {
                var d = data[i];

                aggregateData.sales += d.salesvalue;
                aggregateData.salesLy += d.salesvaluely;
                aggregateData.salesTyComp += d.salesvaluecomp;
                aggregateData.salesForAurLy += d.salesforaurly;
                aggregateData.salesForAurTy += d.salesforaurty;
                aggregateData.salesForAdsLy += d.salesforadsly;
                aggregateData.salesForAdsTy += d.salesforadsty;

                aggregateData.units += d.unitsvalue;
                aggregateData.unitsLy += d.unitsvaluely;
                aggregateData.unitsTyComp += d.unitsvaluecomp;
                aggregateData.unitsForUptLy += d.unitsforuptly;
                aggregateData.unitsForUptTy += d.unitsforuptty;

                aggregateData.trans += d.transvalue;
                aggregateData.transLy += d.transvaluely;
                aggregateData.transTyComp += d.transvaluecomp;
                aggregateData.transForConvLy += d.transforconvly;
                aggregateData.transForConvTy += d.transforconvty;
                aggregateData.transForConv += d.transforconv;

                aggregateData.traffic += d.trafficvalue;
                aggregateData.trafficLy += d.trafficvaluely;
                aggregateData.trafficTyComp += d.trafficvaluecomp;
            }
            return aggregateData;
        },

        smallNumberFilter: function smallNumberFilter(input) {
            if (input < 1000) {
                return Math.round(input * 10) / 10;
            } else if (input >= 1000 && input < 1000000) {
                return Math.round(input / 100) / 10 + 'K';
            } else if (input >= 1000000 && input < 1000000000) {
                return Math.round(input / 100000) / 10 + 'M';
            }
        },

        percentFilter: function percentFilter(input) {
            if (!isNaN(input)) {
                return Math.round(input) + '%';
            }
        }
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, {})(Comp);
});