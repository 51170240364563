define('matrix-frontend/pods/components/shift-info-panel/component', ['exports', 'ember-redux', 'npm:moment'], function (exports, _emberRedux, _npmMoment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var stateToComputed = function stateToComputed(state) {
        return {
            clients: state.client.clients,
            node: state.cube.node
        };
    };
    // import * as types from 'matrix-frontend/actions/_types';


    var dispatchToActions = function dispatchToActions(dispatch) {
        return {};
    };

    var Comp = Ember.Component.extend({
        currentClient: Ember.computed('clients', 'node', function () {
            var node = this.get('node');
            var clients = this.get('clients');
            return node && clients ? clients[node.clientCode] : null;
        }),

        activities: Ember.computed('currentClient', function () {
            var currentClient = this.get('currentClient');
            var a = currentClient && currentClient.activities ? currentClient.activities.reduce(function (o, i) {
                o[i.id] = i;
                return o;
            }, {}) : null;
            return a;
        }),

        shiftBlocks: Ember.computed('shift', function () {
            var shift = this.get('shift');
            var blocks = shift ? shift.blocks : [];
            // const b = blocks.sort((x, y) => y.start.isBefore(x.start));
            // return b;
            return blocks.sortBy('start');
        }),

        errorsLength: Ember.computed('shift.errors', function () {
            var shift = this.get('shfit');
            var length = shift && shift.errors ? shift.errors.length : 0;
            return length;
        }),

        totalHours: Ember.computed('shift', function () {
            var shift = this.get('shift');
            var activities = this.get('activities');
            var totalHours = 0;
            var blocks = shift ? shift.blocks : [];
            blocks.forEach(function (block) {
                var activityId = block.activityId;

                var activity = activities[activityId];
                if (!activity.isCallin) {
                    totalHours += block.end.diff(block.start, 'hours', true);
                }
            });
            return totalHours;
        })
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});