define('matrix-frontend/pods/apidocs/controller', ['exports', 'npm:swagger-ui-dist'], function (exports, _npmSwaggerUiDist) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var SwaggerUIBundle = _npmSwaggerUiDist.default.SwaggerUIBundle,
        SwaggerUIStandalonePreset = _npmSwaggerUiDist.default.SwaggerUIStandalonePreset;
    exports.default = Ember.Controller.extend({
        swaggerConfig: {
            // url: config.docsURL,
            deepLinking: false,
            presets: [SwaggerUIStandalonePreset, SwaggerUIBundle.presets.apis, SwaggerUIBundle.plugins.DownloadUrl],
            layout: 'BaseLayout',
            docExpansion: 'none',
            tagsSorter: 'alpha',
            operationsSorter: 'alpha',
            defaultModelsExpandDepth: -1,
            defaultModelExpandDepth: 1,
            validatorUrl: 'https://online.swagger.io/validator',
            spec: {
                openapi: '3.0.0',
                info: {
                    title: 'Matrix Live API',
                    version: 'v2.5-beta',
                    license: {
                        name: 'Proprietary License'
                    },
                    description: 'In general paths will take the following format: `/{version}/{service}/{namespace}/{specifiers:optional}/{client_code}`.'
                },
                servers: [{
                    url: 'https://live.mxr/{version}/{service}',
                    description: 'The development API server',
                    variables: {
                        version: {
                            enum: ['v2'],
                            default: 'v2'
                        },
                        service: {
                            enum: ['auth', 'client', 'data', 'feed', 'store', 'user'],
                            default: 'auth'
                        }
                    }
                }, {
                    url: 'https://mxltest.matrixretail.com/{version}/{service}',
                    description: 'The TEST API server',
                    variables: {
                        version: {
                            enum: ['v2'],
                            default: 'v2'
                        },
                        service: {
                            enum: ['auth', 'client', 'data', 'feed', 'store', 'user'],
                            default: 'auth'
                        }
                    }
                }, {
                    url: 'https://mxlstage.matrixretail.com/{version}/{service}',
                    description: 'The STAGE API server',
                    variables: {
                        version: {
                            enum: ['v2'],
                            default: 'v2'
                        },
                        service: {
                            enum: ['auth', 'client', 'data', 'feed', 'store', 'user'],
                            default: 'auth'
                        }
                    }
                }, {
                    url: 'https://live.matrixretail.com/{version}/{service}',
                    description: 'The production API server',
                    variables: {
                        version: {
                            enum: ['v2'],
                            default: 'v2'
                        },
                        service: {
                            enum: ['auth', 'client', 'data', 'feed', 'store', 'user'],
                            default: 'auth'
                        }
                    }
                }],
                tags: [{
                    name: 'auth',
                    description: 'Authentication service for getting and verifying tokens',
                    externalDocs: {
                        description: 'Find out more',
                        url: 'http://sanic-jwt.readthedocs.io/en/latest/'
                    }
                }, {
                    name: 'client',
                    description: 'Service to access client level data'
                }, {
                    name: 'data',
                    description: 'Service to access computed data'
                }, {
                    name: 'feed',
                    description: 'Websocket interaction layer'
                }, {
                    name: 'store',
                    description: 'Service to access store level data'
                }, {
                    name: 'user',
                    description: 'Service to access user level data'
                }],
                paths: {
                    '/isalive': {
                        get: {
                            tags: ['auth', 'client', 'data', 'store', 'user'],
                            summary: 'Check if a service is operating',
                            responses: {
                                '200': {
                                    description: '',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/okTrue'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/env': {
                        get: {
                            tags: ['auth', 'client', 'data', 'store', 'user'],
                            summary: 'Check environment details',
                            responses: {
                                '200': {
                                    description: '',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/environment'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/access': {
                        post: {
                            tags: ['auth'],
                            summary: 'Request access token given a legacy application token',
                            description: 'Currently, this endpoint only will issue a JWT when requested from the legacy Java application.',
                            responses: {
                                '200': {
                                    description: 'JWT issued'
                                }
                            },
                            parameters: [{
                                name: 'user_id',
                                in: 'query',
                                description: 'The user id',
                                required: true,
                                schema: {
                                    type: 'string'
                                }
                            }, {
                                name: 'token',
                                in: 'query',
                                description: 'The token that was issued by the legacy Java application',
                                required: true,
                                schema: {
                                    type: 'string'
                                }
                            }]
                        }
                    },
                    '/login': {
                        post: {
                            tags: ['auth'],
                            summary: 'Request access token from a username/password/client_code',
                            description: 'Currently, this endpoint is not enabled for nonstore employee users',
                            responses: {
                                '200': {
                                    description: 'JWT issued'
                                }
                            },
                            requestBody: {
                                description: 'Login credentials',
                                required: true,
                                content: {
                                    'application/json': {
                                        schema: {
                                            $ref: '#/components/schemas/login'
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/refresh': {
                        post: {
                            tags: ['auth'],
                            summary: 'Request access token from a refresh token',
                            description: 'Supply the endpoint with an access token (which may be expired), and a refresh token to generate a fresh access token',
                            responses: {
                                '200': {
                                    description: 'JWT issued'
                                }
                            },
                            parameters: [{
                                name: 'access_token',
                                in: 'header',
                                required: true,
                                description: 'JWT access token issued by the Matrix Live Authentication Service (CAN BE EXPIRED)',
                                schema: {
                                    type: 'string'
                                }
                            }],
                            requestBody: {
                                description: 'Refresh token issued by the Matrix Live Authentication Service',
                                required: true,
                                content: {
                                    'application/json': {
                                        schema: {
                                            $ref: '#/components/schemas/refresh_token'
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/verify': {
                        get: {
                            tags: ['auth'],
                            summary: 'Check whether an access token is acceptable',
                            responses: {
                                '200': {
                                    description: ''
                                }
                            },
                            parameters: [{
                                name: 'access_token',
                                in: 'header',
                                required: true,
                                description: 'JWT access token issued by the Matrix Live Authentication Service',
                                schema: {
                                    type: 'string'
                                }
                            }]
                        }
                    },
                    '/ops': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'operation_call',
                            in: 'path',
                            required: true,
                            description: 'The name of the operation to be called',
                            schema: {
                                $ref: '#/components/schemas/operation_call'
                            }
                        }],
                        get: {
                            summary: 'Retrieve list of acceptable command procedures',
                            tags: ['client'],
                            description: 'List available operations',
                            responses: {
                                '200': {
                                    description: '...',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/operationProcedureList'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/ops/{operation_call}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'operation_call',
                            in: 'path',
                            required: true,
                            description: 'The name of the operation to be called',
                            schema: {
                                $ref: '#/components/schemas/operation_call'
                            }
                        }],
                        post: {
                            summary: 'Run a client operation procedure',
                            tags: ['client'],
                            description: 'Perform an operation',
                            responses: {
                                '200': {
                                    description: '...'
                                }
                            },
                            requestBody: {
                                description: 'Parameters needed to perform operation. `client_code` is required, but anything you need to pass in will be accepted.',
                                required: true,
                                content: {
                                    'application/json': {
                                        schema: {
                                            $ref: '#/components/schemas/operation_body'
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/activities/{client_code}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }],
                        get: {
                            tags: ['client'],
                            summary: 'Retrieve a list of ALL activities for a client',
                            responses: {
                                '200': {
                                    description: 'Zero or more objects found',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                type: 'object',
                                                properties: {
                                                    start: {
                                                        type: 'integer'
                                                    },
                                                    count: {
                                                        type: 'integer'
                                                    },
                                                    length: {
                                                        type: 'integer'
                                                    },
                                                    data: {
                                                        type: 'array',
                                                        items: {
                                                            $ref: '#/components/schemas/activity'
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                },
                                '401': {
                                    description: 'Not authorized'
                                }
                            }
                        },
                        post: {
                            tags: ['client'],
                            summary: 'Create a new activity',
                            responses: {
                                '201': {
                                    description: 'Entity created',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityCreated'
                                            }
                                        }
                                    }
                                },
                                '400': {
                                    description: 'Error in data validation',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityNotCreated'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/activities/{client_code}/{activity_id}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'activity_id',
                            in: 'path',
                            required: true,
                            schema: {
                                $ref: '#/components/schemas/activity_code'
                            }
                        }],
                        get: {
                            tags: ['client'],
                            summary: 'Retrieve a activity',
                            responses: {
                                '200': {
                                    description: 'The activity',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/activity'
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        put: {
                            tags: ['client'],
                            summary: 'Update a activity',
                            responses: {
                                '200': {
                                    description: 'Entity updated',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/okTrue'
                                            }
                                        }
                                    }
                                },
                                '400': {
                                    description: 'Error in data validation',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityNotCreated'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/details': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }],
                        get: {
                            tags: ['client'],
                            summary: 'Retrieve a list of ALL clients',
                            responses: {
                                '200': {
                                    description: 'Zero or more objects found',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                type: 'object',
                                                properties: {
                                                    start: {
                                                        type: 'integer'
                                                    },
                                                    count: {
                                                        type: 'integer'
                                                    },
                                                    length: {
                                                        type: 'integer'
                                                    },
                                                    data: {
                                                        type: 'array',
                                                        items: {
                                                            $ref: '#/components/schemas/client'
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        post: {
                            tags: ['client'],
                            summary: 'Create a new client',
                            responses: {
                                '201': {
                                    description: 'Entity created',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityCreated'
                                            }
                                        }
                                    }
                                },
                                '400': {
                                    description: 'Error in data validation',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityNotCreated'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/details/{client_code}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }],
                        get: {
                            tags: ['client'],
                            summary: 'Retrieve a client',
                            responses: {
                                '200': {
                                    description: 'The client',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/client'
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        put: {
                            tags: ['client'],
                            summary: 'Update a client',
                            responses: {
                                '200': {
                                    description: 'Entity updated',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/okTrue'
                                            }
                                        }
                                    }
                                },
                                '400': {
                                    description: 'Error in data validation',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityNotCreated'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/budgets/{client_code}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }],
                        get: {
                            tags: ['client'],
                            summary: 'Retrieve a list of budgets',
                            responses: {
                                '200': {
                                    description: 'Zero or more objects found',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                type: 'object',
                                                properties: {
                                                    start: {
                                                        type: 'integer'
                                                    },
                                                    count: {
                                                        type: 'integer'
                                                    },
                                                    length: {
                                                        type: 'integer'
                                                    },
                                                    data: {
                                                        type: 'array',
                                                        items: {
                                                            $ref: '#/components/schemas/budget'
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        post: {
                            tags: ['client'],
                            summary: 'Create a new budget',
                            requestBody: {
                                required: true,
                                content: {
                                    'application/json': {
                                        schema: {
                                            $ref: '#/components/schemas/budgetEdit'
                                        }
                                    }
                                }
                            },
                            responses: {
                                '201': {
                                    description: 'Entity created',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityCreated'
                                            }
                                        }
                                    }
                                },
                                '400': {
                                    description: 'Error in data validation',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityNotCreated'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/budgets/{client_code}/{budget_id}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'budget_id',
                            in: 'path',
                            required: true,
                            description: '...',
                            schema: {
                                $ref: '#/components/schemas/budget_code'
                            }
                        }],
                        get: {
                            tags: ['client'],
                            summary: 'Retrieve a budget',
                            responses: {
                                '200': {
                                    description: 'The budget',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/budget'
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        put: {
                            tags: ['client'],
                            summary: 'Update a budget',
                            requestBody: {
                                required: true,
                                content: {
                                    'application/json': {
                                        schema: {
                                            $ref: '#/components/schemas/budgetEdit'
                                        }
                                    }
                                }
                            },
                            responses: {
                                '200': {
                                    description: 'Entity updated',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/okTrue'
                                            }
                                        }
                                    }
                                },
                                '400': {
                                    description: 'Error in data validation',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityNotCreated'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/exchange-rates/{client_code}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }],
                        get: {
                            tags: ['client'],
                            summary: 'Retrieve a list of exchange rates',
                            responses: {
                                '200': {
                                    description: 'Zero or more objects found',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                type: 'object',
                                                properties: {
                                                    start: {
                                                        type: 'integer'
                                                    },
                                                    count: {
                                                        type: 'integer'
                                                    },
                                                    length: {
                                                        type: 'integer'
                                                    },
                                                    data: {
                                                        type: 'array',
                                                        items: {
                                                            $ref: '#/components/schemas/exchangeRate'
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        post: {
                            tags: ['client'],
                            summary: 'Create a new exchange rate',
                            requestBody: {
                                required: true,
                                content: {
                                    'application/json': {
                                        schema: {
                                            $ref: '#/components/schemas/exchangeRateEdit'
                                        }
                                    }
                                }
                            },
                            responses: {
                                '201': {
                                    description: 'Entity created',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityCreated'
                                            }
                                        }
                                    }
                                },
                                '400': {
                                    description: 'Error in data validation',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityNotCreated'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/exchange-rates/{client_code}/{exchange_rate_id}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'exchange_rate_id',
                            in: 'path',
                            required: true,
                            description: '...',
                            schema: {
                                $ref: '#/components/schemas/budget_code'
                            }
                        }],
                        get: {
                            tags: ['client'],
                            summary: 'Retrieve a exchange rate',
                            responses: {
                                '200': {
                                    description: 'The exchange rate',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/exchangeRate'
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        put: {
                            tags: ['client'],
                            summary: 'Update an exchange rate',
                            requestBody: {
                                required: true,
                                content: {
                                    'application/json': {
                                        schema: {
                                            $ref: '#/components/schemas/exchangeRateEdit'
                                        }
                                    }
                                }
                            },
                            responses: {
                                '200': {
                                    description: 'Entity updated',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/okTrue'
                                            }
                                        }
                                    }
                                },
                                '400': {
                                    description: 'Error in data validation',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityNotCreated'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/hierarchy/{client_code}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }],
                        get: {
                            tags: ['client'],
                            summary: 'Retrieve a list of hierarchy nodes for a client',
                            responses: {
                                '200': {
                                    description: 'Zero or more objects found',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                type: 'object',
                                                properties: {
                                                    start: {
                                                        type: 'integer'
                                                    },
                                                    count: {
                                                        type: 'integer'
                                                    },
                                                    length: {
                                                        type: 'integer'
                                                    },
                                                    data: {
                                                        type: 'array',
                                                        items: {
                                                            $ref: '#/components/schemas/hierarchy_node'
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                },
                                '401': {
                                    description: 'Not authorized'
                                }
                            }
                        },
                        post: {
                            tags: ['client'],
                            summary: 'Create a new hierarchy node',
                            requestBody: {
                                required: true,
                                content: {
                                    'application/json': {
                                        schema: {
                                            $ref: '#/components/schemas/hierarchy_node'
                                        }
                                    }
                                }
                            },
                            responses: {
                                '201': {
                                    description: 'Entity created',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityCreated'
                                            }
                                        }
                                    }
                                },
                                '400': {
                                    description: 'Error in data validation',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityNotCreated'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/hierarchy/{client_code}/{hierarchy_code}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'hierarchy_code',
                            in: 'path',
                            required: true,
                            description: '...',
                            schema: {
                                $ref: '#/components/schemas/hierarchy_code'
                            }
                        }],
                        get: {
                            tags: ['client'],
                            summary: 'Retrieve a hierarchy node',
                            responses: {
                                '200': {
                                    description: 'The hierarchy node',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/hierarchy_node'
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        put: {
                            tags: ['client'],
                            summary: 'Update a hierarchy node',
                            requestBody: {
                                required: true,
                                content: {
                                    'application/json': {
                                        schema: {
                                            $ref: '#/components/schemas/hierarchy_node'
                                        }
                                    }
                                }
                            },
                            responses: {
                                '200': {
                                    description: 'Entity updated',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/okTrue'
                                            }
                                        }
                                    }
                                },
                                '400': {
                                    description: 'Error in data validation',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityNotCreated'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/hierarchy/details/{client_code}/{tag}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'tag',
                            in: 'path',
                            required: true,
                            description: 'The tag associated with the entity being requested',
                            schema: {
                                $ref: '#/components/schemas/tag'
                            }
                        }],
                        get: {
                            tags: ['client'],
                            summary: 'Retrieve details for a single hierarchy node',
                            responses: {
                                '200': {
                                    description: 'Object found',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/hierarchy_node'
                                            }
                                        }
                                    }
                                },
                                '401': {
                                    description: 'Not authorized'
                                },
                                '404': {
                                    description: 'No object found'
                                }
                            }
                        },
                        put: {
                            deprecated: true,
                            tags: ['client'],
                            summary: '~ NOT IMPLEMENTED ~',
                            responses: {
                                '200': {
                                    description: '...'
                                }
                            }
                        }
                    },
                    '/labor-drivers/{client_code}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }],
                        get: {
                            tags: ['client'],
                            summary: 'Retrieve a list of labor drivers',
                            responses: {
                                '200': {
                                    description: 'Zero or more objects found',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                type: 'object',
                                                properties: {
                                                    start: {
                                                        type: 'integer'
                                                    },
                                                    count: {
                                                        type: 'integer'
                                                    },
                                                    length: {
                                                        type: 'integer'
                                                    },
                                                    data: {
                                                        type: 'array',
                                                        items: {
                                                            $ref: '#/components/schemas/labor_driver'
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        post: {
                            tags: ['client'],
                            summary: 'Create a new labor driver',
                            requestBody: {
                                required: true,
                                content: {
                                    'application/json': {
                                        schema: {
                                            $ref: '#/components/schemas/labor_driverEdit'
                                        }
                                    }
                                }
                            },
                            responses: {
                                '201': {
                                    description: 'Entity created',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityCreated'
                                            }
                                        }
                                    }
                                },
                                '400': {
                                    description: 'Error in data validation',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityNotCreated'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/labor-drivers/{client_code}/{driver_id}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'driver_id',
                            in: 'path',
                            required: true,
                            schema: {
                                $ref: '#/components/schemas/driver_code'
                            }
                        }],
                        get: {
                            tags: ['client'],
                            summary: 'Retrieve a labor driver',
                            responses: {
                                '200': {
                                    description: 'The labor driver',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/labor_driver'
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        put: {
                            tags: ['client'],
                            summary: 'Update a labor driver',
                            requestBody: {
                                required: true,
                                content: {
                                    'application/json': {
                                        schema: {
                                            $ref: '#/components/schemas/labor_driverEdit'
                                        }
                                    }
                                }
                            },
                            responses: {
                                '200': {
                                    description: 'Entity updated',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/okTrue'
                                            }
                                        }
                                    }
                                },
                                '400': {
                                    description: 'Error in data validation',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityNotCreated'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/out-of-office-activities/{client_code}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }],
                        get: {
                            tags: ['client'],
                            summary: 'Retrieve a list of ALL out of office activities for a client',
                            responses: {
                                '200': {
                                    description: 'Zero or more objects found',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                type: 'object',
                                                properties: {
                                                    start: {
                                                        type: 'integer'
                                                    },
                                                    count: {
                                                        type: 'integer'
                                                    },
                                                    length: {
                                                        type: 'integer'
                                                    },
                                                    data: {
                                                        type: 'array',
                                                        items: {
                                                            $ref: '#/components/schemas/outOfOfficeActivity'
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                },
                                '401': {
                                    description: 'Not authorized'
                                }
                            }
                        },
                        post: {
                            tags: ['client'],
                            summary: 'Create a new activity',
                            responses: {
                                '201': {
                                    description: 'Entity created',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityCreated'
                                            }
                                        }
                                    }
                                },
                                '400': {
                                    description: 'Error in data validation',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityNotCreated'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/out-of-office-activities/{client_code}/{activity_id}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'activity_id',
                            in: 'path',
                            required: true,
                            schema: {
                                $ref: '#/components/schemas/activity_code'
                            }
                        }],
                        get: {
                            tags: ['client'],
                            summary: 'Retrieve an out of office activity',
                            responses: {
                                '200': {
                                    description: 'The out of office activity',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/outOfOfficeActivity'
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        put: {
                            tags: ['client'],
                            summary: 'Update an out of office activity',
                            responses: {
                                '200': {
                                    description: 'Entity updated',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/okTrue'
                                            }
                                        }
                                    }
                                },
                                '400': {
                                    description: 'Error in data validation',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityNotCreated'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/position/{client_code}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }],
                        get: {
                            tags: ['client'],
                            summary: 'Retrieve a list of positions',
                            responses: {
                                '200': {
                                    description: 'Zero or more objects found',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                type: 'object',
                                                properties: {
                                                    start: {
                                                        type: 'integer'
                                                    },
                                                    count: {
                                                        type: 'integer'
                                                    },
                                                    length: {
                                                        type: 'integer'
                                                    },
                                                    data: {
                                                        type: 'array',
                                                        items: {
                                                            $ref: '#/components/schemas/position'
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        post: {
                            tags: ['client'],
                            summary: 'Create a new position',
                            requestBody: {
                                required: true,
                                content: {
                                    'application/json': {
                                        schema: {
                                            $ref: '#/components/schemas/positionEdit'
                                        }
                                    }
                                }
                            },
                            responses: {
                                '201': {
                                    description: 'Entity created',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityCreated'
                                            }
                                        }
                                    }
                                },
                                '400': {
                                    description: 'Error in data validation',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityNotCreated'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/position/{client_code}/{position_id}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'position_id',
                            in: 'path',
                            required: true,
                            schema: {
                                $ref: '#/components/schemas/position_code'
                            }
                        }],
                        get: {
                            tags: ['client'],
                            summary: 'Retrieve a position',
                            responses: {
                                '200': {
                                    description: 'The position',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/position'
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        put: {
                            tags: ['client'],
                            summary: 'Update a position',
                            requestBody: {
                                required: true,
                                content: {
                                    'application/json': {
                                        schema: {
                                            $ref: '#/components/schemas/positionEdit'
                                        }
                                    }
                                }
                            },
                            responses: {
                                '200': {
                                    description: 'Entity updated',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/okTrue'
                                            }
                                        }
                                    }
                                },
                                '400': {
                                    description: 'Error in data validation',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityNotCreated'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/position-groups/{client_code}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }],
                        get: {
                            tags: ['client'],
                            summary: 'Retrieve a list of position groups',
                            responses: {
                                '200': {
                                    description: 'Zero or more objects found',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                type: 'object',
                                                properties: {
                                                    start: {
                                                        type: 'integer'
                                                    },
                                                    count: {
                                                        type: 'integer'
                                                    },
                                                    length: {
                                                        type: 'integer'
                                                    },
                                                    data: {
                                                        type: 'array',
                                                        items: {
                                                            $ref: '#/components/schemas/position_group'
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        post: {
                            tags: ['client'],
                            summary: 'Create a new position group',
                            requestBody: {
                                required: true,
                                content: {
                                    'application/json': {
                                        schema: {
                                            $ref: '#/components/schemas/position_groupEdit'
                                        }
                                    }
                                }
                            },
                            responses: {
                                '201': {
                                    description: 'Entity created',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityCreated'
                                            }
                                        }
                                    }
                                },
                                '400': {
                                    description: 'Error in data validation',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityNotCreated'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/position-groups/{client_code}/{position_group_id}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'position_group_id',
                            in: 'path',
                            required: true,
                            schema: {
                                $ref: '#/components/schemas/position_group_code'
                            }
                        }],
                        get: {
                            tags: ['client'],
                            summary: 'Retrieve a position group',
                            responses: {
                                '200': {
                                    description: 'The position group',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/position_group'
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        put: {
                            tags: ['client'],
                            summary: 'Update a position group',
                            requestBody: {
                                required: true,
                                content: {
                                    'application/json': {
                                        schema: {
                                            $ref: '#/components/schemas/position_groupEdit'
                                        }
                                    }
                                }
                            },
                            responses: {
                                '200': {
                                    description: 'Entity updated',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/okTrue'
                                            }
                                        }
                                    }
                                },
                                '400': {
                                    description: 'Error in data validation',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityNotCreated'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/task-types/{client_code}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }],
                        get: {
                            deprecated: true,
                            tags: ['client'],
                            summary: '~ NOT IMPLEMENTED ~',
                            description: 'Retrieve a list of task types',
                            responses: {
                                '200': {
                                    description: '...'
                                }
                            }
                        },
                        post: {
                            deprecated: true,
                            tags: ['client'],
                            summary: '~ NOT IMPLEMENTED ~',
                            description: 'Create a new task type',
                            responses: {
                                '201': {
                                    description: 'Entity created',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityCreated'
                                            }
                                        }
                                    }
                                },
                                '400': {
                                    description: 'Error in data validation',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityNotCreated'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/task-types/{client_code}/{task_type_code}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'task_type_code',
                            in: 'path',
                            required: true,
                            description: '...',
                            schema: {
                                $ref: '#/components/schemas/task_type_code'
                            }
                        }],
                        get: {
                            deprecated: true,
                            tags: ['client'],
                            summary: '~ NOT IMPLEMENTED ~',
                            description: 'Retrieve a task type',
                            responses: {
                                '200': {
                                    description: '...'
                                }
                            }
                        },
                        put: {
                            deprecated: true,
                            tags: ['client'],
                            summary: '~ NOT IMPLEMENTED ~',
                            description: 'Update a task type',
                            responses: {
                                '200': {
                                    description: '...'
                                }
                            }
                        }
                    },
                    '/validation/shift-rules/{client_code}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }],
                        get: {
                            summary: 'Retrieve the shift validation rules for a given client',
                            tags: ['client'],
                            responses: {
                                '200': {
                                    description: '',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                type: 'object',
                                                properties: {
                                                    start: {
                                                        type: 'integer'
                                                    },
                                                    count: {
                                                        type: 'integer'
                                                    },
                                                    length: {
                                                        type: 'integer'
                                                    },
                                                    data: {
                                                        type: 'array',
                                                        items: {
                                                            $ref: '#/components/schemas/shift_rule'
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                },
                                '401': {
                                    description: 'Not authorized'
                                }
                            }
                        }
                    },
                    '/validation/error-messages/{client_code}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }],
                        get: {
                            summary: 'Retrieve the validation error message severities for a given client',
                            tags: ['client'],
                            responses: {
                                '200': {
                                    description: '',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                type: 'object',
                                                properties: {
                                                    start: {
                                                        type: 'integer'
                                                    },
                                                    count: {
                                                        type: 'integer'
                                                    },
                                                    length: {
                                                        type: 'integer'
                                                    },
                                                    data: {
                                                        type: 'array',
                                                        items: {
                                                            $ref: '#/components/schemas/error_message'
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                },
                                '401': {
                                    description: 'Not authorized'
                                }
                            }
                        }
                    },
                    '/actuallabor/{client_code}/{tag}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'tag',
                            in: 'path',
                            required: true,
                            description: 'The tag associated with the entity being requested',
                            schema: {
                                $ref: '#/components/schemas/tag'
                            }
                        }],
                        get: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: 'Retrieve cached data',
                            tags: ['data'],
                            responses: {
                                '200': {
                                    description: 'Data retrieved'
                                }
                            }
                        },
                        patch: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: 'Rebuild cache for sales forecast data, and return built data',
                            tags: ['data'],
                            responses: {
                                '200': {
                                    description: 'Cache refreshed and data retrieved'
                                }
                            }
                        }
                    },
                    '/lostsales/{client_code}/{tag}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'tag',
                            in: 'path',
                            required: true,
                            description: 'The tag associated with the entity being requested',
                            schema: {
                                $ref: '#/components/schemas/tag'
                            }
                        }, {
                            name: 'focus',
                            in: 'path',
                            required: false,
                            description: 'The focus level to view data',
                            schema: {
                                $ref: '#/components/schemas/focus'
                            }
                        }, {
                            name: 'aggregate',
                            in: 'path',
                            required: false,
                            description: 'For nodes with stores below, combine data into one for the node, or return each store below',
                            schema: {
                                $ref: '#/components/schemas/aggregate'
                            }
                        }, {
                            name: 'cascade',
                            in: 'path',
                            required: false,
                            description: 'When updating the cached report, should parent reports be notified to also update.',
                            schema: {
                                $ref: '#/components/schemas/cascade'
                            }
                        }],
                        get: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: 'Retrieve cached data',
                            tags: ['data'],
                            responses: {
                                '200': {
                                    description: 'Data retrieved'
                                }
                            }
                        },
                        patch: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: 'Rebuild cache for sales forecast data, and return built data',
                            tags: ['data'],
                            responses: {
                                '200': {
                                    description: 'Cache refreshed and data retrieved'
                                }
                            }
                        }
                    },
                    '/optimallabor/{client_code}/{tag}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'tag',
                            in: 'path',
                            required: true,
                            description: 'The tag associated with the entity being requested',
                            schema: {
                                $ref: '#/components/schemas/tag'
                            }
                        }],
                        get: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: 'Retrieve cached data',
                            tags: ['data'],
                            responses: {
                                '200': {
                                    description: 'Data retrieved'
                                }
                            }
                        },
                        patch: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: 'Rebuild cache for optimal labor data, and return built data',
                            tags: ['data'],
                            responses: {
                                '200': {
                                    description: 'Cache refreshed and data retrieved'
                                }
                            }
                        }
                    },
                    '/salesatrisk/{client_code}/{tag}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'tag',
                            in: 'path',
                            required: true,
                            description: 'The tag associated with the entity being requested',
                            schema: {
                                $ref: '#/components/schemas/tag'
                            }
                        }, {
                            name: 'focus',
                            in: 'path',
                            required: false,
                            description: 'The focus level to view data',
                            schema: {
                                $ref: '#/components/schemas/focus'
                            }
                        }, {
                            name: 'aggregate',
                            in: 'path',
                            required: false,
                            description: 'For nodes with stores below, combine data into one for the node, or return each store below',
                            schema: {
                                $ref: '#/components/schemas/aggregate'
                            }
                        }, {
                            name: 'cascade',
                            in: 'path',
                            required: false,
                            description: 'When updating the cached report, should parent reports be notified to also update.',
                            schema: {
                                $ref: '#/components/schemas/cascade'
                            }
                        }],
                        get: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: 'Retrieve cached data',
                            tags: ['data'],
                            responses: {
                                '200': {
                                    description: 'Data retrieved'
                                }
                            }
                        },
                        patch: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: 'Rebuild cache for sales forecast data, and return built data',
                            tags: ['data'],
                            responses: {
                                '200': {
                                    description: 'Cache refreshed and data retrieved'
                                }
                            }
                        }
                    },
                    '/forecasts/{client_code}/{tag}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'tag',
                            in: 'path',
                            required: true,
                            description: 'The tag associated with the entity being requested',
                            schema: {
                                $ref: '#/components/schemas/tag'
                            }
                        }, {
                            name: 'focus',
                            in: 'path',
                            required: false,
                            description: 'The focus level to view data',
                            schema: {
                                $ref: '#/components/schemas/focus'
                            }
                        }, {
                            name: 'aggregate',
                            in: 'path',
                            required: false,
                            description: 'For nodes with stores below, combine data into one for the node, or return each store below',
                            schema: {
                                $ref: '#/components/schemas/aggregate'
                            }
                        }, {
                            name: 'cascade',
                            in: 'path',
                            required: false,
                            description: 'When updating the cached report, should parent reports be notified to also update.',
                            schema: {
                                $ref: '#/components/schemas/cascade'
                            }
                        }],
                        get: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: 'Retrieve cached data',
                            tags: ['data'],
                            responses: {
                                '200': {
                                    description: 'Data retrieved'
                                }
                            }
                        },
                        patch: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: 'Rebuild cache for sales forecast data, and return built data',
                            tags: ['data'],
                            responses: {
                                '200': {
                                    description: 'Cache refreshed and data retrieved'
                                }
                            }
                        }
                    },
                    '/salesactual/{client_code}/{tag}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'tag',
                            in: 'path',
                            required: true,
                            description: 'The tag associated with the entity being requested',
                            schema: {
                                $ref: '#/components/schemas/tag'
                            }
                        }, {
                            name: 'focus',
                            in: 'path',
                            required: false,
                            description: 'The focus level to view data',
                            schema: {
                                $ref: '#/components/schemas/focus'
                            }
                        }, {
                            name: 'aggregate',
                            in: 'path',
                            required: false,
                            description: 'For nodes with stores below, combine data into one for the node, or return each store below',
                            schema: {
                                $ref: '#/components/schemas/aggregate'
                            }
                        }, {
                            name: 'cascade',
                            in: 'path',
                            required: false,
                            description: 'When updating the cached report, should parent reports be notified to also update.',
                            schema: {
                                $ref: '#/components/schemas/cascade'
                            }
                        }],
                        get: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: 'Retrieve cached data',
                            tags: ['data'],
                            responses: {
                                '200': {
                                    description: 'Data retrieved'
                                }
                            }
                        },
                        patch: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: 'Rebuild cache for sales actual data, and return built data',
                            tags: ['data'],
                            responses: {
                                '200': {
                                    description: 'Cache refreshed and data retrieved'
                                }
                            }
                        }
                    },
                    '/schedulehours/{client_code}/{tag}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'tag',
                            in: 'path',
                            required: true,
                            description: 'The tag associated with the entity being requested',
                            schema: {
                                $ref: '#/components/schemas/tag'
                            }
                        }],
                        get: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: 'Retrieve cached data',
                            tags: ['data'],
                            responses: {
                                '200': {
                                    description: 'Data retrieved'
                                }
                            }
                        },
                        patch: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: 'Rebuild cache for sales forecast data, and return built data',
                            tags: ['data'],
                            responses: {
                                '200': {
                                    description: 'Cache refreshed and data retrieved'
                                }
                            }
                        }
                    },
                    '/schedules/{client_code}/{week_end_date}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'week_end_date',
                            in: 'path',
                            required: true,
                            description: 'The week end date in YYYY-MM-DD format',
                            schema: {
                                $ref: '#/components/schemas/week_end_date'
                            }
                        }],
                        post: {
                            summary: 'Create new schedule(s)',
                            description: 'Create new schedule(s) for a client for a given week.\n\n## TODO:\n- add version history\n\n## Optional fields\n- `overwrite`\n- `sherpa`\n',
                            tags: ['data'],
                            requestBody: {
                                required: true,
                                content: {
                                    'application/json': {
                                        schema: {
                                            type: 'object',
                                            properties: {
                                                schedules: {
                                                    type: 'array',
                                                    items: {
                                                        type: 'object',
                                                        properties: {
                                                            store_code: {
                                                                type: 'string',
                                                                format: '1234'
                                                            },
                                                            overwrite: {
                                                                type: 'boolean',
                                                                nullable: true
                                                            },
                                                            sherpa: {
                                                                type: 'string',
                                                                nullable: true
                                                            },
                                                            records: {
                                                                type: 'array',
                                                                items: {
                                                                    type: 'object',
                                                                    properties: {
                                                                        date: {
                                                                            type: 'string',
                                                                            format: 'date'
                                                                        },
                                                                        blocks: {
                                                                            type: 'array',
                                                                            items: {
                                                                                type: 'object',
                                                                                properties: {
                                                                                    activity: {
                                                                                        type: 'string'
                                                                                    },
                                                                                    start: {
                                                                                        type: 'string',
                                                                                        format: 'date-time'
                                                                                    },
                                                                                    end: {
                                                                                        type: 'string',
                                                                                        format: 'date-time'
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            responses: {
                                '201': {
                                    description: 'Data added',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                type: 'object',
                                                properties: {
                                                    received: {
                                                        type: 'integer'
                                                    },
                                                    success: {
                                                        type: 'integer'
                                                    }
                                                }
                                            }
                                        }
                                    }
                                },
                                '400': {
                                    description: 'One or more errors',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                type: 'object',
                                                properties: {
                                                    received: {
                                                        type: 'integer'
                                                    },
                                                    success: {
                                                        type: 'integer'
                                                    },
                                                    errors: {
                                                        type: 'array',
                                                        items: {
                                                            type: 'object',
                                                            properties: {
                                                                error: {
                                                                    type: 'string'
                                                                },
                                                                message: {
                                                                    type: 'string'
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        put: {
                            deprecated: true,
                            summary: 'Update sherpa',
                            description: 'Change the status of schedules',
                            tags: ['data'],
                            responses: {
                                '200': {
                                    description: 'Cache refreshed and data retrieved'
                                }
                            }
                        },
                        delete: {
                            deprecated: true,
                            summary: 'Remove existing schedules',
                            description: 'Delete schedule(s) for a client for a given week.\n\n## TODO:\n- delete from a specific version only\n\n## Optional fields\n- `tags` - Specify one or more tags to limit the delete, or leave blank to run for all stores\n',
                            tags: ['data'],
                            requestBody: {
                                required: true,
                                content: {
                                    'application/json': {
                                        schema: {
                                            type: 'object',
                                            properties: {
                                                tags: {
                                                    type: 'array',
                                                    items: {
                                                        type: 'string'
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            responses: {
                                '202': {
                                    description: 'Data removed',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                type: 'object',
                                                properties: {
                                                    found: {
                                                        type: 'integer'
                                                    },
                                                    deleted: {
                                                        type: 'integer'
                                                    }
                                                }
                                            }
                                        }
                                    }
                                },
                                '400': {
                                    description: 'One or more errors',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                type: 'object',
                                                properties: {
                                                    found: {
                                                        type: 'integer'
                                                    },
                                                    deleted: {
                                                        type: 'integer'
                                                    },
                                                    errors: {
                                                        type: 'array',
                                                        items: {
                                                            type: 'object',
                                                            properties: {
                                                                error: {
                                                                    type: 'string'
                                                                },
                                                                message: {
                                                                    type: 'string'
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/taskhours/{client_code}/{tag}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'tag',
                            in: 'path',
                            required: true,
                            description: 'The tag associated with the entity being requested',
                            schema: {
                                $ref: '#/components/schemas/tag'
                            }
                        }],
                        get: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: 'Retrieve cached data',
                            tags: ['data'],
                            responses: {
                                '200': {
                                    description: 'Data retrieved'
                                }
                            }
                        },
                        patch: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: 'Rebuild cache for sales forecast data, and return built data',
                            tags: ['data'],
                            responses: {
                                '200': {
                                    description: 'Cache refreshed and data retrieved'
                                }
                            }
                        }
                    },
                    '/admin': {
                        parameters: [{
                            name: 'access_token',
                            in: 'query',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'full_tag',
                            in: 'path',
                            required: true,
                            description: 'The tag associated with the entity being requested',
                            schema: {
                                $ref: '#/components/schemas/tag'
                            }
                        }],
                        options: {
                            summary: 'Websocket path * DOCUMENTATION NEEDED *',
                            tags: ['feed'],
                            responses: {
                                '200': {
                                    description: ''
                                }
                            }
                        }
                    },
                    '/{full_tag}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'query',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'full_tag',
                            in: 'path',
                            required: true,
                            description: 'The tag associated with the entity being requested',
                            schema: {
                                $ref: '#/components/schemas/tag'
                            }
                        }],
                        options: {
                            summary: 'Websocket path * DOCUMENTATION NEEDED *',
                            tags: ['feed'],
                            responses: {
                                '200': {
                                    description: ''
                                }
                            }
                        }
                    },
                    '/{full_tag}/stats': {
                        parameters: [{
                            name: 'access_token',
                            in: 'query',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'full_tag',
                            in: 'path',
                            required: true,
                            description: 'The tag associated with the entity being requested',
                            schema: {
                                $ref: '#/components/schemas/tag'
                            }
                        }],
                        options: {
                            summary: 'Websocket path * DOCUMENTATION NEEDED *',
                            tags: ['feed'],
                            responses: {
                                '200': {
                                    description: ''
                                }
                            }
                        }
                    },
                    '/node/{client_code}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }],
                        get: {
                            tags: ['store'],
                            summary: 'Retrieve a list of ALL stores for a client',
                            responses: {
                                '200': {
                                    description: 'Zero or more objects found',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                type: 'object',
                                                properties: {
                                                    start: {
                                                        type: 'integer'
                                                    },
                                                    count: {
                                                        type: 'integer'
                                                    },
                                                    length: {
                                                        type: 'integer'
                                                    },
                                                    data: {
                                                        type: 'array',
                                                        items: {
                                                            $ref: '#/components/schemas/store'
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                },
                                '401': {
                                    description: 'Not authorized'
                                }
                            }
                        },
                        post: {
                            deprecated: true,
                            tags: ['store'],
                            summary: 'Create a new store - NOT IMPLEMENTED',
                            responses: {
                                '201': {
                                    description: 'Entity created',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityCreated'
                                            }
                                        }
                                    }
                                },
                                '400': {
                                    description: 'Error in data validation',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityNotCreated'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/node/{client_code}/{store_code}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'store_code',
                            in: 'path',
                            required: true,
                            schema: {
                                $ref: '#/components/schemas/store_code'
                            }
                        }],
                        get: {
                            deprecated: true,
                            tags: ['store'],
                            summary: 'Retrieve a store - NOT IMPLEMENTED',
                            responses: {
                                '200': {
                                    description: 'The store',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/store'
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        put: {
                            deprecated: true,
                            tags: ['store'],
                            summary: 'Update a store - NOT IMPLEMENTED',
                            responses: {
                                '200': {
                                    description: 'Entity updated',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/okTrue'
                                            }
                                        }
                                    }
                                },
                                '400': {
                                    description: 'Error in data validation',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/entityNotCreated'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/checkliststatus/{client_code}/{tag}/{week_end_date}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'tag',
                            in: 'path',
                            required: true,
                            description: 'The tag associated with the entity being requested',
                            schema: {
                                $ref: '#/components/schemas/tag'
                            }
                        }, {
                            name: 'week_end_date',
                            in: 'path',
                            required: true,
                            description: 'The week end date in YYYY-MM-DD format',
                            schema: {
                                $ref: '#/components/schemas/week_end_date'
                            }
                        }],
                        get: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: 'Retrieve list of employees for a store for current week',
                            tags: ['store'],
                            responses: {
                                '200': {
                                    description: ''
                                }
                            }
                        }
                    },
                    '/employee/{client_code}/{tag}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'tag',
                            in: 'path',
                            required: true,
                            description: 'The tag associated with the entity being requested',
                            schema: {
                                $ref: '#/components/schemas/tag'
                            }
                        }],
                        get: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: 'Retrieve list of employees for a store for current week',
                            tags: ['store'],
                            responses: {
                                '200': {
                                    description: ''
                                }
                            }
                        },
                        post: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: 'Add a new employee to a store',
                            tags: ['store'],
                            responses: {
                                '200': {
                                    description: ''
                                }
                            }
                        }
                    },
                    '/employee/{client_code}/{tag}/{week_end_date}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'tag',
                            in: 'path',
                            required: true,
                            description: 'The tag associated with the entity being requested',
                            schema: {
                                $ref: '#/components/schemas/tag'
                            }
                        }, {
                            name: 'week_end_date',
                            in: 'path',
                            required: true,
                            description: 'The week end date in YYYY-MM-DD format',
                            schema: {
                                $ref: '#/components/schemas/week_end_date'
                            }
                        }],
                        get: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: '',
                            tags: ['store'],
                            responses: {
                                '200': {
                                    description: ''
                                }
                            }
                        }
                    },
                    '/hours/{client_code}/{tag}/{week_end_date}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'tag',
                            in: 'path',
                            required: true,
                            description: 'The tag associated with the entity being requested',
                            schema: {
                                $ref: '#/components/schemas/tag'
                            }
                        }, {
                            name: 'week_end_date',
                            in: 'path',
                            required: true,
                            description: 'The week end date in YYYY-MM-DD format',
                            schema: {
                                $ref: '#/components/schemas/week_end_date'
                            }
                        }],
                        get: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: '',
                            tags: ['store'],
                            responses: {
                                '200': {
                                    description: ''
                                }
                            }
                        }
                    },
                    '/schedule/{client_code}/{tag}/{week_end_date}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'tag',
                            in: 'path',
                            required: true,
                            description: 'The tag associated with the entity being requested',
                            schema: {
                                $ref: '#/components/schemas/tag'
                            }
                        }, {
                            name: 'week_end_date',
                            in: 'path',
                            required: true,
                            description: 'The week end date in YYYY-MM-DD format',
                            schema: {
                                $ref: '#/components/schemas/week_end_date'
                            }
                        }],
                        get: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: '',
                            tags: ['store'],
                            responses: {
                                '200': {
                                    description: ''
                                }
                            }
                        },
                        put: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: '',
                            tags: ['store'],
                            responses: {
                                '200': {
                                    description: ''
                                }
                            }
                        },
                        patch: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: '',
                            tags: ['store'],
                            responses: {
                                '200': {
                                    description: ''
                                }
                            }
                        },
                        delete: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: '',
                            tags: ['store'],
                            responses: {
                                '200': {
                                    description: ''
                                }
                            }
                        }
                    },
                    '/schedule/flags/{client_code}/{tag}/{week_end_date}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'tag',
                            in: 'path',
                            required: true,
                            description: 'The tag associated with the entity being requested',
                            schema: {
                                $ref: '#/components/schemas/tag'
                            }
                        }, {
                            name: 'week_end_date',
                            in: 'path',
                            required: true,
                            description: 'The week end date in YYYY-MM-DD format',
                            schema: {
                                $ref: '#/components/schemas/week_end_date'
                            }
                        }],
                        get: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: '',
                            tags: ['store'],
                            responses: {
                                '200': {
                                    description: ''
                                }
                            }
                        }
                    },
                    '/schedule/publish/{client_code}/{tag}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'tag',
                            in: 'path',
                            required: true,
                            description: 'The tag associated with the entity being requested',
                            schema: {
                                $ref: '#/components/schemas/tag'
                            }
                        }],
                        put: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: '',
                            tags: ['store'],
                            responses: {
                                '200': {
                                    description: ''
                                }
                            }
                        }
                    },
                    '/details/{client_code}/{tag}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'tag',
                            in: 'path',
                            required: true,
                            description: 'The tag associated with the entity being requested',
                            schema: {
                                $ref: '#/components/schemas/tag'
                            }
                        }],
                        get: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: '',
                            tags: ['store'],
                            responses: {
                                '200': {
                                    description: ''
                                }
                            }
                        }
                    },
                    '/role': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }],
                        get: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: '',
                            tags: ['user'],
                            responses: {
                                '200': {
                                    description: ''
                                }
                            }
                        }
                    },
                    '/{client_code}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'username',
                            in: 'path',
                            required: true,
                            description: 'The username for a user.',
                            schema: {
                                $ref: '#/components/schemas/username'
                            }
                        }],
                        get: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: '',
                            tags: ['user'],
                            responses: {
                                '200': {
                                    description: ''
                                }
                            }
                        },
                        post: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: '',
                            tags: ['user'],
                            responses: {
                                '200': {
                                    description: ''
                                }
                            }
                        }
                    },
                    '/{client_code}/{username}': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'username',
                            in: 'path',
                            required: true,
                            description: 'The username for a user.',
                            schema: {
                                $ref: '#/components/schemas/username'
                            }
                        }],
                        get: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: '',
                            tags: ['user'],
                            responses: {
                                '200': {
                                    description: ''
                                }
                            }
                        },
                        put: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: '',
                            tags: ['user'],
                            responses: {
                                '200': {
                                    description: ''
                                }
                            }
                        },
                        delete: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: '',
                            tags: ['user'],
                            responses: {
                                '200': {
                                    description: ''
                                }
                            }
                        }
                    },
                    '/{client_code}/{username}/password': {
                        parameters: [{
                            name: 'access_token',
                            in: 'header',
                            required: true,
                            description: 'JWT access token issued by the Matrix Live Authentication Service',
                            schema: {
                                type: 'string'
                            }
                        }, {
                            name: 'client_code',
                            in: 'path',
                            required: true,
                            description: 'The client code assigned to the client. Lowercase is preferred, but the query is case insensitive.',
                            schema: {
                                $ref: '#/components/schemas/client_code'
                            }
                        }, {
                            name: 'username',
                            in: 'path',
                            required: true,
                            description: 'The username for a user.',
                            schema: {
                                $ref: '#/components/schemas/username'
                            }
                        }],
                        post: {
                            summary: '* DOCUMENTATION NEEDED *',
                            description: '',
                            tags: ['user'],
                            responses: {
                                '200': {
                                    description: ''
                                }
                            }
                        }
                    }
                },
                components: {
                    schemas: {
                        activity: {
                            type: 'object',
                            properties: {
                                id: {
                                    type: 'integer'
                                },
                                name: {
                                    type: 'string'
                                },
                                short_name: {
                                    type: 'string'
                                },
                                is_management: {
                                    type: 'boolean'
                                },
                                is_callin: {
                                    type: 'boolean'
                                },
                                is_task: {
                                    type: 'boolean'
                                },
                                is_scheduled: {
                                    type: 'boolean'
                                },
                                should_display: {
                                    type: 'boolean'
                                },
                                order_index: {
                                    type: 'integer'
                                },
                                activity_priority: {
                                    type: 'integer'
                                },
                                livecolor: {
                                    $ref: '#/components/schemas/livecolor'
                                }
                            }
                        },
                        activity_code: {
                            type: 'string'
                        },
                        budget: {
                            type: 'object',
                            properties: {
                                id: {
                                    type: 'integer'
                                },
                                name: {
                                    type: 'string'
                                },
                                order_index: {
                                    type: 'integer'
                                },
                                can_be_negative: {
                                    type: 'string'
                                },
                                client: {
                                    $ref: '#/components/schemas/client'
                                }
                            }
                        },
                        budgetEdit: {
                            type: 'object',
                            properties: {
                                name: {
                                    type: 'string'
                                },
                                order_index: {
                                    type: 'integer'
                                },
                                can_be_negative: {
                                    type: 'string'
                                },
                                client_id: {
                                    type: 'integer'
                                }
                            }
                        },
                        budget_code: {
                            type: 'string'
                        },
                        client: {
                            type: 'object',
                            properties: {
                                id: {
                                    type: 'integer'
                                },
                                name: {
                                    type: 'string'
                                },
                                client_code: {
                                    type: 'string'
                                },
                                week_start_day: {
                                    type: 'integer'
                                }
                            }
                        },
                        client_code: {
                            type: 'string'
                        },
                        driver_code: {
                            type: 'string'
                        },
                        entityCreated: {
                            type: 'object',
                            properties: {
                                id: {
                                    type: 'integer'
                                },
                                ok: {
                                    type: 'boolean'
                                }
                            }
                        },
                        entityNotCreated: {
                            type: 'object',
                            properties: {
                                errors: {
                                    type: 'array',
                                    items: {
                                        type: 'string'
                                    }
                                },
                                ok: {
                                    type: 'boolean'
                                }
                            }
                        },
                        exchangeRate: {
                            type: 'object',
                            properties: {
                                id: {
                                    type: 'integer'
                                },
                                rate: {
                                    type: 'number'
                                },
                                country_code: {
                                    type: 'string'
                                },
                                start_date: {
                                    type: 'string'
                                },
                                end_date: {
                                    type: 'string',
                                    nullable: true
                                },
                                client: {
                                    $ref: '#/components/schemas/client'
                                }
                            }
                        },
                        exchangeRateEdit: {
                            type: 'object',
                            properties: {
                                rate: {
                                    type: 'number'
                                },
                                country_code: {
                                    type: 'string'
                                },
                                start_date: {
                                    type: 'string'
                                },
                                end_date: {
                                    type: 'string',
                                    nullable: true
                                },
                                client_id: {
                                    type: 'integer'
                                }
                            }
                        },
                        error_message: {
                            type: 'object',
                            properties: {
                                id: {
                                    type: 'integer'
                                },
                                number: {
                                    type: 'integer'
                                },
                                severity: {
                                    type: 'integer'
                                }
                            }
                        },
                        environment: {
                            type: 'object',
                            properties: {
                                env: {
                                    type: 'string'
                                }
                            }
                        },
                        hierarchy_code: {
                            type: 'string'
                        },
                        hierarchy_node: {
                            type: 'object',
                            properties: {
                                id: {
                                    type: 'integer'
                                },
                                name: {
                                    type: 'string'
                                },
                                code: {
                                    type: 'string'
                                },
                                type: {
                                    type: 'string'
                                },
                                level: {
                                    type: 'integer'
                                },
                                tag: {
                                    type: 'string'
                                },
                                parent: {
                                    type: 'object'
                                },
                                children: {
                                    type: 'array',
                                    items: {
                                        $ref: '#/components/schemas/hierarchy_node'
                                    }
                                },
                                stores: {
                                    type: 'array',
                                    items: {
                                        $ref: '#/components/schemas/store'
                                    }
                                }
                            }
                        },
                        labor_driver: {
                            type: 'object',
                            properties: {
                                id: {
                                    type: 'integer'
                                },
                                name: {
                                    type: 'string'
                                },
                                unit_prefix: {
                                    type: 'string'
                                },
                                unit_suffix: {
                                    type: 'string'
                                },
                                driver_order: {
                                    type: 'integer'
                                },
                                is_sales: {
                                    type: 'string'
                                },
                                client: {
                                    $ref: '#/components/schemas/client'
                                }
                            }
                        },
                        labor_driverEdit: {
                            type: 'object',
                            properties: {
                                name: {
                                    type: 'string'
                                },
                                unit_prefix: {
                                    type: 'string'
                                },
                                unit_suffix: {
                                    type: 'string'
                                },
                                driver_order: {
                                    type: 'integer'
                                },
                                is_sales: {
                                    type: 'string'
                                },
                                client_id: {
                                    type: 'integer'
                                }
                            }
                        },
                        login: {
                            type: 'object',
                            properties: {
                                username: {
                                    type: 'string'
                                },
                                password: {
                                    type: 'string'
                                },
                                client_code: {
                                    type: 'string'
                                }
                            }
                        },
                        livecolor: {
                            type: 'object',
                            properties: {
                                id: {
                                    type: 'integer'
                                },
                                name: {
                                    type: 'string'
                                },
                                primary: {
                                    type: 'string'
                                },
                                secondary: {
                                    type: 'string'
                                }
                            }
                        },
                        okTrue: {
                            type: 'object',
                            properties: {
                                ok: {
                                    type: 'boolean'
                                }
                            }
                        },
                        operation_call: {
                            type: 'string'
                        },
                        operation_body: {
                            type: 'object',
                            properties: {
                                client_code: {
                                    type: 'string'
                                },
                                foobar: {
                                    type: 'string'
                                }
                            }
                        },
                        operationProcedureList: {
                            type: 'array',
                            items: {
                                $ref: '#/components/schemas/operationProcedure'
                            }
                        },
                        operationProcedure: {
                            type: 'object',
                            properties: {
                                command: {
                                    type: 'string'
                                },
                                documentation: {
                                    type: 'string'
                                },
                                required_parameters: {
                                    type: 'array',
                                    items: {
                                        type: 'string'
                                    }
                                }
                            }
                        },
                        outOfOfficeActivity: {
                            type: 'object',
                            properties: {
                                id: {
                                    type: 'integer'
                                },
                                name: {
                                    type: 'string'
                                },
                                code: {
                                    type: 'string'
                                }
                            }
                        },
                        position: {
                            type: 'object',
                            properties: {
                                id: {
                                    type: 'integer'
                                },
                                name: {
                                    type: 'string'
                                },
                                code: {
                                    type: 'string'
                                },
                                level: {
                                    type: 'integer'
                                },
                                is_management: {
                                    type: 'string'
                                },
                                salary_visibility_level: {
                                    type: 'integer'
                                },
                                order_index: {
                                    type: 'integer'
                                },
                                group: {
                                    $ref: '#/components/schemas/position_group'
                                },
                                client: {
                                    $ref: '#/components/schemas/client'
                                }
                            }
                        },
                        positionEdit: {
                            type: 'object',
                            properties: {
                                name: {
                                    type: 'string'
                                },
                                code: {
                                    type: 'string'
                                },
                                level: {
                                    type: 'integer'
                                },
                                is_management: {
                                    type: 'string'
                                },
                                salary_visibility_level: {
                                    type: 'integer'
                                },
                                order_index: {
                                    type: 'integer'
                                },
                                group_id: {
                                    type: 'integer'
                                },
                                client_id: {
                                    type: 'integer'
                                }
                            }
                        },
                        position_code: {
                            type: 'string'
                        },
                        position_group: {
                            type: 'object',
                            properties: {
                                id: {
                                    type: 'integer'
                                },
                                name: {
                                    type: 'string'
                                },
                                client: {
                                    $ref: '#/components/schemas/client'
                                }
                            }
                        },
                        position_groupEdit: {
                            type: 'object',
                            properties: {
                                name: {
                                    type: 'string'
                                }
                            }
                        },
                        position_group_code: {
                            type: 'string'
                        },
                        refresh_token: {
                            type: 'object',
                            properties: {
                                refresh_token: {
                                    type: 'string'
                                }
                            }
                        },
                        shift_rule: {
                            type: 'object',
                            properties: {
                                id: {
                                    type: 'integer'
                                },
                                ignore_meal_break: {
                                    type: 'integer'
                                },
                                ignore_rest_break: {
                                    type: 'integer'
                                },
                                max_hours_for_day: {
                                    type: 'number'
                                },
                                max_shift_length: {
                                    type: 'number'
                                },
                                meal_break_1: {
                                    type: 'number'
                                },
                                meal_break_2: {
                                    type: 'number'
                                },
                                meal_break_length: {
                                    type: 'number'
                                },
                                meal_break_max_length: {
                                    type: 'number'
                                },
                                meal_break_spacer: {
                                    type: 'number'
                                },
                                min_shift_length: {
                                    type: 'number'
                                },
                                node_rule: {
                                    type: 'integer'
                                },
                                rest_break_1: {
                                    type: 'number'
                                },
                                rest_break_2: {
                                    type: 'number'
                                },
                                rest_break_3: {
                                    type: 'number'
                                },
                                rest_break_length: {
                                    type: 'number'
                                },
                                rest_break_spacer: {
                                    type: 'number'
                                },
                                state_rule: {
                                    type: 'string'
                                },
                                validate_shift_extension: {
                                    type: 'integer'
                                }
                            }
                        },
                        store_code: {
                            type: 'string'
                        },
                        store: {
                            type: 'object',
                            properties: {
                                id: {
                                    type: 'integer'
                                },
                                name: {
                                    type: 'string'
                                },
                                code: {
                                    type: 'string'
                                },
                                type: {
                                    type: 'string'
                                },
                                level: {
                                    type: 'integer'
                                },
                                tag: {
                                    type: 'string'
                                },
                                info: {
                                    type: 'object'
                                }
                            }
                        },
                        tag: {
                            type: 'string'
                        },
                        focus: {
                            type: 'string'
                        },
                        aggregate: {
                            type: 'boolean'
                        },
                        cascade: {
                            type: 'boolean'
                        },
                        task_type_code: {
                            type: 'string'
                        },
                        username: {
                            type: 'string'
                        },
                        week_end_date: {
                            type: 'string',
                            format: 'date'
                        }
                    }
                }
            }
        }
    });
});