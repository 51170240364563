define('matrix-frontend/pods/components/schedule-page-element-chart-segment/component', ['exports', 'ember-redux'], function (exports, _emberRedux) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var stateToComputed = function stateToComputed(state) {
        return {};
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {};
    };

    var Comp = Ember.Component.extend({
        displayActivities: Ember.computed('segment', function () {
            var segment = this.get('segment');
            var activitiesById = this.get('activitiesById');

            var activities = [];
            for (var key in segment.activities) {
                var a = activitiesById[key];
                if (a) {
                    activities.push(_extends({
                        activityId: key,
                        livecolor: a.livecolor.id,
                        activityPriority: a.activityPriority
                    }, segment.activities[key]));
                }
            }

            // return activities.sort((x, y) => {
            //     if (y.activityPriority === 0) { return false; }
            //     return x.activityPriority > y.activityPriority
            // });
            return activities.sortBy('activityPriority');
        })
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});