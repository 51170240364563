define('matrix-frontend/helpers/format-number', ['exports', 'npm:numbro'], function (exports, _npmNumbro) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.formatNumber = formatNumber;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    function formatNumber(_ref, hash) {
        var _ref2 = _slicedToArray(_ref, 1),
            value = _ref2[0];

        var format = void 0;
        if (hash['format'] !== undefined) {
            format = hash['format'];
        } else {
            format = '0,0.00';
        }

        _npmNumbro.default.zeroFormat('');

        if (value === undefined || value === 0) {
            return '';
        }

        var prefix = hash['prefix'] === undefined ? '' : hash['prefix'];
        var suffix = hash['suffix'] === undefined ? '' : hash['suffix'];

        value = (0, _npmNumbro.default)(value).format(format);

        return '' + prefix + value + suffix;
    }

    exports.default = Ember.Helper.helper(formatNumber);
});