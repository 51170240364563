define('matrix-frontend/routes/application-error', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        templateName: 'error',
        controllerName: 'error'
    });
});