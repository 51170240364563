define('matrix-frontend/components/react-only/employee-skill-row', ['exports', 'react', 'matrix-frontend/actions/_types', 'matrix-frontend/store', 'npm:numbro', 'matrix-frontend/components/react-only/mxl-inline-input', 'matrix-frontend/utils/state', 'matrix-frontend/components/react-only/employee-name-cell'], function (exports, _react, _types, _store, _npmNumbro, _mxlInlineInput, _state, _employeeNameCell) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var formatValue = function formatValue(value) {
        if (Number(value) > 0) {
            var n = (0, _npmNumbro.default)(value);
            return n.format({
                mantissa: 2,
                optionalMantissa: true
            });
        } else {
            return '';
        }
    };

    var compileSkills = function compileSkills(employee, activities, row, isLoan) {
        var useSkills = activities.map(function (x) {
            var skill = employee.skills.find(function (y) {
                return x.id === y.activity;
            }) || {};
            return {
                activity: x.id,
                level: skill.level || 0
            };
        });

        var compiled = useSkills.map(function (x) {
            var activity = activities.find(function (y) {
                return y.id === x.activity;
            });
            return _extends({}, x, {
                activity: activity,
                activityPriority: activity && activity.activityPriority
            });
        }).filter(function (x) {
            return x.activity;
        }).sortBy('activityPriority').map(function (skill, index) {
            var defaultValue = skill.level ? skill.level : '';
            var element = Ember.$('#' + currentId);
            var currentId = 'mx-inline-input-' + row + '-' + index;
            var classes = ['column', 'f1', 'centered', 'no-pad'];

            if (isLoan) {
                classes.push('gray-lighten-1-text');
            }

            return {
                key: index,
                id: currentId,
                classes: classes,
                activityPriority: skill.activity.activityPriority,
                onFocus: function onFocus(e) {
                    e.target.select();
                    e.target.parentNode.classList.add('focused');
                },
                onKeyDown: function onKeyDown(e) {
                    // 37 => left
                    // 38 => up
                    // 39 => right
                    // 40 => down
                    var x = e.keyCode === 38 ? row - 1 : e.keyCode === 40 ? row + 1 : row;
                    var y = e.keyCode === 37 ? index - 1 : e.keyCode === 39 ? index + 1 : index;
                    var nextId = 'mx-inline-input-' + x + '-' + y;
                    if (currentId !== nextId) {
                        var el = Ember.$('#' + nextId);
                        if (el) {
                            el.focus();
                            el.select();
                            e.preventDefault();
                            e.stopPropagation();
                            return false;
                        }
                    }
                },
                onBlur: function onBlur(e, isDirty, whenSucceed, whenFail) {
                    e.target.parentNode.classList.remove('focused');
                    var val = formatValue(e.target.value);
                    var el = e.target;

                    if (isDirty) {
                        _store.store.dispatch({
                            type: _types.EE_SAVE_SKILL,
                            payload: {
                                employee: employee,
                                activity: skill.activity,
                                value: val,
                                onSuccess: whenSucceed,
                                onFailure: whenFail
                            }
                        });
                        setTimeout(function () {
                            el.value = val;
                        }, 1);
                    }
                },
                defaultValue: +parseFloat('' + defaultValue).toFixed(2) || ''
            };
        }).map(function (x) {
            delete x.activityPriority;
            return x;
        });

        return compiled;
    };

    var comp = function comp(props) {
        var employee = props.employee,
            activitiesById = props.activitiesById,
            row = props.row,
            currentStore = props.currentStore;

        var isLoan = currentStore.id !== employee.store.id;

        var activities = Object.keys(activitiesById).map(function (x) {
            return activitiesById[x];
        }).filter(function (x) {
            return x.budget.id && x.shouldDisplay;
        }).sortBy('activityPriority');

        var skills = compileSkills(employee, activities, row, isLoan);

        return _react.default.createElement(
            'div',
            { className: 'row' },
            _react.default.createElement(_employeeNameCell.default, { employee: employee, currentStore: currentStore }),
            _react.default.createElement(
                'div',
                { className: 'wrapper f9' },
                skills.map(function (x) {
                    return (0, _state.hasScope)('employee.store:skills.edit') && !isLoan ?
                    // return (hasRole('schedule2', true) || hasRole('approver')) && !isLoan ? (
                    _react.default.createElement(_mxlInlineInput.default, {
                        key: 'key-' + x.key,
                        id: x.id,
                        onFocusAction: x.onFocus,
                        onKeyDownAction: x.onKeyDown,
                        onBlurAction: x.onBlur,
                        defaultValue: x.defaultValue,
                        classes: x.classes
                    }) : _react.default.createElement(
                        'div',
                        { className: x.classes.join(' ') },
                        x.defaultValue
                    );
                })
            )
        );
    };

    exports.default = comp;
});