define('matrix-frontend/pods/components/hours-vs-optimal-report/component', ['exports', 'npm:underscore', 'matrix-frontend/utils/format-helpers', 'matrix-frontend/pods/components/abstract-vs-report/component', 'npm:moment', 'npm:numbro'], function (exports, _npmUnderscore, _formatHelpers, _component, _npmMoment, _npmNumbro) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _component.default.extend({
        labels: {
            // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
            area: 'Actual Hours',
            line: 'Optimized Hours',
            yAxis: 'Hours'
        },

        tablePartial: 'components/hours-vs-optimal-report/table',

        crunchedData: Ember.computed('normalizedData', function () {
            var data = this.get('normalizedData');
            var dailyData = this.get('data.daily');

            if (data && !_npmUnderscore.default.isEmpty(data)) {
                var sums = _npmUnderscore.default.sortBy(_npmUnderscore.default.map(_npmUnderscore.default.groupBy(data, function (item) {
                    return (0, _npmMoment.default)(item.date).startOf('day').toISOString();
                }), function (item, key) {
                    var date = (0, _npmMoment.default)(key);
                    var displayDay = date.format('ddd');
                    var displayDate = date.format('MM/DD');

                    return _npmUnderscore.default.reduce(item, function (memo, hourSegment) {
                        memo.areaValue += hourSegment.areaValue;
                        memo.lineValue += hourSegment.lineValue;
                        memo.nullAdjustedLineValue += hourSegment.nullAdjustedLineValue;
                        return memo;
                    }, {
                        areaValue: 0,
                        lineValue: 0,
                        nullAdjustedLineValue: 0,
                        date: date,
                        displayDate: displayDate,
                        displayDay: displayDay
                    });
                }), 'date');

                var week = {
                    areaValue: 0,
                    lineValue: 0,
                    nullAdjustedLineValue: 0,
                    displayDate: 'Week'
                };
                _npmUnderscore.default.each(sums, function (dailySegment, index) {
                    if (dailyData[index]) {
                        dailySegment.areaValue = dailyData[index].sum;
                        week.areaValue += dailySegment.areaValue;
                        week.lineValue += dailySegment.lineValue;
                        week.nullAdjustedLineValue += dailySegment.nullAdjustedLineValue;
                    }
                });
                sums.push(week);

                _npmUnderscore.default.each(sums, function (item) {
                    if (item.areaValue && item.lineValue && item.nullAdjustedLineValue) {
                        item.areaValue = Math.round(item.areaValue);
                        item.lineValue = Math.round(item.lineValue);
                        item.nullAdjustedLineValue = Math.round(item.nullAdjustedLineValue);
                    }
                });

                return this.formatCrunchedData(sums);
            } else {
                return [];
            }
        }),

        formatCrunchedData: function formatCrunchedData(data) {
            _npmUnderscore.default.each(data, function (item) {
                if ((item.areaValue || item.areaValue === 0) && (item.lineValue || item.lineValue === 0)) {
                    item.var = item.areaValue - item.nullAdjustedLineValue;
                    item.formattedVarPercent = _formatHelpers.default.formatPercent(item.var, item.nullAdjustedLineValue);

                    item.formattedAreaValue = (0, _npmNumbro.default)(item.areaValue).format('0.0a');
                    item.formattedLineValue = (0, _npmNumbro.default)(item.lineValue).format('0.0a');
                    item.formattedVar = (0, _npmNumbro.default)(item.var).format('0.0a');

                    if (item.lineValue !== item.nullAdjustedLineValue) {
                        item.formattedVar += '*';
                        item.formattedVarPercent += '*';
                    }

                    if (item.areaValue === 0) {
                        item.formattedAreaValue = '-';
                    }
                    if (item.lineValue === 0) {
                        item.formattedLineValue = '-';
                    }
                    if (item.areaValue === 0 || item.lineValue === 0) {
                        item.formattedVar = '-';
                        item.formattedVarPercent = '-';
                    }
                }
            });

            return data;
        },

        normalizedData: Ember.computed('data', function () {
            var _this = this;

            var data = this.get('data.hourly');
            this.set('isNullAdjusted', false); // eslint-disable-line ember/no-side-effects

            if (data && !_npmUnderscore.default.isEmpty(data)) {
                data.forEach(function (item) {
                    item.date = item.date ? new Date(item.date) : null;
                    item.areaValue = item.actualHours;
                    item.lineValue = item.optimalHours;
                    if (item.nullAdjustedOptimal) {
                        item.nullAdjustedLineValue = item.nullAdjustedOptimal;
                        if (item.nullAdjustedLineValue !== item.lineValue) {
                            _this.set('isNullAdjusted', true);
                        }
                    } else {
                        // if null adjusted value is undefined, we should be dealing with a store
                        item.nullAdjustedLineValue = item.lineValue;
                    }
                });
                return data;
            } else {
                return [];
            }
        })
    });
});