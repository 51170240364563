define('matrix-frontend/utils/shift', ['exports', 'matrix-frontend/utils/availability', 'npm:moment', 'matrix-frontend/utils/date-helper'], function (exports, _availability, _npmMoment, _dateHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.unavailableReason = exports.notStarted = exports.isTerminated = exports.isUnvailable = exports.getDailyShiftClasses = exports.checkIsAvailable = undefined;

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var checkIsAvailable = function checkIsAvailable(shift, availability, currentStoreCode) {
        // See GIT repo for several other alternatives on how to calculate this
        // After testing, this was determined to be the fastest method
        var isAvailable = true;
        // console.log('checkIsAvailable', !!shift, availability.length)

        // console.log('availability',
        //     availability.map(x => `${x.isAvailable} | ${x.fromOpen ? 'open' : x.start.format("HH:mm")}-${x.untilClose ? 'close' : x.end.format("HH:mm")}` ))
        // console.log('blocks',
        //     shift.blocks.map(x => `${x.start.format("HH:mm")}-${x.end.format("HH:mm")}` ))
        if (!availability || availability.length === 0 || availability[0].storeCode !== currentStoreCode) {
            isAvailable = false;
        } else {
            availability = availability.filter(function (x) {
                return !x.isAvailable;
            }).sort(function (j, k) {
                return k.start.isBefore(j.start);
            });
            if (availability.length === 0) {
                return true;
            }

            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = availability[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var a = _step.value;

                    // console.log('availability', {
                    //     isAllDay: a.isAllDay,
                    //     fromOpen: a.fromOpen,
                    //     untilClose: a.untilClose,
                    // })
                    if (a.isAllDay) {
                        return false;
                    }
                    var _iteratorNormalCompletion2 = true;
                    var _didIteratorError2 = false;
                    var _iteratorError2 = undefined;

                    try {
                        for (var _iterator2 = shift.blocks[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                            var b = _step2.value;

                            var aStart = new Date(shift.meta.date.year(), shift.meta.date.month(), shift.meta.date.date(), a.start.hours(), a.start.minutes());
                            var aEnd = new Date(shift.meta.date.year(), shift.meta.date.month(), shift.meta.date.date(), a.end.hours(), a.end.minutes());
                            // const bStart = new Date(
                            //     shift.meta.date.year(),
                            //     shift.meta.date.month(),
                            //     shift.meta.date.date(),
                            //     b.start.hours(),
                            //     b.start.minutes(),
                            // )
                            // const bEnd = new Date(
                            //     shift.meta.date.year(),
                            //     shift.meta.date.month(),
                            //     shift.meta.date.date(),
                            //     b.end.hours(),
                            //     b.end.minutes(),
                            // )
                            // console.log('blockComparison', {
                            //     a,b,
                            //     aStart: moment(aStart).format('HH:mm'),
                            //     aEnd: moment(aEnd).format('HH:mm'),
                            //     // bStart: b.start.format('YYYY-MM-DD HH:mm'),
                            //     // bEnd: b.end.format('YYYY-MM-DD HH:mm'),
                            //     // comp1: (a.fromOpen || aStart < b.end._d),
                            //     // comp2: (a.untilClose || aEnd > b.start._d),
                            //     bStart: moment(bStart).format('HH:mm'),
                            //     bEnd: moment(bEnd).format('HH:mm'),
                            //     bsh: b.start.hours(),
                            //     beh: b.end.hours(),
                            //     comp1: (a.fromOpen || aStart < bEnd),
                            //     comp2: (a.untilClose || aEnd > bStart),
                            //     ok: (a.fromOpen || aStart < bEnd) && (a.untilClose || aEnd > bStart)
                            // })
                            if ((a.fromOpen || aStart < b.end._d) && (a.untilClose || aEnd > b.start._d)
                            // (a.fromOpen || aStart < bEnd) &&
                            // (a.untilClose || aEnd > bStart)
                            ) {
                                    return false;
                                }
                        }
                    } catch (err) {
                        _didIteratorError2 = true;
                        _iteratorError2 = err;
                    } finally {
                        try {
                            if (!_iteratorNormalCompletion2 && _iterator2.return) {
                                _iterator2.return();
                            }
                        } finally {
                            if (_didIteratorError2) {
                                throw _iteratorError2;
                            }
                        }
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }
        }
        // console.groupEnd('checkIsAvailable')

        return isAvailable;
    };

    var isUnvailable = function isUnvailable(x) {
        return !x.isAvailable;
    };

    var isTerminated = function isTerminated(_ref) {
        var employee = _ref.employee,
            dotw = _ref.dotw,
            weekEndDate = _ref.weekEndDate;
        return employee && employee.terminationDate && (0, _npmMoment.default)(weekEndDate).subtract(7 - dotw, 'days').isAfter(employee.terminationDate, 'day');
    };

    var notStarted = function notStarted(_ref2) {
        var employee = _ref2.employee,
            dotw = _ref2.dotw,
            weekEndDate = _ref2.weekEndDate;
        return employee && employee.startDate && (0, _npmMoment.default)(weekEndDate).subtract(7 - dotw, 'days').isBefore(employee.startDate, 'day');
    };

    var unavailableReason = function unavailableReason(availability) {
        if (availability && availability.length > 0 && availability.every(isUnvailable)) {
            return availability[0].reason || 'Not available';
        }
        return '';
    };

    var getDailyShiftClasses = function getDailyShiftClasses(_ref3) {
        var currentShift = _ref3.currentShift,
            activeShift = _ref3.activeShift,
            employee = _ref3.employee,
            employees = _ref3.employees,
            availability = _ref3.availability,
            weekEndDate = _ref3.weekEndDate,
            dotw = _ref3.dotw,
            editMode = _ref3.editMode,
            assignableEmployeeIds = _ref3.assignableEmployeeIds,
            availableEmployeeIds = _ref3.availableEmployeeIds,
            skilledEmployeeIds = _ref3.skilledEmployeeIds,
            smartAssignEmployee = _ref3.smartAssignEmployee,
            managementActivities = _ref3.managementActivities,
            currentStore = _ref3.currentStore,
            published = _ref3.published;

        var classes = new Set(['daily-shift']);

        if (activeShift) {
            var activeDOTW = (0, _dateHelper.getDayOfClientWeek)(activeShift.meta.date) + 1;
            if (dotw !== activeDOTW) {
                classes.add('disabled');
            } else {
                classes.add('same-dotw');
                if (!employee) {
                    classes.add('no-employee');
                } else {
                    if (availableEmployeeIds && availableEmployeeIds.has(employee.id)) {
                        classes.add('available-employee');
                    }
                    if (assignableEmployeeIds && assignableEmployeeIds.has(employee.id)) {
                        classes.add('assignable-employee');

                        if (smartAssignEmployee && smartAssignEmployee.id === employee.id) {
                            classes.add('best-match');
                        }
                    }
                    if (skilledEmployeeIds && skilledEmployeeIds.has(employee.id)) {
                        classes.add('skilled-employee');
                    }
                    if (currentShift && activeShift.meta.id === currentShift.meta.id) {
                        classes.add('active');
                    }
                }
            }
        }

        if (employee) {
            if (availability.every(isUnvailable) || availability[0].storeCode !== currentStore.code || employee.terminationDate && (0, _npmMoment.default)(weekEndDate).day(dotw - 1).isAfter(employee.terminationDate, 'day') || employee.startDate && (0, _npmMoment.default)(weekEndDate).day(dotw - 1).isBefore(employee.startDate, 'day') || currentShift && activeShift && activeShift.meta.employeeId && !(0, _availability.employeeAvailableForShift)(employees.find(function (x) {
                return x.id === activeShift.meta.employeeId;
            }), currentShift, dotw)) {
                classes.add('unavailable-employee');
                classes.add('disabled');
                classes.delete('available-employee');
            } else if (availability.some(isUnvailable)) {
                classes.add('partial-available-employee');

                if (activeShift && availableEmployeeIds && !availableEmployeeIds.has(employee.id)) {
                    classes.add('disabled');
                }
            }
        } else {
            classes.add('unassigned');

            if (managementActivities && currentShift) {
                var _loop = function _loop(block) {
                    var activity = managementActivities.find(function (x) {
                        return block.activityId && x.id === block.activityId;
                    });
                    if (activity) {
                        var managementActivityColor = 'activity-color-' + activity.livecolor.id;
                        classes.add(managementActivityColor);
                        return 'break';
                    }
                };

                var _iteratorNormalCompletion3 = true;
                var _didIteratorError3 = false;
                var _iteratorError3 = undefined;

                try {
                    for (var _iterator3 = currentShift.blocks[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                        var block = _step3.value;

                        var _ret = _loop(block);

                        if (_ret === 'break') break;
                    }
                } catch (err) {
                    _didIteratorError3 = true;
                    _iteratorError3 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion3 && _iterator3.return) {
                            _iterator3.return();
                        }
                    } finally {
                        if (_didIteratorError3) {
                            throw _iteratorError3;
                        }
                    }
                }
            }
        }

        if (!editMode) {
            classes.add('disabled');
        }

        if (currentShift) {
            classes.add('has-shift');
            if (!currentShift.meta.saved) {
                classes.add('is-dirty');
            }
            if (currentShift.errors !== undefined && currentShift.errors.length > 0) {
                var severities = currentShift.errors.map(function (x) {
                    return x.severity;
                });
                var mostSevere = Math.max.apply(Math, _toConsumableArray(severities));
                switch (mostSevere) {
                    case -1:
                        classes.add('has-alert');
                        break;
                    case 0:
                        classes.add('has-warning');
                        break;
                    case 1:
                        classes.add('has-violation');
                        break;
                }
            }

            if (employee && published && !currentShift.meta.communicated && currentShift.meta.saved) {
                classes.add('not-communicated');
            }
        }

        return [].concat(_toConsumableArray(classes)).join(' ');
    };

    exports.checkIsAvailable = checkIsAvailable;
    exports.getDailyShiftClasses = getDailyShiftClasses;
    exports.isUnvailable = isUnvailable;
    exports.isTerminated = isTerminated;
    exports.notStarted = notStarted;
    exports.unavailableReason = unavailableReason;
});