define('matrix-frontend/middleware/client', ['exports', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/interactions'], function (exports, _types, _interactions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var beforeListeners = {};
    var afterListeners = {};

    beforeListeners[_types.CLIENT_NONSTORE_USER_SAVE] = function (store, payload) {
        var userId = payload.user.id,
            clientCode = payload.clientCode,
            onSuccess = payload.onSuccess,
            onFailure = payload.onFailure,
            onComplete = payload.onComplete;

        store.dispatch({ type: _types.CLIENT_NONSTORE_USER_UPDATE, payload: payload });

        delete payload.user;
        delete payload.clientCode;
        delete payload.onSuccess;
        delete payload.onFailure;
        delete payload.onComplete;

        if (payload.node) {
            payload.node = function (_ref) {
                var id = _ref.id;
                return { id: id };
            }(payload.node);
        }

        var body = {
            service: 'user',
            namespace: 'nonstore',
            operation: 3,
            include_channel: 3,
            channel_override: [clientCode],
            extra: [userId],
            payload: payload
            // params: {debug: true}
        };
        (0, _interactions.send)({
            header: 'do',
            body: body
        }, 'Updating user ' + userId, false, onSuccess, onFailure, onComplete, true);
    };

    afterListeners[_types.CLIENT_INTAKE] = function (store, payload) {
        store.dispatch({ type: _types.SE_CHART_DRAW });
    };

    exports.default = function (store) {
        return function (next) {
            return function (action) {
                var type = action.type,
                    payload = action.payload;

                // Before listeners

                if (beforeListeners[type]) {
                    var ret = beforeListeners[type](store, payload);
                    if (ret !== undefined) {
                        if (ret === false) {
                            return;
                        } else {
                            action.payload = ret;
                        }
                    }
                }

                next(action);

                // After listeners
                if (afterListeners[type]) {
                    afterListeners[type](store, payload);
                }
            };
        };
    };
});