define('matrix-frontend/pods/components/employees-page/component', ['exports', 'ember-redux', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/scrollbar', 'matrix-frontend/utils/state'], function (exports, _emberRedux, _types, _scrollbar, _state) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var scrollbarWidth = (0, _scrollbar.getScrollBarWidth)();

    var stateToComputed = function stateToComputed(state) {
        return {
            node: state.cube.node,
            view: state.cube.view,
            clients: state.client.clients,
            stores: state.node.stores,
            employeesDB: state.employee,
            weekEndDate: state.cube.weekEndDate,
            employeeId: state.employeePanel.employeeId,
            currentUser: state.user.currentUser,
            shiftsByEmployee: state.scheduleEdit.shiftsByEmployee
            // userRoles: state.user.currentUser.roles,
            // generalRoles: state.general.roles,
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {
            print: function print() {
                return window.print();
            },
            goToOverview: function goToOverview() {
                return dispatch({
                    type: _types.CUBE_SET_PARTIAL,
                    payload: {
                        view: 'overview'
                    }
                });
            }
        };
    };

    var LEGACY_LINKS = {
        overview: {
            display: 'roster',
            route: 'legacy.employeeroster'
        },
        skills: {
            display: 'skills',
            route: 'legacy.employeeskills'
        },
        minmax: {
            display: 'min/max',
            route: 'legacy.employeeminmaxhours'
        },
        availability: {
            display: 'availability',
            route: 'legacy.employeeavailability'
        }
    };

    var Comp = Ember.Component.extend({
        scrollbarWidth: scrollbarWidth,
        redux: Ember.inject.service(),
        modalService: Ember.inject.service(),
        stateService: Ember.inject.service(),
        activeEmployeeId: null,
        forceDOTW: null,

        currentStore: Ember.computed('node', 'stores', function () {
            var node = this.node;
            var stores = this.stores;
            if (node && stores) {
                return stores[node.clientCode + '.' + node.tag];
            } else {
                return null;
            }
        }),

        currentClient: Ember.computed('clients', 'node', function () {
            var node = this.get('node');
            var clients = this.get('clients');
            return node && clients ? clients[node.clientCode] : null;
        }),

        canEmail: Ember.computed('currentUser', 'currentClient', function () {
            var can = (0, _state.hasScope)('employee.store:email');
            return can;
        }),

        userRoles: Ember.computed('currentUser', function () {
            if (!this.currentUser) {
                return null;
            }
            return this.currentUser.roles;
        }),

        generalRoles: Ember.computed('currentClient', function () {
            if (this.currentClient) {
                return this.currentClient.roles;
            }
            return null;
        }),

        legacyLink: Ember.computed('view', function () {
            return LEGACY_LINKS[this.view] || null;
        }),

        employees: Ember.computed('weekEndDate', 'currentStore', 'employeesDB', function () {
            var employeesDB = this.employeesDB;
            var store = this.currentStore;
            var employeeFilters = this.employeeFilters;
            var weekEndDate = this.weekEndDate;
            if (store && weekEndDate && employeesDB) {
                weekEndDate = weekEndDate.format('YYYY-MM-DD');

                if (store.weeks[weekEndDate] && store.weeks[weekEndDate].employees) {
                    return store.weeks[weekEndDate].employees.map(function (ee) {
                        return _extends({}, employeesDB[ee.id], ee, {
                            isLoan: store.id !== employeesDB[ee.id].store.id
                        });
                    })
                    // .filter(ee => {
                    //     const filters = Object.keys(employeeFilters).map(
                    //         x => {
                    //             const f = employeeFilters[x]
                    //             if (x === 'position' && f) {
                    //                 return ee.position.id === Number(f)
                    //             } else if (x === 'skill' && f) {
                    //                 const eeSkills = ee.skills.reduce(
                    //                     (o, i) => {
                    //                         o[i.activity] = i.level
                    //                         return o
                    //                     },
                    //                     {},
                    //                 )
                    //                 return eeSkills[Number(f)] > 0
                    //             } else {
                    //                 return true
                    //             }
                    //         },
                    //     )
                    //     const allIsTrue = x => x === true
                    //     return filters.every(allIsTrue)
                    // })
                    .sort(function (x, y) {
                        var xName = ('' + x.lastName + x.firstName).toUpperCase();
                        var yName = ('' + y.lastName + y.firstName).toUpperCase();
                        var nameComparison = xName > yName ? 1 : xName < yName ? -1 : 0;

                        return x.position.orderIndex - y.position.orderIndex || nameComparison;
                    });
                }
            }

            return null;
        }),

        employeeObjects: Ember.computed('employees', 'currentClient.options', function () {
            if (this.employees) {
                var SEND_EMAILS_TO_UNVERIFIED = (0, _state.getClientOption)('send_emails_to_unverified', 'bool');
                return this.employees.map(function (x) {
                    var name = (x.isLoan ? '(' + x.store.code + ') ' : '') + (x.lastName + ', ' + x.firstName) + (x.email ? ' <' + x.email + '>' : '');
                    return _extends({}, x, {
                        disabled: !x.email || x.restrictedReason || !SEND_EMAILS_TO_UNVERIFIED && !x.verifiedEmail,
                        name: name
                    });
                });
            }
            return null;
        }),

        activeEmployee: Ember.computed('employees', 'activeEmployeeId', function () {
            var _this = this;

            var activeEmployee = this.employees ? _extends({}, this.employees.find(function (x) {
                return x.id === _this.activeEmployeeId;
            })) : null;
            return activeEmployee;
        }),

        employeePanelEmployee: Ember.computed('employees', 'employeeId', function () {
            var _this2 = this;

            var activeEmployee = this.employees ? this.employees.find(function (x) {
                return x.id === _this2.employeeId;
            }) : null;
            return activeEmployee;
        }),

        activitiesById: Ember.computed('currentClient.activities', function () {
            var currentClient = this.get('currentClient');
            if (currentClient && currentClient.activities) {
                var a = currentClient.activities.reduce(function (o, i) {
                    o[i.id] = i;
                    return o;
                }, {});
                return a;
            } else {
                return {};
            }
        }),

        // didInsertElement() {
        //     this.redux.dispatch({
        //         type: types.CUBE_SET_PAGE,
        //         payload: {name: 'employees'},
        //     })
        // },

        actions: {
            openNewEmployeeModal: function openNewEmployeeModal() {
                this.modalService.open('new-employee-modal');
            },
            openAvailabilityModal: function openAvailabilityModal(employeeId, dotw) {
                var _this3 = this;

                this.set('activeEmployeeId', employeeId);
                this.set('forceDOTW', dotw);
                setTimeout(function () {
                    _this3.modalService.open('availability-modal');
                }, 1);
            },
            email: function email() {
                this.modalService.open('email-modal');
            },

            goTo: function goTo(page) {
                var date = this.stateService.getWeekEndDate();
                var store = this.stateService.getBusinessUnit();
                this.router.transitionTo(page, {
                    queryParams: {
                        date: date,
                        storeId: store.id
                    }
                });
            }
        }
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});