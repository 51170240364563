define('matrix-frontend/pods/components/mouse-event/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        redux: Ember.inject.service(), // Needed here to be passed into the context for triggerHold/triggerRelease

        present: false,
        holding: false,
        touchEvent: false,
        enableActions: true,
        event: {},

        getState: function getState() {
            var _get = this.get('event'),
                altKey = _get.altKey,
                clientX = _get.clientX,
                clientY = _get.clientY,
                ctrlKey = _get.ctrlKey,
                offsetX = _get.offsetX,
                offsetY = _get.offsetY,
                shiftKey = _get.shiftKey,
                target = _get.target,
                touches = _get.touches;

            var useTarget = Ember.$(target).hasClass('yield-to-parent') ? target.parentElement : target;

            var state = {
                altKey: altKey,
                clientX: clientX,
                clientY: clientY,
                ctrlKey: ctrlKey,
                offsetX: offsetX,
                offsetY: offsetY,
                shiftKey: shiftKey,
                touches: touches,
                target: useTarget,
                present: this.get('present'),
                holding: this.get('holding')
            };

            return state;
        },
        mouseMove: function mouseMove(event) {
            if (this.get('enableActions')) {
                // console.log('mouseMove')
                this.set('event', event);
                this.triggerMove();
                this.triggerMoveOnHold();
                return false;
            }
        },
        touchMove: function touchMove(event) {
            if (this.get('enableActions')) {
                var location = event.originalEvent.changedTouches[0];
                var realTarget = document.elementFromPoint(location.clientX, location.clientY);
                this.set('event', event);
                this.set('event.target', realTarget);
                this.triggerMove();
                this.triggerMoveOnHold();
                return false;
            }
        },
        mouseDown: function mouseDown(event) {
            if (this.get('enableActions')) {
                // console.log('mouseDown')
                if (event.which) {
                    this.set('event', event);
                    this.triggerHold();
                    return false;
                }
            }
        },
        touchStart: function touchStart(event) {
            if (this.get('enableActions')) {
                this.set('present', true);
                this.set('event', event);
                this.set('touchEvent', true);
                this.triggerHold();
                return false;
            }
        },
        mouseUp: function mouseUp(event) {
            if (this.get('enableActions')) {
                // console.log('mouseUp')
                if (event.which) {
                    this.triggerRelease();
                    return false;
                }
            }
        },
        touchEnd: function touchEnd(event) {
            if (this.get('enableActions')) {
                this.set('touchEvent', false);
                this.triggerRelease();
                return false;
            }
        },
        mouseEnter: function mouseEnter(event) {
            if (this.get('enableActions')) {
                this.set('present', true);
            }
        },
        mouseLeave: function mouseLeave(event) {
            if (this.get('enableActions')) {
                this.set('present', false);
                this.set('event', event);
                if (this.get('holding')) {
                    this.triggerRelease();
                } else {
                    this.triggerLeave();
                }
            }
        },


        state: Ember.computed('holding', 'event', function () {
            return this.get('getState').bind(this).call();
        }),

        triggerHold: function triggerHold() {
            if (this.get('enableActions')) {
                this.set('holding', true);
                var state = this.get('state');

                if (this.get('onHold')) {
                    this.get('onHold').bind(this).call();
                }
            }
        },
        triggerLeave: function triggerLeave() {
            if (this.get('enableActions')) {
                if (this.get('onLeave')) {
                    this.get('onLeave').bind(this).call();
                }
            }
        },
        triggerMove: function triggerMove() {
            if (this.get('enableActions')) {
                if (this.get('onMove')) {
                    this.get('onMove').bind(this).call();
                }
            }
        },
        triggerMoveOnHold: function triggerMoveOnHold() {
            if (this.get('enableActions')) {
                if (this.get('onMoveOnHold') && this.get('holding')) {
                    this.get('onMoveOnHold').bind(this).call();
                }
            }
        },
        triggerRelease: function triggerRelease() {
            if (this.get('enableActions')) {
                this.set('holding', false);

                if (this.get('onRelease')) {
                    this.get('onRelease').bind(this).call();
                }
            }
        }
    });
});