define('matrix-frontend/pods/components/mx-toggler/component', ['exports', 'npm:jquery'], function (exports, _npmJquery) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    exports.default = Ember.Component.extend({
        checked: false,
        reversed: true,
        toggleId: Ember.computed(function () {
            return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
        }),

        init: function init() {
            this._super();
            if (!arguments[0].attrs.labels || !Array.isArray(arguments[0].attrs.labels)) {
                console.error('toggler labels must be array');
            }

            var _arguments$0$attrs$la = _slicedToArray(arguments[0].attrs.labels, 2),
                opt2 = _arguments$0$attrs$la[0],
                opt1 = _arguments$0$attrs$la[1];

            this.set('opt1', opt1);
            this.set('opt2', opt2);
        },

        didInsertElement: function didInsertElement() {
            var _this = this;

            if (this.onToggle) {
                (0, _npmJquery.default)('#toggle-' + this.toggleId).on('change', function () {
                    _this.onToggle.call();
                });
            }
        },


        actions: {
            toggle: function toggle() {
                console.log('toggle');
                this.set('checked', !this.checked);
                // $(`#toggle-${this.toggleId}`).trigger('click')
            }
        }
    });
});