define('matrix-frontend/pods/components/logged-out/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        cookies: Ember.inject.service(),
        /**
         * TODO remove once all legacy iframed pages are removed
         */
        activate: function activate() {
            if (window !== window.top) {
                top.window.location.href = '/logged-out';
            }
        },
        afterModel: function afterModel() {
            this.get('cookies').clear('loggedOut', { path: '/' });
            this.get('cookies').clear('JSESSIONID', { path: '/' });
        }
    });
});