define('matrix-frontend/actions/websocket', ['exports', 'matrix-frontend/actions/_types'], function (exports, _types) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.send = undefined;
    var send = exports.send = function send(msg) {
        return function (dispatch) {
            return dispatch({
                type: _types.WS_SEND,
                payload: {
                    message: msg
                }
            });
        };
    };
});