define('matrix-frontend/services/legacy-service', ['exports', 'matrix-frontend/actions/_types'], function (exports, _types) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Service.extend({
        valueChanged: false,
        leaving: false,

        legacyView: 'editSchedule',
        hideMenu: false,
        showCloser: false,
        loadingService: Ember.inject.service(),
        saveService: Ember.inject.service(),
        redux: Ember.inject.service(),

        init: function init() {
            this._super.apply(this, arguments);
            window.addEventListener('message', Ember.run.bind(this, this.get('receive')), false);
        },

        setWindow: function setWindow(w) {
            this.set('window', w);
        },

        post: function post(data) {
            if (this.window) {
                var url = window.location.href,
                    parts = url.split('/'),
                    allowed = parts[0] + '//' + parts[2];

                this.window.postMessage(JSON.stringify(data), allowed);
            }
        },

        receive: function receive(e) {
            if (e.data) {
                if (typeof e.data === 'string') {
                    // console.log(!e.data.type)
                    // console.log(!e.data.type !== 'ACTION')
                    // console.log(e)
                    var url = window.location.href,
                        parts = url.split('/'),
                        allowed = parts[0] + '//' + parts[2];

                    if (e.origin !== allowed) {
                        return;
                    }

                    try {
                        var message = JSON.parse(e.data);
                        Ember.run.bind(this, this.get('executeMessage'))(message);
                    } catch (e) {
                        console.error(e);
                    }
                }
            }
        },

        executeMessage: function executeMessage(message) {
            var action = message.action,
                parameters = message.parameters,
                actionMethod = this.get('methods')[action];


            try {
                var params = Array.isArray(parameters) ? parameters : JSON.parse(parameters);

                if (actionMethod) {
                    Ember.run.bind(this, actionMethod).apply(undefined, _toConsumableArray(params));
                }
            } catch (e) {
                console.error(e);
            }
        },

        methods: {
            // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
            valueChanged: function valueChanged(value) {
                this.set('valueChanged', value);
                this.get('saveService').set('valueChanged', value);
                var redux = this.get('redux');
                redux.dispatch({
                    type: _types.TIMEOUT_RESET
                });
            },
            click: function click(target) {
                var element = Ember.$('#' + target);
                if (element) {
                    element.click();
                }
                var redux = this.get('redux');
                redux.dispatch({
                    type: _types.TIMEOUT_RESET
                });
            },
            loading: function loading(isLoading) {
                if (!this.get('leaving')) {
                    var loadingService = this.get('loadingService');
                    if (isLoading) {
                        loadingService.startLoading();
                    } else {
                        loadingService.endLoading();
                    }
                } else {
                    this.set('leaving', false);
                }

                // if (!this.get('leaving')) {
                //     const loadingService = this.get('loadingSlider');
                //     if (isLoading) {
                //         loadingService.startLoading();
                //     } else {
                //         loadingService.endLoading();
                //     }
                // } else {
                //     this.set('leaving', false);
                // }
            }
        }
    });
});