define('matrix-frontend/actions/payroll-worksheet', ['exports', 'matrix-frontend/utils/backoff', 'matrix-frontend/utils/interactions', 'matrix-frontend/utils/state'], function (exports, _backoff, _interactions, _state) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.payrollWorksheetReload = undefined;
    var payrollWorksheetReload = exports.payrollWorksheetReload = function payrollWorksheetReload(store) {
        (0, _interactions.setStore)(store);
        console.debug('~~payrollWorksheetReload~~');

        (0, _backoff.generalBackoff)(function () {
            return store.getState().cube.node && store.getState().cube.clientCode && store.getState().user.currentUser && store.getState().cube.weekEndDate && store.getState().websocket.currentSocket && store.getState().websocket.status == 1;
        }, function () {
            var _store$getState$cube = store.getState().cube,
                clientCode = _store$getState$cube.clientCode,
                node = _store$getState$cube.node,
                weekEndDate = _store$getState$cube.weekEndDate;

            var thisWeek = weekEndDate.format('YYYY-MM-DD');
            var lyWeek = weekEndDate.clone().subtract(52, 'weeks').format('YYYY-MM-DD');
            (0, _interactions.gatherReport)(clientCode, node.tag, 'salesactual', 'day', thisWeek);
            (0, _interactions.gatherReport)(clientCode, node.tag, 'salesactual', 'day', lyWeek);
            (0, _interactions.gatherReport)(clientCode, node.tag, 'salesforecast', 'day', thisWeek);
            (0, _interactions.gatherReport)(clientCode, node.tag, 'originalsalesforecast', 'day', thisWeek);
            (0, _interactions.gatherReport)(clientCode, node.tag, 'actuallabor', 'day', thisWeek);
            (0, _interactions.gatherReport)(clientCode, node.tag, 'schedulehours', 'day', thisWeek);
            (0, _interactions.gatherReport)(clientCode, node.tag, 'taskhours', 'day', thisWeek);
        });

        (0, _backoff.generalBackoff)(function () {
            return store.getState().cube.node && store.getState().cube.clientCode && store.getState().user.currentUser && store.getState().cube.weekEndDate;
        }, function () {
            var _store$getState$cube2 = store.getState().cube,
                clientCode = _store$getState$cube2.clientCode,
                weekEndDate = _store$getState$cube2.weekEndDate;

            var thisWeek = weekEndDate.format('YYYY-MM-DD');
            // retrieve items
            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'client',
                    namespace: 'checklist',
                    operation: 0,
                    include_channel: 1,
                    broadcast: false,
                    params: {
                        count: 0,
                        week_end_date: thisWeek,
                        enabled: true,
                        are_hours_allocated: true
                    },
                    response_type: 'PW_INTAKE_ITEMS'
                }
            }, 'retrieve list of items: ' + store.getState().cube.clientCode + ' - ' + thisWeek, false, null, null, null, true);

            // if(store.getState().cube.node.nodeType === 'store') {
            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'client',
                    namespace: ['checklist', 'items'],
                    operation: 0,
                    include_channel: 2,
                    broadcast: false,
                    params: {
                        week_end_date: thisWeek,
                        include_overdue: true
                    },
                    response_type: 'TODO_INTAKE_ITEMS'
                }
            }, 'retrieve list of checklist items: ' + store.getState().cube.clientCode + ', ' + store.getState().cube.node.tag + ', ' + thisWeek, false, null, null, null, true);
            // }

        });

        // retrieve payroll cost report (if possible)
        (0, _backoff.generalBackoff)(function () {
            return store.getState().cube.node && store.getState().cube.clientCode && store.getState().user.currentUser && store.getState().cube.weekEndDate &&
            /*
            let's check if the current user meets the criteria for seeing
            the payroll cost
            */
            (0, _state.getClientOption)('show_payroll_cost', 'bool') && ((0, _state.hasScope)('payrollworksheet.store:view.cost') || (0, _state.hasScope)('payrollworksheet.rollup:view.cost') || (0, _state.hasScope)('payrollworksheet.store:view.percentage') || (0, _state.hasScope)('payrollworksheet.rollup:view.percentage'));
        }, function () {
            var _store$getState$cube3 = store.getState().cube,
                clientCode = _store$getState$cube3.clientCode,
                node = _store$getState$cube3.node,
                weekEndDate = _store$getState$cube3.weekEndDate;

            var thisWeek = weekEndDate.format('YYYY-MM-DD');
            // retrieve payroll cost
            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'data',
                    namespace: 'payrollcost',
                    operation: 0,
                    include_channel: 2,
                    broadcast: false,
                    params: {
                        week_end_date: thisWeek
                    },
                    response_type: 'PW_INTAKE_COST'
                }
            }, 'retrieve payroll cost: ' + store.getState().cube.clientCode + '.' + node.tag + ' - ' + thisWeek, false, null, null, null, true);
        });
    };
});