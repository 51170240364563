define('matrix-frontend/pods/components/schedule-page-section-unassigned-row/component', ['exports', 'ember-redux', 'matrix-frontend/actions/_types', 'npm:moment', 'matrix-frontend/utils/date-helper'], function (exports, _emberRedux, _types, _npmMoment, _dateHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var stateToComputed = function stateToComputed(state) {
        return {
            // currentBrush: state.scheduleEdit.currentBrush,
            // lastExpandedRow: state.scheduleEdit.lastExpandedRow,
            // expandedRow: state.scheduleEdit.expandedRow,
            // shifts: state.scheduleEdit.shifts,
            // startLeft: state.scheduleEdit.startLeft,
            // startTop: state.scheduleEdit.startTop,
            displayStart: state.scheduleEdit.displayStart,
            displayEnd: state.scheduleEdit.displayEnd,
            // editMode: state.scheduleEdit.editMode,
            date: state.cube.date
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {
            pinEmployeePanel: function pinEmployeePanel(employee) {
                return dispatch({ type: _types.EP_PIN, payload: { employee: employee } });
            },
            expandRow: function expandRow(rowId) {
                return dispatch({
                    type: _types.SE_EXPAND_ROW,
                    rowId: rowId
                });
            }
        };
    };

    var Comp = Ember.Component.extend({
        days: Ember.computed(function () {
            var raw = [].concat(_toConsumableArray(Array(7).keys()));
            return raw.map(function (x) {
                return x + 1;
            });
        }),

        dailyShifts: Ember.computed('unassignedShifts', function () {
            var unassignedShifts = this.get('unassignedShifts');
            var date = this.get('date');

            if (!date || !unassignedShifts) {
                return null;
            }

            var dotw = (0, _dateHelper.getDayOfClientWeek)(date) + 1;
            return unassignedShifts[dotw];
        }),

        rowShifts: Ember.computed('unassignedShifts', function () {
            var unassignedShifts = this.get('unassignedShifts');
            var index = this.get('index') - 1;
            var rowShifts = Object.keys(unassignedShifts).map(function (x) {
                return unassignedShifts[x][index] || null;
            });

            var i = 1;
            return rowShifts.reduce(function (x, y) {
                x[i] = y;
                i += 1;
                return x;
            }, {});
        })
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});