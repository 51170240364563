define('matrix-frontend/store', ['exports', 'redux'], function (exports, _redux) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.store = exports.makeStoreInstance = undefined;

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var store = void 0;
    var makeStoreInstance = function makeStoreInstance(_ref) {
        var middlewares = _ref.middlewares,
            reducers = _ref.reducers,
            enhancers = _ref.enhancers;

        var createStoreWithMiddleware = (0, _redux.compose)(_redux.applyMiddleware.apply(undefined, _toConsumableArray(middlewares)), enhancers)(_redux.createStore);
        exports.store = store = createStoreWithMiddleware(reducers);
        return store;
    };

    exports.makeStoreInstance = makeStoreInstance;
    exports.store = store;
});