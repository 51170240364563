define('matrix-frontend/pods/schedule/route', ['exports', 'npm:moment', 'matrix-frontend/routes/base-route', 'matrix-frontend/config/environment', 'matrix-frontend/utils/interactions', 'matrix-frontend/utils/backoff', 'matrix-frontend/utils/date-helper'], function (exports, _npmMoment, _baseRoute, _environment, _interactions, _backoff, _dateHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseRoute.default.extend({
        redux: Ember.inject.service(),
        ajaxService: Ember.inject.service(),
        stateService: Ember.inject.service(),
        legacyService: Ember.inject.service(),

        queryParams: {
            hierarchyNodeId: {
                refreshModel: true
            }
        },

        model: function model(params, transition) {
            var self = this;
            return this._super(params, transition).then(function () {
                var ajaxService = self.get('ajaxService');
                var legacyService = self.get('legacyService');

                if (transition.queryParams.pageView) {
                    legacyService.set('legacyView', transition.queryParams.pageView);
                    if (transition.queryParams.pageView !== 'editSchedule') {
                        legacyService.set('hideMenu', true);
                    }
                }

                var businessUnit = void 0;
                var date = transition.queryParams.date ? transition.queryParams.date : (0, _dateHelper.getClientWeekEndDate)().format('YYYY-MM-DD');

                if (transition.queryParams.hierarchyNodeId) {
                    businessUnit = {
                        type: 'hierarchyNode',
                        id: transition.queryParams.hierarchyNodeId
                    };
                } else if (transition.queryParams.storeId) {
                    businessUnit = {
                        type: 'store',
                        id: transition.queryParams.storeId
                    };
                }

                var retVal = {
                    businessUnit: businessUnit,
                    date: date,
                    print: transition.queryParams.print,
                    goToDay: transition.queryParams.goToDay
                };

                if (businessUnit.type === 'hierarchyNode') {
                    var hierarchyNode = self.get('store').peekRecord('hierarchy-node', businessUnit.id);
                    var clientCode = self.redux.getState().cube.clientCode;

                    var type = hierarchyNode.get('type') ? hierarchyNode.get('type') + '.' : '';
                    var tag = '' + type + hierarchyNode.get('code');

                    var scheduleSummaryUrl = '/workforce/api/scheduleSummary?' + businessUnit.type + '=' + businessUnit.id + '&date=' + date;
                    var nodeChildrenUrl = '/workforce/api/hierarchyNodes/' + businessUnit.id + '/children';
                    var taskUrl = '/workforce/api/tasks?clientId=' + self.get('stateService').getClient().id;

                    // const sarUrl = `/workforce/v2/report_data/sales_at_risk/${clientCode.toLowerCase()}/${businessUnit.type.toLowerCase()}/${businessUnit.id}/${date}?children=true`;
                    // const sarUrl = `${config.mxl.replace('${host}', window.location.host)}/data/sar/${clientCode.toLowerCase()}/${tag}?week_end_data=${date}&save_point=week`;

                    retVal['hierarchyNodeId'] = businessUnit.id;
                    retVal['scheduleSummary'] = ajaxService.ajaxGet(scheduleSummaryUrl);
                    retVal['nodeChildren'] = ajaxService.ajaxGet(nodeChildrenUrl);
                    retVal['tasks'] = ajaxService.ajaxGet(taskUrl);
                    // retVal['salesAtRisk'] = null;

                    // console.log('!!!!!!!!!!!!!!!!!!!!!!!')

                    // const store = self.get('redux').store;
                    // setStore(store);
                    // generalBackoff(
                    //     () => {
                    //         const currentNode = store.getState().cube.node;
                    //         const fulltag = `${currentNode.clientCode}.${currentNode.tag}`;
                    //         const hierarchy = store.getState().node.hierarchies[fulltag];
                    //         console.log(!!hierarchy && (hierarchy.stores || hierarchy.children))
                    //         if (hierarchy) {
                    //             console.log(hierarchy.stores.length, hierarchy.children.length)
                    //             return hierarchy && (hierarchy.stores || hierarchy.children);
                    //         } else {
                    //             return false;
                    //         }
                    //     },
                    //     () => {
                    //         // console.group('scheduleRoute load')
                    //         const currentNode = store.getState().cube.node;
                    //         const fulltag = `${currentNode.clientCode}.${currentNode.tag}`;
                    //         const hierarchy = store.getState().node.hierarchies[fulltag];
                    //         // console.log('fulltag', fulltag, hierarchy)
                    //         const fetchSAR = s => {
                    //             send({
                    //                 header: 'do',
                    //                 body: {
                    //                     service: 'data',
                    //                     namespace: 'salesatrisk',
                    //                     operation: 0,
                    //                     include_channel: 3,
                    //                     channel_override: [
                    //                         currentNode.clientCode,
                    //                         s.tag,
                    //                     ],
                    //                     params: {
                    //                         'week_end_date': date,
                    //                         'focus': 'week'
                    //                     },
                    //                     response_type: 'NODE_INTAKE'
                    //                 }
                    //             });
                    //         };
                    //         hierarchy.stores.forEach(fetchSAR);
                    //         hierarchy.children.forEach(fetchSAR);
                    //         // console.groupEnd();
                    //     },
                    //     'salesAtRisk'
                    // );
                }

                return Ember.RSVP.hash(retVal);
            });
        }
    }

    // actions: {
    //     selectBusinessUnit: function (id, type) {
    //         console.log('go to', id, type);

    //       this.get('stateService').setHierarchyNode(34);
    //   }
    // }
    );
});