define('matrix-frontend/components/react-only/store-details-tooltip', ['exports', 'react', 'npm:react-tooltip', 'matrix-frontend/utils/format-helpers'], function (exports, _react, _npmReactTooltip, _formatHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var makeAddressText = function makeAddressText(_ref) {
        var city = _ref.city,
            state = _ref.state,
            zip = _ref.zip;

        var fixedCity = city ? _formatHelpers.default.fixAllCaps(city) : null;
        var stateZipPart = state || zip ? [state, zip].filter(function (x) {
            return x != null;
        }).join(' ') : null;
        if (fixedCity && stateZipPart) return fixedCity + ', ' + stateZipPart;
        return fixedCity ? fixedCity : stateZipPart ? stateZipPart : null;
    };

    var comp = function comp(props) {
        var hierarchies = props.hierarchies;


        if (!hierarchies) return null;

        var getContent = function getContent(fullTag) {
            var store = hierarchies[fullTag];
            if (!store) return null;
            var parent = hierarchies[store.parent];
            if (!parent) return null;

            return _react.default.createElement(
                'div',
                null,
                _react.default.createElement(
                    'div',
                    { className: 'weight-heavy' },
                    store.code.toUpperCase() + ' ' + _formatHelpers.default.fixAllCaps(store.name || '')
                ),
                _react.default.createElement(
                    'div',
                    null,
                    parent.name
                ),
                _react.default.createElement(
                    'div',
                    null,
                    makeAddressText(store.info)
                )
            );
        };

        return _react.default.createElement(_npmReactTooltip.default, { id: 'storeDetailsTooltip', effect: 'solid', getContent: getContent });
    };

    exports.default = comp;
});