define('matrix-frontend/pods/stores/edit/route', ['exports', 'matrix-frontend/utils/confirm-navigate', 'matrix-frontend/routes/base-route', 'matrix-frontend/actions/_types'], function (exports, _confirmNavigate, _baseRoute, _types) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    exports.default = _baseRoute.default.extend({
        flashMessages: Ember.inject.service(),
        stateService: Ember.inject.service(),

        queryParams: {
            storeId: {
                refreshModel: true
            },
            hierarchyNodeId: {
                refreshModel: true
            },
            date: {
                refreshModel: true
            },
            dataType: {
                refreshModel: true
            },
            view: {
                refreshModel: true
            }
        },

        beforeModel: function beforeModel(transition) {
            var _transition$queryPara = transition.queryParams,
                hierarchyNodeId = _transition$queryPara.hierarchyNodeId,
                dataType = _transition$queryPara.dataType,
                view = _transition$queryPara.view;


            var finalQueryParams = _extends({}, transition.queryParams);
            var shouldReroute = false;

            if (hierarchyNodeId != null) {
                if (dataType != 'public' && dataType != 'private') {
                    finalQueryParams.dataType = 'public';
                    shouldReroute = true;
                }
                if (view != 'visual' && view != 'numeric') {
                    finalQueryParams.view = 'visual';
                    shouldReroute = true;
                }
            } else {
                if (dataType != null) {
                    finalQueryParams.dataType = null;
                    shouldReroute = true;
                }
                if (view != null) {
                    finalQueryParams.view = null;
                    shouldReroute = true;
                }
            }

            if (shouldReroute) {
                this.transitionTo(this.routeName, {
                    queryParams: finalQueryParams,
                    replace: true
                });
            }
        },
        model: function model(params, transition) {
            var self = this;
            return this._super(params, transition).then(function () {
                var _transition$queryPara2 = transition.queryParams,
                    hierarchyNodeId = _transition$queryPara2.hierarchyNodeId,
                    dataType = _transition$queryPara2.dataType,
                    view = _transition$queryPara2.view;

                if (hierarchyNodeId != null) {
                    self.redux.dispatch({
                        type: _types.SHS_SET,
                        payload: {
                            dataType: dataType,
                            view: view
                        }
                    });
                }
                return transition.queryParams;
            });
        },


        resetController: function resetController(controller) {
            var params = ['hierarchyNodeId', 'storeId', 'view', 'dataType'];
            params.forEach(function (param) {
                controller.set(param, null);
            });
        }

        // beforeModel(transition) {
        //     let controller = this.controllerFor('stores.edit')
        //     let formUpdated = controller.get('formUpdated')
        //     if (
        //         formUpdated &&
        //         confirmNavigate.confirmBeforeModel(controller, transition)
        //     ) {
        //         let store = this.store.peekRecord(
        //             'store',
        //             transition.params['stores.edit'].storeId,
        //         )
        //         if (store !== null) {
        //             let storeContactInfo = this.store.peekRecord(
        //                 'contactInfo',
        //                 store.get('contactInfo.id'),
        //             )
        //             if (
        //                 storeContactInfo !== null &&
        //                 storeContactInfo.hasDirtyAttributes
        //             ) {
        //                 this.discardChanges(storeContactInfo)
        //             }
        //         }
        //     }
        // },
        // discardChanges(contactInfo) {
        //     let controller = this.controllerFor('stores')
        //     let storeContactInfo = this.store.peekRecord(
        //         'contactInfo',
        //         contactInfo.get('id'),
        //     )
        //     storeContactInfo.rollbackAttributes()
        //     controller.set('formUpdated', false)
        // },

        // model(params, transition) {
        //     if (transition.queryParams.storeId) {
        //         let url = '/workforce/api/utility/statecodes'
        //         return hash({
        //             statecodes: $.ajax(url, {
        //                 dataType: 'json',
        //                 headers: {'Access-Token': this.session.get('token')},
        //             }),
        //             store: this.store.findRecord(
        //                 'store',
        //                 transition.queryParams.storeId,
        //             ),
        //         })
        //     }
        // },
        // setupController(controller, model) {
        //     this._super(controller, model)
        //     controller.set('formUpdated', false)
        // },

        // actions: {
        //     queryParamsDidChange: function() {
        //         // console.info('query params change')
        //         this.refresh()
        //     },
        // },

        // actions: {
        //     discardChanges(contactInfo) {
        //         if (
        //             confirm(
        //                 "You have Unsaved changes.\nTo Discard your changes and continue, press 'OK'\nTo Go Back, press 'Cancel'",
        //             )
        //         ) {
        //             let controller = this.controllerFor('stores.edit')
        //             controller.set('formUpdated', false)
        //             this.discardChanges(contactInfo)
        //         }
        //     },

        //     saveContactInfo(contactInfo) {
        //         const flashMessages = this.get('flashMessages')

        //         let store = this.modelFor('stores/edit').store
        //         let storeContactInfo = this.store.peekRecord(
        //             'contactInfo',
        //             contactInfo.get('id'),
        //         )
        //         storeContactInfo.set('store', store)

        //         storeContactInfo.save().then(
        //             function() {
        //                 flashMessages.success('Store Contactinfo Saved!')
        //             },
        //             function() {
        //                 flashMessages.alert(
        //                     'There was an error saving Store Contactinfo',
        //                 )
        //             },
        //         )
        //     },
        //     selectBusinessUnit(id, type) {
        //         if (type === 'store') {
        //             this.transitionTo('stores.edit', id)
        //         } else {
        //             this.get('stateService').setHierarchyNode(id)
        //             this.transitionTo('placeholder', {
        //                 queryParams: {hierarchyNodeId: id},
        //             })
        //         }
        //     },

        //     willTransition(transition) {
        //         let controller = this.controllerFor('stores.edit')
        //         confirmNavigate.confirmWillTransition(controller, this, transition)
        //     },
        // },
    });
});