define('matrix-frontend/pods/components/store-employee-row/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init: function init() {
            this._super();
            //var self = this;
        },
        model: function model() {},

        actions: {
            editSkills: function editSkills() {}
        }
    });
});