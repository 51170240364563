define('matrix-frontend/reducers/employee', ['exports', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/cleaners', 'matrix-frontend/store'], function (exports, _types, _cleaners, _store) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var initialState = {};

    var setEmployeeField = function setEmployeeField(employee, field, value) {
        switch (field) {
            case 'positionCode':
                {
                    var state = _store.store.getState();
                    var node = state.cube.node,
                        clients = state.client.clients;

                    var positions = clients[node.clientCode].positions;
                    var position = positions.find(function (x) {
                        return x.code === value;
                    });
                    employee.position = position;
                }
            default:
                {
                    employee[field] = value;
                }
        }
    };

    var employee = function employee(state, action) {
        var type = action.type,
            payload = action.payload;


        switch (type) {
            case _types.EE_INTAKE:
                {
                    var employees = payload.employees;

                    employees = employees.reduce(function (o, i) {
                        var x = _extends({}, i);
                        // x.availability = filter_keys(x.availability, ['default'])
                        x.availability = (0, _cleaners.filter_keys)(x.availability, []);
                        o[i.id] = (0, _cleaners.clean_keys)(x);
                        return o;
                    }, {});
                    return _extends({}, state, employees);
                }

            case _types.EE_UPDATE_SKILL:
                {
                    var employeeId = payload.employeeId,
                        activityId = payload.activityId,
                        level = payload.level;

                    var _employee = state[employeeId];

                    if (_employee.skillsByActivity[activityId] == null) {
                        _employee.skills.push({
                            employee: employeeId,
                            level: parseFloat(level),
                            activity: activityId
                        });
                    } else {
                        _employee.skills = _employee.skills.map(function (x) {
                            if (x.activity === activityId) {
                                x.level = parseFloat(level);
                            }
                            return x;
                        });
                    }
                    _employee.skillsByActivity[activityId] = parseFloat(level);

                    return _extends({}, state, {
                        employeeId: _employee
                    });
                }

            case _types.EE_UPDATE_EMPLOYEE:
                {
                    var _clean_keys = (0, _cleaners.clean_keys)(payload),
                        _employeeId = _clean_keys.employeeId,
                        field = _clean_keys.field,
                        value = _clean_keys.value;

                    var _employee2 = state[_employeeId];
                    if ([field, value].every(function (x) {
                        return Array.isArray(x);
                    })) {
                        field.forEach(function (x, idx) {
                            setEmployeeField(_employee2, x, value[idx]);
                        });
                    } else if ([field, value].some(function (x) {
                        return Array.isArray(x);
                    })) {
                        console.error('Cannot process non-matching field and value types');
                    } else {
                        setEmployeeField(_employee2, field, value);
                    }
                    return _extends({}, state, {
                        employeeId: _employee2
                    });
                }

            case _types.EE_PREP_EMAIL:
                {
                    var email = state.email;

                    return _extends({}, state, {
                        email: _extends({}, email, payload)
                    });
                }

            default:
                {
                    return state || initialState;
                }
        }
    };

    exports.default = employee;
});