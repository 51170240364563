define('matrix-frontend/pods/components/schedule-page-part-segment/component', ['exports', 'ember-redux', 'npm:moment'], function (exports, _emberRedux, _npmMoment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var stateToComputed = function stateToComputed(state) {
        return {
            displayDate: state.cube.date,
            displayDOTW: state.cube.dotw,
            currentBrush: state.scheduleEdit.currentBrush,
            expandedRow: state.scheduleEdit.expandedRow
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {};
    };

    var Comp = Ember.Component.extend({
        date: Ember.computed('displayDOTW', 'displayDate', 'segmentDOTW', function () {
            var displayDOTW = this.get('displayDOTW');
            var displayDate = this.get('displayDate').clone();
            var segmentDOTW = this.get('segmentDOTW');
            return displayDate.add(segmentDOTW - displayDOTW, 'days');
        }),

        isExpanded: Ember.computed('expandedRow', function () {
            var employee = this.get('employee');
            return employee ? this.get('expandedRow') === 'employee-' + employee.id : false;
        }),

        isPainting: Ember.computed('currentBrush.{startMoment,paintMoment}', function () {
            var currentBrush = this.get('currentBrush');
            return currentBrush.startMoment && !currentBrush.stopMoment;
        }),

        activityLiveColorId: Ember.computed('shift', 'currentBrush.{startMoment,paintMoment}', function () {
            var activitiesById = this.get('activitiesById');
            var shift = this.get('shift');
            var parts = this.get('segment').split('.');
            var activityId = null;

            if (shift && activitiesById) {
                var t = this.get('date').clone();
                t.hour(parts[0]);
                t.minute(parts[1]);

                var block = shift.blocks.find(function (x) {
                    return t >= x.start && t < x.end;
                });

                if (block) {
                    activityId = block.activityId;
                }
            }

            if (!activityId || !activitiesById) {
                return null;
            }

            var activity = activitiesById[activityId];
            return activity.livecolor.id;
        })
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});