define('matrix-frontend/components/store-hours', ['exports', 'ember-react-components', 'matrix-frontend/actions/_types', 'matrix-frontend/store', 'matrix-frontend/utils/holidays', 'matrix-frontend/utils/random', 'react'], function (exports, _emberReactComponents, _types, _store, _holidays, _random, _react) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var handleClick = function handleClick(modalService, week, dotw) {
        _store.store.dispatch({
            type: _types.CUBE_SET_TARGET,
            payload: { targetWeek: week, targetDOTW: dotw }
        });
        modalService.open('hoo-modal');
    };

    var HOODisplay = function HOODisplay(props) {
        var _props$hoo = props.hoo,
            publicOpen = _props$hoo.publicOpen,
            publicClose = _props$hoo.publicClose,
            publicOpenOffset = _props$hoo.publicOpenOffset,
            publicCloseOffset = _props$hoo.publicCloseOffset,
            isException = _props$hoo.isException,
            newDefault = _props$hoo.newDefault,
            closedAllDay = _props$hoo.closedAllDay;

        var classes = ['hoo-display'];
        if (isException || newDefault) {
            classes.push('exception');
        }
        if (closedAllDay === 'Y') {
            classes.push('closed');
            return _react.default.createElement(
                'div',
                { className: classes.join(' ') },
                'Closed'
            );
        }
        if (!publicOpen || !publicClose) {
            return _react.default.createElement(
                'div',
                { className: classes.join(' ') },
                'NONE'
            );
        }
        var publicOpenFormat = publicOpen.minutes() === 0 ? 'ha' : 'h:mma';
        var publicCloseFormat = publicClose.minutes() === 0 ? 'ha' : 'h:mma';
        return _react.default.createElement(
            'div',
            { className: classes.join(' ') },
            publicOpenOffset === -1 ? publicOpen.format('ddd') : '',
            publicOpen.format(publicOpenFormat),
            '\xA0-\xA0',
            publicCloseOffset === 1 ? publicClose.format('ddd') : '',
            publicClose.format(publicCloseFormat)
        );
    };

    var DailyCell = function DailyCell(props) {
        var _props$day = props.day,
            dotw = _props$day.dotw,
            hoo = _props$day.hoo,
            total = _props$day.total,
            modalService = props.modalService,
            week = props.week,
            canEdit = props.canEdit;

        var holidays = (0, _holidays.getHolidays)(hoo.date);
        var oddHours = (total <= 4 || total >= 16) && hoo.closedAllDay !== 'Y';
        var classes = ['column', 'f1', 'hoo-cell'];
        if (!canEdit) {
            classes.push('no-pointer');
        }
        return _react.default.createElement(
            'div',
            { className: classes.join(' '), onClick: function onClick() {
                    return canEdit && handleClick(modalService, week, dotw);
                } },
            _react.default.createElement(
                'div',
                { className: 'header' },
                holidays.length ? _react.default.createElement(
                    'div',
                    { className: 'holiday' },
                    holidays.join(', ')
                ) : hoo.newDefault ? _react.default.createElement(
                    'div',
                    { className: 'f1 text-right' },
                    'New hours'
                ) : _react.default.createElement(
                    _react.default.Fragment,
                    null,
                    _react.default.createElement(
                        'div',
                        { className: 'month' },
                        dotw == 1 ? hoo.date.format('MMM') : ''
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'date' },
                        hoo.date.date()
                    )
                )
            ),
            _react.default.createElement(HOODisplay, { hoo: hoo }),
            _react.default.createElement(
                'div',
                { className: oddHours ? 'subtotal odd-hours' : 'subtotal' },
                total,
                ' hrs'
            )
        );
    };
    var FakeCell = function FakeCell() {
        return _react.default.createElement(
            'div',
            { className: 'column f1 hoo-cell' },
            _react.default.createElement(
                'div',
                { className: 'header' },
                _react.default.createElement('div', { className: 'month fake-text gray-lighten-3-text' }),
                _react.default.createElement('div', { className: 'date fake-text gray-lighten-3-text' })
            ),
            _react.default.createElement('div', { className: 'hoo-display fake-text gray-lighten-3-text' })
        );
    };
    var FakeRow = function FakeRow() {
        return _react.default.createElement(
            'div',
            { className: 'row' },
            Array(8).fill().map(function (_) {
                return _react.default.createElement(FakeCell, { key: _random.integer(1, 99999) });
            })
        );
    };
    var WeeklyRow = function WeeklyRow(props) {
        var modalService = props.modalService;

        if (!props.week.hoo || !Object.keys(props.week.hoo).length) {
            return _react.default.createElement(FakeRow, null);
        }
        var days = Object.keys(props.week.hoo).map(function (dotw) {
            var hoo = props.week.hoo[dotw];
            return {
                dotw: dotw,
                hoo: hoo,
                total: hoo.publicClose && hoo.publicOpen ? hoo.publicClose.diff(hoo.publicOpen, 'hours', true) : 0
            };
        });
        return _react.default.createElement(
            'div',
            { className: 'row' },
            days.map(function (day) {
                return _react.default.createElement(DailyCell, {
                    key: day.dotw,
                    week: props.week.weekEndDate,
                    day: day,
                    modalService: modalService,
                    canEdit: props.canEdit
                });
            }),
            _react.default.createElement(
                'div',
                { className: 'column f1 hoo-cell' },
                days.reduce(function (o, i) {
                    return o + i.total;
                }, 0),
                ' hours'
            )
        );
    };

    var comp = function comp(props) {
        return _react.default.createElement(
            _react.default.Fragment,
            null,
            props.hoo && props.hoo.map(function (week) {
                return _react.default.createElement(WeeklyRow, {
                    key: week.weekEndDate,
                    week: week,
                    modalService: props.modalService,
                    canEdit: props.canEdit
                });
            })
        );
    };
    exports.default = (0, _emberReactComponents.default)(comp);
});