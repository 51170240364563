define('matrix-frontend/middleware/user', ['exports', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/cleaners', 'matrix-frontend/utils/interactions'], function (exports, _types, _cleaners, _interactions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var beforeListeners = {};
    var afterListeners = {};

    beforeListeners[_types.USER_EMAIL_PASSWORD] = function (store, payload) {
        var clientCode = store.getState().cube.clientCode;
        (0, _interactions.send)({
            header: 'do',
            body: {
                service: 'user',
                namespace: 'password',
                operation: 1,
                include_channel: 3,
                channel_override: [clientCode],
                payload: payload
            }
        }, null, false, null, null, null, true);
        return false;
    };
    beforeListeners[_types.USER_SEND_VERIFICATION_EMAIL] = function (store, payload) {
        var employeeId = payload.employeeId;

        var clientCode = store.getState().cube.clientCode;
        (0, _interactions.send)({
            header: 'do',
            body: {
                service: 'user',
                namespace: ['email', 'verify'],
                operation: 1,
                include_channel: 3,
                channel_override: [clientCode],
                extra: [employeeId],
                params: { debug: true }
            }
        }, null, false, null, null, null, true);
        return false;
    };
    beforeListeners[_types.USER_SEND_RESET_EMAIL] = function (store, payload) {
        var clientCode = store.getState().cube.clientCode;
        var employeeId = payload.employeeId;

        (0, _interactions.send)({
            header: 'do',
            body: {
                service: 'user',
                namespace: ['email', 'reset'],
                operation: 1,
                include_channel: 3,
                channel_override: [clientCode],
                extra: [employeeId]
            }
        }, null, false, null, null, null, true);
        return false;
    };
    beforeListeners[_types.USER_SAVE] = function (store, payload) {
        (0, _interactions.setStore)(store);
        var _payload = payload,
            userId = _payload.userId,
            employeeId = _payload.employeeId,
            onSuccess = _payload.onSuccess,
            onFailure = _payload.onFailure,
            onComplete = _payload.onComplete,
            roleNames = _payload.roleNames,
            nonStore = _payload.nonStore;

        var roles = roleNames;
        var clientCode = store.getState().cube.clientCode;
        var clients = store.getState().client.clients;
        var currentClient = clients[clientCode];
        var currentClientBck = store.getState().client[clientCode];
        var genRoles = currentClient ? currentClient.roles : currentClientBck.roles;
        payload.roleIds = roleNames.map(function (x) {
            return genRoles.find(function (y) {
                return y.name === x;
            });
        }).filter(function (x) {
            return x;
        }).map(function (x) {
            return x.id;
        });

        delete payload.userId;
        delete payload.employeeId;
        delete payload.onSuccess;
        delete payload.onFailure;
        delete payload.onComplete;
        delete payload.roleNames;
        delete payload.verifiedEmail;
        delete payload.nonStore;

        if (!roles.includes('dashboard')) {
            roles.push('dashboard');
        }

        var value = [payload.username, roles];
        var field = ['username', 'roles'];
        payload = (0, _cleaners.clean_keys)(payload, false);

        if (nonStore) {
            var changes = field.map(function (k, i) {
                return [k, value[i]];
            }).reduce(function (o, _ref) {
                var _ref2 = _slicedToArray(_ref, 2),
                    k = _ref2[0],
                    v = _ref2[1];

                o[k] = v;
                return o;
            }, {});
            var userUpdatePayload = _extends({
                clientCode: clientCode,
                user: _extends({}, store.getState().client[clientCode].users[userId])
            }, changes);
            store.dispatch({
                type: _types.CLIENT_NONSTORE_USER_UPDATE,
                payload: userUpdatePayload
            });
        } else {
            store.dispatch({
                type: _types.EE_UPDATE_EMPLOYEE,
                payload: {
                    value: value,
                    field: field,
                    employeeId: employeeId
                }
            });
        }

        var body = userId ? {
            service: 'user',
            namespace: !!nonStore ? 'nonstore' : 'field',
            operation: 3,
            include_channel: 1,
            extra: [userId],
            payload: payload
        } : {
            service: 'user',
            namespace: !!nonStore ? 'nonstore' : 'field',
            operation: 1,
            include_channel: 1,
            payload: (0, _cleaners.clean_keys)(_extends({}, payload, { employeeId: employeeId }), false)
        };

        if (nonStore) {
            body.include_channel = 3;
            body.channel_override = [clientCode];
        }

        (0, _interactions.send)({
            header: 'do',
            body: body
        }, 'Updating user', false, function (response) {
            if (!nonStore) {
                (0, _interactions.push)({
                    type: 'EE_UPDATE_EMPLOYEE',
                    value: value,
                    field: field,
                    employeeId: employeeId
                });
            }
            if (onSuccess) {
                onSuccess(response);
            }
            console.log({ response: response });
            console.log({ nonStore: nonStore });
            if (response.id && !nonStore) {
                store.dispatch({
                    type: _types.EE_UPDATE_EMPLOYEE,
                    payload: {
                        value: response.id,
                        field: 'userId',
                        employeeId: employeeId
                    }
                });
                (0, _interactions.push)({
                    type: 'EE_UPDATE_EMPLOYEE',
                    value: response.id,
                    field: 'userId',
                    employeeId: employeeId
                });
            }
        }, onFailure, onComplete, !!nonStore);
        return false;
    };

    afterListeners[_types.USER_LOGIN] = function (store, payload) {
        store.dispatch({
            type: _types.WS_CONNECT,
            payload: payload
        });
    };

    exports.default = function (store) {
        return function (next) {
            return function (action) {
                var type = action.type,
                    payload = action.payload;

                // Before listeners

                if (beforeListeners[type]) {
                    var ret = beforeListeners[type](store, payload);
                    if (ret !== undefined) {
                        if (ret === false) {
                            return;
                        } else {
                            action.payload = ret;
                        }
                    }
                }

                next(action);

                // After listeners
                if (afterListeners[type]) {
                    afterListeners[type](store, payload);
                }
            };
        };
    };
});