define('matrix-frontend/components/react-only/email-status-tooltip', ['exports', 'react', 'npm:react-tooltip'], function (exports, _react, _npmReactTooltip) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var comp = function comp(props) {
        var employeesById = props.employeesById;


        var getContent = function getContent(eId) {
            var e = employeesById[eId];
            var content = void 0;
            if (e) {
                if (e.restrictedReason == 'unsubscribe') {
                    content = _react.default.createElement(
                        _react.Fragment,
                        null,
                        'The owner of this email address has requested to be unsubscribed. To resubscribe, send an email to\xA0',
                        _react.default.createElement(
                            'a',
                            null,
                            'resubscribe@matrixretail.com'
                        ),
                        ' from\xA0',
                        _react.default.createElement(
                            'a',
                            null,
                            e.email
                        )
                    );
                } else if (e.restrictedReason == 'hard_bounce') {
                    content = _react.default.createElement(
                        _react.Fragment,
                        null,
                        'This email address doesn\'t seem to exist. Please verify the address with the employee.'
                    );
                } else if (e.restrictedReason == 'complaint') {
                    content = _react.default.createElement(
                        _react.Fragment,
                        null,
                        'This email address has reported previous emails as spam and automatically unsubscribed. To resubscribe, send an email to\xA0',
                        _react.default.createElement(
                            'a',
                            null,
                            'resubscribe@matrixretail.com'
                        ),
                        ' from\xA0',
                        _react.default.createElement(
                            'a',
                            null,
                            e.email
                        )
                    );
                } else if (e.restrictedReason == 'other') {
                    content = _react.default.createElement(
                        _react.Fragment,
                        null,
                        'This email address has been blocked.'
                    );
                }
                if (content) return _react.default.createElement(
                    'div',
                    { className: 'emailStatusTooltip-content' },
                    content
                );
            }
        };

        return _react.default.createElement(_npmReactTooltip.default, { id: 'emailStatusTooltip', effect: 'solid', getContent: getContent });
    };

    exports.default = comp;
});