define('matrix-frontend/components/checklist-body', ['exports', 'ember-react-components', 'matrix-frontend/store', 'matrix-frontend/actions/_types', 'npm:react-window', 'npm:moment', 'react', 'npm:react-tooltip', 'matrix-frontend/components/react-only/checklist-item-row', 'matrix-frontend/constants', 'matrix-frontend/utils/format-helpers'], function (exports, _emberReactComponents, _store, _types, _npmReactWindow, _npmMoment, _react, _npmReactTooltip, _checklistItemRow, _constants, _formatHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var FixedSizeList = _npmReactWindow.default.FixedSizeList;


    var setFocusItemId = function setFocusItemId(focusItemId) {
        return _store.store.dispatch({
            type: _types.TODO_SET_FOCUS_ITEM,
            payload: {
                focusItemId: focusItemId
            }
        });
    };

    var setShowstores = function setShowstores(showstores) {
        return _store.store.dispatch({
            type: _types.TODO_SET_SHOWSTORES,
            payload: {
                showstores: showstores
            }
        });
    };

    var FakeRow = function FakeRow(props) {
        return _react.default.createElement(
            'div',
            { className: 'row', style: props.style },
            _react.default.createElement(
                'div',
                { className: 'wrapper f1' },
                _react.default.createElement('div', { className: 'column f3 fake-text' }),
                _react.default.createElement('div', { className: 'column f1 fake-text gray-lighten-3-text no-border' }),
                _react.default.createElement('div', { className: 'column f1 fake-text gray-lighten-3-text no-border' }),
                _react.default.createElement('div', { className: 'column f1 fake-text gray-lighten-3-text no-border' }),
                _react.default.createElement('div', { className: 'column f1 fake-text gray-lighten-3-text no-border' }),
                _react.default.createElement('div', { className: 'column f2 fake-text gray-lighten-3-text no-border' }),
                _react.default.createElement('div', { className: 'column f2 fake-text gray-lighten-3-text no-border' })
            )
        );
    };

    var TableRow = function (_PureComponent) {
        _inherits(TableRow, _PureComponent);

        function TableRow() {
            _classCallCheck(this, TableRow);

            return _possibleConstructorReturn(this, (TableRow.__proto__ || Object.getPrototypeOf(TableRow)).apply(this, arguments));
        }

        _createClass(TableRow, [{
            key: 'render',
            value: function render() {
                var _props = this.props,
                    data = _props.data,
                    index = _props.index,
                    style = _props.style;
                var RowComponent = data.RowComponent,
                    rowsProps = data.rowsProps,
                    allProps = data.allProps;

                var rowProps = (rowsProps || {})[index] || {};
                return _react.default.createElement(RowComponent, _extends({}, rowProps, allProps, { style: style }));
            }
        }]);

        return TableRow;
    }(_react.PureComponent);

    var TableContainer = function TableContainer(props) {
        var itemData = props.itemData,
            itemCount = props.itemCount,
            itemSize = props.itemSize,
            outerRef = props.outerRef,
            innerRef = props.innerRef;

        var _useState = (0, _react.useState)(0),
            _useState2 = _slicedToArray(_useState, 2),
            height = _useState2[0],
            setHeight = _useState2[1];

        var _useState3 = (0, _react.useState)(),
            _useState4 = _slicedToArray(_useState3, 2),
            docElement = _useState4[0],
            setDocElement = _useState4[1];

        var resizeHandler = function resizeHandler() {
            setHeight(docElement.clientHeight);
        };

        (0, _react.useEffect)(function () {
            window.addEventListener('resize', resizeHandler);
            return function (_) {
                return window.removeEventListener('resize', resizeHandler);
            };
        });

        return _react.default.createElement(
            'div',
            { className: 'body content-container', ref: function ref(e) {
                    if (e) {
                        setHeight(e.clientHeight);
                        setDocElement(e);
                    }
                } },
            _react.default.createElement(
                FixedSizeList,
                { style: {
                        position: 'absolute',
                        width: '100%'
                    },
                    height: height,
                    itemData: itemData,
                    itemCount: itemCount,
                    itemSize: itemSize,
                    outerRef: outerRef,
                    innerRef: innerRef
                },
                TableRow
            )
        );
    };

    var SortIndicator = function SortIndicator(props) {
        var sortKey = props.sortKey,
            currentKey = props.currentKey,
            reverseSort = props.reverseSort;

        if (sortKey === currentKey) {
            var classes = ['sort-indicator', !reverseSort ? 'icon-arrow-down' : 'icon-arrow-up'];
            return _react.default.createElement('span', { className: classes.join(' ') });
        }
        return null;
    };

    var NameHeaderCell = function NameHeaderCell(props) {
        var focusItemId = props.focusItemId,
            currentKey = props.currentKey,
            reverseSort = props.reverseSort,
            showstores = props.showstores,
            allowToggle = props.allowToggle,
            updateSortFunc = props.updateSortFunc,
            selectItem = props.selectItem;


        var sortKey = 'name';

        var classList = ['column', 'f3', 'text', 'sortable', currentKey == sortKey ? 'weight-semi' : ''];

        var onColumnClick = function onColumnClick(_) {
            return updateSortFunc(sortKey);
        };

        var onToggleShowstoreClick = function onToggleShowstoreClick(e) {
            setShowstores(!showstores);
            e.stopPropagation();
        };

        var onUnfocusClick = function onUnfocusClick(e) {
            selectItem(null);
            e.stopPropagation();
        };

        return _react.default.createElement(
            'div',
            { className: classList.join(' '), onClick: onColumnClick },
            focusItemId == null ? 'Item' : _react.default.createElement(
                _react.Fragment,
                null,
                _react.default.createElement(
                    'div',
                    { className: 'flex-table align-flex-start' },
                    _react.default.createElement(
                        'span',
                        null,
                        showstores ? 'Store' : 'Hierarchy'
                    ),
                    allowToggle ? _react.default.createElement(
                        'a',
                        { className: 'toggle-showstores', onClick: onToggleShowstoreClick },
                        showstores ? 'View by hierarchy' : 'View by store'
                    ) : null
                ),
                _react.default.createElement('span', { className: 'breathe-l-10 breathe-r-10 icon-minus-circle pointer unfocus-button', onClick: onUnfocusClick })
            ),
            _react.default.createElement(SortIndicator, { sortKey: sortKey, currentKey: currentKey, reverseSort: reverseSort }),
            props.children
        );
    };

    var getSortFunc = function getSortFunc(focusItemId, focusItem, sortKey) {
        var isStore = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

        var tieredSort = function tieredSort(initializer) {
            for (var _len = arguments.length, funcs = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
                funcs[_key - 1] = arguments[_key];
            }

            return function (a, b) {
                var aValues = void 0,
                    bValues = void 0;
                if (initializer) {
                    aValues = initializer(a);
                    bValues = initializer(b);
                }
                var compare = void 0;
                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    for (var _iterator = funcs[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var f = _step.value;

                        if (!compare) {
                            compare = f(a, b, {
                                aValues: aValues,
                                bValues: bValues
                            });
                        } else {
                            break;
                        }
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return) {
                            _iterator.return();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }

                return compare;
            };
        };

        var comparisonSort = function comparisonSort(f) {
            return function (a, b, extra) {
                var A = a,
                    B = b;
                if (f) {
                    A = f(A, extra ? extra.aValues : null);
                    B = f(B, extra ? extra.bValues : null);
                }
                if (A > B) return 1;
                if (A < B) return -1;
                return 0;
            };
        };

        var funcSort = function funcSort(f) {
            return function (a, b, extra) {
                var A = f(a, extra ? extra.aValues : null);
                var B = f(b, extra ? extra.bValues : null);
                return A - B;
            };
        };

        var reverse = function reverse(f, enabled) {
            return function (a, b, extra) {
                var sign = void 0;
                if (typeof enabled === 'function') {
                    sign = enabled(a, b, extra) ? -1 : 1;
                } else {
                    sign = enabled == null || enabled ? -1 : 1;
                }
                return sign * f(a, b, extra);
            };
        };

        if (isStore) {
            // viewing tasks for store
            var startDate = function startDate(x) {
                return (0, _npmMoment.default)(x.storeitem.start_date_exception ? x.storeitem.start_date_exception : x.item.start_date);
            };
            var dueDate = function dueDate(x) {
                return (0, _npmMoment.default)(x.storeitem.due_date_exception ? x.storeitem.due_date_exception : x.item.due_date);
            };

            switch (sortKey) {
                case 'name':
                    {
                        return tieredSort(null, funcSort(function (x) {
                            return x.item.sort_order;
                        }), comparisonSort(function (x) {
                            return x.item.name;
                        }));
                    }
                case 'start':
                    {
                        return tieredSort(null, funcSort(startDate), funcSort(dueDate), funcSort(function (x) {
                            return x.item.sort_order;
                        }), comparisonSort(function (x) {
                            return x.item.name;
                        }));
                    }
                case 'due':
                    {
                        return tieredSort(null, funcSort(dueDate), funcSort(startDate), funcSort(function (x) {
                            return x.item.sort_order;
                        }), comparisonSort(function (x) {
                            return x.item.name;
                        }));
                    }
                case 'done':
                    {
                        return tieredSort(null, funcSort(function (x) {
                            return x.storeitem.completed_at ? (0, _npmMoment.default)(x.storeitem.completed_at) : null;
                        }), funcSort(function (x) {
                            return x.item.sort_order;
                        }), comparisonSort(function (x) {
                            return x.item.name;
                        }));
                    }
                case 'hours':
                    {
                        return tieredSort(null, funcSort(function (x) {
                            return x.storeitem.hours;
                        }), funcSort(function (x) {
                            return x.item.sort_order;
                        }), comparisonSort(function (x) {
                            return x.item.name;
                        }));
                    }
                case 'status':
                    {
                        var statusValues = {
                            COMPLETED: 4,
                            LATE: 3,
                            INCOMPLETE: 2,
                            OVERDUE: 1,
                            UPCOMING: 0
                        };
                        return tieredSort(null, funcSort(function (x) {
                            return statusValues[x.storeitem.status];
                        }), funcSort(function (x) {
                            return x.item.sort_order;
                        }), comparisonSort(function (x) {
                            return x.item.name;
                        }));
                    }
            }
        } else if (focusItemId == null) {
            // viewing overview of tasks
            var _startDate = function _startDate(x) {
                return (0, _npmMoment.default)(x.start_date);
            };
            var _dueDate = function _dueDate(x) {
                return (0, _npmMoment.default)(x.due_date);
            };

            switch (sortKey) {
                case 'name':
                    {
                        return tieredSort(null, funcSort(function (x) {
                            return x.sort_order;
                        }), comparisonSort(function (x) {
                            return x.name;
                        }));
                    }
                case 'start':
                    {
                        return tieredSort(null, funcSort(_startDate), funcSort(_dueDate), funcSort(function (x) {
                            return x.sort_order;
                        }), comparisonSort(function (x) {
                            return x.name;
                        }));
                    }
                case 'due':
                    {
                        return tieredSort(null, funcSort(_dueDate), funcSort(_startDate), funcSort(function (x) {
                            return x.sort_order;
                        }), comparisonSort(function (x) {
                            return x.name;
                        }));
                    }
                case 'done':
                    {
                        return tieredSort(function (x) {
                            return {
                                done: ['completed', 'late'].reduce(function (agg, k) {
                                    return agg + (x.status_count[k] || 0);
                                }, 0),
                                total: Object.keys(x.status_count).reduce(function (agg, k) {
                                    return agg + x.status_count[k];
                                }, 0)
                            };
                        }, funcSort(function (x, values) {
                            return values.done / values.total;
                        }), reverse(funcSort(function (x, values) {
                            return values.total;
                        }), function (a, b, extra) {
                            return extra.aValues.done / extra.aValues.total < 0.5;
                        }), funcSort(function (x) {
                            return x.sort_order;
                        }), comparisonSort(function (x) {
                            return x.name;
                        }));
                    }
                case 'hours':
                    {
                        return tieredSort(null, funcSort(function (x) {
                            return x.total_hours;
                        }), funcSort(function (x) {
                            return x.sort_order;
                        }), comparisonSort(function (x) {
                            return x.name;
                        }));
                    }
                case 'status':
                    {
                        var statusCount = function statusCount(x, k) {
                            return x.status_count[k] || 0;
                        };
                        return tieredSort(function (x) {
                            return {
                                total: Object.keys(x.status_count).reduce(function (agg, k) {
                                    return agg + x.status_count[k];
                                }, 0)
                            };
                        }, reverse(funcSort(function (x, values) {
                            return statusCount(x, 'overdue') / values.total;
                        })), reverse(funcSort(function (x) {
                            return statusCount(x, 'overdue');
                        })), reverse(funcSort(function (x, values) {
                            return statusCount(x, 'incomplete') / values.total;
                        })), reverse(funcSort(function (x) {
                            return statusCount(x, 'incomplete');
                        })), reverse(funcSort(function (x, values) {
                            return statusCount(x, 'late') / values.total;
                        })), reverse(funcSort(function (x) {
                            return statusCount(x, 'late');
                        })), funcSort(function (x, values) {
                            return statusCount(x, 'completed') / values.total;
                        }), funcSort(function (x) {
                            return statusCount(x, 'completed');
                        }), comparisonSort(function (x) {
                            return x.values;
                        }), funcSort(function (x) {
                            return x.sort_order;
                        }), comparisonSort(function (x) {
                            return x.name;
                        }));
                    }
            }
        } else {
            // viewing task detail
            var _startDate2 = function _startDate2(x) {
                if (x.hierarchy.type === 'store' && x.item.start_date_exception != null) {
                    return _npmMoment.default.utc(x.item.start_date_exception);
                } else if (x.hierarchy.type !== 'store' && x.item.minStartDate != null) {
                    return _npmMoment.default.utc(x.item.minStartDate);
                }
                return _npmMoment.default.utc(focusItem.start_date);
            };
            var _dueDate2 = function _dueDate2(x) {
                if (x.hierarchy.type === 'store' && x.item.due_date_exception != null) {
                    return _npmMoment.default.utc(x.item.due_date_exception);
                } else if (x.hierarchy.type !== 'store' && x.item.maxDueDate != null) {
                    return _npmMoment.default.utc(x.item.maxDueDate);
                }
                return _npmMoment.default.utc(focusItem.due_date);
            };

            switch (sortKey) {
                case 'name':
                    {
                        return tieredSort(null, funcSort(function (x) {
                            return x.hierarchy.type !== 'store';
                        }), comparisonSort(function (x) {
                            return x.hierarchy.code;
                        }));
                    }
                case 'start':
                    {
                        return tieredSort(null, funcSort(_startDate2), funcSort(_dueDate2), comparisonSort(function (x) {
                            return x.hierarchy.code;
                        }));
                    }
                case 'due':
                    {
                        return tieredSort(null, funcSort(_dueDate2), funcSort(_startDate2), comparisonSort(function (x) {
                            return x.hierarchy.code;
                        }));
                    }
                case 'done':
                    {
                        return tieredSort(null, funcSort(function (x) {
                            return x.hierarchy.type === 'store';
                        }), function (a, b) {
                            var mainCompare = void 0;
                            if (a.hierarchy.type === 'store') {
                                mainCompare = funcSort(function (x) {
                                    return x.item.completed_at ? _npmMoment.default.utc(x.item.completed_at) : null;
                                });
                            } else {
                                mainCompare = tieredSort(function (x) {
                                    return {
                                        done: ['COMPLETED', 'LATE'].reduce(function (agg, k) {
                                            return agg + (x.item.statusCounts[k] || 0);
                                        }, 0),
                                        total: Object.keys(x.item.statusCounts).reduce(function (agg, k) {
                                            return agg + x.item.statusCounts[k];
                                        }, 0)
                                    };
                                }, funcSort(function (x, values) {
                                    return values.done / values.total;
                                }), reverse(funcSort(function (x, values) {
                                    return values.total;
                                }), function (a, b, extra) {
                                    return extra.aValues.done / extra.aValues.total < 0.5;
                                }));
                            }
                            return mainCompare(a, b);
                        }, comparisonSort(function (x) {
                            return x.hierarchy.code;
                        }));
                    }
                case 'hours':
                    {
                        return funcSort(function (x) {
                            return x.item.hours;
                        });
                    }
                case 'status':
                    {
                        return tieredSort(null, funcSort(function (x) {
                            return x.hierarchy.type === 'store';
                        }), function (a, b) {
                            var mainCompare = void 0;
                            if (a.hierarchy.type === 'store') {
                                var _statusValues = {
                                    COMPLETED: 4,
                                    LATE: 3,
                                    INCOMPLETE: 2,
                                    OVERDUE: 1
                                };
                                mainCompare = funcSort(function (x) {
                                    return _statusValues[x.item.status];
                                });
                            } else {
                                var _statusCount = function _statusCount(x, k) {
                                    return x.item.statusCounts[k] || 0;
                                };
                                mainCompare = tieredSort(function (x) {
                                    return {
                                        total: Object.keys(x.item.statusCounts).reduce(function (agg, k) {
                                            return agg + x.item.statusCounts[k];
                                        }, 0)
                                    };
                                }, reverse(funcSort(function (x, values) {
                                    return _statusCount(x, 'OVERDUE') / values.total;
                                })), reverse(funcSort(function (x, values) {
                                    return _statusCount(x, 'INCOMPLETE') / values.total;
                                })), reverse(funcSort(function (x, values) {
                                    return _statusCount(x, 'LATE') / values.total;
                                })), funcSort(function (x, values) {
                                    return _statusCount(x, 'COMPLETED') / values.total;
                                }));
                            }
                            return mainCompare(a, b);
                        }, comparisonSort(function (x) {
                            return x.hierarchy.code;
                        }));
                    }
            }
        }
        return function (a, b) {
            return 0;
        };
    };

    var StatusTooltip = function StatusTooltip(props) {
        var items = props.items;


        var content = function content(data) {
            var statusCounts = JSON.parse(data) || {};
            var values = Object.keys(statusCounts).map(function (k) {
                return {
                    status: _constants.ChecklistItemStatus[k],
                    value: statusCounts[k]
                };
            }).filter(function (x) {
                return x.value > 0;
            }).sort(function (a, b) {
                return a.status.value - b.status.value;
            });

            var total = values.map(function (v) {
                return v.value;
            }).reduce(function (agg, x) {
                return agg + x;
            }, 0);
            var useTotal = values.length > 1;

            return _react.default.createElement(
                'div',
                { className: 'checklist-status-tooltip flex-table' },
                _react.default.createElement(
                    'div',
                    { className: 'f-col f5' },
                    values.map(function (v, i) {
                        var _getStatusAttrs = (0, _checklistItemRow.getStatusAttrs)(v.status),
                            style = _getStatusAttrs.style,
                            text = _getStatusAttrs.text;

                        return _react.default.createElement(
                            'div',
                            { key: i, className: 'row' },
                            _react.default.createElement(
                                'div',
                                { className: 'column text weight-heavy padding-l0', style: {
                                        color: style.background
                                    } },
                                text
                            )
                        );
                    }),
                    useTotal ? _react.default.createElement(
                        'div',
                        { key: 'total', className: 'row' },
                        _react.default.createElement(
                            'div',
                            { className: 'column text weight-heavy padding-l0' },
                            'Total'
                        )
                    ) : null
                ),
                _react.default.createElement(
                    'div',
                    { className: 'f-col f1' },
                    values.map(function (v, i) {
                        return _react.default.createElement(
                            'div',
                            { key: i, className: 'row' },
                            _react.default.createElement(
                                'div',
                                { className: 'column text-right' },
                                v.value
                            )
                        );
                    }),
                    useTotal ? _react.default.createElement(
                        'div',
                        { key: 'total', className: 'row' },
                        _react.default.createElement(
                            'div',
                            { className: 'column text text-right' },
                            total
                        )
                    ) : null
                ),
                _react.default.createElement(
                    'div',
                    { className: 'f-col f1' },
                    values.map(function (v, i) {
                        return _react.default.createElement(
                            'div',
                            { key: i, className: 'row' },
                            _react.default.createElement(
                                'div',
                                { className: 'column text-right padding-r0' },
                                _formatHelpers.default.formatPercent(v.value, total)
                            )
                        );
                    })
                )
            );
        };

        return _react.default.createElement(_npmReactTooltip.default, { id: 'statusTooltip', effect: 'solid', getContent: content });
    };

    var PriorOverdueItemsToggle = function PriorOverdueItemsToggle(props) {
        var showingItems = props.showingItems,
            count = props.count,
            _onClick = props.onClick;


        if (!count) return null;

        return _react.default.createElement(
            'div',
            { className: 'prior-overdue-toggle', onClick: function onClick(e) {
                    _onClick();
                    e.stopPropagation();
                } },
            showingItems ? 'Hide' : 'Show',
            ' ',
            count,
            ' prior overdue ',
            count > 1 ? 'items' : 'item'
        );
    };

    var ChecklistBody = function ChecklistBody(props) {
        var clientCode = props.clientCode,
            weekEndDate = props.weekEndDate,
            node = props.node,
            nodeHierarchy = props.nodeHierarchy,
            hierarchy = props.hierarchy,
            focusItemId = props.focusItemId,
            focusItem = props.focusItem,
            checklistItems = props.checklistItems,
            showstores = props.showstores,
            storeitems = props.storeitems,
            shownNodeItems = props.shownNodeItems,
            selectBusinessUnit = props.selectBusinessUnit,
            selectItem = props.selectItem,
            modalService = props.modalService;


        var weekStart = weekEndDate.clone().add(-6, 'days');

        var scrollOuterRef = (0, _react.useRef)();
        var scrollInnerRef = (0, _react.useRef)();

        var _useState5 = (0, _react.useState)('due'),
            _useState6 = _slicedToArray(_useState5, 2),
            sortKey = _useState6[0],
            setSortKey = _useState6[1];

        var _useState7 = (0, _react.useState)(false),
            _useState8 = _slicedToArray(_useState7, 2),
            reverseSort = _useState8[0],
            setReverseSort = _useState8[1];

        var _useState9 = (0, _react.useState)(0),
            _useState10 = _slicedToArray(_useState9, 2),
            scrollbarWidth = _useState10[0],
            setScrollbarWidth = _useState10[1];

        var _useState11 = (0, _react.useState)(null),
            _useState12 = _slicedToArray(_useState11, 2),
            itemName = _useState12[0],
            setItemName = _useState12[1];

        var _useState13 = (0, _react.useState)(false),
            _useState14 = _slicedToArray(_useState13, 2),
            showPriorOverdue = _useState14[0],
            setShowPriorOverdue = _useState14[1];

        var alignScrollbar = function alignScrollbar() {
            if (scrollInnerRef.current && scrollOuterRef.current) {
                var scrollWidth = scrollOuterRef.current.offsetWidth - scrollInnerRef.current.offsetWidth;
                if (scrollbarWidth != scrollWidth) {
                    setScrollbarWidth(scrollWidth);
                }
            }
        };

        var hasChildNodes = hierarchy ? hierarchy.has_child_nodes : false;

        (0, _react.useEffect)(function () {
            if (focusItem != null) {
                setItemName(focusItem.name);
            }
            alignScrollbar();
            window.addEventListener('resize', alignScrollbar);
            return function (_) {
                return window.removeEventListener('resize', alignScrollbar);
            };
        });

        var updateSort = function updateSort(key) {
            if (key === sortKey) {
                setReverseSort(!reverseSort);
            } else {
                setSortKey(key);
                setReverseSort(['hours'].includes(key));
            }
        };

        var sortFunc = getSortFunc(focusItemId, focusItem, sortKey, node.nodeType === 'store');

        var showStoreitemModal = function showStoreitemModal(item, storeitem) {
            _store.store.dispatch({
                type: _types.TODO_SET_MODAL_DATA,
                payload: {
                    modalItem: item,
                    modalStoreitem: storeitem,
                    modalOffset: 0
                }
            });
            modalService.open('checklist-storeitem-modal');
        };

        var isPriorOverdue = function isPriorOverdue(item) {
            var fromPreviousWeek = void 0;
            var isOverdue = void 0;
            if (node.nodeType === 'store') {
                fromPreviousWeek = !(0, _npmMoment.default)(item.due_date).isSameOrAfter(weekStart);
                isOverdue = storeitems[item.id].status === 'OVERDUE';
            } else {
                fromPreviousWeek = !(0, _npmMoment.default)(item.max_due_date).isSameOrAfter(weekStart);
                isOverdue = item.status_count.overdue > 0;
            }
            return fromPreviousWeek && isOverdue;
        };

        var priorOverdueChecklistItems = checklistItems ? Object.keys(checklistItems).filter(function (itemId) {
            return isPriorOverdue(checklistItems[itemId]);
        }).reduce(function (o, itemId) {
            o[itemId] = checklistItems[itemId];
            return o;
        }, {}) : null;

        var numPriorOverdue = priorOverdueChecklistItems ? Object.keys(priorOverdueChecklistItems).length : null;

        var shownChecklistItems = checklistItems ? Object.keys(checklistItems).filter(function (itemId) {
            if (!showPriorOverdue && priorOverdueChecklistItems[itemId]) return false;
            return true;
        }).reduce(function (o, itemId) {
            o[itemId] = checklistItems[itemId];
            return o;
        }, {}) : null;

        var itemData = void 0;
        var itemCount = 0;
        var tableContent = void 0;

        if (node.nodeType !== 'store') {
            if (focusItem === undefined) {
                if (itemName === null) {
                    selectItem(undefined);
                }
                if (nodeHierarchy != null) {
                    // this node does not have this task
                    tableContent = _react.default.createElement(
                        'div',
                        { className: 'large-message' },
                        itemName + ' does not apply to ' + nodeHierarchy.name + ' for the selected week.'
                    );
                }
            } else if (focusItem !== null) {
                // viewing detail of a task
                tableContent = _react.default.createElement(
                    'div',
                    { className: 'head total', style: {
                            paddingRight: scrollbarWidth + 'px'
                        } },
                    _react.default.createElement(_checklistItemRow.ChecklistItemRow, { item: focusItem, totalStores: storeitems ? Object.keys(storeitems).length : null, weekStart: weekStart })
                );
                if (shownNodeItems != null) {
                    // node items have loaded
                    var sorted = shownNodeItems.sort(function (a, b) {
                        return reverseSort ? -sortFunc(a, b) : sortFunc(a, b);
                    }).map(function (obj) {
                        var navigable = void 0;
                        var clickable = void 0;
                        if (obj.hierarchy.type === 'store') {
                            var startDate = _npmMoment.default.utc(obj.item.start_date_exception ? obj.item.start_date_exception : focusItem.start_date);
                            var dueDate = _npmMoment.default.utc(obj.item.due_date_exception ? obj.item.due_date_exception : focusItem.due_date);
                            navigable = dueDate >= weekStart && startDate <= weekEndDate || obj.item.completed_at == null;
                            clickable = true;
                        } else {
                            var _startDate3 = _npmMoment.default.utc(obj.item.minStartDate ? obj.item.minStartDate : focusItem.start_date);
                            var _dueDate3 = _npmMoment.default.utc(obj.item.maxDueDate ? obj.item.maxDueDate : focusItem.due_date);
                            navigable = _dueDate3 >= weekStart && _startDate3 <= weekEndDate || obj.item.statusCounts.OVERDUE > 0;
                        }
                        return _extends({}, obj, {
                            clickable: clickable,
                            navigable: navigable
                        });
                    });
                    itemData = {
                        RowComponent: _checklistItemRow.ChecklistHierarchyItemRow,
                        rowsProps: sorted,
                        allProps: {
                            weekStart: weekStart,
                            clickFunc: function clickFunc(hierarchy, nodeItem, key) {
                                _npmReactTooltip.default.hide();
                                if (hierarchy.type === 'store' && key === 'row') {
                                    showStoreitemModal(focusItem, nodeItem);
                                } else {
                                    // setFocusItemId(null)
                                    selectBusinessUnit(hierarchy.id, hierarchy.type);
                                }
                            },
                            // clickable: true,
                            focusItem: focusItem
                        }
                    };
                    itemCount = sorted.length;
                } else {
                    // loading node items
                    itemData = {
                        RowComponent: FakeRow
                    };
                    itemCount = 10;
                }
            } else if (checklistItems != null) {
                // viewing all tasks
                if (Object.keys(shownChecklistItems).length == 0) {
                    // there are no tasks
                    if (nodeHierarchy != null) tableContent = _react.default.createElement(
                        'div',
                        { className: 'large-message' },
                        'There are no checklist items for ' + nodeHierarchy.name + ' for ' + weekStart.format('M/D') + ' - ' + weekEndDate.format('M/D') + '.'
                    );
                } else {
                    // show the tasks
                    var _sorted = Object.keys(shownChecklistItems).map(function (k) {
                        return shownChecklistItems[k];
                    }).sort(function (a, b) {
                        return reverseSort ? -sortFunc(a, b) : sortFunc(a, b);
                    });
                    itemData = {
                        RowComponent: _checklistItemRow.ChecklistItemRow,
                        rowsProps: _sorted.map(function (item) {
                            return {
                                item: item
                            };
                        }),
                        allProps: {
                            weekStart: weekStart,
                            clickFunc: function clickFunc(focusItemId) {
                                _npmReactTooltip.default.hide();
                                selectItem(focusItemId);
                                // setFocusItemId(focusItemId)
                            },
                            clickable: true
                        }
                    };
                    itemCount = _sorted.length;
                }
            } else {
                // loading checklist items
                itemData = {
                    RowComponent: FakeRow
                };
                itemCount = 10;
            }
        } else {
            // viewing one store
            if (checklistItems != null) {
                if (Object.keys(shownChecklistItems).length == 0) {
                    // there are no tasks
                    if (node != null) tableContent = _react.default.createElement(
                        'div',
                        { className: 'large-message' },
                        'There are no tasks for Store ' + node.code + ' during this week.'
                    );
                } else {
                    var _sorted2 = Object.keys(shownChecklistItems).map(function (itemId) {
                        return {
                            item: shownChecklistItems[itemId],
                            storeitem: storeitems[itemId]
                        };
                    }).sort(function (a, b) {
                        return reverseSort ? -sortFunc(a, b) : sortFunc(a, b);
                    });
                    itemData = {
                        RowComponent: _checklistItemRow.ChecklistStoreItemRow,
                        rowsProps: _sorted2.map(function (item) {
                            return _extends({}, item);
                        }),
                        allProps: {
                            weekStart: weekStart,
                            clickFunc: function clickFunc(item, storeitem) {
                                _npmReactTooltip.default.hide();
                                showStoreitemModal(item, storeitem);
                            },
                            clickable: true
                        }
                    };
                    itemCount = _sorted2.length;
                }
            } else {
                // loading store items
                itemData = {
                    RowComponent: FakeRow
                };
                itemCount = 10;
            }
        }

        var BasicColumn = function BasicColumn(props) {
            var text = props.text,
                flex = props.flex,
                columnKey = props.columnKey;


            var classList = ['column', 'f' + flex, columnKey ? 'sortable' : '', sortKey == columnKey ? 'weight-semi' : ''];

            return _react.default.createElement(
                'div',
                { className: classList.join(' '), onClick: columnKey ? function (_) {
                        return updateSort(columnKey);
                    } : null },
                text,
                columnKey ? _react.default.createElement(SortIndicator, { sortKey: columnKey, currentKey: sortKey, reverseSort: reverseSort }) : null
            );
        };

        return _react.default.createElement(
            _react.Fragment,
            null,
            _react.default.createElement(
                'div',
                { className: 'checklist-table flex-table' },
                _react.default.createElement(
                    'div',
                    { className: 'head', style: {
                            paddingRight: scrollbarWidth + 'px'
                        } },
                    _react.default.createElement(
                        'div',
                        { className: 'row' },
                        _react.default.createElement(
                            'div',
                            { className: 'wrapper f1' },
                            _react.default.createElement(
                                NameHeaderCell,
                                { focusItemId: focusItemId, currentKey: sortKey, reverseSort: reverseSort, showstores: showstores || !hasChildNodes, updateSortFunc: updateSort, allowToggle: hasChildNodes, selectItem: selectItem },
                                focusItemId == null ? _react.default.createElement(PriorOverdueItemsToggle, { showingItems: showPriorOverdue, count: numPriorOverdue, onClick: function onClick() {
                                        return setShowPriorOverdue(!showPriorOverdue);
                                    } }) : null
                            ),
                            _react.default.createElement(BasicColumn, { text: 'Start', flex: 1, columnKey: 'start' }),
                            _react.default.createElement(BasicColumn, { text: 'Due', flex: 1, columnKey: 'due' }),
                            _react.default.createElement(BasicColumn, { text: 'Done', flex: 1, columnKey: 'done' }),
                            _react.default.createElement(BasicColumn, { text: 'Hours', flex: 1, columnKey: 'hours' }),
                            _react.default.createElement(BasicColumn, { text: 'Status', flex: 2, columnKey: 'status' }),
                            _react.default.createElement(BasicColumn, { text: 'Flags', flex: 2, columnKey: 'flags' })
                        )
                    )
                ),
                tableContent,
                itemCount > 0 ? _react.default.createElement(TableContainer, {
                    itemData: itemData,
                    itemCount: itemCount,
                    itemSize: 45,
                    outerRef: scrollOuterRef,
                    innerRef: scrollInnerRef
                }) : null
            ),
            _react.default.createElement(_npmReactTooltip.default, { id: 'itemDescriptionTooltip', effect: 'solid', getContent: function getContent(data) {
                    if (!checklistItems) return null;
                    var item = checklistItems[data];
                    if (!item) return null;
                    return _react.default.createElement(
                        'div',
                        { className: 'content' },
                        _react.default.createElement(
                            'div',
                            null,
                            item.description
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'sortOrder' },
                            'Sort Order: ',
                            item.sort_order
                        )
                    );
                } }),
            _react.default.createElement(_npmReactTooltip.default, { id: 'basicTooltip', effect: 'solid' }),
            _react.default.createElement(StatusTooltip, null)
        );
    };

    var Wrapper = function (_Component) {
        _inherits(Wrapper, _Component);

        function Wrapper() {
            _classCallCheck(this, Wrapper);

            return _possibleConstructorReturn(this, (Wrapper.__proto__ || Object.getPrototypeOf(Wrapper)).apply(this, arguments));
        }

        _createClass(Wrapper, [{
            key: 'shouldComponentUpdate',
            value: function shouldComponentUpdate(nextProps) {
                var _this3 = this;

                return Object.keys(nextProps).reduce(function (agg, k) {
                    var changed = nextProps[k] !== _this3.props[k];
                    if (k != 'children' && changed) {
                        return true;
                    }
                    return agg;
                }, false);
            }
        }, {
            key: 'render',
            value: function render() {
                return _react.default.createElement(ChecklistBody, this.props);
            }
        }]);

        return Wrapper;
    }(_react.Component);

    exports.default = (0, _emberReactComponents.default)(Wrapper);
});