define('matrix-frontend/helpers/range', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.range = range;
    function range(params /*, hash*/) {
        var range = Array(Math.ceil(params[0])).fill().map(function (_, i) {
            return i += 1;
        });
        return range;
    }

    exports.default = Ember.Helper.helper(range);
});