define('matrix-frontend/pods/components/schedule-summary/component', ['exports', 'npm:underscore', 'npm:lodash', 'ember-redux', 'matrix-frontend/utils/interactions', 'matrix-frontend/utils/backoff', 'matrix-frontend/constants', 'npm:moment', 'matrix-frontend/utils/state'], function (exports, _npmUnderscore, _npmLodash, _emberRedux, _interactions, _backoff, _constants, _npmMoment, _state) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var stateToComputed = function stateToComputed(state) {
        return {
            node: state.cube.node,
            clientCode: state.cube.clientCode,
            clients: state.client.clients,
            weekEndDate: state.cube.weekEndDate,
            stores: state.node.stores,
            hierarchies: state.node.hierarchies,
            gatheredHierarchies: state.node.gathered.hierarchies,
            reports: state.node.gathered.reports,
            currentUser: state.user.currentUser
        };
    };

    var dispatchToActions = function dispatchToActions() {
        return {};
    };

    // - TEMPORARY
    var doHardCodeReverse = true;

    var aggregates = [['unassigned', 'unassigned']];
    var reverseSortHeaders = ['salesAtRisk', 'publishPercentage', 'budget.overunder', 'unassigned', 'flags.violations,flags.warnings,flags.alerts'];
    var headers = [{
        labels: [{
            display: 'Name',
            key: 'name',
            text: true,
            sortable: true
        }, {
            display: 'Status',
            key: 'publishPercentage',
            text: true,
            sortable: true
        }],
        classes: 'f3'
    }, {
        labels: [{
            display: 'Sales<br />at Risk'.htmlSafe(),
            key: 'salesAtRisk',
            number: true,
            classes: 'f1',
            sortable: true
        }, {
            display: 'Hours Over<br />(Under)'.htmlSafe(),
            altDisplay: 'Hours Under<br />(Over)'.htmlSafe(),
            // display: 'Hours +/-<br /> Budget',
            key: 'budget.overunder',
            number: true,
            classes: 'f1',
            sortable: true
        }, {
            display: 'Unassigned<br />Shifts'.htmlSafe(),
            key: 'unassigned',
            classes: 'f1',
            sortable: true
        }, {
            display: 'Flags',
            key: 'flags.violations,flags.warnings,flags.alerts',
            classes: 'f3',
            sortable: true
        }],
        classes: 'f4'
    }];

    var statusKey = function statusKey(s) {
        switch (s.status) {
            case 'hidden':
                return 'hidden';
            case 'executive':
                return 'executive';
            case 'readonly':
                return 'readonly';
            case 'preliminary':
                return 'preliminary';
            case 'active':
                return s.published === 'Y' ? 'activePublished' : s.saved_by ? 'activeInProgress' : 'activeNotStarted';
            default:
                return null;
        }
    };

    var Comp = Ember.Component.extend({
        ScheduleStatus: _constants.ScheduleStatus,

        store: Ember.inject.service(),
        stateService: Ember.inject.service(),
        ajaxService: Ember.inject.service(),
        modalService: Ember.inject.service(),

        sortBy: 'name',
        lastSortBy: null,
        reverseSort: false,
        budgetDataStore: {}, // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
        totalOverUnderRaw: {}, // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
        salesAtRiskDataStore: {}, // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
        flagsDataStore: {}, // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
        // _totalSAR: 0,
        budgetsIn: 0,
        sarIn: 0,
        flagsIn: 0,
        currentViewKey: '',
        statusModalInfo: null,

        currentNode: Ember.computed('node', 'hierarchies', function () {
            var node = this.get('node');
            var hierarchies = this.get('hierarchies');
            if (node && hierarchies) {
                return hierarchies[node.clientCode + '.' + node.tag];
            } else {
                return null;
            }
        }),

        currentClient: Ember.computed('clients', 'node', function () {
            var node = this.get('node');
            var clients = this.get('clients');
            return node && clients ? clients[node.clientCode] : null;
        }),

        useFlippedPWHours: Ember.computed('currentClient.options', function () {
            return (0, _state.getClientOption)('use_flipped_pw_hours', 'bool');
        }),

        isLaneBryant: Ember.computed('client', function () {
            var stateService = this.get('stateService');
            return (parseInt(stateService.client.id) === 12 || parseInt(stateService.client.id) === 17) && doHardCodeReverse;
        }),

        getChild: function getChild(children, id) {
            return _npmUnderscore.default.find(children, function (child) {
                return child.hierarchyid === id;
            });
        },

        getCalculatedProperty: function getCalculatedProperty(property, id) {
            var self = this;
            var children = self.get('children');
            var child = self.getChild(children, id);
            var basements = self.get('basements');

            var value = 0;

            _npmUnderscore.default.each(child.list, function (id) {
                var basement = basements[id];
                if (basement) {
                    value += basement[property] || 0;
                }
            });

            return value;
        },

        scheduleSummaryStores: Ember.computed('data.scheduleSummary', 'currentUser.roles', 'currentClient', function () {
            var stores = this.get('data.scheduleSummary.stores');
            var currentUser = this.currentUser;
            var currentClient = this.currentClient;
            if (!stores) return null;
            return stores.filter(function (s) {
                return (0, _state.hasScope)('schedule.store:' + s.status + '.view');
            });
        }),

        reportsObserver: Ember.observer('reports', 'scheduleSummaryStores', 'gatheredHierarchies', 'currentClient.options', function () {
            var MIN_PERIOD = 200;
            if (this.scheduledRefreshId != null) {
                Ember.run.cancel(this.scheduledRefreshId);
            }
            var self = this;
            var scheduledRefreshId = Ember.run.later(function () {
                self.set('scheduledRefreshId', null);
                self.computeBudgetData();
            }, MIN_PERIOD);
            this.set('scheduledRefreshId', scheduledRefreshId);
        }),

        computeBudgetData: function computeBudgetData() {
            var _this = this;

            var self = this;
            var stores = this.scheduleSummaryStores;
            if (!stores) return;

            var getStoreReportValue = function getStoreReportValue(fullTag, reportType, weekEnd, dataKey) {
                var thisReportData = void 0;
                var thisFocusData = void 0;
                var thisWeekData = void 0;
                try {
                    if (thisReportData = _this.reports[reportType]) {
                        if (thisFocusData = thisReportData.day) {
                            if (thisWeekData = thisFocusData[weekEnd]) {
                                var thisTagData = thisWeekData.data[fullTag];
                                // has a value
                                if (thisTagData != null) {
                                    if (dataKey == null) {
                                        return thisTagData;
                                    }
                                    var mapped = {};
                                    thisTagData.forEach(function (x) {
                                        var docw = (0, _npmMoment.default)(x.date).diff(weekEnd, "days") + 6;
                                        mapped[docw] = x[dataKey];
                                    });
                                    return mapped;
                                }
                                // data has been loaded, but still no value
                                if (thisWeekData.complete || thisTagData !== undefined) {
                                    return null;
                                }
                            }
                        }
                    }
                } catch (e) {
                    return undefined;
                }
                // still loading
                return undefined;
            };

            var extractValue = function extractValue(src, key) {
                // x == null returns true for both null and undefined... so shorthand way of doing (x === null || x === undefined)
                return src == null ? src : src[key] == null ? null : src[key];
            };

            // add value to another dict, keeping note of undefined and null
            var applyValue = function applyValue(dst, src, key) {
                if (src[key] === undefined) {
                    return;
                }
                if (src[key] === null && dst[key] === undefined) {
                    dst[key] = null;
                } else if (src[key] !== null) {
                    dst[key] = (dst[key] || 0) + src[key];
                }
            };

            var todayDate = _npmMoment.default.utc().set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
            // const todayDate = this.weekEndDate.clone().add(-6 + todayIndex, 'days')
            var isWeekInPast = todayDate.isAfter(this.weekEndDate, 'day');
            var thisWeekEnd = this.weekEndDate.format('YYYY-MM-DD');
            var docws = [].concat(_toConsumableArray(Array(7).keys()));

            var weekBudgetData = {};
            var weekenddateKey = self.get('weekenddateKey');

            var tasks = this.get('tasks');

            var USE_NEW_PW = (0, _state.getClientOption)('use_new_pw', 'bool');
            var USE_FLIPPED_PW_HOURS = (0, _state.getClientOption)('use_flipped_pw_hours', 'bool');
            var hoursFactor = USE_FLIPPED_PW_HOURS ? -1 : 1;

            stores.forEach(function (item) {
                var tag = _this.get('clientCode') + '.store.' + item.storecode;
                var actualLabor = getStoreReportValue(tag, 'actuallabor', thisWeekEnd, 'shift_total');
                var scheduleHours = getStoreReportValue(tag, 'schedulehours', thisWeekEnd, 'hours');
                var taskHours = getStoreReportValue(tag, 'taskhours', thisWeekEnd);

                var ibcInitial = {
                    week: 0
                };
                docws.forEach(function (docw) {
                    ibcInitial[docw] = 0;
                });

                // count up initial IBC hours
                if (taskHours) {
                    if (!USE_NEW_PW) {
                        taskHours = Object.keys(taskHours).reduce(function (o, k) {
                            o[(0, _npmMoment.default)(k).diff(_this.weekEndDate, 'days') + 6] = taskHours[k];
                            return o;
                        }, {});
                        Object.keys(taskHours).forEach(function (i) {
                            if (taskHours[i].storehours) return;
                            Object.values(taskHours[i]).forEach(function (th) {
                                if (th.is_ibc === 'Y') {
                                    ibcInitial[th.is_ibc_by_week === 'Y' ? 'week' : i] += th.prelim_hours;
                                }
                            });
                        });
                    } else {
                        docws.forEach(function (docw) {
                            if (taskHours && taskHours[docw] && taskHours[docw].storehours) {
                                taskHours[docw].storehours.forEach(function (sh) {
                                    var task = tasks[sh.task];
                                    if (task && task.isIbc) {
                                        ibcInitial[task.isIbcByWeek ? 'week' : docw] += sh.hours;
                                    }
                                });
                            }
                        });
                    }
                }

                var ibcRemaining = null;
                var isIbcByWeek = ibcInitial.week > 0;
                if (isIbcByWeek) {
                    ibcRemaining = ibcInitial.week;
                }

                var variance = 0;
                docws.forEach(function (docw) {
                    var actual = extractValue(actualLabor, docw);
                    var schedule = extractValue(scheduleHours, docw);

                    if (actual === null && isWeekInPast && USE_NEW_PW) {
                        actual = 0;
                    }

                    var actualOrSchedule = actual === null ? schedule : actual;

                    var planHours = null;
                    if (taskHours && taskHours[docw]) {
                        planHours = 0;
                        if (!isIbcByWeek) {
                            ibcRemaining = ibcInitial[docw];
                        }
                        if (!USE_NEW_PW) {
                            Object.values(taskHours[docw]).forEach(function (r) {
                                if (r.is_ibc === 'N') {
                                    var useHours = r.final_hours !== null ? r.final_hours : r.schedule_hours !== null ? r.schedule_hours : r.prelim_hours;
                                    if (useHours === null) {
                                        useHours = 0;
                                    }
                                    if (r.is_can_ibc === 'Y' && useHours > 0) {
                                        var diff = ibcRemaining - useHours;
                                        if (diff > 0) {
                                            ibcRemaining -= useHours;
                                            useHours = 0;
                                        } else {
                                            ibcRemaining = 0;
                                            useHours = -diff;
                                        }
                                    }
                                    planHours += useHours;
                                }
                            });
                        } else {
                            taskHours[docw].storehours.forEach(function (sh) {
                                var task = tasks[sh.task];
                                if (!task) return;
                                if (!task.isIbc) {
                                    var useHours = sh.hours;
                                    if (task.isCanIbc && sh.hours > 0) {
                                        var diff = ibcRemaining - sh.hours;
                                        if (diff > 0) {
                                            ibcRemaining -= sh.hours;
                                            useHours = 0;
                                        } else {
                                            ibcRemaining = 0;
                                            useHours = -diff;
                                        }
                                    }
                                    planHours += useHours;
                                }
                            });
                        }
                    }

                    if (actualOrSchedule != null && planHours != null) variance += actualOrSchedule - planHours;
                });

                weekBudgetData['store' + item.storeid] = {
                    variance: variance,
                    overunder: variance * hoursFactor,
                    isover: variance > 0
                };
            });

            var hierarchyData = this.get('gatheredHierarchies.data') || {};

            var calcNodeInfo = function calcNodeInfo(tag) {
                var info = null;
                var hierarchy = hierarchyData[tag];
                if (hierarchy) {
                    info = {
                        variance: null
                    };

                    hierarchy.child_tags.forEach(function (childTag) {
                        var childHierarchy = hierarchyData[childTag];
                        var childIsStore = childHierarchy.type === 'store';
                        var childInfo = childIsStore ? weekBudgetData['store' + childHierarchy.id] : calcNodeInfo(childTag);
                        if (childInfo) applyValue(info, childInfo, 'variance');
                    });
                    info.overunder = info.variance * hoursFactor;
                    info.isover = info.variance > 0;
                    weekBudgetData['hierarchyNode' + hierarchy.id] = info;
                }
                return info;
            };

            var info = calcNodeInfo(this.clientCode + '.' + this.node.tag);

            this.get('nodeChildren').forEach(function (child) {
                var k = 'hierarchyNode' + child.id;
                if (weekBudgetData[k] == null) {
                    weekBudgetData[k] = {
                        variance: 0,
                        overunder: 0,
                        isover: false
                    };
                }
            });

            if (info) {
                this.set('totalOverUnderRaw.' + weekenddateKey + '.hierarchynode' + this.node.nodeId, info.overunder);
                this.notifyPropertyChange('totalOverUnderRaw');
            }

            self.set('budgetDataStore.' + weekenddateKey, weekBudgetData);
            this.notifyPropertyChange('budgetDataStore');
        },

        basements: Ember.computed('scheduleSummaryStores', function () {
            var stores = this.scheduleSummaryStores;
            var basements = {};

            _npmUnderscore.default.each(stores, function (item) {
                if (!_npmUnderscore.default.has(basements, item.hierarchyid)) {
                    basements[item.hierarchyid] = {
                        hidden: 0,
                        executive: 0,
                        readonly: 0,
                        preliminary: 0,
                        activeNotStarted: 0,
                        activeInProgress: 0,
                        activePublished: 0,

                        published: 0,
                        unassigned: 0,
                        violations: 0,
                        warnings: 0,
                        alerts: 0,
                        num_saved: 0,
                        numStores: 0,
                        num_updated: 0,
                        inProgress: 0,
                        notStarted: 0
                    };
                }

                _npmUnderscore.default.each(aggregates, function (prop) {
                    basements[item.hierarchyid][prop[0]] += item[prop[1]];
                });

                if (item.published === 'Y') {
                    basements[item.hierarchyid].published += 1;
                }

                var storeStatusKey = statusKey(item);
                if (storeStatusKey != null) basements[item.hierarchyid][storeStatusKey] += 1;

                basements[item.hierarchyid].numStores += 1;
                basements[item.hierarchyid].num_saved += item.saved_by ? 1 : 0;

                if (item.published !== 'Y' && item.saved_by) {
                    basements[item.hierarchyid].inProgress += 1;
                }
            });

            Object.keys(basements).forEach(function (hId) {
                // basements[hId].inProgress = basements[hId].activeInProgress
                // basements[hId].num_saved -
                // basements[hId].preliminary -
                // basements[hId].published
                basements[hId].notStarted = basements[hId].activeNotStarted;
                // basements[hId].numStores -
                // basements[hId].published -
                // basements[hId].preliminary -
                // basements[hId].inProgress
            });

            return basements;
        }),

        calculateIbcHours: function calculateIbcHours(data) {
            var tasks = this.get('tasks');

            return _npmUnderscore.default.reduce(data, function (hours, item) {
                var task = tasks[item.task];
                var change = task.isIbc ? item.prelimhours : 0;
                return hours += change;
            }, 0);
        },

        compileBudget: function compileBudget(data) {
            var scheduledActualHours = 0;
            var taskHours = 0;
            var variance = 0;
            var tasks = this.get('tasks');
            var hierarchyNodeId = this.get('hierarchyNodeId');
            var weekenddateKey = this.get('weekenddateKey');

            if (_npmUnderscore.default.has(data[1], 'taskHours')) {
                var ibcHoursOrig = this.calculateIbcHours(data[1].taskHours);
                var ibcRemaining = ibcHoursOrig;

                _npmUnderscore.default.each(data[1].taskHours, function (item) {
                    var task = tasks[item.task];
                    // console.log(item)
                    if (!task.isIbc) {
                        var useHours = item.finalhours !== null ? item.finalhours : item.schedulehours !== null ? item.schedulehours : item.prelimhours;
                        if (useHours === null) {
                            useHours = 0;
                        }

                        if (task.isCanIbc && useHours > 0) {
                            var diff = ibcRemaining - useHours;
                            if (diff > 0) {
                                ibcRemaining -= useHours;
                                useHours = 0;
                            } else {
                                ibcRemaining = 0;
                                useHours = Math.abs(diff);
                            }
                        }
                        taskHours += useHours;
                    }
                });
            } else {
                _npmUnderscore.default.each(data[1], function (item) {
                    taskHours += _npmUnderscore.default.reduce(item.total, function (arr, val) {
                        if (val) {
                            return arr + val;
                        } else {
                            return arr;
                        }
                    }, 0);
                });
            }

            _npmUnderscore.default.each(data[0], function (item) {
                scheduledActualHours += !item.actualhours ? item.scheduledhours : item.actualhours;
            });

            variance = Math.round(scheduledActualHours) - Math.round(taskHours);
            if (this.get('isLaneBryant')) {
                variance *= -1;
            }

            this.incrementProperty('totalOverUnderRaw.' + weekenddateKey + '.hierarchynode' + hierarchyNodeId, variance);
            this.notifyPropertyChange('totalOverUnderRaw');
            return variance;
        },

        compileRows: function compileRows(data) {
            var self = this;

            var rows = [];
            if (this.get('isBasement')) {
                rows = _npmUnderscore.default.map(data, function (item) {
                    var budgetData = self.getBudgetData('store', item.storeid);
                    // Switch next two lines for tagging support.
                    var tag = 'store.' + item.storecode;
                    var salesAtRisk = self.getSalesAtRisk(tag);
                    var flags = self.getFlags(tag);
                    // const weekenddateKey = self.get('weekenddateKey')
                    var publishPercentage = item.published === 'Y' ? 1 : item.updated_at ? 0.5 : 0;

                    if (!item.status) {
                        item.status = 'unknown';
                    }

                    var status = _constants.ScheduleStatus[item.status.toUpperCase()];

                    return {
                        id: item.storeid,
                        name: item.storecode + ' ' + item.storename,
                        type: 'store',
                        code: item.storecode,
                        nodeName: item.storename,
                        tag: tag,
                        // client: self.get('client'),
                        budget: budgetData,
                        isPublished: item.published === 'Y' ? true : false,
                        published: item.published,
                        unassigned: item.unassigned,
                        updated_at: item.updated_at,
                        status: status,
                        preliminary: status === _constants.ScheduleStatus.PRELIMINARY,
                        saved_by: item.saved_by,
                        flags: flags,
                        salesAtRisk: salesAtRisk,
                        publishPercentage: publishPercentage
                    };
                });
            } else {
                rows = _npmUnderscore.default.map(this.get('nodeChildren'), function (child) {
                    var numStores = self.getCalculatedProperty('numStores', child.id);
                    var published = self.getCalculatedProperty('published', child.id);
                    var unassigned = self.getCalculatedProperty('unassigned', child.id);
                    var num_saved = self.getCalculatedProperty('num_saved', child.id);
                    var inProgress = self.getCalculatedProperty('inProgress', child.id);
                    var notStarted = self.getCalculatedProperty('notStarted', child.id);
                    var hidden = self.getCalculatedProperty('hidden', child.id);
                    var executive = self.getCalculatedProperty('executive', child.id);
                    var readonly = self.getCalculatedProperty('readonly', child.id);
                    var preliminary = self.getCalculatedProperty('preliminary', child.id);
                    var activeNotStarted = self.getCalculatedProperty('activeNotStarted', child.id);
                    var activeInProgress = self.getCalculatedProperty('activeInProgress', child.id);
                    var activePublished = self.getCalculatedProperty('activePublished', child.id);
                    // console.log(numStores === published)
                    var budgetData = self.getBudgetData('hierarchyNode', child.id);
                    // Switch next two lines for tagging support.
                    var tag = (child.type + '.' + child.code).toLowerCase();
                    var salesAtRisk = self.getSalesAtRisk(tag);
                    var flags = self.getFlags(tag);

                    var output = {
                        id: child.id,
                        name: child.name,
                        type: 'hierarchyNode',
                        code: child.code,
                        tag: tag,
                        budget: budgetData,
                        isFullyPublished: numStores === published && numStores > 0,
                        numStores: numStores,
                        published: published,
                        total: {
                            hidden: hidden,
                            executive: executive,
                            readonly: readonly,
                            preliminary: preliminary,
                            activeNotStarted: activeNotStarted,
                            activeInProgress: activeInProgress,
                            activePublished: activePublished,
                            numStores: numStores
                        },
                        unassigned: unassigned,
                        num_saved: num_saved,
                        inProgress: inProgress,
                        notStarted: notStarted,
                        publishPercentage: published / numStores,
                        salesAtRisk: salesAtRisk,
                        flags: flags
                    };

                    if (numStores > 0) {
                        return output;
                    } else {
                        return null;
                    }
                });

                rows = _npmUnderscore.default.compact(rows);
                // console.info(rows)
            }

            var key = self.get('sortBy');
            var commaSplit = key.split(',');
            var sorted = _npmLodash.default.sortBy(rows, function (row) {
                if (commaSplit.length > 1) {
                    var orderArray = _npmUnderscore.default.reduce(commaSplit, function (arr, item) {
                        arr.push(_npmLodash.default.get(row, item, 0));
                        return arr;
                    }, []);
                    return orderArray;
                }
                var value = _npmLodash.default.get(row, key, 0) || 0;
                return value;
            });

            if (self.get('reverseSort')) {
                sorted.reverse();
            }
            self.set('lastSortBy', self.get('sortBy'));

            return sorted;
        },

        loadBudgetData: function loadBudgetData(type, id) {
            var self = this;
            var ajaxService = this.get('ajaxService');
            var weekenddate = this.get('weekenddate');
            var scheduleActualsUrl = '/workforce/api/reports/scheduled-actual-hours-by-day?' + type + '=' + id + '&date=' + weekenddate;
            var taskHoursUrl = '/workforce/api/taskHours?' + type + '=' + id + '&date=' + weekenddate;

            var scheduleActuals = ajaxService.ajaxGet(scheduleActualsUrl);
            var taskHours = (0, _interactions.fetch)(taskHoursUrl);

            var weekenddateKey = self.get('weekenddateKey');
            var existingData = self.get('budgetDataStore.' + weekenddateKey + '.' + type + id);
            self.notifyPropertyChange('budgetDataStore');

            if (existingData) {
                self.incrementProperty('budgetsIn');
                return existingData;
            } else {
                return {
                    scheduleActuals: scheduleActuals,
                    taskHours: taskHours,
                    promises: Ember.$.when(scheduleActuals, taskHours).done(function (scheduleActualsValues, taskHoursValues) {
                        var values = [scheduleActualsValues[0], taskHoursValues[0]];
                        var budget = self.compileBudget(values);
                        var hierarchynode = self.get('store').peekRecord('hierarchy-node', '' + id);

                        if (!self.get('budgetDataStore.' + weekenddateKey)) {
                            self.set('budgetDataStore.' + weekenddateKey, {});
                        }
                        self.set('budgetDataStore.' + weekenddateKey + '.' + type + id + '.overunder', budget);
                        if (self.get('isLaneBryant')) {
                            self.set('budgetDataStore.' + weekenddateKey + '.' + type + id + '.isover', budget < 0);
                        } else {
                            self.set('budgetDataStore.' + weekenddateKey + '.' + type + id + '.isover', budget > 0);
                        }

                        if (hierarchynode && hierarchynode.get('isActive') || self.get('isBasement')) {
                            self.incrementProperty('budgetsIn');
                        }
                    })
                    // 2018-02-13 Remove Promises because they are not supported in IE
                    // promises: Promise.all([scheduleActuals, taskHours]).then(function (values) {
                    //     const budget = self.compileBudget(values);
                    //     const weekenddateKey = self.get('weekenddateKey');
                    //     if (!self.get(`budgetDataStore.${weekenddateKey}`)) {
                    //         self.set(`budgetDataStore.${weekenddateKey}`, {});
                    //     }
                    //     self.set(`budgetDataStore.${weekenddateKey}.${id}.overunder`, budget);
                    //     if (self.get('isLaneBryant')) {
                    //         self.set(`budgetDataStore.${weekenddateKey}.${id}.isover`, budget < 0);
                    //     } else {
                    //         self.set(`budgetDataStore.${weekenddateKey}.${id}.isover`, budget > 0);
                    //     }
                    // })
                };
            }
        },

        loadSARData: function loadSARData(shortTag) {
            var self = this;
            var client = this.get('client');
            var weekenddate = this.get('weekenddate');
            var sarUrl = 'data/salesatrisk/' + client.get('clientCode').toLowerCase() + '/' + shortTag + '?week_end=' + weekenddate + '&focus=week&aggregate=true';
            // const weekenddateKey = self.get('weekenddateKey');
            (0, _interactions.fetch)(sarUrl).then(function (r) {
                var amt = r.data || 0;
                var location = shortTag.startsWith('store') ? shortTag : 'hierarchy.' + shortTag.split('.')[1];
                self.set('salesAtRiskDataStore.' + location, amt);
                self.incrementProperty('sarIn');
                self.notifyPropertyChange('salesAtRiskDataStore');
            });
        },

        loadFlagsData: function loadFlagsData(shortTag) {
            var self = this;
            var client = this.get('client');
            var weekenddate = this.get('weekenddate');
            var flagsURL = 'store/schedule/flags/' + client.get('clientCode').toLowerCase() + '/' + shortTag + '/' + weekenddate;

            (0, _interactions.fetch)(flagsURL).then(function (r) {
                var flags = r.flags || {};

                var location = shortTag.startsWith('store') ? shortTag : 'hierarchy.' + shortTag.split('.')[1];
                self.set('flagsDataStore.' + location, flags);
                self.incrementProperty('flagsIn');
                self.notifyPropertyChange('flagsDataStore');
            });
        },

        // loadSARData: function (shortTag) {
        //     // console.log('loadSARData')
        //     const self = this;
        //     const client = this.get('client');
        //     const weekenddate = this.get('weekenddate');
        //     const sarUrl = `data/salesatrisk/${client.get('clientCode').toLowerCase()}/${shortTag}?week_end=${weekenddate}&focus=week`

        //     const weekenddateKey = self.get('weekenddateKey');
        //     const existingData = self.get(`salesAtRiskDataStore.${weekenddateKey}.${shortTag}`);
        //     self.notifyPropertyChange('salesAtRiskDataStore');

        //     // console.log(`existingData? ${existingData}`)
        //     if (existingData) {
        //         self.incrementProperty('sarIn');
        //         return existingData;
        //     } else {
        //         // console.log(weekenddateKey, self.get('salesAtRiskDataStore'))
        //         return fetch(sarUrl).then(r => {
        //             if (!self.get(`salesAtRiskDataStore.${weekenddateKey}`)) {
        //                 self.set(`salesAtRiskDataStore.${weekenddateKey}`, {});
        //             }
        //             if (!self.get(`salesAtRiskDataStore.${weekenddateKey}.store`)) {
        //                 self.set(`salesAtRiskDataStore.${weekenddateKey}.store`, {});
        //             }
        //             const amt = r.data || 0;
        //             self.set(`salesAtRiskDataStore.${weekenddateKey}.${shortTag}`, amt);
        //             // self.incrementProperty('_totalSAR', amt);
        //             self.incrementProperty('sarIn');
        //             self.notifyPropertyChange('salesAtRiskDataStore');
        //             // self.notifyPropertyChange('salesAtRiskDataStore');
        //         });
        //     }
        // },

        // loadFlagsData: function (shortTag) {
        //     // console.log('loadSARData')
        //     const self = this;
        //     const client = this.get('client');
        //     const weekenddate = this.get('weekenddate');
        //     const flagsURL = `store/schedule/flags/${client.get('clientCode').toLowerCase()}/${shortTag}/${weekenddate}`

        //     const weekenddateKey = self.get('weekenddateKey');
        //     const existingData = self.get(`flagsDataStore.${weekenddateKey}.${shortTag}`);
        //     self.notifyPropertyChange('flagsDataStore');

        //     if (existingData) {
        //         self.incrementProperty('flagsIn');
        //         return existingData;
        //     } else {
        //         return fetch(flagsURL).then(r => {
        //             if (!self.get(`flagsDataStore.${weekenddateKey}`)) {
        //                 self.set(`flagsDataStore.${weekenddateKey}`, {});
        //             }
        //             if (!self.get(`flagsDataStore.${weekenddateKey}.store`)) {
        //                 self.set(`flagsDataStore.${weekenddateKey}.store`, {});
        //             }
        //             const flags = r.flags || {};
        //             self.set(`flagsDataStore.${weekenddateKey}.${shortTag}`, flags);
        //             self.incrementProperty('flagsIn');
        //         });
        //     }
        // },

        _retrieveBudgetDataFromRaw: function _retrieveBudgetDataFromRaw(weekenddateKey, id) {
            var data = this.get('budgetDataStore');
            var retval = {};
            if (!data[weekenddateKey]) {
                this.set('budgetDataStore.' + weekenddateKey, retval);
            } else {
                return data[weekenddateKey][id];
            }
        },

        // _retrieveSARDataFromRaw: function (weekenddateKey, shortTag) {
        //     const data = this.get('salesAtRiskDataStore');
        //     let retval = {};
        //     const parts = shortTag.split('.');
        //     if (!data[weekenddateKey]) {
        //         this.set(`salesAtRiskDataStore.${weekenddateKey}`, retval);
        //     } else {
        //         return data[weekenddateKey][parts[0]][parts[1]];
        //     }
        // },

        // _retrieveFlagsDataFromRaw: function (weekenddateKey, shortTag) {
        //     const data = this.get('flagsDataStore');
        //     let retval = {};
        //     const parts = shortTag.split('.');
        //     if (!data[weekenddateKey]) {
        //         this.set(`flagsDataStore.${weekenddateKey}`, retval);
        //     } else {
        //         return data[weekenddateKey][parts[0]][parts[1]];
        //     }
        // },

        // displaySAR: computed('sarIn', function () {
        //     const weekenddateKey = this.get('weekenddateKey');
        //     return this._retrieveSARDataFromRaw(weekenddateKey, 'store.0152')
        // }),

        // displayFlags: computed('flagsIn', function () {
        //     const weekenddateKey = this.get('weekenddateKey');
        //     return this._retrieveFlagsDataFromRaw(weekenddateKey, 'store.0152')
        // }),

        getBudgetData: function getBudgetData(type, id) {
            var budgetData = this.get('budgetData');
            var weekenddateKey = this.get('weekenddateKey');

            if (_npmUnderscore.default.has(budgetData, weekenddateKey) && _npmUnderscore.default.has(budgetData[weekenddateKey], type + id)) {
                this.incrementProperty('budgetsIn');
                return budgetData[weekenddateKey]['' + type + id];
            } else {
                if (!_npmUnderscore.default.has(this.get('budgetDataStore'), weekenddateKey)) {
                    this.set('budgetDataStore.' + weekenddateKey, {});
                }
                // this.set(`budgetDataStore.${weekenddateKey}.${id}`, this.loadBudgetData(type, id));
                // this.set(`budgetDataStore.${weekenddateKey}.${id}.overunder`, '-');
                // return this._retrieveBudgetDataFromRaw(weekenddateKey, id);

                // const existingData = this.get(
                //     `budgetDataStore.${weekenddateKey}.${type}${id}`,
                // )
                // if (!existingData) {
                //     this.set(
                //         `budgetDataStore.${weekenddateKey}.${type}${id}`,
                //         this.loadBudgetData(type, id),
                //     )
                //     this.set(
                //         `budgetDataStore.${weekenddateKey}.${type}${id}.overunder`,
                //         '-',
                //     )
                //     return this._retrieveBudgetDataFromRaw(
                //         weekenddateKey,
                //         `${type}${id}`,
                //     )
                // } else {
                //     this.incrementProperty('budgetsIn')
                //     return existingData
                // }
            }
        },

        getSalesAtRisk: function getSalesAtRisk(shortTag) {
            var sarData = this.get('salesAtRiskDataStore');
            // const weekenddateKey = this.get('weekenddateKey')

            var _shortTag$split = shortTag.split('.'),
                _shortTag$split2 = _slicedToArray(_shortTag$split, 2),
                nodeType = _shortTag$split2[0],
                nodeCode = _shortTag$split2[1];

            var useNodeType = nodeType === 'store' ? nodeType : 'hierarchy';
            var sar = sarData && sarData[useNodeType] && sarData[useNodeType][nodeCode] ? sarData[useNodeType][nodeCode] : 0;
            return sar;

            // if (!_.has(this.get(`salesAtRiskDataStore`), weekenddateKey)) {
            //     this.set(`salesAtRiskDataStore.${weekenddateKey}`, {});
            // }
            // const parts = shortTag.split('.');
            // if (!_.has(this.get(`salesAtRiskDataStore.weekenddateKey`), parts[0])) {
            //     this.set(`salesAtRiskDataStore.${weekenddateKey}.${parts[0]}`, {});
            // }

            // const existingData = this.get(`salesAtRiskDataStore.${weekenddateKey}.${shortTag}`);
            // console.log(`is existing? ${!!existingData}`)
            // if(!existingData) {
            //     this.loadSARData(shortTag);
            //     // this.set(`salesAtRiskDataStore.${weekenddateKey}.${shortTag}`, );
            //     // console.log(`do _retrieveSARDataFromRaw ${this._retrieveSARDataFromRaw(weekenddateKey, shortTag)}`)
            //     // return this._retrieveSARDataFromRaw(weekenddateKey, shortTag);
            // } else {
            //     this.incrementProperty('sarIn');
            // }
        },

        getFlags: function getFlags(shortTag) {
            var flagsData = this.get('flagsDataStore');
            // const weekenddateKey = this.get('weekenddateKey')

            var _shortTag$split3 = shortTag.split('.'),
                _shortTag$split4 = _slicedToArray(_shortTag$split3, 2),
                nodeType = _shortTag$split4[0],
                nodeCode = _shortTag$split4[1];

            var useNodeType = nodeType === 'store' ? nodeType : 'hierarchy';
            var flags = flagsData && flagsData[useNodeType] && flagsData[useNodeType][nodeCode] ? flagsData[useNodeType][nodeCode] : {};
            return flags;

            //     if (_.has(flagsData, weekenddateKey) && _.has(flagsData[weekenddateKey], shortTag)) {
            //         return flagsData[weekenddateKey][`${shortTag}`];
            //     } else {
            //         if (!_.has(this.get(`flagsDataStore`), weekenddateKey)) {
            //             this.set(`flagsDataStore.${weekenddateKey}`, {});
            //         }
            //         const parts = shortTag.split('.');
            //         if (!_.has(this.get(`flagsDataStore.weekenddateKey`), parts[0])) {
            //             this.set(`flagsDataStore.${weekenddateKey}.${parts[0]}`, {});
            //         }

            //         const existingData = this.get(`flagsDataStore.${weekenddateKey}.${shortTag}`);
            //         if(!existingData) {
            //             this.loadFlagsData(shortTag);
            //             return this._retrieveFlagsDataFromRaw(weekenddateKey, shortTag);
            //         } else {
            //             this.incrementProperty('flagsIn');
            //             return existingData;
            //         }
            //     }
        },

        allBudgetDataIn: Ember.computed('budgetsIn', 'withBudgetData', function () {
            // return this.get('rows').length <= this.get('budgetsIn')
            return this.get('rows').length === this.get('withBudgetData');
        }),

        allSalesAtRiskDataIn: Ember.computed('sarIn', 'withSARData', function () {
            // return this.get('rows').length <= this.get('sarIn')
            return this.get('rows').length === this.get('withSARData');
        }),

        allFlagsDataIn: Ember.computed('flagsIn', 'withFlagData', function () {
            // return this.get('rows').length <= this.get('flagsIn')
            return this.get('rows').length === this.get('withFlagData');
        }),

        // allSalesAtRiskDataIn: computed('salesAtRiskData', function () {
        //     const rows = this.get('rows');
        //     const salesAtRiskData = this.get('salesAtRiskData');
        //     const allStoreCodes = rows
        //         .filter(x => x.type === 'store')
        //         .map(x => x.code);
        //     const allHierarchyCodes = rows
        //         .filter(x => x.type === 'hierarchynode')
        //         .map(x => x.code);
        //     const allStores = allStoreCodes.every(x => salesAtRiskData.store.hasOwnProperty(x));
        //     const allHierarchies = allHierarchyCodes.every(x => salesAtRiskData.hierarchynode.hasOwnProperty(x));
        //     return allStores && allHierarchies;
        // }),

        totalOverUnderText: Ember.computed('totalOverUnderRaw', 'useFlippedPWHours', function () {
            var hierarchyNodeId = this.get('hierarchyNodeId');
            var weekenddateKey = this.get('weekenddateKey');
            if (this.useFlippedPWHours) {
                return this.get('totalOverUnderRaw.' + weekenddateKey + '.hierarchynode' + hierarchyNodeId) >= 0 ? 'under' : 'over';
            } else {
                return this.get('totalOverUnderRaw.' + weekenddateKey + '.hierarchynode' + hierarchyNodeId) < 0 ? 'under' : 'over';
            }
        }),

        totalFlags: Ember.computed('allFlagsDataIn', 'flagsDataStore', 'currentNode', function () {
            var currentNode = this.get('currentNode');
            if (currentNode) {
                var nodes = currentNode.stores.length > 0 ? currentNode.stores : currentNode.children;

                var data = this.get('flagsDataStore');

                if (data && nodes) {
                    var location = currentNode.stores.length > 0 ? data.store : data.hierarchy;
                    data = nodes.length > 0 && location ? Object.values(location) : [];

                    if (data.length === nodes.length) {
                        var total = data.reduce(function (o, i) {
                            o.violations += i.violations || 0;
                            o.warnings += i.warnings || 0;
                            o.alerts += i.alerts || 0;
                            return o;
                        }, {
                            alerts: 0,
                            warnings: 0,
                            violations: 0
                        });
                        return total;
                    }
                    // data = [].concat(...data);
                }
            }
            return {
                alerts: 0,
                warnings: 0,
                violations: 0
            };
        }),

        totalSalesAtRisk: Ember.computed('allSalesAtRiskDataIn', 'salesAtRiskDataStore', 'currentNode.tag', function () {
            var currentNode = this.get('currentNode');
            if (currentNode) {
                var nodes = currentNode.stores.length > 0 ? currentNode.stores : currentNode.children;

                var data = this.get('salesAtRiskDataStore');

                if (data && nodes) {
                    var location = currentNode.stores.length > 0 ? data.store : data.hierarchy;
                    data = nodes.length > 0 && location ? Object.values(location) : [];
                    if (data.length === nodes.length) {
                        var total = data.reduce(function (o, i) {
                            return o += i;
                        }, 0);
                        return total;
                    }
                }
            }
            return 0;
        }),

        weekenddateKey: Ember.computed('data', function () {
            return this.get('weekenddate').replace(/\-/g, ''); // eslint-disable-line  no-useless-escape
        }),

        budgetData: Ember.computed('scheduleSummaryStores', 'budgetDataStore', function () {
            var self = this;
            return self.get('budgetDataStore');
        }),

        totalOverUnder: Ember.computed('totalOverUnderRaw', 'data.hierarchyNodeId', function () {
            var hierarchyNodeId = this.get('hierarchyNodeId');
            var weekenddateKey = this.get('weekenddateKey');

            if (!_npmUnderscore.default.has(this.get('totalOverUnderRaw'), weekenddateKey)) {
                this.set('totalOverUnderRaw.' + weekenddateKey, {}); // eslint-disable-line ember/no-side-effects
            }

            if (!_npmUnderscore.default.has(this.get('totalOverUnderRaw.' + weekenddateKey), 'hierarchynode' + hierarchyNodeId)) {
                this.set('totalOverUnderRaw.' + weekenddateKey + '.hierarchynode' + hierarchyNodeId, 0);
            }
            // console.info(this.get('totalOverUnderRaw'))
            return this.get('totalOverUnderRaw')[weekenddateKey]['hierarchynode' + hierarchyNodeId];
        }),

        weekenddate: Ember.computed('data', function () {
            return this.get('stateService').getWeekEndDate();
        }),

        hierarchyNodeId: Ember.computed('data.hierarchyNodeId', function () {
            return this.get('data').hierarchyNodeId;
        }),

        client: Ember.computed('data', function () {
            return this.get('stateService').getClient();
        }),

        isBasement: Ember.computed('data.scheduleSummary', function () {
            // determine whether
            var children = this.get('data').scheduleSummary.children || [];
            return children.length === 0;
        }),

        children: Ember.computed('data.scheduleSummary', function () {
            // List of hierarchyIds of the direct children of the hierarchynode
            return this.get('data').scheduleSummary.children;
        }),

        descendants: Ember.computed('data.scheduleSummary', function () {
            // List of all hierarchyIds underneath the tree of the hierarchynode
            return this.get('data').scheduleSummary.descendants;
        }),

        tasks: Ember.computed('data.tasks', function () {
            var self = this;
            // return this.get('data').tasks;
            // TODO:
            // - Below line is rerutning undefined
            // https://192.168.1.5/clients/9/schedule?date=2017-11-18&hierarchyNodeId=51 (linked to mx_test)
            return _npmUnderscore.default.reduce(self.get('data').tasks.tasks, function (arr, item) {
                arr[item.id] = item;
                return arr;
            }, {});
        }),

        salesAtRisk: Ember.computed('data.salesAtRisk', function () {
            return this.get('data').salesAtRisk;
        }),

        rows: Ember.computed('data.scheduleSummary', 'budgetDataStore', 'reports', 'gatheredHierarchies',
        // 'flagsDataStore',
        // 'salesAtRiskDataStore',
        'reverseSort', 'stores', 'sortBy', 'currentUser.roles', function () {
            var stores = this.scheduleSummaryStores;
            return this.compileRows(stores);
        }),

        salesAtRiskData: Ember.computed('stores', 'hierarchies', 'weekEndDate', function () {
            var data = { store: {}, hierarchynode: {} };

            var stores = this.get('stores');
            var hierarchies = this.get('hierarchies');
            var weekEndDateMoment = this.get('weekEndDate');
            var weekEndDate = weekEndDateMoment.format('YYYY-MM-DD');

            if (stores) {
                data.store = Object.keys(stores).reduce(function (a, y) {
                    var x = stores[y];
                    if (x.weeks && x.weeks[weekEndDate] && x.weeks[weekEndDate].salesAtRisk) {
                        a[x.code] = x.weeks[weekEndDate].salesAtRisk;
                    } else {
                        a[x.code] = 0;
                    }
                    return a;
                }, {});
            }

            if (hierarchies) {
                data.hierarchynode = Object.keys(hierarchies).reduce(function (a, y) {
                    var x = hierarchies[y];
                    if (x.weeks && x.weeks[weekEndDate] && x.weeks[weekEndDate].salesAtRisk) {
                        a[x.code] = x.weeks[weekEndDate].salesAtRisk;
                    } else {
                        a[x.code] = 0;
                    }
                    return a;
                }, {});
            }

            return data;
        }),

        // NOTE:
        // 2018-05-07
        // - Removed budgetDataStore from the computed listeners because of
        //   Ember 3 change
        total: Ember.computed('scheduleSummaryStores', function () {
            // const hierarchyNodeId = this.get('hierarchyNodeId');
            // calculate the totals for the template
            var stores = this.scheduleSummaryStores;
            var total = {
                published: 0,
                numStores: 0,
                unassigned: 0,
                violations: 0,
                warnings: 0,
                alerts: 0,
                publishPercentage: 0,
                num_saved: 0,

                hidden: 0,
                executive: 0,
                readonly: 0,
                preliminary: 0,
                activeInProgress: 0,
                activeNotStarted: 0,
                activePublished: 0
            };

            _npmUnderscore.default.each(stores, function (store) {
                total['unassigned'] += store.unassigned;
                total['numStores'] += 1;
                total['published'] += store.published === 'Y' ? 1 : 0;
                total['num_saved'] += store.saved_by ? 1 : 0;

                var key = statusKey(store);
                if (key != null) total[key] += 1;
            });

            total['inProgress'] = total['num_saved'] - total['published'];
            total['notStarted'] = total.activeNotStarted;
            total['publishPercentage'] = parseInt(total['published'] / total['numStores'] * 100);

            // console.info(total)
            return total;
        }),

        headers: Ember.computed('data.scheduleSummary', 'reverseSort', 'sortBy', function () {
            var self = this;
            return _npmUnderscore.default.map(headers, function (header) {
                header.labels = _npmUnderscore.default.map(header.labels, function (label) {
                    label = Ember.Object.create(label);
                    label.set('sortBy', self.get('sortBy') === label.key);
                    return Ember.Object.create(label);
                });
                return Ember.Object.create(header);
            });
        }),

        nodeChildren: Ember.computed('data.nodeChildren', function () {
            var nodeChildren = this.get('data').nodeChildren.hierarchyNodes;
            return nodeChildren;
        }),

        withBudgetData: Ember.computed('rows', 'weekenddateKey', 'budgetDataStore', 'currentNode', 'budgetsIn', function () {
            var withBudgetData = 0;
            var rows = this.get('rows');
            var weekenddateKey = this.get('weekenddateKey');
            var budgetDataStore = this.get('budgetDataStore.' + weekenddateKey);
            rows.forEach(function (x) {
                var key = '' + x.type + x.id;
                var data = budgetDataStore[key];
                if (data && data.overunder != null) {
                    withBudgetData += 1;
                }
            });
            return withBudgetData;
        }),

        withSARData: Ember.computed('rows', 'weekenddateKey', 'salesAtRiskDataStore', 'currentNode', 'sarIn', function () {
            var withSARData = 0;
            var rows = this.get('rows');
            var weekenddateKey = this.get('weekenddateKey');
            var salesAtRiskDataStore = this.get('salesAtRiskDataStore');
            rows.forEach(function (x) {
                var level = x.type === 'store' ? 'store' : 'hierarchy';
                var data = salesAtRiskDataStore[level];
                if (data && (data[x.code] || data[x.code] === 0)) {
                    withSARData += 1;
                }
            });
            return withSARData;
        }),

        withFlagData: Ember.computed('rows', 'weekenddateKey', 'flagsDataStore', 'currentNode', 'flagsIn', function () {
            var withFlagData = 0;
            var rows = this.get('rows');
            var weekenddateKey = this.get('weekenddateKey');
            var flagsDataStore = this.get('flagsDataStore');
            rows.forEach(function (x) {
                var level = x.type === 'store' ? 'store' : 'hierarchy';
                var data = flagsDataStore[level];
                if (data && data[x.code]) {
                    withFlagData += 1;
                }
            });
            return withFlagData;
        }),

        setSort: function setSort(key) {
            var shouldReverse = void 0;
            var doOpposite = _npmLodash.default.includes(reverseSortHeaders, key);

            if (doOpposite) {
                if (key === this.get('lastSortBy')) {
                    shouldReverse = !this.get('reverseSort');
                } else {
                    shouldReverse = true;
                }
            } else {
                shouldReverse = key === this.get('lastSortBy') && !this.get('reverseSort');
            }

            this.set('reverseSort', shouldReverse);
            this.set('sortBy', key);
        },

        getViewKey: function getViewKey() {
            var node = this.get('node');
            var weekenddateKey = this.get('weekenddateKey');
            var key = node.clientCode + '.' + node.nodeType + '.' + node.code + '.' + weekenddateKey;
            return key;
        },


        observeCube: Ember.observer('currentNode.tag', 'weekEndDate', function () {
            Ember.run.once(this, 'gatherData');
        }),

        didInsertElement: function didInsertElement() {
            this.gatherData();
        },
        gatherData: function gatherData() {
            var _this2 = this;

            (0, _backoff.generalBackoff)(function () {
                var currentNode = _this2.get('currentNode');
                if (!currentNode) {
                    return false;
                }

                var currentViewKey = _this2.get('currentViewKey');
                var viewKey = _this2.getViewKey();
                var nodes = currentNode.stores.length > 0 ? currentNode.stores : currentNode.children;

                if (currentViewKey === viewKey) {
                    return -1;
                }

                return nodes.length > 0 && currentViewKey !== viewKey;
            }, function () {
                var currentNode = _this2.get('currentNode');
                var currentViewKey = _this2.get('currentViewKey');
                var viewKey = _this2.getViewKey();
                var nodes = currentNode.stores.length > 0 ? currentNode.stores : currentNode.children;

                if (currentViewKey !== viewKey) {
                    // console.log(`processing: ${currentViewKey} !== ${viewKey}`)
                    _this2.set('currentViewKey', viewKey);
                    _this2.set('salesAtRiskDataStore', {
                        store: {},
                        hierarchy: {}
                    });
                    _this2.set('flagsDataStore', { store: {}, hierarchy: {} });
                    _this2.set('sarIn', 0);
                    _this2.set('flagsIn', 0);

                    var thisWeek = _this2.weekEndDate.format('YYYY-MM-DD');
                    var gatheredReports = ['actuallabor', 'schedulehours', 'taskhours'].map(function (x) {
                        return (0, _interactions.hasGatheredReport)(_this2.clientCode, _this2.currentNode.tag, x, 'day', thisWeek);
                    });
                    if (gatheredReports.every(function (x) {
                        return x;
                    })) {
                        _this2.computeBudgetData();
                    }

                    // console.log(nodes)
                    nodes.forEach(function (item) {
                        // console.log(`\tload: ${item.tag}`)
                        _this2.loadSARData(item.tag);
                        _this2.loadFlagsData(item.tag);
                    });
                    _this2.notifyPropertyChange('salesAtRiskDataStore');
                    _this2.notifyPropertyChange('flagsDataStore');
                }
            }, 'gatherData');
        },


        canEditStatus: Ember.computed('currentUser.roles', 'currentClient', function () {
            // for some reason ember requires these to actually be retrieved for this value to update
            var currentUser = this.currentUser;
            var currentClient = this.currentClient;
            return (0, _state.hasScope)('schedule.store:status.edit');
        }),

        actions: {
            selectBusinessUnit: function selectBusinessUnit(id, type) {
                this.sendAction('selectBusinessUnit', id, type); // eslint-disable-line ember/closure-actions
            },
            changeSortBy: function changeSortBy(key) {
                this.setSort(key);
            },
            showScheduleStatusModal: function showScheduleStatusModal(row) {
                if (!this.canEditStatus && row && row.type === 'store') return;
                var scheduleSummary = this.data.scheduleSummary;
                var children = scheduleSummary.children;

                var stores = this.scheduleSummaryStores;

                var info = {};
                if (row == null) {
                    var node = this.node;
                    info.hierarchy = {
                        isStore: false,
                        name: node.name,
                        code: node.code,
                        tag: node.tag,
                        id: node.nodeId
                    };
                    info.total = this.total;
                    info.storeCodes = stores.map(function (s) {
                        return s.storecode;
                    });
                } else {
                    info.hierarchy = {
                        isStore: row.type == 'store',
                        name: row.type == 'store' ? row.nodeName : row.name,
                        code: row.code,
                        tag: row.tag,
                        id: row.id
                    };
                    if (info.hierarchy.isStore) {
                        info.status = row.status;
                        info.storeCodes = [row.code];
                    } else {
                        info.total = row.total;
                        var nodeIds = new Set(children.find(function (x) {
                            return x.hierarchyid == row.id;
                        }).list);
                        info.storeCodes = stores.filter(function (x) {
                            return nodeIds.has(x.hierarchyid);
                        }).map(function (s) {
                            return s.storecode;
                        });
                    }
                }

                this.set('statusModalInfo', info);
                Ember.run.next(this, function () {
                    this.modalService.open('schedule-status-modal');
                });
            },
            updateStoreStatuses: function updateStoreStatuses(statusKey) {
                var scheduleSummary = this.data.scheduleSummary;
                var stores = scheduleSummary.stores;

                var storeCodes = new Set(this.statusModalInfo.storeCodes);

                // update stores
                for (var i = 0; i < stores.length; i++) {
                    if (storeCodes.has(stores[i].storecode)) {
                        stores[i].status = statusKey;
                    }
                }

                this.set('data.scheduleSummary', scheduleSummary);
                this.notifyPropertyChange('data.scheduleSummary');
            }
        }
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});