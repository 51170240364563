define('matrix-frontend/utils/custom-functions', ['exports', 'npm:underscore'], function (exports, _npmUnderscore) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        customJusticeSFS: function customJusticeSFS(activity) {
            var unearnedHours = 1.5;
            var laborStandard = activity.laborStandard;

            var values = activity.values;
            _npmUnderscore.default.mapObject(values, function (value) {
                var hours = value.get('scheduleunits') * laborStandard;
                if (hours < unearnedHours) {
                    unearnedHours = unearnedHours - hours;
                    hours = 0;
                } else {
                    hours = hours - unearnedHours;
                    unearnedHours = 0;
                }
                value.set('schedulehours', hours);
            });

            return values;
        }
    };
});