define('matrix-frontend/components/react-only/schedule-unavailability', ['exports', 'react'], function (exports, _react) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var ShiftBlock = function ShiftBlock(props) {
        var left = (props.start - props.segmentOffset) * props.segmentSize;
        var width = (props.end - props.start) * props.segmentSize;
        return _react.default.createElement('div', {
            className: 'unavailability-block',
            style: {
                left: left + 'px',
                width: width + 'px'
            }
        });
    };

    var comp = function comp(props) {
        var unavailability = props.unavailability,
            segmentSize = props.segmentSize,
            segmentOffset = props.segmentOffset,
            leftOffset = props.leftOffset,
            ee = props.ee,
            date = props.date;


        var blocks = ee.startDate && date.isBefore(ee.startDate, 'day') || ee.terminationDate && date.isAfter(ee.terminationDate, 'day') ? _react.default.createElement('div', {
            className: 'unavailability-block',
            style: {
                left: '0px',
                width: '100%'
            }
        }) : unavailability.map(function (x, i) {
            var attrs = {
                segmentSize: segmentSize,
                segmentOffset: segmentOffset,
                start: x.start,
                end: x.end,
                ee: ee.id
            };
            return _react.default.createElement(ShiftBlock, _extends({ key: 'unavailability-block-' + i }, attrs));
        });

        return _react.default.createElement(
            'div',
            { className: 'unavailability-wrapper' },
            _react.default.createElement(
                'div',
                { className: 'unavailability' },
                blocks
            )
        );
    };

    exports.default = comp;
});