define('matrix-frontend/pods/components/schedule-page-element-chart-control/component', ['exports', 'ember-redux', 'npm:moment', 'npm:lodash', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/date-helper'], function (exports, _emberRedux, _npmMoment, _npmLodash, _types, _dateHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var stateToComputed = function stateToComputed(state) {
        return {
            clients: state.client.clients,
            weekEndDate: state.cube.weekEndDate,
            node: state.cube.node,
            stores: state.node.stores,
            shifts: state.scheduleEdit.shifts,
            displayStart: state.scheduleEdit.displayStart,
            displayEnd: state.scheduleEdit.displayEnd,
            chartDraw: state.scheduleEdit.chartDraw,
            date: state.cube.date
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {
            timeLeft: function timeLeft() {
                return dispatch({ type: _types.SE_TIME_LEFT });
            },
            timeRight: function timeRight() {
                return dispatch({ type: _types.SE_TIME_RIGHT });
            }
        };
    };

    // function zip(arrays) {
    //     return arrays[0].map(function(_,i){
    //         return arrays.map(function(array){return array[i]})
    //     });
    // }

    // const blockToSegments = (block, displayStart, displayEnd) => {
    //     const segments = [];
    //     const currentMoment = displayStart.clone();
    //     while (currentMoment.isBefore(displayEnd, 'minutes')) {
    //         const value = (
    //                    block.start.isSameOrBefore(currentMoment, 'minutes')
    //                 && block.end.isSameOrAfter(currentMoment, 'minutes')
    //             ) ? 1 : 0;
    //         segments.push(value);
    //         currentMoment.add(15, 'minutes');
    //     }
    //     return segments;
    // };

    var generateBase = function generateBase(date, activities, displayStart, displayEnd) {
        var segments = [];

        if (displayStart) {
            var currentMoment = displayStart.clone();
            var didBeginning = false;

            // NOTE: REGARDING WEEKLY CHART DISPLAY
            // To remove the weekly display of only 1 hour at a time,
            // remove the duration variable.
            var duration = date ? 15 : 60;
            // const end = (date) ? displayEnd.clone().subtract(1, 'hour') : displayEnd;

            while (currentMoment.isBefore(displayEnd, 'minutes')) {
                var value = {
                    moment: currentMoment.clone(),
                    label: date && currentMoment.minute() % 4 === 0 || !date && currentMoment.minute() % 60 === 0 && currentMoment.hour() % 24 === 0 && (currentMoment.day() !== 0 && didBeginning || currentMoment.day() === 0 && !didBeginning) ? {
                        time: currentMoment.format('h'),
                        ampm: currentMoment.format('a')
                    } : null,
                    activities: activities.reduce(function (a, v) {
                        if (!a[v.id]) {
                            a[v.id] = { unassigned: 0, assigned: 0 };
                        }
                        return a;
                    }, {})
                };

                segments.push(value);
                currentMoment.add(duration, 'minutes');

                if (!didBeginning) {
                    didBeginning = true;
                }
            }
        }
        return segments;
    };

    var generateScale = function generateScale(segments, optimal) {
        // TODO:
        // - Need to fix this built in "feature"
        // - Currently, this will create a scale based upon the highest segment OR
        //   highest optimal labor.
        //   However, this only sees the segments that are currently being plotted.
        //   Therefore, when on daily view, it will only use the segments for that
        //   day. Need to come up with an efficient way to get the maximum scale
        //   needed per day. Perhaps a value that is stored in Redis and passed
        //   already calculated.
        var min = 0;
        var max = 1;

        segments.forEach(function (segment) {
            var combined = Object.keys(segment.activities).reduce(function (calc, x) {
                return calc + segment.activities[x].unassigned + segment.activities[x].assigned;
            }, 0);

            if (combined >= max) {
                max = combined + 1;
            }
        });

        Object.keys(optimal).forEach(function (d) {
            Object.keys(optimal[d]).forEach(function (h) {
                Object.keys(optimal[d][h]).forEach(function (m) {
                    if (optimal[d][h][m].value >= max) {
                        max = optimal[d][h][m].value + 1;
                    }
                });
            });
        });

        return { max: max, min: min, height: 120 / max };
    };

    var getHourlyAverageOptimal = function getHourlyAverageOptimal(optimal, dotw, mmt) {
        if (!optimal[dotw]) {
            return 0;
        }
        var raw = 0;
        for (var i = 1; i <= 4; i++) {
            var minutes = 15 * i;
            var data = optimal[dotw][mmt.hours()][minutes] ? optimal[dotw][mmt.hours()][minutes].value : 0;
            raw += data / 4;
        }
        return Math.ceil(raw);
    };

    var Comp = Ember.Component.extend({
        cachedData: null,

        currentClient: Ember.computed('clients', 'node', function () {
            var node = this.get('node');
            var clients = this.get('clients');
            return node && clients ? clients[node.clientCode] : null;
        }),
        currentStore: Ember.computed('node', 'stores', function () {
            var node = this.get('node');
            var stores = this.get('redux').store.getState().node.stores;
            if (node && stores) {
                return stores[node.clientCode + '.' + node.tag];
            } else {
                return null;
            }
        }),
        activities: Ember.computed('currentClient', function () {
            var currentClient = this.get('currentClient');
            // return (currentClient && currentClient.activities) ? currentClient.activities.sort((x, y) => {
            //     if (y.activityPriority === 0) { return false; }
            //     return x.activityPriority > y.activityPriority;
            // }) : [];
            return currentClient && currentClient.activities ? _npmLodash.default.sortBy(currentClient.activities, ['activityPriority']) : [];
        }),
        optimal: Ember.computed('currentStore', 'weekEndDate', function () {
            var currentStore = this.get('currentStore');
            var weekEndDate = this.get('weekEndDate').format('YYYY-MM-DD');
            if (currentStore && currentStore.weeks[weekEndDate] && currentStore.weeks[weekEndDate].optimalLabor) {
                return currentStore.weeks[weekEndDate].optimalLabor;
            }
            return {};
        }),
        activitiesById: Ember.computed('activities', function () {
            var activities = this.get('activities');
            var output = {};

            activities.forEach(function (x) {
                return output[x.id] = x;
            });

            return output;
        }),
        chartData: Ember.computed('chartDraw', function () {
            // chartData: computed('date', 'shifts', 'displayStart', 'displayEnd', 'activities', function () {
            // chartData: computed('date', 'displayStart', 'displayEnd', 'activities', function () {

            var cachedData = this.get('cachedData');
            var shifts = this.get('shifts');
            var optimal = this.get('optimal');
            var activities = this.get('activities');
            var displayStart = this.get('displayStart');
            var displayEnd = this.get('displayEnd');
            var date = this.get('date');
            var dotw = date ? (0, _dateHelper.getDayOfClientWeek)(date) + 1 : null;

            // if (!date && cachedData) { return cachedData; }

            var self = this;

            var segments = {};

            if (activities.length === 0) {
                return {
                    segments: segments,
                    scale: { max: 6, min: 0, height: 20 }
                };
            }
            // console.log(Object.keys(segments).length)
            // console.time('calculate segment counts')
            Object.keys(shifts).map(function (x) {
                return shifts[x];
            }).filter(function (x) {
                return x.meta.date.isSame(date, 'day') || date === null;
            }).forEach(function (shift) {
                shift.blocks.forEach(function (block) {
                    var activityId = block.activityId;


                    var currentMoment = block.start.clone();
                    while (currentMoment.isBefore(block.end, 'minutes')) {
                        var assignIncrement = 0;
                        var unassignIncrement = 0;
                        // NOTE: REGARDING WEEKLY CHART DISPLAY
                        // To remove the weekly display of only 1 hour at a time,
                        // remove the recurrences variable.
                        var recurrences = date ? 1 : 4;
                        var currentHour = currentMoment.clone().minutes(0);
                        var time = date ? currentMoment.unix() : currentHour.unix();
                        if (!segments[time]) {
                            segments[time] = {};
                        }
                        if (!segments[time][activityId]) {
                            segments[time][activityId] = {
                                unassigned: 0,
                                assigned: 0
                            };
                        }

                        var currentActivity = activities.find(function (x) {
                            return x.id === activityId;
                        });
                        if (!currentActivity) {
                            console.error('could not find activity ' + activityId, activities);
                        }
                        if (currentActivity.shouldDisplay) {
                            // TEMP
                            // Need to fill in with both assigned and unassigned
                            if (shift.meta.employeeId) {
                                assignIncrement += 1 / recurrences;
                            } else {
                                unassignIncrement += 1 / recurrences;
                            }
                        }
                        currentMoment.add(15, 'minutes');
                        segments[time][activityId].assigned += assignIncrement;
                        segments[time][activityId].unassigned += unassignIncrement;
                    }
                });
            });
            // console.timeEnd('calculate segment counts')

            // console.time('populate segment activities')
            var data = generateBase(date, activities, displayStart, displayEnd).map(function (segment) {
                Object.keys(segment.activities).map(function (key, index) {
                    var unix = segment.moment.unix();

                    if (segments[unix] && segments[unix][key]) {
                        segment.activities[key] = segments[unix][key];
                    }
                });
                return segment;
            });
            // console.timeEnd('populate segment activities')

            var scale = generateScale(data, optimal);

            // console.time('clean segments')
            data = data.map(function (segment) {
                var total = 0;
                var o = void 0;
                var d = void 0;
                var mmt = segment.moment;
                Object.keys(segment.activities).map(function (key, index) {
                    var segmentActivity = segment.activities[key];
                    total += segmentActivity.assigned + segmentActivity.unassigned;
                });

                if (dotw) {
                    d = dotw;
                } else {
                    d = (0, _dateHelper.getDayOfClientWeek)(mmt) + 1;
                }

                // NOTE: REGARDING WEEKLY CHART DISPLAY
                // The below if/else changes the per 15 minute display to hourly.
                // Therefore, it renders a segment per hour, and needs to get an
                // average for that hour. To remove this logic and display
                // consistently, take out the else statement.
                if (date) {
                    o = optimal[d] && optimal[d][mmt.hours()] && optimal[d][mmt.hours()][mmt.minutes()] ? optimal[d][mmt.hours()][mmt.minutes()] : { value: 0, prev: 0, next: 0 };
                } else {
                    var hours = mmt.hours();
                    var prevHour = getHourlyAverageOptimal(optimal, d, mmt.clone().hours(hours - 1));
                    var current = getHourlyAverageOptimal(optimal, d, mmt.clone());
                    var nextHour = getHourlyAverageOptimal(optimal, d, mmt.clone().hours(hours + 1));

                    o = {
                        value: current,
                        prev: prevHour - current,
                        next: nextHour - current
                    };
                }

                segment.optimal = o;
                segment.remainder = scale.max - segment.optimal.value;
                // console.log(mmt.hours(), mmt.minutes(),  segment.optimal, segment.remainder)

                return segment;
            });
            // console.timeEnd('clean segments')

            var chartData = {
                segments: data,
                scale: scale
            };

            if (!date) {
                this.set('cachedData', chartData);
            }

            return chartData;
        })
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});