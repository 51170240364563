define('matrix-frontend/utils/enum', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    var EnumSymbol = function () {
        function EnumSymbol(name, _ref) {
            var value = _ref.value,
                description = _ref.description;

            _classCallCheck(this, EnumSymbol);

            this.sym = Symbol.for(name);
            // value: number
            // description: string

            if (!Object.is(value, undefined)) this.value = value;
            if (description) this.description = description;

            Object.freeze(this);
        }

        _createClass(EnumSymbol, [{
            key: 'toString',
            value: function toString() {
                return this.sym;
            }
        }, {
            key: 'valueOf',
            value: function valueOf() {
                return this.value;
            }
        }, {
            key: 'display',
            get: function get() {
                return this.description || Symbol.keyFor(this.sym);
            }
        }]);

        return EnumSymbol;
    }();

    var Enum = exports.Enum = function () {
        function Enum(enumLiterals) {
            _classCallCheck(this, Enum);

            for (var key in enumLiterals) {
                if (!enumLiterals[key]) throw new TypeError('each enum should have been initialized with atleast empty {} value');
                this[key] = new EnumSymbol(key, enumLiterals[key]);
            }
            Object.freeze(this);
        }

        _createClass(Enum, [{
            key: 'symbols',
            value: function symbols() {
                var _this = this;

                // return [for (key of Object.keys(this)) this[key] ]
                return Object.keys(this).map(function (k) {
                    return _this[k];
                });
            }
        }, {
            key: 'keys',
            value: function keys() {
                return Object.keys(this);
            }
        }, {
            key: 'contains',
            value: function contains(sym) {
                if (!(sym instanceof EnumSymbol)) return false;
                return this[Symbol.keyFor(sym.sym)] === sym;
            }
        }]);

        return Enum;
    }();
});