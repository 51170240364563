define('matrix-frontend/components/react-only/summary-row', ['exports', 'matrix-frontend/store', 'matrix-frontend/actions/_types', 'react', 'matrix-frontend/constants', 'npm:react-tooltip', 'matrix-frontend/utils/format-helpers'], function (exports, _store, _types, _react, _constants, _npmReactTooltip, _formatHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.SummaryRow = exports.SummaryCell = exports.FakeCell = exports.FakeRow = undefined;

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var FakeRow = exports.FakeRow = function FakeRow(props) {
        return _react.default.createElement(
            'div',
            { className: 'row', style: props.style },
            _react.default.createElement(
                'div',
                { className: 'wrapper f2' },
                _react.default.createElement('div', { className: 'column text f1 fake-text gray-text' })
            ),
            _react.default.createElement(
                'div',
                { className: 'wrapper f9' },
                _react.default.createElement('div', { className: 'column text f1 fake-text gray-lighten-2-text' }),
                _react.default.createElement('div', { className: 'column text f1 fake-text gray-lighten-2-text' }),
                _react.default.createElement('div', { className: 'column text f1 fake-text gray-lighten-2-text' }),
                _react.default.createElement('div', { className: 'column text f1 fake-text gray-lighten-2-text' }),
                _react.default.createElement('div', { className: 'column text f1 fake-text gray-lighten-2-text' }),
                _react.default.createElement('div', { className: 'column text f1 fake-text gray-lighten-2-text' }),
                _react.default.createElement('div', { className: 'column text f1 fake-text gray-lighten-2-text' }),
                _react.default.createElement('div', { className: 'column text f1 fake-text gray-lighten-2-text' })
            )
        );
    };

    var truncate = function truncate(text, maxLength) {
        var actual = text.trim();
        if (actual.length <= maxLength) return actual;
        return actual.substring(0, maxLength - 2).trim() + '...';
    };

    var FakeCell = exports.FakeCell = function FakeCell(props) {
        return _react.default.createElement('div', { className: 'column f1 fake-text gray-lighten-2-text data-cell' });
    };

    var SummaryCell = exports.SummaryCell = function (_Component) {
        _inherits(SummaryCell, _Component);

        function SummaryCell() {
            _classCallCheck(this, SummaryCell);

            return _possibleConstructorReturn(this, (SummaryCell.__proto__ || Object.getPrototypeOf(SummaryCell)).apply(this, arguments));
        }

        _createClass(SummaryCell, [{
            key: 'render',
            value: function render() {
                var _this2 = this;

                var _props = this.props,
                    value = _props.value,
                    fill = _props.fill,
                    border = _props.border,
                    dataFor = _props.dataFor,
                    dataTip = _props.dataTip;

                var report = _store.store.getState().reports.report;

                var style = {
                    border: border ? '2px solid ' + border : '',
                    backgroundColor: fill
                };

                var showTooltip = void 0;
                var hideTooltip = void 0;

                var cellAttrs = void 0;
                var contentAttrs = void 0;

                if (report.tooltipContentFor) {
                    cellAttrs = {
                        onMouseEnter: function onMouseEnter(_) {
                            return _npmReactTooltip.default.show(_this2.ref);
                        },
                        onMouseLeave: function onMouseLeave(_) {
                            return _npmReactTooltip.default.hide(_this2.ref);
                        }
                    };
                    contentAttrs = {
                        'data-for': dataFor,
                        'data-tip': dataTip,
                        'data-place': 'bottom'
                    };
                }

                return _react.default.createElement(
                    'div',
                    _extends({ className: 'column f1 data-cell' }, cellAttrs),
                    _react.default.createElement(
                        'div',
                        _extends({
                            className: 'content',
                            style: style,
                            ref: function ref(_ref) {
                                return _this2.ref = _ref;
                            }
                        }, contentAttrs),
                        _react.default.createElement(
                            'span',
                            { className: 'right' },
                            value
                        )
                    )
                );
            }
        }]);

        return SummaryCell;
    }(_react.Component);

    var SummaryRow = exports.SummaryRow = function (_PureComponent) {
        _inherits(SummaryRow, _PureComponent);

        function SummaryRow() {
            _classCallCheck(this, SummaryRow);

            return _possibleConstructorReturn(this, (SummaryRow.__proto__ || Object.getPrototypeOf(SummaryRow)).apply(this, arguments));
        }

        _createClass(SummaryRow, [{
            key: 'render',
            value: function render() {
                var _this4 = this;

                var _props$data = this.props.data,
                    selectBusinessUnit = _props$data.selectBusinessUnit,
                    columns = _props$data.columns,
                    nodes = _props$data.nodes,
                    rollups = _props$data.rollups,
                    minDisplayValue = _props$data.minDisplayValue,
                    maxDisplayValue = _props$data.maxDisplayValue;

                var clientCode = _store.store.getState().cube.clientCode;

                var report = _store.store.getState().reports.report;

                var node = nodes[this.props.index];
                var rollup = rollups['' + node.tag];

                var handleClickName = function handleClickName() {
                    selectBusinessUnit(node.id, node.type);
                };

                var cells = columns.map(function (dc) {
                    if (rollup) {
                        var dcData = rollup[dc.key];
                        if (dcData) {
                            if (dcData.displayValue !== undefined) {
                                var v = Math.round(dcData.displayValue);
                                var rV = void 0;

                                if (v > 0) {
                                    rV = v / maxDisplayValue;
                                } else if (v < 0) {
                                    rV = v / -minDisplayValue;
                                }
                                v = v > 0 ? v.toLocaleString() : v < 0 ? '(' + (-v).toLocaleString() + ')' : '-';

                                var color = report.displayColor(rV) || {
                                    border: '#ccc'
                                };
                                return _react.default.createElement(SummaryCell, { key: dc.key, value: v, fill: color.fill, border: color.border, dataFor: 'dataTooltip', dataTip: node.tag + '|' + dc.key });
                            }
                        }
                        return _react.default.createElement(SummaryCell, { key: dc.key, value: 'N/A', dataFor: 'dataTooltip', dataTip: node.tag + '|' + dc.key, border: '#ccc' });
                    }
                    return _react.default.createElement(FakeCell, { key: dc.key });
                });

                var tooltipAttrs = node.type === 'store' ? {
                    'data-for': 'storeDetailsTooltip',
                    'data-place': 'bottom',
                    'data-tip': node.tag
                } : {
                    'data-for': 'basicTooltip',
                    'data-place': 'bottom',
                    'data-tip': node.name
                };
                var nameText = node.type === 'store' ? node.code + ' ' + _formatHelpers.default.fixAllCaps(node.name) : node.name;

                return _react.default.createElement(
                    'div',
                    { className: 'row', style: this.props.style },
                    _react.default.createElement(
                        'div',
                        { className: 'wrapper f2 pointer', onClick: function onClick(_) {
                                return handleClickName();
                            } },
                        _react.default.createElement(
                            'div',
                            _extends({ ref: function ref(_ref2) {
                                    return _this4.ref = _ref2;
                                }, className: 'column text f6 no-left', onMouseEnter: function onMouseEnter(_) {
                                    return _npmReactTooltip.default.show(_this4.ref);
                                }, onMouseLeave: function onMouseLeave(_) {
                                    return _npmReactTooltip.default.hide(_this4.ref);
                                } }, tooltipAttrs),
                            _react.default.createElement(
                                'span',
                                { className: 'ellipsis-text' },
                                nameText
                            )
                        )
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'wrapper f9' },
                        cells
                    )
                );
            }
        }]);

        return SummaryRow;
    }(_react.PureComponent);
});