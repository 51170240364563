define('matrix-frontend/helpers/calc-weekly-variance', ['exports', 'npm:numbro', 'npm:underscore'], function (exports, _npmNumbro, _npmUnderscore) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.calcWeeklyVariance = calcWeeklyVariance;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    function calcWeeklyVariance(_ref, hash) {
        var _ref2 = _slicedToArray(_ref, 3),
            data = _ref2[0],
            key1 = _ref2[1],
            key2 = _ref2[2];

        _npmNumbro.default.zeroFormat('');
        var prefix = hash['prefix'] === undefined ? '' : hash['prefix'];
        var suffix = hash['suffix'] === undefined ? '' : hash['suffix'];

        var key1Total = 0;
        var key2Total = 0;
        var numeralFormat = '+0,0.0';

        _npmUnderscore.default.each(data, function (item) {
            if (item[key1] !== 0) {
                key1Total += item[key1];
                key2Total += item[key2];
            }
        });

        var varValue = key1Total - key2Total;

        if (hash['format'] === 'diff') {
            numeralFormat = '(0,0)';

            return varValue === 0 ? '' : (0, _npmNumbro.default)(varValue).format({
                thousandSeparated: true,
                mantissa: 0,
                negative: 'parenthesis'
            });
        } else {
            var varPct = key1Total === 0 ? '' : Math.round(varValue / key2Total * 100 * 10) / 10;
            var returnValue = key1Total === 0 ? '' : (0, _npmNumbro.default)(varPct).format(numeralFormat);
            if (!Ember.isEmpty(returnValue)) {
                return '' + prefix + returnValue + suffix;
            }
        }
    }

    exports.default = Ember.Helper.helper(calcWeeklyVariance);
});