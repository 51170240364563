define('matrix-frontend/components/react-only/payroll-worksheet-summary-row', ['exports', 'react', 'npm:moment', 'npm:react-tooltip', 'matrix-frontend/utils/payroll-worksheet', 'matrix-frontend/utils/state', 'matrix-frontend/utils/format-helpers'], function (exports, _react, _npmMoment, _npmReactTooltip, _payrollWorksheet, _state, _formatHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.HierarchyRow = exports.HeaderRow = exports.FakeRow = undefined;

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var NotApplicableCell = function NotApplicableCell(props) {
        return _react.default.createElement(
            'div',
            { className: 'not-applicable' },
            'N/A'
        );
    };

    var FakeRow = exports.FakeRow = function FakeRow(props) {
        return _react.default.createElement(
            'div',
            { className: 'row', style: props.style },
            _react.default.createElement(
                'div',
                { className: 'wrapper f1' },
                _react.default.createElement('div', { className: 'column f1 fake-text gray-text' })
            ),
            _react.default.createElement(
                'div',
                { className: 'wrapper f2' },
                _react.default.createElement('div', { className: 'column f2 fake-text gray-lighten-2-text' }),
                _react.default.createElement('div', { className: 'column f1 fake-text gray-lighten-2-text' }),
                _react.default.createElement('div', { className: 'column f1 fake-text gray-lighten-2-text' }),
                _react.default.createElement('div', { className: 'column f1 fake-text gray-lighten-2-text' })
            ),
            _react.default.createElement(
                'div',
                { className: 'wrapper f2' },
                _react.default.createElement('div', { className: 'column f2 fake-text gray-lighten-2-text' }),
                _react.default.createElement('div', { className: 'column f1 fake-text gray-lighten-2-text' }),
                _react.default.createElement('div', { className: 'column f1 fake-text gray-lighten-2-text' }),
                _react.default.createElement('div', { className: 'column f1 fake-text gray-lighten-2-text' })
            )
        );
    };

    var VariancePercentIndicatorCell = function VariancePercentIndicatorCell(props) {
        var a = props.a,
            b = props.b,
            styleForPercentFunc = props.styleForPercentFunc,
            positiveIsGood = props.positiveIsGood;


        if (a === undefined || b === undefined) {
            return _react.default.createElement('div', { className: 'column f2 fake-text gray-lighten-2-text' });
        }

        var style = void 0;
        var content = void 0;
        var classList = ['solid-text-block'];

        if (a != null && b != null && b != 0) {
            var percent = a / b - 1;
            style = styleForPercentFunc(percent);
            content = (0, _formatHelpers.percentFormat)(Math.min(percent, 99.999));
            var x = positiveIsGood ? percent : -percent;
            if (x >= 10) {
                classList.push('super-good');
                style.background = undefined;
            } else if (-x >= 10) {
                classList.push('super-bad');
                style.background = undefined;
            }
        } else {
            style = {
                background: '#ddd'
            };
            content = 'N/A';
        }

        return _react.default.createElement(
            'div',
            { className: 'column f2' },
            _react.default.createElement(
                'div',
                { className: classList.join(' '), style: style },
                content
            )
        );
    };

    var SalesIndicatorCell = function SalesIndicatorCell(props) {
        var sales = props.sales,
            forecast = props.forecast;


        var styleForPercent = function styleForPercent(percent) {
            var color = (0, _payrollWorksheet.getSalesIndicatorColor)(percent);
            var useDark = -0.15 < percent && percent <= 0;
            return {
                background: color,
                color: useDark ? 'rgba(0,0,0,0.5)' : '#fff'
            };
        };

        return _react.default.createElement(VariancePercentIndicatorCell, { a: sales, b: forecast, styleForPercentFunc: styleForPercent, positiveIsGood: true });
    };

    var HoursIndicatorCell = function HoursIndicatorCell(props) {
        var actual = props.actual,
            plan = props.plan;


        var styleForPercent = function styleForPercent(percent) {
            var color = (0, _payrollWorksheet.getHoursIndicatorColor)(percent);
            var useDark = 0 <= percent && percent < 0.3;
            return {
                background: color,
                color: useDark ? 'rgba(0,0,0,0.5)' : '#fff'
            };
        };

        return _react.default.createElement(VariancePercentIndicatorCell, { a: actual, b: plan, styleForPercentFunc: styleForPercent });
    };

    var AggregateSalesIndicatorCell = function AggregateSalesIndicatorCell(props) {
        var counts = props.counts,
            total = props.total;


        var tooltipData = {
            counts: counts,
            total: total
        };

        var tooltipRef = (0, _react.useRef)();
        var showTooltip = function showTooltip(_) {
            return _npmReactTooltip.default.show(tooltipRef.current);
        };
        var hideTooltip = function hideTooltip(_) {
            return _npmReactTooltip.default.hide(tooltipRef.current);
        };

        if (total == 0) {
            return _react.default.createElement('div', { className: 'column f2' });
        }

        var categories = Object.keys(counts);
        if (categories.length == 0) {
            return _react.default.createElement('div', { className: 'column f2 fake-text gray-lighten-2-text' });
        }

        var zeroIndicator = _react.default.createElement('div', { className: 'zero-indicator' });

        var underBlocks = categories.filter(function (x) {
            return x[0] === '-';
        }).sort(function (a, b) {
            return -(parseFloat(a) - parseFloat(b));
        }).map(function (key, i) {
            var category = (0, _payrollWorksheet.getKeyedSalesVarianceCategory)(key);
            var color = category.color;
            return _react.default.createElement('div', { key: i, className: 'solid-empty-block', style: {
                    background: color,
                    flexGrow: counts[key]
                } });
        });

        var invalidBlock = counts[null] ? _react.default.createElement('div', { key: 'null', className: 'solid-empty-block', style: {
                background: '#ddd',
                flexGrow: counts[null]
            } }) : null;

        var overBlocks = categories.filter(function (x) {
            return x[0] === '+';
        }).sort(function (a, b) {
            return -(parseFloat(a) - parseFloat(b));
        }).map(function (key, i) {
            var category = (0, _payrollWorksheet.getKeyedSalesVarianceCategory)(key);
            var color = category.color;
            return _react.default.createElement('div', { key: i, className: 'solid-empty-block', style: {
                    background: color,
                    flexGrow: counts[key]
                } });
        });

        return _react.default.createElement(
            'div',
            { className: 'column f2', 'data-for': 'aggregateSalesVariance', 'data-place': 'bottom', 'data-tip': JSON.stringify(tooltipData) },
            _react.default.createElement(
                'div',
                { className: 'solid-empty-block-wrapper no-pointer-events' },
                overBlocks,
                invalidBlock ? _react.default.createElement(
                    _react.Fragment,
                    null,
                    zeroIndicator,
                    invalidBlock,
                    zeroIndicator
                ) : zeroIndicator,
                underBlocks
            )
        );
    };

    var AggregateHoursIndicatorCell = function AggregateHoursIndicatorCell(props) {
        var counts = props.counts,
            total = props.total;


        var tooltipData = {
            counts: counts,
            total: total
        };

        var tooltipRef = (0, _react.useRef)();
        var showTooltip = function showTooltip(_) {
            return _npmReactTooltip.default.show(tooltipRef.current);
        };
        var hideTooltip = function hideTooltip(_) {
            return _npmReactTooltip.default.hide(tooltipRef.current);
        };

        if (total == 0) {
            return _react.default.createElement('div', { className: 'column f2' });
        }

        var categories = Object.keys(counts);
        if (categories.length == 0) {
            return _react.default.createElement('div', { className: 'column f2 fake-text gray-lighten-2-text' });
        }

        var zeroIndicator = _react.default.createElement('div', { className: 'zero-indicator' });

        var underBlocks = categories.filter(function (x) {
            return x[0] === '-';
        }).sort(function (a, b) {
            return parseFloat(a) - parseFloat(b);
        }).map(function (key, i) {
            var category = (0, _payrollWorksheet.getKeyedHoursVarianceCategory)(key);
            var color = category.color;
            return _react.default.createElement('div', { key: i, className: 'solid-empty-block', style: {
                    background: color,
                    flexGrow: counts[key]
                } });
        });

        var invalidBlock = counts[null] ? _react.default.createElement('div', { key: 'null', className: 'solid-empty-block', style: {
                background: '#ddd',
                flexGrow: counts[null]
            } }) : null;

        var overBlocks = categories.filter(function (x) {
            return x[0] === '+';
        }).sort(function (a, b) {
            return parseFloat(a) - parseFloat(b);
        }).map(function (key, i) {
            var category = (0, _payrollWorksheet.getKeyedHoursVarianceCategory)(key);
            var color = category.color;
            return _react.default.createElement('div', { key: i, className: 'solid-empty-block', style: {
                    background: color,
                    flexGrow: counts[key]
                } });
        });

        return _react.default.createElement(
            'div',
            { className: 'column f2', onMouseEnter: showTooltip, onMouseLeave: hideTooltip },
            _react.default.createElement(
                'div',
                { className: 'solid-empty-block-wrapper no-pointer-events', ref: tooltipRef, 'data-for': 'aggregateHoursVariance', 'data-place': 'bottom', 'data-tip': JSON.stringify(tooltipData) },
                underBlocks,
                invalidBlock ? _react.default.createElement(
                    _react.Fragment,
                    null,
                    zeroIndicator,
                    invalidBlock,
                    zeroIndicator
                ) : zeroIndicator,
                overBlocks
            )
        );
    };

    var DataCell = function DataCell(_ref) {
        var data = _ref.data,
            prefix = _ref.prefix,
            _ref$classText = _ref.classText,
            classText = _ref$classText === undefined ? 'column f1' : _ref$classText;

        if (data === undefined) {
            return _react.default.createElement('div', { className: classText + ' fake-text gray-lighten-2-text' });
        }
        return _react.default.createElement(
            'div',
            { className: classText },
            (0, _formatHelpers.numberFormat)(data, { prefix: prefix })
        );
    };

    var HeaderRow = exports.HeaderRow = function (_PureComponent) {
        _inherits(HeaderRow, _PureComponent);

        function HeaderRow() {
            _classCallCheck(this, HeaderRow);

            return _possibleConstructorReturn(this, (HeaderRow.__proto__ || Object.getPrototypeOf(HeaderRow)).apply(this, arguments));
        }

        _createClass(HeaderRow, [{
            key: 'componentDidMount',
            value: function componentDidMount() {
                _npmReactTooltip.default.rebuild();
            }
        }, {
            key: 'componentDidUpdate',
            value: function componentDidUpdate() {
                _npmReactTooltip.default.rebuild();
            }
        }, {
            key: 'render',
            value: function render() {
                var _props = this.props,
                    showToggle = _props.showToggle,
                    subview = _props.subview,
                    onToggle = _props.onToggle,
                    rollup = _props.rollup,
                    timeColumn = _props.timeColumn;


                var toggle = showToggle && rollup ? _react.default.createElement(
                    'a',
                    { onClick: function onClick(_) {
                            return onToggle();
                        } },
                    subview === 'node' ? 'Show by store' : 'Show by hierarchy'
                ) : null;

                var storeCountText = rollup ? rollup.storeCount + ' ' + (rollup.storeCount != 1 ? 'stores' : 'store') : '0 stores';
                var totalText = 'Total (' + storeCountText + ')';

                return _react.default.createElement(
                    'div',
                    { className: 'row' },
                    _react.default.createElement(
                        'div',
                        { className: 'wrapper f1' },
                        _react.default.createElement(
                            'div',
                            { className: 'column f1 header-title-cell' },
                            totalText,
                            toggle
                        )
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'wrapper f2' },
                        rollup ? _react.default.createElement(
                            _react.Fragment,
                            null,
                            _react.default.createElement(AggregateSalesIndicatorCell, { counts: rollup.sales.categoryCounts, total: rollup.storeCount }),
                            _react.default.createElement(DataCell, { data: timeColumn === 'total' ? rollup.sales.actualOrCurrent : rollup.sales.actualOrCurrent(), prefix: '$' }),
                            _react.default.createElement(DataCell, { data: rollup.sales.originalForecast, prefix: '$' }),
                            _react.default.createElement(DataCell, { data: rollup.sales.actualOrCurrentVsOriginalForecast.variance(), prefix: '$' })
                        ) : _react.default.createElement('div', { className: 'column f1' })
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'wrapper f2' },
                        rollup ? _react.default.createElement(
                            _react.Fragment,
                            null,
                            _react.default.createElement(AggregateHoursIndicatorCell, { counts: rollup.hours.categoryCounts, total: rollup.storeCount }),
                            _react.default.createElement(DataCell, { data: timeColumn === 'total' ? rollup.hours.actualOrSchedule : rollup.hours.actualOrSchedule() }),
                            _react.default.createElement(DataCell, { data: rollup.storeCount == 0 ? null : rollup.hours.planTest.total }),
                            _react.default.createElement(DataCell, { data: rollup.hours.actualOrScheduleVsPlan.variance() })
                        ) : _react.default.createElement('div', { className: 'column f1' })
                    )
                );
            }
        }]);

        return HeaderRow;
    }(_react.PureComponent);

    var HierarchyRow = exports.HierarchyRow = function (_PureComponent2) {
        _inherits(HierarchyRow, _PureComponent2);

        function HierarchyRow() {
            _classCallCheck(this, HierarchyRow);

            return _possibleConstructorReturn(this, (HierarchyRow.__proto__ || Object.getPrototypeOf(HierarchyRow)).apply(this, arguments));
        }

        _createClass(HierarchyRow, [{
            key: 'componentDidMount',
            value: function componentDidMount() {
                _npmReactTooltip.default.rebuild();
            }
        }, {
            key: 'componentDidUpdate',
            value: function componentDidUpdate() {
                _npmReactTooltip.default.rebuild();
            }
        }, {
            key: 'render',
            value: function render() {
                var _props2 = this.props,
                    style = _props2.style,
                    hierarchy = _props2.hierarchy,
                    rollup = _props2.rollup,
                    selectBusinessUnit = _props2.selectBusinessUnit,
                    timeColumn = _props2.timeColumn;


                var isStore = hierarchy.type === 'store';
                if (!rollup) return null;

                var tooltipAttrs = isStore ? {
                    'data-for': 'storeDetailsTooltip',
                    'data-place': 'bottom',
                    'data-tip': hierarchy.tag
                } : null;

                return _react.default.createElement(
                    'div',
                    { className: 'row', style: style },
                    _react.default.createElement(
                        'div',
                        { className: 'wrapper f1' },
                        _react.default.createElement(
                            'div',
                            _extends({ className: 'column f1 text pointer', onClick: function onClick(_) {
                                    return selectBusinessUnit(hierarchy.id, hierarchy.type);
                                } }, tooltipAttrs),
                            isStore ? hierarchy.code + ' ' + _formatHelpers.default.fixAllCaps(hierarchy.name) : hierarchy.name
                        )
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'wrapper f2' },
                        isStore ? _react.default.createElement(SalesIndicatorCell, { sales: rollup.sales.actualOrCurrent, forecast: rollup.sales.originalForecast }) : _react.default.createElement(AggregateSalesIndicatorCell, { counts: rollup.sales.categoryCounts, total: rollup.storeCount }),
                        _react.default.createElement(DataCell, { data: isStore || timeColumn === 'total' ? rollup.sales.actualOrCurrent : rollup.sales.actualOrCurrent(), prefix: '$' }),
                        _react.default.createElement(DataCell, { data: rollup.sales.originalForecast, prefix: '$' }),
                        _react.default.createElement(DataCell, { data: rollup.sales.actualOrCurrentVsOriginalForecast.variance(), prefix: '$' })
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'wrapper f2' },
                        isStore ? _react.default.createElement(HoursIndicatorCell, { actual: rollup.hours.actualOrSchedule, plan: rollup.hours.planTest.total }) : _react.default.createElement(AggregateHoursIndicatorCell, { counts: rollup.hours.categoryCounts, total: rollup.storeCount }),
                        _react.default.createElement(DataCell, { data: isStore || timeColumn === 'total' ? rollup.hours.actualOrSchedule : rollup.hours.actualOrSchedule() }),
                        _react.default.createElement(DataCell, { data: rollup.storeCount == 0 ? null : rollup.hours.planTest.total }),
                        _react.default.createElement(DataCell, { data: rollup.hours.actualOrScheduleVsPlan.variance() })
                    )
                );
            }
        }]);

        return HierarchyRow;
    }(_react.PureComponent);
});