define('matrix-frontend/pods/components/schedule-print-page/component', ['exports', 'ember-redux', 'matrix-frontend/actions/_types', 'npm:moment'], function (exports, _emberRedux, _types, _npmMoment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var stateToComputed = function stateToComputed(state) {
        return {
            // // currentBrush: state.scheduleEdit.currentBrush,
            // // lastExpandedRow: state.scheduleEdit.lastExpandedRow,
            // // expandedRow: state.scheduleEdit.expandedRow,
            // // shifts: state.scheduleEdit.shifts,
            // // startLeft: state.scheduleEdit.startLeft,
            // // startTop: state.scheduleEdit.startTop,
            // displayStart: state.scheduleEdit.displayStart,
            // displayEnd: state.scheduleEdit.displayEnd,
            // // editMode: state.scheduleEdit.editMode,
            // date: state.cube.date,
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {};
    };

    var Comp = Ember.Component.extend({
        days: Ember.computed(function () {
            var raw = [].concat(_toConsumableArray(Array(7).keys()));
            return raw.map(function (x) {
                return x + 1;
            });
        })

        // dailyShifts: computed('unassignedShifts', function () {
        //     const unassignedShifts = this.get('unassignedShifts')
        //     const date = this.get('date')

        //     if (!date || !unassignedShifts) {
        //         return null
        //     }

        //     const dotw = parseInt(date.format('d')) + 1
        //     return unassignedShifts[dotw]
        // }),

        // rowShifts: computed('unassignedShifts', function () {
        //     const unassignedShifts = this.get('unassignedShifts')
        //     const index = this.get('index') - 1
        //     const rowShifts = Object.keys(unassignedShifts).map(
        //         x => unassignedShifts[x][index] || null,
        //     )

        //     let i = 1
        //     return rowShifts.reduce((x, y) => {
        //         x[i] = y
        //         i += 1
        //         return x
        //     }, {})
        // }),
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});