define('matrix-frontend/reducers/employee-panel', ['exports', 'matrix-frontend/actions/_types'], function (exports, _types) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var initialState = {
        displayPanel: false,
        employeeId: null,
        pinned: false,
        dotw: null
    };

    var schedule = function schedule(state, action) {
        var type = action.type,
            payload = action.payload;


        switch (type) {
            case _types.EP_SET_EMPLOYEE_PANEL:
                {
                    var employee = payload.employee,
                        displayPanel = payload.displayPanel;

                    var employeeId = employee ? employee.id : null;
                    return _extends({}, state, {
                        displayPanel: displayPanel,
                        employeeId: employeeId
                    });
                }

            case _types.EP_PIN:
                {
                    var _employee = payload.employee;

                    return _extends({}, state, {
                        employeeId: _employee.id,
                        displayPanel: true,
                        pinned: true
                    });
                }

            case _types.EP_UNPIN:
                {
                    return _extends({}, state, {
                        displayPanel: false,
                        employeeId: null,
                        pinned: false
                    });
                }

            case _types.EP_SET:
                {
                    return _extends({}, state, payload);
                }

            default:
                {
                    return state || initialState;
                }
        }
    };

    exports.default = schedule;
});