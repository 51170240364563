define('matrix-frontend/models/employee', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        client: _emberData.default.belongsTo('client'),
        position: _emberData.default.attr('number', { defaultValue: 0 }),
        contactInfo: _emberData.default.belongsTo('contact-info'),
        employeeCode: _emberData.default.attr('string'),
        firstName: _emberData.default.attr('string'),
        lastName: _emberData.default.attr('string'),
        middleInitial: _emberData.default.attr('string'),
        dateOfBirth: _emberData.default.attr('date'),
        isScheduleApprover: _emberData.default.attr('boolean')
    });
});