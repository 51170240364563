define('matrix-frontend/components/react-only/employee-availability-cell', ['exports', 'react', 'npm:moment', 'matrix-frontend/utils/date-helper'], function (exports, _react, _npmMoment, _dateHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var LoadingCell = function LoadingCell(_) {
        return _react.default.createElement(
            'div',
            { className: 'cell' },
            _react.default.createElement(
                'div',
                { className: 'loading' },
                _react.default.createElement('i', { className: 'fa fa-spinner fa-spin' })
            )
        );
    };

    var FailedCell = function FailedCell(_) {
        return _react.default.createElement(
            'div',
            { className: 'cell' },
            _react.default.createElement(
                'div',
                { className: 'failed' },
                _react.default.createElement('i', { className: 'fa fa-times' })
            )
        );
    };

    var comp = function comp(props) {
        var availability = props.availability,
            employeeId = props.employeeId,
            openAvailabilityModal = props.openAvailabilityModal,
            startDate = props.startDate,
            terminationDate = props.terminationDate,
            isLoan = props.isLoan,
            currentStoreCode = props.currentStoreCode;


        var compiled = Object.values(availability).map(function (x, idx) {
            var mmt = (0, _dateHelper.dotwToMoment)(x[0].dotw);
            var tooltip = mmt.format('ddd, MMM Do');

            if (startDate && (0, _npmMoment.default)(startDate).isAfter(mmt, 'day') || terminationDate && (0, _npmMoment.default)(terminationDate).isBefore(mmt, 'day')) {
                return {
                    tooltip: tooltip,
                    loading: false,
                    failed: false,
                    passthru: {
                        className: ['empty']
                    }
                };
            }

            var className = (x.every(function (y) {
                return y.isAvailable && y.fromOpen && y.untilClose;
            }) ? 'pointer full' : x.every(function (y) {
                return !y.isAvailable;
            }) ? x.some(function (y) {
                return y.reasonCode && y.reasonCode !== 'Y';
            }) ? 'pointer unavailable' : x.some(function (y) {
                return y.reasonCode && y.reasonCode === 'Y' || y.activityCode;
            }) ? 'pointer unavailable rotation' : 'pointer unavailable not-available' : 'pointer partial') + (isLoan ? ' is-loan' : '') + (currentStoreCode !== x[0].storeCode ? ' is-on-loan' : '');

            return {
                tooltip: tooltip,
                loading: x.loading,
                failed: x.failed,
                passthru: {
                    className: className,
                    onClick: function onClick() {
                        openAvailabilityModal(employeeId, x[0].dotw);
                    }
                }
            };
        });

        return _react.default.createElement(
            'div',
            { className: 'info-cell' },
            compiled.map(function (x, i) {
                return x.loading ? _react.default.createElement(LoadingCell, { key: i }) : x.failed ? _react.default.createElement(FailedCell, { key: i }) : _react.default.createElement(
                    'div',
                    { key: i, className: 'cell', 'data-tooltip': x.tooltip },
                    _react.default.createElement('div', x.passthru)
                );
            })
        );
    };

    exports.default = comp;
});