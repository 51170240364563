define('matrix-frontend/helpers/style-generator', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.styleGenerator = styleGenerator;

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    function styleGenerator(params /*, hash*/) {
        var values = [].concat(_toConsumableArray(params));
        var defs = values.shift().split('|');
        var output = [];

        defs.forEach(function (x, i) {
            var parts = x.split(':');
            var prefix = parts[0];
            var suffix = parts.length === 2 ? parts[1] : '';
            output.push(prefix + ':' + values[i] + suffix);
        });

        return Ember.String.htmlSafe(output.join(';'));
    }

    exports.default = Ember.Helper.helper(styleGenerator);
});