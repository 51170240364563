define('matrix-frontend/pods/components/date-picker/component', ['exports', 'ember-redux', 'npm:moment', 'matrix-frontend/config/environment', 'npm:flatpickr', 'matrix-frontend/utils/flatpickr/weekSelect', 'matrix-frontend/utils/date-helper'], function (exports, _emberRedux, _npmMoment, _environment, _npmFlatpickr, _weekSelect, _dateHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var stateToComputed = function stateToComputed(state) {
        return {
            currentUser: state.user.currentUser,
            clientWeekStartDay: state.cube.clientWeekStartDay
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {};
    };

    var Comp = Ember.Component.extend({
        router: Ember.inject.service('-routing'),
        // stateService: service(),
        saveService: Ember.inject.service(),
        notificationService: Ember.inject.service(),

        tagName: 'span',
        weekEndDateSelector: null,
        showSelector: false,

        dateRangeEnd: Ember.computed('date', function () {
            return (0, _npmMoment.default)(this.get('date')).format('MM/DD');
        }),

        dateRangeStart: Ember.computed('date', function () {
            return (0, _npmMoment.default)(this.get('date')).subtract(6, 'days').format('MM/DD');
        }),

        previousWeek: Ember.computed('date', function () {
            var earliestWeekEnd = this.get('earliestWeekEnd');
            var previous = (0, _npmMoment.default)(this.get('date')).subtract(7, 'days');
            return previous.isAfter(earliestWeekEnd) ? previous : null;
        }),

        currentWeekEndDate: Ember.computed('clientWeekStartDay', function () {
            return (0, _dateHelper.getClientWeekEndDate)((0, _npmMoment.default)());
        }),

        earliestWeekEnd: Ember.computed('currentWeekEndDate', function () {
            var thisWeekEnd = this.get('currentWeekEndDate');
            return thisWeekEnd.subtract(_environment.default.weeksHistory, 'weeks');
        }),

        nextWeek: Ember.computed('date', function () {
            return (0, _npmMoment.default)(this.get('date')).add(7, 'days');
        }),

        goToWeek: function goToWeek(week) {
            this.saveService.doAction(Ember.$('#' + week + 'Week').click.bind(Ember.$('#' + week + 'Week')));
        },


        didInsertElement: function didInsertElement() {
            var _this = this;

            this.weekEndDateSelector = (0, _npmFlatpickr.default)('#weekEndDateSelector', {
                plugins: [new _weekSelect.default({})],
                dateFormat: 'Y-m-d',
                // weekNumbers: true,
                minDate: this.earliestWeekEnd._d,
                clickOpens: false,
                onChange: function onChange(e, __, instance) {
                    var date = (0, _npmMoment.default)(instance.weekEndDay).format('YYYY-MM-DD');

                    _this.saveService.doTransition(_this.router.transitionTo({
                        queryParams: {
                            date: date
                        }
                    }));
                },
                locale: {
                    firstDayOfWeek: this.clientWeekStartDay - 1
                }
            });
        },

        actions: {
            displaySelector: function displaySelector() {
                this.weekEndDateSelector.open();
            },
            goToPreviousWeek: function goToPreviousWeek() {
                this.goToWeek('previous');
            },
            goToNextWeek: function goToNextWeek() {
                this.goToWeek('next');
            }
        }
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});