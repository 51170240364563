define('matrix-frontend/middleware/websocket', ['exports', 'matrix-frontend/actions/_types', 'matrix-frontend/config/environment', 'matrix-frontend/services/notification-service', 'matrix-frontend/utils/backoff', 'matrix-frontend/utils/socket', 'matrix-frontend/utils/interactions', 'matrix-frontend/utils/cleaners'], function (exports, _types, _environment, _notificationService, _backoff, _socket, _interactions, _cleaners) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    // import ReconnectingWebSocket from 'npm:reconnecting-websocket';
    var beforeListeners = {};
    var afterListeners = {};

    var WS_TIMEOUT = 7000;

    beforeListeners[_types.WS_CONNECT] = function (store, payload) {
        console.debug('WS_CONNECT');

        store.dispatch({
            type: _types.WS_STATUS,
            payload: { status: WebSocket.CONNECTING }
        });

        var token = payload.token,
            mxUsername = payload.mxUsername,
            mxCompany = payload.mxCompany;

        var socket = (0, _socket.make_connection)(token, mxUsername, mxCompany);

        setTimeout(function () {
            console.debug('Checking websocket status:', store.getState().websocket.status);
            if (store.getState().websocket.status !== WebSocket.OPEN) {
                store.dispatch({
                    type: _types.WS_STATUS,
                    payload: { status: 99 }
                });
                console.warn('Failed to create connection');
            }
        }, WS_TIMEOUT);

        socket.onopen = function () {
            store.dispatch({
                type: _types.WS_STATUS,
                payload: { status: WebSocket.OPEN }
            });
            store.dispatch({
                type: _types.WS_OPENED,
                payload: {
                    socket: socket
                }
            });

            // If there already is a weekEndDate and node, then register a feed
            // This should capture instances when there is an unintended
            // disconnection, and the socket needs to reconnect
            if (store.getState().cube.weekEndDate && store.getState().cube.node) {
                store.dispatch({
                    type: _types.WS_REGISTER
                });
            }
        };
        socket.onmessage = function (e) {
            store.dispatch({
                type: _types.WS_RECEIVE,
                payload: JSON.parse(e.data)
            });
        };
        socket.onerror = function (e) {
            console.error(e);
        };
        socket.onclose = function (e) {
            store.dispatch({
                type: _types.WS_CLOSED
            });
            store.dispatch({
                type: _types.WS_STATUS,
                payload: { status: WebSocket.CLOSED }
            });
            console.debug('closed connection');
        };
    };
    beforeListeners[_types.WS_REGISTER] = function (store) {
        console.debug('REGISTER');

        var _store$getState = store.getState(),
            currentSocket = _store$getState.websocket.currentSocket,
            _store$getState$cube = _store$getState.cube,
            weekEndDate = _store$getState$cube.weekEndDate,
            node = _store$getState$cube.node;

        store.dispatch({
            type: _types.WS_RESET_FEED
        });

        if (weekEndDate && node) {
            var newFeed = (0, _socket.make_feed)(node, weekEndDate);
            var intended = store.getState().websocket.feed.intended;
            console.debug('\tnewFeed', newFeed);
            console.debug('\tintended', intended);
            console.debug('\tnot?', newFeed !== intended);

            // Check to make sure that we are not connecting to something twice
            if (newFeed !== intended) {
                console.debug('Start subscription backoff');

                (0, _backoff.generalBackoff)(function () {
                    // Wait until there is a connection, and then register
                    console.debug('Checking subscribe');

                    var _store$getState2 = store.getState(),
                        _store$getState2$webs = _store$getState2.websocket,
                        currentSocket = _store$getState2$webs.currentSocket,
                        status = _store$getState2$webs.status;

                    return currentSocket && status === WebSocket.OPEN;
                }, function () {
                    console.debug('READY TO REGISTER: ' + newFeed);

                    // Before actually registering, double check that
                    // we need to and that there is not already a connection
                    if (weekEndDate === store.getState().cube.weekEndDate && node === store.getState().cube.node && newFeed !== store.getState().websocket.feed.intended) {
                        console.debug('DOING REGISTRATION: ' + newFeed);
                        store.dispatch({
                            type: _types.WS_SWITCH_TO_FEED,
                            payload: {
                                feed: newFeed
                            }
                        });
                        store.dispatch({
                            type: _types.WS_SEND,
                            payload: {
                                header: 'register',
                                body: newFeed
                            }
                        });
                    } else {
                        console.debug('abort stale registration: ' + newFeed);
                    }
                }, 'SETTING UP SOCKET CONNECTION', 15, true);
            }
        }
    };
    // beforeListeners[types.WS_UNSUBSCRIBE] = (store, payload) => {
    //     const {
    //         websocket: {currentSocket},
    //     } = store.getState()
    //     if (currentSocket && currentSocket.close) {
    //         try {
    //             const notice = JSON.stringify({})
    //             currentSocket.send(notice)
    //             currentSocket.close()
    //         } catch (e) {
    //             console.error(e)
    //         }
    //     }
    // }
    // beforeListeners[types.WS_SUBSCRIBE] = (store, payload) => {
    //     payload = payload || {}
    //     console.debug('!!! WS_SUBSCRIBE !!!', payload)
    //     setStore(store)
    //     // console.group('WS_SUBSCRIBE')

    //     const {
    //         websocket: {currentSocket},
    //         cube: {weekEndDate, node},
    //     } = store.getState()

    //     console.debug('weekEndDate', weekEndDate)

    //     if (weekEndDate && node) {
    //         const newFeed = make_feed(node, weekEndDate)
    //         const intended = store.getState().websocket.feed.intended
    //         console.debug('\tnewFeed', newFeed)
    //         console.debug('\tintended', intended)
    //         console.debug('\tnot?', newFeed !== intended)
    //         if (newFeed !== intended) {
    //             console.debug("Start subscription backoff")
    //             generalBackoff(
    //                 () => {
    //                     console.debug("Checking subscribe")
    //                     return store.getState().user.currentUser
    //                 },
    //                 () => {
    //                     store.dispatch({
    //                         type: types.WS_SWITCH_TO_FEED,
    //                         payload: {
    //                             feed: newFeed,
    //                         },
    //                     })

    //                     if (currentSocket) {
    //                         store.dispatch({
    //                             type: types.WS_UNSUBSCRIBE,
    //                         })
    //                     }

    //                     const {
    //                         user: {currentUser},
    //                     } = store.getState()

    //                     const {token} = currentUser

    //                     console.debug(`about to make_connection on ${newFeed}`)
    //                     const {feed, socket} = make_connection(newFeed, token)
    //                     console.debug(feed)
    //                     socket.onopen = () => {
    //                         store.dispatch({
    //                             type: types.WS_CONNECTING,
    //                             // payload: {

    //                             // }
    //                         })
    //                         // TODO:
    //                         // - Add timeout feature that will set stop trying to connect after X seconds
    //                         if (socket.readyState === 1) {
    //                             console.info(`connected to ${feed}`)
    //                             store.dispatch({
    //                                 type: types.WS_CONNECT,
    //                                 payload: {
    //                                     feed,
    //                                     socket,
    //                                 },
    //                             })
    //                         } else if (socket.readyState === 3) {
    //                             store.dispatch({
    //                                 type: types.WS_CLOSE,
    //                             })
    //                             console.error('Socket connection closed')
    //                         }
    //                     }
    //                     socket.onmessage = e => {
    //                         store.dispatch({
    //                             type: types.WS_RECEIVE,
    //                             payload: JSON.parse(e.data),
    //                         })
    //                     }
    //                     socket.onerror = e => {
    //                         console.error(e)
    //                         // store.dispatch({
    //                         //     type: types.WS_RESET_FEED,
    //                         // })
    //                         // store.dispatch({
    //                         //     type: types.WS_SUBSCRIBE,
    //                         // })
    //                     }
    //                     socket.onclose = e => {
    //                         store.dispatch({
    //                             type: types.WS_CLOSE,
    //                         })
    //                         console.info(`closed conntection to ${feed}`)
    //                     }
    //                 },
    //                 'SETTING UP SOCKET CONNECTION',
    //             )
    //         } else {
    //             console.info(`Already connected to ${newFeed}`)
    //         }

    //         // console.groupEnd('WS_SUBSCRIBE')
    //     }
    // }
    beforeListeners[_types.WS_RECEIVE] = function (store, payload) {
        if (payload) {
            var actionType = payload.type;

            if (payload.hasOwnProperty('exception')) {
                store.dispatch({
                    type: _types.ERROR,
                    payload: { notice: true }
                });
                console.error('i', payload.exception);
            } else {
                console.debug('i', payload);
            }

            if (actionType) {
                var type = actionType.toUpperCase();
                delete payload.type;
                if (_types[type]) {
                    // console.info(`Dispatching ${types[type]}`, payload)
                    store.dispatch({
                        type: _types[type],
                        payload: payload
                    });
                } else {
                    console.error('There is no action ' + type + ', as received.');
                }
            } else {
                console.warn('Nothing to do');
                console.debug(payload);
            }
        } else {
            console.error('No payload received');
        }
    };
    // beforeListeners[types.PING] = ({store}) => {
    //     store.dispatch({
    //         type: types.WS_SEND,
    //         payload: {
    //             header: 'pong',
    //             body: {},
    //         },
    //     })
    // }
    beforeListeners[_types.WS_SEND] = function (store, payload) {
        console.debug('o', payload);

        var _store$getState3 = store.getState(),
            currentSocket = _store$getState3.websocket.currentSocket;

        var cleanedPayload = (0, _cleaners.clean_keys)(payload, false);
        // console.info({payload, cleanedPayload})
        currentSocket.send(JSON.stringify(cleanedPayload));
    };
    beforeListeners[_types.WS_NOTICE] = function (store, payload) {
        var service = new _notificationService.default();
        var notice = payload.notice;

        if (notice) {
            var type = notice.type,
                message = notice.message;

            var doNotice = service[type];
            if (doNotice) {
                service[type](message);
            } else {
                console.error('Could not send notice');
            }
        }
    };
    // beforeListeners[types.WS_DISCONNECT] = ({store}) => {
    //     console.debug("WS_DISCONNECT", store)
    //     const {
    //         websocket: {currentSocket},
    //     } = store.getState()
    //     if (currentSocket) {
    //         try {
    //             const notice = JSON.stringify({
    //                 header: 'disconnectEditor',
    //                 body: 'all',
    //             })
    //             currentSocket.send(notice)
    //             currentSocket.close()
    //         } catch (e) {
    //             console.error(e)
    //         }
    //     }
    //     store.dispatch({
    //         type: types.WS_CLOSE,
    //         payload: {cont: false}
    //     })
    // }
    // afterListeners[types.WS_CLOSE] = (store, payload) => {
    //     console.group("WS_CLOSE")
    //     const {
    //         websocket: {currentSocket},
    //     } = store.getState()
    //     if (currentSocket) {
    //         try {
    //             // store.dispatch({
    //             //     type: types.WS_UNSUBSCRIBE,
    //             // })
    //             // currentSocket.close()
    //             // store.dispatch({
    //             //     type: types.WS_RESET_FEED,
    //             // })
    //             // store.dispatch({
    //             //     type: types.WS_SUBSCRIBE,
    //             // })
    //         } catch (e) {
    //             console.error(e)
    //         }
    //     }
    //     console.groupEnd("WS_CLOSE")
    // }

    afterListeners[_types.WS_RECEIVE_GATHER] = function (store, payload) {

        if (payload) {
            var actionType = payload.actionType,
                actionPayload = payload.actionPayload;


            if (actionPayload.hasOwnProperty('exception')) {
                store.dispatch({
                    type: _types.ERROR,
                    payload: { notice: true }
                });
                console.error('i', actionPayload.exception);
            } else {
                console.debug('i', actionPayload);
            }

            if (actionPayload.description) {
                (0, _interactions.clearPendingGather)(actionPayload.description);
            }

            if (actionType) {
                var type = actionType.toUpperCase();
                delete payload.type;
                if (_types[type]) {
                    // console.info(`Dispatching ${type}`)
                    store.dispatch({
                        type: _types[type],
                        payload: actionPayload
                    });
                } else {
                    console.error('There is no action ' + type + ', as received.');
                }
            } else {
                console.warn('Nothing to do');
                console.debug(payload);
            }
        } else {
            console.error('No payload received');
        }
    };

    exports.default = function (store) {
        return function (next) {
            return function (action) {
                var type = action.type,
                    payload = action.payload;


                if (beforeListeners[type]) {
                    var ret = beforeListeners[type](store, payload);
                    if (ret !== undefined) {
                        if (ret === false) {
                            return;
                        } else {
                            action.payload = ret;
                        }
                    }
                }

                next(action);

                // After listeners
                if (afterListeners[type]) {
                    afterListeners[type](store, payload);
                }
            };
        };
    };
});