define('matrix-frontend/helpers/hour-segment-activity-color', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.hourSegmentActivityColor = hourSegmentActivityColor;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    function hourSegmentActivityColor(_ref) {
        var _ref2 = _slicedToArray(_ref, 6),
            segment = _ref2[0],
            shift = _ref2[1],
            activitiesById = _ref2[2],
            isExpanded = _ref2[3],
            isPainting = _ref2[4],
            currentBrush = _ref2[5];

        var activityId = null;
        if (!activitiesById) {
            return activityId;
        }

        if (isExpanded && isPainting) {
            if (isPainting) {
                var startMoment = currentBrush.startMoment,
                    paintMoment = currentBrush.paintMoment;


                if (startMoment && paintMoment && segment.isSameOrAfter(startMoment) && segment.isBefore(paintMoment)) {
                    var a = activitiesById[currentBrush.activityId];
                    return {
                        id: currentBrush.activityId,
                        livecolor: a.livecolor.id,
                        isCurrent: true
                    };
                }
            }
        }

        if (shift && activitiesById) {
            var block = shift.blocks.find(function (x) {
                return segment.isSameOrAfter(x.start) && segment.isBefore(x.end);
            });

            if (block) {
                activityId = block.activityId;
            }
        }

        if (!activityId) {
            return null;
        }

        var activity = activitiesById[activityId];
        return {
            id: activityId,
            livecolor: activity.livecolor.id,
            isCurrent: false
        };
    }

    exports.default = Ember.Helper.helper(hourSegmentActivityColor);
});