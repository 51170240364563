define('matrix-frontend/components/checklist-navigation-bar', ['exports', 'ember-react-components', 'react', 'npm:moment', 'matrix-frontend/utils/date-helper'], function (exports, _emberReactComponents, _react, _npmMoment, _dateHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var numFormat = Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        signDisplay: 'always'
    });

    var comp = function comp(props) {
        var weekEndDate = props.weekEndDate,
            selectWeek = props.selectWeek;


        var thisWeekEnd = (0, _dateHelper.getClientWeekEndDate)((0, _npmMoment.default)());
        var offsets = [-1, 0, 1, 2, 3, 4];

        var getColumnText = function getColumnText(x) {
            if (x == -1) return 'Last Wk';
            if (x == 0) return 'This Wk';
            if (x == 1) return 'Next Wk';
            return numFormat.format(x);
        };

        var columns = offsets.map(function (x) {
            var weekEnd = thisWeekEnd.clone().add(x * 7, 'days');
            return {
                text: getColumnText(x),
                weekEnd: weekEnd
            };
        });

        return _react.default.createElement(
            'div',
            { className: 'button-group no-margin day-buttons no-print' },
            columns.map(function (c, i) {
                var classes = 'tiny thin secondary';
                if (weekEndDate.startOf('day').isSame(c.weekEnd))
                    // classes += thisWeekEnd.isSame(c.weekEnd) ? ' selected' : ' selected-other-week'
                    classes += ' selected';
                return _react.default.createElement(
                    'button',
                    { key: i, className: classes, onClick: function onClick(_) {
                            return selectWeek(c.weekEnd);
                        } },
                    c.text
                );
            })
        );
    };

    exports.default = (0, _emberReactComponents.default)(comp);
});