define('matrix-frontend/reducers/websocket', ['exports', 'matrix-frontend/actions/_types'], function (exports, _types) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var blankFeed = {
        intended: null,
        connected: null
    };
    var initialState = {
        currentSocket: null,
        feed: blankFeed,
        level: null,
        sid: null,
        status: WebSocket.CLOSED,
        viewers: null
    };

    var websocket = function websocket(state, action) {
        switch (action.type) {
            case _types.WS_STATUS:
                {
                    var status = action.payload.status;

                    // console.log('reducing WS_UNSUBSCRIBE')
                    console.debug('Setting websocket status to ' + status);
                    return _extends({}, state, {
                        status: status
                    });
                }
            case _types.WS_OPENED:
                {
                    var socket = action.payload.socket;

                    // const parts = feed.split('.')
                    return _extends({}, state, {
                        // feed: {
                        //     ...state.feed,
                        //     connected: feed,
                        // },
                        currentSocket: socket
                        // level: parts[1],
                    });
                }

            // case types.WS_CONNECTING: {
            //     // console.log('reducing WS_UNSUBSCRIBE')
            //     return {
            //         ...state,
            //         status: CONNECTING,
            //     }
            // }

            // case types.WS_CLOSE: {
            //     // console.log('reducing WS_UNSUBSCRIBE')
            //     return {
            //         ...state,
            //         status: NOT_CONNECTED,
            //         currentSocket: null
            //     }
            // }

            // case types.WS_UNSUBSCRIBE: {
            //     // console.log('reducing WS_UNSUBSCRIBE')
            //     return {
            //         ...initialState,
            //     }
            // }

            case _types.WS_INTAKE:
                {
                    var sid = action.payload.sid;

                    return _extends({}, state, { sid: sid });
                }

            case _types.WS_REGISTER_VIEWERS:
                {
                    var viewers = action.payload.viewers;

                    return _extends({}, state, {
                        viewers: new Set(viewers)
                    });
                }

            case _types.WS_SWITCH_TO_FEED:
                {
                    var feed = action.payload.feed;

                    console.debug('WS_SWITCH_TO_FEED', feed);
                    return _extends({}, state, {
                        feed: _extends({}, state.feed, {
                            intended: feed
                        })
                    });
                }

            // // case types.WS_SEND: {
            // //     console.log('reducing WS_SEND')
            // //     return { ...state }
            // // }

            case _types.WS_RECEIVE:
                {
                    console.debug('reducing WS_RECEIVE');
                    var feed_name = action.payload.feed_name;

                    return _extends({}, state, {
                        feed: _extends({}, state.feed, {
                            connected: feed_name
                        })
                    });
                }

            case _types.WS_RESET_FEED:
                {
                    return _extends({}, state, { feed: blankFeed });
                }

            // case types.WS_FOOBAR: {
            //     console.log('reducing foobar', action)
            //     return {...state}
            // }

            default:
                {
                    return state || initialState;
                }
        }
    };

    exports.default = websocket;
});