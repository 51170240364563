define('matrix-frontend/pods/clients/show/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        stateService: Ember.inject.service(),

        afterModel: function afterModel() {
            this._super();

            var client = this.modelFor('clients/show');
            this.get('stateService').setClient(client);
        }
    });
});