define('matrix-frontend/pods/components/mx-timeout/component', ['exports', 'ember-redux', 'matrix-frontend/actions/_types', 'matrix-frontend/config/environment', 'matrix-frontend/utils/state'], function (exports, _emberRedux, _types, _environment, _state) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var stateToComputed = function stateToComputed(state) {
    var timeoutInSeconds = (0, _state.getClientOption)('timeout_in_seconds');
    var timeoutDisplayModal = (0, _state.getClientOption)('timeout_display_modal');
    return {
      ttl: state.timeout.ttl,
      timeoutInSeconds: timeoutInSeconds === undefined ? null : parseInt(timeoutInSeconds),
      timeoutDisplayModal: timeoutDisplayModal == undefined ? null : parseInt(timeoutDisplayModal)
    };
  };

  var dispatchToActions = function dispatchToActions(dispatch) {
    return {
      increment: function increment() {
        return dispatch({ type: _types.TIMEOUT_INCREMENT });
      },
      reset: function reset() {
        return dispatch({ type: _types.TIMEOUT_RESET });
      }
    };
  };

  var Comp = Ember.Component.extend({
    interval: null,
    modalService: Ember.inject.service(),
    cookies: Ember.inject.service(),

    showModal: Ember.computed('ttl', function () {
      return this.get('ttl') >= this.get('timeoutInSeconds') - this.get('timeoutDisplayModal');
    }),

    remainingTime: Ember.computed('ttl', function () {
      return this.get('timeoutInSeconds') - this.get('ttl');
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      var doLogout = function doLogout() {
        _this.actions.reset();
        clearInterval(_this.get('interval'));
        _this.get('cookies').write('loggedOut', 1, {
          path: '/'
        });
        _this.get('logout')();
      };

      var runTimer = function runTimer() {
        var timeoutInSeconds = _this.get('timeoutInSeconds');
        var timeoutDisplayModal = _this.get('timeoutDisplayModal');
        var showModalAt = timeoutInSeconds - timeoutDisplayModal;
        if (timeoutInSeconds > 0) {
          var ttl = _this.get('ttl');
          if (ttl >= timeoutInSeconds) {
            doLogout();
          } else {
            if (ttl === showModalAt) {
              var modalService = _this.get('modalService');
              modalService.open('timeoutModal');
            }
            _this.actions.increment();
          }
        }
      };

      if (this.get('interval') === null) {
        this.set('interval', setInterval(runTimer, 1000));

        this.$(document).mousemove(function (event) {
          if (!_this.get('showModal')) _this.actions.reset();
        });
      }
    },


    actions: {
      stayLoggedIn: function stayLoggedIn() {
        this.actions.reset();
        var modalService = this.get('modalService');
        modalService.close('timeoutModal');
      },
      resetCounters: function resetCounters() {
        this.actions.reset();
      }
    }
  });

  exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});