define('matrix-frontend/models/store-employee', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        firstName: _emberData.default.attr('string'),
        lastName: _emberData.default.attr('string'),
        positionCode: _emberData.default.attr('string'),
        positionLevel: _emberData.default.attr('number'),
        homeStore: _emberData.default.belongsTo('store'),
        minimumHours: _emberData.default.attr('number', { defaultValue: 0 }),
        maximumHours: _emberData.default.attr('number', { defaultValue: 0 }),
        maximumShifts: _emberData.default.attr('number', { defaultValue: 0 }),
        rate: _emberData.default.attr('number', { defaultValue: 0 }),
        isExempt: _emberData.default.attr('number', { defaultValue: 0 }),
        startDate: _emberData.default.attr('date'),
        terminationDate: _emberData.default.attr('date')
        //skills: DS.hasMany('employee-skill')
    });
});