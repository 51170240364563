define('matrix-frontend/models/user', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Validations = (0, _emberCpValidations.buildValidations)({
        passwordCurrent: (0, _emberCpValidations.validator)('presence', {
            presence: true,
            message: 'Current password is required!'
        }),
        passwordNew: {
            description: 'New password',
            validators: [(0, _emberCpValidations.validator)('presence', {
                presence: true,
                message: 'New password is required!'
            }), (0, _emberCpValidations.validator)('format', {
                regex: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
                message: 'Password must include upper and lower case letters, and a number'
            }), (0, _emberCpValidations.validator)('length', {
                min: 8,
                max: 20,
                message: 'Password must be bewttween 8 and 20 characters long'
            })]
        },
        passwordNewConfirm: (0, _emberCpValidations.validator)('confirmation', {
            on: 'passwordNew',
            message: '{description} must match!',
            description: 'Passwords'
        })
    });

    exports.default = _emberData.default.Model.extend(Validations, {
        client: _emberData.default.belongsTo('client', { async: true, inverse: null }),
        hierarchyNode: _emberData.default.belongsTo('hierarchy-node', {
            async: true,
            inverse: null
        }),
        homeStore: _emberData.default.belongsTo('store', { async: true, inverse: null }),
        lastSuccessfulLogin: _emberData.default.attr('date'),
        name: _emberData.default.attr('string'),
        passwordCurrent: _emberData.default.attr('string'),
        passwordNew: _emberData.default.attr('string'),
        passwordNewConfirm: _emberData.default.attr('string'),
        roles: _emberData.default.hasMany('role', { async: true }),

        businessUnit: Ember.computed('hierarchyNode', 'homeStore', function () {
            if (this.get('hierarchyNode.id')) {
                return this.get('hierarchyNode');
            } else {
                return this.get('homeStore');
            }
        })
    });
});