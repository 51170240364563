define('matrix-frontend/enhancers/index', ['exports', 'ember-redux/enhancers/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _index.default;
    }
  });
});