define('matrix-frontend/components/react-only/employee-skill-cell', ['exports', 'react'], function (exports, _react) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var comp = function comp(props) {
        var skills = props.skills,
            activitiesById = props.activitiesById,
            displayValue = props.displayValue,
            tooltipValue = props.tooltipValue,
            isLoan = props.isLoan;


        var useSkills = Object.keys(activitiesById).reduce(function (o, i) {
            o[i] = skills[i] || 0;
            return o;
        }, {});

        var compiled = Object.keys(useSkills).filter(function (x) {
            return activitiesById[x] && activitiesById[x].budget.id && activitiesById[x].shouldDisplay;
        }).map(function (id, index) {
            var value = skills[id];
            var color = activitiesById && activitiesById[id] ? _extends({}, activitiesById[id]).livecolor.primary.replace(/ /g, '') : 'gray';
            return {
                className: value ? 'full' : 'empty',
                style: value ? {
                    borderColor: color,
                    backgroundColor: color
                } : {},
                activityPriority: activitiesById[id].activityPriority,
                'data-tooltip': tooltipValue ? +parseFloat('' + value).toFixed(2) || 0 : activitiesById[id].name,
                value: value
            };
        }).sortBy('activityPriority').map(function (x) {
            delete x.activityPriority;
            return x;
        });

        var classes = ['info-cell'];
        if (isLoan) {
            classes.push('is-loan');
        }

        return _react.default.createElement(
            'div',
            { className: classes.join(' ') },
            compiled.map(function (x, i) {
                return _react.default.createElement(
                    'div',
                    { className: 'cell', key: i },
                    _react.default.createElement(
                        'div',
                        x,
                        displayValue ? _react.default.createElement(
                            'small',
                            null,
                            x.value
                        ) : ''
                    )
                );
            })
        );
    };

    exports.default = comp;
});