define('matrix-frontend/services/redux', ['exports', 'matrix-frontend/reducers/index', 'matrix-frontend/middleware/index', 'ember-redux/services/redux', 'matrix-frontend/store'], function (exports, _index, _index2, _redux, _store) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _redux.default.extend({
        reducers: _index.default,
        middlewares: _index2.default,
        makeStoreInstance: _store.makeStoreInstance
    });
});