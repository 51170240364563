define('matrix-frontend/pods/components/mx-form-save-discard/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        formUpdated: false,

        buttonsDisabled: Ember.computed('formUpdated', function () {
            if (this.get('formUpdated') === false) {
                return 'disabled';
            } else {
                return '';
            }
        }),

        actions: {
            saveAction: function saveAction(_saveAction) {
                _saveAction();
                this.set('formUpdated', false);
            },
            discardAction: function discardAction(_discardAction) {
                if (_discardAction()) {
                    this.set('formUpdated', false);
                }
            }
        }
    });
});