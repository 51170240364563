define('matrix-frontend/reducers/user', ['exports', 'matrix-frontend/actions/_types', 'npm:jwt-decode'], function (exports, _types, _npmJwtDecode) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var initialState = {
        currentUser: null,
        redirectLocation: null
    };

    var user = function user(state, action) {
        var type = action.type,
            payload = action.payload;

        switch (type) {
            case _types.USER_LOGIN:
                {
                    var salaryVisibility = 0;
                    var jwtPayload = (0, _npmJwtDecode.default)(payload.token);
                    var svScope = jwtPayload.scopes.find(function (x) {
                        return x.startsWith('salary_visibility_level');
                    });
                    if (!!svScope) {
                        var parts = svScope.split(':');
                        salaryVisibility = Number(parts[1]);
                    }
                    var currentUser = _extends({}, payload, {
                        id: jwtPayload.id,
                        username: jwtPayload.username,
                        // roles: jwtPayload.roles,
                        scopes: jwtPayload.scopes,
                        salaryVisibility: salaryVisibility
                    });
                    return _extends({}, state, {
                        currentUser: currentUser
                    });
                }

            case _types.USER_REDIRECT_LOCATION:
                {
                    return _extends({}, state, {
                        redirectLocation: window.location.pathname + window.location.search
                    });
                }

            default:
                {
                    return state || initialState;
                }
        }
    };

    exports.default = user;
});