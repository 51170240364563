define('matrix-frontend/components/nonstore-user-management', ['exports', 'react', 'ember-react-components', 'matrix-frontend/store', 'matrix-frontend/components/react-only/user-row'], function (exports, _react, _emberReactComponents, _store, _userRow) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var FakeRow = function FakeRow() {
        return _react.default.createElement(
            'div',
            { className: 'row' },
            _react.default.createElement(
                'div',
                { className: 'wrapper f3' },
                _react.default.createElement('div', { className: 'column text f1 fake-text' }),
                _react.default.createElement('div', { className: 'column text f1 fake-text gray-lighten-3-text' })
            ),
            _react.default.createElement(
                'div',
                { className: 'wrapper f4' },
                _react.default.createElement('div', { className: 'column f2 fake-text gray-lighten-3-text' }),
                _react.default.createElement('div', { className: 'column f3 fake-text gray-lighten-3-text' }),
                _react.default.createElement('div', { className: 'column f2 fake-text gray-lighten-3-text' })
            )
        );
    };

    var Loading = function Loading(props) {
        return _react.default.createElement(
            _react.default.Fragment,
            null,
            [].concat(_toConsumableArray(Array(10).keys())).map(function (x) {
                return _react.default.createElement(FakeRow, { key: x });
            })
        );
    };

    var Head = function Head() {
        return _react.default.createElement(
            'div',
            { className: 'row' },
            _react.default.createElement(
                'div',
                { className: 'wrapper f3' },
                _react.default.createElement(
                    'div',
                    { className: 'column text f1' },
                    'Name'
                ),
                _react.default.createElement(
                    'div',
                    { className: 'column text f1' },
                    'Level'
                )
            ),
            _react.default.createElement(
                'div',
                { className: 'wrapper f4' },
                _react.default.createElement(
                    'div',
                    { className: 'column f2' },
                    'Contact Info'
                ),
                _react.default.createElement(
                    'div',
                    { className: 'column text f3' },
                    'Email'
                ),
                _react.default.createElement(
                    'div',
                    { className: 'column text f2' },
                    'Username'
                )
            )
        );
    };
    var Totals = function Totals(_ref) {
        var users = _ref.users,
            openNewNonstoreUserModal = _ref.openNewNonstoreUserModal;

        var count = users && Object.keys(users).length;
        return _react.default.createElement(
            'div',
            { className: 'row' },
            _react.default.createElement(
                'div',
                { className: 'wrapper f3' },
                _react.default.createElement(
                    'div',
                    { className: 'column text f1' },
                    count ? count : 'loading',
                    ' users',
                    _react.default.createElement(
                        'a',
                        {
                            className: 'small breathe-l-10 thin no-print pointer',
                            onClick: function onClick() {
                                return openNewNonstoreUserModal();
                            }
                        },
                        'Add'
                    )
                ),
                _react.default.createElement('div', { className: 'column f1' })
            ),
            _react.default.createElement('div', { className: 'column f4' })
        );
    };

    var UserRows = function UserRows(props) {
        var passthru = _extends({}, props);
        delete passthru.users;

        var users = Object.values(props.users).sortBy('lastName');

        return _react.default.createElement(
            _react.default.Fragment,
            null,
            users.map(function (user, idx) {
                return _react.default.createElement(_userRow.default, _extends({ key: user.id, user: user, row: idx }, passthru));
            })
        );
    };

    var Body = function Body(props) {
        var Display = props.users === null ? Loading : UserRows;
        return _react.default.createElement(Display, props);
    };

    var comp = function comp(props) {
        return _react.default.createElement(
            'div',
            { className: 'employees flex-table breathe-t-20' },
            _react.default.createElement(
                'section',
                { id: 'employee-head', className: 'head' },
                _react.default.createElement(Head, null)
            ),
            _react.default.createElement(
                'section',
                { id: 'employee-totals', className: 'head totals' },
                _react.default.createElement(Totals, props)
            ),
            _react.default.createElement(
                'section',
                { id: 'employee-wrapper', className: 'body' },
                _react.default.createElement(Body, props)
            )
        );
    };

    exports.default = (0, _emberReactComponents.default)(comp);
});