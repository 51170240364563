define('matrix-frontend/reducers/index', ['exports', 'redux', 'matrix-frontend/reducers/checklist', 'matrix-frontend/reducers/client', 'matrix-frontend/reducers/cube', 'matrix-frontend/reducers/employee-panel', 'matrix-frontend/reducers/employee', 'matrix-frontend/reducers/node', 'matrix-frontend/reducers/general', 'matrix-frontend/reducers/payroll-worksheet', 'matrix-frontend/reducers/preferences', 'matrix-frontend/reducers/reports', 'matrix-frontend/reducers/schedule-edit', 'matrix-frontend/reducers/self-serve', 'matrix-frontend/reducers/timeout', 'matrix-frontend/reducers/user', 'matrix-frontend/reducers/websocket', 'matrix-frontend/reducers/noclient', 'matrix-frontend/reducers/store-summary'], function (exports, _redux, _checklist, _client, _cube, _employeePanel, _employee, _node, _general, _payrollWorksheet, _preferences, _reports, _scheduleEdit, _selfServe, _timeout, _user, _websocket, _noclient, _storeSummary) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var foobar = function foobar() {
        return { foo: 'bar' };
    };

    exports.default = (0, _redux.combineReducers)({
        foobar: foobar,
        checklist: _checklist.default,
        client: _client.default,
        cube: _cube.default,
        employeePanel: _employeePanel.default,
        employee: _employee.default,
        general: _general.default,
        node: _node.default,
        payrollWorksheet: _payrollWorksheet.default,
        preferences: _preferences.default,
        reports: _reports.default,
        selfServe: _selfServe.default,
        scheduleEdit: _scheduleEdit.default,
        timeout: _timeout.default,
        user: _user.default,
        websocket: _websocket.default,
        noclient: _noclient.default,
        storeSummary: _storeSummary.default
    });
});