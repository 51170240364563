define('matrix-frontend/pods/schedule-print/controller', ['exports', 'matrix-frontend/mixins/base-controller'], function (exports, _baseController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_baseController.default, {
        stateService: Ember.inject.service(),
        actions: {
            goBack: function goBack() {
                var date = this.stateService.getWeekEndDate();
                var store = this.stateService.getBusinessUnit();
                var options = { queryParams: { date: date, storeId: store.id } };
                this.transitionToRoute('schedule', options);
            }
        }
    });
});