define('matrix-frontend/pods/components/store-employee-skill-row/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            keyPressedWithinInputField: function keyPressedWithinInputField(event) {
                var allowedKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'Enter', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab', 'Backspace', 'Shift'];

                if (allowedKeys.indexOf(event.key) === -1) {
                    event.preventDefault();
                    return false;
                }

                var inputField = Ember.$('input[id=\'' + event.target.id + '\']');
                var activityId = inputField.attr('data-activity-type');
                var targetField = void 0;

                switch (event.key) {
                    case 'ArrowUp':
                        targetField = inputField.parents('tr').first().prev().find('input[id$=\'activityId-' + activityId + '\']');
                        break;
                    case 'ArrowDown':
                    case 'Enter':
                        targetField = inputField.parents('tr').first().next().find('input[id$=\'activityId-' + activityId + '\']');
                        break;
                    case 'ArrowLeft':
                        targetField = inputField.parents('td').first().prev().find("input[id^='skillId']");
                        break;
                    case 'ArrowRight':
                        targetField = inputField.parents('td').first().next().find("input[id^='skillId']");
                        break;
                }
                if (targetField) {
                    targetField.focus();
                    setTimeout(function () {
                        targetField.select();
                    }, 1);
                }
            }
        }
    });
});