define('matrix-frontend/utils/date-helper', ['exports', 'npm:moment', 'matrix-frontend/store'], function (exports, _npmMoment, _store) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.dotwToMoment = exports.segmentToTime = exports.momentToSegment = exports.offsetDate = exports.offsetTime = undefined;
    exports.getDay = getDay;
    exports.getWeek = getWeek;
    exports.getWeekStartDate = getWeekStartDate;
    exports.getClientWeekStartDate = getClientWeekStartDate;
    exports.getClientWeekEndDate = getClientWeekEndDate;
    exports.getDayOfClientWeek = getDayOfClientWeek;

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    // import * as holidays from 'matrix-frontend/utils/holidays'

    function getDay(dateString) {
        return 'day' + (parseInt((0, _npmMoment.default)(dateString).format('e')) + 1);
    }
    function getWeek(dateString) {
        var weekStartDate = (0, _npmMoment.default)(dateString).subtract(6, 'days');
        var week = {};
        for (var i = 0; i < 7; i++) {
            var day = 'day' + (i + 1);
            week[day] = {
                date: weekStartDate.format('MM/DD'),
                day: weekStartDate.format('ddd')
            };
            weekStartDate.add(1, 'days');
        }

        return week;
    }

    function getWeekStartDate(dateString) {
        return (0, _npmMoment.default)(dateString).subtract(6, 'days').format('YYYY-MM-DD');
    }

    function getClientWeekStartDate(date) {
        var clientWeekStartDay = _store.store.getState().cube.clientWeekStartDay;

        var d = (0, _npmMoment.default)(date).startOf('day');
        var x = clientWeekStartDay - (d.day() + 1);
        if (x > 0) {
            x -= 7;
        }
        return d.add(x, 'days');
    }

    function getClientWeekEndDate(date) {
        var clientWeekStartDay = _store.store.getState().cube.clientWeekStartDay;

        var d = (0, _npmMoment.default)(date).startOf('day');
        var x = clientWeekStartDay - (d.day() + 1);
        if (x > 0) {
            x -= 7;
        }
        return d.add(x + 6, 'days');
    }

    function getDayOfClientWeek(date) {
        var clientWeekStartDay = _store.store.getState().cube.clientWeekStartDay;

        var d = (0, _npmMoment.default)(date).startOf('day');
        var x = d.day() + 1 - clientWeekStartDay;
        if (x < 0) {
            x += 7;
        }
        return x;
    }

    var offsetTime = exports.offsetTime = function offsetTime(date, time, offset) {
        if (_npmMoment.default.isMoment(time)) {
            return (0, _npmMoment.default)(time).add(offset, 'days');
        }

        try {
            var parts = time.split(':');
            var hours = parseInt(parts[0]);
            var minutes = parseInt(parts[1]);

            if (offset === -2) {
                offset = 0;
            }

            return (0, _npmMoment.default)(date).hours(hours).minutes(minutes).add(offset, 'days');
        } catch (err) {
            console.log(err);
            console.log(time, typeof time === 'undefined' ? 'undefined' : _typeof(time));
        }
    };

    var offsetDate = exports.offsetDate = function offsetDate(date, time) {
        var hours = void 0;
        var minutes = void 0;

        if (time) {
            if (_npmMoment.default.isMoment(time)) {
                hours = time.hours();
                minutes = time.minutes();
            } else {
                var parts = time.split(':');
                hours = parseInt(parts[0]);
                minutes = parseInt(parts[1]);
            }
            return (0, _npmMoment.default)(date).hours(hours).minutes(minutes);
        } else {
            return (0, _npmMoment.default)(date);
        }
    };
    // def segment_number_to_time(number, is_weekly_number=False, base7=False):
    //     if number > 95 and not is_weekly_number:
    //         number = number % 96

    //     total_minutes = number * 15
    //     day = None

    //     if is_weekly_number:
    //         day = int(floor(total_minutes / 60 / 24))
    //         total_minutes -= day * 60 * 24

    //     hours = int(floor(total_minutes / 60))
    //     minutes = int(total_minutes % 60)

    //     if base7 and day is not None:
    //         day += 1

    //     return (day, time(hours, minutes))

    var momentToSegment = exports.momentToSegment = function momentToSegment(mmt) {
        var baseWeek = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

        var dotw = getDayOfClientWeek(mmt);
        var offset = dotw * 96;
        var number = mmt.hours() * 4 + Math.floor(mmt.minutes() / 15) + offset;

        if (baseWeek) {
            var daysFromStartOfWeek = _npmMoment.default.duration(mmt.diff(baseWeek)).as('days') + 6;
            var weekAdjust = Math.floor(daysFromStartOfWeek / 7);
            number += weekAdjust * 672;
        }

        // const weekAdjust = baseWeek
        //     ? mmt.week() - baseWeek.week() < 0
        //         ? 1
        //         : mmt.week() - baseWeek.week()
        //     : 0
        // return number + weekAdjust * 7 * 96
        // const ref = (baseWeek ? baseWeek.clone() : getClientWeekEndDate(mmt)).startOf('day').subtract(6, 'days')
        // let number = Math.floor(moment.duration(mmt.diff(ref)).as('minutes') / 15)
        // if(number < 0) {
        //     number = 672 + (number % 672)
        // }
        return number;
    };

    var segmentToTime = exports.segmentToTime = function segmentToTime(number) {
        var debug = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        var totalMinutes = number * 15;

        var day = Math.floor(totalMinutes / 60 / 24);
        totalMinutes -= day * 60 * 24;

        var hours = Math.floor(totalMinutes / 60);
        var minutes = Math.round(totalMinutes % 60);

        if (debug) {
            console.info(totalMinutes, day, hours, minutes);
        }

        var time = {
            dotw: day + 1,
            hours: hours,
            minutes: minutes
        };
        return time;
    };

    var dotwToMoment = exports.dotwToMoment = function dotwToMoment(dotw) {
        var weekEndDate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

        if (!weekEndDate) {
            weekEndDate = _store.store.getState().cube.weekEndDate;
        }

        return (0, _npmMoment.default)(weekEndDate).subtract(7 - dotw, 'days');
    };
});