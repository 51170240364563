define('matrix-frontend/components/react-only/employee-availability-row', ['exports', 'matrix-frontend/components/react-only/employee-name-cell', 'matrix-frontend/store', 'matrix-frontend/utils/date-helper', 'npm:moment', 'react'], function (exports, _employeeNameCell, _store, _dateHelper, _npmMoment, _react) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    // <div class="availability">
    //     {{#if avail.isAvailable}}
    //         {{#if avail.isAllDay}}
    //             Open - Close
    //         {{else}}
    //             {{availability-display avail.start avail.end avail.fromOpen avail.untilClose}}
    //         {{/if}}
    //     {{else}}
    //         {{#if isSingleAvailability}}
    //             {{#if avail.reason}}
    //                 <em>{{avail.reason}}</em>
    //             {{else}}
    //                 <em>Not available</em>
    //             {{/if}}
    //         {{/if}}
    //     {{/if}}
    // </div>

    var getTime = function getTime(time) {
        var hours = time.hours();
        var minutes = time.minutes();
        var ampm = hours >= 12 ? 'p' : 'a';
        hours = hours % 12;
        hours = hours === 0 ? 12 : hours;
        minutes = minutes === 0 ? '' : ':' + minutes;
        return '' + hours + minutes + ampm;
    };

    var availabilityDisplay = function availabilityDisplay(item) {
        var start = void 0;
        var end = void 0;

        if (item.fromOpen) {
            start = 'Open';
        } else {
            start = getTime(item.start);
        }
        // if (untilClose || end.hours() === 23 && end.minutes() === 59) {
        if (item.untilClose) {
            end = 'Close';
        } else {
            end = getTime(item.end);
        }

        return start + ' - ' + end;
    };

    var getAvailabilityActivities = function getAvailabilityActivities(availabilityActivities, code) {
        var activity = availabilityActivities.find(function (x) {
            return x.code === code;
        });
        if (activity) {
            return activity.name;
        }
        console.error('UNKNOWN: ' + code);
        return 'UNKNOWN';
    };

    var AvailabilityText = function AvailabilityText(_ref) {
        var item = _ref.item,
            availabilityActivities = _ref.availabilityActivities,
            currentStoreCode = _ref.currentStoreCode,
            index = _ref.index;

        var isLoan = item.storeCode.toUpperCase() !== currentStoreCode.toUpperCase();
        return !isLoan || isLoan && index === 0 ? _react.default.createElement(
            'div',
            { className: 'availability' },
            isLoan ? 'Loaned to ' + item.storeCode.toUpperCase() : item.isAvailable ? item.isAllDay || item.fromOpen && item.untilClose ? 'Open - Close' : availabilityDisplay(item) : getAvailabilityActivities(availabilityActivities, item.reasonCode || item.activityCode || 'N')
        ) : _react.default.createElement('span', null);
    };

    var availabilityClass = function availabilityClass(_ref2, currentStoreCode) {
        var _ref3 = _slicedToArray(_ref2, 1),
            item = _ref3[0];

        return item.isAvailable && item.storeCode === currentStoreCode ? item.isAllDay || item.fromOpen && item.untilClose ? 'open-to-close' : 'partial' : item.reasonCode || item.activityCode ? 'off' : 'gray-lighten-2_5';
    };

    var Day = function Day(props) {
        var availability = props.availability,
            openAvailabilityModal = props.openAvailabilityModal,
            employeeId = props.employeeId,
            dotw = props.dotw,
            availabilityActivities = props.availabilityActivities,
            startDate = props.startDate,
            terminationDate = props.terminationDate,
            isLoan = props.isLoan,
            currentStoreCode = props.currentStoreCode;

        // console.log(availability)
        if (availability.some(function (x) {
            return x.isAvailable;
        }) && availability.some(function (x) {
            return !x.isAvailable;
        })) {
            availability = availability.filter(function (x) {
                return x.isAvailable;
            }).sort(function (a, b) {
                return (0, _npmMoment.default)(a.start).diff((0, _npmMoment.default)(b.start));
            });
        }
        var className = 'column f1 pointer availability-wrapper ' + availabilityClass(availability, currentStoreCode) + (isLoan ? ' is-loan' : '');
        var mmt = (0, _dateHelper.dotwToMoment)(dotw);

        if (startDate && (0, _npmMoment.default)(startDate).isAfter(mmt, 'day')) {
            return _react.default.createElement(
                'div',
                { className: 'column f1 gray-lighten-1-text' },
                _react.default.createElement(
                    'em',
                    null,
                    'Not started'
                )
            );
        } else if (terminationDate && (0, _npmMoment.default)(terminationDate).isBefore(mmt, 'day')) {
            return _react.default.createElement(
                'div',
                { className: 'column f1 gray-lighten-1-text' },
                _react.default.createElement(
                    'em',
                    null,
                    'Termed'
                )
            );
        } else {
            return _react.default.createElement(
                'div',
                {
                    className: className,
                    onClick: function onClick() {
                        openAvailabilityModal(employeeId, dotw);
                    }
                },
                _react.default.createElement(
                    'div',
                    { className: 'availability-display' },
                    availability.map(function (x, i) {
                        return _react.default.createElement(AvailabilityText, {
                            key: i,
                            item: x,
                            index: i,
                            isLoan: isLoan,
                            currentStoreCode: currentStoreCode,
                            availabilityActivities: availabilityActivities
                        });
                    })
                )
            );
        }
    };

    var comp = function comp(props) {
        var employee = props.employee,
            activitiesById = props.activitiesById,
            openAvailabilityModal = props.openAvailabilityModal,
            currentStore = props.currentStore;


        var availability = _extends({}, employee.availability.default, employee.availability.exceptions);
        var isLoan = currentStore.id !== employee.store.id;

        var days = [].concat(_toConsumableArray(Array(7).keys())).map(function (x) {
            return x + 1;
        });

        var _store$getState = _store.store.getState(),
            availabilityActivities = _store$getState.general.availabilityActivities;

        return _react.default.createElement(
            'div',
            { className: 'row' },
            _react.default.createElement(_employeeNameCell.default, { employee: employee, currentStore: currentStore }),
            _react.default.createElement(
                'div',
                { className: 'wrapper f9' },
                days.map(function (x, i) {
                    return _react.default.createElement(Day, {
                        key: i,
                        employeeId: employee.id,
                        dotw: i + 1,
                        availability: availability[x],
                        openAvailabilityModal: openAvailabilityModal,
                        availabilityActivities: availabilityActivities,
                        startDate: employee.startDate,
                        terminationDate: employee.terminationDate,
                        isLoan: isLoan,
                        currentStoreCode: currentStore.code
                    });
                })
            )
        );
    };

    exports.default = comp;
});