define('matrix-frontend/actions/timeout', ['exports', 'matrix-frontend/actions/_types'], function (exports, _types) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.reset = exports.increment = undefined;
  var increment = exports.increment = function increment() {
    return function (dispatch) {
      return dispatch({ type: type.TIMEOUT_INCREMENT });
    };
  };
  var reset = exports.reset = function reset() {
    return function (dispatch) {
      return dispatch({ type: _types.TIMEOUT_RESET });
    };
  };
});