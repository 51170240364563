define('matrix-frontend/reducers/checklist', ['exports', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/cleaners', 'npm:lodash'], function (exports, _types, _cleaners, _npmLodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var initialState = {
        includeOverdue: true,
        focusItemId: null,
        showstores: false,
        weeklyItems: {},
        itemStoreitems: {},
        hierarchies: {},
        hierarchyExclusions: new Set(),
        modalOffset: 0,
        modalItem: null,
        modalStoreitem: null,
        editedStoreitem: {}
    };

    var store = function store(state, action) {
        var type = action.type,
            payload = action.payload;

        switch (type) {
            case _types.TODO_SET_MODAL_DATA:
                {
                    // const {item, storeitem, offset} = payload
                    return _extends({}, state, payload);
                }
            case _types.TODO_SET_INCLUDE_OVERDUE:
                {
                    var includeOverdue = payload.includeOverdue;

                    return _extends({}, state, {
                        includeOverdue: includeOverdue
                    });
                }
            case _types.TODO_SET_FOCUS_ITEM:
                {
                    var focusItemId = payload.focusItemId;

                    return _extends({}, state, {
                        focusItemId: focusItemId
                    });
                }
            case _types.TODO_SET_SHOWSTORES:
                {
                    var showstores = payload.showstores;

                    return _extends({}, state, {
                        showstores: showstores
                    });
                }
            case _types.TODO_EDIT_STOREITEM:
                {
                    var edit = payload.edit;

                    return _extends({}, state, {
                        editedStoreitem: edit
                    });
                }
            // case types.TODO_INTAKE_HIERARCHY: {
            //     const {meta, results} = payload

            //     const {args} = meta
            //     const {client_code, tag} = args

            //     const hierarchyExclusions = new Set([...state.hierarchyExclusions, `${client_code}.${tag}`])
            //     const hierarchies = {...state.hierarchies}
            //     results.forEach(hierarchy => {
            //         hierarchies[hierarchy.tag] = hierarchy
            //     })

            //     return {
            //         ...state,
            //         hierarchyExclusions,
            //         hierarchies,
            //     }
            // }
            case _types.TODO_INTAKE_ITEMS:
                {
                    var meta = payload.meta,
                        results = payload.results;

                    var fullTag = meta.channel.join('.');
                    var weekEnd = meta.params.week_end_date;


                    var weeklyItems = _extends({}, state.weeklyItems);
                    var weekItems = weeklyItems[weekEnd] || {};
                    var tagItems = {};

                    var itemStoreitems = _extends({}, state.itemStoreitems);

                    results.data.forEach(function (item) {
                        if (item.storeitem) {
                            var storeitemData = itemStoreitems[item.id] || {
                                exclusions: new Set(),
                                storeitems: {}
                            };
                            var exclusions = storeitemData.exclusions,
                                storeitems = storeitemData.storeitems;

                            exclusions.add(fullTag);

                            if (Array.isArray(item.storeitem.allocated_hours)) {
                                item.storeitem.allocated_hours = item.storeitem.allocated_hours.reduce(function (o, h) {
                                    o[h.date] = h.hours;
                                    return o;
                                }, {});
                            }

                            storeitems[fullTag] = item.storeitem;
                            itemStoreitems[item.id] = storeitemData;
                        }
                        var cleaned = _extends({}, item);
                        delete cleaned.storeitem;
                        tagItems[item.id] = cleaned;
                    });

                    weekItems[fullTag] = tagItems;
                    weeklyItems[weekEnd] = weekItems;
                    // console.info('intake complete', {weeklyItems})

                    return _extends({}, state, {
                        weeklyItems: weeklyItems,
                        itemStoreitems: itemStoreitems
                    });
                }
            case _types.TODO_INTAKE_STOREITEMS:
                {
                    var _meta = payload.meta,
                        _results = payload.results;
                    var args = _meta.args;
                    var client_code = args.client_code,
                        tag = args.tag,
                        item_id = args.item_id;


                    var _itemStoreitems = _extends({}, state.itemStoreitems);
                    var storeitemData = _itemStoreitems[item_id] || {
                        exclusions: new Set(),
                        storeitems: {}
                    };
                    var exclusions = storeitemData.exclusions,
                        storeitems = storeitemData.storeitems;


                    exclusions.add(client_code + '.' + tag);
                    _results.forEach(function (storeitem) {
                        if (Array.isArray(storeitem.allocated_hours)) {
                            storeitem.allocated_hours = storeitem.allocated_hours.reduce(function (o, h) {
                                o[h.date] = h.hours;
                                return o;
                            }, {});
                        }
                        storeitems[client_code + '.store.' + storeitem.store_code.toLowerCase()] = storeitem;
                    });
                    _itemStoreitems[item_id] = storeitemData;

                    return _extends({}, state, {
                        itemStoreitems: _itemStoreitems
                    });
                }

            default:
                {
                    return state || initialState;
                }
        }
    };

    exports.default = store;
});