define('matrix-frontend/models/daily-forecast', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        currentforecast: _emberData.default.attr('formatted-number'),
        demanddate: _emberData.default.attr('isodate'),
        originalforecast: _emberData.default.attr('formatted-number'),
        scheduleforecast: _emberData.default.attr('formatted-number'),
        forecaststore: _emberData.default.attr('number')
    });
});