define('matrix-frontend/pods/components/nonstore-user-management-page/component', ['exports', 'ember-redux'], function (exports, _emberRedux) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var stateToComputed = function stateToComputed(state) {
        return {
            clientCode: state.cube.clientCode,
            client: state.client,
            employeeId: state.employeePanel.employeeId
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {
            openNewNonstoreUserModal: function openNewNonstoreUserModal() {
                this.modalService.open('new-nonstore-user-modal');
            }
        };
    };

    var Comp = Ember.Component.extend({
        redux: Ember.inject.service(),
        modalService: Ember.inject.service(),

        currentClient: Ember.computed('clientCode', 'client', function () {
            if (!this.clientCode) {
                return null;
            }
            return this.client[this.clientCode];
        }),

        users: Ember.computed('currentClient', function () {
            if (!this.currentClient) {
                return null;
            }
            var users = this.currentClient.users;
            return users;
        }),

        employee: Ember.computed('employeeId', 'users', function () {
            return this.users[this.employeeId];
        })
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});