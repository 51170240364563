define("matrix-frontend/utils/helpers", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.coalesce = coalesce;
    function coalesce() {
        var args = [].concat(Array.prototype.slice.call(arguments));
        return args.find(function (x) {
            return x != null;
        }) || null;
    }
});