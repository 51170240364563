define('matrix-frontend/components/schedule-email-body', ['exports', 'ember-react-components', 'react', 'npm:react-tooltip', 'matrix-frontend/components/react-only/multi-checker', 'matrix-frontend/helpers/dateCompare', 'matrix-frontend/helpers/moment-compare', 'matrix-frontend/components/react-only/email-status-tooltip'], function (exports, _emberReactComponents, _react, _npmReactTooltip, _multiChecker, _dateCompare, _momentCompare, _emailStatusTooltip) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    // {{!-- <div class="info">
    //     Email employees with all published shifts and availability for the next 6 weeks starting
    //     {{moment null 'MMM D' 'sunday'}}
    //     .
    // </div> --}}
    // {{!-- <div class="alert">
    //     This schedule is not published. Emails will not show any shifts for this week.
    // </div> --}}
    // {{#if (and (dateCompare weekEndDate 'today' 'sameAfter') (not published))}}
    //     <div class="alert">
    //         This schedule is not published. Emails will not show any shifts for this week.
    //     </div>
    // {{/if}}
    // {{#if (moment-compare 'isBefore' weekEndDate)}}
    //     <div class="warning">
    //         You are viewing a
    //         <strong>
    //             previous week
    //         </strong>
    //         . Emails will not show the shifts on the current page.
    //     </div>
    // {{/if}}
    // <a {{action 'selectAllEmployees'}}>
    //     all
    // </a>
    // /
    // <a {{action 'selectNoEmployees'}}>
    //     none
    // </a>
    // <div class="flex-table">
    //     <div class="header">
    //         <div class="row">
    //             <div class="column text f1"></div>
    //             <div class="column text f4">
    //                 Name
    //             </div>
    //             <div class="column text f6">
    //                 Email
    //             </div>
    //             <div class="column text f5 hide">
    //                 Communicated
    //             </div>
    //         </div>
    //     </div>
    //     <div class="body">
    //         {{#multiselect-checkboxes
    //             class='multiselect-checkboxes'
    //             options=employees
    //             selection=employeesToEmail
    //             updateSelectionValue=false as |employee isSelected|
    //         }}
    //             {{#if employee.email}}
    //                 <label>
    //                     <div class="row">
    //                         <div class="column text f1">
    //                             {{! disabled=(or (not employee.verifiedEmail) (indexOf employee.email "[DO NOT CONTACT]")) }}
    //                             {{#if (not (indexOf employee.email '[DO NOT CONTACT]'))}}
    //                                 {{input
    //                                     type='checkbox'
    //                                     checked=(contains employeesToEmail employee)
    //                                     click=(action 'toggleEmployeeEmail' employee)
    //                                     disabled=(indexOf employee.email '[DO NOT CONTACT]')
    //                                 }}
    //                             {{/if}}
    //                         </div>
    //                         <div
    //                             class="column text f4
    //                                 {{if (indexOf employee.email '[DO NOT CONTACT]') ' gray-lighten-1-text'}}"
    //                         >
    //                             {{employee.lastName}}
    //                             ,
    //                             {{employee.firstName}}
    //                         </div>
    //                         <div
    //                             class="column text f6
    //                                 {{if (indexOf employee.email '[DO NOT CONTACT]') ' gray-lighten-1-text'}}"
    //                         >
    //                             <i
    //                                 class="{{if
    //                                         employee.verifiedEmail
    //                                         'icon-check green-text'
    //                                         'icon-circle gray-lighten-1-text'
    //                                     }}"
    //                             ></i>
    //                             <span
    //                                 class="{{if
    //                                         (and employee.email (indexOf employee.email '[DO NOT CONTACT]'))
    //                                         'gray-lighten-1-text'
    //                                     }}"
    //                             >
    //                                 {{!-- {{'<'}} --}}
    //                                 {{employee.email}}
    //                                 {{!-- {{'>'}} --}}
    //                             </span>
    //                         </div>
    //                         <div class="column text f5 hide">
    //                             {{#if published}}
    //                                 {{! <i class="icon-close red-text" /> }}
    //                                 <i class="icon-check green-text"></i>
    //                                 Emailed 3/1 @ 10:15a
    //                             {{else}}
    //                                 <em class="gray-lighten-1-text">
    //                                     N/A
    //                                 </em>
    //                             {{/if}}
    //                         </div>
    //                     </div>
    //                 </label>
    //             {{else}}
    //                 <label>
    //                     <div class="row">
    //                         <div class="column text f1"></div>
    //                         <div class="column text f4 gray-lighten-1-text">
    //                             {{employee.lastName}}
    //                             ,
    //                             {{employee.firstName}}
    //                         </div>
    //                         <div class="column text f6"></div>
    //                         <div class="column text f5 hide"></div>
    //                     </div>
    //                 </label>
    //             {{/if}}
    //         {{/multiselect-checkboxes}}
    //     </div>
    // </div>


    var EmailRow = function EmailRow(_ref) {
        var item = _ref.item,
            checked = _ref.checked,
            updateState = _ref.updateState;

        if (item.email) {
            var emailTooltip = item.restrictedReason ? {
                'data-tip': item.id,
                'data-for': 'emailStatusTooltip',
                'data-place': 'bottom'
            } : null;

            var icon = function () {
                var classes = 'constant-size-icon breathe-r-5';
                if (item.restrictedReason != null) {
                    classes += " icon-close red-text";
                } else if (item.verifiedEmail) {
                    classes += " icon-check green-text";
                } else {
                    classes += ' icon-circle gray-lighten-1-text';
                }
                return _react.default.createElement('i', { className: classes });
            }();

            // row with email
            return _react.default.createElement(
                'label',
                null,
                _react.default.createElement(
                    'div',
                    { className: 'row' },
                    _react.default.createElement(
                        'div',
                        { className: 'column text f1' },
                        item.disabled ? null : _react.default.createElement('input', {
                            type: 'checkbox',
                            checked: checked,
                            onChange: function onChange() {
                                return updateState(item.id);
                            },
                            value: item.id,
                            disabled: item.disabled
                        })
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'column text f4' + (item.disabled ? ' gray-lighten-1-text' : '') },
                        item.lastName,
                        ',\xA0',
                        item.firstName
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'column text f6' + (item.disabled ? ' gray-lighten-1-text' : '') },
                        _react.default.createElement(
                            'div',
                            emailTooltip,
                            icon,
                            _react.default.createElement(
                                'span',
                                null,
                                item.email
                            )
                        )
                    )
                )
            );
        }

        // row for no email
        return _react.default.createElement(
            'label',
            null,
            _react.default.createElement(
                'div',
                { className: 'row' },
                _react.default.createElement('div', { className: 'column text f1' }),
                _react.default.createElement(
                    'div',
                    { className: 'column text f4 gray-lighten-1-text' },
                    item.lastName,
                    ',\xA0',
                    item.firstName
                ),
                _react.default.createElement('div', { className: 'column text f6' })
            )
        );
    };

    var comp = function comp(props) {
        var weekEndDate = props.weekEndDate,
            published = props.published,
            employees = props.employees,
            selectAllEmployees = props.selectAllEmployees,
            selectNoEmployees = props.selectNoEmployees,
            toggleEmployeeEmail = props.toggleEmployeeEmail,
            employeesToEmail = props.employeesToEmail;


        (0, _react.useEffect)(function () {
            _npmReactTooltip.default.rebuild();
        });

        var employeesById = {};
        if (employees) {
            employees.forEach(function (e) {
                employeesById[e.id] = e;
            });
        }

        var objects = employees || [];
        return _react.default.createElement(
            _react.default.Fragment,
            null,
            (0, _dateCompare.dateCompare)([weekEndDate, 'today', 'sameAfter']) && !published ? _react.default.createElement(
                'div',
                { className: 'alert' },
                'This schedule is not published. Emails will not show any shifts for this week.'
            ) : null,
            (0, _momentCompare.momentCompare)(['isBefore', weekEndDate]) ? _react.default.createElement(
                'div',
                { className: 'warning' },
                'You are viewing a\xA0',
                _react.default.createElement(
                    'strong',
                    null,
                    'previous week'
                ),
                '. Emails will not show the shifts on the current page.'
            ) : null,
            objects.any(function (e) {
                return e.restrictedReason;
            }) ? _react.default.createElement(
                'div',
                { className: 'alert' },
                'Emails can\'t be sent to addresses marked with\xA0',
                _react.default.createElement('i', { className: 'icon-close red-text' })
            ) : null,
            _react.default.createElement(
                'a',
                { href: '#', onClick: selectAllEmployees },
                'all'
            ),
            '\xA0/\xA0',
            _react.default.createElement(
                'a',
                { href: '#', onClick: selectNoEmployees },
                'none'
            ),
            _react.default.createElement(
                'div',
                { className: 'flex-table' },
                _react.default.createElement(
                    'div',
                    { className: 'header' },
                    _react.default.createElement(
                        'div',
                        { className: 'row' },
                        _react.default.createElement('div', { className: 'column text f1' }),
                        _react.default.createElement(
                            'div',
                            { className: 'column text f4' },
                            'Name'
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'column text f6' },
                            'Email'
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'column text f5 hide' },
                            'Communicated'
                        )
                    )
                ),
                _react.default.createElement(
                    'div',
                    { className: 'body' },
                    _react.default.createElement(
                        'div',
                        { className: 'multi-checker' },
                        _react.default.createElement(
                            'div',
                            { className: 'objects' },
                            objects.map(function (obj) {
                                return _react.default.createElement(
                                    'div',
                                    { key: obj.id },
                                    _react.default.createElement(EmailRow, {
                                        key: obj.id,
                                        item: obj,
                                        checked: employeesToEmail.has(obj.id),
                                        updateState: toggleEmployeeEmail
                                    })
                                );
                            })
                        )
                    )
                )
            ),
            _react.default.createElement(_emailStatusTooltip.default, { employeesById: employeesById })
        );
    };

    exports.default = (0, _emberReactComponents.default)(comp);
});