define('matrix-frontend/pods/legacy/route', ['exports', 'matrix-frontend/routes/base-route', 'matrix-frontend/utils/state'], function (exports, _baseRoute, _state) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _BaseRoute$extend;

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    var VIEW_MAPPING = {
        'legacy.employeeaddedit': 'overview',
        'legacy.employeeskills': 'skills',
        'legacy.employeeavailability': 'availability'
    };

    exports.default = _baseRoute.default.extend((_BaseRoute$extend = {
        saveService: Ember.inject.service()
    }, _defineProperty(_BaseRoute$extend, 'saveService', Ember.inject.service()), _defineProperty(_BaseRoute$extend, 'queryParams', {
        storeId: {
            refreshModel: true
        },
        hierarchyNodeId: {
            refreshModel: true
        },
        date: {
            refreshModel: true
        },
        fromChecklist: {
            refreshModel: true
        }
    }), _defineProperty(_BaseRoute$extend, 'model', function model(params, transition) {
        if (transition.targetName.includes('employee')) {
            params.fromChecklist = 'false';
            if ((0, _state.hasRole)('legacy_employee')) {
                this.replaceWith(transition.targetName, { queryParams: params });
            } else {
                params.view = VIEW_MAPPING[transition.targetName] || 'overview';
                this.replaceWith('employees', { queryParams: params });
            }
            transition.abort();
            // this.transitionTo('login')
        }

        return this._super(params, transition);
    }), _BaseRoute$extend));
});