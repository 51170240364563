define("matrix-frontend/utils/availability", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var employeeAvailableForShift = exports.employeeAvailableForShift = function employeeAvailableForShift(employee, currentShift, dotw) {
        var availability = employee.availability.exceptions[dotw] || employee.availability.default[dotw];
        availability = availability.filter(function (x) {
            return !x.isAvailable;
        }).sort(function (j, k) {
            return k.start.isBefore(j.start);
        });

        if (availability.length === 0) {
            return true;
        }

        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
            for (var _iterator = availability[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var a = _step.value;

                if (a.isAllDay) {
                    return false;
                }
                var _iteratorNormalCompletion2 = true;
                var _didIteratorError2 = false;
                var _iteratorError2 = undefined;

                try {
                    for (var _iterator2 = currentShift.blocks[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                        var b = _step2.value;

                        var aStart = new Date(currentShift.meta.date.year(), currentShift.meta.date.month(), currentShift.meta.date.date(), a.start.hours(), a.start.minutes());
                        var aEnd = new Date(currentShift.meta.date.year(), currentShift.meta.date.month(), currentShift.meta.date.date(), a.end.hours(), a.end.minutes());
                        if ((a.fromOpen || aStart < b.end._d) && (a.untilClose || aEnd > b.start._d)) {
                            return false;
                        }
                    }
                } catch (err) {
                    _didIteratorError2 = true;
                    _iteratorError2 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion2 && _iterator2.return) {
                            _iterator2.return();
                        }
                    } finally {
                        if (_didIteratorError2) {
                            throw _iteratorError2;
                        }
                    }
                }
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally {
            try {
                if (!_iteratorNormalCompletion && _iterator.return) {
                    _iterator.return();
                }
            } finally {
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }

        return true;
    };
});