define('matrix-frontend/routes/application', ['exports', 'matrix-frontend/actions/_types', 'matrix-frontend/config/environment'], function (exports, _types, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        ajax: Ember.inject.service(),
        ajaxService: Ember.inject.service(),
        redux: Ember.inject.service(),
        cookies: Ember.inject.service(),
        router: Ember.inject.service(),
        // loadingSlider: Ember.inject.service(),

        // _startLoading: function () {
        //   this.trigger('startLoading');
        // },

        // _endLoading: function () {
        //   this.trigger('endLoading');
        // },

        model: function model(params, transition) {
            var self = this;
            var env = self.session.get('env');
            var redux = self.get('redux');

            if (!env) {
                console.info(_environment.default);
                var envURL = _environment.default.mxl.replace('${host}', window.location.host) + '/env';
                this.get('ajax').request(envURL).then(function (response) {
                    self.session.set('env', response.env);
                    self.session.set('version', response.version);
                });
            }

            var url = transition.intent.url;


            console.log(url);
            if (url && url.startsWith('/claim')) {
                var parts = url.split('/');
                var claimURL = _environment.default.mxl.replace('${host}', window.location.host) + '/store/shift/claim/' + parts[2];
                this.get('ajax').request(claimURL).then(function (data) {
                    redux.dispatch({
                        type: _types.SS_CLAIM_INTAKE,
                        payload: data
                    });
                });
                return {};
            } else if (url && (url.startsWith('/email') || url.startsWith('/password') || url.startsWith('/poassword'))) {
                return {};
            }

            return this.get('ajax').request('/workforce/current-user').then(function (data) {
                var redux = self.get('redux');
                if (!data) {
                    var loggedOut = self.get('cookies').read('loggedOut') || 0;
                    if (parseInt(loggedOut) === 1) {
                        self.transitionTo('logged-out');
                    } else {
                        redux.dispatch({
                            type: _types.USER_REDIRECT_LOCATION,
                            payload: {
                                location: window.location
                            }
                        });
                        self.transitionTo('login');
                    }
                    return true;
                } else {
                    var token = data.token;
                    var rawCurrentUser = self.store.normalize('user', data.user.users[0]);
                    var currentUser = self.store.push(rawCurrentUser);
                    self.session.set('user', currentUser);
                    self.session.set('token', data.token);

                    Ember.$(window).on('storage', function (event) {
                        redux.dispatch({
                            type: _types.TIMEOUT_RESET
                        });
                        if (event.originalEvent.key === 'logged-out') {
                            window.location.replace('/workforce/logout');
                        }
                    });

                    return currentUser.get('roles').then(function (data) {
                        var isMatrixAdmin = data.any(function (role) {
                            return 'matrix_admin' === role.get('name');
                        });
                        var isDashboardUser = true;

                        var isEmployeeAdmin = data.any(function (role) {
                            return 'matrix_admin' === role.get('name') || role.get('name') == 'employee_admin';
                        });

                        var url = _environment.default.liveAuthURL.replace('${host}', window.location.host);
                        var ajax = self.get('ajax');

                        ajax.request(url, {
                            crossDomain: true,
                            method: 'POST',
                            data: {
                                user: currentUser.get('id'),
                                token: token
                            }
                        }).then(function (response) {
                            var payload = {
                                id: parseInt(currentUser.get('id')),
                                username: currentUser.get('name'),
                                roles: data.map(function (q) {
                                    return {
                                        id: q.get('id'),
                                        name: q.get('name')
                                    };
                                }),
                                token: response.access_token,
                                mxUsername: self.get('cookies').read('mxUsername'),
                                mxCompany: self.get('cookies').read('mxCompany'),
                                isMatrixAdmin: isMatrixAdmin,
                                isEmployeeAdmin: isEmployeeAdmin
                            };

                            redux.dispatch({
                                type: _types.USER_LOGIN,
                                payload: payload
                            });
                        }, function (x) {
                            return console.error(x);
                        });

                        var redirectLocation = redux.getState().user.redirectLocation;
                        if (redirectLocation && redirectLocation !== '/' && redirectLocation !== '/login' && !redirectLocation.startsWith('/claim')) {
                            window.location.replace(redirectLocation);
                        }

                        if (!(isMatrixAdmin || isDashboardUser)) {
                            transition.abort();
                            if (rawCurrentUser.hierarchyNode) {
                                window.location.replace('/workforce/application/storeSelector.jsf');
                            } else {
                                window.location.replace('/workforce/application/index.jsf');
                            }
                        } else {
                            return Ember.RSVP.hash({
                                currentUser: currentUser,
                                hierarchyNode: currentUser.get('hierarchyNode'),
                                homeStore: currentUser.get('homeStore'),
                                client: currentUser.get('client'),
                                roles: currentUser.get('roles')
                            });
                        }
                    });
                }
            });
        }
    });
});