define('matrix-frontend/routes/base-route', ['exports', 'matrix-frontend/utils/keyboard-shortcuts', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/date-helper', 'npm:moment', 'matrix-frontend/utils/analytics-utils'], function (exports, _keyboardShortcuts, _types, _dateHelper, _npmMoment, _analyticsUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var oldURL = "";

    exports.default = Ember.Route.extend({
        stateService: Ember.inject.service(),
        saveService: Ember.inject.service(),
        loadingService: Ember.inject.service(),
        modalService: Ember.inject.service(),
        redux: Ember.inject.service(),
        queryParams: {
            date: {
                refreshModel: true
            },
            storeId: {
                refreshModel: true
            }
        },

        keyboardShortcuts: {
            esc: 'closeAllModals'
            // tab: 'recordUserId',
        },

        activate: function activate() {
            (0, _keyboardShortcuts.bindKeyboardShortcuts)(this);
        },
        deactivate: function deactivate() {
            (0, _keyboardShortcuts.unbindKeyboardShortcuts)(this);
        },
        model: function model(params, transition) {
            var self = this;

            var _redux$getState = this.redux.getState(),
                _redux$getState$cube = _redux$getState.cube,
                currentView = _redux$getState$cube.view,
                targetName = _redux$getState$cube.targetName;

            var useView = targetName === transition.targetName ? currentView : params.view;

            this.redux.dispatch({
                type: _types.CUBE_SET_PAGE,
                payload: {
                    targetName: transition.targetName || null,
                    view: useView || 'overview'
                }
            });

            if (transition.queryParams.hierarchyNodeId) {
                var node = this.get('stateService').getHierarchyNode();

                if (node === null || node.get('id') !== transition.queryParams.hierarchyNodeId) {
                    return self.get('stateService').setHierarchyNode(transition.queryParams.hierarchyNodeId).then(function () {
                        return self.get('stateService').getHierarchyNode();
                    });
                } else {
                    return new Ember.RSVP.Promise(function (resolve) {
                        resolve(node);
                    });
                }
            } else if (transition.queryParams.storeId) {
                var store = this.get('stateService').getStore();

                if (store === null || store.get('id') !== transition.queryParams.storeId) {
                    return self.get('stateService').setStore(transition.queryParams.storeId, true).then(function () {
                        return self.get('stateService').getStore();
                    });
                } else {
                    return new Ember.RSVP.Promise(function (resolve) {
                        resolve(store);
                    });
                }
            }
        },
        afterModel: function afterModel(model, transition) {
            var self = this;
            this._super(model, transition);
            var client = this.modelFor('clients/show');
            this.get('stateService').setClient(client);

            var weekEndDate = transition.queryParams.date;
            if (!(weekEndDate && (0, _dateHelper.getDayOfClientWeek)(weekEndDate) == 6)) {
                console.info("CORRECTING WEEK END DATE");
                weekEndDate = (0, _dateHelper.getClientWeekEndDate)((0, _npmMoment.default)()).format('YYYY-MM-DD');
            }
            this.get('stateService').setWeekEndDate(weekEndDate, true);

            // TODO:
            // - fix hack
            setTimeout(function () {
                var unit = self.get('stateService').getBusinessUnit();
                self.notifyPropertyChange('stateService.hierarchyNode');
                self.notifyPropertyChange('stateService.storeLocation');
            }, 500);
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('date', this.get('stateService.weekEndDate'));
        },


        actions: {
            closeAllModals: function closeAllModals() {
                this.get('modalService').closeAllModals();
                this.get('redux').dispatch({
                    type: _types.EP_SET_EMPLOYEE_PANEL,
                    payload: { employee: null, displayPanel: false }
                });
            },
            willTransition: function willTransition(transition) {
                // console.log('willTransition')
                this.get('loadingService').startLoading();
                this.get('saveService').doTransition(transition);
                return true;
            },
            didTransition: function didTransition() {
                var _this = this;

                var date = this.get('stateService.weekEndDate');
                var unit = this.get('stateService').getBusinessUnit();
                var pageNames = {
                    schedule: 'Schedule',
                    'reports.show': 'Report',
                    'attachments.index': 'Library',
                    'reports.tps': 'Payroll Worksheet',
                    'user-management': 'User Management',
                    'stores.edit': 'Store',
                    'rolescope-management': 'Roles and Scopes',
                    employees: 'Employees',
                    legacy: ' ',
                    'new-reports': 'Reports',
                    'new-checklist': 'Checklist',
                    'new-payroll-worksheet': 'Payroll Worksheet'
                };

                setTimeout(function () {
                    var normalizedURL = (0, _analyticsUtils.getNormalizedURL)(_this.router);
                    if (oldURL !== normalizedURL) {
                        setTimeout(function () {
                            oldURL = normalizedURL;
                            (0, _analyticsUtils.pushToGTM)(normalizedURL);
                        }, 1000);
                    }
                }, 2000);

                var name = void 0;

                if (date && unit) {
                    name = unit.get('name') + ' (' + date + ') ' + pageNames[this.routeName] + ' - Matrix Retail';
                } else {
                    name = pageNames[this.routeName] + ' - Matrix Retail';
                }
                document.title = name;
                // console.log('didTransition')
                this.get('loadingService').endLoading();
                return true;
            }
        }
    });
});