define('matrix-frontend/components/schedule-row', ['exports', 'ember-react-components', 'matrix-frontend/actions/_types', 'matrix-frontend/handlers/schedule-edit', 'matrix-frontend/store', 'matrix-frontend/utils/date-helper', 'matrix-frontend/utils/shift', 'npm:moment', 'npm:numbro', 'react', 'matrix-frontend/components/react-only/mouse-area', 'matrix-frontend/components/react-only/schedule-brush', 'matrix-frontend/components/react-only/schedule-day', 'matrix-frontend/components/react-only/schedule-shift', 'matrix-frontend/components/react-only/schedule-unavailability'], function (exports, _emberReactComponents, _types, _scheduleEdit, _store, _dateHelper, _shift, _npmMoment, _npmNumbro, _react, _mouseArea, _scheduleBrush, _scheduleDay, _scheduleShift, _scheduleUnavailability) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var APPLICABLE_CLASSES = new Set(['best-match', 'assignable-employee', 'skilled-employee', 'daily-shift', 'disabled']);

    var EmployeeName = function EmployeeName(props) {
        var _useState = (0, _react.useState)(props.className),
            _useState2 = _slicedToArray(_useState, 2),
            className = _useState2[0],
            setClassName = _useState2[1];

        var _useState3 = (0, _react.useState)(props.name),
            _useState4 = _slicedToArray(_useState3, 2),
            name = _useState4[0],
            setName = _useState4[1];

        return _react.default.createElement(
            'div',
            { className: className },
            name
        );
    };

    var showEmployeePanel = function showEmployeePanel(context) {
        if (!_store.store.getState().employeePanel.pinned) {
            _store.store.dispatch({
                type: _types.EP_SET_EMPLOYEE_PANEL,
                payload: { employee: context.employee, displayPanel: true }
            });
        }
    };
    var hideEmployeePanel = function hideEmployeePanel(context) {
        if (!_store.store.getState().employeePanel.pinned) {
            _store.store.dispatch({
                type: _types.EP_SET_EMPLOYEE_PANEL,
                payload: { employee: null, displayPanel: false }
            });
        }
    };

    var EmployeeNameWrapper = function EmployeeNameWrapper(props) {
        var employee = props.employee,
            printMode = props.printMode,
            currentStore = props.currentStore;

        var storeCode = employee.store.code.toUpperCase() !== currentStore.code.toUpperCase() ? '(' + employee.store.code.toUpperCase() + ') ' : '';
        var name = ('' + storeCode + employee.lastName + ', ' + employee.firstName).substring(0, 20);

        if (printMode) {
            return;
        } else {
            var context = { employee: employee };
            return _react.default.createElement(
                _mouseArea.default,
                {
                    onLeave: hideEmployeePanel,
                    onEnter: showEmployeePanel,
                    className: 'column text f9 no-left name-column',
                    context: context,
                    enableActions: true
                },
                _react.default.createElement(EmployeeName, { name: name })
            );
        }
    };

    var doClick = function doClick(ignore, employee) {
        var _store$getState$emplo = _store.store.getState().employeePanel,
            employeeId = _store$getState$emplo.employeeId,
            pinned = _store$getState$emplo.pinned;

        if (!ignore) {
            if (pinned && employeeId === employee.id) {
                _store.store.dispatch({ type: _types.EP_UNPIN });
            } else {
                _store.store.dispatch({
                    type: _types.EP_PIN,
                    payload: { employee: employee }
                });
            }
        }
    };

    var getHoursDisplay = function getHoursDisplay(_ref) {
        var hours = _ref.hours,
            segmentSize = _ref.segmentSize;

        return hours.map(function (hour) {
            return _react.default.createElement('div', {
                style: { width: segmentSize * 4 + 'px' },
                className: 'column no-pad hour',
                key: 'dotw-' + hour.dotw + '-hour-' + hour.hour
            });
        });
    };

    var getEmployeeShifts = function getEmployeeShifts(_ref2) {
        var shiftsByEmployee = _ref2.shiftsByEmployee,
            id = _ref2.id;

        if (id && shiftsByEmployee) {
            var es = shiftsByEmployee[id];
            if (es) {
                return es;
            }
        }
        return {};
    };

    var getShift = function getShift(_ref3) {
        var dotw = _ref3.dotw,
            shiftsByEmployee = _ref3.shiftsByEmployee,
            id = _ref3.id;

        var employeeShifts = getEmployeeShifts({ shiftsByEmployee: shiftsByEmployee, id: id });
        var shift = employeeShifts[dotw];
        return dotw ? shift : null;
    };

    var getUnavailability = function getUnavailability(_ref4) {
        var employee = _ref4.employee,
            date = _ref4.date,
            dotw = _ref4.dotw,
            displayStart = _ref4.displayStart,
            displayEnd = _ref4.displayEnd,
            currentStore = _ref4.currentStore;

        var defaultAvailability = employee.availability.default || {};
        var exceptions = employee.availability.exceptions || {};
        var merged = _extends({}, defaultAvailability, exceptions);

        if (merged[dotw].some(function (x) {
            return x.storeCode !== currentStore.code;
        })) {
            merged[dotw] = [{
                fromOpen: true,
                untilClose: true,
                isAvailable: false
            }];
        }

        var unavailability = [];
        if (date) {
            var f = merged[dotw].filter(function (x) {
                return x.isAvailable === false;
            });
            if (f.length > 0) {
                f.forEach(function (x) {
                    // TEMP fix. This really shouldn't be here. It is to correct
                    // a bug that was supplying null values in x.start and
                    // x.end. It is more of an anti-pattern that should be
                    // resolved at some point where the values are set in
                    // the middleware or the reducer.
                    if (x.fromOpen && x.start === null) {
                        Ember.set(x, 'start', (0, _npmMoment.default)(x.date).set({ hours: 0, minutes: 0, seconds: 0 }));
                    }
                    if (x.untilClose && x.end === null) {
                        Ember.set(x, 'end', (0, _npmMoment.default)(x.date).set({
                            hours: 23,
                            minutes: 59,
                            seconds: 59
                        }));
                    }

                    var startTime = (0, _dateHelper.offsetDate)(date, x.start);
                    var endTime = (0, _dateHelper.offsetDate)(date, x.end);

                    var startMoment = x.fromOpen ? displayStart : displayStart.isBefore(startTime) ? startTime : displayStart.clone();
                    var endMoment = x.untilClose ? displayEnd.clone().add(1, 'minutes') : displayEnd.isAfter(endTime) ? endTime : endTime.isSameOrBefore(displayStart) ? displayStart : displayEnd.clone().add(15, 'minutes');
                    var start = (0, _dateHelper.momentToSegment)(startMoment);
                    var end = (0, _dateHelper.momentToSegment)(endMoment);

                    if (end < start && !endTime.isSameOrBefore(displayStart)) {
                        end += 672;
                    }

                    unavailability.push(_extends({}, x, { start: start, end: end }));
                });
            }
        }

        return unavailability;
    };

    var getShiftDisplay = function getShiftDisplay(_ref5) {
        var dotw = _ref5.dotw,
            shiftsByEmployee = _ref5.shiftsByEmployee,
            id = _ref5.id,
            segmentOffset = _ref5.segmentOffset,
            segmentSize = _ref5.segmentSize,
            leftOffset = _ref5.leftOffset,
            activitiesById = _ref5.activitiesById;

        var shift = getShift({ dotw: dotw, shiftsByEmployee: shiftsByEmployee, id: id });
        return shift ? _react.default.createElement(_scheduleShift.default, {
            segmentOffset: segmentOffset,
            segmentSize: segmentSize,
            leftOffset: leftOffset,
            shift: shift,
            activitiesById: activitiesById
        }) : _react.default.createElement('div', null);
    };

    var getUnavailabilityDisplay = function getUnavailabilityDisplay(_ref6) {
        var date = _ref6.date,
            dotw = _ref6.dotw,
            shiftsByEmployee = _ref6.shiftsByEmployee,
            segmentOffset = _ref6.segmentOffset,
            segmentSize = _ref6.segmentSize,
            leftOffset = _ref6.leftOffset,
            employee = _ref6.employee,
            displayStart = _ref6.displayStart,
            displayEnd = _ref6.displayEnd,
            currentStore = _ref6.currentStore;

        var shift = getShift({ dotw: dotw, shiftsByEmployee: shiftsByEmployee, id: employee.id });
        var unavailability = getUnavailability({
            employee: employee,
            date: date,
            dotw: dotw,
            displayStart: displayStart,
            displayEnd: displayEnd,
            currentStore: currentStore
        });

        return _react.default.createElement(_scheduleUnavailability.default, {
            unavailability: unavailability,
            segmentOffset: segmentOffset,
            segmentSize: segmentSize,
            leftOffset: leftOffset,
            shift: shift,
            ee: employee,
            date: date
        });
    };

    var getVacationHours = function getVacationHours(employee) {
        var defaultAvailability = employee.availability.default || {};
        var exceptions = employee.availability.exceptions || {};
        var merged = _extends({}, defaultAvailability, exceptions);

        var totalHours = Object.keys(merged).reduce(function (o, i) {
            var subtotal = merged[i].reduce(function (x, y) {
                x += y.creditHours || 0;
                return x;
            }, 0);
            o += subtotal;
            return o;
        }, 0);
        return totalHours;
    };

    var getStatistics = function getStatistics(_ref7) {
        var date = _ref7.date,
            activitiesById = _ref7.activitiesById,
            shiftsByEmployee = _ref7.shiftsByEmployee,
            employee = _ref7.employee;

        var employeeShifts = getEmployeeShifts({
            shiftsByEmployee: shiftsByEmployee,
            id: employee.id
        });

        if (activitiesById) {
            var weeklyHours = Object.keys(employeeShifts).map(function (x) {
                return employeeShifts[x];
            }).reduce(function (o, i) {
                i.blocks.forEach(function (block) {
                    var activityId = block.activityId;

                    var activity = activitiesById[activityId];
                    if (activity && !activity.isCallin) {
                        o += block.end.diff(block.start, 'hours', true);
                    }
                });
                return o;
            }, 0);
            var dailyHours = Object.keys(employeeShifts).map(function (x) {
                return employeeShifts[x];
            }).filter(function (x) {
                return x.meta.date.isSame(date, 'day');
            }).reduce(function (o, i) {
                i.blocks.forEach(function (block) {
                    var activityId = block.activityId;

                    var activity = activitiesById[activityId];
                    if (activity && !activity.isCallin) {
                        o += block.end.diff(block.start, 'hours', true);
                    }
                });
                return o;
            }, 0);

            var vacationHours = getVacationHours(employee);

            return {
                count: Object.keys(employeeShifts).length,
                weeklyHours: weeklyHours,
                dailyHours: dailyHours,
                vacationHours: vacationHours
            };
        } else {
            return {};
        }
    };

    var getFlags = function getFlags(_ref8) {
        var dotw = _ref8.dotw,
            date = _ref8.date,
            shiftsByEmployee = _ref8.shiftsByEmployee,
            employee = _ref8.employee,
            employeeFlags = _ref8.employeeFlags;

        var daily = {
            alert: [],
            warning: [],
            violation: []
        };
        var weekly = {
            alert: [],
            warning: [],
            violation: []
        };

        var id = employee.id;

        var shift = getShift({ dotw: dotw, shiftsByEmployee: shiftsByEmployee, id: employee.id });
        var employeeShifts = getEmployeeShifts({
            shiftsByEmployee: shiftsByEmployee,
            id: employee.id
        });

        if (date && shift && shift.blocks && shift.blocks.length > 0 && shift.errors) {
            daily = shift.errors.reduce(function (o, i) {
                var severity = i.severity === -1 ? 'alert' : i.severity === 0 ? 'warning' : 'violation';

                o[severity].push(i);

                return o;
            }, daily);
        } else if (!date && employeeShifts) {
            Object.keys(employeeShifts).forEach(function (id) {
                var shift = employeeShifts[id];
                if (shift && shift.blocks && shift.blocks.length > 0 && shift.errors) {
                    shift.errors.forEach(function (i) {
                        var severity = i.severity === -1 ? 'alert' : i.severity === 0 ? 'warning' : 'violation';
                        if (daily[severity] === undefined) {
                            daily[severity] = [];
                        }

                        daily[severity].push(i);
                    });
                }
            });
        }

        if (employeeFlags[id] && employeeFlags[id].length > 0) {
            employeeFlags[id].forEach(function (i) {
                var severity = i.severity === -1 ? 'alert' : i.severity === 0 ? 'warning' : 'violation';
                weekly[severity].push(i);
            });
        }

        return {
            daily: daily,
            weekly: weekly
        };
    };

    var ClosedAllDayRow = function ClosedAllDayRow() {
        return _react.default.createElement(
            'div',
            { className: 'wrapper f7 daily-row' },
            '\xA0'
        );
    };

    var DailyRow = function DailyRow(props) {
        var date = props.date,
            canEdit = props.canEdit,
            noSchedule = props.noSchedule,
            employee = props.employee,
            viewableHours = props.viewableHours,
            activityPaint = props.activityPaint,
            activitiesById = props.activitiesById,
            brushWidth = props.brushWidth,
            segmentOffset = props.segmentOffset,
            segmentSize = props.segmentSize,
            leftOffset = props.leftOffset,
            unavailability = props.unavailability,
            hours = props.hours,
            dotw = props.dotw,
            shiftsByEmployee = props.shiftsByEmployee,
            displayStart = props.displayStart,
            displayEnd = props.displayEnd,
            currentStore = props.currentStore;


        var hoursDisplay = getHoursDisplay({ hours: hours, segmentSize: segmentSize });
        var shiftDisplay = noSchedule == false ? getShiftDisplay({
            dotw: dotw,
            shiftsByEmployee: shiftsByEmployee,
            id: employee.id,
            segmentOffset: segmentOffset,
            segmentSize: segmentSize,
            leftOffset: leftOffset,
            activitiesById: activitiesById
        }) : null;
        var unavailabilityDisplay = getUnavailabilityDisplay({
            date: date,
            dotw: dotw,
            shiftsByEmployee: shiftsByEmployee,
            segmentOffset: segmentOffset,
            segmentSize: segmentSize,
            leftOffset: leftOffset,
            employee: employee,
            displayStart: displayStart,
            displayEnd: displayEnd,
            currentStore: currentStore
        });

        var enableActions = !!(noSchedule == false && canEdit && activityPaint);

        return _react.default.createElement(
            _mouseArea.default,
            { enableActions: enableActions, className: 'wrapper f7 daily-row' },
            _react.default.createElement(_scheduleBrush.default, {
                viewableHours: viewableHours,
                activityPaint: activityPaint,
                activitiesById: activitiesById,
                brushWidth: brushWidth,
                segmentOffset: segmentOffset,
                segmentSize: segmentSize,
                leftOffset: leftOffset,
                unavailability: unavailability,
                employeeRow: 'employee-' + employee.id + '-row',
                useMouse: true
            }),
            hoursDisplay,
            shiftDisplay,
            unavailabilityDisplay
        );
    };

    var WeeklyRow = function WeeklyRow(props) {
        var employee = props.employee,
            employees = props.employees,
            activities = props.activities,
            activitiesById = props.activitiesById,
            canEdit = props.canEdit,
            editMode = props.editMode,
            activeShift = props.activeShift,
            weekEndDate = props.weekEndDate,
            shiftsByEmployee = props.shiftsByEmployee,
            days = props.days,
            currentStore = props.currentStore,
            noSchedule = props.noSchedule;

        var employeeShifts = getEmployeeShifts({
            shiftsByEmployee: shiftsByEmployee,
            id: employee.id
        });

        return _react.default.createElement(
            'div',
            { className: 'wrapper f7 weekly-row' },
            days.map(function (dotw) {
                return _react.default.createElement(_scheduleDay.default, {
                    key: 'employee-' + employee.id + '-dotw-' + dotw,
                    dotw: dotw,
                    employee: employee,
                    employees: employees,
                    shifts: employeeShifts,
                    weekEndDate: weekEndDate,
                    activities: activities,
                    activitiesById: activitiesById,
                    canEdit: canEdit,
                    editMode: editMode,
                    activeShift: activeShift,
                    currentStore: currentStore,
                    noSchedule: noSchedule
                });
            })
        );
    };

    var NameSection = function NameSection(props) {
        var employee = props.employee,
            printMode = props.printMode,
            currentStore = props.currentStore;

        return _react.default.createElement(
            'div',
            { className: 'wrapper f2 compound', onClick: function onClick() {
                    return doClick(printMode, employee);
                } },
            _react.default.createElement(
                'div',
                { className: 'column text f1 no-left gray-lighten-1-text position-column' },
                _react.default.createElement(
                    'small',
                    null,
                    employee.position.code
                )
            ),
            _react.default.createElement(EmployeeNameWrapper, { employee: employee, printMode: printMode, currentStore: currentStore })
        );
    };

    var deleteShift = function deleteShift(shift, currentUser) {
        _store.store.dispatch({
            type: _types.SE_SUNSET_SHIFT,
            payload: { shift: shift, currentUser: currentUser }
        });
    };

    var LastSection = function LastSection(props) {
        var closedAllDay = props.closedAllDay,
            date = props.date,
            dotw = props.dotw,
            activities = props.activities,
            activitiesById = props.activitiesById,
            shiftsByEmployee = props.shiftsByEmployee,
            employee = props.employee,
            employeeFlags = props.employeeFlags,
            displayModal = props.displayModal,
            currentStore = props.currentStore,
            employees = props.employees,
            activeShift = props.activeShift,
            weekEndDate = props.weekEndDate,
            editMode = props.editMode,
            assignableEmployeeIds = props.assignableEmployeeIds,
            availableEmployeeIds = props.availableEmployeeIds,
            skilledEmployeeIds = props.skilledEmployeeIds,
            smartAssignEmployee = props.smartAssignEmployee,
            canEdit = props.canEdit,
            noSchedule = props.noSchedule,
            currentUser = props.currentUser,
            published = props.published;

        var statistics = getStatistics({
            date: date,
            activitiesById: activitiesById,
            shiftsByEmployee: shiftsByEmployee,
            employee: employee
        });
        var flags = getFlags({
            dotw: dotw,
            date: date,
            shiftsByEmployee: shiftsByEmployee,
            employee: employee,
            employeeFlags: employeeFlags
        });
        var hoursFormat = {
            mantissa: 2,
            optionalMantissa: true
        };

        var availability = [];
        var managementActivities = activities && activities.filter(function (x) {
            return x.isManagement;
        });
        var shifts = shiftsByEmployee && employee && noSchedule == false ? shiftsByEmployee[employee.id] : null;
        var shift = shifts ? shifts[dotw] : null;
        var hasUncommunicated = shifts ? published && Object.values(shifts).some(function (x) {
            return !x.meta.communicated && x.meta.saved;
        }) : false;

        if (dotw) {
            availability = employee ? employee.availability.exceptions && employee.availability.exceptions[dotw] ? employee.availability.exceptions[dotw] : employee.availability.default[dotw] : [];
        }

        var dailyShiftClasses = (0, _shift.getDailyShiftClasses)({
            currentShift: shift,
            activeShift: activeShift,
            employee: employee,
            employees: employees,
            availability: availability,
            weekEndDate: weekEndDate,
            dotw: dotw,
            editMode: editMode,
            assignableEmployeeIds: assignableEmployeeIds,
            availableEmployeeIds: availableEmployeeIds,
            skilledEmployeeIds: skilledEmployeeIds,
            smartAssignEmployee: smartAssignEmployee,
            managementActivities: managementActivities,
            currentStore: currentStore,
            published: published
        }).split(' ');

        var classes = ['column', 'number', 'f1', 'column-total']
        // .concat(dailyShiftClasses.filter(x => APPLICABLE_CLASSES.has(x)))
        .concat(dailyShiftClasses).join(' ');
        var content = closedAllDay || noSchedule ? _react.default.createElement(
            _react.default.Fragment,
            null,
            '\xA0'
        ) : _react.default.createElement(
            _react.default.Fragment,
            null,
            _react.default.createElement(
                'div',
                {
                    className: classes,
                    onClick: function onClick(_) {
                        return (0, _scheduleEdit.employeeClickHandler)({
                            employee: employee,
                            dotw: dotw,
                            shift: shift,
                            employees: employees,
                            editMode: editMode,
                            canEdit: canEdit
                        });
                    }
                },
                _react.default.createElement(
                    _react.default.Fragment,
                    null,
                    date ? _react.default.createElement(
                        'span',
                        null,
                        (0, _npmNumbro.default)(statistics.dailyHours).format(hoursFormat)
                    ) : _react.default.createElement(
                        _react.default.Fragment,
                        null,
                        _react.default.createElement(
                            'span',
                            { className: 'only-on-small' },
                            'Shifts:\xA0'
                        ),
                        statistics.count
                    ),
                    flags.daily ? _react.default.createElement(
                        'div',
                        {
                            className: 'flags',
                            onClick: function onClick(e) {
                                e.stopPropagation();
                                _store.store.dispatch({
                                    type: _types.SE_SET,
                                    payload: { showEmployeeFlagsId: employee.id }
                                });
                                // displayModal(`daily-flags-${employee.id}`)
                                displayModal('daily-flags');
                            }
                        },
                        flags.daily.violation.length > 0 ? _react.default.createElement('span', { className: 'icon-flag red-text' }) : '',
                        flags.daily.warning.length > 0 ? _react.default.createElement('span', { className: 'icon-flag orange-text' }) : '',
                        flags.daily.alert.length > 0 ? _react.default.createElement('span', { className: 'icon-flag yellow-text' }) : ''
                    ) : ''
                )
            ),
            _react.default.createElement(
                'div',
                { className: 'column number f1 column-total' },
                date && activeShift && shift && activeShift.meta.id === shift.meta.id ? _react.default.createElement(
                    'a',
                    {
                        href: '#',
                        className: 'button inline secondary',
                        onClick: function onClick() {
                            deleteShift(shift, currentUser);
                        }
                    },
                    _react.default.createElement('i', { className: 'icon-trash' }),
                    ' Delete'
                ) : _react.default.createElement(
                    _react.default.Fragment,
                    null,
                    flags.weekly ? _react.default.createElement(
                        'div',
                        {
                            className: 'flags',
                            onClick: function onClick() {
                                _store.store.dispatch({
                                    type: _types.SE_SET,
                                    payload: {
                                        showEmployeeFlagsId: employee.id
                                    }
                                });
                                // displayModal(`weekly-flags-${employee.id}`)
                                displayModal('weekly-flags');
                            }
                        },
                        flags.weekly.violation.length > 0 ? _react.default.createElement('span', { className: 'icon-flag red-text' }) : '',
                        flags.weekly.warning.length > 0 ? _react.default.createElement('span', { className: 'icon-flag orange-text' }) : '',
                        flags.weekly.alert.length > 0 ? _react.default.createElement('span', { className: 'icon-flag yellow-text' }) : '',
                        hasUncommunicated ? _react.default.createElement('span', { className: 'icon-flag purple-text' }) : ''
                    ) : '',
                    _react.default.createElement(
                        'span',
                        { className: 'only-on-small' },
                        'Hours:\xA0'
                    ),
                    _react.default.createElement(
                        'span',
                        null,
                        (0, _npmNumbro.default)(statistics.weeklyHours).format(hoursFormat)
                    )
                )
            )
        );
        return _react.default.createElement(
            'div',
            { className: 'wrapper f2' },
            content
        );
    };

    var comp = function comp(props) {
        var _props = props,
            date = _props.date,
            employee = _props.employee,
            printMode = _props.printMode,
            closedAllDay = _props.closedAllDay;

        var _useState5 = (0, _react.useState)(new Set(['row', 'assigned-shifts-row', 'no-pad', 'pointer'])),
            _useState6 = _slicedToArray(_useState5, 2),
            rowClasses = _useState6[0],
            setRowClasses = _useState6[1];

        (0, _react.useEffect)(function () {
            var classes = new Set([].concat(_toConsumableArray(rowClasses)));
            if (date) {
                classes.add('daily');
                classes.delete('weekly');
            } else {
                classes.delete('daily');
                classes.add('weekly');
            }
            setRowClasses(classes);
        }, [date]);

        var _store$getState$sched = _store.store.getState().scheduleEdit,
            assignableEmployeeIds = _store$getState$sched.assignableEmployeeIds,
            availableEmployeeIds = _store$getState$sched.availableEmployeeIds,
            skilledEmployeeIds = _store$getState$sched.skilledEmployeeIds,
            smartAssignEmployee = _store$getState$sched.smartAssignEmployee;

        props = _extends({}, props, {
            assignableEmployeeIds: assignableEmployeeIds,
            availableEmployeeIds: availableEmployeeIds,
            skilledEmployeeIds: skilledEmployeeIds,
            smartAssignEmployee: smartAssignEmployee
        });

        var MainSection = date ? closedAllDay ? ClosedAllDayRow : DailyRow : WeeklyRow;

        return _react.default.createElement(
            'div',
            { className: 'employee-row' },
            _react.default.createElement(
                'div',
                { id: 'employee-' + employee.id, className: [].concat(_toConsumableArray(rowClasses)).join(' ') },
                _react.default.createElement(NameSection, props),
                _react.default.createElement(MainSection, props),
                _react.default.createElement(LastSection, props)
            )
        );
    };

    exports.default = (0, _emberReactComponents.default)(comp);
});