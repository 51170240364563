define('matrix-frontend/serializers/v2-role', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTSerializer.extend({
        modelNameFromPayloadKey: function modelNameFromPayloadKey() {
            return 'v2-role';
        },

        payloadKeyFromModelName: function payloadKeyFromModelName() {
            return 'role';
        },

        normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
            return this._super(store, primaryModelClass, { roles: payload }, id, requestType);
        }
    });
});