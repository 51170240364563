define('matrix-frontend/pods/components/tps-rollup/component', ['exports', 'ember-redux', 'npm:underscore', 'matrix-frontend/utils/date-helper', 'matrix-frontend/constants'], function (exports, _emberRedux, _npmUnderscore, _dateHelper, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    // 2017-07-06 || AMH
    // - TEMPORARY
    var doHardCodeReverse = true;

    var stateToComputed = function stateToComputed(state) {
        return {
            node: state.cube.node,
            hierarchies: state.node.hierarchies,
            weekEndDate: state.cube.weekEndDate
        };
    };
    var dispatchToActions = function dispatchToActions(dispatch) {
        return {};
    };

    var Comp = Ember.Component.extend({
        ScheduleStatus: _constants.ScheduleStatus,
        stateService: Ember.inject.service(),

        classNames: ['tps-report-container-base'], // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
        taskVisible: {}, init: function init() {
            var data = this.get('planHours');
            var taskVisible = {};
            _npmUnderscore.default.each(data, function (item) {
                if (item.taskHours) {
                    _npmUnderscore.default.each(item.taskHours, function (task, key) {
                        var id = Ember.String.dasherize(key);
                        taskVisible[id] = false;
                    });
                }
            });
            this.set('taskVisible', taskVisible);
            this._super();
        },


        currentNode: Ember.computed('node', 'hierarchies', function () {
            var node = this.get('node');
            var hierarchies = this.get('redux').store.getState().node.hierarchies;
            if (node && hierarchies) {
                return hierarchies[node.clientCode + '.' + node.tag];
            } else {
                return null;
            }
        }),

        scheduleStatus: Ember.computed('weekEndDate', 'currentNode', function () {
            if (this.weekEndDate) {
                var weekEndDate = this.weekEndDate.format('YYYY-MM-DD');
                if (this.currentNode && this.currentNode.weeks && this.currentNode.weeks[weekEndDate]) {
                    return this.currentNode.weeks[weekEndDate].scheduleStatus;
                }
            }
            return null;
        }),

        shouldHide: Ember.computed('scheduleStatus', function () {
            // 2020-03-19 - AMH
            // Changing to remove readonly schedules to
            // still get to the PW in light of the locking of schedules due to
            // COVID-19 closure of stores and locking of schedules in emergency situation
            if (!this.scheduleStatus || this.scheduleStatus.status.hidden || this.scheduleStatus.status.executive
            // || this.scheduleStatus.status.readonly
            ) {
                    return true;
                }
            return false;
        }),

        budgetsAsChildIds: Ember.computed('crunchedData', function () {
            var data = this.get('crunchedData.planHours');

            var sortedBudgetArray = _npmUnderscore.default.chain(data).filter(function (item, key) {
                if (key !== 'daily' && key !== 'week') {
                    return item;
                }
            }).sortBy(function (item) {
                return item.orderindex;
            }).map(function (item) {
                return '"' + Ember.String.dasherize(item.name) + '-budget"';
            }).value();
            this.get('planHours');
            return '[' + sortedBudgetArray.join() + ']';
        }),

        budgetTasksAsChildIds: Ember.computed('planHours', function () {
            var planHours = this.get('planHours');
            var budgets = {};

            _npmUnderscore.default.each(planHours, function (item) {
                if (item.taskHours) {
                    budgets[item.name] = _npmUnderscore.default.map(item.taskHours, function (item) {
                        return '' + Ember.String.dasherize(item.name);
                    });
                }
            });
            return budgets;
        }),

        crunchedData: Ember.computed('data', function () {
            var data = this.get('data');
            var taskByBudget = {}; //  New data store item for holding task data by budget
            var weekHdr = _dateHelper.default.getWeek(this.get('date'));
            var week = { daily: weekHdr, week: { optimizedhours: 0 }
                //crunch schedule hours
            };_npmUnderscore.default.each(data.scheduleHours, function (item, index) {
                var day = 'day' + (index + 1);
                var dayValues = week.daily[day];
                var weekValues = week['week'];

                dayValues.scheduledhours = item.scheduledhours;
                dayValues.actualhours = item.actualhours;
                dayValues.scheduleActual = !item.actualhours ? item.scheduledhours : item.actualhours;
                weekValues.scheduledhours = !weekValues.scheduledhours ? item.scheduledhours : weekValues.scheduledhours += item.scheduledhours;
                weekValues.actualhours = !weekValues.actualhours ? item.actualhours : weekValues.actualhours += item.actualhours;
                //init optimized hours for later
                dayValues.optimizedhours = 0;
                if (!weekValues.scheduleActual) {
                    weekValues.scheduleActual = dayValues.scheduleActual;
                } else {
                    weekValues.scheduleActual += dayValues.scheduleActual;
                }
                week.daily[day] = dayValues;
                week['week'] = weekValues;
            });

            var taskHours = data.taskHours;
            var planHours = {
                daily: {
                    day1: 0,
                    day2: 0,
                    day3: 0,
                    day4: 0,
                    day5: 0,
                    day6: 0,
                    day7: 0
                },
                week: 0
            };
            var budgets = data.budgets;
            budgets.forEach(function (item) {
                var budgetName = item.get('name');
                var canBeNegative = item.get('canBeNegative');
                planHours[budgetName] = {
                    canBeNegative: canBeNegative,
                    name: budgetName,
                    total: {
                        daily: {
                            day1: 0,
                            day2: 0,
                            day3: 0,
                            day4: 0,
                            day5: 0,
                            day6: 0,
                            day7: 0
                        },
                        week: 0
                    }
                };
            });
            var tasks = data.tasks;
            var optimizedHourTasksNames = [];
            tasks.forEach(function (item) {
                var budgetName = item.get('activity.budget.name');
                var isIbc = item.get('isIbc');
                var name = item.get('name');

                // 2017-07-04 || AMH
                // Remove existing doe to resolve the optimized/non-optimized tasks in a single budget bug
                // if(!item.get('isOptimizedHours')) {
                //   if(!planHours[budgetName].taskHours) {
                //     planHours[budgetName].taskHours = {};
                //   }
                //   planHours[budgetName].taskHours[name] = {
                //     budgetname: budgetName,
                //     name: name,
                //     total: {
                //       daily: { day1: 0, day2: 0, day3: 0, day4: 0, day5: 0, day6: 0, day7: 0 },
                //       week: 0 }
                //   };
                // } else {
                //   optimizedHourTasksNames.push(name);
                // }

                // 2017-07-04 || AMH
                // Replace with the below code to add taskHours to ALL tasks regardless of optimization or not, but looking
                // for visibility
                if (item.get('isVisible')) {
                    if (!planHours[budgetName].taskHours) {
                        planHours[budgetName].taskHours = {};
                    }
                    planHours[budgetName].taskHours[name] = {
                        budgetname: budgetName,
                        name: name,
                        isIbc: isIbc,
                        total: {
                            daily: {
                                day1: 0,
                                day2: 0,
                                day3: 0,
                                day4: 0,
                                day5: 0,
                                day6: 0,
                                day7: 0
                            },
                            week: 0
                        }
                    };
                }

                // 2017-07-04 || AMH
                if (!taskByBudget[budgetName]) {
                    taskByBudget[budgetName] = [];
                }
                taskByBudget[budgetName][name] = {
                    isVisible: item.get('isVisible'),
                    isOptimizedHours: item.get('isOptimizedHours')
                };
                if (item.get('isOptimizedHours')) {
                    // Check to see if taskByBudget for the budget exists
                    // Add to taskByBudget data store
                    optimizedHourTasksNames.push(name);
                }
            });
            _npmUnderscore.default.each(taskHours, function (item, budget) {
                // value/key
                //get budget total by day
                _npmUnderscore.default.each(item.total, function (value, date) {
                    var day = _dateHelper.default.getDay(date);
                    planHours[budget].total.daily[day] = value;
                    planHours.daily[day] += value;
                    planHours.week += value;
                });
                planHours[budget].total.week = _npmUnderscore.default.reduce(item.total, function (memo, value) {
                    return memo + value;
                }, 0);
                var items = _npmUnderscore.default.chain(item).omit(item, ['total']).value();
                _npmUnderscore.default.each(items, function (task, name) {
                    _npmUnderscore.default.each(task.total, function (value, date) {
                        var day = _dateHelper.default.getDay(date);
                        // 2017-07-04 || AMH
                        // Remove code so that calculation is done on ALL tasks
                        // if(planHours[budget].hasOwnProperty("taskHours")) {
                        //   planHours[budget].taskHours[name].total.daily[day] = value;
                        //   planHours[budget].taskHours[name].total.week += value;
                        // } else {
                        //   week.daily[day].optimizedhours += value;
                        //   week.week.optimizedhours += value;
                        // }

                        // 2017-07-04 || AMH
                        // Make sure that the task is recognized and has been loaded
                        if (taskByBudget[budget]) {
                            var taskData = taskByBudget[budget][name];
                            // If it is visible, load data into planHours
                            // The planHours variable determines whether or not the line item will be displayed on screen
                            // If the data exists for the task at that level, then it will appear, and the budget will scaffold
                            if (taskData.isVisible) {
                                planHours[budget].taskHours[name].total.daily[day] = value;
                                planHours[budget].taskHours[name].total.week += value;
                            }
                            // Perform check in taskByBudget for task/budget to determin whether to increment optimizedhours
                            // to show in the optimized hours line on the PW
                            if (taskData.isOptimizedHours) {
                                week.daily[day].optimizedhours += value;
                                week.week.optimizedhours += value;
                            }
                        }
                    });
                });
            });

            week.planHours = planHours;
            //crunch forecasts
            _npmUnderscore.default.each(data.dailyForecast, function (item) {
                var day = _dateHelper.default.getDay(item.demanddate);
                week.daily[day].currentforecast = item.currentforecast;
                week.daily[day].scheduleforecast = item.scheduleforecast;
                week.week.currentforecast = !week.week.currentforecast ? item.currentforecast : week.week.currentforecast + item.currentforecast;
                week.week.scheduleforecast = !week.week.scheduleforecast ? item.scheduleforecast : week.week.scheduleforecast + item.scheduleforecast;
            });
            //crunch sales
            _npmUnderscore.default.each(data.salesMetrics, function (item) {
                var day = _dateHelper.default.getDay(item.demanddate);
                week.daily[day].salesvalue = item.salesvalue;
                week.daily[day].salesvaluecomp = item.salesvaluecomp;
                week.daily[day].salesforecastcomp = item.salesforecastcomp;
                week.daily[day].salesvaluely = item.salesvaluely;
                week.week.salesvalue = !week.week.salesvalue ? item.salesvalue : week.week.salesvalue + item.salesvalue;
                week.week.salesvaluely = !week.week.salesvaluely ? item.salesvaluely : week.week.salesvaluely + item.salesvaluely;
            });
            return week;
        }),
        forecastPlusSalesWeek: Ember.computed('crunchedData', function () {
            var data = this.get('crunchedData');
            var forecastPlusSalesWeek = 0;

            _npmUnderscore.default.each(data.daily, function (item) {
                var value = !item.salesvalue ? item.currentforecast : item.salesvalue;
                forecastPlusSalesWeek += !value ? 0 : value;
            });
            return forecastPlusSalesWeek;
        }),

        hoursOverUnder: Ember.computed('crunchedData', function () {
            var self = this;
            var data = this.get('crunchedData');
            var hoursOverUnder = { daily: {}, week: 0 };

            _npmUnderscore.default.each(data.daily, function (item, day) {
                var currenthours = item.actualhours ? item.actualhours : item.scheduledhours;
                hoursOverUnder.daily[day] = {
                    actualvsschedule: currenthours - item.scheduledhours,
                    schedulevsplan: item.scheduledhours - data.planHours.daily[day],
                    hoursoverunder: currenthours - data.planHours.daily[day]

                    // 2017-07-06 || AMH
                    // Hard code -1 multiplier for LB only
                    // Per instructions from MG on 2017-07-05 Meeting
                    // Intention is to create a ClientSettings module in future to handle
                };if (self.get('isLaneBryant')) {
                    _npmUnderscore.default.each(hoursOverUnder.daily[day], function (value, key) {
                        hoursOverUnder.daily[day][key] = value * -1;
                    });
                }
            });

            hoursOverUnder.week = _npmUnderscore.default.reduce(hoursOverUnder.daily, function (memo, item) {
                return {
                    actualvsschedule: memo.actualvsschedule + item.actualvsschedule,
                    schedulevsplan: memo.schedulevsplan + item.schedulevsplan,
                    hoursoverunder: memo.hoursoverunder + item.hoursoverunder
                };
            }, { actualvsschedule: 0, schedulevsplan: 0, hoursoverunder: 0 });
            return hoursOverUnder;
        }),

        lySalesVarianceWeek: Ember.computed('crunchedData', function () {
            var data = this.get('crunchedData.daily');
            var lySalesVarianceWeek = _npmUnderscore.default.reduce(data, function (memo, item) {
                if (item.salesvalue) {
                    memo.salesvalue += item.salesvalue;
                    memo.salesvaluely += item.salesvaluely;
                }

                return memo;
            }, { salesvalue: 0, salesvaluely: 0 });
            return lySalesVarianceWeek;
        }),

        planHours: Ember.computed('crunchedData', function () {
            var data = this.get('crunchedData.planHours');
            var planHours = _npmUnderscore.default.chain(data).filter(function (item, key) {
                if (key !== 'daily' && key !== 'week') {
                    return item;
                }
            })
            //.sortBy(function(item) { return item.orderindex; } )
            .indexBy('name').value();

            return planHours;
        }),
        salesTrendWeek: Ember.computed('trend', function () {
            var data = this.get('crunchedData.daily');
            var trend = this.get('trend.trend');
            var salesTrendWeek = _npmUnderscore.default.reduce(data, function (memo, item) {
                return memo + (!item.salesvalue ? item.scheduleforecast * trend : item.salesvalue);
            }, 0);
            return salesTrendWeek;
        }),

        scheduleForecastVarianceWeek: Ember.computed('crunchedData', function () {
            var data = this.get('crunchedData.daily');
            var lySalesVarianceWeek = _npmUnderscore.default.reduce(data, function (memo, item) {
                if (!item.salesvalue) {
                    memo.currentforecast += item.currentforecast;
                    memo.scheduleforecast += item.scheduleforecast;
                }
                return memo;
            }, { currentforecast: 0, scheduleforecast: 0 });
            return lySalesVarianceWeek;
        }),

        sortedBudgets: Ember.computed('data.budgets', function () {
            return this.get('data.budgets');
        }),

        trend: Ember.computed('crunchedData', function () {
            var data = this.get('crunchedData.daily');
            var forecastComp = 0;
            var rawTrend = 0;
            var salesComp = 0;
            var trendPct = 0;
            _npmUnderscore.default.each(data, function (item) {
                if (item.salesvaluecomp && item.salesforecastcomp) {
                    salesComp += item.salesvaluecomp;
                    forecastComp += item.salesforecastcomp;
                }
            });

            rawTrend = (salesComp - forecastComp) / forecastComp;
            trendPct = rawTrend * 100;

            return {
                trend: rawTrend + 1,
                trendPct: trendPct
            };
        }),

        traverseAndClose: function traverseAndClose(id) {
            var element = '#' + id;
            var children = Ember.$(element).data().children;
            var self = this;
            var taskVisible = this.get('taskVisible');
            _npmUnderscore.default.each(children, function (child) {
                var e = '#' + child;
                if (Ember.$(e).is(':visible')) {
                    if (taskVisible[child]) {
                        Ember.set(taskVisible, child, false);
                    }
                    Ember.$(e).toggle();
                    var checkbox = e + '-toggle';
                    Ember.$(checkbox).prop('checked', false);
                }
                self.traverseAndClose(child);
            });
            this.set('taskVisible', taskVisible);
        },


        // 2017-07-06 || AMH
        isLaneBryant: Ember.computed('client', function () {
            var stateService = this.get('stateService');
            return (parseInt(stateService.client.id) === 12 || parseInt(stateService.client.id) === 17) && doHardCodeReverse;
        }),

        planHoursText: Ember.computed('node.clientCode', function () {
            var clientCode = this.get('node.clientCode');
            if (clientCode === 'jjill') {
                return 'Earned Hours';
            }
            return 'Plan Hours';
        }),

        scheduleForecastText: Ember.computed('node.clientCode', function () {
            var clientCode = this.get('node.clientCode');
            if (clientCode === 'jjill') {
                return 'Store Ops Goal';
            }
            return 'Schedule Forecast';
        }),

        actions: {
            toggleRow: function toggleRow(id) {
                var element = '#' + id;
                var children = Ember.$(element).data().children;
                var self = this;
                _npmUnderscore.default.each(children, function (child) {
                    var e = '#' + child;
                    if (Ember.$(e).is(':visible')) {
                        var checkbox = e + '-toggle';
                        Ember.$(checkbox).prop('checked', false);
                        self.traverseAndClose(child);
                    }

                    var taskVisible = self.get('taskVisible');
                    if (taskVisible.hasOwnProperty(child)) {
                        var isVisible = taskVisible[child] ? false : true;
                        Ember.set(taskVisible, child, isVisible);
                        self.set('taskVisible', taskVisible);
                    } else {
                        Ember.$(e).toggle();
                    }
                });
            }
        },

        didRender: function didRender() {
            Ember.$('[data-level]').each(function () {
                var children = Ember.$(this).data().children;
                var id = Ember.$(this).attr('id');
                var level = Ember.$(this).data('level');
                var indent = level * 20 + 'px';
                var toggleId = id + '-toggle';
                var label = Ember.$('label[for=\'' + toggleId + '\']');
                var titleIndex = level * 20 + 20 + 7 + 'px';
                Ember.$(label).css('margin-left', indent);
                _npmUnderscore.default.each(children, function (child) {
                    Ember.$('#' + child).find('td.row-title').each(function () {
                        Ember.$(this).css('padding-left', titleIndex);
                    });
                });
            });
        }
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});