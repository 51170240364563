define('matrix-frontend/utils/validation', ['exports', 'matrix-frontend/utils/validation-rules', 'matrix-frontend/utils/employee-rules', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/cleaners'], function (exports, _validationRules, _employeeRules, _types, _cleaners) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.doShiftValidation = undefined;

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var getVacationHours = function getVacationHours(employee) {
        var defaultAvailability = employee.availability.default || {};
        var exceptions = employee.availability.exceptions || {};
        var merged = _extends({}, defaultAvailability, exceptions);

        var totalHours = Object.keys(merged).reduce(function (o, i) {
            var subtotal = merged[i].reduce(function (x, y) {
                x += y.creditHours || 0;
                return x;
            }, 0);
            o += subtotal;
            return o;
        }, 0);
        return totalHours;
    };

    var populateAssignments = function populateAssignments(ee, activities) {
        var numAssigned = 0;
        var hoursAssigned = 0;

        if (ee.loanShifts) {
            Object.values(ee.loanShifts).forEach(function (shift) {
                // Increment number of shifts and weekly hours
                numAssigned += 1;
                if (shift.blocks && shift.blocks.length > 0) {
                    shift.blocks.forEach(function (block) {
                        var activityId = block.activityId;

                        var activity = activities[activityId];
                        if (!activity.isCallin) {
                            hoursAssigned += block.end.diff(block.start, 'hours', true);
                        }
                    });
                }
            });
        }
        return { numAssigned: numAssigned, hoursAssigned: hoursAssigned };
    };

    var doShiftValidation = exports.doShiftValidation = function doShiftValidation(store) {
        var state = store.getState();
        var node = state.cube.node;
        var employeesDB = state.employee;

        // let errors = []
        var shifts = _extends({}, state.scheduleEdit.shifts);

        var tag = node.clientCode + '.' + node.tag;
        var client = state.client.clients[node.clientCode];
        var storeNode = state.node.stores[tag];
        var weekEndDate = state.cube.weekEndDate;
        var shiftRules = client.shiftRules;
        var shiftErrors = client.shiftErrors;
        // const clients = state.client.clients

        if (employeesDB && client.activities && storeNode.weeks && storeNode.weeks[weekEndDate.format('YYYY-MM-DD')] && storeNode.weeks[weekEndDate.format('YYYY-MM-DD')].employees) {
            var _ret = function () {
                var activities = client.activities.reduce(function (a, v) {
                    a[v.id] = v;
                    return a;
                }, {});

                var employees = storeNode.weeks[weekEndDate.format('YYYY-MM-DD')].employees.map(function (ee) {
                    return _extends({}, employeesDB[ee.id], ee);
                }).reduce(function (o, i) {
                    o[i.id] = _extends({}, i, populateAssignments(i, activities));
                    return o;
                }, {});

                var employeeFlags = {};
                var flagCount = {
                    '-1': 0,
                    '0': 0,
                    '1': 0

                    // 1. Get appropriate rules
                };var rules = storeNode && storeNode.info && storeNode.info.state && shiftRules[storeNode.info.state] !== undefined ? shiftRules[storeNode.info.state] : shiftRules.DEFAULT;
                // console.log(`    Applying ${storeNode.info.state}`, rules)

                // 2. Apply rules to each shift

                var _loop = function _loop(i) {
                    var shift = (0, _cleaners.momentize)(JSON.parse(JSON.stringify(shifts[i])));

                    if (shift.meta.employeeId != null) {
                        shift.errors = [];
                        var shiftErrorNumbers = shift.errors.map(function (x) {
                            return x.number;
                        });

                        if (shift.blocks && shift.blocks.length > 0) {
                            for (var rule in _validationRules) {
                                if (typeof _validationRules[rule] === 'function') {
                                    var error = _validationRules[rule](shiftErrors, rules, shift, client.activities);
                                    if (error) {
                                        flagCount[error.severity] += 1;
                                        // console.log('FOUND ERROR', error)

                                        if (!shiftErrorNumbers.includes(error.number)) {
                                            shift.errors.push(_extends({}, error, {
                                                date: shift.meta.date
                                            }));
                                        }
                                        console.debug('>> ERROR SHIFT', shift);
                                    }
                                }
                            }
                        }

                        if (shift.meta.employeeId && employees[shift.meta.employeeId]) {
                            // Increment number of shifts and weekly hours
                            employees[shift.meta.employeeId].numAssigned += 1;
                            if (shift.blocks && shift.blocks.length > 0) {
                                shift.blocks.forEach(function (block) {
                                    var activityId = block.activityId;

                                    var activity = activities[activityId];
                                    if (!activity.isCallin) {
                                        employees[shift.meta.employeeId].hoursAssigned += block.end.diff(block.start, 'hours', true);
                                    }
                                });
                            }

                            for (var _rule in _employeeRules) {
                                if (typeof _employeeRules[_rule] === 'function') {
                                    (function () {
                                        var error = _employeeRules[_rule](shift, employees[shift.meta.employeeId]);
                                        if (error && error.number) {
                                            if (!shift.errors.find(function (x) {
                                                return x.number === error.number;
                                            })) {
                                                flagCount[error.severity] += 1;

                                                if (!shiftErrorNumbers.includes(error.number)) {
                                                    shift.errors.push(_extends({}, error, {
                                                        date: shift.meta.date
                                                    }));
                                                }
                                            }
                                        }
                                    })();
                                }
                            }
                        }
                    }

                    shifts[i] = shift;
                };

                for (var i in shifts) {
                    _loop(i);
                }

                // 3. Persist errors
                store.dispatch({ type: _types.SE_SET_SHIFTS, payload: { shifts: shifts } });

                // 4. Check min/max errors
                Object.keys(employees).forEach(function (key) {
                    var ee = employees[key];
                    var vacationHours = getVacationHours(ee);
                    employeeFlags[key] = [];
                    if (ee.numAssigned > ee.maximumShifts) {
                        var error = {
                            code: 'maximumShifts',
                            msg: 'Employee max shifts per week exceeded. ' + ee.numAssigned + ' out of ' + ee.maximumShifts + ' assigned.',
                            severity: 0,
                            number: -1
                            // console.log('>>>>>>>', employeeFlags[key].find(x => x.code === 'maximumShifts'))
                        };flagCount[error.severity] += 1;
                        employeeFlags[key].push(error);
                    }
                    if (ee.hoursAssigned + vacationHours > ee.maximumHours) {
                        var _error = {
                            code: 'maximumHours',
                            msg: 'Employee max hours per week exceeded. ' + (ee.hoursAssigned + vacationHours) + ' out of ' + ee.maximumHours + ' assigned.',
                            severity: 0,
                            number: -1
                            // console.log('>>>>>>>', employeeFlags[key].find(x => x.code === 'maximumHours'))
                        };flagCount[_error.severity] += 1;
                        employeeFlags[key].push(_error);
                    }
                    if (ee.hoursAssigned + vacationHours < ee.minimumHours) {
                        var _error2 = {
                            code: 'minimumHours',
                            msg: 'Employee min hours per week not met. ' + (ee.hoursAssigned + vacationHours) + ' out of ' + ee.minimumHours + ' assigned.',
                            severity: -1,
                            number: 0
                            // console.log('>>>>>>>', employeeFlags[key].find(x => x.code === 'minimumHours'))
                        };flagCount[_error2.severity] += 1;
                        employeeFlags[key].push(_error2);
                    }
                });

                // 5. Persist employee flags
                // store.dispatch({type: types.SE_SET_EMPLOYEE_FLAGS, payload: {employeeFlags}});
                store.dispatch({ type: _types.SE_SET, payload: { employeeFlags: employeeFlags } });
                return {
                    v: flagCount
                };
            }();

            if ((typeof _ret === 'undefined' ? 'undefined' : _typeof(_ret)) === "object") return _ret.v;
        } else {
            return null;
        }
    };
});