define('matrix-frontend/pods/components/schedule-page-part-day/component', ['exports', 'ember-redux', 'matrix-frontend/actions/_types'], function (exports, _emberRedux, _types) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    // import moment from 'npm:moment';

    var stateToComputed = function stateToComputed(state) {
        return {
            smartAssign: state.scheduleEdit.smartAssign,
            smartAssignEmployee: state.scheduleEdit.smartAssignEmployee,
            availableEmployees: state.scheduleEdit.availableEmployees,
            assignableEmployees: state.scheduleEdit.assignableEmployees,
            clients: state.client.clients,
            node: state.cube.node,
            editMode: state.scheduleEdit.editMode,
            activeShift: state.scheduleEdit.activeShift
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {
            doSwap: function doSwap(employee, dotw, activeShift, index) {
                dispatch({
                    type: _types.SE_DO_SWAP,
                    payload: { employee: employee, dotw: dotw, activeShift: activeShift, index: index }
                });
                dispatch({
                    type: _types.EP_SET_EMPLOYEE_PANEL,
                    payload: { employee: null, displayPanel: false }
                });
            },
            cancel: function cancel(_) {
                dispatch({
                    type: _types.SE_SET,
                    payload: { activeShift: null }
                });
                dispatch({
                    type: _types.EP_SET_EMPLOYEE_PANEL,
                    payload: { employee: null, displayPanel: false }
                });
            }
        };
    };

    var Comp = Ember.Component.extend({
        isActive: Ember.computed('activeShift', 'shifts', function () {
            var shift = this.get('shift');
            var activeShift = this.get('activeShift');
            if (shift && activeShift) {
                return shift.meta.id === activeShift.meta.id;
            }
            return false;
        }),

        isManagement: Ember.computed('shiftBlocks', 'activities', function () {
            var managementActivity = null;
            var shiftBlocks = this.get('shiftBlocks');
            var activities = this.get('activities');
            var managementBlock = shiftBlocks.find(function (x) {
                if (activities) {
                    var activity = activities.find(function (y) {
                        return y.id === x.activityId;
                    });
                    return activity && activity.isManagement ? activity : null;
                }
                return null;
            });

            if (managementBlock) {
                managementActivity = activities.find(function (y) {
                    return y.id === managementBlock.activityId;
                });
            }

            return managementActivity ? managementActivity : null;
        }),

        isSmartAssignEmployee: Ember.computed('smartAssignEmployee', function () {
            var smartAssignEmployee = this.get('smartAssignEmployee');
            var employee = this.get('employee');
            return smartAssignEmployee && employee ? smartAssignEmployee.id === employee.id : false;
        }),

        // isUnassigned: computed('activeShift', function () {
        //     const activeShift = this.get('activeShift');
        //     if (activeShift) {
        //         return activeShift.meta.employeeId ? true : false;
        //     }
        //     return false;
        // }),

        shift: Ember.computed('shifts', function () {
            var shifts = this.get('shifts');
            if (shifts) {
                var dotw = this.get('dotw');
                return shifts[dotw];
            } else {
                return null;
            }
        }),

        showEmployeePanel: function showEmployeePanel() {
            // The "this" context is the mouse-event component
            var redux = this.get('redux');
            var employee = this.get('employee');
            if (!redux.store.getState().employeePanel.pinned) {
                redux.dispatch({
                    type: _types.EP_SET_EMPLOYEE_PANEL,
                    payload: { employee: employee, displayPanel: true }
                });
            }
        },
        hideEmployeePanel: function hideEmployeePanel() {
            // The "this" context is the mouse-event component
            var redux = this.get('redux');
            if (!redux.store.getState().employeePanel.pinned) {
                redux.dispatch({
                    type: _types.EP_SET_EMPLOYEE_PANEL,
                    payload: { employee: null, displayPanel: false }
                });
            }
        },


        shiftBlocks: Ember.computed('shifts', function () {
            var shift = this.get('shift');
            var blocks = shift ? shift.blocks : [];

            // return blocks.sort((x, y) => y.start.isBefore(x.start));
            return blocks.sortBy('start');
        })
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});