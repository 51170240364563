define('matrix-frontend/pods/components/line-area-graph/component', ['exports', 'npm:d3'], function (exports, _npmD) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['graph-container'],

        didInsertElement: function didInsertElement() {
            this.draw();
        },

        draw: function () {
            // eslint-disable-line ember/no-function-prototype-extensions
            var data = this.get('data');
            var labels = this.get('labels');

            //clean up any existing graph
            _npmD.default.select('.line-area-graph').remove();

            if (data && labels) {
                var margin = this.get('media.isMediumUp') ? { top: 40, right: 20, bottom: 30, left: 90 } : { top: 40, right: 20, bottom: 30, left: 50 },
                    width = (this.get('media.isSmallOnly') ? 320 : 900) - margin.left - margin.right,
                    height = 220 - margin.top - margin.bottom;

                var extentValue = _npmD.default.extent(data, function (d) {
                    return Math.max(d.areaValue, d.lineValue);
                });
                var tickFormatFunction = function tickFormatFunction(d) {
                    var symbol = labels.tickSymbol ? labels.tickSymbol : '';
                    if (extentValue[1] >= 1000000) {
                        return symbol + d / 1e6 + 'm';
                    } else if (extentValue[1] >= 1000) {
                        return symbol + d / 1e3 + 'k';
                    } else {
                        return d;
                    }
                };

                var x = _npmD.default.time.scale().range([0, width]);

                var y = _npmD.default.scale.linear().range([height, 0]);

                var xAxis = _npmD.default.svg.axis().scale(x).ticks(_npmD.default.time.days).tickFormat('').orient('bottom');

                var yAxis = _npmD.default.svg.axis().scale(y).ticks(5).tickFormat(tickFormatFunction).orient('left');

                var area = _npmD.default.svg.area().interpolate('basis').x(function (d) {
                    return x(d.date);
                }).y0(height).y1(function (d) {
                    return y(d.areaValue);
                });

                var line = _npmD.default.svg.line().interpolate('basis').x(function (d) {
                    return x(d.date);
                }).y(function (d) {
                    return y(d.lineValue);
                });

                var svg = _npmD.default.select('#' + this.get('elementId')).append('svg').attr('class', 'line-area-graph');

                if (this.get('media.isMediumUp')) {
                    svg.attr('viewBox', '0 0 ' + (width + margin.left + margin.right) + ' ' + (height + margin.top + margin.bottom));
                } else {
                    svg.attr('width', width + margin.left + margin.right).attr('height', height + margin.top + margin.bottom);
                }

                var graph = svg.append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

                x.domain(_npmD.default.extent(data, function (d) {
                    return d.date;
                }));
                y.domain(extentValue);

                graph.append('g').attr('class', 'x axis').attr('transform', 'translate(0,' + height + ')').call(xAxis);

                graph.append('g').attr('class', 'y axis').call(yAxis).append('text').attr('transform', 'rotate(-90)').attr('y', 6).attr('dy', '.71em').style('text-anchor', 'end');

                graph.append('path').attr('class', 'area').attr('d', area(data));

                graph.append('path').attr('class', 'line').attr('d', line(data));

                if (labels.area && labels.line && this.get('media.isMediumUp')) {
                    var areaLegend = svg.append('g').attr('transform', 'translate(' + margin.left + ', 25)');

                    areaLegend.append('rect').attr('class', 'area').attr('width', '10px').attr('height', '10px').attr('y', -10);

                    areaLegend.append('text').text(labels.area).attr('y', 0).attr('x', 15);

                    var lineLegend = svg.append('g').attr('transform', 'translate(' + (margin.left + 75) + ', 25)');

                    lineLegend.append('line').attr('class', 'line').attr('x1', 40).attr('x2', 50).attr('y1', -5).attr('y2', -5);

                    lineLegend.append('text').text(labels.line).attr('y', 0).attr('x', 55);
                }
            }
        }.observes('data', 'media.isMediumUp')
    });
});