define('matrix-frontend/actions/checklist', ['exports', 'matrix-frontend/utils/backoff', 'matrix-frontend/utils/interactions'], function (exports, _backoff, _interactions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.checklistReload = undefined;
    var checklistReload = exports.checklistReload = function checklistReload(store) {
        (0, _interactions.setStore)(store);
        console.debug('~~checklistReload~~');

        (0, _backoff.generalBackoff)(function () {
            return store.getState().cube.node && store.getState().cube.clientCode && store.getState().user.currentUser && store.getState().cube.weekEndDate;
        }, function () {
            // const {
            //     includeOverdue,
            // } = store.getState().checklist
            var weekEnd = store.getState().cube.weekEndDate.format('YYYY-MM-DD');

            // retrieve checklist items
            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'client',
                    namespace: ['checklist', 'items'],
                    operation: 0,
                    include_channel: 2,
                    broadcast: false,
                    params: {
                        week_end_date: weekEnd,
                        include_overdue: true
                    },
                    response_type: 'TODO_INTAKE_ITEMS'
                }
            }, 'retrieve list of checklist items: ' + store.getState().cube.clientCode + ', ' + store.getState().cube.node.tag + ', ' + weekEnd, false, null, null, null, true);
        });
    };
});