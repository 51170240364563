define('matrix-frontend/utils/validation-rules', ['exports', 'lodash', 'npm:moment'], function (exports, _lodash, _npmMoment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.nyDinnerBreak = exports.nyLunchDinnerBreak = exports.nyLunchBreak = exports.employeeMeetsSkillRequirements = exports.validShiftExtension = exports.insufficientNumberOfRestBreaks = exports.restBreakTooCloseToPreviousBreak = exports.restBreakTooCloseToEnd = exports.restBreakTooCloseToStart = exports.restBreakInvalid = exports.insufficientNumberOfMealBreaks = exports.mealBreakTooCloseToPreviousBreak = exports.mealBreakTooCloseToEnd = exports.mealBreakTooCloseToStart = exports.hoursBeforeMealBreak = exports.mealBreakInvalid = exports.maxHoursForDay = exports.minLength = exports.maxLength = undefined;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var REST_ACTIVITY = 1;

    var getShiftBlocks = function getShiftBlocks(shift, ignoreActivities) {
        ignoreActivities = ignoreActivities || [];
        var blocks = shift ? shift.blocks : [];
        if (ignoreActivities.length > 0) {
            blocks = blocks.filter(function (x) {
                return !ignoreActivities.includes(x.activityId);
            });
        }
        return blocks.sort(function (x, y) {
            return (0, _npmMoment.default)(y.start).isBefore((0, _npmMoment.default)(x.start));
        });
    };

    var getFirstBlock = function getFirstBlock(shift, ignoreActivities) {
        ignoreActivities = ignoreActivities || [];
        var shiftBlocks = getShiftBlocks(shift, ignoreActivities);
        return shiftBlocks.length > 0 ? shiftBlocks[0] : null;
    };

    var getLastBlock = function getLastBlock(shift, ignoreActivities) {
        ignoreActivities = ignoreActivities || [];
        var shiftBlocks = getShiftBlocks(shift, ignoreActivities);
        if (shiftBlocks) {
            var _shiftBlocks$slice = shiftBlocks.slice(-1),
                _shiftBlocks$slice2 = _slicedToArray(_shiftBlocks$slice, 1),
                last = _shiftBlocks$slice2[0];

            // console.log('last', ignoreActivities, last)
            return last;
        } else {
            return null;
        }
    };

    var getMealBreaks = function getMealBreaks(shift) {
        var shiftBlocks = getShiftBlocks(shift);
        var firstBlock = getFirstBlock(shift);
        var lastBlock = getLastBlock(shift);
        var breaks = [];

        shiftBlocks.reduce(function (prev, curr) {
            if (prev) {
                if ((0, _npmMoment.default)(prev.end).isBefore((0, _npmMoment.default)(curr.start))) {
                    var prevBreak = breaks.length > 0 ? breaks.slice(-1)[0] : null;
                    var fromPrevBreak = prevBreak ? (0, _npmMoment.default)(prev.end).diff((0, _npmMoment.default)(prevBreak.end), 'hours', true) : null;
                    breaks.push({
                        start: prev.end,
                        end: curr.start,
                        length: (0, _npmMoment.default)(curr.start).diff((0, _npmMoment.default)(prev.end), 'hours', true),
                        fromStart: (0, _npmMoment.default)(prev.end).diff((0, _npmMoment.default)(firstBlock.start), 'hours', true),
                        fromEnd: (0, _npmMoment.default)(lastBlock.end).diff((0, _npmMoment.default)(curr.start), 'hours', true),
                        fromPrevBreak: fromPrevBreak
                    });
                }
            }
            return curr;
        }, null);

        return breaks;
    };

    var getRestBreaks = function getRestBreaks(shift) {
        var shiftBlocks = getShiftBlocks(shift);
        var firstBlock = getFirstBlock(shift);
        var lastBlock = getLastBlock(shift);
        var breaks = shiftBlocks.filter(function (x) {
            return x.activityId === REST_ACTIVITY;
        }).map(function (x, i) {
            var prevBreak = i > 0 ? shiftBlocks[i] : null;
            var fromPrevBreak = prevBreak ? (0, _npmMoment.default)(x.start).diff((0, _npmMoment.default)(prevBreak.end), 'hours', true) : null;
            return {
                start: x.start,
                end: x.end,
                length: (0, _npmMoment.default)(x.end).diff((0, _npmMoment.default)(x.start), 'hours', true),
                fromStart: (0, _npmMoment.default)(x.start).diff((0, _npmMoment.default)(firstBlock.start), 'hours', true),
                fromEnd: (0, _npmMoment.default)(lastBlock.end).diff((0, _npmMoment.default)(x.end), 'hours', true),
                fromPrevBreak: fromPrevBreak
            };
        });

        return breaks;
    };

    var getPaidShiftLength = function getPaidShiftLength(shift, ignoreActivities) {
        ignoreActivities = ignoreActivities || [];
        var shiftBlocks = getShiftBlocks(shift, ignoreActivities);
        return shiftBlocks.reduce(function (partialSum, curr) {
            return partialSum + (0, _npmMoment.default)(curr.end).diff((0, _npmMoment.default)(curr.start), 'hours', true);
        }, 0);
    };

    var getTotalShiftLength = function getTotalShiftLength(shift, ignoreActivities) {
        ignoreActivities = ignoreActivities || [];
        var firstBlock = getFirstBlock(shift, ignoreActivities);
        var lastBlock = getLastBlock(shift, ignoreActivities);
        return (0, _npmMoment.default)(lastBlock.end).diff((0, _npmMoment.default)(firstBlock.start), 'hours', true);
    };

    var getShiftExtensionActivities = function getShiftExtensionActivities(activities) {
        return activities.filter(function (x) {
            return x.isCallin === true;
        }).map(function (x) {
            return x.id;
        });
    };

    var getMinutesOfDay = function getMinutesOfDay(m) {
        return m.minutes() + m.hours() * 60;
    };

    var mealBreakAtLeastBetween = function mealBreakAtLeastBetween(mealBreak, minLength, startTime, endTime) {
        // calculating the mealbreak offset (if any)
        var offset = mealBreak.length - minLength;
        if (offset < 0) {
            /* the meal break doesn't even have the minimum length required, no
             * need to go further
             */
            return false;
        } else if (offset === 0) {
            /* meal break and minimum length required are the same, so let's just
             * compare the time boundaries and be done with it
             */
            return getMinutesOfDay(mealBreak.start) >= getMinutesOfDay((0, _npmMoment.default)(startTime, 'H:mm')) && getMinutesOfDay(mealBreak.end) <= getMinutesOfDay((0, _npmMoment.default)(endTime, 'H:mm'));
        }
        var minMealBreakLength = minLength * 60; // minimum meal break in minutes
        // slide stuff around
        return getMinutesOfDay(mealBreak.start) >= getMinutesOfDay((0, _npmMoment.default)(startTime, 'H:mm')) && getMinutesOfDay((0, _npmMoment.default)(mealBreak.start).add(minMealBreakLength, 'minutes')) <= getMinutesOfDay((0, _npmMoment.default)(endTime, 'H:mm')) || getMinutesOfDay((0, _npmMoment.default)(mealBreak.end).subtract(minMealBreakLength, 'minutes')) >= getMinutesOfDay((0, _npmMoment.default)(startTime, 'H:mm')) && getMinutesOfDay(mealBreak.end) <= getMinutesOfDay((0, _npmMoment.default)(endTime, 'H:mm'));
    };

    /* ---------------------------------------------------------------------------
    -> Rule #1
       Max shift length exceeded ("shift length" = "hours paid")
    --------------------------------------------------------------------------- */

    var maxLength = exports.maxLength = function maxLength(shiftErrors, rules, shift, activities) {
        var error = null;
        var number = 1;

        if (rules.maxShiftLength) {
            var shiftExtensionActivities = getShiftExtensionActivities(activities);
            var totalLength = getPaidShiftLength(shift, shiftExtensionActivities);
            if (totalLength > rules.maxShiftLength) {
                error = {
                    number: number,
                    msg: 'Shift is longer than ' + rules.maxShiftLength + ' paid hours',
                    severity: shiftErrors[number] || 0
                };
            }
        }

        return error;
    };

    /* ---------------------------------------------------------------------------
    -> Rule #2
       Too short for min shift length ("shift length" = "hours paid")
    --------------------------------------------------------------------------- */
    var minLength = exports.minLength = function minLength(shiftErrors, rules, shift, activities) {
        var error = null;
        var number = 2;

        if (rules.minShiftLength) {
            var shiftExtensionActivities = getShiftExtensionActivities(activities);
            var totalLength = getPaidShiftLength(shift, shiftExtensionActivities);
            if (totalLength < rules.minShiftLength) {
                error = {
                    number: number,
                    msg: 'Shift is shorter than ' + rules.minShiftLength + ' paid hours',
                    severity: shiftErrors[number] || 0
                };
            }
        }

        return error;
    };

    /* ---------------------------------------------------------------------------
    -> Rule #3
       Too long from shift start to shift end
       "max hours for day" = "elapsed time from firstBlock.start to lastBlock.end"
    --------------------------------------------------------------------------- */
    var maxHoursForDay = exports.maxHoursForDay = function maxHoursForDay(shiftErrors, rules, shift) {
        var error = null;
        var number = 3;

        if (rules.maxHoursForDay) {
            var totalLength = getTotalShiftLength(shift);
            if (totalLength > rules.maxHoursForDay) {
                error = {
                    number: number,
                    msg: 'Shift length from start to end is longer than ' + rules.maxHoursForDay + ' hours',
                    severity: shiftErrors[number] || 0
                };
            }
        }

        return error;
    };

    ///////////////////////////////////////
    ///             Rule #4             ///
    ///////////////////////////////////////
    var mealBreakInvalid = exports.mealBreakInvalid = function mealBreakInvalid(shiftErrors, rules, shift) {
        var error = null;
        var number = 4;

        if (rules.mealBreakLength && rules.mealBreakMaxLength && rules.ignoreMealBreak !== 1) {
            var mealBreaks = getMealBreaks(shift);
            mealBreaks.forEach(function (mealBreak) {
                if (mealBreak.length < rules.mealBreakLength || mealBreak.length > rules.mealBreakMaxLength) {
                    error = {
                        number: number,
                        msg: 'Meal break length invalid. Required length: ' + rules.mealBreakLength + ' - ' + rules.mealBreakMaxLength + '. Actual length: ' + mealBreak.length,
                        severity: shiftErrors[number] || 0
                    };
                }
            });
        }

        return error;
    };

    ///////////////////////////////////////
    ///             Rule #5             ///
    ///////////////////////////////////////
    // export const hoursBeforeMealBreak = (shiftErrors, rules, shift) => {
    var hoursBeforeMealBreak = exports.hoursBeforeMealBreak = function hoursBeforeMealBreak() {
        // eslint-disable-line no-unused-vars
        var error = null;
        // const number = 5;

        // TODO:
        // - Verify logic with team that this is needed.
        // hoursWorkedBeforeBreak = mealBreakStart - lastWorkStart;
        // if (hoursWorkedBeforeBreak > mealBreakRule) {
        //   this.errors.push({
        //     messageId: 5,
        //     breakNumber: mealBreakNumber,
        //     errorMsg: "Hours worked before Meal Break (" + _segmentToHour(hoursWorkedBeforeBreak) + ") is greater than " + this.rules["mealBreak" + (mi + 1)]
        //   });
        // }

        // if (rules.mealBreakMaxLength) {
        //     const mealBreaks = getMealBreaks(shift);
        //     mealBreaks.forEach(mealBreak => {
        //         if (mealBreak.length !== rules.mealBreakLength) {
        //             error = {
        //                 number,
        //                 msg: `Meal break length invalid. Required length: ${rules.mealBreakLength} Actual length: ${mealBreak.length}`,
        //                 severity: shiftErrors[number] || 0
        //             }
        //         }
        //     });
        // }

        return error;
    };

    ///////////////////////////////////////
    ///             Rule #6             ///
    ///////////////////////////////////////
    var mealBreakTooCloseToStart = exports.mealBreakTooCloseToStart = function mealBreakTooCloseToStart(shiftErrors, rules, shift) {
        var error = null;
        var number = 6;

        if (rules.mealBreakSpacer && rules.ignoreMealBreak !== 1) {
            var mealBreaks = getMealBreaks(shift);
            mealBreaks.forEach(function (mealBreak) {
                if (mealBreak.fromStart < rules.mealBreakSpacer) {
                    error = {
                        number: number,
                        msg: 'Time between shift start and meal break start is less than ' + rules.mealBreakSpacer + ' hours',
                        severity: shiftErrors[number] || 0
                    };
                }
            });
        }

        return error;
    };

    ///////////////////////////////////////
    ///             Rule #7             ///
    ///////////////////////////////////////
    var mealBreakTooCloseToEnd = exports.mealBreakTooCloseToEnd = function mealBreakTooCloseToEnd(shiftErrors, rules, shift) {
        var error = null;
        var number = 7;

        if (rules.mealBreakSpacer && rules.ignoreMealBreak !== 1) {
            var mealBreaks = getMealBreaks(shift);
            mealBreaks.forEach(function (mealBreak) {
                if (mealBreak.fromEnd < rules.mealBreakSpacer) {
                    error = {
                        number: number,
                        msg: 'Time between meal break end and shift end is less than ' + rules.mealBreakSpacer + ' hours',
                        severity: shiftErrors[number] || 0
                    };
                }
            });
        }

        return error;
    };

    ///////////////////////////////////////
    ///             Rule #8             ///
    ///////////////////////////////////////
    var mealBreakTooCloseToPreviousBreak = exports.mealBreakTooCloseToPreviousBreak = function mealBreakTooCloseToPreviousBreak(shiftErrors, rules, shift) {
        var error = null;
        var number = 8;

        if (rules.mealBreakSpacer && rules.ignoreMealBreak !== 1) {
            var mealBreaks = getMealBreaks(shift);
            if (mealBreaks.length > 1) {
                mealBreaks.forEach(function (mealBreak) {
                    if (mealBreak.fromPrevBreak && mealBreak.fromPrevBreak < rules.mealBreakSpacer) {
                        error = {
                            number: number,
                            msg: 'Time between 1st meal break end and 2nd meal break start is less than ' + rules.mealBreakSpacer + ' hours',
                            severity: shiftErrors[number] || 0
                        };
                    }
                });
            }
        }

        return error;
    };

    ///////////////////////////////////////
    ///             Rule #9             ///
    ///////////////////////////////////////
    var insufficientNumberOfMealBreaks = exports.insufficientNumberOfMealBreaks = function insufficientNumberOfMealBreaks(shiftErrors, rules, shift, activities) {
        var error = null;
        var number = 9;

        if (rules.ignoreMealBreak !== 1) {
            var mealBreaks = getMealBreaks(shift);
            var restBreaks = getRestBreaks(shift);
            var shiftExtensionActivities = getShiftExtensionActivities(activities);
            var shiftBlocks = getShiftBlocks(shift, shiftExtensionActivities);

            if (shiftBlocks.length > 0 && (mealBreaks || restBreaks)) {
                var totalLength = getPaidShiftLength(shift, shiftExtensionActivities);
                var numRequired = totalLength > rules.mealBreak2 ? 2 : totalLength > rules.mealBreak1 ? 1 : 0;

                var numMealBreaks = mealBreaks ? mealBreaks.length : 0;
                var numRestBreaks = restBreaks ? restBreaks.length : 0;
                var numBreaks = numMealBreaks + numRestBreaks;

                if (numBreaks < numRequired) {
                    var text = numRequired === 2 ? '2 meal breaks are required' : 'A meal break is required';
                    error = {
                        number: number,
                        msg: '' + text,
                        severity: shiftErrors[number] || 0
                    };
                }
            }
        }

        return error;
    };

    ///////////////////////////////////////
    ///             Rule #10            ///
    ///////////////////////////////////////
    // TODO:
    // - Verify logic with team that this is needed and not duplicative of #9.
    // if (numOfRequiredMealBreaks > 0)

    ///////////////////////////////////////
    ///             Rule #11            ///
    ///////////////////////////////////////
    var restBreakInvalid = exports.restBreakInvalid = function restBreakInvalid(shiftErrors, rules, shift) {
        var error = null;
        var number = 11;

        if (rules.restBreakLength && rules.ignoreRestBreak !== 1) {
            var restBreaks = getRestBreaks(shift);
            restBreaks.forEach(function (restBreak) {
                if (restBreak.length !== rules.restBreakLength) {
                    error = {
                        number: number,
                        msg: 'Rest break length invalid. Required length: ' + rules.restBreakLength + ' Actual length: ' + restBreak.length,
                        severity: shiftErrors[number] || 0
                    };
                }
            });
        }

        return error;
    };

    ///////////////////////////////////////
    ///             Rule #12            ///
    ///////////////////////////////////////
    // TODO:
    // - Verify logic with team that this is needed and not duplicative of #13.

    ///////////////////////////////////////
    ///             Rule #13            ///
    ///////////////////////////////////////
    var restBreakTooCloseToStart = exports.restBreakTooCloseToStart = function restBreakTooCloseToStart(shiftErrors, rules, shift) {
        var error = null;
        var number = 13;
        if (rules.restBreakSpacer && rules.ignoreRestBreak !== 1) {
            var restBreaks = getRestBreaks(shift);
            restBreaks.forEach(function (restBreak) {
                if (restBreak.fromStart < rules.restBreakSpacer) {
                    error = {
                        number: number,
                        msg: 'Time between shift start and rest break start is less than ' + rules.restBreakSpacer + ' hours',
                        severity: shiftErrors[number] || 0
                    };
                }
            });
        }

        return error;
    };

    ///////////////////////////////////////
    ///             Rule #14            ///
    ///////////////////////////////////////
    var restBreakTooCloseToEnd = exports.restBreakTooCloseToEnd = function restBreakTooCloseToEnd(shiftErrors, rules, shift) {
        var error = null;
        var number = 14;

        if (rules.restBreakSpacer && rules.ignoreRestBreak !== 1) {
            var restBreaks = getRestBreaks(shift);
            restBreaks.forEach(function (restBreak) {
                if (restBreak.fromEnd < rules.restBreakSpacer) {
                    error = {
                        number: number,
                        msg: 'Time between shift end and rest break end is less than ' + rules.restBreakSpacer + ' hours',
                        severity: shiftErrors[number] || 0
                    };
                }
            });
        }

        return error;
    };

    ///////////////////////////////////////
    ///             Rule #15            ///
    ///////////////////////////////////////
    var restBreakTooCloseToPreviousBreak = exports.restBreakTooCloseToPreviousBreak = function restBreakTooCloseToPreviousBreak(shiftErrors, rules, shift) {
        var error = null;
        var number = 15;

        if (rules.restBreakSpacer && rules.ignoreRestBreak !== 1) {
            var restBreaks = getRestBreaks(shift);
            if (restBreaks.length > 1) {
                restBreaks.forEach(function (restBreak) {
                    if (restBreak.fromPrevBreak && restBreak.fromPrevBreak < rules.restBreakSpacer) {
                        error = {
                            number: number,
                            msg: 'Time between 1st rest break end and 2nd rest break start is less than ' + rules.restBreakSpacer + ' hours',
                            severity: shiftErrors[number] || 0
                        };
                    }
                });
            }
        }

        return error;
    };

    ///////////////////////////////////////
    ///             Rule #16            ///
    ///////////////////////////////////////
    var insufficientNumberOfRestBreaks = exports.insufficientNumberOfRestBreaks = function insufficientNumberOfRestBreaks(shiftErrors, rules, shift, activities) {
        var error = null;
        var number = 16;

        if (rules.ignoreRestBreak !== 1) {
            var restBreaks = getRestBreaks(shift);
            /*
            const firstBlock = getFirstBlock(shift)
            const lastBlock = getLastBlock(shift)
            const totalLength = moment(lastBlock.end).diff(moment(firstBlock.start), 'hours', true)
            */
            var shiftExtensionActivities = getShiftExtensionActivities(activities);
            var totalLength = getPaidShiftLength(shift, shiftExtensionActivities);
            var numRequired = totalLength > rules.restBreak3 ? 3 : totalLength > rules.restBreak2 ? 2 : totalLength > rules.restBreak1 ? 1 : 0;

            if (restBreaks.length < numRequired) {
                var deficiency = numRequired - restBreaks.length;
                var text1 = numRequired > 1 ? numRequired + ' rest breaks are required' : '1 rest break is required';
                var verb = restBreaks.length === 1 ? 'is' : 'are';
                var text2 = restBreaks.length + ' ' + verb + ' scheduled, ' + deficiency + ' missing';
                error = {
                    number: number,
                    msg: text1 + ', ' + text2,
                    severity: shiftErrors[number] || 0
                };
            }
        }

        return error;
    };

    ///////////////////////////////////////
    ///             Rule #17            ///
    ///////////////////////////////////////
    // TODO:
    // - Verify logic with team that this is needed and not duplicative of #16.

    ///////////////////////////////////////
    ///             Rule #18            ///
    ///////////////////////////////////////
    var validShiftExtension = exports.validShiftExtension = function validShiftExtension(shiftErrors, rules, shift, activities) {
        var error = null;
        var number = 18;

        if (rules.validateShiftExtension === 1) {
            var lastBlock = getLastBlock(shift);
            var firstBlock = getFirstBlock(shift);
            var shiftExtensionActivities = getShiftExtensionActivities(activities);
            var extensions = shift.blocks.filter(function (x) {
                return shiftExtensionActivities.includes(x.activityId);
            });

            // Only search thru shifts with a shift extension
            if (extensions && extensions.length > 0) {
                var isValid = true;

                extensions.forEach(function (ext) {
                    if (ext !== lastBlock) {
                        isValid = false;
                    }
                    if (ext === firstBlock) {
                        isValid = false;
                    }
                });

                if (isValid === false) {
                    error = {
                        number: number,
                        msg: 'Shift Extension may only be used to extend a shift',
                        severity: shiftErrors[number] || 0
                    };
                }
            }
        }

        return error;
    };

    ///////////////////////////////////////
    ///             Rule #19            ///
    ///////////////////////////////////////
    // export const employeeMeetsSkillRequirements = (shiftErrors, rules, shift) => {
    var employeeMeetsSkillRequirements = exports.employeeMeetsSkillRequirements = function employeeMeetsSkillRequirements() {
        var error = null;
        // const number = 19

        return error;
    };

    ///////////////////////////////////////
    ///             Rule #30            ///
    ///////////////////////////////////////
    // 2019-02-07 - Removed in favor of update to Rule #4 to make it look for min/max
    // export const mealBreakTooLong = (shiftErrors, rules, shift) => {
    //     let error = null
    //     const number = 30

    //     if (rules.mealBreakMaxLength) {
    //         const mealBreaks = getMealBreaks(shift)
    //         mealBreaks.forEach(mealBreak => {
    //             if (mealBreak.length > rules.mealBreakMaxLength) {
    //                 error = {
    //                     number,
    //                     msg: `Break length max (${
    //                         rules.mealBreakMaxLength
    //                     }) exceeded`,
    //                     severity: shiftErrors[number] || 0,
    //                 }
    //             }
    //         })
    //     }

    //     return error
    // }

    /* *****************************************************************************
     *                                   Rule #40                                  *
     *                                                                             *
     *                                   NY LUNCH                                  *
     *                                                                             *
     * IF (shiftstart <= 11am AND shiftend >= 2pm AND                              *
     *     shiftend - shiftstart >= 6 hrs); THEN                                   *
     *                                                                             *
     * -> Employee must have 30-min meal break between 11am and 2pm                *
     *                                                                             *
     * ************************************************************************** */
    var nyLunchBreak = exports.nyLunchBreak = function nyLunchBreak(shiftErrors, rules, shift) {
        var error = null;
        var number = 40;

        if (rules.nyLunch === 1) {
            var firstBlock = getFirstBlock(shift);
            var lastBlock = getLastBlock(shift);
            var totalLength = getTotalShiftLength(shift);

            var shiftStartTime = (0, _npmMoment.default)('11:00', 'H:mm');
            var shiftEndTime = (0, _npmMoment.default)('14:00', 'H:mm');

            var validMealBreak = false;

            if (getMinutesOfDay((0, _npmMoment.default)(firstBlock.start)) <= getMinutesOfDay(shiftStartTime) && getMinutesOfDay((0, _npmMoment.default)(lastBlock.end)) >= getMinutesOfDay(shiftEndTime) && totalLength >= 6) {

                var mealBreaks = getMealBreaks(shift);
                mealBreaks.forEach(function (mealBreak) {
                    if (mealBreakAtLeastBetween(mealBreak, 0.5, '11:00', '14:00')) {
                        validMealBreak = true;
                    }
                });
                if (!validMealBreak) {
                    error = {
                        number: number,
                        msg: 'A 30-min meal break between 11am and 2pm is required',
                        severity: shiftErrors[number] || 0
                    };
                }
            }
        }

        return error;
    };

    /* *****************************************************************************
     *                                   Rule #41                                  *
     *                                                                             *
     *                              NY LUNCH AND DINNER                            *
     *                                                                             *
     * IF (shiftstart <= 11am AND shiftend >= 7pm); THEN                           *
     *                                                                             *
     * -> Employee must have 30-min meal break between 5pm and 7pm                 *
     *    (in addition to lunch meal above)                                        *
     *                                                                             *
      * ************************************************************************** */
    var nyLunchDinnerBreak = exports.nyLunchDinnerBreak = function nyLunchDinnerBreak(shiftErrors, rules, shift) {
        var error = null;
        var number = 41;

        if (rules.nyLunchDinner === 1) {
            var firstBlock = getFirstBlock(shift);
            var lastBlock = getLastBlock(shift);

            var shiftStartTime = (0, _npmMoment.default)('11:00', 'H:mm');
            var shiftEndTime = (0, _npmMoment.default)('19:00', 'H:mm');

            var validMealBreak = false;

            if (getMinutesOfDay((0, _npmMoment.default)(firstBlock.start)) <= getMinutesOfDay(shiftStartTime) && getMinutesOfDay((0, _npmMoment.default)(lastBlock.end)) >= getMinutesOfDay(shiftEndTime)) {

                var mealBreaks = getMealBreaks(shift);
                mealBreaks.forEach(function (mealBreak) {
                    if (mealBreakAtLeastBetween(mealBreak, 0.5, '17:00', '19:00')) {
                        validMealBreak = true;
                    }
                });
                if (!validMealBreak) {
                    error = {
                        number: number,
                        msg: 'A 30-min meal break between 5pm and 7pm is required',
                        severity: shiftErrors[number] || 0
                    };
                }
            }
        }

        return error;
    };

    /* *****************************************************************************
     *                                   Rule #42                                  *
     *                                                                             *
     *                                  NY DINNER                                  *
     *                                                                             *
     * IF (shiftstart >= 1pm AND shiftend - shiftstart >= 6 hrs); THEN             *
     *                                                                             *
     * -> Employee must have 45-min meal break "midway between the beginning       *
     *    and end of shift"                                                        *
     * ************************************************************************** */
    var nyDinnerBreak = exports.nyDinnerBreak = function nyDinnerBreak(shiftErrors, rules, shift) {
        var error = null;
        var number = 42;

        if (rules.nyDinner === 1) {
            var firstBlock = getFirstBlock(shift);
            var totalLength = getTotalShiftLength(shift);

            var shiftStartTime = (0, _npmMoment.default)('13:00', 'H:mm');
            var validMealBreak = false;

            if (getMinutesOfDay((0, _npmMoment.default)(firstBlock.start)) >= getMinutesOfDay(shiftStartTime) && totalLength >= 6) {

                var mealBreaks = getMealBreaks(shift);
                mealBreaks.forEach(function (mealBreak) {
                    if (mealBreak.length >= 0.75) {
                        validMealBreak = true;
                    }
                });
                if (!validMealBreak) {
                    error = {
                        number: number,
                        msg: 'A 45-min meal break is required',
                        severity: shiftErrors[number] || 0
                    };
                }
            }
        }

        return error;
    };
});