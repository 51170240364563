define('matrix-frontend/pods/components/schedule-page-part-segment-unavailable/component', ['exports', 'ember-redux'], function (exports, _emberRedux) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var stateToComputed = function stateToComputed(state) {
        return {
            clients: state.client.clients,
            node: state.cube.node,
            displayDate: state.cube.date,
            displayDOTW: state.cube.dotw
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {};
    };

    var Comp = Ember.Component.extend({
        date: Ember.computed('displayDOTW', 'displayDate', 'segmentDOTW', function () {
            var displayDOTW = this.get('displayDOTW');
            var displayDate = this.get('displayDate');
            var segmentDOTW = this.get('segmentDOTW');
            return displayDate.add(segmentDOTW - displayDOTW, 'days');
        }),

        activityLiveColorId: Ember.computed('shift', 'currentBrush.{startMoment,paintMoment}', function () {
            return null;
            // const activitiesById = this.get('activitiesById')
            // const shift = this.get('shift')
            // const parts = this.get('segment').split('.')
            // let activityId = null

            // if (shift) {
            //     let t = shift.meta.date.clone()
            //     t.hour(parts[0])
            //     t.minute(parts[1])

            //     const block = shift.blocks.find(x => {
            //         return t >= x.start && t < x.end
            //     })

            //     if (block) {
            //         activityId = block.activityId
            //     }
            // }

            // if (activityId === null) {
            //     return null
            // }

            // const activity = activitiesById[activityId]
            // return activity.livecolor.id
        })
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});