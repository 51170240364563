define('matrix-frontend/pods/schedule/controller', ['exports', 'matrix-frontend/mixins/base-controller'], function (exports, _baseController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_baseController.default, {
        showStore: Ember.computed('model.businessUnit.type', function () {
            return this.model.businessUnit.type === 'store';
        }),

        doLegacy: Ember.computed('', function () {
            return !this.session.get('user').get('roles').any(function (role) {
                return 'feature_schedule_edit' === role.get('name');
            });
        }),

        actions: {
            transInController: function transInController(routeName) {
                console.log('transInController');
                // this.transitionToRoute(routeName);
            }
        }
    });
});