define('matrix-frontend/components/payroll-worksheet-detail', ['exports', 'ember-react-components', 'matrix-frontend/store', 'matrix-frontend/utils/state', 'matrix-frontend/actions/_types', 'npm:moment', 'react', 'npm:react-tooltip', 'matrix-frontend/utils/format-helpers', 'lodash', 'matrix-frontend/utils/interactions', 'matrix-frontend/utils/functional-sort', 'matrix-frontend/services/notification-service'], function (exports, _emberReactComponents, _store, _state, _types, _npmMoment, _react, _npmReactTooltip, _formatHelpers, _lodash2, _interactions, _functionalSort, _notificationService) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var DataCell = function DataCell(props) {
        var content = props.content,
            classes = props.classes,
            style = props.style,
            tooltipAttrs = props.tooltipAttrs,
            showOutline = props.showOutline,
            editable = props.editable,
            onChange = props.onChange,
            formatFunc = props.formatFunc,
            onClick = props.onClick,
            empty = props.empty,
            extraProps = props.extraProps;


        if (empty) {
            return _react.default.createElement('div', { className: 'column f1 gray-lighten-2-text' });
        }

        if (content === undefined) {
            return _react.default.createElement('div', { className: 'column f1 fake-text gray-lighten-2-text' });
        }

        if (editable) {
            var _useState = (0, _react.useState)({
                value: '',
                madeEdit: false,
                initialValue: ''
            }),
                _useState2 = _slicedToArray(_useState, 2),
                state = _useState2[0],
                setState = _useState2[1];

            (0, _react.useLayoutEffect)(function () {
                if (!editable) return;
                setState(_extends({}, state, { value: formatFunc(content) }));
            }, [content]);

            var onKeyPress = function onKeyPress(e) {
                if (e.key == 'Enter') {
                    e.target.blur();
                }
            };

            var onElementChange = function onElementChange(e) {
                setState(_extends({}, state, {
                    madeEdit: true,
                    value: e.target.value
                }));
            };

            var getParsedValue = function getParsedValue(s) {
                var x = parseFloat(s.replace(/,/g, ''));
                if (isNaN(x)) return null;
                return Math.max(x, 0.0001);
            };

            var onFocus = function onFocus(e) {
                setState(_extends({}, state, {
                    madeEdit: false,
                    initialValue: content
                }));
                e.target.select();
            };

            var onBlur = function onBlur(e) {
                var value = getParsedValue(state.value);
                if (state.madeEdit && onChange && value != null) {
                    onChange(value);
                }
                if (value == null) value = state.initialValue;
                setState(_extends({}, state, {
                    value: formatFunc(value)
                }));
            };

            return _react.default.createElement(
                'div',
                _extends({ className: classes.join(' '), style: style }, tooltipAttrs),
                showOutline ? _react.default.createElement('div', { className: 'hover-outline' }) : null,
                _react.default.createElement('input', { className: 'no-style-input text-right  0.3rem 0.625rem', onKeyDown: onKeyPress, onFocus: onFocus, onChange: onElementChange, onBlur: onBlur, value: state.value || '' })
            );
        }

        if (onClick) {
            classes.push('text-editable');
        }

        return _react.default.createElement(
            'div',
            _extends({ className: classes.join(' '), style: style }, tooltipAttrs, { onClick: onClick ? function (_) {
                    return onClick();
                } : null }, extraProps),
            content,
            showOutline ? _react.default.createElement('div', { className: 'hover-outline' }) : null
        );
    };

    var DataSection = function DataSection(props) {
        var timeColumns = props.timeColumns,
            rollup = props.rollup,
            contentFunc = props.contentFunc;


        return timeColumns.map(function (tc, i) {
            if (rollup) {
                return _react.default.createElement(DataCell, _extends({ key: tc.key }, contentFunc(tc, rollup[tc.key])));
            }
            return _react.default.createElement(DataCell, { key: tc.key, empty: rollup === null });
        });
    };

    var Row = function (_PureComponent) {
        _inherits(Row, _PureComponent);

        function Row() {
            _classCallCheck(this, Row);

            return _possibleConstructorReturn(this, (Row.__proto__ || Object.getPrototypeOf(Row)).apply(this, arguments));
        }

        _createClass(Row, [{
            key: 'render',
            value: function render() {
                var _props = this.props,
                    id = _props.id,
                    titleContent = _props.titleContent,
                    dataContent = _props.dataContent,
                    collapsible = _props.collapsible,
                    collapsed = _props.collapsed,
                    childrenHeight = _props.childrenHeight,
                    totalRowText = _props.totalRowText,
                    level = _props.level,
                    withBorder = _props.withBorder,
                    withDataBorder = _props.withDataBorder,
                    onToggleCollapsed = _props.onToggleCollapsed,
                    extraChildren = _props.extraChildren;


                var levelClass = level ? 'level-' + level : '';

                var rowClass = 'row ' + levelClass;
                if (!withBorder) rowClass += ' no-border';
                var borderClass = withDataBorder ? 'with-border' : '';

                var indentPerLevel = 20;

                var content = void 0;
                var expandIndicator = void 0;
                if (collapsible) {
                    var onClick = onToggleCollapsed ? function (_) {
                        onToggleCollapsed();
                    } : null;
                    expandIndicator = _react.default.createElement('div', { key: 'indicator', className: 'expanded-indicator ' + (collapsed ? 'icon-plus-circle' : 'icon-minus-circle'), onClick: onClick });

                    var left = 12 + (level - 1) * indentPerLevel;
                    content = _react.default.createElement(
                        'div',
                        { className: 'children-inner', style: {
                                maxHeight: childrenHeight ? childrenHeight + 'px' : 0
                            } },
                        this.props.children,
                        _react.default.createElement(
                            'div',
                            { className: 'group-line-container' },
                            _react.default.createElement('div', { className: 'group-line', style: { left: left } })
                        )
                    );
                }

                var indentWidth = 26 + (level - 1) * indentPerLevel;
                var preTitleContent = _react.default.createElement(
                    'div',
                    { className: 'indicator-container', style: { width: indentWidth } },
                    expandIndicator
                );

                return _react.default.createElement(
                    _react.Fragment,
                    null,
                    _react.default.createElement(
                        'div',
                        { className: rowClass },
                        _react.default.createElement(
                            'div',
                            { className: 'wrapper f3' },
                            preTitleContent,
                            titleContent
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'wrapper f8 data-cells ' + borderClass },
                            collapsed && collapsible && totalRowText && false ? null : dataContent,
                            extraChildren
                        )
                    ),
                    content
                );
            }
        }]);

        return Row;
    }(_react.PureComponent);

    var basicContentFunc = function basicContentFunc(getContent, getIsProjected, getTooltipAttrs) {
        return function (tc, data) {
            var classes = ['column f1 text-right'];
            if (getIsProjected && getIsProjected(tc, data)) {
                classes.push('italics');
            }
            var tooltipAttrs = getTooltipAttrs ? getTooltipAttrs(tc, data) : null;
            return {
                content: getContent(tc, data),
                classes: classes,
                tooltipAttrs: tooltipAttrs
            };
        };
    };

    var simpleContentFunc = function simpleContentFunc(getContent) {
        return function (tc, data) {
            var _getContent = getContent(tc, data),
                content = _getContent.content,
                projected = _getContent.projected,
                tooltipAttrs = _getContent.tooltipAttrs,
                showOutline = _getContent.showOutline,
                editable = _getContent.editable,
                onChange = _getContent.onChange,
                formatFunc = _getContent.formatFunc,
                onClick = _getContent.onClick,
                extraProps = _getContent.extraProps;

            var classes = ['column f1 text-right'];
            if (projected) {
                classes.push('italics');
            }
            var style = {};
            if (editable) {
                classes.push('text-editable');
            }
            if (showOutline) {
                classes.push('with-hover-outline');
            }
            return {
                content: content,
                classes: classes,
                style: style,
                tooltipAttrs: tooltipAttrs,
                editable: editable,
                onChange: onChange,
                formatFunc: formatFunc,
                onClick: onClick,
                extraProps: extraProps,
                showOutline: showOutline
            };
        };
    };

    var current;
    var payrollCostActuals;

    var DetailTable = function DetailTable(props) {
        var title = props.title,
            timeColumns = props.timeColumns,
            structure = props.structure,
            bottomMargin = props.bottomMargin,
            collapsible = props.collapsible,
            realtime = props.realtime;

        var _useState3 = (0, _react.useState)({}),
            _useState4 = _slicedToArray(_useState3, 2),
            expandedRows = _useState4[0],
            setExpandedRows = _useState4[1];

        var collapsibleRows = [];

        var isRowCollapsed = function isRowCollapsed(path) {
            return !expandedRows[path];
        };

        var onToggleRowCollapsed = function onToggleRowCollapsed(path) {
            var rowsExpanded = _extends({}, expandedRows);
            rowsExpanded[path] = isRowCollapsed(path);
            setExpandedRows(rowsExpanded);
        };

        var makeRow = function makeRow(item) {
            var id = item.id,
                currentPath = item.currentPath,
                level = item.level,
                children = item.children,
                extraProps = item.extraProps;

            var path = currentPath ? currentPath + '.' + id : id;
            var rowLevel = level || 1;
            var collapsible = children && children.length;
            var collapsed = isRowCollapsed(path);
            var height = 40;

            if (collapsible) {
                collapsibleRows.push(path);
            }

            var childrenHeight = 0;

            var createChild = function createChild(child) {
                var childData = makeRow(_extends({}, child, {
                    currentPath: path,
                    level: rowLevel + 1
                }));
                if (collapsible && !collapsed) {
                    childrenHeight += childData.height + childData.childrenHeight;
                }
                return childData.component;
            };

            var childComponents = children ? children.map(createChild) : null;

            var props = _extends({
                id: id,
                level: rowLevel,
                withDataBorder: true,
                collapsible: collapsible,
                collapsed: collapsed,
                childrenHeight: childrenHeight,
                onToggleCollapsed: function onToggleCollapsed() {
                    return onToggleRowCollapsed(path);
                }
            }, extraProps);

            return {
                height: height,
                childrenHeight: childrenHeight,
                component: _react.default.createElement(
                    Row,
                    _extends({ key: id }, props),
                    childComponents
                )
            };
        };

        var rootComponents = structure.map(function (x) {
            return makeRow(x).component;
        });

        var FullExpandCollapser = function FullExpandCollapser(props) {
            var willExpand = collapsibleRows.reduce(function (agg, path) {
                return agg + (expandedRows[path] ? 1 : 0);
            }, 0) <= collapsibleRows.length / 2;

            var onClick = function onClick(_) {
                var rowsExpanded = _extends({}, expandedRows);
                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    for (var _iterator = collapsibleRows[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var path = _step.value;

                        rowsExpanded[path] = willExpand;
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return) {
                            _iterator.return();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }

                setExpandedRows(rowsExpanded);
            };

            return _react.default.createElement('div', { className: 'expanded-indicator ' + (willExpand ? 'icon-plus-circle' : 'icon-minus-circle'), onClick: onClick });
        };

        var realTimeWarning = !realtime && current ? ' as of ' + current : '';

        return _react.default.createElement(
            'div',
            { className: (collapsible ? 'detail-table' : 'detail-table flat') + ' flex-table ' + (bottomMargin ? 'bottom-margin' : null) },
            _react.default.createElement(
                'div',
                { className: 'head' },
                _react.default.createElement(
                    'div',
                    { className: 'row' },
                    _react.default.createElement(
                        'div',
                        { className: 'wrapper f3' },
                        _react.default.createElement(
                            'div',
                            { className: 'expander-wrapper' },
                            _react.default.createElement(FullExpandCollapser, null)
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'column f1 text title-cell' },
                            _react.default.createElement(
                                'div',
                                null,
                                '' + title,
                                _react.default.createElement(
                                    'span',
                                    { className: 'realtime-warning' },
                                    realTimeWarning
                                )
                            )
                        )
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'wrapper f8' },
                        timeColumns.map(function (tc) {
                            return _react.default.createElement(
                                'div',
                                { key: tc.key, className: 'column f1' },
                                tc.text
                            );
                        })
                    )
                )
            ),
            _react.default.createElement(
                'div',
                { className: 'body' },
                rootComponents
            )
        );
    };

    var ControlPanel = function ControlPanel(props) {
        var text = props.text,
            showSaveControls = props.showSaveControls,
            discardAction = props.discardAction,
            saveAction = props.saveAction;


        var saveControls = showSaveControls ? _react.default.createElement(
            _react.Fragment,
            null,
            _react.default.createElement(
                'div',
                { className: 'button-group save-controls no-margin' },
                _react.default.createElement(
                    'span',
                    { className: 'tiny primary-text breathe-r-5' },
                    text
                ),
                _react.default.createElement(
                    'button',
                    { className: 'secondary thin breathe-r-5', onClick: discardAction },
                    _react.default.createElement('span', { className: 'icon-trash' })
                ),
                _react.default.createElement(
                    'button',
                    { className: 'thin', onClick: saveAction },
                    'Save'
                )
            )
        ) : null;

        return _react.default.createElement(
            'div',
            { className: 'control-panel' },
            saveControls
        );
    };

    var Body = function Body(props) {
        var client = props.client,
            clientCode = props.clientCode,
            weekEndDate = props.weekEndDate,
            node = props.node,
            nodeHierarchy = props.nodeHierarchy,
            rollup = props.rollup,
            infoRollup = props.infoRollup,
            timeColumns = props.timeColumns,
            budgets = props.budgets,
            activities = props.activities,
            tasks = props.tasks,
            items = props.items,
            storeitems = props.storeitems,
            modalService = props.modalService;


        var weekEndDtStr = weekEndDate.format('YYYY-MM-DD').toString();
        var rollupMiscEndDt = rollup && Object.hasOwn(rollup, 'misc') ? rollup.misc[weekEndDtStr] : null;

        if (rollupMiscEndDt) {
            current = rollupMiscEndDt.reportTimeStamp;
            var mapPayrollCostActuals = function mapPayrollCostActuals() {
                var newKeys = [];
                for (var i = 0; i < 7; i++) {
                    var tday = (0, _npmMoment.default)(weekEndDate, "YYYY-MM-DD").startOf('day').add(-6 + i, 'days').format("YYYY-MM-DD").toString();
                    var boo = rollupMiscEndDt && Object.hasOwn(rollupMiscEndDt.payrollCostActuals, tday) && rollupMiscEndDt.payrollCostActuals[tday] === 'actuals';
                    newKeys.push(boo);
                }
                return newKeys;
            };
            payrollCostActuals = mapPayrollCostActuals();
        }

        if (!(client && client.options)) return _react.default.createElement('div', null);

        var weekStart = weekEndDate.clone().add(-6, 'days').set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
        var isStore = node.nodeType === 'store';
        var missingAnyActualSales = !rollup ? false : [].concat(_toConsumableArray(Array(7).keys())).some(function (docw) {
            return isStore ? rollup[docw].sales.actual == null : rollup[docw].sales.actual.count != rollup[docw].storeCount;
        });
        var missingAnyActualHours = !rollup ? false : [].concat(_toConsumableArray(Array(7).keys())).some(function (docw) {
            return isStore ? rollup[docw].hours.actual == null : rollup[docw].hours.actual.count != rollup[docw].storeCount;
        });
        var missingAnyActualPayrollCost = !payrollCostActuals ? false : !rollup ? false : payrollCostActuals.some(function (e) {
            return !e;
        });

        var _useState5 = (0, _react.useState)({
            edits: {}
        }),
            _useState6 = _slicedToArray(_useState5, 2),
            state = _useState6[0],
            setState = _useState6[1];

        var _useState7 = (0, _react.useState)(null),
            _useState8 = _slicedToArray(_useState7, 2),
            hoverItem = _useState8[0],
            setHoverItem = _useState8[1];

        // clear edits when new values come in
        (0, _react.useLayoutEffect)(function () {
            // console.info('clear edits')
            setState(_extends({}, state, {
                edits: {}
            }));
        }, [rollup]);

        var numEdits = Object.keys(state.edits).length;
        var hasEdits = numEdits != 0;
        // console.info(hasEdits)
        // console.info(state.edits)

        var getEditValue = function getEditValue(key, tc, defaultReturn) {
            var edit = state.edits[key + '.' + tc.key];
            return edit === undefined ? defaultReturn : edit;
        };

        var setEditValue = function setEditValue(key, tc, value) {
            var edits = _extends({}, state.edits);
            edits[key + '.' + tc.key] = value;
            setState(_extends({}, state, {
                edits: edits
            }));
        };

        var onSaveClick = function onSaveClick() {
            // console.info("SAVE")
            var payload = {
                forecasts: []
            };

            var docws = [].concat(_toConsumableArray(Array(7).keys()));
            docws.forEach(function (docw) {
                var currentForecast = state.edits['currentForecast.' + docw];
                if (currentForecast !== undefined) {
                    payload.forecasts.push({
                        current_forecast: currentForecast,
                        demand_date: weekStart.clone().add(docw, 'days').format('YYYY-MM-DD')
                    });
                }
            });

            var weekEndStr = weekEndDate.format('YYYY-MM-DD');

            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'data',
                    namespace: ['payrollworksheet', 'data'],
                    operation: 1,
                    include_channel: 2,
                    broadcast: false,
                    payload: payload,
                    params: {
                        week_end_date: weekEndStr
                    }
                }
            }, 'update payrollworksheet data', false, null, null, function (results) {
                // console.info(results)
                if (results.ok) {
                    if (results.cached.forecast) {
                        _store.store.dispatch({
                            type: _types.NODE_INTAKE_REPORT_DATA,
                            payload: {
                                meta: {
                                    args: {
                                        client_code: clientCode,
                                        tag: node.tag,
                                        report_type: 'salesforecast',
                                        focus: 'day',
                                        week_end_date: weekEndStr
                                    }
                                },
                                results: _defineProperty({}, node.tag, results.cached.forecast)
                            }
                        });
                    }
                } else {
                    var service = new _notificationService.default();
                    service.error();
                }
            }, true);
        };

        var onDiscardClick = function onDiscardClick() {
            // console.info('discard')
            setState({
                edits: {}
            });
        };

        var editedRollup = _lodash2.default.cloneDeep(rollup);
        if (isStore && hasEdits) {
            // add value to another dict, keeping note of undefined and null
            var applyValue = function applyValue(dst, src, key) {
                if (src[key] === undefined) {
                    return;
                }
                if (src[key] === null && dst[key] === undefined) {
                    dst[key] = null;
                } else if (src[key] !== null) {
                    dst[key] = (dst[key] || 0) + src[key];
                }
            };

            // clear total values
            var salesTotal = editedRollup.total.sales;
            salesTotal.actualOrCurrent = null;
            salesTotal.currentForecast = null;
            salesTotal.actualOrCurrentVsLy.reset();
            salesTotal.actualOrCurrentVsOriginalForecast.reset();
            salesTotal.currentVsOriginalForecast.reset();

            // recalc each day
            timeColumns.forEach(function (tc) {
                var sales = editedRollup[tc.key].sales;
                if (tc.key !== 'total') {
                    sales.currentForecast = getEditValue('currentForecast', tc, sales.currentForecast);
                    if (sales.actual == null) {
                        sales.actualOrCurrent = sales.currentForecast;
                        sales.actualOrCurrentVsOriginalForecast.reset();
                        sales.actualOrCurrentVsOriginalForecast.add(sales.currentForecast, sales.originalForecast);
                        sales.actualOrCurrentVsLy.reset();
                        sales.actualOrCurrentVsLy.add(sales.currentForecast, sales.lyActual);
                    }
                    sales.currentVsOriginalForecast.reset();
                    sales.currentVsOriginalForecast.add(sales.currentForecast, sales.originalForecast);

                    // apply to total
                    applyValue(salesTotal, sales, 'actualOrCurrent');
                    applyValue(salesTotal, sales, 'currentForecast');
                    salesTotal.actualOrCurrentVsLy.addFrom(sales.actualOrCurrentVsLy);
                    salesTotal.actualOrCurrentVsOriginalForecast.addFrom(sales.actualOrCurrentVsOriginalForecast);
                    salesTotal.currentVsOriginalForecast.addFrom(sales.currentVsOriginalForecast);
                }
            });
        }

        var FORECAST1_LABEL = (0, _state.getClientOption)('forecast1_label', 'label');
        var FORECAST3_LABEL = (0, _state.getClientOption)('forecast3_label', 'label');
        var ACTUAL_OR_SHORT_FORECAST3 = 'Actual | ' + (0, _state.getClientOption)('forecast3_label', 'label', { short: true, target: 'pw_detail' });

        var makeInfoTooltip = function makeInfoTooltip(baseText) {
            return {
                'data-tip': isStore ? baseText : baseText + ' Rollups are a total of the displayed value from each store in the rollup.',
                'data-for': 'basicTooltip',
                'data-place': 'bottom'
            };
        };

        var trendHeaderComp = null;
        if (isStore && missingAnyActualSales) {
            var changes = 0;
            var edits = _extends({}, state.edits);
            for (var dotw = 0; dotw < 7; dotw++) {
                var sales = editedRollup[dotw].sales;

                if (sales.actual == null && sales.trend != null) {
                    if (Math.abs(sales.trend - sales.currentForecast) > 0.01) {
                        console.info(dotw, sales.trend, sales.currentForecast);
                        edits['currentForecast.' + dotw] = sales.trend;
                        changes++;
                    }
                }
            }
            if (changes > 0) {
                trendHeaderComp = _react.default.createElement(
                    'div',
                    { className: 'column f1 text no-border', style: {
                            justifyContent: 'space-between'
                        } },
                    _react.default.createElement(
                        'span',
                        { className: 'italics' },
                        FORECAST3_LABEL
                    ),
                    _react.default.createElement(
                        'a',
                        _extends({ href: '#', onClick: function onClick(e) {
                                setState(_extends({}, state, {
                                    edits: edits
                                }));
                            } }, makeInfoTooltip('Click to update the ' + FORECAST3_LABEL + ' with the Trend - not applied on days with Actual sales.')),
                        'Apply Trend'
                    )
                );
            }
        }

        var salesRowStructure = [{
            id: 'actualOrForecast',
            extraProps: {
                titleContent: _react.default.createElement(
                    'div',
                    _extends({ className: 'column f1 text no-border'
                    }, makeInfoTooltip('Actual sales are displayed when available - otherwise ' + FORECAST3_LABEL.toLowerCase() + ' is displayed.')),
                    'Actual Sales |',
                    _react.default.createElement(
                        'span',
                        { className: 'italics breathe-l-5' },
                        FORECAST3_LABEL
                    )
                ),
                dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: editedRollup, contentFunc: function contentFunc(tc, data) {
                        if (isStore || tc.key === 'total') {
                            return basicContentFunc(function (tc, data) {
                                return (0, _formatHelpers.numberFormat)(data.sales.actualOrCurrent);
                            }, function (tc, data) {
                                return tc.key === 'total' ? missingAnyActualSales : data.sales.actual == null;
                            })(tc, data);
                        } else {
                            return basicContentFunc(function (tc, data) {
                                return (0, _formatHelpers.numberFormat)(data.sales.actualOrCurrent());
                            }, function (tc, data) {
                                return data.sales.currentForecastNoActual.count != 0;
                            }, function (tc, data) {
                                return {
                                    'data-tip': 'sales|actualOrCurrent|' + tc.key,
                                    'data-place': 'bottom',
                                    'data-for': 'infoTooltip'
                                };
                            })(tc, data);
                        }
                    } })
            },
            children: [{
                id: 'actual',
                extraProps: {
                    titleContent: _react.default.createElement(
                        'div',
                        { className: 'column f1 text no-border' },
                        'Actual Sales'
                    ),
                    dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: rollup, contentFunc: function contentFunc(tc, data) {
                            if (isStore || tc.key === 'total') {
                                return basicContentFunc(function (tc, data) {
                                    return (0, _formatHelpers.numberFormat)(data.sales.actual);
                                })(tc, data);
                            } else {
                                return basicContentFunc(function (tc, data) {
                                    return (0, _formatHelpers.numberFormat)(data.sales.actual.total);
                                }, null, function (tc, data) {
                                    return {
                                        'data-tip': 'sales|actual|' + tc.key,
                                        'data-place': 'bottom',
                                        'data-for': 'infoTooltip'
                                    };
                                })(tc, data);
                            }
                        } })
                },
                children: [{
                    id: 'vsScheduleForecast',
                    extraProps: {
                        titleContent: _react.default.createElement(
                            'div',
                            { className: 'column f1 text no-border' },
                            'vs. ',
                            FORECAST1_LABEL
                        ),
                        dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: rollup, contentFunc: basicContentFunc(function (tc, data) {
                                return (0, _formatHelpers.numberFormat)(data.sales.actualVsOriginalForecast.variance());
                            }, null, function (tc, data) {
                                return data.sales.actualVsOriginalForecast.count() > 0 ? {
                                    'data-tip': 'sales|actualVsOriginalForecast|' + tc.key,
                                    'data-place': 'bottom',
                                    'data-for': 'varianceTooltip'
                                } : null;
                            }) })
                    }
                }, {
                    id: 'variancePercent',
                    extraProps: {
                        titleContent: _react.default.createElement(
                            'div',
                            { className: 'column f1 text no-border' },
                            '% Variance'
                        ),
                        dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: rollup, contentFunc: basicContentFunc(function (tc, data) {
                                return (0, _formatHelpers.percentFormat)(data.sales.actualVsOriginalForecast.percentVariance());
                            }) })
                    }
                }]
            }, {
                id: 'trend',
                extraProps: {
                    titleContent: _react.default.createElement(
                        'div',
                        _extends({ className: 'column f1 text no-border'
                        }, makeInfoTooltip('Trend scales the ' + FORECAST1_LABEL + ' proportional to actual sales to provide a projection for the remainder of the week. Actual sales are displayed when available - otherwise Trend is displayed.')),
                        'Trend'
                    ),
                    dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: rollup, contentFunc: function contentFunc(tc, data) {
                            if (isStore || tc.key === 'total') {
                                return basicContentFunc(function (tc, data) {
                                    return (0, _formatHelpers.numberFormat)(data.sales.trend);
                                }, function (tc, data) {
                                    return tc.key === 'total' ? missingAnyActualSales : isStore ? data.sales.actual == null : data.sales.trend.count != 0;
                                })(tc, data);
                            } else {
                                return basicContentFunc(function (tc, data) {
                                    return (0, _formatHelpers.numberFormat)(data.sales.trendValue());
                                }, function (tc, data) {
                                    return data.sales.trend.count != 0;
                                }, function (tc, data) {
                                    return data.sales.trendValue() != null ? {
                                        'data-tip': 'sales|trend|' + tc.key,
                                        'data-place': 'bottom',
                                        'data-for': 'infoTooltip'
                                    } : 0;
                                })(tc, data);
                            }
                        } })
                },
                children: [{
                    id: 'vsScheduleForecast',
                    extraProps: {
                        titleContent: _react.default.createElement(
                            'div',
                            { className: 'column f1 text no-border' },
                            'vs. ',
                            FORECAST1_LABEL
                        ),
                        dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: rollup, contentFunc: basicContentFunc(function (tc, data) {
                                return (0, _formatHelpers.numberFormat)(data.sales.trendVsOriginalForecast ? data.sales.trendVsOriginalForecast.variance() : null);
                            }, function (tc, data) {
                                return tc.key === 'total' ? missingAnyActualSales : isStore ? data.sales.actual == null : data.sales.trend.count != 0;
                            }, function (tc, data) {
                                return data.sales.trendVsOriginalForecast && data.sales.trendVsOriginalForecast.count() > 0 ? {
                                    'data-tip': 'sales|trendVsOriginalForecast|' + tc.key,
                                    'data-place': 'bottom',
                                    'data-for': 'varianceTooltip'
                                } : null;
                            }) })
                    }
                }, {
                    id: 'variancePercent',
                    extraProps: {
                        titleContent: _react.default.createElement(
                            'div',
                            { className: 'column f1 text no-border' },
                            '% Variance'
                        ),
                        dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: rollup, contentFunc: basicContentFunc(function (tc, data) {
                                return (0, _formatHelpers.percentFormat)(data.sales.trendVsOriginalForecast ? data.sales.trendVsOriginalForecast.percentVariance() : null);
                            }, function (tc, data) {
                                return tc.key === 'total' ? missingAnyActualSales : isStore ? data.sales.actual == null : data.sales.trend.count != 0;
                            }) })
                    }
                }]
            }, {
                id: 'currentForecast',
                extraProps: {
                    titleContent: trendHeaderComp || _react.default.createElement(
                        'div',
                        { className: 'column f1 text no-border italics' },
                        FORECAST3_LABEL
                    ),
                    dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: editedRollup, contentFunc: simpleContentFunc(function (tc, data) {
                            var editable = isStore && tc.key !== 'total';
                            return {
                                content: editable ? data.sales.currentForecast : (0, _formatHelpers.numberFormat)(data.sales.currentForecast),
                                projected: true,
                                editable: editable,
                                formatFunc: _formatHelpers.numberFormat,
                                showOutline: editable,
                                onChange: function onChange(x) {
                                    setEditValue('currentForecast', tc, x);
                                }
                            };
                        }) })
                },
                children: [{
                    id: 'vsScheduleForecast',
                    extraProps: {
                        titleContent: _react.default.createElement(
                            'div',
                            { className: 'column f1 text no-border' },
                            'vs. ',
                            FORECAST1_LABEL
                        ),
                        dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: editedRollup, contentFunc: basicContentFunc(function (tc, data) {
                                return (0, _formatHelpers.numberFormat)(data.sales.currentVsOriginalForecast.variance());
                            }, function (tc, data) {
                                return true;
                            }, function (tc, data) {
                                return data.sales.currentVsOriginalForecast.count() > 0 ? {
                                    'data-tip': 'sales|currentVsOriginalForecast|' + tc.key,
                                    'data-place': 'bottom',
                                    'data-for': 'varianceTooltip'
                                } : null;
                            }) })
                    }
                }, {
                    id: 'variancePercent',
                    extraProps: {
                        titleContent: _react.default.createElement(
                            'div',
                            { className: 'column f1 text no-border' },
                            '% Variance'
                        ),
                        dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: editedRollup, contentFunc: basicContentFunc(function (tc, data) {
                                return (0, _formatHelpers.percentFormat)(data.sales.currentVsOriginalForecast.percentVariance());
                            }, function (tc, data) {
                                return true;
                            }) })
                    }
                }]
            }, {
                id: 'lySales',
                extraProps: {
                    titleContent: _react.default.createElement(
                        'div',
                        { className: 'column f1 text no-border' },
                        'LY Sales'
                    ),
                    dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: rollup, contentFunc: function contentFunc(tc, data) {
                            if (isStore || tc.key === 'total') {
                                return basicContentFunc(function (tc, data) {
                                    return (0, _formatHelpers.numberFormat)(data.sales.lyActual);
                                })(tc, data);
                            } else {
                                return basicContentFunc(function (tc, data) {
                                    return (0, _formatHelpers.numberFormat)(data.sales.lyActual.total);
                                }, null, function (tc, data) {
                                    return {
                                        'data-tip': 'sales|lyActual|' + tc.key,
                                        'data-place': 'bottom',
                                        'data-for': 'infoTooltip'
                                    };
                                })(tc, data);
                            }
                        } })
                },
                children: [{
                    id: 'vsScheduleForecast',
                    extraProps: {
                        titleContent: _react.default.createElement(
                            'div',
                            { className: 'column f1 text no-border' },
                            ACTUAL_OR_SHORT_FORECAST3,
                            ' vs. LY'
                        ),
                        dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: editedRollup, contentFunc: basicContentFunc(function (tc, data) {
                                return (0, _formatHelpers.numberFormat)(data.sales.actualOrCurrentVsLy.variance());
                            }, function (tc, data) {
                                return tc.key === 'total' ? missingAnyActualSales : isStore ? data.sales.actual == null : data.sales.currentForecastNoActual.count != 0;
                            }, function (tc, data) {
                                return data.sales.actualOrCurrentVsLy.count() > 0 ? {
                                    'data-tip': 'sales|actualOrCurrentVsLy|' + tc.key,
                                    'data-place': 'bottom',
                                    'data-for': 'varianceTooltip'
                                } : null;
                            }) })
                    }
                }, {
                    id: 'variancePercent',
                    extraProps: {
                        titleContent: _react.default.createElement(
                            'div',
                            { className: 'column f1 text no-border' },
                            '% Variance'
                        ),
                        dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: editedRollup, contentFunc: basicContentFunc(function (tc, data) {
                                return (0, _formatHelpers.percentFormat)(data.sales.actualOrCurrentVsLy.percentVariance());
                            }, function (tc, data) {
                                return tc.key === 'total' ? missingAnyActualSales : isStore ? data.sales.actual == null : data.sales.currentForecastNoActual.count != 0;
                            }) })
                    }
                }]
            }]
        }, {
            id: 'scheduleForecast',
            extraProps: {
                titleContent: _react.default.createElement(
                    'div',
                    { className: 'column f1 text no-border italics' },
                    infoRollup && infoRollup.anyNotActive ? _react.default.createElement(
                        'span',
                        { className: 'yellow white-text', style: {
                                padding: '0px 5px 0px 5px',
                                borderRadius: 6,
                                fontWeight: 'bold'
                            } },
                        'Prelim ' + FORECAST1_LABEL
                    ) : FORECAST1_LABEL
                ),
                dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: rollup, contentFunc: basicContentFunc(function (tc, data) {
                        return (0, _formatHelpers.numberFormat)(data.sales.originalForecast);
                    }, function (tc, data) {
                        return true;
                    }) })
            }
        }, {
            id: 'variance',
            extraProps: {
                titleContent: _react.default.createElement(
                    'div',
                    { className: 'column f1 text no-border' },
                    'Variance'
                ),
                dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: editedRollup, withBorder: true, contentFunc: basicContentFunc(function (tc, data) {
                        return (0, _formatHelpers.numberFormat)(data.sales.actualOrCurrentVsOriginalForecast.variance());
                    }, function (tc, data) {
                        return tc.key === 'total' ? missingAnyActualSales : data.sales.actual == null;
                    }, function (tc, data) {
                        return data.sales.actualOrCurrentVsOriginalForecast.count() > 0 ? {
                            'data-tip': 'sales|actualOrCurrentVsOriginalForecast|' + tc.key,
                            'data-place': 'bottom',
                            'data-for': 'varianceTooltip'
                        } : null;
                    }) })
            },
            children: [{
                id: 'variancePercent',
                extraProps: {
                    titleContent: _react.default.createElement(
                        'div',
                        { className: 'column f1 text no-border' },
                        '% Variance'
                    ),
                    dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: editedRollup, contentFunc: basicContentFunc(function (tc, data) {
                            return (0, _formatHelpers.percentFormat)(data.sales.actualOrCurrentVsOriginalForecast.percentVariance());
                        }, function (tc, data) {
                            return tc.key === 'total' ? missingAnyActualSales : data.sales.actual == null;
                        }) })
                }
            }]
        }];
        // costRowStructure 0 would return null
        var costRowStructure1 = [{
            id: 'actualOrForecast',
            extraProps: {
                titleContent: _react.default.createElement(
                    'div',
                    _extends({ className: 'column f1 text no-border'
                    }, makeInfoTooltip('This line shows estimated payroll cost by day and for the week. To estimate payroll costs, actual sales and hours are used, if available;  otherwise, the current forecast and/or current schedule are used.')),
                    'Actual |',
                    _react.default.createElement(
                        'span',
                        { className: 'italics breathe-l-5' },
                        'Estimated'
                    )
                ),
                dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: editedRollup, contentFunc: function contentFunc(tc, data) {
                        if (isStore || tc.key === 'total') {
                            return basicContentFunc(function (tc, data) {
                                return (0, _formatHelpers.numberFormat)(data.payrollcost.cost);
                            }, function (tc, data) {
                                return tc.key === 'total' ? missingAnyActualPayrollCost : data.hours.actual == null;
                            })(tc, data);
                        } else {
                            return basicContentFunc(function (tc, data) {
                                return (0, _formatHelpers.numberFormat)(data.payrollcost.cost);
                            }, function (tc, data) {
                                return !payrollCostActuals ? false : !payrollCostActuals[tc.key];
                            })(tc, data);
                        }
                    } })
            }
        }];

        var costRowStructure2 = [{
            id: 'percentOfSales',
            extraProps: {
                titleContent: _react.default.createElement(
                    'div',
                    { className: 'column f1 text no-border italics' },
                    'Percent of Sales'
                ),
                dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: rollup, contentFunc: function contentFunc(tc, data) {
                        if (isStore || tc.key === 'total') {

                            //console.log(`is store, base: ${Number(data.sales.actualOrCurrent)}}`);
                            return basicContentFunc(function (tc, data) {
                                return (0, _formatHelpers.percentFormat)(Number(data.payrollcost.cost) / Number(data.sales.actualOrCurrent), { decimals: 2, withSign: false });
                            }, function (tc, data) {
                                return tc.key === 'total' ? missingAnyActualPayrollCost : data.sales.actual == null || data.hours.actual == null;
                            })(tc, data);
                        } else {
                            //console.log(`is not store, func: ${Number(data.sales.actualOrCurrent())}`);
                            return basicContentFunc(function (tc, data) {
                                return (0, _formatHelpers.percentFormat)(Number(data.payrollcost.cost) / Number(data.sales.actualOrCurrent()), { decimals: 2, withSign: false });
                            }, function (tc, data) {
                                return !payrollCostActuals ? false : !payrollCostActuals[tc.key];
                            })(tc, data);
                        }
                    } })
            }
        }];

        var costRowStructure3 = [{
            id: 'actualOrForecast',
            extraProps: {
                titleContent: _react.default.createElement(
                    'div',
                    _extends({ className: 'column f1 text no-border'
                    }, makeInfoTooltip('This line shows estimated payroll cost by day and for the week. To estimate payroll costs, actual sales and hours are used, if available;  otherwise, the current forecast and/or current schedule are used.')),
                    'Actual |',
                    _react.default.createElement(
                        'span',
                        { className: 'italics breathe-l-5' },
                        'Estimated'
                    )
                ),
                dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: editedRollup, contentFunc: function contentFunc(tc, data) {
                        if (isStore || tc.key === 'total') {
                            return basicContentFunc(function (tc, data) {
                                return (0, _formatHelpers.numberFormat)(data.payrollcost.cost);
                            }, function (tc, data) {
                                return tc.key === 'total' ? missingAnyActualPayrollCost : data.hours.actual == null;
                            })(tc, data);
                        } else {
                            return basicContentFunc(function (tc, data) {
                                return (0, _formatHelpers.numberFormat)(data.payrollcost.cost);
                            }, function (tc, data) {
                                return !payrollCostActuals ? false : !payrollCostActuals[tc.key];
                            })(tc, data);
                        }
                    } })
            }
        }, {
            id: 'percentOfSales',
            extraProps: {
                titleContent: _react.default.createElement(
                    'div',
                    { className: 'column f1 text no-border italics' },
                    'Percent of Sales'
                ),
                dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: rollup, contentFunc: function contentFunc(tc, data) {
                        if (isStore || tc.key === 'total') {

                            //console.log(`is store, base: ${Number(data.sales.actualOrCurrent)}}`);
                            return basicContentFunc(function (tc, data) {
                                return (0, _formatHelpers.percentFormat)(Number(data.payrollcost.cost) / Number(data.sales.actualOrCurrent), { decimals: 2, withSign: false });
                            }, function (tc, data) {
                                return tc.key === 'total' ? missingAnyActualPayrollCost : data.sales.actual == null || data.hours.actual == null;
                            })(tc, data);
                        } else {
                            //console.log(`is not store, func: ${Number(data.sales.actualOrCurrent())}`);
                            return basicContentFunc(function (tc, data) {
                                return (0, _formatHelpers.percentFormat)(Number(data.payrollcost.cost) / Number(data.sales.actualOrCurrent()), { decimals: 2, withSign: false });
                            }, function (tc, data) {
                                return !payrollCostActuals ? false : !payrollCostActuals[tc.key];
                            })(tc, data);
                        }
                    } })
            }
        }];

        var showStoreitemModal = function showStoreitemModal(item, storeitem) {
            _store.store.dispatch({
                type: _types.TODO_SET_MODAL_DATA,
                payload: {
                    modalItem: item,
                    modalStoreitem: storeitem,
                    modalOffset: 0
                }
            });
            modalService.open('checklist-storeitem-modal');
        };

        var PLAN_HOURS_LABEL = (0, _state.getClientOption)('planhours_label');
        var USE_FLIPPED_PW_HOURS = (0, _state.getClientOption)('use_flipped_pw_hours', 'bool');
        var HOURS_FACTOR = USE_FLIPPED_PW_HOURS ? -1 : 1;
        var IBC_LABEL = (0, _state.getClientOption)('label_ibc');

        var planHoursRows = null;
        var scheduleVsPlanRows = null;
        if (rollup && budgets && activities && tasks && items) {
            var ibcTask = Object.keys(tasks).map(function (id) {
                return tasks[id];
            }).find(function (t) {
                return t.isIbc;
            });

            var taskRows = function taskRows(budgetId) {
                if (!rollup.total.hours.planTest.byBudget) return null;
                var byBudget = rollup.total.hours.planTest.byBudget[budgetId];
                if (!byBudget) return null;
                var byTask = byBudget.byTask;
                var budgetTasks = Object.keys(byTask).map(function (taskId) {
                    return tasks[taskId];
                });
                var rows = budgetTasks.sort(function (a, b) {
                    return a.orderIndex - b.orderIndex;
                }).map(function (task) {
                    if (ibcTask && task.id == ibcTask.id) {
                        var dataTip = 'The amount of ' + IBC_LABEL + ' hours applied. These hours are represented as negatives because they effectively remove hours from other tasks.';
                        return {
                            id: task.id,
                            extraProps: {
                                titleContent: _react.default.createElement(
                                    'div',
                                    {
                                        className: 'column f1 text no-border',
                                        'data-tip': dataTip,
                                        'data-for': 'basicTooltip',
                                        'data-place': 'bottom'
                                    },
                                    IBC_LABEL,
                                    ' Applied'
                                ),
                                dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: rollup, contentFunc: basicContentFunc(function (tc, data) {
                                        return (0, _formatHelpers.numberFormat)(-data.hours.ibcApplied, { decimals: 2 });
                                    }) })
                            },
                            children: [{
                                id: 'available',
                                extraProps: {
                                    titleContent: _react.default.createElement(
                                        'div',
                                        {
                                            className: 'column f1 text no-border',
                                            'data-tip': 'The amount of ' + IBC_LABEL + ' hours initially available.',
                                            'data-for': 'basicTooltip',
                                            'data-place': 'bottom'
                                        },
                                        'Available'
                                    ),
                                    dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: rollup, contentFunc: basicContentFunc(function (tc, data) {
                                            return (0, _formatHelpers.numberFormat)(data.hours.ibcAvailable, { decimals: 2 });
                                        }) })
                                }
                            }, {
                                id: 'remaining',
                                extraProps: {
                                    titleContent: _react.default.createElement(
                                        'div',
                                        {
                                            className: 'column f1 text no-border',
                                            'data-tip': 'The amount of ' + IBC_LABEL + ' hours remaining after hours have been applied.',
                                            'data-for': 'basicTooltip',
                                            'data-place': 'bottom'
                                        },
                                        'Remaining'
                                    ),
                                    dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: rollup, contentFunc: basicContentFunc(function (tc, data) {
                                            return (0, _formatHelpers.numberFormat)(data.hours.ibcRemaining, { decimals: 2 });
                                        }) })
                                }
                            }]
                        };
                    }

                    var byItem = rollup.total.hours.planTest.byBudget[budgetId].byTask[task.id].byItem;
                    var sortedItems = Object.keys(byItem).map(function (itemId) {
                        return items[itemId];
                    }).filter(function (x) {
                        return x != null;
                    }).sort(function (a, b) {
                        return a.sortOrder - b.sortOrder;
                    });

                    var checkOverlap = function checkOverlap() {
                        var itemMap = {};
                        var _iteratorNormalCompletion2 = true;
                        var _didIteratorError2 = false;
                        var _iteratorError2 = undefined;

                        try {
                            for (var _iterator2 = timeColumns[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                                var tc = _step2.value;

                                if (tc.key != 'total') {
                                    var dayBudgetInfo = void 0;
                                    var dayTaskInfo = void 0;
                                    if ((dayBudgetInfo = rollup[tc.key].hours.planTest.byBudget[budgetId]) != null) {
                                        if ((dayTaskInfo = dayBudgetInfo.byTask[task.id]) != null) {
                                            var dayItemIds = Object.keys(dayTaskInfo.byItem);
                                            if (dayItemIds.length > 1) {
                                                return null;
                                            } else if (dayItemIds.length == 1) {
                                                itemMap[tc.key] = dayItemIds[0];
                                            }
                                        }
                                    }
                                }
                            }
                        } catch (err) {
                            _didIteratorError2 = true;
                            _iteratorError2 = err;
                        } finally {
                            try {
                                if (!_iteratorNormalCompletion2 && _iterator2.return) {
                                    _iterator2.return();
                                }
                            } finally {
                                if (_didIteratorError2) {
                                    throw _iteratorError2;
                                }
                            }
                        }

                        return itemMap;
                    };

                    var itemMap = checkOverlap();
                    var hasOverlap = itemMap == null;

                    var ItemHoverOutline = function ItemHoverOutline(_ref) {
                        var targetItem = _ref.targetItem;

                        // only display when hovering on an item
                        if (!hoverItem) return null;
                        var item = hoverItem.item,
                            storeitem = hoverItem.storeitem;

                        if (targetItem && targetItem.id != item.id) return null;

                        // only show in the correct row
                        if (item.task.id != task.id) return null;

                        // get day indices relative to current week
                        var dotw = function dotw(date) {
                            return (0, _npmMoment.default)(date).diff(weekStart, 'days');
                        };
                        var startDotw = dotw(storeitem.start_date_exception || item.start_date);
                        var dueDotw = dotw(storeitem.due_date_exception || item.due_date);

                        var borderWidth = '2px';

                        var limit = function limit(x) {
                            return Math.min(Math.max(x, 0), 6);
                        };

                        return _react.default.createElement('div', { className: '', style: {
                                left: limit(startDotw) / 8 * 100 + '%',
                                width: (limit(dueDotw) - limit(startDotw) + 1) / 8 * 100 + '%',
                                height: '100%',
                                position: 'absolute',
                                borderStyle: 'solid',
                                borderColor: '#2490b2',
                                // top borders always show
                                borderTopWidth: borderWidth,
                                borderBottomWidth: borderWidth,
                                // edge borders only show when not extending beyond the week
                                borderLeftWidth: startDotw >= 0 ? borderWidth : 0,
                                borderRightWidth: dueDotw <= 6 ? borderWidth : 0,
                                pointerEvents: 'none'
                            } });
                    };

                    return {
                        id: task.id,
                        extraProps: {
                            titleContent: _react.default.createElement(
                                'div',
                                { className: 'column f1 text no-border' },
                                task.name
                            ),
                            dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: rollup, contentFunc: simpleContentFunc(function (tc, data) {
                                    var def = {
                                        content: data.hours.planTest.byBudget[budgetId] && data.hours.planTest.byBudget[budgetId].byTask[task.id] ? (0, _formatHelpers.numberFormat)(data.hours.planTest.byBudget[budgetId].byTask[task.id].total, { decimals: 2 }) : null
                                    };
                                    if (isStore && tc.key != 'total' && !hasOverlap) {
                                        var itemId = itemMap[tc.key];
                                        if (storeitems && storeitems[itemId]) {
                                            def.onClick = function () {
                                                return showStoreitemModal(items[itemId], storeitems[itemId]);
                                            };
                                            def.extraProps = {
                                                onMouseEnter: function onMouseEnter(e) {
                                                    setHoverItem({
                                                        item: items[itemId],
                                                        storeitem: storeitems[itemId]
                                                    });
                                                },
                                                onMouseLeave: function onMouseLeave(e) {
                                                    setHoverItem(null);
                                                }
                                            };
                                        }
                                    }
                                    if (!hasOverlap) {
                                        var _itemId = itemMap[tc.key];
                                        var item = items[_itemId];
                                        if (item) {
                                            // console.info(tc.key, item)
                                            def.tooltipAttrs = {
                                                'data-tip': item.name,
                                                'data-for': 'basicTooltip',
                                                'data-place': 'bottom'
                                                // def.showOutline = true;
                                            };
                                        }
                                    }
                                    return def;
                                }) }),
                            extraChildren: !hasOverlap ? _react.default.createElement(ItemHoverOutline, null) : null
                        },
                        children: hasOverlap ? sortedItems.map(function (item) {
                            return {
                                id: item.id,
                                extraProps: {
                                    titleContent: _react.default.createElement(
                                        'div',
                                        { className: 'column f1 text no-border' },
                                        item.name
                                    ),
                                    dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: rollup, contentFunc: simpleContentFunc(function (tc, data) {
                                            var budgetData = data.hours.planTest.byBudget[budgetId];
                                            return {
                                                content: budgetData && budgetData.byTask[task.id] && budgetData.byTask[task.id].byItem[item.id] ? (0, _formatHelpers.numberFormat)(budgetData.byTask[task.id].byItem[item.id].total, { decimals: 2 }) : null,
                                                onClick: isStore && storeitems && storeitems[item.id] ? function () {
                                                    showStoreitemModal(item, storeitems[item.id]);
                                                } : null,
                                                extraProps: isStore && storeitems && storeitems[item.id] ? {
                                                    onMouseEnter: function onMouseEnter(e) {
                                                        setHoverItem({
                                                            item: item,
                                                            storeitem: storeitems[item.id]
                                                        });
                                                    },
                                                    onMouseLeave: function onMouseLeave(e) {
                                                        setHoverItem(null);
                                                    }
                                                } : null
                                            };
                                        }) }),
                                    extraChildren: _react.default.createElement(ItemHoverOutline, { targetItem: item })
                                }
                            };
                        }) : null
                    };
                });

                return rows;
            };

            var sortedBudgets = Object.keys(budgets).map(function (budgetId) {
                return budgets[budgetId];
            }).sort(function (a, b) {
                return a.orderIndex - b.orderIndex;
            });
            planHoursRows = sortedBudgets.map(function (budget) {
                return {
                    id: budget.id,
                    extraProps: {
                        titleContent: _react.default.createElement(
                            'div',
                            { className: 'column f1 text no-border' },
                            budget.name
                        ),
                        dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: rollup, contentFunc: basicContentFunc(function (tc, data) {
                                return (data.hours.planTest.byBudget || {})[budget.id] ? (0, _formatHelpers.numberFormat)(data.hours.planTest.byBudget[budget.id].total, { decimals: 2 }) : null;
                            }) })
                    },
                    children: taskRows(budget.id)
                };
            });

            var scheduleActivityIds = Object.keys(rollup.total.hours.scheduleByActivity);
            var planActivityIds = Object.keys(rollup.total.hours.planTest.byActivity || {});
            if (scheduleActivityIds.length > 0 && planActivityIds.length > 0) {
                var usedActivities = Object.keys([].concat(_toConsumableArray(scheduleActivityIds), _toConsumableArray(planActivityIds)).reduce(function (o, x) {
                    o[x] = 1;
                    return o;
                }, {})).map(function (x) {
                    return activities[x];
                });
                var sortedActivities = usedActivities.sort((0, _functionalSort.tieredSort)([(0, _functionalSort.funcSort)(function (a) {
                    return (budgets[a.budget.id] || { orderIndex: Infinity }).orderIndex;
                }), (0, _functionalSort.funcSort)(function (a) {
                    return a.orderIndex;
                })]));
                scheduleVsPlanRows = sortedActivities.map(function (activity) {
                    return {
                        id: activity.id,
                        extraProps: {
                            titleContent: _react.default.createElement(
                                'div',
                                { className: 'column f1 text no-border' },
                                activity.name
                            ),
                            dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: rollup, contentFunc: simpleContentFunc(function (tc, data) {
                                    var a = data.hours.scheduleByActivity[activity.id] || 0;
                                    var b = data.hours.planTest.byActivity[activity.id] || 0;
                                    if (a == 0 && b == 0) return {
                                        content: null
                                    };
                                    return {
                                        content: (0, _formatHelpers.numberFormat)((a - b) * HOURS_FACTOR, { decimals: 2 }),
                                        tooltipAttrs: {
                                            'data-tip': activity.id + '|' + tc.key,
                                            'data-for': 'scheduleVsPlanByActivity',
                                            'data-place': 'bottom'
                                        }
                                    };
                                }) })
                        }
                    };
                });
            }
        }

        // console.info(rollup)

        var safeProd = function safeProd(a, b) {
            return a != null && b != null ? a * b : null;
        };

        var hoursRowStructure = [{
            id: 'actualOrScheduled',
            extraProps: {
                titleContent: _react.default.createElement(
                    'div',
                    _extends({ className: 'column f1 text no-border'
                    }, makeInfoTooltip('Actual hours are displayed when available - otherwise, scheduled hours are displayed. ')),
                    'Actual |',
                    _react.default.createElement(
                        'span',
                        { className: 'italics breathe-l-5' },
                        'Scheduled'
                    )
                ),
                dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: rollup, contentFunc: function contentFunc(tc, data) {
                        if (isStore || tc.key === 'total') {
                            return basicContentFunc(function (tc, data) {
                                return (0, _formatHelpers.numberFormat)(data.hours.actualOrSchedule, { decimals: 2 });
                            }, function (tc, data) {
                                return tc.key === 'total' ? missingAnyActualHours : data.hours.actual == null;
                            })(tc, data);
                        } else {
                            return basicContentFunc(function (tc, data) {
                                return (0, _formatHelpers.numberFormat)(data.hours.actualOrSchedule(), { decimals: 2 });
                            }, function (tc, data) {
                                return data.hours.scheduleNoActual.count != 0;
                            }, function (tc, data) {
                                return {
                                    'data-tip': 'hours|actualOrSchedule|' + tc.key,
                                    'data-place': 'bottom',
                                    'data-for': 'infoTooltip'
                                };
                            })(tc, data);
                        }
                    } })
            },
            children: [{
                id: 'actual',
                extraProps: {
                    titleContent: _react.default.createElement(
                        'div',
                        { className: 'column f1 text no-border' },
                        'Actual Hours'
                    ),
                    dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: rollup, contentFunc: function contentFunc(tc, data) {
                            if (isStore || tc.key === 'total') {
                                return basicContentFunc(function (tc, data) {
                                    return (0, _formatHelpers.numberFormat)(data.hours.actual, { decimals: 2 });
                                })(tc, data);
                            } else {
                                return basicContentFunc(function (tc, data) {
                                    return (0, _formatHelpers.numberFormat)(data.hours.actual.total, { decimals: 2 });
                                }, null, function (tc, data) {
                                    return {
                                        'data-tip': 'hours|actual|' + tc.key,
                                        'data-place': 'bottom',
                                        'data-for': 'infoTooltip'
                                    };
                                })(tc, data);
                            }
                        } })
                }
            }, {
                id: 'schedule',
                extraProps: {
                    titleContent: _react.default.createElement(
                        'div',
                        { className: 'column f1 text no-border italics' },
                        'Current Schedule'
                    ),
                    dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: rollup, contentFunc: basicContentFunc(function (tc, data) {
                            return (0, _formatHelpers.numberFormat)(data.hours.schedule, { decimals: 2 });
                        }, function (tc, data) {
                            return true;
                        }) })
                }
            }]
        }, {
            id: 'planHours',
            extraProps: {
                titleContent: _react.default.createElement(
                    'div',
                    { className: 'column f1 text no-border' },
                    PLAN_HOURS_LABEL
                ),
                dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: rollup, contentFunc: basicContentFunc(function (tc, data) {
                        return (0, _formatHelpers.numberFormat)(data.hours.planTest.total, { decimals: 2 });
                    }) })
            },
            children: planHoursRows
        }, {
            id: 'overUnder',
            extraProps: {
                titleContent: _react.default.createElement(
                    'div',
                    { className: 'column f1 text no-border' },
                    USE_FLIPPED_PW_HOURS ? 'Hours Under (Over)' : 'Hours Over (Under)'
                ),
                dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: rollup, contentFunc: basicContentFunc(function (tc, data) {
                        return (0, _formatHelpers.numberFormat)(data.hours.actualOrScheduleVsPlan.variance(), { decimals: 2 });
                    }, null, function (tc, data) {
                        return data.hours.actualOrScheduleVsPlan.count() > 0 ? {
                            'data-tip': 'hours|actualOrScheduleVsPlan|' + tc.key,
                            'data-place': 'bottom',
                            'data-for': 'varianceTooltip'
                        } : null;
                    }) })
            },
            children: [{
                id: 'actualVsSchedule',
                extraProps: {
                    titleContent: _react.default.createElement(
                        'div',
                        { className: 'column f1 text no-border' },
                        'Actual Hrs vs. Schedule'
                    ),
                    dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: rollup, contentFunc: basicContentFunc(function (tc, data) {
                            return (0, _formatHelpers.numberFormat)(data.hours.actualVsSchedule.variance(), { decimals: 2 });
                        }, null, function (tc, data) {
                            return data.hours.actualVsSchedule.count() > 0 ? {
                                'data-tip': 'hours|actualVsSchedule|' + tc.key,
                                'data-place': 'bottom',
                                'data-for': 'varianceTooltip'
                            } : null;
                        }) })
                }
            }, {
                id: 'scheduleVsPlan',
                extraProps: {
                    titleContent: _react.default.createElement(
                        'div',
                        { className: 'column f1 text no-border' },
                        'Schedule vs. ',
                        PLAN_HOURS_LABEL.split(' ')[0]
                    ),
                    dataContent: _react.default.createElement(DataSection, { timeColumns: timeColumns, rollup: rollup, contentFunc: basicContentFunc(function (tc, data) {
                            return (0, _formatHelpers.numberFormat)(data.hours.scheduleVsPlan.variance(), { decimals: 2 });
                        }, null, function (tc, data) {
                            return data.hours.scheduleVsPlan.count() > 0 ? {
                                'data-tip': 'hours|scheduleVsPlan|' + tc.key,
                                'data-place': 'bottom',
                                'data-for': 'varianceTooltip'
                            } : null;
                        }) })
                },
                children: scheduleVsPlanRows
            }]
        }];

        // console.info(rollup)


        var infoTooltipContent = function infoTooltipContent(dataTip) {
            if (!dataTip || !rollup || isStore) return null;

            var _dataTip$split = dataTip.split('|'),
                _dataTip$split2 = _slicedToArray(_dataTip$split, 3),
                prefix = _dataTip$split2[0],
                id = _dataTip$split2[1],
                tcKey = _dataTip$split2[2];

            var data = rollup[tcKey];
            if (!data) return null;

            if (prefix === 'sales') {
                if (id === 'trend') {
                    return _react.default.createElement(
                        'div',
                        { className: 'report-data-tooltip light-content' },
                        _react.default.createElement(
                            'div',
                            { className: 'flex-table flex-row-table' },
                            _react.default.createElement(
                                'div',
                                { className: 'column no-pad-left left' },
                                _react.default.createElement(
                                    'span',
                                    { className: 'bold bottom-border' },
                                    'Actual | Trend'
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    'Actual'
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    'Trend'
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    'No Data'
                                )
                            ),
                            _react.default.createElement(
                                'div',
                                { className: 'zero-flex-column more-pad-right right' },
                                _react.default.createElement(
                                    'span',
                                    { className: 'bold bottom-border' },
                                    '$'
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    '$'
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    '$'
                                ),
                                _react.default.createElement('span', null)
                            ),
                            _react.default.createElement(
                                'div',
                                { className: 'zero-flex-column more-pad-right right' },
                                _react.default.createElement(
                                    'span',
                                    { className: 'bold bottom-border' },
                                    (0, _formatHelpers.numberFormat)(data.sales.trendValue()) || 'N/A'
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    (0, _formatHelpers.numberFormat)(data.sales.actual.total) || 'N/A'
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    (0, _formatHelpers.numberFormat)(data.sales.trend.total) || 'N/A'
                                ),
                                _react.default.createElement('span', null)
                            ),
                            _react.default.createElement(
                                'div',
                                { className: 'zero-flex-column no-pad-right right' },
                                _react.default.createElement(
                                    'span',
                                    { className: 'bold bottom-border' },
                                    (0, _formatHelpers.numberFormat)(data.storeCount)
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    (0, _formatHelpers.numberFormat)(data.sales.actual.count)
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    (0, _formatHelpers.numberFormat)(data.sales.trend.count)
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    (0, _formatHelpers.numberFormat)(data.storeCount - data.sales.actual.count - data.sales.trend.count)
                                )
                            )
                        )
                    );
                } else if (id === 'actual') {
                    return _react.default.createElement(
                        'div',
                        { className: 'report-data-tooltip light-content' },
                        _react.default.createElement(
                            'div',
                            { className: 'flex-table flex-row-table' },
                            _react.default.createElement(
                                'div',
                                { className: 'column no-pad-left left' },
                                _react.default.createElement(
                                    'span',
                                    { className: 'bold bottom-border' },
                                    'Actual'
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    'No Data'
                                )
                            ),
                            _react.default.createElement(
                                'div',
                                { className: 'zero-flex-column more-pad-right right' },
                                _react.default.createElement(
                                    'span',
                                    { className: 'bold bottom-border' },
                                    '$'
                                ),
                                _react.default.createElement('span', null)
                            ),
                            _react.default.createElement(
                                'div',
                                { className: 'zero-flex-column more-pad-right right' },
                                _react.default.createElement(
                                    'span',
                                    { className: 'bold bottom-border' },
                                    (0, _formatHelpers.numberFormat)(data.sales.actual.total) || 'N/A'
                                ),
                                _react.default.createElement('span', null)
                            ),
                            _react.default.createElement(
                                'div',
                                { className: 'zero-flex-column no-pad-right right' },
                                _react.default.createElement(
                                    'span',
                                    { className: 'bold bottom-border' },
                                    (0, _formatHelpers.numberFormat)(data.sales.actual.count)
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    (0, _formatHelpers.numberFormat)(data.storeCount - data.sales.actual.count)
                                )
                            )
                        )
                    );
                } else if (id === 'actualOrCurrent') {
                    return _react.default.createElement(
                        'div',
                        { className: 'report-data-tooltip light-content' },
                        _react.default.createElement(
                            'div',
                            { className: 'flex-table flex-row-table' },
                            _react.default.createElement(
                                'div',
                                { className: 'column no-pad-left left' },
                                _react.default.createElement(
                                    'span',
                                    { className: 'bold bottom-border' },
                                    ACTUAL_OR_SHORT_FORECAST3
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    'Actual Sales'
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    FORECAST3_LABEL
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    'No Data'
                                )
                            ),
                            _react.default.createElement(
                                'div',
                                { className: 'zero-flex-column more-pad-right right' },
                                _react.default.createElement(
                                    'span',
                                    { className: 'bold bottom-border' },
                                    '$'
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    '$'
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    '$'
                                ),
                                _react.default.createElement('span', null)
                            ),
                            _react.default.createElement(
                                'div',
                                { className: 'zero-flex-column more-pad-right right' },
                                _react.default.createElement(
                                    'span',
                                    { className: 'bold bottom-border' },
                                    (0, _formatHelpers.numberFormat)(data.sales.actualOrCurrent()) || 'N/A'
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    (0, _formatHelpers.numberFormat)(data.sales.actual.total) || 'N/A'
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    (0, _formatHelpers.numberFormat)(data.sales.currentForecastNoActual.total) || 'N/A'
                                ),
                                _react.default.createElement('span', null)
                            ),
                            _react.default.createElement(
                                'div',
                                { className: 'zero-flex-column no-pad-right right' },
                                _react.default.createElement(
                                    'span',
                                    { className: 'bold bottom-border' },
                                    (0, _formatHelpers.numberFormat)(data.storeCount)
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    (0, _formatHelpers.numberFormat)(data.sales.actual.count)
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    (0, _formatHelpers.numberFormat)(data.sales.currentForecastNoActual.count)
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    (0, _formatHelpers.numberFormat)(data.storeCount - data.sales.actual.count - data.sales.currentForecastNoActual.count)
                                )
                            )
                        )
                    );
                } else if (id === 'lyActual') {
                    return _react.default.createElement(
                        'div',
                        { className: 'report-data-tooltip light-content' },
                        _react.default.createElement(
                            'div',
                            { className: 'flex-table flex-row-table' },
                            _react.default.createElement(
                                'div',
                                { className: 'column no-pad-left left' },
                                _react.default.createElement(
                                    'span',
                                    { className: 'bold bottom-border' },
                                    'LY Actual'
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    'No Data'
                                )
                            ),
                            _react.default.createElement(
                                'div',
                                { className: 'zero-flex-column more-pad-right right' },
                                _react.default.createElement(
                                    'span',
                                    { className: 'bold bottom-border' },
                                    '$'
                                ),
                                _react.default.createElement('span', null)
                            ),
                            _react.default.createElement(
                                'div',
                                { className: 'zero-flex-column more-pad-right' },
                                _react.default.createElement(
                                    'span',
                                    { className: 'bold bottom-border' },
                                    (0, _formatHelpers.numberFormat)(data.sales.lyActual.total) || 'N/A'
                                ),
                                _react.default.createElement('span', null)
                            ),
                            _react.default.createElement(
                                'div',
                                { className: 'zero-flex-column no-pad-right right' },
                                _react.default.createElement(
                                    'span',
                                    { className: 'bold bottom-border' },
                                    (0, _formatHelpers.numberFormat)(data.sales.lyActual.count)
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    (0, _formatHelpers.numberFormat)(data.storeCount - data.sales.lyActual.count)
                                )
                            )
                        )
                    );
                }
            } else if (prefix === 'hours') {
                if (id === 'actual') {
                    return _react.default.createElement(
                        'div',
                        { className: 'report-data-tooltip light-content' },
                        _react.default.createElement(
                            'div',
                            { className: 'flex-table flex-row-table' },
                            _react.default.createElement(
                                'div',
                                { className: 'column no-pad-left left' },
                                _react.default.createElement(
                                    'span',
                                    { className: 'bold bottom-border' },
                                    'Actual Hours'
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    'No Data'
                                )
                            ),
                            _react.default.createElement(
                                'div',
                                { className: 'zero-flex-column more-pad-right' },
                                _react.default.createElement(
                                    'span',
                                    { className: 'bold bottom-border' },
                                    (0, _formatHelpers.numberFormat)(data.hours.actual.total) || 'N/A'
                                ),
                                _react.default.createElement('span', null)
                            ),
                            _react.default.createElement(
                                'div',
                                { className: 'zero-flex-column no-pad-right right' },
                                _react.default.createElement(
                                    'span',
                                    { className: 'bold bottom-border' },
                                    (0, _formatHelpers.numberFormat)(data.hours.actual.count)
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    (0, _formatHelpers.numberFormat)(data.storeCount - data.hours.actual.count)
                                )
                            )
                        )
                    );
                } else if (id === 'actualOrSchedule') {
                    return _react.default.createElement(
                        'div',
                        { className: 'report-data-tooltip light-content' },
                        _react.default.createElement(
                            'div',
                            { className: 'flex-table flex-row-table' },
                            _react.default.createElement(
                                'div',
                                { className: 'column no-pad-left left' },
                                _react.default.createElement(
                                    'span',
                                    { className: 'bold bottom-border' },
                                    'Actual | Schedule'
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    'Actual Hours'
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    'Schedule Hours'
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    'No Data'
                                )
                            ),
                            _react.default.createElement(
                                'div',
                                { className: 'zero-flex-column more-pad-right right' },
                                _react.default.createElement(
                                    'span',
                                    { className: 'bold bottom-border' },
                                    (0, _formatHelpers.numberFormat)(data.hours.actualOrSchedule()) || 'N/A'
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    (0, _formatHelpers.numberFormat)(data.hours.actual.total) || 'N/A'
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    (0, _formatHelpers.numberFormat)(data.hours.scheduleNoActual.total) || 'N/A'
                                ),
                                _react.default.createElement('span', null)
                            ),
                            _react.default.createElement(
                                'div',
                                { className: 'zero-flex-column no-pad-right right' },
                                _react.default.createElement(
                                    'span',
                                    { className: 'bold bottom-border' },
                                    (0, _formatHelpers.numberFormat)(data.storeCount)
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    (0, _formatHelpers.numberFormat)(data.hours.actual.count)
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    (0, _formatHelpers.numberFormat)(data.hours.scheduleNoActual.count)
                                ),
                                _react.default.createElement(
                                    'span',
                                    null,
                                    (0, _formatHelpers.numberFormat)(data.storeCount - data.hours.actual.count - data.hours.scheduleNoActual.count)
                                )
                            )
                        )
                    );
                }
            }
        };

        var varianceTooltipContent = function varianceTooltipContent(dataTip) {
            if (!dataTip || !rollup || isStore) return null;

            var _dataTip$split3 = dataTip.split('|'),
                _dataTip$split4 = _slicedToArray(_dataTip$split3, 3),
                prefix = _dataTip$split4[0],
                id = _dataTip$split4[1],
                tcKey = _dataTip$split4[2];

            var data = rollup[tcKey];
            if (!data) return null;

            var content = function content(comparison, nameA, nameB) {
                var opts = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

                var withStoreCount = tcKey !== 'total' && !isStore;

                var numFormat = opts.numFormat;


                return _react.default.createElement(
                    'div',
                    { className: 'report-data-tooltip light-content' },
                    _react.default.createElement(
                        'div',
                        { className: 'flex-table flex-row-table' },
                        _react.default.createElement(
                            'div',
                            { className: 'column no-pad-left left' },
                            _react.default.createElement('span', null),
                            _react.default.createElement(
                                'span',
                                { className: 'bold' },
                                nameA
                            ),
                            _react.default.createElement(
                                'span',
                                { className: 'bold' },
                                nameB
                            ),
                            _react.default.createElement(
                                'span',
                                { className: 'bold' },
                                'Variance'
                            ),
                            withStoreCount ? _react.default.createElement(
                                'span',
                                { className: 'bold' },
                                'Stores'
                            ) : null
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'zero-flex-column more-pad-right right' },
                            _react.default.createElement('span', null),
                            _react.default.createElement(
                                'span',
                                null,
                                '$'
                            ),
                            _react.default.createElement(
                                'span',
                                null,
                                '$'
                            ),
                            _react.default.createElement(
                                'span',
                                null,
                                '$'
                            ),
                            withStoreCount ? _react.default.createElement('span', null) : null
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'zero-flex-column more-pad-right right' },
                            _react.default.createElement(
                                'span',
                                { className: 'bold' },
                                'Included'
                            ),
                            _react.default.createElement(
                                'span',
                                null,
                                (0, _formatHelpers.numberFormat)(comparison.a, numFormat) || 'N/A'
                            ),
                            _react.default.createElement(
                                'span',
                                null,
                                (0, _formatHelpers.numberFormat)(comparison.b, numFormat) || 'N/A'
                            ),
                            _react.default.createElement(
                                'span',
                                null,
                                (0, _formatHelpers.numberFormat)(comparison.variance(), numFormat) || 'N/A'
                            ),
                            withStoreCount ? _react.default.createElement(
                                'span',
                                null,
                                (0, _formatHelpers.numberFormat)(comparison.countIncluded) || 'N/A'
                            ) : null
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'zero-flex-column more-pad-right right' },
                            _react.default.createElement(
                                'span',
                                { className: 'bold' },
                                'Excluded'
                            ),
                            _react.default.createElement(
                                'span',
                                null,
                                (0, _formatHelpers.numberFormat)(comparison.excludedA, numFormat) || 'N/A'
                            ),
                            _react.default.createElement(
                                'span',
                                null,
                                (0, _formatHelpers.numberFormat)(comparison.excludedB, numFormat) || 'N/A'
                            ),
                            _react.default.createElement(
                                'span',
                                null,
                                (0, _formatHelpers.numberFormat)(safeProd(comparison.excludedA - comparison.excludedB, comparison.factor()), numFormat) || 'N/A'
                            ),
                            withStoreCount ? _react.default.createElement(
                                'span',
                                null,
                                (0, _formatHelpers.numberFormat)(comparison.countExcluded) || 'N/A'
                            ) : null
                        ),
                        _react.default.createElement(
                            'div',
                            { className: 'zero-flex-column no-pad-right right' },
                            _react.default.createElement(
                                'span',
                                { className: 'bold' },
                                'Total'
                            ),
                            _react.default.createElement(
                                'span',
                                null,
                                (0, _formatHelpers.numberFormat)(comparison.a + comparison.excludedA, numFormat) || 'N/A'
                            ),
                            _react.default.createElement(
                                'span',
                                null,
                                (0, _formatHelpers.numberFormat)(comparison.b + comparison.excludedB, numFormat) || 'N/A'
                            ),
                            _react.default.createElement(
                                'span',
                                null,
                                (0, _formatHelpers.numberFormat)(safeProd(comparison.a + comparison.excludedA - (comparison.b + comparison.excludedB), comparison.factor()), numFormat) || 'N/A'
                            ),
                            withStoreCount ? _react.default.createElement(
                                'span',
                                null,
                                (0, _formatHelpers.numberFormat)(comparison.countIncluded + comparison.countExcluded) || 'N/A'
                            ) : null
                        )
                    )
                );
            };

            if (prefix === 'sales') {
                if (id === 'actualOrCurrentVsOriginalForecast') {
                    return content(data.sales[id], ACTUAL_OR_SHORT_FORECAST3, FORECAST1_LABEL);
                } else if (id === 'actualVsOriginalForecast') {
                    return content(data.sales[id], 'Actual Sales', FORECAST1_LABEL);
                } else if (id === 'trendVsOriginalForecast') {
                    return content(data.sales[id], 'Trend', FORECAST1_LABEL);
                } else if (id === 'currentVsOriginalForecast') {
                    return content(data.sales[id], FORECAST3_LABEL, FORECAST1_LABEL);
                } else if (id === 'actualOrCurrentVsLy') {
                    return content(data.sales[id], ACTUAL_OR_SHORT_FORECAST3, 'LY Sales');
                }
            } else if (prefix === 'hours') {
                var opts = {
                    numFormat: {
                        decimals: 2
                    }
                };
                if (id === 'actualOrScheduleVsPlan') {
                    return content(data.hours[id], 'Actual | Schedule', PLAN_HOURS_LABEL, opts);
                } else if (id === 'actualVsSchedule') {
                    return content(data.hours[id], 'Actual Hours', 'Schedule Hours', opts);
                } else if (id === 'scheduleVsPlan') {
                    return content(data.hours[id], 'Schedule Hours', PLAN_HOURS_LABEL, opts);
                }
            }
        };

        var scheduleVsPlanByActivityContent = function scheduleVsPlanByActivityContent(dataTip) {
            if (!dataTip || !rollup) return null;

            var _dataTip$split5 = dataTip.split('|'),
                _dataTip$split6 = _slicedToArray(_dataTip$split5, 2),
                activityId = _dataTip$split6[0],
                tcKey = _dataTip$split6[1];

            var data = rollup[tcKey];
            if (!data) return null;

            var a = data.hours.scheduleByActivity[activityId] || 0;
            var b = data.hours.planTest.byActivity[activityId] || 0;
            var variance = (a - b) * HOURS_FACTOR;

            var numFormat = {
                decimals: 2
            };
            return _react.default.createElement(
                'div',
                { className: 'report-data-tooltip light-content' },
                _react.default.createElement(
                    'div',
                    { className: 'flex-table flex-row-table' },
                    _react.default.createElement(
                        'div',
                        { className: 'column no-pad-left left' },
                        _react.default.createElement('span', null),
                        _react.default.createElement(
                            'span',
                            { className: 'bold' },
                            'Schedule'
                        ),
                        _react.default.createElement(
                            'span',
                            { className: 'bold' },
                            PLAN_HOURS_LABEL.split(' ')[0]
                        ),
                        _react.default.createElement(
                            'span',
                            { className: 'bold' },
                            'Variance'
                        )
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'zero-flex-column more-pad-right right' },
                        _react.default.createElement(
                            'span',
                            { className: 'bold' },
                            'Total'
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            (0, _formatHelpers.numberFormat)(a, numFormat) || 'N/A'
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            (0, _formatHelpers.numberFormat)(b, numFormat) || 'N/A'
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            (0, _formatHelpers.numberFormat)(variance, numFormat) || 'N/A'
                        )
                    ),
                    !isStore ? _react.default.createElement(
                        'div',
                        { className: 'zero-flex-column more-pad-right right' },
                        _react.default.createElement(
                            'span',
                            { className: 'bold' },
                            'Average'
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            (0, _formatHelpers.numberFormat)(a / data.storeCount, numFormat) || 'N/A'
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            (0, _formatHelpers.numberFormat)(b / data.storeCount, numFormat) || 'N/A'
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            (0, _formatHelpers.numberFormat)(variance / data.storeCount, numFormat) || 'N/A'
                        )
                    ) : null
                )
            );
        };

        // console.info(rollup)

        (0, _react.useEffect)(function () {
            window.requestAnimationFrame(function () {
                _npmReactTooltip.default.rebuild();
            });
        });

        var payrollPerms = function payrollPerms() {

            var rollupPermissions = 0;
            (0, _state.hasScope)('payrollworksheet.rollup:view.cost') ? rollupPermissions = rollupPermissions + 1 : null;
            (0, _state.hasScope)('payrollworksheet.rollup:view.percentage') ? rollupPermissions = rollupPermissions + 2 : null;

            var storePermissions = 0;
            (0, _state.hasScope)('payrollworksheet.store:view.cost') ? storePermissions = storePermissions + 1 : null;
            (0, _state.hasScope)('payrollworksheet.store:view.percentage') ? storePermissions = storePermissions + 2 : null;

            var result = {
                0: null,
                1: _react.default.createElement(DetailTable, { title: 'Payroll Cost $', timeColumns: timeColumns, structure: costRowStructure1, collapsible: false, bottomMargin: true }),
                2: _react.default.createElement(DetailTable, { title: 'Payroll Cost $', timeColumns: timeColumns, structure: costRowStructure2, collapsible: false, bottomMargin: true }),
                3: _react.default.createElement(DetailTable, { title: 'Payroll Cost $', timeColumns: timeColumns, structure: costRowStructure3, collapsible: false, bottomMargin: true })
            }[isStore ? storePermissions : rollupPermissions]; // ?? null   apparently babel or whatever we're using doesn't support the nullish coalescing operator without a plugin but we're deprecated and deep in dependency hell, and I dont think its strictly speaking necessary anyway.

            return result;
        };

        return _react.default.createElement(
            _react.Fragment,
            null,
            _react.default.createElement(ControlPanel, { showSaveControls: hasEdits && isStore, text: numEdits + ' ' + (numEdits > 1 ? 'changes' : 'change'), saveAction: onSaveClick, discardAction: onDiscardClick }),
            _react.default.createElement(DetailTable, { title: 'Sales $', timeColumns: timeColumns, structure: salesRowStructure, collapsible: true, realtime: true }),
            _react.default.createElement(DetailTable, { title: 'Payroll Hours', timeColumns: timeColumns, structure: hoursRowStructure, collapsible: true, realtime: true }),
            payrollPerms(),
            _react.default.createElement(_npmReactTooltip.default, { id: 'basicTooltip', effect: 'solid', getContent: function getContent(dataTip) {
                    return _react.default.createElement(
                        'div',
                        { style: { maxWidth: 250 } },
                        dataTip
                    );
                } }),
            _react.default.createElement(_npmReactTooltip.default, { id: 'infoTooltip', effect: 'solid', getContent: infoTooltipContent }),
            _react.default.createElement(_npmReactTooltip.default, { id: 'varianceTooltip', effect: 'solid', getContent: varianceTooltipContent }),
            _react.default.createElement(_npmReactTooltip.default, { id: 'scheduleVsPlanByActivity', effect: 'solid', getContent: scheduleVsPlanByActivityContent })
        );
    };

    var Wrapper = function (_Component) {
        _inherits(Wrapper, _Component);

        function Wrapper() {
            _classCallCheck(this, Wrapper);

            return _possibleConstructorReturn(this, (Wrapper.__proto__ || Object.getPrototypeOf(Wrapper)).apply(this, arguments));
        }

        _createClass(Wrapper, [{
            key: 'shouldComponentUpdate',
            value: function shouldComponentUpdate(nextProps) {
                var _this3 = this;

                return Object.keys(nextProps).reduce(function (agg, k) {
                    var changed = nextProps[k] !== _this3.props[k];
                    if (k != 'children' && changed) {
                        return true;
                    }
                    return agg;
                }, false);
            }
        }, {
            key: 'render',
            value: function render() {
                return _react.default.createElement(Body, this.props);
            }
        }]);

        return Wrapper;
    }(_react.Component);

    exports.default = (0, _emberReactComponents.default)(Wrapper);
});