define('matrix-frontend/pods/components/schedule-status/component', ['exports', 'matrix-frontend/actions/_types', 'ember-redux', 'matrix-frontend/constants', 'npm:backoff'], function (exports, _types, _emberRedux, _constants, _npmBackoff) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var stateToComputed = function stateToComputed(state) {
        return {
            status: state.scheduleEdit.status,
            published: state.scheduleEdit.published,
            isLocked: state.scheduleEdit.isLocked,
            inProgress: state.scheduleEdit.inProgress,
            // noSchedule: state.scheduleEdit.noSchedule,
            currentVersion: state.scheduleEdit.currentVersion
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {};
    };

    var base = {
        status: _constants.ScheduleStatus.UNKNOWN,
        isLocked: false,
        numDirty: 0,
        published: false,
        inProgress: false
    };

    var Comp = Ember.Component.extend({
        display: Ember.computed('status', 'isLocked', 'numDirty', 'published', 'inProgress', 'override', 'force', function () {
            if (this.override) {
                return this.override;
            }
            if (this.force) {
                return _extends({}, base, {
                    status: this.forceStatus
                });
            }
            var retval = void 0;
            var schedule = this.get('schedule');
            if (schedule) {
                retval = {
                    status: schedule.status,
                    isPublished: schedule.isPublished,
                    isLocked: schedule.isLocked,
                    inProgress: schedule.inProgress
                };
            } else {
                var status = this.get('status');
                var published = this.get('published');
                var inProgress = this.get('inProgress');
                var numDirty = this.get('numDirty');
                status = status === 'active' ? numDirty > 0 ? 'in progress' : 'not started' : status;
                retval = {
                    status: status,
                    isPublished: published,
                    inProgress: inProgress,
                    isLocked: this.get('isLocked')
                };
            }
            // console.log('status.display', retval)
            return retval;
        }),

        statusClass: Ember.computed('display', function () {
            var display = this.get('display');
            if (display.isPublished) {
                return 'green white-text';
            } else if (display.inProgress) {
                return 'purple white-text';
            } else {
                return 'gray-lighten-3 dark-text';
            }
            // if (display.status === ScheduleStatus.PRELIMINARY) {
            //     return 'yellow dark-text'
            // } else 
            // if (display.status <= ScheduleStatus.PRELIMINARY) {
            //     return 'gray-lighten-3 gray-text'
            // } else {
            //     if (display.isPublished) {
            //         return 'green white-text'
            //     } else if (display.inProgress) {
            //         return 'purple white-text'
            //     } else {
            //         return 'gray-lighten-3 dark-text'
            //     }
            // }
        })
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});