define('matrix-frontend/components/employees-totals', ['exports', 'ember-react-components', 'matrix-frontend/actions/employee', 'matrix-frontend/store', 'matrix-frontend/utils/colors', 'matrix-frontend/utils/date-helper', 'matrix-frontend/utils/state', 'npm:moment', 'react', 'matrix-frontend/components/react-only/employee-skill-cell'], function (exports, _emberReactComponents, _employee, _store, _colors, _dateHelper, _state, _npmMoment, _react, _employeeSkillCell) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    function defaultdict(createValue) {
        return new Proxy(Object.create(null), {
            get: function get(storage, property) {
                if (!(property in storage)) storage[property] = createValue(property);
                return storage[property];
            }
        });
    }

    var refreshEmployees = function refreshEmployees() {
        (0, _employee.employeeForceReload)(_store.store);
    };

    var Overview = function Overview(props) {
        var activitiesById = props.activitiesById,
            currentStore = props.currentStore;


        var minimumHours = props.employees ? props.employees.filter(function (x) {
            return currentStore.id === x.store.id;
        }).reduce(function (o, i) {
            return o += i.minimumHours;
        }, 0) : 0;
        var maximumHours = props.employees ? props.employees.filter(function (x) {
            return currentStore.id === x.store.id;
        }).reduce(function (o, i) {
            return o += i.maximumHours;
        }, 0) : 0;
        var numberOfUsers = props.employees ? props.employees.filter(function (x) {
            return currentStore.id === x.store.id;
        }).reduce(function (o, i) {
            return o += !!i.username;
        }, 0) : 0;
        var numBorrowed = props.employees ? props.employees.filter(function (x) {
            return currentStore.id !== x.store.id;
        }).length : 0;
        var skills = props.employees && activitiesById && Object.keys(activitiesById).length ? props.employees.filter(function (x) {
            return currentStore.id === x.store.id;
        }).reduce(function (o, i) {
            var compiled = i.skills.filter(function (x) {
                return activitiesById[x.activity].budget.id && activitiesById[x.activity].shouldDisplay;
            }).reduce(function (x, y) {
                x[y.activity] = !!y.level;
                return x;
            }, {});
            Object.keys(compiled).forEach(function (key) {
                return o[key] += compiled[key];
            });
            return o;
        }, defaultdict(function () {
            return 0;
        })) : [];

        return _react.default.createElement(
            _react.default.Fragment,
            null,
            _react.default.createElement(
                'div',
                { className: 'column f2' },
                numBorrowed ? _react.default.createElement(
                    'div',
                    { className: 'totals' },
                    _react.default.createElement(
                        'small',
                        { className: 'gray-lighten-1-text' },
                        'Totals exclude ',
                        numBorrowed,
                        ' borrowed'
                    )
                ) : ''
            ),
            _react.default.createElement('div', { className: 'column f2' }),
            _react.default.createElement(
                'div',
                { className: 'column f1' },
                minimumHours,
                '\u2013',
                maximumHours
            ),
            _react.default.createElement(
                'div',
                { className: 'column f3' },
                _react.default.createElement(_employeeSkillCell.default, { skills: skills, activitiesById: activitiesById, displayValue: true })
            ),
            _react.default.createElement(
                'div',
                { className: 'column f2' },
                numberOfUsers,
                ' users'
            )
        );
    };

    var Availability = function Availability(props) {
        var weekStart = (0, _npmMoment.default)((0, _dateHelper.getWeekStartDate)(props.weekEndDate));
        var raw = [].concat(_toConsumableArray(Array(7).keys())).map(function (x) {
            var date = weekStart.clone().add(x, 'days');
            return date.format('ddd M/D');
        });

        return _react.default.createElement(
            _react.default.Fragment,
            null,
            raw.map(function (x, i) {
                return _react.default.createElement(
                    'div',
                    { className: 'column f2', key: i },
                    x
                );
            })
        );
    };

    var Contact = function Contact(props) {
        var columns = [[2, 'Phone', ''], [3, 'Email', 'text'], [2, 'Emergency Contact', 'text'], [2, 'Emergency Phone', '']];
        var printColumns = [[2, 'Mobile', ''], [2, 'Home', ''], [2, 'Emergency Contact', 'text'], [2, 'Emergency Phone', '']];
        return _react.default.createElement(
            _react.default.Fragment,
            null,
            columns.map(function (x, i) {
                return _react.default.createElement(
                    'div',
                    { className: 'column f' + x[0] + ' hide-on-print ' + x[2], key: i },
                    x[1]
                );
            }),
            printColumns.map(function (x, i) {
                return _react.default.createElement(
                    'div',
                    { className: 'column f' + x[0] + ' flex-on-print ' + x[2], key: i },
                    x[1]
                );
            })
        );
    };

    var MinMax = function MinMax(props) {
        var minimumHours = props.employees ? props.employees.filter(function (x) {
            return props.currentStore.id === x.store.id;
        }).reduce(function (o, i) {
            return o += i.minimumHours;
        }, 0) : 0;
        var maximumHours = props.employees ? props.employees.filter(function (x) {
            return props.currentStore.id === x.store.id;
        }).reduce(function (o, i) {
            return o += i.maximumHours;
        }, 0) : 0;
        var maximumShifts = props.employees ? props.employees.filter(function (x) {
            return props.currentStore.id === x.store.id;
        }).reduce(function (o, i) {
            return o += i.maximumShifts;
        }, 0) : 0;
        var columns = [['Min Hours/Week', minimumHours + ' hrs'], ['Max Hours/Week', maximumHours + ' hrs'], ['Max Shifts/Week', maximumShifts + ' shifts']];
        return _react.default.createElement(
            _react.default.Fragment,
            null,
            columns.map(function (_ref, i) {
                var _ref2 = _slicedToArray(_ref, 2),
                    x = _ref2[0],
                    y = _ref2[1];

                return _react.default.createElement(
                    'div',
                    { className: 'column f2 as-columns', key: i },
                    _react.default.createElement(
                        'div',
                        null,
                        x
                    ),
                    _react.default.createElement(
                        'small',
                        null,
                        y
                    )
                );
            })
        );
    };

    var Skills = function Skills(props) {
        var activitiesById = props.activitiesById;


        if (!activitiesById || !Object.keys(activitiesById).length) {
            return _react.default.createElement('div', null);
        }

        var compiled = Object.keys(activitiesById).filter(function (x) {
            return activitiesById[x].budget.id && activitiesById[x].shouldDisplay;
        }).map(function (id, index) {
            var name = activitiesById[id].name;
            var color = activitiesById && activitiesById[id] ? activitiesById[id].livecolor.primary.replace(/ /g, '') : 'gray';
            return {
                key: index,
                style: {
                    borderColor: color,
                    backgroundColor: color,
                    color: (0, _colors.textColor)(color)
                },
                activityPriority: activitiesById[id].activityPriority,
                name: name
            };
        }).sortBy('activityPriority').map(function (x) {
            delete x.activityPriority;
            return x;
        });

        return _react.default.createElement(
            _react.default.Fragment,
            null,
            compiled.map(function (x, i) {
                return _react.default.createElement(
                    'div',
                    { className: 'column f2', key: i, style: x.style },
                    x.name
                );
            })
        );
    };

    var getDisplay = function getDisplay(props) {
        switch (props.view) {
            case 'availability':
                return _react.default.createElement(Availability, props);
            case 'contact':
                return _react.default.createElement(Contact, props);
            case 'minmax':
                return _react.default.createElement(MinMax, props);
            case 'skills':
                return _react.default.createElement(Skills, props);
            default:
                return _react.default.createElement(Overview, props);
        }
    };

    var comp = function comp(props) {
        var openNewEmployeeModal = props.openNewEmployeeModal,
            currentUser = props.currentUser,
            currentStore = props.currentStore;

        var canAddEmployee = currentUser && (0, _state.hasScope)('employee.store:add');
        // (isFieldAdmin() || currentUser.salaryVisibility > 0) &&
        // (hasRole('schedule2', true) || hasRole('approver'))
        return _react.default.createElement(
            'div',
            { className: 'row' },
            _react.default.createElement(
                'div',
                { className: 'wrapper f2' },
                _react.default.createElement(
                    'div',
                    { className: 'column text f1 no-left' },
                    _react.default.createElement('span', { className: 'icon-refresh', onClick: refreshEmployees })
                ),
                _react.default.createElement(
                    'div',
                    { className: 'column text f6 no-left' },
                    props.employees ? _react.default.createElement(
                        'span',
                        null,
                        _react.default.createElement(
                            'span',
                            { className: 'breathe-r-10' },
                            props.employees ? props.employees.filter(function (x) {
                                return currentStore.id === x.store.id;
                            }).length : 'loading',
                            ' ',
                            'employees'
                        ),
                        canAddEmployee ? _react.default.createElement(
                            'a',
                            { className: 'small thin no-print', onClick: function onClick() {
                                    return openNewEmployeeModal();
                                } },
                            'Add'
                        ) : ''
                    ) : 'loading'
                )
            ),
            _react.default.createElement(
                'div',
                { className: 'wrapper f9' },
                getDisplay(props)
            )
        );
    };
    exports.default = (0, _emberReactComponents.default)(comp);
});