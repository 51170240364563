define('matrix-frontend/models/attachment', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        displayName: _emberData.default.attr('string'),
        hierarchyNode: _emberData.default.belongsTo('hierarchy-node', { async: true }),
        attachmentStore: _emberData.default.belongsTo('store', { async: true }),
        weekEndDate: _emberData.default.attr('date')
    });
});