define('matrix-frontend/pods/claim/show/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        redux: Ember.inject.service(),

        model: function model() {
            var _get$getState = this.get('redux').getState(),
                claim = _get$getState.selfServe.claim;

            return claim;
        }
    });
});