define('matrix-frontend/controllers/error', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        loadingService: Ember.inject.service(),

        errorMessage: Ember.computed('model.status', function () {
            var status = this.get('model.status');

            if (status === 503) {
                return "Sorry, we're currently down for maintenance.";
            } else if (status === 404 || status === undefined) {
                return "This page seems to be missing, are you sure you're in the right spot?";
            } else {
                return 'Error.';
            }
        }),

        displayHomeLink: Ember.computed('model.status', function () {
            var status = this.get('model.status');
            var retVal = false;

            if (status !== 503) {
                retVal = true;
            }

            this.get('loadingService').endLoading();

            return retVal;
        })
    });
});