define('matrix-frontend/components/react-only/employee-rollup-body', ['exports', 'react', 'matrix-frontend/components/react-only/employee-rollup-row'], function (exports, _react, _employeeRollupRow) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var FakeRow = function FakeRow() {
        return _react.default.createElement(
            'div',
            { className: 'row' },
            _react.default.createElement(
                'div',
                { className: 'wrapper f2' },
                _react.default.createElement('div', { className: 'column text f1' }),
                _react.default.createElement('div', { className: 'column text f6 no-left fake-text' })
            ),
            _react.default.createElement(
                'div',
                { className: 'wrapper f9' },
                _react.default.createElement('div', { className: 'column f2 fake-text gray-lighten-3-text' }),
                _react.default.createElement('div', { className: 'column f1 fake-text gray-lighten-3-text' }),
                _react.default.createElement('div', { className: 'column f3 fake-text gray-lighten-3-text' }),
                _react.default.createElement('div', { className: 'column f2 fake-text gray-lighten-3-text' })
            )
        );
    };

    var Loading = function Loading(props) {
        return _react.default.createElement(
            _react.default.Fragment,
            null,
            [].concat(_toConsumableArray(Array(10).keys())).map(function (x) {
                return _react.default.createElement(FakeRow, { key: x });
            })
        );
    };

    var NodeRows = function NodeRows(props) {
        var passthru = _extends({}, props);
        delete passthru.items;

        return _react.default.createElement(
            _react.default.Fragment,
            null,
            props.items.map(function (item, idx) {
                return _react.default.createElement(_employeeRollupRow.default, _extends({
                    item: item,
                    key: item.id,
                    row: idx
                }, passthru));
            })
        );
    };

    var comp = function comp(props) {
        var Display = props.items === null ? Loading : NodeRows;
        return _react.default.createElement(
            'section',
            { id: 'employee-wrapper', className: 'body' },
            _react.default.createElement(Display, props)
        );
    };

    exports.default = comp;
});