define('matrix-frontend/pods/components/shift-full-time/component', ['exports', 'ember-redux', 'matrix-frontend/actions/_types', 'npm:moment'], function (exports, _emberRedux, _types, _npmMoment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var stateToComputed = function stateToComputed(state) {
        return {
            weekEndDate: state.cube.weekEndDate
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {};
    };

    var Comp = Ember.Component.extend({
        shiftBlocks: Ember.computed('shift', function () {
            var shift = this.get('shift');
            var blocks = shift ? shift.blocks : [];

            // return blocks.sort((x, y) => moment(y.start).isBefore(moment(x.start)));
            return blocks.sortBy('start');
        }),

        firstBlock: Ember.computed('shiftBlocks', function () {
            var shiftBlocks = this.get('shiftBlocks');
            return shiftBlocks.length > 0 ? shiftBlocks[0] : null;
        }),

        lastBlock: Ember.computed('shiftBlocks', function () {
            var shiftBlocks = this.get('shiftBlocks');
            if (shiftBlocks) {
                var _shiftBlocks$slice = shiftBlocks.slice(-1),
                    _shiftBlocks$slice2 = _slicedToArray(_shiftBlocks$slice, 1),
                    last = _shiftBlocks$slice2[0];

                return last;
            } else {
                return null;
            }
        }),

        unavailableReason: Ember.computed('employee', function () {
            var employee = this.get('employee');
            var dotw = this.get('dotw');
            if (employee && employee.availability) {
                var availability = employee.availability.exceptions && employee.availability.exceptions[dotw] ? employee.availability.exceptions[dotw] : employee.availability.default[dotw];
                var isUnvailable = function isUnvailable(x) {
                    return !x.isAvailable;
                };

                if (availability.every(isUnvailable)) {
                    return availability[0].reason || 'Not available';
                }
            }
            return null;
        }),

        isTerminated: Ember.computed('employee.terminationDate', 'dotw', 'weekEndDate', function () {
            var terminationDate = this.get('employee.terminationDate');
            var dotw = this.get('dotw');
            var weekEndDate = this.get('weekEndDate');
            if (terminationDate) {
                var day = (0, _npmMoment.default)(weekEndDate).day(dotw - 1);
                return day.isAfter(terminationDate, 'day');
            }
            return false;
        }),

        notStarted: Ember.computed('employee.startDate', 'dotw', 'weekEndDate', function () {
            var startDate = this.get('employee.startDate');
            var dotw = this.get('dotw');
            var weekEndDate = this.get('weekEndDate');
            if (startDate) {
                var day = (0, _npmMoment.default)(weekEndDate).day(dotw - 1);
                return day.isBefore(startDate, 'day');
            }
            return false;
        })
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});