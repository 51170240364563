define('matrix-frontend/components/schedule-printer', ['exports', 'ember-react-components', 'react'], function (exports, _emberReactComponents, _react) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    // const styles = StyleSheet.create({
    //     page: {
    //         flexDirection: 'row',
    //         backgroundColor: '#E4E4E4',
    //     },
    //     section: {
    //         margin: 10,
    //         padding: 10,
    //         flexGrow: 1,
    //     },
    // })

    // // Create Document Component
    // const MyDocument = () => (
    //     <Document>
    //         <Page size="A4">
    //             <View>
    //                 <Text>Section #1</Text>
    //             </View>
    //             <View>
    //                 <Text>Section #2</Text>
    //             </View>
    //         </Page>
    //     </Document>
    // )

    // <PDFViewer>
    //     <MyDocument />
    // </PDFViewer>
    // const {Page, Text, View, Document, PDFViewer, render} = require('npm:@react-pdf/renderer')

    var comp = function comp(props) {
        return _react.default.createElement(
            'div',
            { className: 'schedule-printer' },
            'schedule printer: ',
            props.pages
        );
    };

    exports.default = (0, _emberReactComponents.default)(comp);
});