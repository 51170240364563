define('matrix-frontend/components/react-only/schedule-shift', ['exports', 'react', 'ember-react-components', 'matrix-frontend/utils/date-helper', 'matrix-frontend/store'], function (exports, _react, _emberReactComponents, _dateHelper, _store) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var ShiftBlock = function ShiftBlock(props) {
        var left = (props.start - props.segmentOffset) * props.segmentSize;
        var width = (props.end - props.start) * props.segmentSize;
        return _react.default.createElement('div', {
            className: 'shift-block ' + props.activity,
            style: {
                left: left + 'px',
                width: width + 'px'
            }
        });
    };

    var comp = function comp(props) {
        var shift = props.shift,
            segmentSize = props.segmentSize,
            segmentOffset = props.segmentOffset,
            leftOffset = props.leftOffset,
            activitiesById = props.activitiesById;


        var weekEndDate = _store.store.getState().cube.weekEndDate;

        var blocks = shift.blocks.map(function (x, i) {
            var color = activitiesById[x.activityId].livecolor.id;
            var attrs = {
                segmentSize: segmentSize,
                segmentOffset: segmentOffset,
                start: (0, _dateHelper.momentToSegment)(x.start, weekEndDate),
                end: (0, _dateHelper.momentToSegment)(x.end, weekEndDate),
                activity: 'activity-color-' + color
            };
            return _react.default.createElement(ShiftBlock, _extends({ key: 'shift-block-' + i }, attrs));
        });

        return _react.default.createElement(
            'div',
            { className: 'shift-wrapper' },
            _react.default.createElement(
                'div',
                { className: 'shift' },
                blocks
            )
        );
    };

    exports.default = comp;
});