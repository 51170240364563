define('matrix-frontend/pods/components/focus-input/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.TextField.extend({
        empty: true,
        // type: "mxltext",

        focusIn: function focusIn(event) {
            this._super(event);
            if (this.additionalFocus) {
                this.additionalFocus();
            }
            if (this.empty) {
                this.$().val('');
            } else {
                this.$().select();
            }
        }
    });
});