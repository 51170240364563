define('matrix-frontend/initializers/session', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    function initialize(application) {
        var Session = Ember.Object.extend({});

        application.register('session:main', Session);
        application.inject('route', 'session', 'session:main');
        application.inject('adapter', 'session', 'session:main');
        application.inject('controller', 'session', 'session:main');
        application.inject('component', 'session', 'session:main');
        application.inject('service', 'session', 'session:main');
    }

    exports.default = {
        name: 'session',
        initialize: initialize
    };
});