define('matrix-frontend/pods/components/schedule-page-section-prehead/component', ['exports', 'ember-redux', 'matrix-frontend/actions/_types', 'npm:lodash', 'matrix-frontend/utils/keyboard-shortcuts', 'matrix-frontend/utils/state', 'matrix-frontend/utils/date-helper'], function (exports, _emberRedux, _types, _npmLodash, _keyboardShortcuts, _state, _dateHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    var stateToComputed = function stateToComputed(state) {
        var _ref;

        return _ref = {
            date: state.cube.date,
            weekEndDate: state.cube.weekEndDate,
            clients: state.client.clients,
            node: state.cube.node,
            stores: state.node.stores,
            editMode: state.scheduleEdit.editMode,
            currentBrush: state.scheduleEdit.currentBrush,
            closedAllDay: state.scheduleEdit.closedAllDay,
            preferences: state.preferences,
            currentUser: state.user.currentUser,
            sid: state.websocket.sid,
            viewers: state.websocket.viewers
        }, _defineProperty(_ref, 'currentUser', state.user.currentUser), _defineProperty(_ref, 'showEmployeeFilter', state.scheduleEdit.showEmployeeFilter), _defineProperty(_ref, 'employeeFilters', state.scheduleEdit.employeeFilters), _ref;
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {
            setPaintbrush: function setPaintbrush(activityId) {
                return dispatch({
                    type: _types.SE_SET_PAINTBRUSH,
                    payload: { activityId: activityId }
                });
            },
            toggleChart: function toggleChart(displayChart) {
                return dispatch({ type: _types.PREFERENCE_SET, payload: { displayChart: displayChart } });
            },
            toggleEmployeeFilter: function toggleEmployeeFilter(_) {
                return dispatch({ type: _types.SE_TOGGLE_EMPLOYEE_FILTER });
            },
            setEmployeeFilterPosition: function setEmployeeFilterPosition(filterType, value) {
                return dispatch({
                    type: _types.SE_SET_EMPLOYEE_FILTER,
                    payload: {
                        filterType: filterType,
                        value: value
                    }
                });
            },
            openHOOModal: function openHOOModal() {
                this.modalService.open('hoo-modal');
            }
        };
    };

    var setActivityAction = function setActivityAction(event, key) {
        var activities = this.get('activitiesShouldDisplay');
        if (activities) {
            // 2019-02-04 - AMH
            // Originally, I tried implementing this with a callback to the same
            // setPaintbrushs.setPaintbrush that is used by the click handler.
            // However, it takes upwards of a millenia to render and causes some
            // sort of a recurring loop. Therefore, as a simple hack to get around
            // this issue, we just trigger the click event on the corresponding
            // element.

            key = parseInt(key);
            if (key === 0) {
                key = activities.length;
            }
            var id = '#activity-selector-' + key;
            Ember.$(id).trigger('click');
        }
    };

    var shift = function shift(event, key) {
        this.set('isShift', event.shiftKey);
        event.preventDefault();
        return false;
    };

    var ctrl = function ctrl(event, key) {
        this.set('isAlt', event.ctrlKey);
        event.preventDefault();
        return false;
    };

    var Comp = Ember.Component.extend({
        redux: Ember.inject.service(),
        modalService: Ember.inject.service(),
        isShift: false,
        isAlt: false,
        REMOVE_ACTIVITY: -1,
        BREAK_ACTIVITY: 1,

        keyboardShortcuts: {
            1: {
                action: setActivityAction,
                global: false,
                eventType: 'keyup'
            },
            2: {
                action: setActivityAction,
                global: false,
                eventType: 'keyup'
            },
            3: {
                action: setActivityAction,
                global: false,
                eventType: 'keyup'
            },
            4: {
                action: setActivityAction,
                global: false,
                eventType: 'keyup'
            },
            5: {
                action: setActivityAction,
                global: false,
                eventType: 'keyup'
            },
            6: {
                action: setActivityAction,
                global: false,
                eventType: 'keyup'
            },
            7: {
                action: setActivityAction,
                global: false,
                eventType: 'keyup'
            },
            8: {
                action: setActivityAction,
                global: false,
                eventType: 'keyup'
            },
            9: {
                action: setActivityAction,
                global: false,
                eventType: 'keyup'
            },
            0: {
                action: setActivityAction,
                global: false,
                eventType: 'keyup'
            },
            shift: {
                action: shift,
                global: false,
                eventType: ['keyup', 'keydown']
            },
            ctrl: {
                action: ctrl,
                global: false,
                eventType: ['keyup', 'keydown']
            }
        },

        activitiesShouldDisplay: Ember.computed('activities', function () {
            var activities = this.get('activities') || [];
            return activities.filter(function (x) {
                return x.shouldDisplay;
            });
        }),

        currentClient: Ember.computed('clients', 'node', function () {
            var node = this.get('node');
            var clients = this.get('clients');
            return node && clients ? clients[node.clientCode] : null;
        }),

        currentStore: Ember.computed('node', 'stores', function () {
            var node = this.get('node');
            var stores = this.get('redux').store.getState().node.stores;
            if (node && stores) {
                return stores[node.clientCode + '.' + node.tag];
            } else {
                return null;
            }
        }),

        canEdit: Ember.computed('currentUser', function () {
            return (0, _state.hasRole)('schedule1', true);
        }),

        canEditHoO: Ember.computed('currentUser', function () {
            return (0, _state.hasScope)('store.hoo:edit');
        }),

        numViewers: Ember.computed('viewers', 'currentUser.username', function () {
            var currentViewers = this.get('viewers');
            var currentUser = this.get('currentUser');

            if (currentViewers && currentUser) {
                var viewers = [].concat(_toConsumableArray(currentViewers)).filter(function (x) {
                    return x !== currentUser.username;
                });
                return viewers.length;
            }
            return 0;
        }),

        dotw: Ember.computed('date', function () {
            var date = this.get('date');
            if (!date) {
                return null;
            }
            return (0, _dateHelper.getDayOfClientWeek)(date) + 1;
        }),

        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            (0, _keyboardShortcuts.bindKeyboardShortcuts)(this);
        },
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);
            (0, _keyboardShortcuts.unbindKeyboardShortcuts)(this);
        },


        hours: Ember.computed('currentStore.weeks', 'weekEndDate', 'dotw', function () {
            var currentStore = this.get('currentStore');
            var weekEndDate = this.get('weekEndDate');
            var dotw = this.get('dotw');
            if (dotw && currentStore && weekEndDate && currentStore.weeks && currentStore.weeks[weekEndDate.format('YYYY-MM-DD')] && currentStore.weeks[weekEndDate.format('YYYY-MM-DD')].hours) {
                console.info(currentStore.weeks[weekEndDate.format('YYYY-MM-DD')]);
                var hours = currentStore.weeks[weekEndDate.format('YYYY-MM-DD')].hours[dotw];
                return hours;
            }
            return null;
        }),

        employeeSkillFilterClasses: Ember.computed('employeeFilters', 'activitiesById', function () {
            var employeeSkillFilterActivityId = this.get('employeeFilters.skill');
            var activitiesById = this.get('activitiesById');
            if (activitiesById && employeeSkillFilterActivityId) {
                return ' activity-color-' + activitiesById[employeeSkillFilterActivityId].livecolor.id;
            }
            return '';
        }),

        employeeSkillFilterBorderColor: Ember.computed('employeeSkillFilterClasses', function () {
            var employeeSkillFilterActivityId = this.get('employeeFilters.skill');
            var activitiesById = this.get('activitiesById');
            if (activitiesById && employeeSkillFilterActivityId) {
                return activitiesById[employeeSkillFilterActivityId].livecolor.secondary;
            }
            return '#e7e7e7';
        })
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});