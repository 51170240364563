define('matrix-frontend/pods/components/swipeable-view/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        // recognizers: 'swipe tap',
        // swipeLeft: function (e) {
        //   e.stopPropagation();
        //   this.get('targetObject').send(this.get('targetObject').get('swipeLeftAction'));
        // },
        // swipeRight: function (e) {
        //   e.stopPropagation();
        //   this.get('targetObject').send(this.get('targetObject').get('swipeRightAction'));
        // }
    });
});