define('matrix-frontend/components/react-only/schedule-brush', ['exports', 'react', 'matrix-frontend/utils/date-helper', 'matrix-frontend/actions/_types', 'matrix-frontend/store', 'npm:moment'], function (exports, _react, _dateHelper, _types, _store, _npmMoment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var REMOVE_ACTIVITY = -1;
    var BREAK_ACTIVITY = 1;

    var pointToSegment = function pointToSegment(point, segmentSize) {
        var offset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

        var number = point / segmentSize;
        return number + offset;
    };

    var pointToTime = function pointToTime(point, segmentSize) {
        var offset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

        var segment = pointToSegment(point, segmentSize, offset);
        var time = (0, _dateHelper.segmentToTime)(segment);
        time.minutes = Math.floor(time.minutes / 15) * 15;
        return time;
    };

    var timeToDisplay = function timeToDisplay(time) {
        var hours = time.hours,
            minutes = time.minutes;

        var hoursDisplay = hours > 12 ? hours - 12 : hours;
        var minutesDisplay = minutes > 0 ? ':' + minutes : '';
        var ampmDisplay = hours >= 12 ? 'p' : 'a';
        return '' + hoursDisplay + minutesDisplay + ampmDisplay;
    };

    var commitBrush = function commitBrush(start, end, activityId, employeeRow) {
        var _store$getState = _store.store.getState(),
            _store$getState$cube = _store$getState.cube,
            date = _store$getState$cube.date,
            dotw = _store$getState$cube.dotw,
            currentUser = _store$getState.user.currentUser;

        var startDateOffset = start.dotw - dotw;
        var endDateOffset = end.dotw - dotw;
        var startMoment = date.clone().add(startDateOffset, 'days').hours(start.hours).minutes(start.minutes);
        var stopMoment = date.clone().add(endDateOffset, 'days').hours(end.hours).minutes(end.minutes);

        _store.store.dispatch({
            type: _types.SE_COMPILE_BRUSH,
            payload: {
                date: date,
                currentUser: currentUser,
                activityId: activityId,
                startMoment: startMoment,
                stopMoment: stopMoment,
                employeeRow: employeeRow
            }
        });
    };

    var comp = function comp(props) {
        var mouseState = props.state,
            mouseDown = props.mouseDown,
            viewableHours = props.viewableHours,
            activityPaint = props.activityPaint,
            employeeRow = props.employeeRow,
            brushWidth = props.brushWidth,
            segmentSize = props.segmentSize,
            segmentOffset = props.segmentOffset,
            leftOffset = props.leftOffset,
            unavailability = props.unavailability,
            activitiesById = props.activitiesById;

        var state = mouseState || {};

        var _useState = (0, _react.useState)(false),
            _useState2 = _slicedToArray(_useState, 2),
            isPainting = _useState2[0],
            setPainting = _useState2[1];

        var _useState3 = (0, _react.useState)(false),
            _useState4 = _slicedToArray(_useState3, 2),
            atMax = _useState4[0],
            setAtMax = _useState4[1];

        var _useState5 = (0, _react.useState)(false),
            _useState6 = _slicedToArray(_useState5, 2),
            display = _useState6[0],
            setDisplay = _useState6[1];

        var _useState7 = (0, _react.useState)(0),
            _useState8 = _slicedToArray(_useState7, 2),
            startPoint = _useState8[0],
            setStart = _useState8[1];

        var _useState9 = (0, _react.useState)(null),
            _useState10 = _slicedToArray(_useState9, 2),
            startTime = _useState10[0],
            setStartTime = _useState10[1];

        var _useState11 = (0, _react.useState)(null),
            _useState12 = _slicedToArray(_useState11, 2),
            endTime = _useState12[0],
            setEndTime = _useState12[1];

        var _useState13 = (0, _react.useState)(0),
            _useState14 = _slicedToArray(_useState13, 2),
            duration = _useState14[0],
            setDuration = _useState14[1];

        var _useState15 = (0, _react.useState)(brushWidth),
            _useState16 = _slicedToArray(_useState15, 2),
            width = _useState16[0],
            setWidth = _useState16[1];

        var activityId = state.shiftKey ? REMOVE_ACTIVITY : state.altKey ? BREAK_ACTIVITY : activityPaint ? activityPaint.id : null;
        var strokeActivity = activityId ? activitiesById[activityId] : null;
        var stroke = strokeActivity ? 'activity-color-' + strokeActivity.livecolor.id : '';

        (0, _react.useEffect)(function () {
            if (!state.holding) {
                setDisplay(state.present);
            }
        }, [state.present]);

        (0, _react.useEffect)(function () {
            if (!state.holding) {
                if (isPainting) {
                    var start = pointToTime(startPoint, segmentSize, segmentOffset);
                    var end = pointToTime(startPoint + width, segmentSize, segmentOffset);
                    commitBrush(start, end, activityId, employeeRow);
                }
                setWidth(brushWidth);
                setPainting(false);
                setStartTime(null);
                setEndTime(null);
                setDisplay(false);
                setAtMax(false);
            } else if (!Ember.$(state.target).hasClass('unavailability-block')) {
                setPainting(true);
            }
        }, [state.holding, mouseDown]);

        (0, _react.useEffect)(function () {
            // console.log({target: state.target})
            var offset = void 0;
            var unavailabilityLeft = void 0;
            // const operation = state.holding ? Math.ceil : Math.floor
            var operation = Math.floor;
            // console.log('state.eventName', state.eventName)
            if (state.holding && state.eventName !== 'touchStart') {
                if (isPainting) {
                    offset = operation((state.clientX - leftOffset) / segmentSize) * segmentSize;

                    if (Ember.$(state.target).hasClass('unavailability-block')) {
                        unavailabilityLeft = Ember.$(state.target).offset().left;
                        var effectiveOffset = operation((unavailabilityLeft - leftOffset) / segmentSize) * segmentSize;
                        var end = pointToTime(effectiveOffset, segmentSize, segmentOffset);
                        setEndTime(end);
                    } else {
                        var _end = pointToTime(offset, segmentSize, segmentOffset);
                        setEndTime(_end);
                    }

                    var _width = offset - startPoint;
                    if (!Ember.$(state.target).hasClass('unavailability-block')) {
                        setWidth(offset - startPoint);
                        if (atMax) {
                            setAtMax(false);
                        }
                    } else if (_width > 0) {
                        unavailabilityLeft = Ember.$(state.target).offset().left;
                        setWidth(unavailabilityLeft - leftOffset - startPoint);
                        setAtMax(true);
                    }
                }
            } else {
                offset = operation((state.clientX - leftOffset) / segmentSize) * segmentSize;

                // console.log({offset, state, leftOffset, segmentSize})
                if (offset > 0 && !Ember.$(state.target).hasClass('unavailability-block')) {
                    setStart(offset);

                    var start = pointToTime(offset, segmentSize, segmentOffset);
                    setStartTime(start);
                }
            }
            if (!state.display && state.present) {
                setDisplay(true);
            }
        }, [state.clientX]);

        (0, _react.useEffect)(function () {
            if (startTime && endTime) {
                if (!atMax) {
                    var start = startTime.hours + startTime.minutes / 60;
                    var end = (startTime.dotw !== endTime.dotw ? 24 * (endTime.dotw - startTime.dotw) : 0) + endTime.hours + endTime.minutes / 60;
                    setDuration(end - start);
                }
            } else {
                setDuration(0);
            }
        }, [endTime]);

        if (display && startPoint > 0) {
            var classNames = ['schedule-brush', isPainting ? stroke + ' border' : ''];
            var startDisplay = startTime && duration >= 0 ? _react.default.createElement(
                'span',
                { className: 'brush-start' },
                timeToDisplay(startTime)
            ) : duration < 0 ? _react.default.createElement(
                'span',
                { className: 'brush-start' },
                _react.default.createElement(
                    'em',
                    null,
                    'REMOVED'
                )
            ) : '';
            var endDisplay = endTime && duration >= 0 ? _react.default.createElement(
                'span',
                { className: 'brush-end' },
                timeToDisplay(endTime)
            ) : '';
            var durationDisplay = duration >= 2 ? _react.default.createElement(
                'span',
                { className: 'brush-duration' },
                duration + 'hrs'
            ) : '';

            return _react.default.createElement(
                'div',
                { className: 'brush-wrapper' },
                _react.default.createElement(
                    'div',
                    {
                        className: classNames.join(' '),
                        style: {
                            left: startPoint + 'px',
                            width: width + 'px'
                        }
                    },
                    startDisplay,
                    durationDisplay,
                    endDisplay
                )
            );
        } else {
            return _react.default.createElement(
                'div',
                { className: 'brush-wrapper' },
                _react.default.createElement('div', null)
            );
        }
    };

    // export default WithEmberSupport(comp)
    exports.default = comp;
});