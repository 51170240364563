define('matrix-frontend/pods/components/header-bar/component', ['exports', 'ember-redux', 'npm:moment', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/socket', 'matrix-frontend/utils/cleaners'], function (exports, _emberRedux, _npmMoment, _types, _socket, _cleaners) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var stateToComputed = function stateToComputed(state) {
        return {
            status: state.websocket.status,
            currentSocket: state.websocket.currentSocket,
            feed: state.websocket.feed,
            currentUser: state.user.currentUser,
            node: state.cube.node
        };
    };
    // import { decrement } from '../actions/timeout';


    var dispatchToActions = function dispatchToActions(dispatch) {
        return {
            // describe: () =>
            //     dispatch({type: types.WS_SEND, payload: {header: 'describe'}}),
            // reconnect: (currentUser, clientCode, tag) => {
            //     const {token} = currentUser
            //     const {feed, socket} = make_connection(
            //         clientCode,
            //         clean_tag(tag),
            //         token,
            //     )
            //     socket.onopen = () => {
            //         dispatch({
            //             type: types.WS_CONNECTING,
            //         })
            //         if (socket.readyState === 1) {
            //             console.info(`reconnected to ${feed}`)
            //             dispatch({
            //                 type: types.WS_CONNECT,
            //                 payload: {
            //                     feed,
            //                     socket,
            //                 },
            //             })
            //         } else if (socket.readyState === 3) {
            //             dispatch({
            //                 type: types.WS_CLOSE,
            //             })
            //             console.error('Socket connection closed')
            //         }
            //     }
            //     socket.onmessage = e => {
            //         dispatch({
            //             type: types.WS_RECEIVE,
            //             payload: JSON.parse(e.data),
            //         })
            //     }
            //     socket.onerror = e => {
            //         console.error(e)
            //     }
            //     socket.onclose = e => {
            //         dispatch({
            //             type: types.WS_CLOSE,
            //         })
            //         console.log('ConnectionClosed')
            //     }
            // },
        };
    };

    var Comp = Ember.Component.extend({
        WebSocket: WebSocket,
        stateService: Ember.inject.service(),
        cookies: Ember.inject.service(),

        accountMenuHidden: true,
        userMenuHidden: true,
        matrixMenuHidden: true,
        clientMenuHidden: true,
        envHidden: false,
        currentPath: null,
        ignoreVersion: false,

        environmentLinks: [{
            // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
            name: 'Test',
            url: 'https://test.matrixretail.com',
            env: 'test'
        }, {
            name: 'Stage',
            url: 'https://stage.matrixretail.com',
            env: 'stage'
        }, {
            name: 'Hosted',
            url: 'https://hosted.matrixretail.com',
            env: 'prod'
        }],

        hexColorsArray: ['#187DA5', '#EDF055'], // eslint-disable-line ember/avoid-leaking-state-in-ember-objects

        // client: computed('stateService.client', function() {
        //   return this.get('stateService').getClient();
        // }),
        client: Ember.computed.oneWay('stateService.client'),

        userInitial: Ember.computed(function () {
            return this.session.get('user').get('name').charAt(0);
        }),

        lastSuccessfulLogin: Ember.computed(function () {
            var lastLogin = this.session.get('user').get('lastSuccessfulLogin');
            return (0, _npmMoment.default)(lastLogin).fromNow();
        }),

        isAdminUser: Ember.computed('currentUser.roles', function () {
            var roles = this.get('currentUser.roles');
            if (!roles) {
                return false;
            }
            return roles.any(function (role) {
                return 'matrix_admin' === role.name;
            });
            // return this.session
            //     .get('user')
            //     .get('roles')
            //     .any(role => {
            //         return 'matrix_admin' === role.get('name')
            //     })
        }),

        isEmployeeAdminUser: Ember.computed(function () {
            return this.session.get('user').get('roles').any(function (role) {
                return 'matrix_admin' === role.get('name') || role.get('name') == 'employee_admin';
            });
        }),

        currentPathDidChange: function () {
            // eslint-disable-line ember/no-function-prototype-extensions
            this.get('loadingService').endLoading();
        }.observes('currentPath'),

        actions: {
            logout: function logout() {
                this.get('cookies').write('loggedOut', 1, { path: '/' });
                window.location = '/workforce/logout';
            },
            toggleAccountMenu: function toggleAccountMenu() {
                this.toggleProperty('accountMenuHidden');
                this.set('userMenuHidden', true);
                this.set('matrixMenuHidden', true);
                this.set('clientMenuHidden', true);
            },
            toggleUserMenu: function toggleUserMenu() {
                this.toggleProperty('userMenuHidden');
                this.set('accountMenuHidden', true);
                this.set('matrixMenuHidden', true);
                this.set('clientMenuHidden', true);
            },
            toggleMatrixMenu: function toggleMatrixMenu() {
                this.toggleProperty('matrixMenuHidden');
                this.set('accountMenuHidden', true);
                this.set('userMenuHidden', true);
                this.set('clientMenuHidden', true);
            },
            toggleClientMenu: function toggleClientMenu() {
                this.toggleProperty('clientMenuHidden');
                this.set('accountMenuHidden', true);
                this.set('userMenuHidden', true);
                this.set('matrixMenuHidden', true);
            },
            toggleEnvironment: function toggleEnvironment() {
                this.toggleProperty('envHidden');
            },
            legacyApp: function legacyApp() {
                window.open('/workforce/application/index.jsf', '_blank');
            },
            envLink: function envLink(url) {
                window.location = url;
            },
            goToUserManagement: function goToUserManagement() {
                this.sendAction('transInComponent', 'user-management'); // eslint-disable-line ember/closure-actions
            },
            goToRoleScopeManagement: function goToRoleScopeManagement() {
                this.sendAction('transInComponent', 'rolescope-management'); // eslint-disable-line ember/closure-actions
            },
            ignoreVersion: function ignoreVersion() {
                this.set('ignoreVersion', true);
            },
            refreshVersion: function refreshVersion() {
                window.location.reload(true);
                // this.set("ignoreVersion", true)
            }
        }
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});