define('matrix-frontend/pods/components/schedule-page-section-row/component', ['exports', 'ember-redux', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/date-helper', 'npm:moment'], function (exports, _emberRedux, _types, _dateHelper, _npmMoment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var stateToComputed = function stateToComputed(state) {
        return {
            currentBrush: state.scheduleEdit.currentBrush,
            lastExpandedRow: state.scheduleEdit.lastExpandedRow,
            expandedRow: state.scheduleEdit.expandedRow,
            shifts: state.scheduleEdit.shifts,
            shiftsByEmployee: state.scheduleEdit.shiftsByEmployee,
            startLeft: state.scheduleEdit.startLeft,
            startTop: state.scheduleEdit.startTop,
            displayStart: state.scheduleEdit.displayStart,
            displayEnd: state.scheduleEdit.displayEnd,
            closedAllDay: state.scheduleEdit.closedAllDay,
            employeeFlags: state.scheduleEdit.employeeFlags,
            editMode: state.scheduleEdit.editMode,
            date: state.cube.date,
            weekEndDate: state.cube.weekEndDate,
            clients: state.client.clients,
            node: state.cube.node
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {
            pinEmployeePanel: function pinEmployeePanel(employee) {
                return dispatch({ type: _types.EP_PIN, payload: { employee: employee } });
            },
            expandRow: function expandRow(rowId) {
                return dispatch({
                    type: _types.SE_EXPAND_ROW,
                    rowId: rowId
                });
            }
        };
    };

    var Comp = Ember.Component.extend({
        modalService: Ember.inject.service(),

        init: function init() {
            this._super.apply(this, arguments);
            var employeeId = this.get('employee.id');
            // defineProperty(this, 'employeeShifts', computed.oneWay(`shiftsByEmployee.${employeeId}`));
        },


        // employeeShifts: computed('_es', function () {
        //     return this.get('_es');
        // }),
        // employeeShifts: computed('shifts', function () {
        employeeShifts: Ember.computed('shiftsByEmployee', function () {
            var id = this.get('employee.id');
            var shifts = this.get('shifts');
            var shiftsArr = Object.keys(shifts).map(function (x) {
                return shifts[x];
            });
            var shiftsByEmployee = this.get('shiftsByEmployee');
            if (id && shiftsByEmployee) {
                var es = shiftsByEmployee[id];
                if (es) {
                    return es;
                }
            }
            return null;

            // if (shifts) {
            //     const shiftsByEmployee = shiftsArr.filter(x => x.meta.employeeId == id);
            //     return shiftsByEmployee;
            // } else {
            //     return null;
            // }
        }),

        unavailability: Ember.computed('employee.availability', 'displayStart', 'displayEnd', 'dotw', function () {
            var _this = this;

            var defaultAvailability = this.get('employee.availability.default');
            var exceptions = this.get('employee.availability.exceptions');
            var merged = _extends({}, defaultAvailability, exceptions);

            var unavailability = [];

            if (this.get('date')) {
                var f = merged[this.get('dotw')].filter(function (x) {
                    return x.isAvailable === false;
                });
                if (f.length > 0) {
                    f.forEach(function (x) {
                        var startTime = (0, _dateHelper.offsetTime)(_this.get('date'), x.start);
                        var endTime = (0, _dateHelper.offsetTime)(_this.get('date'), x.end);
                        var start = (0, _dateHelper.momentToSegment)(x.fromOpen ? _this.get('displayStart') : _this.get('displayStart').isBefore(startTime) ? _this.get('displayStart').clone() : startTime);
                        var end = (0, _dateHelper.momentToSegment)(x.untilClose ? _this.get('displayEnd').add(1, 'minutes') : _this.get('displayEnd').isAfter(endTime) ? endTime : _this.get('displayEnd').clone().add(15, 'minutes'));
                        console.log(startTime.format('HH:mm'), _this.get('displayStart').isBefore(startTime), start);
                        unavailability.push(_extends({}, x, { start: start, end: end }));
                    });
                }
            }
            // })

            return unavailability;
        }),

        hasExpanded: Ember.computed('expandedRow', function () {
            return this.get('expandedRow');
        }),

        isExpanded: Ember.computed('employee', 'expandedRow', function () {
            var employee = this.get('employee');
            return this.get('expandedRow') === 'employee-' + employee.id;
        }),

        // isPainting: computed('currentBrush.{startMoment,stopMoment}', function() {
        //     const currentBrush = this.get('currentBrush')
        //     return currentBrush.startMoment && !currentBrush.stopMoment
        // }),

        flags: Ember.computed('employeeShifts', 'shift', 'date', 'employeeFlags', function () {
            var daily = {
                alert: [],
                warning: [],
                violation: []
            };
            var weekly = {
                alert: [],
                warning: [],
                violation: []
            };

            var shift = this.get('shift');
            var date = this.get('date');
            var id = this.get('employee.id');
            var employeeShifts = this.get('employeeShifts');
            // const shifts = Object.keys(shiftsRaw).map((x) => shiftsRaw[x]).filter(x => (x.meta.employeeId === id));
            var employeeFlags = this.get('employeeFlags');

            if (date && shift && shift.blocks && shift.blocks.length > 0 && shift.errors) {
                daily = shift.errors.reduce(function (o, i) {
                    var severity = i.severity === -1 ? 'alert' : i.severity === 0 ? 'warning' : 'violation';

                    o[severity].push(i);

                    return o;
                }, daily);
            } else if (!date && employeeShifts) {
                Object.keys(employeeShifts).forEach(function (id) {
                    var shift = employeeShifts[id];
                    if (shift && shift.blocks && shift.blocks.length > 0 && shift.errors) {
                        shift.errors.forEach(function (i) {
                            var severity = i.severity === -1 ? 'alert' : i.severity === 0 ? 'warning' : 'violation';
                            if (daily[severity] === undefined) {
                                daily[severity] = [];
                            }

                            daily[severity].push(i);
                        });
                    }
                });
            }

            if (employeeFlags[id] && employeeFlags[id].length > 0) {
                employeeFlags[id].forEach(function (i) {
                    var severity = i.severity === -1 ? 'alert' : i.severity === 0 ? 'warning' : 'violation';
                    weekly[severity].push(i);
                });
            }

            return {
                daily: daily,
                weekly: weekly
            };
        }),

        validationErrorClass: Ember.computed('shift', function () {
            var shift = this.get('shift');
            if (shift && shift.blocks && shift.blocks.length > 0) {
                var severities = shift.errors.map(function (x) {
                    return x.severity;
                });
                var mostSevere = Math.max.apply(Math, _toConsumableArray(severities));
                switch (mostSevere) {
                    case -1:
                        return ' has-alert';
                        // return 'conflict conflict-alert';
                        break;
                    case 0:
                        return ' has-warning';
                        // return 'conflict conflict-warning';
                        break;
                    case 1:
                        return ' has-violation';
                        // return 'conflict conflict-violation';
                        break;
                }
            }
            return null;
        }),

        hideEmployeePanel: function hideEmployeePanel() {
            // The "this" context is the mouse-event component
            var redux = this.get('redux');
            if (!redux.store.getState().employeePanel.pinned) {
                redux.dispatch({
                    type: _types.EP_SET_EMPLOYEE_PANEL,
                    payload: { employee: null, displayPanel: false }
                });
            }
        },


        dotw: Ember.computed('date', function () {
            var date = this.get('date');
            if (!date) {
                return null;
            }
            return (0, _dateHelper.getDayOfClientWeek)(date) + 1;
        }),

        hourId: function hourId(hour) {
            var id = this.get('employee.id');
            return 'ee-' + id + '-hour-' + hour;
        },
        showEmployeePanel: function showEmployeePanel() {
            // The "this" context is the mouse-event component
            var redux = this.get('redux');
            var employee = this.get('employee');
            if (!redux.store.getState().employeePanel.pinned) {
                redux.dispatch({
                    type: _types.EP_SET_EMPLOYEE_PANEL,
                    payload: { employee: employee, displayPanel: true }
                });
            }
        },


        shiftBlockTimesStartLeft: Ember.computed('startLeft', function () {
            var startLeft = this.get('startLeft');
            // return startLeft - 200;
            return -225;
        }),

        shiftsByDOTW: Ember.computed('employeeShifts', function () {
            var shiftsByDOTW = {};
            var employeeShifts = this.get('employeeShifts');
            var id = this.get('employee.id');
            var shiftsRaw = this.get('shifts');
            var shifts = Object.keys(shiftsRaw).map(function (x) {
                return shiftsRaw[x];
            }).filter(function (x) {
                return x.meta.employeeId === id;
            });

            shifts.forEach(function (x) {
                shiftsByDOTW[x.meta.dotw] = x;
            });
            return shiftsByDOTW;
        }),

        shift: Ember.computed('dotw', 'shiftsByDOTW', 'activitiesById', function () {
            var shiftsByDOTW = this.get('shiftsByDOTW');
            var dotw = this.get('dotw');
            // const employeeShifts = this.get('employeeShifts');
            if (dotw) {
                // const id = this.get('employee.id');
                // const shiftsRaw = this.get('shifts');
                // const shifts = Object.keys(shiftsRaw).map((x) => shiftsRaw[x]).filter(x => (x.meta.employeeId === id));
                // const shift = shifts.find(x => (x.meta.employeeId === id && x.meta.date.isSame(date, 'day')));
                return shiftsByDOTW[dotw];
            } else {
                return null;
            }
        }),

        statistics: Ember.computed('employeeShifts', 'date', 'activitiesById', function () {
            var date = this.get('date');
            var activitiesById = this.get('activitiesById');
            var employeeShifts = this.get('employeeShifts') || {};

            if (activitiesById) {
                var weeklyHours = Object.keys(employeeShifts).map(function (x) {
                    return employeeShifts[x];
                }).reduce(function (o, i) {
                    i.blocks.forEach(function (block) {
                        var activityId = block.activityId;

                        var activity = activitiesById[activityId];
                        if (activity && !activity.isCallin) {
                            o += block.end.diff(block.start, 'hours', true);
                        }
                    });
                    return o;
                }, 0);
                var dailyHours = Object.keys(employeeShifts).map(function (x) {
                    return employeeShifts[x];
                }).filter(function (x) {
                    return x.meta.date.isSame(date, 'day');
                }).reduce(function (o, i) {
                    i.blocks.forEach(function (block) {
                        var activityId = block.activityId;

                        var activity = activitiesById[activityId];
                        if (activity && !activity.isCallin) {
                            o += block.end.diff(block.start, 'hours', true);
                        }
                    });
                    return o;
                }, 0);

                return {
                    count: Object.keys(employeeShifts).length,
                    weeklyHours: weeklyHours,
                    dailyHours: dailyHours
                };
            } else {
                return {};
            }
        }),

        // startPaint() {
        //     // console.log('startPaint')
        //     // The "this" context is the mouse-event component

        //     const state = this.get('state')
        //     const redux = this.get('redux')
        //     const date = redux.store.getState().cube.date

        //     redux.dispatch({
        //         type: types.SE_SET_STARTLEFT,
        //         payload: {mouseState: state},
        //     })

        //     // redux.dispatch({
        //     //     type: 'SET_STARTTOP',
        //     //     payload: { mouseState: state }
        //     // });

        //     redux.dispatch({
        //         type: types.SE_START_PAINTING,
        //         payload: {mouseState: state, date},
        //     })
        // },

        // stopPaint() {
        //     // console.log('stopPaint')
        //     // The "this" context is the mouse-event component

        //     const state = this.get('state')
        //     const redux = this.get('redux')
        //     const currentUser = redux.store.getState().user.currentUser
        //     const date = redux.store.getState().cube.date
        //     const currentBrush = redux.getState().scheduleEdit.currentBrush

        //     redux.dispatch({
        //         type: types.SE_STOP_PAINTING,
        //         payload: {mouseState: state, date},
        //     })

        //     if (
        //         currentBrush.currentState.clientX >
        //             currentBrush.startState.clientX ||
        //         (currentBrush.currentState.touches &&
        //             currentBrush.currentState.touches[0].clientX >
        //                 currentBrush.startState.touches[0].clientX)
        //     ) {
        //         redux.dispatch({
        //             type: types.SE_COMPILE_BRUSH,
        //             payload: {date, currentUser},
        //         })
        //     }
        //     // console.log('stopPaint done')
        // },

        wasLastExpandedRow: Ember.computed('lastExpandedRow', function () {
            var employee = this.get('employee');
            return this.get('lastExpandedRow') === 'employee-' + employee.id;
        }),

        actions: {
            displayFlags: function displayFlags(level) {
                var employee = this.get('employee');
                var key = level + '-flags' + employee.id;
                var modalService = this.get('modalService');
                modalService.open(key);
            }
        }
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});