define('matrix-frontend/transforms/isodate', ['exports', 'ember-data/transform', 'npm:moment'], function (exports, _transform, _npmMoment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _transform.default.extend({
        deserialize: function deserialize(serialized) {
            return serialized;
        },
        serialize: function serialize(deserialized) {
            return (0, _npmMoment.default)(deserialized).format('YYYY-MM-DD');
        }
    });
});