define('matrix-frontend/pods/components/employee-panel/component', ['exports', 'ember-redux', 'matrix-frontend/actions/_types', 'npm:moment', 'npm:lodash', 'matrix-frontend/services/notification-service', 'matrix-frontend/utils/state', 'matrix-frontend/utils/date-helper'], function (exports, _emberRedux, _types, _npmMoment, _npmLodash, _notificationService, _state, _dateHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var getVacationHours = function getVacationHours(employee) {
        var defaultAvailability = employee.availability.default || {};
        var exceptions = employee.availability.exceptions || {};
        var merged = _extends({}, defaultAvailability, exceptions);

        var totalHours = Object.keys(merged).reduce(function (o, i) {
            var subtotal = merged[i].reduce(function (x, y) {
                x += y.creditHours || 0;
                return x;
            }, 0);
            o += subtotal;
            return o;
        }, 0);
        return totalHours;
    };

    var stateToComputed = function stateToComputed(state) {
        return {
            employeesDB: state.employee,
            clients: state.client.clients,
            stores: state.node.stores,
            node: state.cube.node,
            weekEndDate: state.cube.weekEndDate,
            displayPanel: state.employeePanel.displayPanel,
            employeeId: state.employeePanel.employeeId,
            pinned: state.employeePanel.pinned,
            shifts: state.scheduleEdit.shifts,
            currentUser: state.user.currentUser
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {
            unpin: function unpin() {
                return dispatch({ type: _types.EP_UNPIN });
            },
            saveSkill: function saveSkill(employee, activity, value, startValue) {
                var current = parseFloat(value);
                var start = parseFloat(startValue);

                // Only save when there is a change
                if (current !== start) {
                    dispatch({
                        type: _types.EE_SAVE_SKILL,
                        payload: {
                            employee: employee,
                            activity: activity,
                            value: value
                        }
                    });

                    //     const service = new NotificationsService()
                    //     // service.info('Saving...')
                    //     dispatch({
                    //         type: types.EE_SAVE_SKILL,
                    //         payload: {
                    //             employee,
                    //             activity,
                    //             value,
                    //         },
                    //     })
                }
                // When complete...
                // service.success()
            }
        };
    };

    var Comp = Ember.Component.extend({
        modalService: Ember.inject.service(),
        redux: Ember.inject.service(),

        skillFormat: {
            mantissa: 2,
            optionalMantissa: true
        },

        currentClient: Ember.computed('clients', 'node', function () {
            var node = this.get('node');
            var clients = this.get('clients');
            return node && clients ? clients[node.clientCode] : null;
        }),

        currentStore: Ember.computed('node', 'stores', function () {
            var node = this.get('node');
            var stores = this.get('redux').store.getState().node.stores;
            if (node && stores) {
                return stores[node.clientCode + '.' + node.tag];
            } else {
                return null;
            }
        }),

        activities: Ember.computed('currentClient', function () {
            var currentClient = this.get('currentClient');
            return currentClient && currentClient.activities ? _npmLodash.default.sortBy(currentClient.activities, ['activityPriority']) : null;
        }),

        canEdit: Ember.computed('currentUser', function () {
            // return hasRole('schedule2', true) || hasRole('approver')
            return (0, _state.hasScope)('employee.store:skills.edit');
        }),

        employee: Ember.computed('employeeId', 'employeesDB', 'currentStore', 'weekEndDate', function () {
            var _this = this;

            var weekEndDate = this.weekEndDate ? this.weekEndDate.format('YYYY-MM-DD') : null;

            if (weekEndDate && this.currentStore && this.currentStore.weeks && this.currentStore.weeks[weekEndDate] && this.currentStore.weeks[weekEndDate].employees) {
                var employees = this.currentStore.weeks[weekEndDate].employees;
                var employee = employees.find(function (x) {
                    return x.id === _this.employeeId;
                });
                if (employee) {
                    employee = _extends({}, this.employeesDB[this.employeeId], employee);

                    return employee;
                }
            }
            return null;
        }),

        displayActivities: Ember.computed('activities', function () {
            return this.activities.filter(function (x) {
                return x.shouldDisplay;
            });
        }),

        days: Ember.computed('weekEndDate', function () {
            var weekEndDate = this.get('weekEndDate');
            var raw = [].concat(_toConsumableArray(Array(7).keys()));
            return raw.map(function (x) {
                return (0, _npmMoment.default)(weekEndDate).subtract(x, 'days');
            }).reverse();
        }),

        daysOfTheWeek: Ember.computed(function () {
            var raw = [].concat(_toConsumableArray(Array(7).keys()));
            return raw.map(function (x) {
                return x + 1;
            });
        }),

        skillLevels: Ember.computed('employee', function () {
            var employee = this.get('employee');
            var skills = employee.skills.reduce(function (o, i) {
                o[i.activity] = i.level;
                return o;
            }, {});
            return skills;
        }),

        loanShifts: Ember.computed('employee.id', 'currentStore', 'weekEndDate', function () {
            var _this2 = this;

            if (this.currentStore && this.currentStore.weeks && this.weekEndDate && this.employee && this.employee.id && this.currentStore.weeks[this.weekEndDate.format('YYYY-MM-DD')] && this.currentStore.weeks[this.weekEndDate.format('YYYY-MM-DD')].employees && this.currentStore.weeks[this.weekEndDate.format('YYYY-MM-DD')].employees) {
                var employee = this.currentStore.weeks[this.weekEndDate.format('YYYY-MM-DD')].employees.find(function (x) {
                    return x.id === _this2.employee.id;
                });
                return employee && employee.loanShifts && employee.loanShifts;
            }
            return null;
        }),

        employeeScheduleInfo: Ember.computed('employee', 'shifts', 'loanShifts', function () {
            var combinedAvailability = this.employee && this.employee.availability ? _extends({}, this.employee.availability.default, this.employee.availability.exceptions) : {};
            var id = this.get('employee.id');
            var days = this.get('days');
            var loanShifts = this.loanShifts;
            var shiftsRaw = this.shifts;
            var shifts = Object.keys(shiftsRaw).map(function (x) {
                return shiftsRaw[x];
            }).filter(function (x) {
                return x.meta.employeeId === id;
            });

            var reduced = shifts.reduce(function (a, v) {
                var dotw = v.meta.dotw;
                a[dotw] = v;
                return a;
            }, {});

            return days.map(function (day) {
                var dotw = (0, _dateHelper.getDayOfClientWeek)(day) + 1;
                var loanShift = loanShifts ? loanShifts[dotw] : null;
                var shift = reduced[dotw] || loanShift || null;
                var availability = combinedAvailability[dotw] || null;
                var info = {
                    availability: availability,
                    shift: shift
                };
                return info;
            });
        }),

        statistics: Ember.computed('employeeScheduleInfo', function () {
            var date = this.get('date');
            var employeeShifts = this.get('employeeScheduleInfo') || [];
            var weeklyHours = employeeShifts.reduce(function (o, i) {
                if (i && i.shift && i.shift.blocks && i.shift.blocks.length > 0) {
                    i.shift.blocks.forEach(function (block) {
                        o += block.end.diff(block.start, 'hours', true);
                    });
                }
                return o;
            }, 0);

            var vacationHours = getVacationHours(this.employee);

            return {
                count: employeeShifts.filter(function (x) {
                    return x.shift;
                }).length,
                weeklyHours: weeklyHours,
                vacationHours: vacationHours
            };
        }),

        actions: {
            displayModal: function displayModal(dotw) {
                this.get('redux').dispatch({
                    type: _types.EP_SET,
                    payload: {
                        dotw: dotw
                    }
                });
                this.get('modalService').open('availability-modal');
            }
        }
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});