define('matrix-frontend/pods/components/tps-report-budget-edit/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        isChangedHours: false,
        isChangedUnits: false,

        budgetUpdated: Ember.computed('isChangedHours', 'isChangedUnits', function () {
            var isChangedHours = this.get('isChangedHours');
            var isChangedUnits = this.get('isChangedUnits');

            if (isChangedHours === true || isChangedUnits === true) {
                return true;
            } else {
                return false;
            }
        }),

        hoursLabel: Ember.computed('customHoursLabel', function () {
            var customHoursLabel = this.get('customHoursLabel');

            if (customHoursLabel) {
                return customHoursLabel;
            }

            return 'Hours';
        }),

        isShowModal: Ember.computed('day', 'editModal', function () {
            var day = this.get('day');
            var editModal = this.get('editModal');
            return day === editModal;
        }),

        targetId: Ember.computed('activityName', 'day', function () {
            var activityName = this.get('activityName');
            activityName = activityName.replace(/\s/g, '_');
            var day = this.get('day');
            return '#' + activityName + '_' + day;
        }),

        unitsLabel: Ember.computed('customUnitsLabel', function () {
            var customUnitsLabel = this.get('budget.task.customUnitsLabel');
            if (customUnitsLabel) {
                return customUnitsLabel;
            }
            return 'Units';
        }),

        resetIsChanged: function resetIsChanged() {
            this.set('isChangedHours', false);
            this.set('isChangedUnits', false);
            this.set('isRowChange', false);
        },


        actions: {
            doneClicked: function doneClicked(closeModal) {
                var isChanged = this.get('budgetUpdated');
                this.resetIsChanged();

                closeModal(isChanged);
            },
            onChangeHours: function onChangeHours() {
                this.set('isChangedHours', true);
                this.set('isRowChange', true);
            },
            onChangeUnits: function onChangeUnits(budget) {
                //const task = budget.get('task');
                var laborStandard = budget.get('currentLaborStandard');
                var units = Number(budget.get('scheduleunits'));

                if (!isNaN(units)) {
                    var hours = Math.round(units * laborStandard * 100) / 100;
                    budget.set('schedulehours', hours);
                    budget.set('scheduleunits', units);
                    this.set('budget', budget);
                    this.set('isChangedUnits', true);
                    this.set('isRowChange', true);
                }
            }
        }
    });
});