define('matrix-frontend/utils/socket', ['exports', 'matrix-frontend/config/environment', 'npm:reconnecting-websocket'], function (exports, _environment, _npmReconnectingWebsocket) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.make_connection = exports.make_feed = undefined;
    var make_feed = exports.make_feed = function make_feed(node, weekEndDate) {
        var tag = node.tag.match(/^null\..+/) ? 'corp' : node.tag;
        weekEndDate = weekEndDate.format('YYYY-MM-DD');
        var feed = node.clientCode + '.' + tag + '|' + weekEndDate;
        return feed;
    };

    var make_connection = exports.make_connection = function make_connection(token, username) {
        var clientCode = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';

        var url = _environment.default.socket_gateway_protocol + '://' + _environment.default.socket_gateway_host.replace('${host}', window.location.host) + '/v2/feed/' + clientCode + '.' + username + '?access_token=' + token;
        console.debug(url);
        var socket = new _npmReconnectingWebsocket.default(url);

        // const socket = new WebSocket(url)
        return socket;
    };
});