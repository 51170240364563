define('matrix-frontend/middleware/employee-panel', ['exports', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/interactions'], function (exports, _types, _interactions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var beforeListeners = {};
    var afterListeners = {};

    beforeListeners[_types.EP_PIN] = function (store, payload) {
        var employee = payload.employee;
        var _store$getState$emplo = store.getState().employeePanel,
            currentEmployee = _store$getState$emplo.employee,
            pinned = _store$getState$emplo.pinned;


        if (pinned && currentEmployee && currentEmployee.id === employee.id) {
            store.dispatch({
                type: _types.EP_UNPIN
            });

            return false;
        }
    };

    // beforeListeners[types.EP_SAVE_SKILL] = (store, payload) => {
    //     const {employeeCode, storeCode, activityCode, value} = payload
    //     setStore(store)

    //     send({
    //         header: 'do',
    //         body: {
    //             service: 'store',
    //             namespace: ['employee', 'details'],
    //             operation: 3,
    //             include_channel: 2,
    //             extra: [employeeCode],
    //             payload: {
    //                 skills: [
    //                     {
    //                         activity_code: activityCode,
    //                         level: value,
    //                     },
    //                 ],
    //             },
    //             response_type: 'SUCCESS',
    //         },
    //     })
    //     return false
    // }

    exports.default = function (store) {
        return function (next) {
            return function (action) {
                var type = action.type,
                    payload = action.payload;

                // Before listeners

                if (beforeListeners[type]) {
                    var ret = beforeListeners[type](store, payload);
                    if (ret !== undefined) {
                        if (ret === false) {
                            return;
                        } else {
                            action.payload = ret;
                        }
                    }
                }

                next(action);

                // After listeners
                if (afterListeners[type]) {
                    afterListeners[type](store, payload);
                }
            };
        };
    };
});