define('matrix-frontend/pods/components/schedule-page-section-head/component', ['exports', 'matrix-frontend/actions/_types', 'ember-redux', 'matrix-frontend/utils/date-helper', 'matrix-frontend/utils/state'], function (exports, _types, _emberRedux, _dateHelper, _state) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var stateToComputed = function stateToComputed(state) {
        return {
            node: state.cube.node,
            stores: state.node.stores,
            displayStart: state.scheduleEdit.displayStart,
            displayEnd: state.scheduleEdit.displayEnd,
            closedAllDay: state.scheduleEdit.closedAllDay,
            date: state.cube.date,
            // atRiskData: state.scheduleEdit.atRiskData,
            shifts: state.scheduleEdit.shifts,
            showEmployeeFilter: state.scheduleEdit.showEmployeeFilter,
            employeeFilters: state.scheduleEdit.employeeFilters,
            // noSchedule: state.scheduleEdit.noSchedule,
            weekEndDate: state.cube.weekEndDate
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {
            goToDay: function goToDay(dotw) {
                return dispatch({ type: _types.CUBE_GO_TO_DAY, payload: { dotw: dotw } });
            },
            toggleEmployeeFilter: function toggleEmployeeFilter(_) {
                return dispatch({ type: _types.SE_TOGGLE_EMPLOYEE_FILTER });
            },
            setEmployeeFilterPosition: function setEmployeeFilterPosition(filterType, value) {
                return dispatch({
                    type: _types.SE_SET_EMPLOYEE_FILTER,
                    payload: {
                        filterType: filterType,
                        value: value
                    }
                });
            }
        };
    };

    var Comp = Ember.Component.extend({
        modalService: Ember.inject.service(),
        isLoadingAppointment: false,
        currentStore: Ember.computed('node', 'stores', function () {
            var node = this.get('node');
            var stores = this.get('redux').store.getState().node.stores;
            if (node && stores) {
                return stores[node.clientCode + '.' + node.tag];
            } else {
                return null;
            }
        }),

        dotw: Ember.computed('date', function () {
            var date = this.get('date');
            if (!date) {
                return null;
            }
            return (0, _dateHelper.getDayOfClientWeek)(date) + 1;
        }),

        days: Ember.computed('displayStart', 'displayEnd', function () {
            var displayStart = this.get('displayStart');
            var displayEnd = this.get('displayEnd');

            if (!displayStart || !displayEnd) {
                return null;
            }

            var daysToSpan = displayEnd.diff(displayStart, 'days') || 7;

            var raw = [].concat(_toConsumableArray(Array(daysToSpan).keys()));
            return raw.map(function (x) {
                var t = displayStart.clone().add(x, 'days');
                return {
                    moment: t,
                    dotw: x + 1
                };
            });
        }),

        hours: Ember.computed('displayStart', 'displayEnd', function () {
            var displayStart = this.get('displayStart');
            var displayEnd = this.get('displayEnd');
            var hoursToSpan = displayEnd.diff(displayStart, 'hours') + 1;
            var raw = [].concat(_toConsumableArray(Array(hoursToSpan).keys()));
            return raw.map(function (x) {
                var t = displayStart.clone().add(x, 'hours');
                return {
                    hour: t.format('H'),
                    displayHour: t.format('h'),
                    ampm: t.format('a'),
                    day: t.format('dd'),
                    dotw: (0, _dateHelper.getDayOfClientWeek)(t) + 1
                };
            });
        }),

        storeIsOpen: Ember.computed('currentStore', 'weekEndDate', function () {
            var currentStore = this.get('currentStore');
            if (this.weekEndDate && currentStore) {
                var weekEndDate = this.get('weekEndDate').format('YYYY-MM-DD');

                var storeIsOpen = [].concat(_toConsumableArray(Array(7).keys())).reduce(function (o, i) {
                    if (currentStore && currentStore.weeks && currentStore.weeks[weekEndDate] && currentStore.weeks[weekEndDate].hours) {
                        var hours = currentStore.weeks[weekEndDate].hours[i + 1];
                        o[i + 1] = hours.closedAllDay !== 'Y';
                    } else {
                        o[i + 1] = null;
                    }
                    return o;
                }, {});

                return storeIsOpen;
            }
            return null;
        }),

        showAppointmentButton: Ember.computed('currentClient.options', function () {
            return (0, _state.getClientOption)('appointments_schedule_button', 'bool');
        }),

        salesAtRiskData: Ember.computed('atRiskData', 'currentStore', 'dotw', 'date', 'noSchedule', function () {
            var atRiskData = this.get('atRiskData');
            var date = this.get('date');

            if (atRiskData && !this.noSchedule) {
                var values = atRiskData.values;

                if (date) {
                    var dotw = (0, _dateHelper.getDayOfClientWeek)(date) + 1;
                    // console.log(dotw, atRiskData[dotw])
                    return values[dotw];
                } else {
                    var data = {};
                    Object.keys(values).forEach(function (x) {
                        if (data[x] === undefined) {
                            data[x] = {
                                salesTarget: 0,
                                optimalLabor: 0,
                                underscheduledHours: 0,
                                underscheduledPercent: 0,
                                atRisk: 0
                            };
                        }
                        Object.keys(values[x]).forEach(function (hours) {
                            Object.keys(values[x][hours]).forEach(function (key) {
                                data[x][key] += values[x][hours][key];
                            });
                        });
                    });
                    return data;
                }
            }

            return null;
        }),
        actions: {
            openAppointmentModal: function openAppointmentModal() {
                this.modalService.open('appointments-modal');
            }
        }
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});