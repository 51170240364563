define('matrix-frontend/pods/components/contact-info/component', ['exports', 'npm:underscore'], function (exports, _npmUnderscore) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        flashMessages: Ember.inject.service(),
        permissionService: Ember.inject.service(),
        formUpdated: false,

        countryCodes: Ember.computed(function () {
            return _npmUnderscore.default.uniq(_npmUnderscore.default.map(this.get('statecodes'), function (item) {
                return item.countryCode.code;
            }));
        }),

        stateCodes: Ember.computed('statecodes', 'contactInfo.country', function () {
            var country = this.get('contactInfo.country');

            return _npmUnderscore.default.map(_npmUnderscore.default.filter(this.get('statecodes'), function (item) {
                return item.countryCode.code === country;
            }, country), function (item) {
                return item.code;
            });
        }),

        actions: {
            cancel: function cancel() {
                this.sendAction('discardAction', this.get('contactInfo')); // eslint-disable-line ember/closure-actions
            },
            filterStates: function filterStates(country) {
                if (country !== this.get('contactInfo.country')) {
                    this.set('contactInfo.state', '');
                }
                this.set('contactInfo.country', country);

                this.set('formUpdated', true);
            },
            save: function save() {
                this.sendAction('saveContactInfo', this.get('contactInfo')); // eslint-disable-line ember/closure-actions
            },
            setFormUpdated: function setFormUpdated() {
                if (this.get('contactInfo.validations.isValid')) {
                    this.set('formUpdated', true);
                }
            },
            updateState: function updateState(state) {
                this.set('contactInfo.state', state);
                this.set('formUpdated', true);
            }
        }
    });
});