define('matrix-frontend/components/schedule-status-modal-content', ['exports', 'react', 'ember-react-components', 'matrix-frontend/constants', 'matrix-frontend/utils/format-helpers', 'matrix-frontend/utils/state'], function (exports, _react, _emberReactComponents, _constants, _formatHelpers, _state) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var STATUSES = [{ key: 'hidden', text: 'Hidden' }, { key: 'executive', text: 'Executive' }, { key: 'readonly', text: 'Read-only' }, { key: 'preliminary', text: 'Preliminary' }, { key: 'active', text: 'Active' }];

    var KEYED_STATUSES = {
        hidden: _constants.ScheduleStatus.HIDDEN,
        executive: _constants.ScheduleStatus.EXECUTIVE,
        readonly: _constants.ScheduleStatus.READONLY,
        preliminary: _constants.ScheduleStatus.PRELIMINARY,
        active: _constants.ScheduleStatus.ACTIVE
    };

    var infoForStatus = function infoForStatus(status) {
        var info = {};
        if (status === _constants.ScheduleStatus.HIDDEN) {
            info.text = 'Hidden';
            info.classes = 'dark white-text';
        } else if (status === _constants.ScheduleStatus.EXECUTIVE) {
            info.text = 'Executive';
            info.classes = 'blue white-text';
        } else if (status === _constants.ScheduleStatus.READONLY) {
            info.text = 'Read-only';
            info.classes = 'orange white-text';
        } else if (status === _constants.ScheduleStatus.PRELIMINARY) {
            info.text = 'Preliminary';
            info.classes = 'yellow white-text';
        } else if (status === _constants.ScheduleStatus.ACTIVE) {
            info.text = 'Active';
            info.classes = 'green white-text';
        } else if (status === _constants.ScheduleStatus.UNKNOWN) {
            return null;
        }
        return info;
    };

    var MIXED_STATUSES = [{ key: 'hidden', text: 'Hidden', colorClass: 'dark', scope: 'hidden' }, { key: 'executive', text: 'Executive', colorClass: 'blue', scope: 'executive' }, { key: 'readonly', text: 'Read-only', colorClass: 'orange', scope: 'readonly' }, { key: 'preliminary', text: 'Preliminary', colorClass: 'yellow', scope: 'preliminary' }, { key: 'activeNotStarted', text: 'Active - Not Started', colorClass: 'gray-lighten-3', scope: 'active' }, { key: 'activeInProgress', text: 'Active - In Progress', colorClass: 'purple', scope: 'active' }, { key: 'activePublished', text: 'Active - Published', colorClass: 'green', scope: 'active' }];

    var comp = function comp(props) {
        var hierarchy = props.hierarchy,
            total = props.total,
            status = props.status,
            newStatus = props.newStatus,
            setNewStatus = props.setNewStatus,
            showSave = props.showSave,
            canEdit = props.canEdit;


        if (!hierarchy) return null;

        var onTargetChange = function onTargetChange(e) {
            return setNewStatus(KEYED_STATUSES[e.target.value]);
        };

        (0, _react.useEffect)(function () {
            showSave(newStatus != status && canEdit);
        }, [newStatus]);

        var content = null;
        if (hierarchy.isStore) {
            var info = infoForStatus(status);
            content = _react.default.createElement(
                'div',
                { className: 'row', style: { justifyContent: 'center' } },
                _react.default.createElement(
                    'div',
                    { className: 'visibility-status ' + info.classes },
                    info.text
                )
            );
        } else {
            var statusRows = MIXED_STATUSES.filter(function (s) {
                return (0, _state.hasScope)('schedule.store:' + s.scope + '.view');
            }).map(function (s) {
                var count = total[s.key];
                return _react.default.createElement(
                    'div',
                    { key: s.key, className: 'flex', style: {
                            padding: '4px 0px',
                            alignItems: 'center'
                        } },
                    _react.default.createElement('div', { className: '' + s.colorClass, style: {
                            width: '20px',
                            height: '20px',
                            border: '1px solid rgba(0,0,0,0.1)'
                        } }),
                    _react.default.createElement(
                        'label',
                        { className: 'flex', style: {
                                flex: '2',
                                paddingLeft: '20px',
                                cursor: 'unset'
                            } },
                        s.text
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'flex', style: {
                                flex: '1',
                                paddingLeft: '8px',
                                justifyContent: 'flex-end'
                            } },
                        (0, _formatHelpers.numberFormat)(count)
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'flex', style: {
                                flex: '1',
                                paddingLeft: '8px',
                                justifyContent: 'flex-end'
                            } },
                        (0, _formatHelpers.percentFormat)(count / total.numStores, { decimals: 0, withSign: false })
                    )
                );
            });
            content = _react.default.createElement(
                'div',
                { className: 'flex direction-column', style: {
                        padding: '10px 2rem'
                    } },
                statusRows
            );
        }

        return _react.default.createElement(
            'div',
            { className: 'modal-form' },
            content,
            canEdit ? _react.default.createElement(
                'div',
                { className: 'row' },
                _react.default.createElement(
                    'div',
                    { className: 'field' },
                    _react.default.createElement(
                        'label',
                        null,
                        'Set status to'
                    ),
                    _react.default.createElement(
                        'select',
                        { value: newStatus && newStatus.description || "null", onChange: onTargetChange },
                        _react.default.createElement(
                            'option',
                            { hidden: true, disabled: true, value: 'null' },
                            'Select a status'
                        ),
                        STATUSES.filter(function (s) {
                            return (0, _state.hasScope)('schedule.store:' + s.key + '.view');
                        }).map(function (s) {
                            return _react.default.createElement(
                                'option',
                                { key: s.key, value: s.key },
                                s.text
                            );
                        })
                    )
                )
            ) : null
        );
    };

    exports.default = (0, _emberReactComponents.default)(comp);
});