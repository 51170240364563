define('matrix-frontend/modifiers/recognize-gesture', ['exports', 'ember-gestures/modifiers/recognize-gesture'], function (exports, _recognizeGesture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _recognizeGesture.default;
    }
  });
});