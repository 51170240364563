define('matrix-frontend/helpers/display-time', ['exports', 'npm:moment'], function (exports, _npmMoment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.displayTime = displayTime;
    function displayTime(params /*, hash*/) {
        var time = params[0];
        if (time) {
            return (0, _npmMoment.default)(time).format('h:mma');
        } else {
            return null;
        }
    }

    exports.default = Ember.Helper.helper(displayTime);
});