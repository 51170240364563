define('matrix-frontend/pods/new-reports/route', ['exports', 'matrix-frontend/routes/base-route', 'matrix-frontend/actions/_types', 'matrix-frontend/constants'], function (exports, _baseRoute, _types, _constants) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    exports.default = _baseRoute.default.extend({
        redux: Ember.inject.service(),
        queryParams: {
            hierarchyNodeId: {
                refreshModel: true
            },
            report: {
                refreshModel: true
            },
            view: {
                refreshModel: true
            },
            subview: {
                refreshModel: true
            }
        },

        beforeModel: function beforeModel(transition) {
            var _transition$queryPara = transition.queryParams,
                storeId = _transition$queryPara.storeId,
                report = _transition$queryPara.report,
                view = _transition$queryPara.view,
                subview = _transition$queryPara.subview;
            // console.info('new-reports before', transition.queryParams)


            var finalRoute = void 0;
            var finalQueryParams = {};
            var shouldReroute = false;
            var hardReroute = true;

            var reportDef = _constants.ReportTypesKeyed[report];

            // no report specified, go to default
            if (reportDef == null) {
                shouldReroute = true;
                hardReroute = false;
                finalQueryParams = _extends({}, transition.queryParams, {
                    report: 'svf',
                    view: 'detail',
                    subview: 'node'
                });
                reportDef = _constants.ReportTypesKeyed['svf'];
            }

            // go to supported view if not valid for this report
            if (!reportDef.views.includes(view)) {
                shouldReroute = true;
                finalQueryParams.view = 'detail';
            }

            if (storeId != null) {

                // reroute store to another page
                if (reportDef.storeLinkTo != null) {
                    shouldReroute = true;
                    finalRoute = reportDef.storeLinkTo;
                    finalQueryParams = {
                        hierarchyNodeId: null,
                        report: null,
                        view: null,
                        subview: null
                    };
                    this.transitionTo(finalRoute, { queryParams: finalQueryParams, replace: true });
                    return;
                } else {
                    // can only view map or detail at store level
                    if (view !== 'map' && view !== 'detail') {
                        shouldReroute = true;
                        finalQueryParams = _extends({}, transition.queryParams, finalQueryParams, {
                            view: 'detail'
                        });
                    }
                }
            }

            if (view === 'summary' && !(subview === 'store' || subview === 'node')) {
                shouldReroute = true;
                finalQueryParams.subview = 'node';
            }

            if (shouldReroute) {
                console.info(hardReroute ? 'transitionTo' : 'replaceWith');
                console.info('FROM', transition.queryParams);
                console.info('TO', finalQueryParams);
                if (hardReroute) {
                    this.transitionTo({
                        queryParams: finalQueryParams
                    });
                } else {
                    this.transitionTo({
                        queryParams: finalQueryParams,
                        replace: true
                    });
                }
            }
        },
        model: function model(params, transition) {
            var self = this;
            return this._super(params, transition).then(function () {
                // console.info('new-reports model', transition.queryParams)
                var _transition$queryPara2 = transition.queryParams,
                    report = _transition$queryPara2.report,
                    view = _transition$queryPara2.view,
                    subview = _transition$queryPara2.subview;

                self.redux.dispatch({
                    type: _types.RP_SET_REPORT,
                    payload: {
                        report: _constants.ReportTypesKeyed[report]
                    }
                });
                self.redux.dispatch({
                    type: _types.RP_SET_VIEW,
                    payload: {
                        view: view,
                        subview: subview
                    }
                });
                return transition.queryParams;
            });
        },


        resetController: function resetController(controller) {
            var params = ['hierarchyNodeId', 'storeId', 'report', 'view', 'subview'];
            params.forEach(function (param) {
                // console.info('reset', param)
                controller.set(param, null);
            });
        }
    });
});