define('matrix-frontend/adapters/v2-user', ['exports', 'ember-data', 'matrix-frontend/config/environment'], function (exports, _emberData, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTAdapter.extend({
        redux: Ember.inject.service(),
        host: _environment.default.mxl.replace('${host}', window.location.host),
        // namespace: 'v2',
        defaultSerializer: 'v2-user',
        stateService: Ember.inject.service(),

        headers: Ember.computed('session.token', function () {
            var store = this.get('redux').store;
            var token = store.getState().user.currentUser.token;

            if (token) {
                return {
                    Authorization: 'Bearer ' + token
                };
            } else {
                return {};
            }
        }),

        pathForType: function pathForType() {
            return 'user';
        },
        sortQueryParams: function sortQueryParams(params) {
            delete params.clientCode;
            delete params.username;
            return params;
        },
        buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
            var url = this._super.apply(this, arguments).split('/');

            if (query) {
                url.push(query.clientCode);
                if (query.username) {
                    url.push(query.username);
                }
            }
            url = url.join('/');
            return url;
        },
        urlForCreateRecord: function urlForCreateRecord() {
            var url = this._super.apply(this, arguments).split('/');
            url.pop();
            // const record = snapshot.record;
            var clientCode = this.get('stateService').getClient().get('code');
            url.push('user');
            url.push(clientCode);
            url = url.join('/');
            return url;
        },
        urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
            var url = this._super.apply(this, arguments).split('/');
            url.pop();
            var record = snapshot.record;
            url.push(record.get('clientCode'));
            url.push(record.get('username'));
            url = url.join('/');
            return url;
        },
        urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
            var url = this._super.apply(this, arguments).split('/');
            url.pop();
            var record = snapshot.record;
            url.push(record.get('clientCode'));
            url.push(record.get('username'));
            url = url.join('/');
            return url;
        },
        createRecord: function createRecord(store, type, snapshot) {
            var data = {};
            var serializer = store.serializerFor(type.modelName);
            var url = this.buildURL(type.modelName, null, snapshot, 'createRecord');
            if (snapshot.adapterOptions && snapshot.adapterOptions.password) {
                var password = snapshot.adapterOptions.password;
                data.password = password;
            }
            serializer.serializeIntoHash(data, type, snapshot, { includeId: true });
            return this.ajax(url, 'POST', { data: data });
        },
        updateRecord: function updateRecord(store, type, snapshot) {
            var data = {};
            var serializer = store.serializerFor(type.modelName);
            if (snapshot.adapterOptions && snapshot.adapterOptions.password) {
                var password = snapshot.adapterOptions.password;
                data.password = password;
            }
            serializer.serializeIntoHash(data, type, snapshot);
            var id = snapshot.id;
            var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
            return this.ajax(url, 'PUT', { data: data });
        }
    });
});