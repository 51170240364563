define("matrix-frontend/reducers/_optimal", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var optimal = {
        1: {
            0: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            1: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            2: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            3: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            4: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            5: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            6: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            7: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            8: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            9: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            10: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 1 },
                30: { value: 1, prev: -1, next: 0 },
                45: { value: 1, prev: 0, next: 1 }
            },
            11: {
                0: { value: 2, prev: -1, next: 0 },
                15: { value: 2, prev: 0, next: 0 },
                30: { value: 2, prev: 0, next: 0 },
                45: { value: 2, prev: 0, next: 0 }
            },
            12: {
                0: { value: 2, prev: 0, next: 0 },
                15: { value: 2, prev: 0, next: 0 },
                30: { value: 2, prev: 0, next: 0 },
                45: { value: 2, prev: 0, next: 0 }
            },
            13: {
                0: { value: 2, prev: 0, next: 0 },
                15: { value: 2, prev: 0, next: 0 },
                30: { value: 2, prev: 0, next: 0 },
                45: { value: 2, prev: 0, next: 2 }
            },
            14: {
                0: { value: 4, prev: -2, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            15: {
                0: { value: 4, prev: 0, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            16: {
                0: { value: 4, prev: 0, next: -3 },
                15: { value: 1, prev: 3, next: 0 },
                30: { value: 4, prev: -3, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            17: {
                0: { value: 4, prev: 0, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            18: {
                0: { value: 4, prev: 0, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 1 }
            },
            19: {
                0: { value: 5, prev: -1, next: 0 },
                15: { value: 5, prev: 0, next: 0 },
                30: { value: 5, prev: 0, next: 0 },
                45: { value: 5, prev: 0, next: 0 }
            },
            20: {
                0: { value: 5, prev: 0, next: 0 },
                15: { value: 5, prev: 0, next: -5 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            21: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            22: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            23: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            }
        },
        2: {
            0: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            1: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            2: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            3: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            4: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            5: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            6: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            7: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            8: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            9: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            10: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 1 },
                30: { value: 1, prev: -1, next: 0 },
                45: { value: 1, prev: 0, next: 1 }
            },
            11: {
                0: { value: 2, prev: -1, next: 0 },
                15: { value: 2, prev: 0, next: 0 },
                30: { value: 2, prev: 0, next: 0 },
                45: { value: 2, prev: 0, next: 0 }
            },
            12: {
                0: { value: 2, prev: 0, next: 0 },
                15: { value: 2, prev: 0, next: 0 },
                30: { value: 2, prev: 0, next: 0 },
                45: { value: 2, prev: 0, next: 0 }
            },
            13: {
                0: { value: 2, prev: 0, next: 0 },
                15: { value: 2, prev: 0, next: 0 },
                30: { value: 2, prev: 0, next: 0 },
                45: { value: 2, prev: 0, next: 2 }
            },
            14: {
                0: { value: 4, prev: -2, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            15: {
                0: { value: 4, prev: 0, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            16: {
                0: { value: 4, prev: 0, next: -3 },
                15: { value: 1, prev: 3, next: 0 },
                30: { value: 1, prev: 0, next: 3 },
                45: { value: 4, prev: -3, next: 0 }
            },
            17: {
                0: { value: 4, prev: 0, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            18: {
                0: { value: 4, prev: 0, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 1 }
            },
            19: {
                0: { value: 5, prev: -1, next: 0 },
                15: { value: 5, prev: 0, next: 0 },
                30: { value: 5, prev: 0, next: 0 },
                45: { value: 5, prev: 0, next: 0 }
            },
            20: {
                0: { value: 5, prev: 0, next: 0 },
                15: { value: 5, prev: 0, next: -5 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            21: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            22: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            23: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            }
        },
        3: {
            0: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            1: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            2: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            3: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            4: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            5: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            6: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            7: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            8: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            9: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            10: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 1 },
                30: { value: 1, prev: -1, next: 0 },
                45: { value: 1, prev: 0, next: 1 }
            },
            11: {
                0: { value: 2, prev: -1, next: 0 },
                15: { value: 2, prev: 0, next: 0 },
                30: { value: 2, prev: 0, next: 0 },
                45: { value: 2, prev: 0, next: 0 }
            },
            12: {
                0: { value: 2, prev: 0, next: 0 },
                15: { value: 2, prev: 0, next: 0 },
                30: { value: 2, prev: 0, next: 0 },
                45: { value: 2, prev: 0, next: 0 }
            },
            13: {
                0: { value: 2, prev: 0, next: 0 },
                15: { value: 2, prev: 0, next: 0 },
                30: { value: 2, prev: 0, next: 0 },
                45: { value: 2, prev: 0, next: 2 }
            },
            14: {
                0: { value: 4, prev: -2, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            15: {
                0: { value: 4, prev: 0, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            16: {
                0: { value: 4, prev: 0, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            17: {
                0: { value: 4, prev: 0, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            18: {
                0: { value: 4, prev: 0, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 1 }
            },
            19: {
                0: { value: 5, prev: -1, next: 0 },
                15: { value: 5, prev: 0, next: 0 },
                30: { value: 5, prev: 0, next: 0 },
                45: { value: 5, prev: 0, next: 0 }
            },
            20: {
                0: { value: 5, prev: 0, next: 0 },
                15: { value: 5, prev: 0, next: -5 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            21: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            22: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            23: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            }
        },
        4: {
            0: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            1: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            2: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            3: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            4: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            5: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            6: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            7: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            8: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            9: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            10: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 1 },
                30: { value: 1, prev: -1, next: 0 },
                45: { value: 1, prev: 0, next: 1 }
            },
            11: {
                0: { value: 2, prev: -1, next: 0 },
                15: { value: 2, prev: 0, next: 0 },
                30: { value: 2, prev: 0, next: 0 },
                45: { value: 2, prev: 0, next: 0 }
            },
            12: {
                0: { value: 2, prev: 0, next: 0 },
                15: { value: 2, prev: 0, next: 0 },
                30: { value: 2, prev: 0, next: 0 },
                45: { value: 2, prev: 0, next: 0 }
            },
            13: {
                0: { value: 2, prev: 0, next: 0 },
                15: { value: 2, prev: 0, next: 0 },
                30: { value: 2, prev: 0, next: 0 },
                45: { value: 2, prev: 0, next: 2 }
            },
            14: {
                0: { value: 4, prev: -2, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            15: {
                0: { value: 4, prev: 0, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            16: {
                0: { value: 4, prev: 0, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            17: {
                0: { value: 4, prev: 0, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            18: {
                0: { value: 4, prev: 0, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 1 }
            },
            19: {
                0: { value: 5, prev: -1, next: 0 },
                15: { value: 5, prev: 0, next: 0 },
                30: { value: 5, prev: 0, next: 0 },
                45: { value: 5, prev: 0, next: 0 }
            },
            20: {
                0: { value: 5, prev: 0, next: 0 },
                15: { value: 5, prev: 0, next: -5 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            21: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            22: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            23: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            }
        },
        5: {
            0: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            1: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            2: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            3: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            4: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            5: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            6: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            7: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            8: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            9: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            10: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 1 },
                30: { value: 1, prev: -1, next: 0 },
                45: { value: 1, prev: 0, next: 1 }
            },
            11: {
                0: { value: 2, prev: -1, next: 0 },
                15: { value: 2, prev: 0, next: 0 },
                30: { value: 2, prev: 0, next: 0 },
                45: { value: 2, prev: 0, next: 0 }
            },
            12: {
                0: { value: 2, prev: 0, next: 0 },
                15: { value: 2, prev: 0, next: 0 },
                30: { value: 2, prev: 0, next: 0 },
                45: { value: 2, prev: 0, next: 0 }
            },
            13: {
                0: { value: 2, prev: 0, next: 0 },
                15: { value: 2, prev: 0, next: 0 },
                30: { value: 2, prev: 0, next: 0 },
                45: { value: 2, prev: 0, next: 2 }
            },
            14: {
                0: { value: 4, prev: -2, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            15: {
                0: { value: 4, prev: 0, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            16: {
                0: { value: 4, prev: 0, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            17: {
                0: { value: 4, prev: 0, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            18: {
                0: { value: 4, prev: 0, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 1 }
            },
            19: {
                0: { value: 5, prev: -1, next: 0 },
                15: { value: 5, prev: 0, next: 0 },
                30: { value: 5, prev: 0, next: 0 },
                45: { value: 5, prev: 0, next: 0 }
            },
            20: {
                0: { value: 5, prev: 0, next: 0 },
                15: { value: 5, prev: 0, next: -5 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            21: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            22: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            23: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            }
        },
        6: {
            0: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            1: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            2: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            3: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            4: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            5: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            6: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            7: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            8: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            9: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            10: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 1 },
                30: { value: 1, prev: -1, next: 0 },
                45: { value: 1, prev: 0, next: 1 }
            },
            11: {
                0: { value: 2, prev: -1, next: 0 },
                15: { value: 2, prev: 0, next: 0 },
                30: { value: 2, prev: 0, next: 0 },
                45: { value: 2, prev: 0, next: 0 }
            },
            12: {
                0: { value: 2, prev: 0, next: 0 },
                15: { value: 2, prev: 0, next: 0 },
                30: { value: 2, prev: 0, next: 0 },
                45: { value: 2, prev: 0, next: 0 }
            },
            13: {
                0: { value: 2, prev: 0, next: 0 },
                15: { value: 2, prev: 0, next: 0 },
                30: { value: 2, prev: 0, next: 0 },
                45: { value: 2, prev: 0, next: 2 }
            },
            14: {
                0: { value: 4, prev: -2, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            15: {
                0: { value: 4, prev: 0, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            16: {
                0: { value: 4, prev: 0, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            17: {
                0: { value: 4, prev: 0, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            18: {
                0: { value: 4, prev: 0, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 1 }
            },
            19: {
                0: { value: 5, prev: -1, next: 0 },
                15: { value: 5, prev: 0, next: 0 },
                30: { value: 5, prev: 0, next: 0 },
                45: { value: 5, prev: 0, next: 0 }
            },
            20: {
                0: { value: 5, prev: 0, next: 0 },
                15: { value: 5, prev: 0, next: -5 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            21: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            22: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            23: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            }
        },
        7: {
            0: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            1: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            2: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            3: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            4: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            5: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            6: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            7: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            8: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            9: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            10: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 1 },
                30: { value: 1, prev: -1, next: 0 },
                45: { value: 1, prev: 0, next: 1 }
            },
            11: {
                0: { value: 3, prev: -2, next: 0 },
                15: { value: 3, prev: 0, next: 0 },
                30: { value: 3, prev: 0, next: 0 },
                45: { value: 3, prev: 0, next: 0 }
            },
            12: {
                0: { value: 3, prev: 0, next: 0 },
                15: { value: 3, prev: 0, next: 0 },
                30: { value: 3, prev: 0, next: 0 },
                45: { value: 3, prev: 0, next: 0 }
            },
            13: {
                0: { value: 3, prev: 0, next: 0 },
                15: { value: 3, prev: 0, next: 0 },
                30: { value: 3, prev: 0, next: 0 },
                45: { value: 3, prev: 0, next: 2 }
            },
            14: {
                0: { value: 4, prev: -1, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            15: {
                0: { value: 4, prev: 0, next: 0 },
                15: { value: 4, prev: 0, next: 0 },
                30: { value: 4, prev: 0, next: 0 },
                45: { value: 4, prev: 0, next: 0 }
            },
            16: {
                0: { value: 4, prev: 0, next: 2 },
                15: { value: 6, prev: -2, next: 0 },
                30: { value: 6, prev: 0, next: 0 },
                45: { value: 6, prev: 0, next: 0 }
            },
            17: {
                0: { value: 6, prev: 0, next: 1 },
                15: { value: 7, prev: -1, next: 0 },
                30: { value: 7, prev: 0, next: 0 },
                45: { value: 7, prev: 0, next: 0 }
            },
            18: {
                0: { value: 7, prev: 0, next: 0 },
                15: { value: 7, prev: 0, next: 0 },
                30: { value: 7, prev: 0, next: 0 },
                45: { value: 7, prev: 0, next: -2 }
            },
            19: {
                0: { value: 5, prev: 2, next: 0 },
                15: { value: 5, prev: 0, next: 0 },
                30: { value: 5, prev: 0, next: 0 },
                45: { value: 5, prev: 0, next: 0 }
            },
            20: {
                0: { value: 5, prev: 0, next: 0 },
                15: { value: 5, prev: 0, next: -5 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            21: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            22: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            },
            23: {
                0: { value: 0, prev: 0, next: 0 },
                15: { value: 0, prev: 0, next: 0 },
                30: { value: 0, prev: 0, next: 0 },
                45: { value: 0, prev: 0, next: 0 }
            }
        }
    };

    exports.default = optimal;
});