define('matrix-frontend/helpers/moment-compare', ['exports', 'npm:moment'], function (exports, _npmMoment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.momentCompare = momentCompare;

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    function _toArray(arr) {
        return Array.isArray(arr) ? arr : Array.from(arr);
    }

    function momentCompare(params, hash) {
        var _params = _toArray(params),
            operator = _params[0],
            date = _params[1],
            comparison = _params[2],
            remainder = _params.slice(3);

        var comp = comparison ? comparison : (0, _npmMoment.default)();

        if (operator === 'isBefore') {
            return date.isBefore.apply(date, [comp].concat(_toConsumableArray(remainder)));
        } else if (operator === 'isSameOrBefore') {
            return date.isSameOrBefore.apply(date, [comp].concat(_toConsumableArray(remainder)));
        } else if (operator === 'isAfter') {
            return date.isAfter.apply(date, [comp].concat(_toConsumableArray(remainder)));
        } else if (operator === 'isSameOrAfter') {
            return date.isSameOrAfter.apply(date, [comp].concat(_toConsumableArray(remainder)));
        }
        return false;
    }

    exports.default = Ember.Helper.helper(momentCompare);
});