define('matrix-frontend/utils/analytics-utils', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.getNormalizedURL = getNormalizedURL;
    exports.trackPageView = trackPageView;
    exports.pushToGTM = pushToGTM;
    exports.userSnapshot = userSnapshot;
    function getNormalizedURL(router) {
        var normalizedURL = router.currentURL;
        normalizedURL = normalizedURL.split('?')[0];

        normalizedURL = normalizedURL.split('#')[0];

        if (normalizedURL.charAt(0) !== '/') {
            normalizedURL = '/' + normalizedURL;
        }

        normalizedURL = normalizedURL.toLowerCase();

        normalizedURL = normalizedURL.replace(/^\/clients\/\d+\//, "/");

        return normalizedURL;
    }

    function trackPageView(url) {
        if (typeof window.gtag !== 'undefined' && typeof window.gtag === 'function') {
            gtag('config', 'G-3SRCQ87V4F', {
                'page_path': url
            });
        } else {
            console.warn('gtag is either not initialized or not functioning correctly. Trying to send pageview: ' + url);
        }
    }

    function pushToGTM(path) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'pageLoad',
            'page_path': path
        });
    }

    function userSnapshot(id, previousEventId, path, dateTime, client, store, userHierarchy) {
        console.warn('Pushing ' + path + ' from ' + userHierarchy + ' at ' + client + ' store "' + store + '" at ' + dateTime + ' to dataLayer. Event ID ' + id + ', previous event ID ' + previousEventId + '.');
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'user_snap_shot',
            'event_id': id,
            'previous_event_id': previousEventId,
            'real_page_path': path,
            'date_time': dateTime,
            'user_client': client,
            'user_store': store,
            'user_hierarchy': userHierarchy
        });
        console.warn(window.dataLayer);
    }
});