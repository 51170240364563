define('matrix-frontend/services/ajax-service', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    exports.default = Ember.Service.extend({
        redux: Ember.inject.service(),

        dataType: '',
        headers: {}, // eslint-disable-line ember/avoid-leaking-state-in-ember-objects

        init: function init() {
            var redux = this.get('redux');
            var currentUser = redux.store.getState().user.currentUser;

            var access_token = currentUser ? currentUser.token : '';

            this._super.apply(this, arguments);
            this.set('dataType', 'json');
            this.set('headers', {
                'Access-Token': this.get('session.token'),
                Authorization: 'Bearer ' + access_token
            });
        },
        ajaxGet: function ajaxGet(url) {
            var ajaxOptions = _extends({}, this.get('ajaxOptions'), { method: 'GET' });
            delete ajaxOptions['dataType'];
            return Ember.$.ajax(url, ajaxOptions);
        },


        // ajaxPost(url, data) {
        //   let ajaxOptions = this.get('ajaxOptions');
        //   ajaxOptions = {
        //     ...ajaxOptions,
        //     contentType : 'application/json',
        //     method: 'POST',
        //     data,
        //   };
        //   console.log(ajaxOptions)
        //   return $.post(url, ajaxOptions);
        //   // return $.post(url, {
        //   //     contentType: 'application/json; charset=utf-8',
        //   //      dataType: 'json',
        //   // data: JSON.stringify(data)
        //   // });
        // },

        ajaxOptions: Ember.computed('dataType', 'headers', function () {
            var dataType = this.get('dataType');
            var headers = this.get('headers');
            return {
                dataType: dataType,
                headers: headers
            };
        })
    });
});