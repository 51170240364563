define('matrix-frontend/pods/users/index/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        application: Ember.inject.controller.controller(),
        queryParams: ['hierarchyNodeId', 'storeId', 'sort', 'asc'],
        storeId: '',
        hierarchyNodeId: '',
        sort: 'businessUnit.name',
        asc: true,

        usernameSortAscending: Ember.computed('sort', 'asc', function () {
            return this.get('sort') === 'name' ? this.get('asc') : true;
        }),

        hierarchySortAscending: Ember.computed('sort', 'asc', function () {
            return this.get('sort') === 'businessUnit.name' ? this.get('asc') : true;
        }),

        lastLoginSortAscending: Ember.computed('sort', 'asc', function () {
            return this.get('sort') === 'lastSuccessfulLogin' ? this.get('asc') : true;
        }),

        usernameSort: Ember.computed('sort', function () {
            return this.get('sort') === 'name';
        }),

        hierarchySort: Ember.computed('sort', function () {
            return this.get('sort') === 'businessUnit.name';
        }),

        lastLoginSort: Ember.computed('sort', function () {
            return this.get('sort') === 'lastSuccessfulLogin';
        }),

        sortProperties: Ember.computed('sort', 'asc', function () {
            var sort = this.get('sort');
            var asc = this.get('asc') ? 'asc' : 'desc';
            return [sort + ':' + asc];
        }),

        sortedList: Ember.computed.sort('model', 'sortProperties'),

        businessUnit: Ember.computed('storeId', 'hierarchyNodeId', function () {
            if (this.get('storeId')) {
                return this.get('application.store');
            } else {
                return this.get('application.hierarchyNode');
            }
        }),

        actions: {
            selectBusinessUnit: function selectBusinessUnit(id, type) {
                if (type === 'store') {
                    this.set('storeId', id);
                    this.set('hierarchyNodeId', '');
                } else {
                    this.set('storeId', '');
                    this.set('hierarchyNodeId', id);
                }
            },

            updateSort: function updateSort(property) {
                if (property === this.get('sort')) {
                    this.toggleProperty('asc');
                } else {
                    this.set('sort', property);
                }
            }
        }
    });
});