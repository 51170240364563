define('matrix-frontend/utils/holidays', ['exports', 'npm:moment'], function (exports, _npmMoment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.getHolidays = undefined;

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    var Holiday = function () {
        function Holiday() {
            var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
                id = _ref.id,
                name = _ref.name,
                notes = _ref.notes,
                month = _ref.month,
                date = _ref.date,
                weekNumFromStart = _ref.weekNumFromStart,
                weekNumFromEnd = _ref.weekNumFromEnd,
                weekDay = _ref.weekDay,
                override = _ref.override,
                _ref$offset = _ref.offset,
                offset = _ref$offset === undefined ? 0 : _ref$offset;

            _classCallCheck(this, Holiday);

            Object.assign(this, {
                id: id,
                name: name,
                notes: notes,
                month: month,
                date: date,
                weekNumFromStart: weekNumFromStart,
                weekNumFromEnd: weekNumFromEnd,
                weekDay: weekDay,
                override: override,
                offset: offset
            });
            Object.freeze(this);
        }

        _createClass(Holiday, [{
            key: 'toString',
            value: function toString() {
                return this.name;
            }
        }, {
            key: 'match',
            value: function match(mmt) {
                return this.override ? this.override(mmt) : this.isStatic ? this._matchStatic(mmt) : this.isWeekBased ? this._matchWeekBased(mmt) : false;
            }
        }, {
            key: '_matchStatic',
            value: function _matchStatic(mmt) {
                return mmt.month() + 1 === this.month && mmt.date() === this.date;
            }
        }, {
            key: '_matchWeekBased',
            value: function _matchWeekBased(mmt) {
                var m = mmt.clone().subtract(this.offset, 'days');
                var weekNum = this.weekNumFromStart ? Math.floor((m.date() - 1) / 7) + 1 : Math.floor((m.clone().endOf('month').date() - m.date()) / 7 + 1);
                var control = this.weekNumFromStart ? this.weekNumFromStart : this.weekNumFromEnd;

                // TODO:
                // - Verify DOTW logic
                var dotw = Number(m.format('d')) + 1;
                return mmt.month() + 1 === this.month && weekNum === control && dotw === this.weekDay;
            }
        }, {
            key: 'isStatic',
            get: function get() {
                return !!this.month && !!this.date && !this.override;
            }
        }, {
            key: 'isWeekBased',
            get: function get() {
                return (!!this.weekNumFromStart || !!this.weekNumFromEnd) && !!this.weekDay;
            }
        }]);

        return Holiday;
    }();

    var holidays = [new Holiday({
        id: 'newyearsday',
        name: "New Year's Day",
        month: 1,
        date: 1
    }), new Holiday({
        id: 'valentinesday',
        name: "Valentine's Day",
        month: 2,
        date: 14
    }), new Holiday({
        id: 'stpatricksday',
        name: "St. Patrick's Day",
        month: 3,
        date: 17
    }), new Holiday({
        id: 'independenceday',
        name: 'Independence Day',
        month: 7,
        date: 4
    }), new Holiday({
        id: 'halloween',
        name: 'Halloween',
        month: 10,
        date: 31
    }), new Holiday({
        id: 'christmaseve',
        name: 'Christmas Eve',
        month: 12,
        date: 24
    }), new Holiday({
        id: 'christmas',
        name: 'Christmas',
        month: 12,
        date: 25
    }), new Holiday({
        id: 'newyearseve',
        name: "New Year's Eve",
        month: 12,
        date: 31
    }), new Holiday({
        id: 'mlkday',
        name: 'MLK Day',
        month: 1,
        weekNumFromStart: 3,
        weekDay: 2
    }), new Holiday({
        id: 'superbowlsunday',
        name: 'Super Bowl Sunday',
        /* month: 2,
        weekNumFromStart: 1,
        weekDay: 1, */
        override: function override(mmt) {
            var superBowlInMomentYear = getSuperBowlSunday(mmt.year());
            return mmt.isSame(superBowlInMomentYear, 'day');
        }
    }), new Holiday({
        id: 'presidentsday',
        name: "Presidents' Day",
        month: 2,
        weekNumFromStart: 3,
        weekDay: 2
    }), new Holiday({
        id: 'dstbegins',
        name: 'DST begins',
        month: 3,
        weekNumFromStart: 2,
        weekDay: 1
    }), new Holiday({
        id: 'mothersday',
        name: "Mother's Day",
        month: 5,
        weekNumFromStart: 2,
        weekDay: 1
    }), new Holiday({
        id: 'fathersday',
        name: "Father's Day",
        month: 6,
        weekNumFromStart: 3,
        weekDay: 1
    }), new Holiday({
        id: 'laborday',
        name: 'Labor Day',
        month: 9,
        weekNumFromStart: 1,
        weekDay: 2
    }), new Holiday({
        id: 'dstends',
        name: 'DST ends',
        month: 11,
        weekNumFromStart: 1,
        weekDay: 1
    }), new Holiday({
        id: 'thanksgiving',
        name: 'Thanksgiving',
        month: 11,
        weekNumFromStart: 4,
        weekDay: 5
    }), new Holiday({
        id: 'blackfriday',
        name: 'Black Friday',
        month: 11,
        weekNumFromStart: 4,
        weekDay: 5,
        offset: 1
    }), new Holiday({
        id: 'memorialday',
        name: 'Memorial Day',
        month: 5,
        weekNumFromEnd: 1,
        weekDay: 2
    }), new Holiday({
        id: 'inaugurationday',
        name: 'Inauguration Day',
        month: 1,
        date: 20,
        override: function override(mmt) {
            return this._matchStatic(mmt) && (mmt.year() - 1937) % 4 === 0;
        }
    }), new Holiday({
        id: 'electionday',
        name: 'Election Day',
        month: 11,
        override: function override(mmt) {
            var dotw = Number(mmt.format('d')) + 1;
            return mmt.month() + 1 === this.month && mmt.date() >= 2 && mmt.date() < 9 && dotw === 3;
        }
    }), new Holiday({
        id: 'easter',
        name: 'Easter',
        override: function override(mmt) {
            var easterInMomentYear = getEaster(mmt.year());
            return mmt.isSame(easterInMomentYear, 'day');
        }
    })];

    var getSuperBowlSunday = function getSuperBowlSunday(year) {
        var day = 15;
        var month = 1;
        if (year === 2015) {
            day = 1;
            month = 2;
        } else if (year === 2016) {
            day = 7;
            month = 2;
        } else if (year === 2017) {
            day = 5;
            month = 2;
        } else if (year === 2018) {
            day = 4;
            month = 2;
        } else if (year === 2019) {
            day = 3;
            month = 2;
        } else if (year === 2020) {
            day = 2;
            month = 2;
        } else if (year === 2021) {
            day = 7;
            month = 1;
        } else if (year === 2022) {
            day = 13;
            month = 2;
        } else if (year === 2023) {
            day = 12;
            month = 2;
        } else if (year === 2024) {
            day = 11;
            month = 2;
        } else if (year === 2025) {
            day = 9;
            month = 2;
        } else {
            return (0, _npmMoment.default)([1967, month - 1, day]); // yes, the first one
        }
        return (0, _npmMoment.default)([year, month - 1, day]);
    };

    var getEaster = function getEaster(year) {
        var goldenNumber = year % 19;
        var yearInHundreds = Math.floor(year / 100);
        var epact = (yearInHundreds - Math.floor(yearInHundreds / 4) - Math.floor((8 * yearInHundreds + 13) / 25) + 19 * goldenNumber + 15) % 30;
        var daysToPaschalMoon = epact - Math.floor(epact / 28) * (1 - Math.floor(29 / (epact + 1)) * Math.floor((21 - goldenNumber) / 11));
        var paschalMoonDOTW = (year + Math.floor(year / 4) + daysToPaschalMoon + 2 - yearInHundreds + Math.floor(yearInHundreds / 4)) % 7;
        var numDaysFromEquinoxToPaschalMoon = daysToPaschalMoon - paschalMoonDOTW;
        var month = 2 + Math.floor((numDaysFromEquinoxToPaschalMoon + 40) / 44);
        var day = numDaysFromEquinoxToPaschalMoon + 28 - 31 * Math.floor((month + 1) / 4);
        return (0, _npmMoment.default)([year, month, day]);
    };

    var getHolidays = function getHolidays(mmt) {
        if (!_npmMoment.default.isMoment(mmt)) {
            mmt = (0, _npmMoment.default)(mmt);
        }
        var matches = holidays.filter(function (x) {
            return x.match(mmt);
        });
        return matches;
    };

    exports.getHolidays = getHolidays;
});