define('matrix-frontend/pods/components/sales-vs-forecast-report/component', ['exports', 'npm:underscore', 'npm:moment', 'matrix-frontend/utils/format-helpers', 'matrix-frontend/pods/components/abstract-vs-report/component', 'npm:numbro'], function (exports, _npmUnderscore, _npmMoment, _formatHelpers, _component, _npmNumbro) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = _component.default.extend({
        labels: {
            // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
            area: 'Actual Sales',
            line: 'Sales Forecast',
            yAxis: '$',
            tickSymbol: '$'
        },

        tablePartial: 'components/sales-vs-forecast-report/table',

        forceAverage: Ember.computed('selectionData', function () {
            var displayedData = this.selectionData.map(function (x) {
                return [x.salesactual, x.salesforecast, x.forecastvar, x.lysalesactual, x.lyvar];
            }).reduce(function (o, i) {
                return o.concat(i.filter(function (x) {
                    return x;
                }).map(function (x) {
                    return Math.abs(x);
                }));
            }, []).filter(function (x) {
                return !isNaN(x);
            });
            var maxDisplayed = Math.max.apply(Math, _toConsumableArray(displayedData));
            var forceAverage = maxDisplayed < 100000 ? false : maxDisplayed < 100000000 ? 'thousand' : maxDisplayed < 100000000000 ? 'million' : maxDisplayed >= 100000000000 ? 'billion' : false;
            return forceAverage;
        }),

        crunchedData: Ember.computed('data', function () {
            var daily = this.get('data.daily');
            var crunchedDaily = Ember.copy(daily, true);

            if (crunchedDaily && !_npmUnderscore.default.isEmpty(crunchedDaily)) {
                var week = {
                    salesactual: 0,
                    salesforecast: 0,
                    salesforecastadjusted: 0,
                    forecastvar: 0,
                    lysalesactual: 0,
                    lysalesactualadjusted: 0,
                    lysalesforecast: 0,
                    lyvar: 0,
                    displayDate: 'Week'
                };

                _npmUnderscore.default.each(crunchedDaily, function (day) {
                    day.date = (0, _npmMoment.default)(day.demanddate);
                    day.displayDay = day.date.format('ddd');
                    day.displayDate = day.date.format('MM/DD');
                    day.areaValue = day.salesactual;
                    day.lineValue = day.salesforecast;

                    week.salesactual += day.salesactual;
                    week.salesforecast += day.salesforecast;
                    week.salesforecastadjusted += day.salesforecastadjusted;
                    week.forecastvar += day.forecastvar;
                    week.lysalesactual += day.lysalesactual;
                    week.lysalesactualadjusted += day.lysalesactualadjusted;
                    week.lysalesforecast += day.lysalesforecast;
                    week.lyvar += day.lyvar;
                });
                crunchedDaily.push(week);
            }

            return this.formatCrunchedData(crunchedDaily);
        }),

        formatCrunchedData: function formatCrunchedData(data) {
            var _this = this;

            this.set('isNullAdjusted', false);

            _npmUnderscore.default.each(data, function (item) {
                // item.salesactual = item.salesactual * 1000
                if ((item.salesactual || item.salesactual === 0) && (item.salesforecast || item.salesforecast === 0)) {
                    item.formattedVarPercent = _formatHelpers.default.formatPercent(item.forecastvar, item.salesforecastadjusted);
                    item.formattedVarPercentLastYear = _formatHelpers.default.formatPercent(item.lyvar, item.lysalesactualadjusted);

                    item.formattedsalesactual = (0, _npmNumbro.default)(item.salesactual).format('0.0a');
                    item.formattedsalesforecast = (0, _npmNumbro.default)(item.salesforecast).format('0.0a');
                    item.formattedVar = (0, _npmNumbro.default)(item.forecastvar).format('0.0a');
                    item.formattedLastYearValue = (0, _npmNumbro.default)(item.lysalesactual).format('0.0a');
                    item.formattedVarLastYear = (0, _npmNumbro.default)(item.lyvar).format('0.0a');

                    if (item.salesforecast !== item.salesforecastadjusted && item.salesforecastadjusted !== 0) {
                        item.formattedVar += '*';
                        item.formattedVarPercent += '*';
                        _this.set('isNullAdjusted', true);
                    }
                    if (item.lysalesactual !== item.lysalesactualadjusted && item.lysalesactualadjusted !== 0) {
                        item.formattedVarLastYear += '*';
                        item.formattedVarPercentLastYear += '*';
                        _this.set('isNullAdjusted', true);
                    }

                    _npmUnderscore.default.each(item, function (value, key, day) {
                        if (value === 0 || value === '$0') {
                            day[key] = '-';
                        }
                    });
                }
            });

            return data;
        },

        normalizedData: Ember.computed('data', function () {
            var data = this.get('data.hourly');

            if (data && !_npmUnderscore.default.isEmpty(data)) {
                data.forEach(function (item) {
                    item.date = item.date ? new Date(item.date) : null;
                    item.areaValue = item.salesvalue;
                    item.lineValue = item.forecastvalue;
                });
                return data;
            } else {
                return [];
            }
        })
    });
});