define('matrix-frontend/pods/legacy/controller', ['exports', 'matrix-frontend/mixins/base-controller'], function (exports, _baseController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_baseController.default, {
        stateService: Ember.inject.service(),

        actions: {
            selectBusinessUnit: function selectBusinessUnit(id, type) {
                this.set('storeEmployeeId', undefined);
                this._super(id, type);
            },
            goBack: function goBack() {
                var date = this.get('stateService').getWeekEndDate();
                var store = this.get('stateService').getBusinessUnit();
                this.transitionToRoute('schedule', {
                    queryParams: {
                        date: date,
                        storeId: store.id
                    }
                });
            }
        }
    });
});