define('matrix-frontend/components/employees-head', ['exports', 'matrix-frontend/actions/_types', 'react', 'ember-react-components', 'matrix-frontend/store'], function (exports, _types, _react, _emberReactComponents, _store) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var VIEWS = [{
        display: 'Contact Info',
        view: 'contact',
        f: 2
    }, {
        display: 'Availability',
        view: 'availability',
        f: 2
    }, {
        display: 'Hrs/Wk',
        view: 'minmax',
        f: 1
    }, {
        display: 'Skills',
        view: 'skills',
        f: 3
    }, {
        display: 'Username',
        f: 2
    }];

    var headerClick = function headerClick(pageView, view) {
        view = pageView === view ? 'overview' : view;
        _store.store.dispatch({
            type: _types.CUBE_SET_PARTIAL,
            payload: {
                view: view
            }
        });
    };

    var Header = function Header(_ref) {
        var pageView = _ref.pageView,
            display = _ref.display,
            f = _ref.f,
            view = _ref.view;

        var viewDisplay = view ? _react.default.createElement('span', {
            className: 'breathe-l-5 icon-' + (view === pageView ? 'minus' : 'plus') + '-circle'
        }) : '';
        var classes = ['column', 'f' + f];

        if (view) {
            classes.push('pointer');
        }
        if (pageView !== 'overview' && view !== pageView) {
            classes.push('gray-lighten-2-text');
        }

        return _react.default.createElement(
            'div',
            {
                className: classes.join(' '),
                onClick: function onClick() {
                    return view ? headerClick(pageView, view) : null;
                }
            },
            display,
            viewDisplay
        );
    };

    var comp = function comp(props) {
        return _react.default.createElement(
            'div',
            { className: 'row hide-on-print' },
            _react.default.createElement(
                'div',
                { className: 'wrapper f2' },
                _react.default.createElement(
                    'div',
                    { className: 'column text f1 no-left' },
                    '\xA0'
                ),
                _react.default.createElement(
                    'div',
                    { className: 'column text f6 no-left' },
                    'Name'
                )
            ),
            _react.default.createElement(
                'div',
                { className: 'wrapper f9' },
                VIEWS.map(function (x, i) {
                    return _react.default.createElement(Header, _extends({ key: i, pageView: props.view }, x));
                })
            )
        );
    };
    exports.default = (0, _emberReactComponents.default)(comp);
});