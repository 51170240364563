define('matrix-frontend/components/rolescope-management-content', ['exports', 'react', 'ember-react-components', 'matrix-frontend/utils/interactions', 'matrix-frontend/store', 'npm:underscore'], function (exports, _react, _emberReactComponents, _interactions, _store, _npmUnderscore) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var FakeRow = function FakeRow() {
        return _react.default.createElement(
            'div',
            { className: 'row' },
            _react.default.createElement(
                'div',
                { className: 'wrapper f3' },
                _react.default.createElement('div', { className: 'column text f1 fake-text' }),
                _react.default.createElement('div', { className: 'column text f1 fake-text gray-lighten-3-text' })
            ),
            _react.default.createElement(
                'div',
                { className: 'wrapper f4' },
                _react.default.createElement('div', { className: 'column f2 fake-text gray-lighten-3-text' }),
                _react.default.createElement('div', { className: 'column f3 fake-text gray-lighten-3-text' }),
                _react.default.createElement('div', { className: 'column f2 fake-text gray-lighten-3-text' })
            )
        );
    };

    var Loading = function Loading(props) {
        return _react.default.createElement(
            _react.default.Fragment,
            null,
            [].concat(_toConsumableArray(Array(10).keys())).map(function (x) {
                return _react.default.createElement(FakeRow, { key: x });
            })
        );
    };

    var NamespaceRow = function NamespaceRow(props) {
        return _react.default.createElement(
            'div',
            { className: 'row header' },
            _react.default.createElement(
                'div',
                { className: 'wrapper f1' },
                _react.default.createElement(
                    'div',
                    { className: 'column text f1' },
                    props.text
                )
            )
        );
    };

    var ScopeActionRow = function ScopeActionRow(props) {
        var canEdit = props.canEdit,
            scope = props.scope,
            roles = props.roles,
            roleMap = props.roleMap,
            changeRoleScope = props.changeRoleScope;


        return _react.default.createElement(
            'div',
            { className: 'row' },
            _react.default.createElement(
                'div',
                { className: 'wrapper f2' },
                _react.default.createElement('div', { className: 'column text f1' }),
                _react.default.createElement(
                    'div',
                    { className: 'column text f2' },
                    scope.action
                ),
                _react.default.createElement(
                    'div',
                    { className: 'column text f3' },
                    scope.description
                )
            ),
            _react.default.createElement(
                'div',
                { className: 'wrapper f4' },
                roles.map(function (role, i) {
                    var checked = role.scopes.includes(scope.namespace + ':' + scope.action);
                    var scopesForRole = void 0;
                    var override = void 0;
                    if ((scopesForRole = roleMap[role.id]) !== undefined) {
                        if ((override = scopesForRole[scope.id]) !== undefined) {
                            checked = override;
                        }
                    }
                    return _react.default.createElement(
                        'div',
                        { key: i, className: 'column f1' },
                        _react.default.createElement('div', { className: 'checkbox ' + (checked ? 'checked' : '') + ' ' + (canEdit ? 'pointer' : ''), onClick: !canEdit ? null : function (_) {
                                return changeRoleScope(role, scope, !checked);
                            } })
                    );
                })
            )
        );
    };

    var NamespaceSection = function NamespaceSection(props) {
        var namespace = props.namespace,
            groupScopes = props.groupScopes;

        return _react.default.createElement(
            'div',
            null,
            _react.default.createElement(NamespaceRow, { text: namespace }),
            groupScopes.map(function (s, i) {
                return _react.default.createElement(ScopeActionRow, _extends({ key: i, scope: s }, props));
            })
        );
    };

    var ScopeRows = function ScopeRows(props) {
        var scopes = props.scopes;


        var groups = {};
        scopes.sort(function (a, b) {
            if (a.namespace > b.namespace) return 1;
            if (a.namespace < b.namespace) return -1;
            if (a.action > b.action) return 1;
            if (a.action < b.action) return -1;
            return 0;
        }).forEach(function (scope) {
            if (groups[scope.namespace] === undefined) {
                groups[scope.namespace] = [];
            }
            groups[scope.namespace].push(scope);
        });

        return _react.default.createElement(
            _react.default.Fragment,
            null,
            [].concat(_toConsumableArray(Object.keys(groups))).map(function (namespace) {
                return _react.default.createElement(NamespaceSection, _extends({ key: namespace, namespace: namespace, groupScopes: groups[namespace] }, props));
            })
        );
    };

    var Head = function Head(props) {
        var roles = props.roles;


        var roleColumns = roles.map(function (role, i) {
            return _react.default.createElement(
                'div',
                { key: i, className: 'column f1 weight-heavy' },
                role.name
            );
        });

        return _react.default.createElement(
            'div',
            { className: 'row' },
            _react.default.createElement(
                'div',
                { className: 'wrapper f2' },
                _react.default.createElement('div', { className: 'column text f1' }),
                _react.default.createElement(
                    'div',
                    { className: 'column text f2' },
                    'Action'
                ),
                _react.default.createElement(
                    'div',
                    { className: 'column text f3' },
                    'Description'
                )
            ),
            _react.default.createElement(
                'div',
                { className: 'wrapper f4' },
                roleColumns
            )
        );
    };

    var Body = function Body(props) {
        var Display = props.scopes === null ? Loading : ScopeRows;
        return _react.default.createElement(Display, props);
    };

    var ControlPanel = function ControlPanel(props) {
        var goToIndex = props.goToIndex,
            showSaveControls = props.showSaveControls,
            discardAction = props.discardAction,
            saveAction = props.saveAction;


        var saveControls = showSaveControls ? _react.default.createElement(
            'div',
            { className: 'button-group save-controls' },
            _react.default.createElement(
                'button',
                { className: 'secondary tiny', onClick: discardAction },
                _react.default.createElement('span', { className: 'icon-trash' })
            ),
            _react.default.createElement(
                'button',
                { className: 'tiny', onClick: saveAction },
                'Save'
            )
        ) : null;

        return _react.default.createElement(
            'div',
            { className: 'control-panel' },
            _react.default.createElement(
                'div',
                { className: 'button-group no-margin' },
                _react.default.createElement(
                    'button',
                    { className: 'tiny thin secondary button', onClick: goToIndex },
                    'Back to Scheduler'
                )
            ),
            saveControls
        );
    };

    var comp = function comp(props) {
        var scopes = props.scopes;
        var roles = (props.currentClient || {}).roles;

        var _useState = (0, _react.useState)({}),
            _useState2 = _slicedToArray(_useState, 2),
            roleMap = _useState2[0],
            setRoleMap = _useState2[1];

        var isMatrixAdmin = props.currentUser.roles && props.currentUser.roles.any(function (role) {
            return 'matrix_admin' === role.name;
        });

        var canEdit = isMatrixAdmin;

        var changeRoleScope = function changeRoleScope(role, scope, value) {
            var newRoleMap = _extends({}, roleMap);
            if (newRoleMap[role.id] === undefined) {
                newRoleMap[role.id] = {};
            }
            if (newRoleMap[role.id][scope.id] !== undefined) {
                delete newRoleMap[role.id][scope.id];
            } else {
                newRoleMap[role.id][scope.id] = value;
            }
            if (_npmUnderscore.default.isEqual(newRoleMap[role.id], {})) {
                delete newRoleMap[role.id];
            }
            setRoleMap(newRoleMap);
        };

        var discardChanges = function discardChanges() {
            setRoleMap({});
        };

        var saveChanges = function saveChanges() {
            console.debug('New role map', roleMap);
            var payload = [].concat(_toConsumableArray(Object.keys(roleMap))).map(function (roleId) {
                var overrides = roleMap[roleId];
                var addScopes = Object.keys(overrides).filter(function (x) {
                    return overrides[x];
                });
                var removeScopes = Object.keys(overrides).filter(function (x) {
                    return !overrides[x];
                });
                return {
                    role_id: parseInt(roleId),
                    add_scopes: addScopes.map(function (x) {
                        return parseInt(x);
                    }),
                    remove_scopes: removeScopes.map(function (x) {
                        return parseInt(x);
                    })
                };
            });
            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'user',
                    namespace: ['role'],
                    operation: 3,
                    include_channel: 3,
                    channel_override: [_store.store.getState().cube.clientCode],
                    broadcast: false,
                    payload: payload,
                    response_type: 'NOCLIENT_RELOAD_RSMANAGEMENT'
                }
            }, 'update client roles for: ' + _store.store.getState().cube.clientCode, false, null, null, null, true);
            setRoleMap({});
        };

        var keyedRoles = {};
        props.currentClient.roles.forEach(function (x) {
            keyedRoles[x.name] = x;
        });

        var maxLevel = props.currentUser.roles.map(function (x) {
            return (keyedRoles[x.name] || {}).level;
        }).reduce(function (agg, x) {
            return x != null && x > agg ? x : agg;
        }, 0);
        var canSeeRole = function canSeeRole(role) {
            return isMatrixAdmin || role.level <= maxLevel;
        };

        var visibleRoles = roles.filter(function (role) {
            return role.viewable && canSeeRole(role);
        });

        var childProps = {
            canEdit: canEdit,
            roles: visibleRoles,
            scopes: scopes,
            roleMap: roleMap,
            changeRoleScope: changeRoleScope
        };

        return _react.default.createElement(
            'div',
            { className: 'outer' },
            _react.default.createElement(ControlPanel, { goToIndex: props.goToIndex, showSaveControls: !_npmUnderscore.default.isEqual(roleMap, {}) && canEdit, discardAction: discardChanges, saveAction: saveChanges }),
            _react.default.createElement(
                'div',
                { className: 'rolescopes flex-table breathe-t-20' },
                _react.default.createElement(
                    'section',
                    { id: 'rolescopes-head', className: 'head' },
                    _react.default.createElement(Head, childProps)
                ),
                _react.default.createElement(
                    'section',
                    { id: 'rolescopes-wrapper', className: 'body' },
                    _react.default.createElement(Body, childProps)
                )
            )
        );
    };

    exports.default = (0, _emberReactComponents.default)(comp);
});