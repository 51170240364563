define('matrix-frontend/components/react-only/detail-chart', ['exports', 'react', 'npm:react-tooltip', 'npm:moment', 'matrix-frontend/utils/hooks'], function (exports, _react, _npmReactTooltip, _npmMoment, _hooks) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.DetailChart = undefined;

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var wrapNaN = function wrapNaN(x) {
        return isNaN(x) ? 0 : x;
    };

    var smoothPath = function smoothPath(points, smoothFactor) {
        var d = '';
        points.forEach(function (curr, i) {
            var _curr = _slicedToArray(curr, 2),
                x = _curr[0],
                y = _curr[1];

            var next = points[i + 1];
            var next2 = points[i + 2];
            if (next2) {
                var dx = next2[0] - x;
                var dy = next2[1] - y;
                var x2 = next[0] - dx * smoothFactor;
                var y2 = next[1] - dy * smoothFactor;
                if (i == 0) {
                    var x1 = x + (next[0] - x) * smoothFactor;
                    var y1 = y + (next[1] - y) * smoothFactor;
                    d += 'M ' + x + ' ' + y + ' C ' + x1 + ' ' + y1 + ' ' + x2 + ' ' + y2 + ' ' + next[0] + ' ' + next[1];
                } else {
                    d += 'S ' + x2 + ' ' + y2 + ' ' + next[0] + ' ' + next[1];
                }
            } else if (i == 0) {
                d = 'M ' + x + ' ' + y + ' L ' + next[0] + ' ' + next[1];
            }
        });
        return d;
    };

    var DetailChart = exports.DetailChart = function DetailChart(props) {

        var initialState = function initialState() {
            return {
                hiddenSeries: {},
                reportKey: null,
                focusX: null
            };
        };

        var weekEndDate = props.weekEndDate,
            reportKey = props.reportKey,
            minX = props.minX,
            maxX = props.maxX,
            series = props.series,
            focusTooltipContentFunc = props.focusTooltipContentFunc;


        var ref = (0, _react.useRef)();

        var _useResize = (0, _hooks.useResize)(ref),
            width = _useResize.width,
            height = _useResize.height;

        var _useState = (0, _react.useState)(initialState()),
            _useState2 = _slicedToArray(_useState, 2),
            state = _useState2[0],
            setState = _useState2[1];

        var isSeriesHidden = function isSeriesHidden(series) {
            return state.hiddenSeries[series.key] == true;
        };

        var toggleSeriesHidden = function toggleSeriesHidden(series) {
            var x = _extends({}, state.hiddenSeries);
            x[series.key] = !x[series.key];
            setState(_extends({}, state, {
                hiddenSeries: x
            }));
        };

        var setFocusPoint = function setFocusPoint(x) {
            setState(_extends({}, state, {
                focusX: x
            }));
        };

        (0, _react.useLayoutEffect)(function () {
            if (props.reportKey !== state.reportKey) {
                setState({
                    hiddenSeries: {},
                    reportKey: props.reportKey
                });
            }
        }, [props.reportKey]);

        var numLoaded = series.filter(function (s) {
            return s.loaded;
        }).length;
        var numWithData = series.filter(function (s) {
            return s.data != null;
        }).length;

        var minY = 0;
        var maxY = 5000;
        var hasData = false;

        series.forEach(function (s) {
            if (s !== null && s.data && !isSeriesHidden(s)) {
                var ys = s.data.map(function (p) {
                    return wrapNaN(p[1]);
                });
                var sMinY = Math.min.apply(Math, _toConsumableArray(ys));
                var sMaxY = Math.max.apply(Math, _toConsumableArray(ys));
                minY = !hasData ? sMinY : Math.min(sMinY, minY);
                maxY = !hasData ? sMaxY : Math.max(sMaxY, maxY);
                hasData = true;
            }
        });

        if (hasData) {
            var range = maxY - minY;
            maxY += range * 0.1;
            if (minY != 0) minY -= range * 0.05;
        }

        var scaleY = height / (maxY - minY);
        var offsetY = height + minY * scaleY;

        var leftSpace = width * 2 / 11;
        var rightSpace = width * 9 / 11 / 8;
        var chartSpace = width - leftSpace - rightSpace;

        var scaleX = chartSpace / (maxX - minX);
        var offsetX = -minX * scaleX;

        var toChartPxX = function toChartPxX(x) {
            return leftSpace + wrapNaN(x) * scaleX + offsetX;
        };

        var fromChartPx = function fromChartPx(x) {
            return (x - leftSpace - offsetX) / scaleX;
        };

        var toPxY = function toPxY(y) {
            return wrapNaN(y) * -scaleY + offsetY;
        };

        var Axis = function Axis(_ref) {
            var x1 = _ref.x1,
                y1 = _ref.y1,
                x2 = _ref.x2,
                y2 = _ref.y2;
            return _react.default.createElement('polyline', { fill: 'none', stroke: '#aaa', strokeWidth: '0.5px', points: x1 + ',' + y1 + ' ' + x2 + ',' + y2 });
        };

        var YAxis = function YAxis() {
            return _react.default.createElement(Axis, { key: 'y', x1: leftSpace, y1: 0, x2: leftSpace, y2: height });
        };
        var XAxis = function XAxis() {
            return _react.default.createElement(Axis, { key: 'x', x1: leftSpace, y1: toPxY(0), x2: width - rightSpace, y2: toPxY(0) });
        };

        var tickSpaces = [1, 2, 5, 10, 15, 20, 50, 80, 100, 150, 200, 250, 300, 400, 500, 1000, 1500, 2000, 3000, 4000, 5000, 10000, 20000, 50000, 100000, 150000, 200000, 250000, 500000, 800000, 1000000, 1500000, 2000000];

        var getTickSpace = function getTickSpace() {
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = tickSpaces[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var _tickSpace = _step.value;

                    if (_tickSpace * scaleY >= 20) {
                        return _tickSpace;
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }
        };
        var tickSpace = getTickSpace();

        var getTicks = function getTicks(tickSpace) {
            var tickYs = [0];
            var y = tickSpace;
            while (y < maxY) {
                tickYs.push(y);
                y += tickSpace;
            }
            y = -tickSpace;
            while (y > minY) {
                tickYs.push(y);
                y -= tickSpace;
            }
            return tickYs.map(function (y) {
                return {
                    value: y,
                    pxY: toPxY(y)
                };
            }).filter(function (t) {
                return t.pxY >= 10 && t.pxY <= height - 10;
            });
        };
        var ticks = getTicks(tickSpace).map(function (t, i) {
            return _react.default.createElement(
                _react.Fragment,
                { key: i },
                _react.default.createElement(
                    'text',
                    { key: 'text', x: leftSpace - 15, y: t.pxY, textAnchor: 'end', dominantBaseline: 'central', fill: '#aaa', fontSize: 12, fontFamily: 'Open Sans' },
                    t.value.toLocaleString()
                ),
                _react.default.createElement('polyline', { key: 'dash', fill: 'none', stroke: '#aaa', strokeWidth: '0.5px', points: leftSpace - 10 + ',' + t.pxY + ' ' + leftSpace + ',' + t.pxY })
            );
        });

        var LineSeries = function LineSeries(_ref2) {
            var style = _ref2.style,
                data = _ref2.data,
                smooth = _ref2.smooth,
                _ref2$smoothFactor = _ref2.smoothFactor,
                smoothFactor = _ref2$smoothFactor === undefined ? 0.3 : _ref2$smoothFactor;

            var elemStyle = {
                stroke: style.color,
                strokeWidth: 1.5,
                strokeLinecap: 'round',
                strokeDasharray: style.dashed ? '0.5 3' : null
            };

            if (!smooth) {
                var points = data.map(function (_ref3) {
                    var _ref4 = _slicedToArray(_ref3, 2),
                        x = _ref4[0],
                        y = _ref4[1];

                    return toChartPxX(x) + ',' + toPxY(y);
                }).join(' ');
                return _react.default.createElement('polyline', _extends({ fill: 'none', strokeWidth: 3 }, elemStyle, { points: points }));
            } else {
                var _points = data.map(function (_ref5) {
                    var _ref6 = _slicedToArray(_ref5, 2),
                        x = _ref6[0],
                        y = _ref6[1];

                    return [toChartPxX(x), toPxY(y)];
                });
                return _react.default.createElement('path', _extends({ d: smoothPath(_points, smoothFactor), fill: 'none', strokeWidth: 3 }, elemStyle));
            }
        };

        var AreaSeries = function AreaSeries(_ref7) {
            var style = _ref7.style,
                data = _ref7.data,
                smooth = _ref7.smooth,
                _ref7$smoothFactor = _ref7.smoothFactor,
                smoothFactor = _ref7$smoothFactor === undefined ? 0.3 : _ref7$smoothFactor;

            var xs = data.map(function (p) {
                return wrapNaN(p[0]);
            });
            var minX = Math.min.apply(Math, _toConsumableArray(xs));
            var maxX = Math.max.apply(Math, _toConsumableArray(xs));

            var elemStyle = {
                fill: style.color
            };

            if (!smooth) {
                var transformedXY = [[minX, 0]].concat(_toConsumableArray(data), [[maxX, 0]]).map(function (_ref8) {
                    var _ref9 = _slicedToArray(_ref8, 2),
                        x = _ref9[0],
                        y = _ref9[1];

                    return [toChartPxX(x), toPxY(y)];
                });
                var points = transformedXY.map(function (_ref10) {
                    var _ref11 = _slicedToArray(_ref10, 2),
                        x = _ref11[0],
                        y = _ref11[1];

                    return x + ',' + y;
                }).join(' ');
                return _react.default.createElement('polygon', _extends({}, elemStyle, { points: points }));
            } else {
                var _points2 = data.map(function (_ref12) {
                    var _ref13 = _slicedToArray(_ref12, 2),
                        x = _ref13[0],
                        y = _ref13[1];

                    return [toChartPxX(x), toPxY(y)];
                });
                return _react.default.createElement('path', _extends({ d: smoothPath(_points2, smoothFactor) + ' L ' + toChartPxX(maxX) + ' ' + toPxY(0) + ' L ' + toChartPxX(minX) + ' ' + toPxY(0) }, elemStyle));
            }
        };

        var FocusMarker = function FocusMarker() {
            if (state.focusX != null && numLoaded > 0 && numWithData > 0) {
                var tX = toChartPxX(state.focusX);

                var points = series.map(function (s) {
                    if (isSeriesHidden(s) || !s.data) {
                        return null;
                    }
                    var y = null;
                    var _iteratorNormalCompletion2 = true;
                    var _didIteratorError2 = false;
                    var _iteratorError2 = undefined;

                    try {
                        for (var _iterator2 = s.data[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                            var p = _step2.value;

                            if (p[0] == state.focusX) {
                                y = p[1];
                                break;
                            }
                        }
                    } catch (err) {
                        _didIteratorError2 = true;
                        _iteratorError2 = err;
                    } finally {
                        try {
                            if (!_iteratorNormalCompletion2 && _iterator2.return) {
                                _iterator2.return();
                            }
                        } finally {
                            if (_didIteratorError2) {
                                throw _iteratorError2;
                            }
                        }
                    }

                    if (y == null) {
                        return null;
                    }

                    var tY = toPxY(y);
                    return _react.default.createElement('circle', { key: s.key, cx: tX, cy: tY, r: 3, fill: '#000' });
                });
                return _react.default.createElement(
                    _react.Fragment,
                    null,
                    _react.default.createElement('line', { x1: tX, y1: 0, x2: tX, y2: height, stroke: '#aaa', strokeWidth: '0.5px' }),
                    points
                );
            }
            return null;
        };

        var AreaLegendEntry = function AreaLegendEntry(_ref14) {
            var series = _ref14.series;
            var name = series.name,
                style = series.style;

            var elemStyle = {
                background: style.color
            };
            return _react.default.createElement(
                'div',
                { className: 'legend-entry', onClick: function onClick(_) {
                        return toggleSeriesHidden(series);
                    }, style: { opacity: isSeriesHidden(series) ? 0.5 : 1 } },
                _react.default.createElement('div', { className: 'series-icon-area', style: elemStyle }),
                _react.default.createElement(
                    'div',
                    { className: 'series-text ' + (style.classes || '') },
                    name
                )
            );
        };

        var LineLegendEntry = function LineLegendEntry(_ref15) {
            var series = _ref15.series;
            var name = series.name,
                style = series.style;

            var elemStyle = {
                borderBottomWidth: '3px',
                borderBottomStyle: style.dashed ? 'dotted' : 'solid',
                borderBottomColor: style.color
            };
            return _react.default.createElement(
                'div',
                { className: 'legend-entry', onClick: function onClick(_) {
                        return toggleSeriesHidden(series);
                    }, style: { opacity: isSeriesHidden(series) ? 0.5 : 1 } },
                _react.default.createElement('div', { className: 'series-icon-line', style: elemStyle }),
                _react.default.createElement(
                    'div',
                    { className: 'series-text ' + (style.classes || '') },
                    name
                )
            );
        };

        var getSeriesComp = function getSeriesComp(series) {
            if (series.data != null && !isSeriesHidden(series)) {
                switch (series.type) {
                    case 'line':
                        return _react.default.createElement(LineSeries, { key: series.key, style: series.style, data: series.data, smooth: true });
                    case 'area':
                        return _react.default.createElement(AreaSeries, { key: series.key, style: series.style, data: series.data, smooth: true });
                    default:
                        break;
                }
            }
            return null;
        };

        var getLegendComp = function getLegendComp(series) {
            switch (series.type) {
                case 'line':
                    return _react.default.createElement(LineLegendEntry, { key: series.key, series: series });
                case 'area':
                    return _react.default.createElement(AreaLegendEntry, { key: series.key, series: series });
                default:
                    break;
            }
        };

        var loadingMessage = function loadingMessage() {
            var innerText = void 0;
            if (numLoaded == 0) {
                innerText = "Loading graph...";
            } else if (numWithData == 0) {
                innerText = "No data";
            }
            if (innerText !== undefined) {
                return _react.default.createElement(
                    'text',
                    { key: 'loadingText', x: leftSpace + chartSpace / 2, y: height / 2, textAnchor: 'middle', dominantBaseline: 'central', fill: '#ccc', fontSize: 30, fontWeight: 700, fontFamily: 'Open Sans' },
                    innerText
                );
            }
            return null;
        };

        var mouseMove = function mouseMove(e) {
            var target = e.target || e.srcElement;
            var rect = target.getBoundingClientRect();
            var x = e.clientX - rect.left;
            var dataX = Math.round(fromChartPx(x));
            if (dataX < 0 || dataX >= 168) {
                dataX = null;
            }
            if (dataX != state.focusX) {
                setFocusPoint(dataX);
            }
        };

        var focusTooltipContent = function focusTooltipContent() {
            if (state.focusX == null || focusTooltipContentFunc == null) {
                return null;
            }
            var seriesData = {};
            series.forEach(function (s) {
                if (s.data) {
                    var _iteratorNormalCompletion3 = true;
                    var _didIteratorError3 = false;
                    var _iteratorError3 = undefined;

                    try {
                        for (var _iterator3 = s.data[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                            var p = _step3.value;

                            if (p[0] == state.focusX) {
                                seriesData[s.key] = p[1];
                                break;
                            }
                        }
                    } catch (err) {
                        _didIteratorError3 = true;
                        _iteratorError3 = err;
                    } finally {
                        try {
                            if (!_iteratorNormalCompletion3 && _iterator3.return) {
                                _iterator3.return();
                            }
                        } finally {
                            if (_didIteratorError3) {
                                throw _iteratorError3;
                            }
                        }
                    }
                }
            });
            return focusTooltipContentFunc(state.focusX, seriesData);
        };

        return _react.default.createElement(
            'div',
            { className: 'chart-container' },
            _react.default.createElement(
                'svg',
                { ref: ref, height: '150px', onMouseMove: mouseMove, onMouseLeave: function onMouseLeave(_) {
                        return setFocusPoint(null);
                    }, 'data-for': 'focusTooltip', 'data-place': 'bottom', 'data-tip': '' },
                _react.default.createElement(YAxis, null),
                _react.default.createElement(XAxis, null),
                ticks,
                series.map(getSeriesComp).filter(function (s) {
                    return s != null;
                }),
                _react.default.createElement(FocusMarker, null),
                loadingMessage()
            ),
            _react.default.createElement(
                'div',
                { className: 'chart-legend' },
                series.map(getLegendComp)
            ),
            _react.default.createElement(_npmReactTooltip.default, {
                key: 'focusTooltip',
                id: 'focusTooltip',
                overridePosition: function overridePosition(_ref16, currentEvent, currentTarget, node) {
                    var left = _ref16.left,
                        top = _ref16.top;

                    var rect = currentTarget.getBoundingClientRect();
                    top = rect.bottom;
                    return { top: top, left: left };
                },
                getContent: numWithData ? focusTooltipContent : function () {
                    return null;
                }
            })
        );
    };
});