define('matrix-frontend/components/react-only/multi-checker', ['exports', 'react'], function (exports, _react) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var Item = function Item(_ref) {
        var item = _ref.item,
            checked = _ref.checked,
            propertyId = _ref.propertyId,
            propertyName = _ref.propertyName,
            updateState = _ref.updateState;

        var classes = ['breathe-l-5'];
        if (item.disabled) {
            classes.push('gray-lighten-1-text');
        }
        return _react.default.createElement(
            'div',
            { className: 'item' },
            _react.default.createElement(
                'label',
                null,
                _react.default.createElement('input', {
                    type: 'checkbox',
                    checked: checked,
                    onChange: updateState,
                    value: item[propertyId],
                    disabled: item.disabled
                }),
                _react.default.createElement(
                    'span',
                    { className: classes.join(' ') },
                    item[propertyName]
                )
            )
        );
    };

    var comp = function comp(_ref2) {
        var objects = _ref2.objects,
            setter = _ref2.setter,
            _ref2$initial = _ref2.initial,
            initial = _ref2$initial === undefined ? null : _ref2$initial,
            _ref2$propertyId = _ref2.propertyId,
            propertyId = _ref2$propertyId === undefined ? 'id' : _ref2$propertyId,
            _ref2$propertyName = _ref2.propertyName,
            propertyName = _ref2$propertyName === undefined ? 'name' : _ref2$propertyName;

        var _useState = (0, _react.useState)(initial || new Set()),
            _useState2 = _slicedToArray(_useState, 2),
            state = _useState2[0],
            setState = _useState2[1];

        var updateState = function updateState(e) {
            var value = e.target.value;

            var newState = void 0;

            if (state.has(value)) {
                newState = new Set([].concat(_toConsumableArray(state)).filter(function (x) {
                    return x !== value;
                }));
            } else {
                newState = new Set([].concat(_toConsumableArray(state), [value]));
            }
            setState(newState);
        };

        var checkall = function checkall() {
            var newState = new Set(objects.filter(function (x) {
                return !x.disabled;
            }).map(function (x) {
                return '' + x[propertyId];
            }));
            setState(newState);
        };

        var checknone = function checknone() {
            setState(new Set());
        };

        (0, _react.useEffect)(function () {
            setter(new Set([].concat(_toConsumableArray(state))));
        }, [state]);

        return _react.default.createElement(
            'div',
            { className: 'multi-checker' },
            _react.default.createElement(
                'div',
                { className: 'controls' },
                _react.default.createElement(
                    'a',
                    { href: '#', onClick: checkall },
                    'all'
                ),
                '\xA0/\xA0',
                _react.default.createElement(
                    'a',
                    { href: '#', onClick: checknone },
                    'none'
                )
            ),
            _react.default.createElement(
                'div',
                { className: 'objects' },
                objects.map(function (obj) {
                    return _react.default.createElement(
                        'div',
                        { key: obj[propertyId] },
                        _react.default.createElement(Item, {
                            key: obj[propertyId],
                            item: obj,
                            checked: state.has('' + obj[propertyId]),
                            propertyId: propertyId,
                            propertyName: propertyName,
                            updateState: updateState
                        })
                    );
                })
            )
        );
    };

    exports.default = comp;
});