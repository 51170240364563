define('matrix-frontend/utils/backoff', ['exports', 'npm:backoff'], function (exports, _npmBackoff) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.socketBackoff = exports.generalBackoff = exports.setStore = undefined;


    var store = void 0;
    var setStore = exports.setStore = function setStore(s) {
        store = s;
    };

    var generalBackoff = exports.generalBackoff = function generalBackoff(condition, ready) {
        var name = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'GENERAL';
        var number = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 7;
        var forceFib = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
        var method = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : _npmBackoff.default.exponential;

        var generalBackoff = forceFib ? _npmBackoff.default.fibonacci() : method();
        generalBackoff.on('backoff', function (number, delay) {});
        generalBackoff.on('ready', function (number, delay) {
            var check = condition();
            console.debug('ready ' + number + ':' + delay + ' | ' + !!check);
            if (check) {
                ready();
            } else {
                if (check !== -1) {
                    console.debug('Backing off: ' + name);
                    generalBackoff.backoff();
                } else {
                    console.debug('STOP: ' + name);
                }
            }
        });
        generalBackoff.on('fail', function () {
            console.debug('fail ' + name);
        });
        generalBackoff.backoff();
        generalBackoff.failAfter(number);
    };

    var socketBackoff = exports.socketBackoff = function socketBackoff(condition, ready) {
        var name = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'SOCKET';
        var method = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : _npmBackoff.default.fibonacci;

        generalBackoff(function () {
            if (!store) {
                return false;
            }

            var _store$getState = store.getState(),
                _store$getState$webso = _store$getState.websocket,
                currentSocket = _store$getState$webso.currentSocket,
                status = _store$getState$webso.status;

            return currentSocket && status === 2 && condition();
        }, ready, name);
    };
});