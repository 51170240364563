define("matrix-frontend/utils/functional-sort", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var doReverse = function doReverse(x) {
        var should = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        return should ? -x : x;
    };

    var tieredSort = exports.tieredSort = function tieredSort(funcs) {
        var reverse = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        return function (a, b) {
            var compare = 0;
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = funcs[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var f = _step.value;

                    if (compare != 0) break;
                    compare = f(a, b);
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            return doReverse(compare, reverse);
        };
    };

    var funcSort = exports.funcSort = function funcSort(f, options) {
        var opts = _extends({
            useAB: false,
            reverse: false,
            numeric: false
        }, options || {});
        return function (a, b) {
            var x = void 0;
            if (opts.useAB) {
                x = f(a, b);
            } else {
                var aV = f(a);
                var bV = f(b);
                if (opts.numeric) {
                    x = aV - bV;
                } else {
                    x = aV > bV ? 1 : aV < bV ? -1 : 0;
                }
            }
            return doReverse(x, opts.reverse);
        };
    };
});