define('matrix-frontend/pods/components/bar-chart/component', ['exports', 'npm:d3'], function (exports, _npmD) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['graph-container', 'bar-chart-container'],

        didInsertElement: function didInsertElement() {
            this.draw();
        },

        draw: function () {
            // eslint-disable-line ember/no-function-prototype-extensions
            var component = this;
            var data = this.get('data');
            var labels = this.get('labels');

            //clean up any existing graph
            _npmD.default.select('.bar-chart').remove();

            if (data && labels) {
                var margin = { top: 25, right: 5, bottom: 5, left: 25 },
                    width = 320 - margin.right - margin.left,
                    height = 155 - margin.top - margin.bottom,
                    barWidth = Math.floor(width / data.length);

                var extentValue = _npmD.default.extent(data, function (d) {
                    return Math.max(d.areaValue, d.lineValue);
                });

                var tickFormatFunction;
                var labelUnit = '';
                if (extentValue[1] >= 1000000) {
                    tickFormatFunction = function tickFormatFunction(d) {
                        return d / 1e6;
                    };
                    labelUnit = '(m)';
                } else if (extentValue[1] >= 1000) {
                    tickFormatFunction = function tickFormatFunction(d) {
                        return d / 1e3;
                    };
                    labelUnit = '(k)';
                }

                var y = _npmD.default.scale.linear().domain([0, extentValue[1]]).range([height, 0]);

                var yAxis = _npmD.default.svg.axis().scale(y).ticks(5).tickFormat(tickFormatFunction).tickSize(0, 0).orient('left');

                var svg = _npmD.default.select('#' + this.get('elementId')).append('svg').attr('class', 'bar-chart').attr('viewBox', '0 0 ' + (width + margin.left + margin.right) + ' ' + (height + margin.top + margin.bottom));

                var chart = svg.append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

                var bars = chart.append('g').attr('class', 'bars');

                var bar = bars.selectAll('g').data(data).enter().append('g').attr('transform', function (d, i) {
                    return 'translate(' + i * barWidth + ',0)';
                }).on('tap', function (d) {
                    component.sendAction('action', d.date);
                });

                bar.append('rect').attr('height', height).attr('width', barWidth).attr('class', function (d) {
                    if (d.selected) {
                        return 'background-bar selected';
                    } else {
                        return 'background-bar';
                    }
                });

                bar.append('rect').attr('y', function (d) {
                    return y(d.areaValue);
                }).attr('x', '3').attr('height', function (d) {
                    return height - y(d.areaValue);
                }).attr('width', barWidth - 6).attr('class', 'area');

                bar.append('rect').attr('y', function (d) {
                    return y(d.lineValue);
                }).attr('x', '3').attr('height', function (d) {
                    return height - y(d.lineValue);
                }).attr('width', barWidth - 6).attr('class', 'line').style('stroke-width', '1px');

                bar.append('text').attr('x', 7).attr('y', height - 5).style('font-size', '.8em').style('fill', 'white').text(function (d) {
                    return d.displayDay;
                });

                chart.append('g').attr('class', 'y axis').call(yAxis).append('text').attr('y', -12).attr('x', 2).attr('dy', '.71em');

                if (labels.area && labels.line) {
                    var areaLegend = svg.append('g').attr('transform', 'translate(' + margin.left + ', 18)');

                    areaLegend.append('rect').attr('class', 'area').attr('width', '10px').attr('height', '10px').attr('y', -10);

                    areaLegend.append('text').text(labels.area).attr('class', 'legend-label').attr('y', 0).attr('x', 15);

                    var lineLegend = svg.append('g').attr('transform', 'translate(' + (margin.left + 50) + ', 18)');

                    lineLegend.append('line').attr('class', 'line').attr('x1', 40).attr('x2', 50).attr('y1', -4).attr('y2', -4);

                    lineLegend.append('text').text(labels.line + ' ' + labelUnit).attr('class', 'legend-label').attr('y', 0).attr('x', 55);
                }
            }
        }.observes('data')
    });
});