define('matrix-frontend/reducers/_shifts', ['exports', 'npm:moment'], function (exports, _npmMoment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var shifts = {
        11: {
            record: {
                id: 11,
                enter: 0,
                exit: null,
                date: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27
                }),
                employeeId: 1
            },
            blocks: [{
                id: 111,
                start: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 17,
                    minute: 30,
                    seconds: 0
                }),
                end: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 20,
                    minute: 30,
                    seconds: 0
                }),
                activity: 2
            }]
        },
        22: {
            record: {
                id: 22,
                enter: 0,
                exit: null,
                date: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27
                }),
                employeeId: 2
            },
            blocks: [{
                id: 221,
                start: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 13,
                    minute: 15,
                    seconds: 0
                }),
                end: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 13,
                    minute: 45,
                    seconds: 0
                }),
                activity: 1
            }, {
                id: 222,
                start: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 13,
                    minute: 45,
                    seconds: 0
                }),
                end: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 17,
                    minute: 30,
                    seconds: 0
                }),
                activity: 2
            }, {
                id: 223,
                start: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 17,
                    minute: 30,
                    seconds: 0
                }),
                end: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 18,
                    minute: 30,
                    seconds: 0
                }),
                activity: 12
            }, {
                id: 224,
                start: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 18,
                    minute: 30,
                    seconds: 0
                }),
                end: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 20,
                    minute: 30,
                    seconds: 0
                }),
                activity: 1
            }]
        },
        33: {
            record: {
                id: 33,
                enter: 0,
                exit: null,
                date: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27
                }),
                employeeId: 3
            },
            blocks: [{
                id: 331,
                start: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 10,
                    minute: 30,
                    seconds: 0
                }),
                end: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 13,
                    minute: 15,
                    seconds: 0
                }),
                activity: 1
            }, {
                id: 332,
                start: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 13,
                    minute: 45,
                    seconds: 0
                }),
                end: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 18,
                    minute: 30,
                    seconds: 0
                }),
                activity: 1
            }]
        },
        55: {
            record: {
                id: 55,
                enter: 0,
                exit: null,
                date: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27
                }),
                employeeId: 5
            },
            blocks: [{
                id: 551,
                start: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 17,
                    minute: 0,
                    seconds: 0
                }),
                end: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 20,
                    minute: 0,
                    seconds: 0
                }),
                activity: 2
            }, {
                id: 552,
                start: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 20,
                    minute: 0,
                    seconds: 0
                }),
                end: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 20,
                    minute: 30,
                    seconds: 0
                }),
                activity: 2
            }]
        },
        66: {
            record: {
                id: 66,
                enter: 0,
                exit: null,
                date: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27
                }),
                employeeId: 6
            },
            blocks: [{
                id: 661,
                start: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 14,
                    minute: 0,
                    seconds: 0
                }),
                end: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 17,
                    minute: 0,
                    seconds: 0
                }),
                activity: 2
            }, {
                id: 662,
                start: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 17,
                    minute: 0,
                    seconds: 0
                }),
                end: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 20,
                    minute: 30,
                    seconds: 0
                }),
                activity: 2
            }]
        },
        1010: {
            record: {
                id: 1010,
                enter: 0,
                exit: null,
                date: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27
                }),
                employeeId: 10
            },
            blocks: [{
                id: 10101,
                start: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 11,
                    minute: 30,
                    seconds: 0
                }),
                end: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 15,
                    minute: 0,
                    seconds: 0
                }),
                activity: 2
            }, {
                id: 10102,
                start: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 15,
                    minute: 0,
                    seconds: 0
                }),
                end: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 17,
                    minute: 0,
                    seconds: 0
                }),
                activity: 2
            }]
        },
        1212: {
            record: {
                id: 1212,
                enter: 0,
                exit: null,
                date: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27
                }),
                employeeId: 12
            },
            blocks: [{
                id: 12121,
                start: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 17,
                    minute: 30,
                    seconds: 0
                }),
                end: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 27,
                    hour: 20,
                    minute: 30,
                    seconds: 0
                }),
                activity: 2
            }]
        },

        211: {
            record: {
                id: 211,
                enter: 0,
                exit: null,
                date: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 28
                }),
                employeeId: 1
            },
            blocks: [{
                id: 2111,
                start: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 28,
                    hour: 10,
                    minute: 30,
                    seconds: 0
                }),
                end: (0, _npmMoment.default)().set({
                    year: 2018,
                    month: 4,
                    date: 28,
                    hour: 14,
                    minute: 30,
                    seconds: 0
                }),
                activity: 2
            }]
        }
    };

    exports.default = shifts;
});