define('matrix-frontend/helpers/getter', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.getter = getter;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    function getter(params /*, hash*/) {
        var _params = _slicedToArray(params, 2),
            obj = _params[0],
            key = _params[1];

        if (params[2]) {
            key = parseInt(key);
        }
        if (params[3] !== undefined) {
            if (!obj.hasOwnProperty(key)) {
                return params[3];
            }
            return obj[key];
        } else {
            return obj[key];
        }
    }

    exports.default = Ember.Helper.helper(getter);
});