define('matrix-frontend/pods/clients/index/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        loadingService: Ember.inject.service(),
        model: function model() {
            return this.store.findAll('client').then(function (item) {
                return item.sortBy('name');
            });
        },

        afterModel: function afterModel() {
            // Unload store
            this.get('store').unloadAll('v2-hierarchy-node');
            this.get('loadingService').endLoading();
        }
    });
});