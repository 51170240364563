define('matrix-frontend/services/modal-service', ['exports', 'matrix-frontend/actions/_types'], function (exports, _types) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var serviceCache = [];

    exports.default = Ember.Service.extend({
        init: function init() {
            serviceCache.push(this);
            this._super.apply(this, arguments);
        },


        modals: {}, // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
        saveInProgress: false,
        redux: Ember.inject.service(),

        register: function register(_ref) {
            var component = _ref.component,
                key = _ref.key,
                beforeOpen = _ref.beforeOpen,
                afterOpen = _ref.afterOpen,
                beforeClose = _ref.beforeClose,
                afterClose = _ref.afterClose;

            this.set('modals.' + key, {
                component: component,
                status: 0,
                hasChange: false,
                loading: false,
                beforeOpen: beforeOpen,
                afterOpen: afterOpen,
                beforeClose: beforeClose,
                afterClose: afterClose,
                inputs: new Set(),
                errors: new Set(),
                warnings: new Set(),
                alerts: new Set(),
                infos: new Set()
            });
        },
        addInput: function addInput(key, input) {
            this.get('modals.' + key + '.inputs').add(input);
        },
        isOpen: function isOpen(key) {
            return this.get('modals.' + key + '.status') === 2;
        },
        isClosing: function isClosing(key) {
            return this.get('modals.' + key + '.status') === 1;
        },
        isSaving: function isSaving(key) {
            return this.get('saveInProgress');
        },
        hasChange: function hasChange(key) {
            return this.get('modals.' + key + '.hasChange') || false;
        },
        isLoading: function isLoading(key) {
            return this.get('modals.' + key + '.loading') || false;
        },
        isValid: function isValid(key) {
            return this.get('modals.' + key + '.isValid') ? true : this.get('modals.' + key + '.isValid') == false ? false : true;
        },
        changeMade: function changeMade(key) {
            this.set('modals.' + key + '.hasChange', true);
            this.notifyPropertyChange('modals');
        },
        changeDone: function changeDone(key) {
            this.set('modals.' + key + '.hasChange', false);
            this.notifyPropertyChange('modals');
        },
        setValid: function setValid(key, validity) {
            this.set('modals.' + key + '.isValid', validity);
            this.notifyPropertyChange('modals');
        },
        setLoading: function setLoading(key, loading) {
            this.set('modals.' + key + '.loading', loading);
            this.notifyPropertyChange('modals');
        },
        pushNotice: function pushNotice(key, type, text) {
            var item = 'modals.' + key + '.' + type + 's';
            this.get(item).add(text);
            this.notifyPropertyChange('modals');
        },
        resetNotices: function resetNotices(key) {
            this.set('modals.' + key + '.errors', new Set());
            this.set('modals.' + key + '.warnings', new Set());
            this.set('modals.' + key + '.alerts', new Set());
            this.set('modals.' + key + '.infos', new Set());
        },
        reset: function reset(key) {
            this.saveCompleted();
            this.changeDone(key);
            this.setLoading(key, false);
            this.setValid(key, undefined);
            this.resetNotices(key);

            Ember.$('#modal-' + key + ' :input').not(':button, :submit, :reset, :hidden')
            // .val('')
            .removeAttr('checked').removeAttr('selected');

            this.get('modals.' + key + '.inputs').forEach(function (x) {
                if (x._state === 'inDOM' && !x.renderer._destroyed) {
                    x.set('isValid', null);
                }
            });
        },
        bubbleValidation: function bubbleValidation(key) {
            var requires = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
            var requireAll = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
            var additionalRequirement = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

            requires = requires || [];

            var inputs = [].concat(_toConsumableArray(this.get('modals.' + key + '.inputs').values()));
            // console.log({inputs})
            var inputsToTest = requires ? inputs.filter(function (x) {
                return requires.includes(x.elementId);
            }) : inputs;
            var valids = inputsToTest.map(function (x) {
                return x.isValid;
            });
            // console.log({valids})
            var allValid = requireAll ? valids.every(function (x) {
                return x;
            }) : valids.some(function (x) {
                return x;
            });
            var isValid = additionalRequirement ? additionalRequirement() && allValid : allValid;
            // console.log({requires, requireAll, isValid, allValid,additionalRequirement: additionalRequirement()})
            this.setValid(key, isValid);
        },
        doSave: function doSave(key) {
            if (this.isValid(key)) {
                var component = this.get('modals.' + key + '.component');
                if (component.saveAction) {
                    component.saveAction();
                }
            }
        },
        open: function open(key) {
            var self = this;
            return new Ember.RSVP.Promise(function (resolve) {
                self.get('redux').dispatch({
                    type: _types.CUBE_TOGGLE_MULTI_USER_SAVE_MODE,
                    payload: {
                        force: false
                    }
                });
                if (self.get('modals.' + key + '.beforeOpen')) {
                    self.get('modals.' + key + '.beforeOpen').call();
                }
                self.set('modals.' + key + '.status', 2);
                self.notifyPropertyChange('modals');
                resolve(key);
                Ember.$(document).keypress(function (e) {
                    if (e.keyCode === 27) {
                        self.close(key);
                    }
                });
            }).then(function () {
                if (self.get('modals.' + key + '.afterOpen')) {
                    self.get('modals.' + key + '.afterOpen').call();
                }
            });
        },
        close: function close(key) {
            var _this = this;

            var self = this;
            Ember.$(document).off('keypress');
            if (this.get('modals.' + key + '.beforeClose')) {
                this.get('modals.' + key + '.beforeClose').call();
            }
            this.set('modals.' + key + '.status', 1);
            this.notifyPropertyChange('modals');
            setTimeout(function () {
                _this.set('modals.' + key + '.status', 0);
                _this.notifyPropertyChange('modals');
                if (_this.get('modals.' + key + '.afterClose')) {
                    _this.get('modals.' + key + '.afterClose').call();
                }
                self.reset(key);
            }, 500);
        },
        closeAllModals: function closeAllModals() {
            var _this2 = this;

            var force = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            Object.keys(this.get('modals')).forEach(function (modal) {
                if (_this2.isOpen(modal) || force) {
                    _this2.close(modal);
                }
            });
        },
        saveInitiated: function saveInitiated() {
            var self = this;
            self.set('saveInProgress', true);
            self.notifyPropertyChange('saveInProgress');
        },
        saveCompleted: function saveCompleted() {
            var self = this;
            self.set('saveInProgress', false);
            self.notifyPropertyChange('saveInProgress');
        },
        saveAllCompleted: function saveAllCompleted() {
            serviceCache.forEach(function (service) {
                service.saveCompleted();
            });
        },
        forceClose: function forceClose() {
            serviceCache.forEach(function (service) {
                service.closeAllModals();
            });
        },
        runSave: function runSave(saveAction) {
            var self = this;
            self.saveInitiated();
            setTimeout(function () {
                var result = saveAction.call();
                if (result) {
                    self.saveCompleted();
                }
            }, 50);
        }
    });
});