define('matrix-frontend/constants', ['exports', 'matrix-frontend/utils/enum', 'matrix-frontend/utils/colors', 'react', 'matrix-frontend/utils/format-helpers', 'matrix-frontend/utils/payroll-worksheet'], function (exports, _enum, _colors, _react, _formatHelpers, _payrollWorksheet) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.ReportTypesOrdered = exports.ReportTypesKeyed = exports.ReportTypes = exports.ReportDataKeys = exports.ChecklistItemStatus = exports.ScheduleStatus = undefined;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var useColorBlindFriendly = function useColorBlindFriendly() {
        return window.localStorage.colorBlindFriendly == "1";
    };

    // darker shades of blue
    var DARKENING_BLUES = [[111, 199, 255], [80, 150, 233], [45, 93, 207], [12, 41, 184]];

    // standard yellow > orange > red scale
    var INTENSIFYING_HEAT = [[255, 221, 0], [255, 170, 0], [255, 85, 0], [255, 0, 0]];

    // darker shades of green
    var DARKENING_GREENS = [[0, 255, 116], [14, 204, 100], [13, 163, 81], [10, 128, 63]];

    // pulled from new PW hours over gradient
    // (doesn't look very good on map)
    var PALE_INTENSIFYING_HEAT = [[114, 179, 255], [75, 132, 214], [36, 86, 173], [0, 42, 135]];

    var positiveColorScale = function positiveColorScale(x, space) {
        // if(useColorBlindFriendly()) {
        //     return steppedColorFunc(x, space, DARKENING_GREENS)
        // }
        return (0, _colors.steppedColorFunc)(x, space, DARKENING_GREENS);
    };

    var negativeColorScale = function negativeColorScale(x, space) {
        if (useColorBlindFriendly()) {
            return (0, _colors.steppedColorFunc)(x, space, DARKENING_BLUES);
        }
        return (0, _colors.steppedColorFunc)(x, space, INTENSIFYING_HEAT);
    };

    var ScheduleStatus = exports.ScheduleStatus = new _enum.Enum({
        UNKNOWN: { value: -1, description: 'unknown' },
        HIDDEN: { value: 0, description: 'hidden' },
        EXECUTIVE: { value: 1, description: 'executive' },
        READONLY: { value: 2, description: 'readonly' },
        PRELIMINARY: { value: 3, description: 'preliminary' },
        ACTIVE: { value: 4, description: 'active' }
    });

    var ChecklistItemStatus = exports.ChecklistItemStatus = new _enum.Enum({
        COMPLETED: { value: 0, color: "#83f287" },
        INCOMPLETE: { value: 3, color: "#d0d0d0" },
        UPCOMING: { value: 4, color: "#e0e0e0" },
        LATE: { value: 1, color: "#803d09" },
        OVERDUE: { value: 2, color: "#de2828" }
    });

    var ReportDataKeys = exports.ReportDataKeys = {
        salesactual: 'sales',
        salesforecast: 'sales',
        salesscheduleforecast: 'sales',
        originalsalesforecast: 'sales',
        salesatrisk: 'at_risk',
        actuallabor: 'labor',
        optimallabor: 'labor'
    };

    var forecastText = function forecastText(info) {
        return info && info.anyNotActive ? 'Prelim Fcst' : 'Forecast';
    };

    var ReportTypes = exports.ReportTypes = {
        SALES_VS_FORECAST: {
            name: 'Sales vs Forecast',
            text: 'SvF',
            key: 'svf',
            gathers: [{
                key: 'actual',
                report: 'salesactual',
                endpoint: 'salesactual',
                weekEndDate: function weekEndDate(m) {
                    return m;
                },
                series: {
                    name: 'Actual',
                    type: 'area',
                    style: {
                        color: '#77b7d1'
                    }
                }
            }, {
                key: 'forecast',
                report: 'salesscheduleforecast',
                endpoint: 'forecasts',
                extraParams: {
                    forecast_type: 'schedule'
                },
                weekEndDate: function weekEndDate(m) {
                    return m;
                },
                seriesFunc: function seriesFunc(info) {
                    return {
                        name: forecastText(info),
                        type: 'line',
                        style: {
                            color: '#000',
                            dashed: true,
                            classes: 'italics'
                        }
                    };
                }
            }],
            useComps: true,
            displayValue: function displayValue(actual, forecast) {
                return actual !== undefined && forecast !== undefined ? actual - forecast : 0;
            },
            displayColor: function displayColor(relativeValue) {
                var mix = Math.abs(relativeValue);
                if (relativeValue > 0) {
                    var sMix = Math.min(mix * 10, 1);
                    var h = 140;
                    var s = sMix * 1.0 + (1 - sMix) * 0.3;

                    var _hsv2hsl = (0, _colors.hsv2hsl)(h / 360, s, 1),
                        _hsv2hsl2 = _slicedToArray(_hsv2hsl, 3),
                        fh = _hsv2hsl2[0],
                        fs = _hsv2hsl2[1],
                        fl = _hsv2hsl2[2];

                    var _hsv2hsl3 = (0, _colors.hsv2hsl)(h / 360, s, 0.8),
                        _hsv2hsl4 = _slicedToArray(_hsv2hsl3, 3),
                        bh = _hsv2hsl4[0],
                        bs = _hsv2hsl4[1],
                        bl = _hsv2hsl4[2];

                    return {
                        fill: 'hsl(' + h + ',' + fs * 100 + '%,' + fl * 100 + '%)',
                        border: 'hsl(' + h + ',' + bs * 100 + '%,' + bl * 100 + '%)'
                    };
                }
                if (relativeValue < 0) {
                    var _h = 0;
                    var _s = mix * 0.6 + (1 - mix) * 0.3;

                    var _hsv2hsl5 = (0, _colors.hsv2hsl)(_h / 360, _s, 1),
                        _hsv2hsl6 = _slicedToArray(_hsv2hsl5, 3),
                        _fh = _hsv2hsl6[0],
                        _fs = _hsv2hsl6[1],
                        _fl = _hsv2hsl6[2];

                    var _hsv2hsl7 = (0, _colors.hsv2hsl)(_h / 360, _s, 0.8),
                        _hsv2hsl8 = _slicedToArray(_hsv2hsl7, 3),
                        _bh = _hsv2hsl8[0],
                        _bs = _hsv2hsl8[1],
                        _bl = _hsv2hsl8[2];

                    return {
                        fill: 'hsl(' + _h + ',' + _fs * 100 + '%,' + _fl * 100 + '%)',
                        border: 'hsl(' + _h + ',' + _bs * 100 + '%,' + _bl * 100 + '%)'
                    };
                }
            },
            tooltipContentFor: function tooltipContentFor(data, view, info) {
                var a = view === 'detail' ? data.actual : data.compA;
                var b = view === 'detail' ? data.forecast : data.compB;
                return _react.default.createElement(
                    'div',
                    { className: 'flex-table flex-row-table' },
                    _react.default.createElement(
                        'div',
                        { className: 'column no-pad-left left' },
                        _react.default.createElement(
                            'span',
                            null,
                            'Actual'
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            forecastText(info)
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            'Var'
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            'Var %'
                        )
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'zero-flex-column more-pad-right right' },
                        _react.default.createElement(
                            'span',
                            null,
                            '$'
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            '$'
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            '$'
                        ),
                        _react.default.createElement(
                            'span',
                            { style: { visibility: 'hidden' } },
                            '%'
                        )
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'zero-flex-column no-pad-right right' },
                        _react.default.createElement(
                            'span',
                            null,
                            (0, _formatHelpers.numberFormat)(data.actual)
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            (0, _formatHelpers.numberFormat)(data.forecast)
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            (0, _formatHelpers.numberFormat)(a && b ? a - b : 0)
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            (0, _formatHelpers.percentFormat)(a && b ? a / b - 1 : 0)
                        )
                    )
                );
            },
            map: {
                value: function value(dp) {
                    if (dp.data && dp.data.compA != null && dp.data.compB != null) {
                        return dp.data.compA / dp.data.compB - 1;
                    }
                    return null;
                },
                radius: function radius(dp) {
                    if (dp.data && dp.data.compA != null && dp.data.compB != null) {
                        return Math.abs(dp.data.compA - dp.data.compB);
                    }
                    return null;
                },
                color: function color(rv) {
                    return rv > 0 ? positiveColorScale(rv, 0.15) : negativeColorScale(-rv, 0.15);
                }
            },
            views: ['map', 'summary', 'detail']
        },
        SALES_VS_LY: {
            name: 'Sales vs LY',
            text: 'SvLY',
            key: 'svly',
            gathers: [{
                key: 'actual',
                report: 'salesactual',
                endpoint: 'salesactual',
                weekEndDate: function weekEndDate(m) {
                    return m;
                },
                series: {
                    name: 'Actual',
                    type: 'area',
                    style: {
                        color: '#77b7d1'
                    }
                }
            }, {
                key: 'actualLY',
                report: 'salesactual',
                endpoint: 'salesactual',
                weekEndDate: function weekEndDate(m) {
                    return m.clone().subtract(52, 'weeks');
                },
                series: {
                    name: 'Actual LY',
                    type: 'line',
                    style: {
                        color: '#437370'
                    }
                }
            }],
            useComps: true,
            displayValue: function displayValue(actual, actualLY) {
                return actual !== undefined && actualLY !== undefined ? actual - actualLY : 0;
            },
            displayColor: function displayColor(relativeValue) {
                var mix = Math.abs(relativeValue);
                if (relativeValue > 0) {
                    var sMix = Math.min(mix * 10, 1);
                    var h = 140;
                    var s = sMix * 1.0 + (1 - sMix) * 0.3;

                    var _hsv2hsl9 = (0, _colors.hsv2hsl)(h / 360, s, 1),
                        _hsv2hsl10 = _slicedToArray(_hsv2hsl9, 3),
                        fh = _hsv2hsl10[0],
                        fs = _hsv2hsl10[1],
                        fl = _hsv2hsl10[2];

                    var _hsv2hsl11 = (0, _colors.hsv2hsl)(h / 360, s, 0.8),
                        _hsv2hsl12 = _slicedToArray(_hsv2hsl11, 3),
                        bh = _hsv2hsl12[0],
                        bs = _hsv2hsl12[1],
                        bl = _hsv2hsl12[2];

                    return {
                        fill: 'hsl(' + h + ',' + fs * 100 + '%,' + fl * 100 + '%)',
                        border: 'hsl(' + h + ',' + bs * 100 + '%,' + bl * 100 + '%)'
                    };
                }
                if (relativeValue < 0) {
                    var _h2 = 0;
                    var _s2 = mix * 0.6 + (1 - mix) * 0.3;

                    var _hsv2hsl13 = (0, _colors.hsv2hsl)(_h2 / 360, _s2, 1),
                        _hsv2hsl14 = _slicedToArray(_hsv2hsl13, 3),
                        _fh2 = _hsv2hsl14[0],
                        _fs2 = _hsv2hsl14[1],
                        _fl2 = _hsv2hsl14[2];

                    var _hsv2hsl15 = (0, _colors.hsv2hsl)(_h2 / 360, _s2, 0.8),
                        _hsv2hsl16 = _slicedToArray(_hsv2hsl15, 3),
                        _bh2 = _hsv2hsl16[0],
                        _bs2 = _hsv2hsl16[1],
                        _bl2 = _hsv2hsl16[2];

                    return {
                        fill: 'hsl(' + _h2 + ',' + _fs2 * 100 + '%,' + _fl2 * 100 + '%)',
                        border: 'hsl(' + _h2 + ',' + _bs2 * 100 + '%,' + _bl2 * 100 + '%)'
                    };
                }
            },
            tooltipContentFor: function tooltipContentFor(data, view) {
                var a = view === 'detail' ? data.actual : data.compA;
                var b = view === 'detail' ? data.actualLY : data.compB;
                return _react.default.createElement(
                    'div',
                    { className: 'flex-table flex-row-table' },
                    _react.default.createElement(
                        'div',
                        { className: 'column no-pad-left left' },
                        _react.default.createElement(
                            'span',
                            null,
                            'Actual'
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            'Actual LY'
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            'Var'
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            'Var %'
                        )
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'zero-flex-column more-pad-right right' },
                        _react.default.createElement(
                            'span',
                            null,
                            '$'
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            '$'
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            '$'
                        ),
                        _react.default.createElement(
                            'span',
                            { style: { visibility: 'hidden' } },
                            '%'
                        )
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'zero-flex-column no-pad-right right' },
                        _react.default.createElement(
                            'span',
                            null,
                            (0, _formatHelpers.numberFormat)(data.actual)
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            (0, _formatHelpers.numberFormat)(data.actualLY)
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            (0, _formatHelpers.numberFormat)(a && b ? a - b : 0)
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            (0, _formatHelpers.percentFormat)(a && b ? a / b - 1 : 0)
                        )
                    )
                );
            },
            map: {
                value: function value(dp) {
                    if (dp.data && dp.data.compA != null && dp.data.compB != null) {
                        return dp.data.compA / dp.data.compB - 1;
                    }
                    return null;
                },
                radius: function radius(dp) {
                    if (dp.data && dp.data.compA != null && dp.data.compB != null) {
                        return Math.abs(dp.data.compA - dp.data.compB);
                    }
                    return null;
                },
                color: function color(rv) {
                    return rv > 0 ? positiveColorScale(rv, 0.15) : negativeColorScale(-rv, 0.15);
                }
            },
            views: ['map', 'summary', 'detail']
        },
        SALES_AT_RISK: {
            name: 'Sales @ Risk',
            text: 'S@R',
            key: 'sar',
            gathers: [{
                key: 'salesatrisk',
                report: 'salesatrisk',
                endpoint: 'salesatrisk',
                weekEndDate: function weekEndDate(m) {
                    return m;
                },
                series: {
                    name: '@ Risk',
                    type: 'area',
                    style: {
                        color: '#f89406'
                    }
                }
            }, {
                key: 'forecast',
                report: 'salesscheduleforecast',
                endpoint: 'forecasts',
                extraParams: {
                    forecast_type: 'schedule'
                },
                weekEndDate: function weekEndDate(m) {
                    return m;
                },
                seriesFunc: function seriesFunc(info) {
                    return {
                        name: forecastText(info),
                        type: 'line',
                        style: {
                            color: '#000',
                            dashed: true,
                            classes: 'italics'
                        }
                    };
                }
            }],
            tooltipContentFor: function tooltipContentFor(data, view, info) {
                return _react.default.createElement(
                    'div',
                    { className: 'flex-table flex-row-table' },
                    _react.default.createElement(
                        'div',
                        { className: 'column no-pad-left left' },
                        _react.default.createElement(
                            'span',
                            null,
                            forecastText(info)
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            '@ Risk'
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            '@ Risk %'
                        )
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'zero-flex-column more-pad-right right' },
                        _react.default.createElement(
                            'span',
                            null,
                            '$'
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            '$'
                        ),
                        _react.default.createElement(
                            'span',
                            { style: { visibility: 'hidden' } },
                            '%'
                        )
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'zero-flex-column no-pad-right right' },
                        _react.default.createElement(
                            'span',
                            null,
                            (0, _formatHelpers.numberFormat)(data.forecast)
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            (0, _formatHelpers.numberFormat)(data.salesatrisk)
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            (0, _formatHelpers.percentFormat)(data.salesatrisk / data.forecast, { withSign: false })
                        )
                    )
                );
            },
            displayValue: function displayValue(sar) {
                return sar;
            },
            displayColor: function displayColor(relativeValue) {
                if (relativeValue > 0) {
                    var h = relativeValue * 33 + (1 - relativeValue) * 45;
                    var sMix = Math.min(relativeValue * 10, 1);
                    var s = sMix * 1.0 + (1 - sMix) * 0.3;

                    var _hsv2hsl17 = (0, _colors.hsv2hsl)(h, s, 1),
                        _hsv2hsl18 = _slicedToArray(_hsv2hsl17, 3),
                        fh = _hsv2hsl18[0],
                        fs = _hsv2hsl18[1],
                        fl = _hsv2hsl18[2];

                    var _hsv2hsl19 = (0, _colors.hsv2hsl)(h, s, 0.8),
                        _hsv2hsl20 = _slicedToArray(_hsv2hsl19, 3),
                        bh = _hsv2hsl20[0],
                        bs = _hsv2hsl20[1],
                        bl = _hsv2hsl20[2];

                    return {
                        fill: 'hsl(' + h + ',' + fs * 100 + '%,' + fl * 100 + '%)',
                        border: 'hsl(' + h + ',' + bs * 100 + '%,' + bl * 100 + '%)'
                    };
                }
            },
            map: {
                value: function value(dp) {
                    if (dp.data && dp.data.salesatrisk != null && dp.data.forecast != null) {
                        return dp.data.salesatrisk / dp.data.forecast;
                    }
                    return null;
                },
                radius: function radius(dp) {
                    if (dp.data && dp.data.salesatrisk != null && dp.data.forecast != null) {
                        return dp.data.salesatrisk;
                    }
                    return null;
                },
                color: function color(rv) {
                    return (0, _colors.steppedColorFunc)(rv, 0.1, [[255, 221, 0], [255, 170, 0], [255, 85, 0], [255, 0, 0]]);
                }
            },
            views: ['map', 'summary', 'detail']
            // storeLinkTo: 'schedule',
        },
        HOURS_VS_OPTIMAL: {
            name: 'Hours vs Optimal',
            text: 'HvO',
            key: 'hvo',
            gathers: [{
                key: 'actual',
                report: 'actuallabor',
                endpoint: 'actuallabor',
                weekEndDate: function weekEndDate(m) {
                    return m;
                },
                series: {
                    name: 'Actual',
                    type: 'area',
                    style: {
                        color: '#77b7d1'
                    }
                },
                dataTransform: function dataTransform(x) {
                    return x / 4;
                }
            }, {
                key: 'optimal',
                report: 'optimallabor',
                endpoint: 'optimallabor',
                weekEndDate: function weekEndDate(m) {
                    return m;
                },
                series: {
                    name: 'Optimal',
                    type: 'line',
                    style: {
                        color: '#000'
                    }
                },
                dataTransform: function dataTransform(x) {
                    return x / 4;
                }
            }],
            useComps: true,
            displayValue: function displayValue(actual, optimal) {
                return actual !== undefined && optimal !== undefined ? actual - optimal : 0;
            },
            displayColor: function displayColor(relativeValue) {
                var mix = Math.abs(relativeValue);
                if (relativeValue > 0) {
                    // return {
                    //     fill: hoursOverGradient.colorFor(mix, {format:'rgba', alpha: 0.8}),
                    //     border: 'rgba(0,0,0,10%)',
                    // }
                    var s = mix * 0.6 + (1 - mix) * 0.3;

                    var _hsv2hsl21 = (0, _colors.hsv2hsl)(0, s, 1),
                        _hsv2hsl22 = _slicedToArray(_hsv2hsl21, 3),
                        fh = _hsv2hsl22[0],
                        fs = _hsv2hsl22[1],
                        fl = _hsv2hsl22[2];

                    return {
                        fill: 'hsl(' + fh + ',' + fs * 100 + '%,' + fl * 100 + '%)',
                        border: 'rgba(0,0,0,20%)'
                    };
                }
                if (relativeValue < 0) {
                    var _s3 = mix * 0.6 + (1 - mix) * 0.3;

                    var _hsv2hsl23 = (0, _colors.hsv2hsl)(221, _s3, 1),
                        _hsv2hsl24 = _slicedToArray(_hsv2hsl23, 3),
                        _fh3 = _hsv2hsl24[0],
                        _fs3 = _hsv2hsl24[1],
                        _fl3 = _hsv2hsl24[2];

                    return {
                        fill: 'hsl(' + _fh3 + ',' + _fs3 * 100 + '%,' + _fl3 * 100 + '%)',
                        // fill: hoursUnderGradient.colorFor(mix, {format:'rgba', alpha: 0.8}),
                        border: 'rgba(0,0,0,20%)'
                    };
                }
            },
            tooltipContentFor: function tooltipContentFor(data, view) {
                var a = view === 'detail' ? data.actual : data.compA;
                var b = view === 'detail' ? data.optimal : data.compB;
                return _react.default.createElement(
                    'div',
                    { className: 'flex-table flex-row-table' },
                    _react.default.createElement(
                        'div',
                        { className: 'column no-pad-left left' },
                        _react.default.createElement(
                            'span',
                            null,
                            'Actual'
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            'Optimal'
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            'Var'
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            'Var %'
                        )
                    ),
                    _react.default.createElement(
                        'div',
                        { className: 'zero-flex-column no-pad-right right' },
                        _react.default.createElement(
                            'span',
                            null,
                            (0, _formatHelpers.numberFormat)(data.actual, { decimals: 2 })
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            (0, _formatHelpers.numberFormat)(data.optimal, { decimals: 2 })
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            (0, _formatHelpers.numberFormat)(a && b ? a - b : 0, { decimals: 2 })
                        ),
                        _react.default.createElement(
                            'span',
                            null,
                            (0, _formatHelpers.percentFormat)(a && b ? a / b - 1 : 0)
                        )
                    )
                );
            },
            map: {
                value: function value(dp) {
                    if (dp.data && dp.data.compA != null && dp.data.compB != null) {
                        return dp.data.compA / dp.data.compB - 1;
                    }
                    return null;
                },
                radius: function radius(dp) {
                    if (dp.data && dp.data.compA != null && dp.data.compB != null) {
                        return Math.abs(dp.data.compA - dp.data.compB);
                    }
                    return null;
                },
                color: function color(rv) {
                    return rv > 0 ? (0, _colors.steppedColorFunc)(rv, 0.15, INTENSIFYING_HEAT) : (0, _colors.steppedColorFunc)(-rv, 0.15, DARKENING_BLUES);
                }
            },
            views: ['map', 'summary', 'detail']
        }
    };

    var ReportTypesKeyed = exports.ReportTypesKeyed = {
        'svf': ReportTypes.SALES_VS_FORECAST,
        'sar': ReportTypes.SALES_AT_RISK,
        'svly': ReportTypes.SALES_VS_LY,
        'hvo': ReportTypes.HOURS_VS_OPTIMAL
    };

    var ReportTypesOrdered = exports.ReportTypesOrdered = [ReportTypes.SALES_VS_FORECAST, ReportTypes.SALES_VS_LY, ReportTypes.SALES_AT_RISK, ReportTypes.HOURS_VS_OPTIMAL];
});