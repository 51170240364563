define('matrix-frontend/reducers/_employees', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var employees = [{
        id: 1,
        lastName: 'Cruz',
        firstName: 'Keisha',
        position: 'SSL'
    }, {
        id: 2,
        lastName: 'Lucero',
        firstName: 'Rebecca',
        position: 'ASL'
    }, {
        id: 3,
        lastName: 'Baptista',
        firstName: 'Mercon',
        position: 'LSA'
    }, {
        id: 4,
        lastName: 'Diego-Carrido',
        firstName: 'Crist',
        position: 'LSA'
    }, {
        id: 5,
        lastName: 'Castillo',
        firstName: 'Marya',
        position: 'SA'
    }, {
        id: 6,
        lastName: 'Garcia',
        firstName: 'Armando',
        position: 'SA'
    }, {
        id: 7,
        lastName: 'Goldstrom',
        firstName: 'Cheyenne',
        position: 'SA'
    }, {
        id: 8,
        lastName: 'Hossain',
        firstName: 'Farhana',
        position: 'SA'
    }, {
        id: 9,
        lastName: 'Johnson',
        firstName: 'Samantha',
        position: 'SA'
    }, {
        id: 10,
        lastName: 'Lewis',
        firstName: 'Kiyani',
        position: 'SA'
    }, {
        id: 11,
        lastName: 'Lucus',
        firstName: 'Lindsay',
        position: 'SA'
    }, {
        id: 12,
        lastName: 'Montecillo',
        firstName: 'Mikhaila',
        position: 'SA'
    }, {
        id: 13,
        lastName: 'Sandra',
        firstName: 'Gianna',
        position: 'SA'
    }, {
        id: 14,
        lastName: 'Scroggin',
        firstName: 'Madison',
        position: 'SA'
    }, {
        id: 15,
        lastName: 'Thomad',
        firstName: 'Summer',
        position: 'SA'
    }, {
        id: 16,
        lastName: 'Ferrer',
        firstName: 'Daffney',
        position: 'SA'
    }];

    exports.default = employees;
});