define('matrix-frontend/pods/components/schedule-page-section-unassigned/component', ['exports', 'ember-redux', 'matrix-frontend/actions/_types', 'npm:moment', 'matrix-frontend/utils/interactions'], function (exports, _emberRedux, _types, _npmMoment, _interactions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var ROW_HEIGHT = 30;
    var MAX_ROWS_TO_DISPLAY = screen.height < 800 ? 3 : 5;
    var MIN_EMPLOYEES_TO_DISPLAY = 2.5;
    var MIN_ROWS_TO_DISPLAY = 2;

    var stateToComputed = function stateToComputed(state) {
        return {
            clients: state.client.clients,
            node: state.cube.node,
            date: state.cube.date,
            dotw: state.cube.dotw,
            weekEndDate: state.cube.weekEndDate,
            // currentBrush: state.scheduleEdit.currentBrush,
            // lastExpandedRow: state.scheduleEdit.lastExpandedRow,
            // expandedRow: state.scheduleEdit.expandedRow,
            smartAssign: state.scheduleEdit.smartAssign,
            smartAssignIgnoredShifts: state.scheduleEdit.smartAssignIgnoredShifts,
            assignableEmployeeIds: state.scheduleEdit.assignableEmployeeIds,
            availableEmployeeIds: state.scheduleEdit.availableEmployeeIds,
            skilledEmployeeIds: state.scheduleEdit.skilledEmployeeIds,
            smartAssignEmployee: state.scheduleEdit.smartAssignEmployee,
            shifts: state.scheduleEdit.shifts,
            shiftsByEmployee: state.scheduleEdit.shiftsByEmployee,
            editMode: state.scheduleEdit.editMode,
            employeeFilters: state.scheduleEdit.employeeFilters,
            employeesDB: state.employee,
            currentUser: state.user.currentUser
            // startLeft: state.scheduleEdit.startLeft,
            // startTop: state.scheduleEdit.startTop,
            // displayStart: state.scheduleEdit.displayStart,
            // displayEnd: state.scheduleEdit.displayEnd,
            // editMode: state.scheduleEdit.editMode,
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {
            unassignShift: function unassignShift(activeShift) {
                dispatch({
                    type: _types.SE_DO_SWAP,
                    payload: { employee: null, dotw: this.dotw, activeShift: activeShift }
                });
            },
            // deleteShift: function(activeShift) {
            //     dispatch({
            //         type: types.SE_SUNSET_SHIFT,
            //         payload: {shift: activeShift, currentUser: this.currentUser},
            //     })
            // },
            smartAssign: function smartAssign(mode) {
                dispatch({
                    type: _types.SE_SMART_ASSIGN,
                    payload: { mode: mode }
                });
                if (mode === 0) {
                    dispatch({
                        type: _types.SE_SET,
                        payload: { activeShift: null }
                    });
                }
            },
            // smartAssignSelect: (dotw, shift) => console.log(null, dotw, shift, 0)
            smartAssignSelect: function smartAssignSelect(dotw, shift) {
                dispatch({
                    type: _types.SE_SMART_ASSIGN,
                    payload: { mode: 2, select: true }
                });
                dispatch({
                    type: _types.SE_DO_SWAP,
                    payload: {
                        employee: null,
                        dotw: dotw,
                        activeShift: shift,
                        index: 0,
                        smart: true
                    }
                });
            },
            smartAssignIgnore: function smartAssignIgnore(shift) {
                dispatch({
                    type: _types.SE_SMART_ASSIGN_IGNORE,
                    payload: { shift: shift }
                });
                dispatch({
                    type: _types.SE_SET,
                    payload: { activeShift: null }
                });
                dispatch({
                    type: _types.SE_SMART_ASSIGN,
                    payload: { mode: 1, select: false }
                });
            },
            doSwap: function doSwap(employee, dotw, activeShift, index) {
                return dispatch({
                    type: _types.SE_DO_SWAP,
                    payload: { employee: employee, dotw: dotw, activeShift: activeShift, index: index, smart: true }
                });
            }
        };
    };

    var Comp = Ember.Component.extend({
        redux: Ember.inject.service(),
        modalService: Ember.inject.service(),
        broadcast: Ember.Object.create({}),
        resized: null,
        isResizing: false,
        currentResize: null,
        maxSize: 0,

        // activities: computed('currentClient', function() {
        //     const currentClient = this.get('currentClient')
        //     // return (currentClient && currentClient.activities) ? currentClient.activities.sort((x, y) => {
        //     //     if (y.activityPriority === 0) { return false; }
        //     //     return x.activityPriority > y.activityPriority
        //     // }) : null;
        //     return currentClient && currentClient.activities
        //         ? currentClient.activities.sortBy('activityPriority')
        //         : null
        // }),

        // currentClient: computed('clients', 'node', function() {
        //     const node = this.get('node')
        //     const clients = this.get('clients')
        //     return node && clients ? clients[node.clientCode] : null
        // }),

        // // minHeight: computed('maxRows', 'employees', 'isResizing', 'currentResize', function() {
        //     // TODO:
        //     // - reimplement some logic that incorporates the below calculations

        //     // const employees = this.get('employees')
        //     // const anchor = $('#schedule-edit-totals-row')
        //     // const numEmployees = employees ? employees.length : 0
        //     // const anchorBottom = (numEmployees + 1.75) * ROW_HEIGHT
        //     // // const anchorBottom = anchor.offset().top + anchor.height()
        //     // const maxRows = this.get('maxRows')
        //     // const calcHeight =
        //     //     Math.max(Math.min(maxRows, MAX_ROWS_TO_DISPLAY), 2) * ROW_HEIGHT
        //     // const height =
        //     //     numEmployees > 0 &&
        //     //     screen.height > 800 &&
        //     //     anchorBottom + calcHeight < screen.height
        //     //         ? anchorBottom
        //     //         : calcHeight

        //     // // console.group('height')
        //     // // console.log('screen.height', screen.height)
        //     // // console.log('numEmployees', numEmployees)
        //     // // console.log('anchor.height()', anchor.height())
        //     // // console.log('anchorBottom', anchorBottom)
        //     // // console.log('calcHeight', calcHeight)
        //     // // console.log('height', height)
        //     // // console.groupEnd('height')
        //     // // return height
        //     // return calcHeight

        //     const maxRows = this.get('maxRows')
        //     // if (!resized) {
        //     //     return Math.min(maxRows, MAX_ROWS_TO_DISPLAY) * ROW_HEIGHT
        //     // } else {
        //     //     return null
        //     // }
        //     // const resized = this.get('resized')
        //     // const isResizing = this.get('isResizing')
        //     // const currentResize = this.get('currentResize')
        //     const numRows = Math.min(maxRows, MAX_ROWS_TO_DISPLAY)
        //     console.log('numRows', numRows)
        //     const calculated = numRows * ROW_HEIGHT
        //     // return (isResizing && currentResize && currentResize !== resized) ? calculated : (isResizing && !currentResize) ? resized : calculated
        //     // return (isResizing && !currentResize)
        //     //     ? resized || calculated
        //     //     : (isResizing && currentResize)
        //     //     ? calculated
        //     //     : resized || calculated
        //     return calculated
        // }),
        height: Ember.computed('maxRows', 'resized', 'maxSize', 'employees', 'isResizing', 'currentResize', function () {
            var maxSize = this.get('maxSize');
            var maxRows = this.get('maxRows');
            var resized = this.get('resized');
            var isResizing = this.get('isResizing');
            var currentResize = this.get('currentResize');
            var numRows = Math.min(maxRows, MAX_ROWS_TO_DISPLAY);
            var calculated = numRows * ROW_HEIGHT;
            var needed = calculated + ROW_HEIGHT;
            var height = isResizing && !currentResize ? resized || calculated : isResizing && currentResize ? calculated : resized || calculated;
            return height < maxSize && (resized === null || needed < resized) ? maxSize : height;
        }),

        maxRows: Ember.computed('unassignedShifts', function () {
            var unassignedShifts = this.get('unassignedShifts');
            var maxUnassigned = Object.keys(unassignedShifts).map(function (x) {
                return unassignedShifts[x];
            }).reduce(function (x, y) {
                return Math.max(x, y.length);
            }, 0);
            return maxUnassigned + 1;
        }),

        numUnassigned: Ember.computed('unassignedShifts', function () {
            var unassignedShifts = this.get('unassignedShifts');
            return Object.keys(unassignedShifts).map(function (x) {
                return unassignedShifts[x];
            }).reduce(function (o, i) {
                return o += i.length;
            }, 0);
        }),

        smartAssignShift: Ember.computed('unassignedShifts', 'smartAssignIgnoredShifts', function () {
            // TODO:
            // - Add ignoredShifts
            // - If shift is in ignoredShifts ... then pass to next
            // - Is ignored if SE_SET_SMART_ASSIGN_EMPLOYEE came back empty
            var topShift = null;
            var unassignedShifts = this.get('unassignedShifts');
            var smartAssignIgnoredShifts = this.get('smartAssignIgnoredShifts');
            for (var x in unassignedShifts) {
                unassignedShifts[x].forEach(function (shift) {
                    if (!smartAssignIgnoredShifts.has(shift.meta.id)) {
                        if (!topShift) {
                            topShift = shift;
                        } else {
                            if (shift.meta.score > topShift.meta.score) {
                                topShift = shift;
                            }
                        }
                    }
                });
                // const shifts = unassignedShifts[x].sort((a, b) => b.meta.score - a.meta.score);
                // if (shifts) {
                //     console.log(topShift, shifts[0].meta.score)
                //     if (!topShift || shifts[0].meta.score > topShift.meta.score) {
                //         topShift = shifts[0];
                //     }
                // }
            }

            return topShift;
        }),

        smartAssignShiftDisplay: Ember.computed('smartAssignShift', function () {
            var smartAssignShift = this.get('smartAssignShift');
            if (smartAssignShift) {
                var blocks = smartAssignShift.blocks.sort(function (x, y) {
                    return y.start.isBefore(x.start);
                });
                var date = smartAssignShift.meta.date;
                var firstBlock = blocks[0].start;
                var lastBlock = blocks.slice(-1)[0].end;
                return date.format('dd') + ' ' + firstBlock.format('h:mma') + ' - ' + lastBlock.format('h:mma');
            }
            return null;
        }),

        unassignedShifts: Ember.computed('shiftsByEmployee.unassigned', 'smartAssign', 'activitiesById', 'date', 'hours', 'employeeFilters', 'noSchedule', function () {
            var activitiesById = this.get('activitiesById');
            var shiftsRaw = this.get('shifts');
            var smartAssign = this.get('smartAssign');
            var date = this.get('date');
            var employeeFilters = this.get('employeeFilters');
            var shifts = this.get('shiftsByEmployee.unassigned');

            var activities = Object.values(activitiesById);

            if (!activities || activities.length === 0 || !shifts || this.noSchedule) {
                return [];
            }

            var base = [].concat(_toConsumableArray(Array(7).keys())).map(function (x) {
                return x + 1;
            }).reduce(function (x, y) {
                x[y] = [];
                return x;
            }, {});

            var reduced = shifts.reduce(function (a, v) {
                var dotw = v.meta.dotw;
                if (!a[dotw]) {
                    a[dotw] = [];
                }

                var doPush = date ? v.meta.date.isSame(date, 'day') : true;
                if (doPush && v.blocks.some(function (y) {
                    return !employeeFilters.skill || y.activityId === employeeFilters.skill;
                }) && !v.meta.removed) {
                    a[dotw].push(v);
                }
                return a;
            }, base);

            // If SmartAssign is on, then order shifts by their priority score,
            // otherwise, sort them by lowest activity, and then start time
            for (var x in reduced) {
                if (smartAssign) {
                    reduced[x] = reduced[x].sort(function (x, y) {
                        return y.meta.score - x.meta.score;
                    });
                } else {
                    reduced[x] = reduced[x].sort(function (x, y) {
                        var xActivityPriority = Math.min.apply(Math, _toConsumableArray(x.blocks.reduce(function (lst, curr) {
                            var a = activitiesById[curr.activityId];
                            lst.push(a.activityPriority);
                            return lst;
                        }, [])));
                        var xFirstBlock = x.blocks.sort(function (a, b) {
                            return b.start.isBefore(a.start);
                        })[0];
                        var yActivityPriority = Math.min.apply(Math, _toConsumableArray(y.blocks.reduce(function (lst, curr) {
                            var a = activitiesById[curr.activityId];
                            lst.push(a.activityPriority);
                            return lst;
                        }, [])));
                        var yFirstBlock = y.blocks.sort(function (a, b) {
                            return b.start.isBefore(a.start);
                        })[0];
                        if (xFirstBlock) {
                            return xActivityPriority - yActivityPriority || xFirstBlock.start.diff(yFirstBlock.start, 'minutes');
                        }
                        return false;
                    });
                }
            }

            return reduced;
        }),

        didInsertElement: function didInsertElement() {
            var self = this;
            var anchor = Ember.$('#schedule-edit-totals-row');
            var element = Ember.$('.unassigned-wrapper');
            var employeeWrapper = Ember.$('#employee-wrapper');
            var maxHeight = screen.height - employeeWrapper.offset().top - MIN_EMPLOYEES_TO_DISPLAY * ROW_HEIGHT;
            var minHeight = MIN_ROWS_TO_DISPLAY * ROW_HEIGHT;
            // const startHeight = screen.height -
            //     employeeWrapper.offset().top - employeeWrapper.height()
            element.css({
                maxHeight: maxHeight,
                minHeight: minHeight
            });
            element.resizable({
                handles: 't',
                minWidth: element.width(),
                maxWidth: element.width(),
                minHeight: this.get('height'),
                resize: function resize() {
                    self.set('resized', element.height());
                    self.set('currentResize', element.height());
                },
                start: function start() {
                    self.set('isResizing', true);
                },
                stop: function stop() {
                    self.set('isResizing', false);
                    self.set('currentResize', null);
                }
            });
        },
        setElementHeight: function setElementHeight() {
            var element = Ember.$('.unassigned-wrapper');
            var scheduleEditTotalsRow = Ember.$('#schedule-edit-totals-row');
            var height = screen.height - scheduleEditTotalsRow.offset().top - scheduleEditTotalsRow.height() - 120;
            element.height(height);
            this.set('maxSize', height);
        },


        smartAssignObserver: Ember.observer('numUnassigned', function () {
            var numUnassigned = this.get('numUnassigned');
            var smartAssign = this.get('smartAssign');
            if (numUnassigned === 0 && smartAssign > 0) {
                this.get('redux').dispatch({
                    type: _types.SE_SMART_ASSIGN,
                    payload: { mode: 0 }
                });
            }
        }),
        actions: {
            displayModal: function displayModal(key) {
                var modalService = this.get('modalService');
                modalService.open(key);
            },
            broadcast: function broadcast() {
                var employeeIds = this.get('broadcast.to');
                var shiftId = this.get('activeShift.meta.id');
                (0, _interactions.setStore)(this.get('redux'));

                (0, _interactions.send)({
                    header: 'do',
                    body: {
                        service: 'store',
                        namespace: 'shift',
                        operation: 1,
                        include_channel: 0,
                        extra: ['broadcast', shiftId],
                        payload: {
                            employeeIds: employeeIds
                        }
                    }
                });
            }
        }
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});