define('matrix-frontend/models/employee-skill', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        employee: _emberData.default.belongsTo('store-employee'),
        activity: _emberData.default.belongsTo('activity'),
        skillLevel: _emberData.default.attr('number')
    });
});