define('matrix-frontend/pods/components/schedule-page-part-segment-paintable/component', ['exports', 'ember-redux', 'npm:moment'], function (exports, _emberRedux, _npmMoment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var stateToComputed = function stateToComputed(state) {
        return {
            clients: state.client.clients,
            node: state.cube.node,
            displayDate: state.cube.date,
            displayDOTW: state.cube.dotw,
            currentBrush: state.scheduleEdit.currentBrush,
            expandedRow: state.scheduleEdit.expandedRow
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {};
    };

    var Comp = Ember.Component.extend({
        date: Ember.computed('displayDOTW', 'displayDate', 'segmentDOTW', function () {
            var displayDOTW = this.get('displayDOTW');
            var displayDate = this.get('displayDate');
            var segmentDOTW = this.get('segmentDOTW');
            return displayDate.add(segmentDOTW - displayDOTW, 'days');
        }),

        t: Ember.computed(function () {
            var parts = this.get('segment').split('.');
            var t = this.get('date').clone();
            t.hour(parts[0]);
            t.minute(parts[1]);
            return t;
        }),

        isExpanded: Ember.computed('expandedRow', function () {
            var employee = this.get('employee');
            return this.get('expandedRow') === 'employee-' + employee.id;
        }),

        isPainting: Ember.computed('currentBrush.{startMoment,stopMoment}', function () {
            var currentBrush = this.get('currentBrush');
            return currentBrush.startMoment && !currentBrush.stopMoment;
        }),

        activityPaint: Ember.computed('shift', 'currentBrush.activityId', function () {
            var activitiesById = this.get('activitiesById');
            var shift = this.get('shift');
            var t = this.get('t');
            var activity = null;

            if (shift) {
                var block = shift.blocks.find(function (x) {
                    return t >= x.start && t < x.end;
                });

                if (block) {
                    var a = activitiesById[block.activityId];
                    if (a) {
                        activity = {
                            id: block.activityId,
                            livecolor: a.livecolor.id,
                            isCurrent: false
                        };
                    }
                }
            }

            // console.log('currentBrush', this.get('currentBrush.startMoment'))
            // if (this.get('expandedRow')) {
            if (this.get('isPainting')) {
                var currentBrush = this.get('currentBrush');
                var startMoment = currentBrush.startMoment,
                    paintMoment = currentBrush.paintMoment;


                if (t >= startMoment && t < paintMoment) {
                    var _a = activitiesById[currentBrush.activityId];
                    // console.log('a', a)
                    // console.log('currentBrush', currentBrush)
                    activity = {
                        id: currentBrush.activityId,
                        livecolor: _a.livecolor.id,
                        isCurrent: true
                    };
                }
            }
            // }
            // console.log('activity', activity)

            return activity;
        })
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});