define('matrix-frontend/pods/components/checklist-storeitem-modal/component', ['exports', 'ember-redux', 'matrix-frontend/utils/interactions', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/helpers', 'npm:moment', 'matrix-frontend/utils/date-helper'], function (exports, _emberRedux, _interactions, _types, _helpers, _npmMoment, _dateHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var stateToComputed = function stateToComputed(state) {
        return {
            currentUser: state.user.currentUser,
            weekEndDate: state.cube.weekEndDate,
            clientCode: state.cube.clientCode,
            clients: state.client.clients,
            item: state.checklist.modalItem,
            storeitem: state.checklist.modalStoreitem,
            editedStoreitem: state.checklist.editedStoreitem,
            offset: state.checklist.modalOffset
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {
        return {
            beforeOpen: function beforeOpen() {
                dispatch({
                    type: _types.TODO_EDIT_STOREITEM,
                    payload: {
                        edit: {}
                    }
                });
            },
            onOpen: function onOpen() {
                this.set('editing', null);
            },
            transitionTo: function transitionTo(route, extra) {
                this.saveService.doTransition(this.router.transitionTo(route, extra));
            },
            showSave: function showSave(doShow) {
                var action = doShow ? 'changeMade' : 'changeDone';
                this.modalService[action](this.modalName);
            },
            save: function save() {
                var _this = this;

                var self = this;
                // console.info(this.item, this.storeitem, this.editedStoreitem)

                var update = {
                    // store_code: this.storeitem.store_code,
                };

                var modified = {};

                if (this.editedStoreitem.completedAt !== undefined) {
                    update.set_done = this.editedStoreitem.completedAt != null;
                    modified.completed_at = this.editedStoreitem.completedAt;
                }
                if (this.editedStoreitem.status !== undefined && this.editedStoreitem.status != this.storeitem.status) {
                    modified.status = this.editedStoreitem.status;
                }

                if (this.editedStoreitem.schedule_units != null && this.editedStoreitem.schedule_units != this.storeitem.schedule_units) {
                    update.schedule_units = this.editedStoreitem.schedule_units;
                    modified.schedule_units = this.editedStoreitem.schedule_units;
                }
                if (this.editedStoreitem.schedule_hours != null && this.editedStoreitem.schedule_hours != this.storeitem.schedule_hours) {
                    update.schedule_hours = this.editedStoreitem.schedule_hours;
                    modified.schedule_hours = this.editedStoreitem.schedule_hours;
                }

                if (this.editedStoreitem.allocated_hours && Object.keys(this.editedStoreitem.allocated_hours).length > 0) {
                    update.allocated_hours = Object.keys(this.editedStoreitem.allocated_hours).map(function (k) {
                        return {
                            date: k,
                            hours: _this.editedStoreitem.allocated_hours[k]
                        };
                    });
                    modified.allocated_hours = _extends({}, this.storeitem.allocated_hours, this.editedStoreitem.allocated_hours);
                }

                var tag = 'store.' + this.storeitem.store_code;

                (0, _interactions.send)({
                    header: 'do',
                    body: {
                        service: 'client',
                        namespace: ['checklist', 'storeitems'],
                        operation: 1,
                        include_channel: 3,
                        channel_override: [this.clientCode, tag],
                        broadcast: false,
                        params: {
                            item_id: this.item.id
                        },
                        payload: {
                            update: update
                        }
                    }
                }, 'update storeitem', false, null, null, function (results) {
                    if (results.ok) {
                        self.notificationService.success();

                        dispatch({
                            type: _types.TODO_INTAKE_STOREITEMS,
                            payload: {
                                meta: {
                                    args: {
                                        client_code: self.clientCode,
                                        tag: tag,
                                        item_id: self.item.id
                                    }
                                },
                                results: [_extends({}, self.storeitem, modified)]
                            }
                        });

                        if (results.cached.taskhours) {
                            Object.keys(results.cached.taskhours).forEach(function (weekEndStr) {
                                dispatch({
                                    type: _types.NODE_INTAKE_REPORT_DATA,
                                    payload: {
                                        meta: {
                                            args: {
                                                client_code: self.clientCode,
                                                tag: tag,
                                                report_type: 'taskhours',
                                                focus: 'day',
                                                week_end_date: weekEndStr
                                            }
                                        },
                                        results: _defineProperty({}, tag, results.cached.taskhours[weekEndStr])
                                    }
                                });
                            });
                        }
                    } else {
                        self.notificationService.error();
                    }
                }, true);

                this.modalService.close(this.modalName);
                return true;
            }
        };
    };

    var Comp = Ember.Component.extend({
        redux: Ember.inject.service(),
        saveService: Ember.inject.service(),
        modalService: Ember.inject.service(),
        notificationService: Ember.inject.service(),
        modalName: 'checklist-storeitem-modal',

        editing: null,

        client: Ember.computed('clientCode', 'clients', function () {
            var client = this.clients[this.clientCode];
            return client ? client : null;
        }),

        task: Ember.computed('client', 'item', function () {
            var tasks = this.get('client.tasks');
            if (tasks == null || this.item == null) return null;
            return tasks[this.item.task.id];
        }),

        budget: Ember.computed('client', 'task', function () {
            var task = this.get('task');
            var activities = this.get('client.activities');
            var budgets = this.get('client.budgets');
            if (task == null || activities == null || budgets == null) return null;
            var activity = activities.find(function (x) {
                return x.id == task.activity.id;
            });
            if (!activity) return null;
            return budgets[activity.budget.id];
        }),

        safeWeekEndDate: Ember.computed('weekEndDate', 'item', 'storeitem', function () {
            var item = this.item;
            var storeitem = this.storeitem;
            // console.info({item, storeitem})
            if (item && storeitem) {
                var earliestWeekEnd = (0, _dateHelper.getClientWeekEndDate)((0, _helpers.coalesce)(storeitem.start_date_exception, item.start_date));
                var latestWeekEnd = (0, _dateHelper.getClientWeekEndDate)((0, _helpers.coalesce)(storeitem.due_date_exception, item.due_date));
                return _npmMoment.default.min(_npmMoment.default.max(this.weekEndDate, earliestWeekEnd), latestWeekEnd);
            }
            return this.weekEndDate;
        }),

        subheader: Ember.computed('storeitem', function () {
            if (this.storeitem == null) return '';
            return 'Store ' + this.storeitem.store_code;
        }),

        hasChange: Ember.computed('modalService.modals', function () {
            return this.modalService.hasChange(this.modalName);
        }),

        isValid: Ember.computed('modalService.modals', function () {
            return this.modalService.isValid(this.modalName);
        })
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});