define('matrix-frontend/middleware/schedule-edit', ['exports', 'matrix-frontend/actions/_types', 'matrix-frontend/services/notification-service', 'matrix-frontend/services/random-service', 'matrix-frontend/services/modal-service', 'matrix-frontend/utils/date-helper', 'npm:moment', 'matrix-frontend/utils/interactions', 'matrix-frontend/utils/backoff', 'matrix-frontend/utils/validation', 'matrix-frontend/utils/cleaners', 'matrix-frontend/actions/schedule-edit', 'matrix-frontend/utils/shift', 'npm:underscore'], function (exports, _types, _notificationService, _randomService, _modalService, _dateHelper, _npmMoment, _interactions, _backoff, _validation, _cleaners, _scheduleEdit, _shift, _npmUnderscore) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    // const REMOVE_ACTIVITY = 0;
    var WS_TIMEOUT = 3000;
    var CHART_DELAY = 0;
    var SYNC_DELAY = 1500;
    // const SYNC_DELAY = 0
    var HOURS_SPAN = 2;
    var DEFAULT_MAX_HOURS = 40;
    var DEFAULT_MAX_SHIFTS = 5;
    var REMOVE_ACTIVITY = -1;

    var filterHighest = function filterHighest(employees) {
        var highest = employees.reduce(function (o, i) {
            return i.score > o ? i.score : o;
        }, -9999);

        return employees.filter(function (x) {
            return x.score === highest;
        });
    };

    var doChartDraw = function doChartDraw(store) {
        return store.dispatch({ type: _types.SE_CHART_DRAW });
    };
    var triggerChartDraw = _npmUnderscore.default.debounce(doChartDraw, CHART_DELAY);

    var doCacheSchedule = function doCacheSchedule(store, extra, payload) {
        (0, _interactions.setStore)(store);
        var state = store.getState();
        if (state.scheduleEdit.isCaching === false) {
            store.dispatch({
                type: _types.SE_SET,
                payload: { isCaching: true, hasError: false }
            });
            payload.utcOffset = 0;
            payload.shifts = (0, _cleaners.demomentize)(payload.shifts);
            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'store',
                    namespace: 'schedule',
                    operation: 2,
                    include_channel: 2,
                    extra: extra,
                    payload: _extends({}, payload, {
                        sid: state.websocket.sid
                    }),
                    response_type: 'SE_INTAKE_PARTIAL'
                }
            });
        } else {
            console.log('ignoring');
        }
    };
    var triggerCacheSchedule = _npmUnderscore.default.debounce(doCacheSchedule, SYNC_DELAY);

    var validateShifts = function validateShifts(store) {
        // run after SE_COMPILE_BRUSH, SE_INTAKE, SE_SWAP_SHIFTS, SE_INTAKE_PARTIAL
        console.debug('RUNNING SHIFT VALIDATION');

        (0, _backoff.generalBackoff)(function () {
            var state = store.getState();
            var node = state.cube.node;

            var tag = node.clientCode + '.' + node.tag;
            var client = state.client.clients[node.clientCode];
            var storeNode = state.node.stores[tag];
            var weekEndDate = state.cube.weekEndDate;
            var clients = state.client.clients;

            if (!client) {
                return false;
            }

            var shiftRules = client.shiftRules;
            var shiftErrors = client.shiftErrors;

            var shifts = _extends({}, state.scheduleEdit.shifts);

            return state.client.clients && shifts && state.client.clients[node.clientCode] !== undefined && storeNode && weekEndDate && storeNode.weeks && storeNode.weeks[weekEndDate.format('YYYY-MM-DD')] && storeNode.weeks[weekEndDate.format('YYYY-MM-DD')].employees && shiftRules && shiftErrors && storeNode.info && client && client.activities;
        }, function () {
            (0, _validation.doShiftValidation)(store);
        }, 'shift validation');
    };

    var calculateSAR = function calculateSAR(store) {
        // run after SE_COMPILE_BRUSH, SE_INTAKE, SE_SWAP_SHIFTS, SE_INTAKE_PARTIAL
        var state = store.getState();
        if (state.cube.weekEndDate) {
            var weekEndDate = state.cube.weekEndDate.format('YYYY-MM-DD');
            var date = state.cube.date;
            var node = state.cube.node;
            var clients = state.client.clients;
            var stores = state.node.stores;
            var shifts = state.scheduleEdit.shifts;

            if (node) {
                var currentClient = clients[node.clientCode];
                var currentStore = stores[node.clientCode + '.' + node.tag];

                if (currentStore && currentClient && currentClient.activities) {
                    var weeklyData = currentStore.weeks[weekEndDate];

                    if (weeklyData && weeklyData.optimalLabor && weeklyData.salesForecast) {
                        var activities = currentClient.activities.reduce(function (a, v) {
                            a[v.id] = v;
                            return a;
                        }, {});

                        var shiftsData = {};
                        var atRiskData = {};

                        // Prepare shifts data
                        Object.keys(shifts).map(function (x) {
                            return shifts[x];
                        })
                        // .filter(x => moment(x.meta.date).isSame(date, 'day') || date === null)
                        .forEach(function (shift) {
                            if (shift.meta.employeeId) {
                                shift.blocks.forEach(function (block) {
                                    var activityId = block.activityId;

                                    var activity = activities[activityId];

                                    if (activity.isScheduled && !activity.isCallin) {
                                        var currentMoment = (0, _npmMoment.default)(block.start).clone();
                                        while (currentMoment.isBefore((0, _npmMoment.default)(block.end), 'minutes')) {
                                            // const currentHour = currentMoment.clone().minutes(0);
                                            var dotw = (0, _dateHelper.getDayOfClientWeek)(currentMoment) + 1;
                                            var hours = currentMoment.hours();

                                            if (shiftsData[dotw] === undefined) {
                                                shiftsData[dotw] = {};
                                            }
                                            if (shiftsData[dotw][hours] === undefined) {
                                                shiftsData[dotw][hours] = 0;
                                            }

                                            shiftsData[dotw][hours] += 0.25;
                                            currentMoment.add(15, 'minutes');
                                        }
                                    }
                                });
                            }
                        });

                        // NOTE:
                        // - This was calculating based on optimalLabor
                        //   so if there was no optimalLabor, there was no data
                        //   displaying on the Schedule Edit page
                        // Object.keys(weeklyData.optimalLabor).forEach(dotw => {
                        Array(7).fill().forEach(function (_, dotw) {
                            dotw += 1;
                            var dotwData = weeklyData.optimalLabor[dotw];
                            if (atRiskData[dotw] === undefined) {
                                atRiskData[dotw] = {};
                            }

                            // Object.keys(dotwData).forEach(hours => {
                            Array(24).fill().forEach(function (_, hours) {
                                if (atRiskData[dotw][hours] === undefined) {
                                    atRiskData[dotw][hours] = {};
                                }

                                var salesTarget = weeklyData.salesForecast ? weeklyData.salesForecast[dotw - 1] ? weeklyData.salesForecast[dotw - 1].sales[hours] ? weeklyData.salesForecast[dotw - 1].sales[hours]
                                //   .reduce(
                                //       (o, i) => o + i,
                                //       0,
                                //   ) / 4
                                : 0 : 0 : 0;
                                var optimalLabor = dotwData ? Object.keys(dotwData[hours]).reduce(function (o, i) {
                                    return o + dotwData[hours][i].value / 4;
                                }, 0) : 0;
                                var scheduledHours = shiftsData && shiftsData[dotw] ? shiftsData[dotw][hours] || 0 : 0;
                                var underscheduledHours = Math.max(optimalLabor - scheduledHours, 0);
                                var underscheduledPercent = optimalLabor > 0 ? underscheduledHours / optimalLabor * 100 : 0;
                                var atRisk = salesTarget * underscheduledPercent / 100;

                                atRiskData[dotw][hours] = {
                                    salesTarget: salesTarget,
                                    optimalLabor: optimalLabor,
                                    underscheduledHours: underscheduledHours,
                                    underscheduledPercent: underscheduledPercent,
                                    atRisk: atRisk
                                };
                            });
                        });

                        store.dispatch({
                            type: _types.SE_SET_SAR,
                            payload: { atRiskData: atRiskData }
                        });
                    }
                }
            }
        }
        // console.log('calculateSAR done')
    };

    var beforeListeners = {};
    var afterListeners = {};

    beforeListeners[_types.SE_EMAIL_SCHEDULES] = function (store, payload) {
        var employeeIdsToEmail = payload.employeeIdsToEmail;


        (0, _interactions.send)({
            header: 'do',
            body: {
                service: 'store',
                namespace: ['schedule', 'emailer'],
                operation: 1,
                include_channel: 2,
                payload: {
                    employeeIdsToEmail: employeeIdsToEmail
                }
                // response_type: 'SUCCESS',
            }
        }, 'EE schedule emailer', false, function (results) {
            // console.log(results)
        });
    };

    beforeListeners[_types.MODAL_SAVE_COMPLETE] = function (store, payload) {
        var service = new _modalService.default();
        service.saveAllCompleted();
    };

    beforeListeners[_types.SE_TOGGLE_EMPLOYEE_FILTER] = function (store, payload) {
        var currentFilters = store.getState().scheduleEdit.employeeFilters;
        var hasFilter = Object.values(currentFilters).any(function (x) {
            return x > 0;
        });
        if (hasFilter) {
            store.dispatch({
                type: _types.SE_SET_EMPLOYEE_FILTER,
                payload: {
                    type: 'skill',
                    value: 0
                }
            });
            store.dispatch({
                type: _types.SE_SET_EMPLOYEE_FILTER,
                payload: {
                    type: 'position',
                    value: 0
                }
            });
            return false;
        }
    };
    beforeListeners[_types.SE_SCHEDULE_SAVE] = function (store, payload) {
        var shiftValidation = (0, _validation.doShiftValidation)(store);
        var shifts = payload.shifts,
            scheduleId = payload.scheduleId,
            utcOffset = payload.utcOffset,
            flagTotals = payload.flagTotals,
            status = payload.status,
            published = payload.published,
            weekEndDate = payload.weekEndDate;

        console.group('TRYING TO SAVE');
        console.debug('status', status);
        console.debug('shiftValidation', shiftValidation);
        // TODO:
        // - Check availability conflicts

        if ((published || status === 3) && shiftValidation[1] > 0) {
            var service = new _notificationService.default();
            service.error('Cannot save. Resolve all red flags.');
        } else {
            store.dispatch({
                type: _types.SE_SET,
                payload: {
                    isSaving: true,
                    savingText: 'Saving',
                    saveMode: false
                }
            });
            store.dispatch({
                type: _types.WS_SEND,
                payload: {
                    header: 'do',
                    body: {
                        service: 'store',
                        namespace: 'schedule',
                        operation: 3,
                        include_channel: 2,
                        payload: { shifts: shifts, scheduleId: scheduleId, utcOffset: utcOffset, flagTotals: flagTotals },
                        extra: [weekEndDate.format('YYYY-MM-DD')],
                        // broadcast: true,
                        // response_type: 'SE_SET_RESULTS'
                        response_type: 'SE_INTAKE_PARTIAL'
                    }
                }
            });
            // notificationService.info("Saving")
        }
        console.groupEnd('TRYING TO SAVE');
    };
    beforeListeners[_types.SE_GO_TO_DAY] = function (store, payload) {
        var _store$getState = store.getState(),
            _store$getState$sched = _store$getState.scheduleEdit,
            shifts = _store$getState$sched.shifts,
            scheduleId = _store$getState$sched.scheduleId;

        var _store$getState2 = store.getState(),
            _store$getState2$cube = _store$getState2.cube,
            weekEndDate = _store$getState2$cube.weekEndDate,
            date = _store$getState2$cube.date,
            node = _store$getState2$cube.node;

        var _store$getState3 = store.getState(),
            stores = _store$getState3.node.stores;

        var displayStart = void 0;
        var displayEnd = void 0;
        var closedAllDay = void 0;

        var payloadDate = payload.date;
        var newDate = store.getState().cube.date;

        var currentStore = stores[node.clientCode + '.' + node.tag];
        var dotw = (0, _dateHelper.getDayOfClientWeek)(newDate) + 1;
        var wed = weekEndDate.format('YYYY-MM-DD');

        if (newDate) {
            displayStart = (0, _npmMoment.default)(newDate).hours(8);
            displayEnd = (0, _npmMoment.default)(newDate).hours(20);
        } else {
            displayStart = (0, _dateHelper.getClientWeekStartDate)((0, _npmMoment.default)(weekEndDate));
            displayEnd = (0, _dateHelper.getClientWeekEndDate)((0, _npmMoment.default)(weekEndDate)).hours(24).minutes(0).seconds(0);
            // displayStart = moment(weekEndDate)
            //     .startOf('week')
            //     .hours(0)
            //     .minutes(0)
            //     .seconds(0)
            // displayEnd = moment(weekEndDate)
            //     .endOf('week')
            //     .hours(24)
            //     .minutes(0)
            //     .seconds(0)
        }
        closedAllDay = false;

        if (currentStore.weeks[wed] && currentStore.weeks[wed].hours) {
            var hours = currentStore.weeks[wed].hours[dotw];
            if (hours) {
                closedAllDay = hours.closedAllDay === 'Y';

                if (!closedAllDay) {
                    if (hours && payloadDate) {
                        var privateOpen = (0, _dateHelper.offsetTime)(newDate, hours.privateOpen, hours.privateOpenOffset);
                        var privateClose = (0, _dateHelper.offsetTime)(newDate, hours.privateClose, hours.privateCloseOffset);

                        displayStart = privateOpen.subtract(HOURS_SPAN, 'hours').startOf('hour');
                        displayEnd = privateClose.add(HOURS_SPAN, 'hours').endOf('hour');
                    }
                }
            }
        }

        return {
            displayStart: displayStart,
            displayEnd: displayEnd,
            closedAllDay: closedAllDay
        };
    };
    beforeListeners[_types.SE_COMPILE_BRUSH] = function (store, payload) {
        store.dispatch({
            type: _types.SE_SET,
            payload: { inProcess: true }
        });
    };
    beforeListeners[_types.SE_DO_SWAP] = function (store, payload) {
        var _store$getState4 = store.getState(),
            currentUser = _store$getState4.user.currentUser;

        var _store$getState5 = store.getState(),
            _store$getState5$sche = _store$getState5.scheduleEdit,
            shifts = _store$getState5$sche.shifts,
            scheduleId = _store$getState5$sche.scheduleId;

        var _store$getState6 = store.getState(),
            _store$getState6$cube = _store$getState6.cube,
            weekEndDate = _store$getState6$cube.weekEndDate,
            date = _store$getState6$cube.date,
            node = _store$getState6$cube.node;

        var _store$getState7 = store.getState(),
            stores = _store$getState7.node.stores;

        var _store$getState$sched2 = store.getState().scheduleEdit,
            currentActiveShift = _store$getState$sched2.activeShift,
            smartAssign = _store$getState$sched2.smartAssign;
        var activeShift = payload.activeShift,
            employee = payload.employee,
            index = payload.index,
            shift = payload.shift;

        var shiftsArr = Object.keys(shifts).map(function (x) {
            return shifts[x];
        });
        var toEmployeeShift = employee && activeShift ? shiftsArr.find(function (x) {
            return x.meta.employeeId === employee.id && (0, _npmMoment.default)(x.meta.date).isSame((0, _npmMoment.default)(activeShift.meta.date), 'day');
        }) : null;
        var fromRecord = currentActiveShift ? currentActiveShift : null;
        var toRecord = toEmployeeShift ? toEmployeeShift : index && activeShift ? activeShift : null;

        if (!activeShift || activeShift === currentActiveShift) {
            activeShift = null;
        }

        if (smartAssign === 1) {
            store.dispatch({
                type: _types.SE_SMART_ASSIGN,
                payload: { mode: 2 }
            });
        }

        store.dispatch({
            type: _types.SE_SET_ACTIVE_SHIFT,
            payload: { activeShift: activeShift }
        });

        if (currentActiveShift) {
            store.dispatch({
                type: _types.SE_SWAP_SHIFTS,
                payload: {
                    fromRecord: fromRecord,
                    toRecord: toRecord,
                    employee: employee,
                    currentUser: currentUser
                }
            });

            store.dispatch({
                type: _types.SE_SET,
                payload: {
                    inProcess: true,
                    smartAssignSelect: false
                }
            });
        }
    };
    beforeListeners[_types.SE_INTAKE] = function (store, payload) {
        store.dispatch({
            type: _types.SE_INTAKE_PARTIAL,
            payload: payload
        });
    };
    beforeListeners[_types.SE_INTAKE_PARTIAL] = function (store, payload) {
        var currentWeekEndDate = store.getState().cube.weekEndDate;
        var extra = payload.meta.extra;

        if (extra) {
            var _extra = _slicedToArray(extra, 1),
                date = _extra[0];

            var purportedWeeEndDate = (0, _npmMoment.default)(date);
            if (!purportedWeeEndDate.isSame(currentWeekEndDate, 'day')) {
                console.error('Attempted INTAKE of wrong week');
                // console.log(
                //     purportedWeeEndDate.format('YYYY-MM-DD'),
                //     currentWeekEndDate.format('YYYY-MM-DD'),
                // )
                (0, _scheduleEdit.scheduleEditReload)(store);
                return false;
            }
        }
        if (store.getState().scheduleEdit.isSaving && store.getState().scheduleEdit.savingText !== 'Discarding' && payload.results.is_saving === false && payload.results.ok === true) {
            var service = new _notificationService.default();
            service.saved();
        }
    };
    beforeListeners[_types.SE_START_PAINTING] = function (store, payload) {
        var _store$getState8 = store.getState(),
            _store$getState8$sche = _store$getState8.scheduleEdit,
            shifts = _store$getState8$sche.shifts,
            scheduleId = _store$getState8$sche.scheduleId;

        var _store$getState9 = store.getState(),
            _store$getState9$cube = _store$getState9.cube,
            weekEndDate = _store$getState9$cube.weekEndDate,
            date = _store$getState9$cube.date,
            node = _store$getState9$cube.node;

        var _store$getState10 = store.getState(),
            stores = _store$getState10.node.stores;

        var target = payload.mouseState.target || payload.mouseState.touches[0].target;
        var id = target.id;
        var parts = id.split('-');
        var rowId = 'employee-' + parts[1];
        var expandedRow = store.getState().scheduleEdit.expandedRow;

        if (!expandedRow || rowId !== expandedRow) {
            store.dispatch({
                type: _types.SE_EXPAND_ROW,
                rowId: rowId
            });
        }
    };

    beforeListeners[_types.SE_RELOAD] = function (store, payload) {
        (0, _scheduleEdit.scheduleEditReload)(store);
        return false;
    };
    afterListeners[_types.SE_START_PAINTING] = function (store, payload) {
        var _store$getState11 = store.getState(),
            currentUser = _store$getState11.user.currentUser;

        var _store$getState12 = store.getState(),
            _store$getState12$sch = _store$getState12.scheduleEdit,
            shifts = _store$getState12$sch.shifts,
            scheduleId = _store$getState12$sch.scheduleId;

        var _store$getState13 = store.getState(),
            _store$getState13$cub = _store$getState13.cube,
            weekEndDate = _store$getState13$cub.weekEndDate,
            date = _store$getState13$cub.date,
            node = _store$getState13$cub.node;

        var _store$getState14 = store.getState(),
            stores = _store$getState14.node.stores;

        var startState = store.getState().scheduleEdit.currentBrush.startState;

        if (startState) {
            var ctrlKey = startState.ctrlKey,
                shiftKey = startState.shiftKey,
                altKey = startState.altKey;

            if (ctrlKey || altKey) {
                store.dispatch({
                    type: _types.SE_PAINTING,
                    payload: { mouseState: startState, date: date }
                });
                store.dispatch({
                    type: _types.SE_STOP_PAINTING,
                    payload: { mouseState: startState, date: date }
                });
                store.dispatch({
                    type: _types.SE_COMPILE_BRUSH,
                    payload: { date: date, currentUser: currentUser }
                });
            }
        }
    };
    afterListeners[_types.SE_COMPILE_BRUSH] = function (store, payload) {
        var _store$getState15 = store.getState(),
            _store$getState15$sch = _store$getState15.scheduleEdit,
            shifts = _store$getState15$sch.shifts,
            scheduleId = _store$getState15$sch.scheduleId,
            _store$getState15$sch2 = _store$getState15$sch.currentBrush,
            activityId = _store$getState15$sch2.activityId,
            activityHolder = _store$getState15$sch2.activityHolder;

        var _store$getState16 = store.getState(),
            _store$getState16$cub = _store$getState16.cube,
            weekEndDate = _store$getState16$cub.weekEndDate,
            date = _store$getState16$cub.date,
            node = _store$getState16$cub.node;

        var _store$getState17 = store.getState(),
            stores = _store$getState17.node.stores;

        store.dispatch({ type: _types.SE_COLLAPSE });

        triggerChartDraw(store);
        triggerCacheSchedule(store, [weekEndDate.format('YYYY-MM-DD')], {
            scheduleId: scheduleId,
            shifts: store.getState().scheduleEdit.shifts
        });
        validateShifts(store);
        calculateSAR(store);

        if (activityId === REMOVE_ACTIVITY && activityHolder) {
            store.dispatch({
                type: _types.SE_SET_PAINTBRUSH,
                payload: {
                    activityId: activityHolder
                }
            });
        }
    };
    afterListeners[_types.SE_DO_SWAP] = function (store, payload) {
        (0, _interactions.setStore)(store);

        var _store$getState18 = store.getState(),
            _store$getState18$sch = _store$getState18.scheduleEdit,
            shifts = _store$getState18$sch.shifts,
            scheduleId = _store$getState18$sch.scheduleId;

        var _store$getState19 = store.getState(),
            _store$getState19$cub = _store$getState19.cube,
            weekEndDate = _store$getState19$cub.weekEndDate,
            date = _store$getState19$cub.date,
            node = _store$getState19$cub.node;

        var _store$getState20 = store.getState(),
            stores = _store$getState20.node.stores;

        var _store$getState$sched3 = store.getState().scheduleEdit,
            shiftSwap = _store$getState$sched3.shiftSwap,
            smartAssign = _store$getState$sched3.smartAssign;
        var smart = payload.smart;

        if (shiftSwap) {
            store.dispatch({
                type: _types.SE_SET_ACTIVE_SHIFT,
                payload: { activeShift: null }
            });
            store.dispatch({
                type: _types.SE_SET_ASSISNABLE_EMPLOYEE_IDS,
                payload: { employees: null }
            });
            store.dispatch({
                type: _types.SE_SET_AVAILABLE_EMPLOYEE_IDS,
                payload: { employees: null }
            });
            store.dispatch({
                type: _types.SE_SET_SKILLED_EMPLOYEE_IDS,
                payload: { employees: null }
            });

            if (smartAssign > 0) {
                var mode = smart ? 1 : 0;
                store.dispatch({
                    type: _types.SE_SMART_ASSIGN,
                    payload: { mode: 1 }
                });
                if (mode === 0) {
                    store.dispatch({
                        type: _types.SE_SET,
                        payload: { smartAssignIgnoredShifts: new Set() }
                    });
                }
            }

            triggerChartDraw(store);
            validateShifts(store);
            triggerCacheSchedule(store, [weekEndDate.format('YYYY-MM-DD')], {
                scheduleId: scheduleId,
                shifts: store.getState().scheduleEdit.shifts
            });
        }
        document.getSelection().removeAllRanges();
    };
    afterListeners[_types.SE_FLASH_SHIFTS] = function (store, payload) {
        (0, _interactions.setStore)(store);

        var _store$getState21 = store.getState(),
            scheduleId = _store$getState21.scheduleEdit.scheduleId;

        var _store$getState22 = store.getState(),
            weekEndDate = _store$getState22.cube.weekEndDate;

        triggerChartDraw(store);
        triggerCacheSchedule(store, [weekEndDate.format('YYYY-MM-DD')], {
            scheduleId: scheduleId,
            shifts: store.getState().scheduleEdit.shifts
        });
        validateShifts(store);
        calculateSAR(store);
    };
    afterListeners[_types.SE_GO_TO_DAY] = function (store, payload) {
        triggerChartDraw(store);
    };
    afterListeners[_types.SE_INTAKE] = function (store, payload) {
        var _store$getState23 = store.getState(),
            _store$getState23$sch = _store$getState23.scheduleEdit,
            shifts = _store$getState23$sch.shifts,
            scheduleId = _store$getState23$sch.scheduleId;

        var _store$getState24 = store.getState(),
            _store$getState24$cub = _store$getState24.cube,
            weekEndDate = _store$getState24$cub.weekEndDate,
            date = _store$getState24$cub.date,
            node = _store$getState24$cub.node,
            deferDOTW = _store$getState24$cub.deferDOTW;

        var _store$getState25 = store.getState(),
            stores = _store$getState25.node.stores;

        var _store$getState26 = store.getState(),
            sid = _store$getState26.websocket.sid;

        if (payload.results) {
            var can_edit = payload.results.can_edit;


            if (can_edit) {
                store.dispatch({
                    type: _types.SE_TOGGLE_EDIT_MODE_COMPLETE,
                    payload: { force: true }
                });
            }
            triggerChartDraw(store);
        }
        // validateShifts(store);
        // calculateSAR(store);

        // console.log('after SE_INTAKE', deferDOTW)
        // if (deferDOTW) {
        //     console.log('DO deferDOTW:', deferDOTW)
        //     store.dispatch({type: types.CUBE_GO_TO_DAY, payload: {dotw: deferDOTW}});
        // }
    };
    afterListeners[_types.SE_INTAKE_PARTIAL] = function (store, payload) {
        if (payload.results && 'shifts' in payload.results) {
            triggerChartDraw(store);
            validateShifts(store);
            calculateSAR(store);
        }
    };
    afterListeners[_types.SE_SET_ACTIVE_SHIFT] = function (store, payload) {
        var _store$getState27 = store.getState(),
            activeShift = _store$getState27.scheduleEdit.activeShift;

        if (activeShift) {
            // console.time('SE_SET_ACTIVE_SHIFT')
            var employeesDB = store.getState().employee;

            var _store$getState28 = store.getState(),
                _store$getState28$sch = _store$getState28.scheduleEdit,
                shifts = _store$getState28$sch.shifts,
                scheduleId = _store$getState28$sch.scheduleId,
                smartAssignSelect = _store$getState28$sch.smartAssignSelect;

            var _store$getState29 = store.getState(),
                _store$getState29$cub = _store$getState29.cube,
                weekEndDate = _store$getState29$cub.weekEndDate,
                date = _store$getState29$cub.date,
                node = _store$getState29$cub.node;

            var _store$getState30 = store.getState(),
                stores = _store$getState30.node.stores;

            // const {node: {stores}} = store.getState();
            var activityWeights = activeShift.blocks.reduce(function (o, i) {
                var activityId = parseInt(i.activityId);
                if (!o[activityId]) {
                    o[activityId] = 0;
                }

                var instant = (0, _npmMoment.default)(i.start);
                var end = (0, _npmMoment.default)(i.end);
                while (instant.isBefore(end)) {
                    o[activityId] += 1;
                    instant.add(15, 'minutes');
                }
                return o;
            }, {});
            var activeShiftLength = activeShift.blocks.reduce(function (o, i) {
                return o + (0, _npmMoment.default)(i.end).diff((0, _npmMoment.default)(i.start), 'hours', true);
            }, 0);
            var currentNode = stores[node.clientCode + '.' + node.tag];
            var nodeWeek = currentNode.weeks[(0, _npmMoment.default)(weekEndDate).format('YYYY-MM-DD')];
            var shiftsArr = Object.keys(shifts).map(function (x) {
                return shifts[x];
            }).filter(function (x) {
                return x.meta.employeeId && x.meta.date.isSame(activeShift.meta.date, 'day');
            });
            var currentStoreCode = currentNode.code;

            // SMART ASSIGN
            if (nodeWeek) {
                var employees = nodeWeek.employees.map(function (ee) {
                    return _extends({}, employeesDB[ee.id], ee, {
                        score: 0
                    });
                });

                /*
                KNOCKOUT CRITERIA
                    - EE must be available during the entire shift
                    - EE must have skill > 0 for each activity in the shift
                    - EE must not have 5 or more shifts
                    - EE’s hours scheduled + shift hours must be less than EE’s max weekly hours
                        ** NOTE:  this is NOT a knockout for assigning manually using the "click-and-click" function
                    - EE must not already be working that day
                        ** NOTE:  this only applies for selection and should not be included in determining the available employees (for swaps)
                    EE must have no other shifts 12 hours prior to beginning of shift or 12 hours after end of shift
                        ** NOTE:  this is NOT a knockout for assigning manually using the "click-and-click" function
                        ** NOTE:  this only applies for selection and should not be included in determining the available employees (for swaps)
                 EMPLOYEE RANKING
                    - ROUND 1. Pick EE with highest weighted-average skill (weighting based on activities in the shift)
                    - ROUND 2. Pick EE with highest number of hrs remaining to reach EE min hrs for the week  (i.e., [EE min hrs] - [EE assigned hrs] )
                    - ROUND 3. EE tenure
                    - ROUND 4. Random seeding
                */
                var dotw = (0, _dateHelper.getDayOfClientWeek)(activeShift.meta.date) + 1;

                // KNOCKOUT 1. EE must be available during the entire shift
                // console.time(
                //     'KNOCKOUT 1. EE must be available during the entire shift',
                // )
                if (employees.length > 0) {
                    employees = employees.filter(function (ee) {
                        // return Boolean(Math.round(Math.random()))
                        if (ee.terminationDate) {
                            if (activeShift.meta.date.isAfter(ee.terminationDate, 'day')) {
                                return false;
                            }
                        }
                        if (ee.startDate) {
                            if (activeShift.meta.date.isBefore(ee.startDate, 'day')) {
                                return false;
                            }
                        }

                        var availability = ee.availability.exceptions[dotw] ? ee.availability.exceptions[dotw] : ee.availability.default[dotw];
                        var isAvailable = (0, _shift.checkIsAvailable)(activeShift, availability, currentStoreCode);
                        return isAvailable;
                    });
                }

                // Mark available employees
                store.dispatch({
                    type: _types.SE_SET_AVAILABLE_EMPLOYEE_IDS,
                    payload: { employeeIds: new Set(employees.map(function (x) {
                            return x.id;
                        })) }
                });
                // console.timeEnd(
                //     'KNOCKOUT 1. EE must be available during the entire shift',
                // )

                // KNOCKOUT 2. EE must have skill > 0 for each activity in the shift
                // console.time('KNOCKOUT 2. EE must have skill > 0 for each activity in the shift')
                if (employees.length > 0) {
                    employees = employees.filter(function (x) {
                        return activeShift.blocks.every(function (block) {
                            return block.activityId <= 1 ? true : x.skillsByActivity[block.activityId] && x.skillsByActivity[block.activityId] > 0;
                        });
                        return true;
                    });
                }

                store.dispatch({
                    type: _types.SE_SET_SKILLED_EMPLOYEE_IDS,
                    payload: { employeeIds: new Set(employees.map(function (x) {
                            return x.id;
                        })) }
                });
                // console.timeEnd('KNOCKOUT 2. EE must have skill > 0 for each activity in the shift')

                // KNOCKOUT 3. EE must not have more than maximumShifts
                // console.time('KNOCKOUT 3. EE must not have more than maximumShifts')
                if (employees.length > 0) {
                    employees = employees.filter(function (x) {
                        var eeAssignedShifts = Object.keys(shifts).map(function (y) {
                            var shift = shifts[y];
                            if (shift.meta.employeeId === x.id) {
                                return shift;
                            }
                        }).filter(function (y) {
                            return y !== undefined;
                        })
                        // .filter(x => !moment(x.meta.date).isSame(moment(activeShift.meta.date), 'day'));
                        .filter(function (x) {
                            return !x.meta.date.isSame(activeShift.meta.date, 'day');
                        });
                        var maximumShifts = x.maximumShifts || DEFAULT_MAX_SHIFTS;
                        return eeAssignedShifts.length < maximumShifts;
                    });
                }
                // console.timeEnd('KNOCKOUT 3. EE must not have more than maximumShifts')

                // KNOCKOUT 4. EE's hours scheduled + shift hours must be less than EE's max weekly hours
                // console.time('KNOCKOUT 4. EE\'s hours scheduled + shift hours must be less than EE\'s max weekly hours')
                // ***** NOT applicable when not in smart assign mode *****
                if (smartAssignSelect && employees.length > 0) {
                    employees = employees.filter(function (x) {
                        var eeAssignedShifts = Object.keys(shifts).map(function (y) {
                            var shift = shifts[y];
                            if (shift.meta.employeeId === x.id) {
                                return shift;
                            }
                        }).filter(function (y) {
                            return y !== undefined;
                        }).filter(function (x) {
                            return !(0, _npmMoment.default)(x.meta.date).isSame((0, _npmMoment.default)(activeShift.meta.date), 'day');
                        });
                        var eeAssignedHours = eeAssignedShifts.reduce(function (o, i) {
                            i.blocks.forEach(function (block) {
                                var start = (0, _npmMoment.default)(block.start);
                                var end = (0, _npmMoment.default)(block.end);
                                var hours = end.diff(start, 'hours', true);
                                o += hours;
                            });
                            return o;
                        }, 0);
                        var maxHours = x.maximumHours || DEFAULT_MAX_HOURS;

                        return maxHours - eeAssignedHours - activeShiftLength >= 0;
                    });
                }

                // Mark assignable employees
                store.dispatch({
                    type: _types.SE_SET_ASSISNABLE_EMPLOYEE_IDS,
                    payload: { employeeIds: new Set(employees.map(function (x) {
                            return x.id;
                        })) }
                });
                // console.timeEnd('KNOCKOUT 4. EE\'s hours scheduled + shift hours must be less than EE\'s max weekly hours')

                // KNOCKOUT 5. EE must not already be working that day
                // console.time('KNOCKOUT 5. EE must not already be working that day')
                // Remove employees with a shift
                if (employees.length > 0) {
                    var employeesWithShift = shiftsArr.reduce(function (o, i) {
                        o.add(i.meta.employeeId);
                        return o;
                    }, new Set());
                    employees = employees.filter(function (x) {
                        return !employeesWithShift.has(x.id);
                    });
                }
                // console.timeEnd('KNOCKOUT 5. EE must not already be working that day')

                // KNOCKOUT 6. EE must have no other shifts 12 hours prior to beginning of shift or 12 hours after end of shift
                // console.time('KNOCKOUT 6. EE must have no other shifts 12 hours prior to beginning of shift or 12 hours after end of shift')
                // ***** NOT applicable when not in smart assign mode *****
                if (smartAssignSelect && employees.length > 0) {
                    var activeFirstBlock = activeShift.blocks[0];

                    var _activeShift$blocks$s = activeShift.blocks.slice(-1),
                        _activeShift$blocks$s2 = _slicedToArray(_activeShift$blocks$s, 1),
                        activeLastBlock = _activeShift$blocks$s2[0];

                    var windowStart = (0, _npmMoment.default)(activeFirstBlock.start).subtract(12, 'h');
                    var windowEnd = (0, _npmMoment.default)(activeLastBlock.end).add(12, 'h');
                    employees = employees.filter(function (x) {
                        var eeAssignedShifts = Object.keys(shifts).map(function (y) {
                            var shift = shifts[y];
                            if (shift.meta.employeeId === x.id) {
                                return shift;
                            }
                        }).filter(function (y) {
                            return y !== undefined;
                        }).filter(function (y) {
                            return !y.meta.date.isSame(activeShift.meta.date, 'day');
                        }).filter(function (y) {
                            var shiftFirstBlock = y.blocks[0];

                            var _y$blocks$slice = y.blocks.slice(-1),
                                _y$blocks$slice2 = _slicedToArray(_y$blocks$slice, 1),
                                shiftLastBlock = _y$blocks$slice2[0];

                            return shiftFirstBlock && shiftLastBlock;
                        }).filter(function (y) {
                            var shiftFirstBlock = y.blocks[0];

                            var _y$blocks$slice3 = y.blocks.slice(-1),
                                _y$blocks$slice4 = _slicedToArray(_y$blocks$slice3, 1),
                                shiftLastBlock = _y$blocks$slice4[0];

                            var shiftStart = shiftFirstBlock.start;
                            var shiftEnd = shiftLastBlock.end;
                            var isDuring = shiftStart.isBetween(windowStart, windowEnd) || shiftEnd.isBetween(windowStart, windowEnd, null, '[]');
                            return isDuring;
                        });
                        return eeAssignedShifts.length === 0;
                    });
                }
                // console.timeEnd('KNOCKOUT 6. EE must have no other shifts 12 hours prior to beginning of shift or 12 hours after end of shift')

                // RANKING SELECTION
                // ROUND 1. Pick EE with highest weighted-average skill (weighting based on activities in the shift)
                // console.time('ROUND 1. Pick EE with highest weighted-average skill (weighting based on activities in the shift)')
                if (employees.length > 1) {
                    employees = filterHighest(employees.map(function (x) {
                        var _loop = function _loop(_activityId) {
                            _activityId = parseInt(_activityId);
                            var weight = activityWeights[_activityId];
                            var skill = x.skills.find(function (y) {
                                return y.activity === _activityId;
                            });
                            var skillValue = skill ? skill.level : 0;
                            x.score += weight * skillValue;
                            activityId = _activityId;
                        };

                        for (var activityId in activityWeights) {
                            _loop(activityId);
                        }
                        return x;
                    }));
                }
                // console.timeEnd('ROUND 1. Pick EE with highest weighted-average skill (weighting based on activities in the shift)')

                // ROUND 2. Pick EE with highest number of hrs remaining to reach EE min hrs for the week  (i.e., [EE min hrs] - [EE assigned hrs] )
                // console.time('ROUND 2. Pick EE with highest number of hrs remaining to reach EE min hrs for the week  (i.e., [EE min hrs] - [EE assigned hrs] )')
                if (employees.length > 1) {
                    employees = filterHighest(employees.map(function (x) {
                        var eeAssignedShifts = Object.keys(shifts).map(function (y) {
                            var shift = shifts[y];
                            if (shift.meta.employeeId === x.id) {
                                return shift;
                            }
                        }).filter(function (y) {
                            return y !== undefined;
                        });
                        var eeAssignedHours = eeAssignedShifts.reduce(function (o, i) {
                            i.blocks.forEach(function (block) {
                                var start = (0, _npmMoment.default)(block.start);
                                var end = (0, _npmMoment.default)(block.end);
                                var hours = end.diff(start, 'hours', true);
                                o += hours;
                            });
                            return o;
                        }, 0);

                        x.score = x.minimumHours - eeAssignedHours;
                        return x;
                    }));
                }
                // console.timeEnd('ROUND 2. Pick EE with highest number of hrs remaining to reach EE min hrs for the week  (i.e., [EE min hrs] - [EE assigned hrs] )')

                // ROUND 3. EE tenure
                // console.time('ROUND 3. EE tenure')
                if (employees.length > 1) {
                    employees = filterHighest(employees.map(function (x) {
                        var startDate = (0, _npmMoment.default)(x.startDate);
                        var now = (0, _npmMoment.default)();
                        x.score = x.startDate ? now.diff(startDate, 'days') : 0;
                        return x;
                    }));
                }
                // console.timeEnd('ROUND 3. EE tenure')

                // ROUND 4. Random seeding
                // console.time('ROUND 4. Random seeding')
                var service = new _randomService.default();
                service.setSeed(activeShift.blocks[0].start.unix());

                var employee = service.choose(employees);
                // console.timeEnd('ROUND 4. Random seeding')

                store.dispatch({
                    type: _types.SE_SET_SMART_ASSIGN_EMPLOYEE,
                    payload: { employee: employee }
                });
            }
            // console.timeEnd('SE_SET_ACTIVE_SHIFT')
        }
    };
    afterListeners[_types.SE_SUNSET_SHIFT] = function (store, payload) {
        (0, _interactions.setStore)(store);

        var _store$getState31 = store.getState(),
            _store$getState31$sch = _store$getState31.scheduleEdit,
            shifts = _store$getState31$sch.shifts,
            scheduleId = _store$getState31$sch.scheduleId,
            weekEndDate = _store$getState31.cube.weekEndDate;

        store.dispatch({
            type: _types.SE_SET_ACTIVE_SHIFT,
            payload: { activeShift: null }
        });
        store.dispatch({
            type: _types.SE_SET_ASSISNABLE_EMPLOYEE_IDS,
            payload: { employees: null }
        });
        store.dispatch({
            type: _types.SE_SET_AVAILABLE_EMPLOYEE_IDS,
            payload: { employees: null }
        });
        store.dispatch({
            type: _types.SE_SET_SKILLED_EMPLOYEE_IDS,
            payload: { employees: null }
        });

        triggerChartDraw(store);
        validateShifts(store);
        triggerCacheSchedule(store, [weekEndDate.format('YYYY-MM-DD')], {
            scheduleId: scheduleId,
            shifts: store.getState().scheduleEdit.shifts
        });
    };
    afterListeners[_types.SE_SET_SMART_ASSIGN_EMPLOYEE] = function (store, payload) {
        // const {employee} = payload;
        // if (!employee) {
        //     const {scheduleEdit: {smartAssignIgnoredShifts, activeShift}} = store.getState();
        //     smartAssignIgnoredShifts.add(activeShift);
        //     store.dispatch({})
        // }
    };
    afterListeners[_types.SE_SHIFTS_UPDATED] = function (store, payload) {
        var weekEndDate = store.getState().cube.weekEndDate;

        validateShifts(store);
        calculateSAR(store);
        // store.dispatch({
        //     type: types.WS_SEND,
        //     payload: {
        //         header: 'do',
        //         body: {
        //             service: 'store',
        //             namespace: 'schedule',
        //             operation: 1,
        //             include_channel: 2,
        //             broadcast: true,
        //             response_type: 'SE_INTAKE',
        //             extra: [weekEndDate.format('YYYY-MM-DD')]
        //         }
        //     }
        // });
    };
    afterListeners[_types.SE_SWAP_SHIFTS] = function (store, payload) {
        // validateShifts(store)
        calculateSAR(store);
    };
    afterListeners[_types.SE_VALIDATE_SHIFTS] = function (store, payload) {
        validateShifts(store);
    };
    afterListeners[_types.SE_TIME_PLUS] = function (store, payload) {
        triggerChartDraw(store);
    };
    afterListeners[_types.SE_TIME_MINUS] = function (store, payload) {
        triggerChartDraw(store);
    };
    afterListeners[_types.SE_TIME_LEFT] = function (store, payload) {
        triggerChartDraw(store);
    };
    afterListeners[_types.SE_TIME_RIGHT] = function (store, payload) {
        triggerChartDraw(store);
    };
    afterListeners[_types.SE_CHART_DRAW] = function (store, payload) {
        calculateSAR(store);
    };

    exports.default = function (store) {
        return function (next) {
            return function (action) {
                var type = action.type,
                    payload = action.payload;

                // Before listeners

                if (beforeListeners[type]) {
                    var ret = beforeListeners[type](store, payload);
                    if (ret !== undefined) {
                        if (ret === false) {
                            return;
                        } else {
                            action.payload = ret;
                        }
                    }
                }

                next(action);

                // After listeners
                if (afterListeners[type]) {
                    afterListeners[type](store, payload);
                }

                // console.timeEnd(`Middleware ${type}`)
            };
        };
    };
});