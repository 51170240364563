define('matrix-frontend/helpers/moment', ['exports', 'npm:moment', 'matrix-frontend/utils/date-helper'], function (exports, _npmMoment, _dateHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.moment = moment;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    function moment(params /*, hash*/) {
        var _params = _slicedToArray(params, 3),
            mmt = _params[0],
            format = _params[1],
            _params$ = _params[2],
            manipulate = _params$ === undefined ? null : _params$;

        if (mmt) {
            if (!_npmMoment.default.isMoment(mmt)) {
                mmt = (0, _npmMoment.default)(mmt);
            }
        } else {
            mmt = (0, _npmMoment.default)();
        }

        if (!format) {
            return mmt.fromNow();
        }

        if (manipulate) {
            mmt = manipulate === 'weekEnd' ? (0, _dateHelper.getClientWeekEndDate)(mmt) : manipulate === 'weekStart' ? (0, _dateHelper.getClientWeekStartDate)(mmt) : mmt;
        }

        if (format === 'shortTime') {
            format = mmt.minutes() === 0 ? 'ha' : 'h:mma';
        } else if (format === 'calendar') {
            return mmt.calendar();
        }

        return mmt.format(format);
    }

    exports.default = Ember.Helper.helper(moment);
});