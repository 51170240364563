define('matrix-frontend/components/interactive-layer', ['exports', 'ember-leaflet/components/interactive-layer'], function (exports, _interactiveLayer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _interactiveLayer.default;
    }
  });
});