define('matrix-frontend/models/client', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        clientCode: _emberData.default.attr('string'),
        weekStartDay: _emberData.default.attr('number'),
        hierarchyNode: _emberData.default.belongsTo('hierarchy-node', {
            async: true,
            inverse: null
        }),

        code: function () {
            // eslint-disable-line ember/no-function-prototype-extensions
            return this.get('clientCode').toLowerCase();
        }.property('clientCode')
    });
});