define('matrix-frontend/components/store-summary-navigation-bar', ['exports', 'ember-react-components', 'react', 'npm:react-tooltip'], function (exports, _emberReactComponents, _react, _npmReactTooltip) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var comp = function comp(props) {
        var dataType = props.dataType,
            view = props.view,
            selectDataType = props.selectDataType,
            selectView = props.selectView;


        var dataTypes = [{
            name: 'Open Hours',
            text: 'Open',
            key: 'public'
        }, {
            name: 'Occupied Hours',
            text: 'Occupied',
            key: 'private'
        }];

        var viewTypes = [{
            // name: 'Numeric',
            text: 'Text',
            key: 'numeric'
        }, {
            // name: 'Visual',
            text: 'Visual',
            key: 'visual'
        }];

        return _react.default.createElement(
            _react.Fragment,
            null,
            _react.default.createElement(
                'div',
                { className: 'left report-select' },
                dataTypes.map(function (x, i) {
                    var classes = 'thin';
                    if (x.key == dataType) {
                        classes += ' selected';
                    }
                    return _react.default.createElement(
                        'button',
                        { key: i, className: classes, onClick: function onClick(_) {
                                return selectDataType(x.key);
                            }, 'data-for': 'basicNavTooltip', 'data-place': 'bottom', 'data-tip': x.name || x.text },
                        x.text
                    );
                })
            ),
            _react.default.createElement(
                'div',
                { className: 'right report-select' },
                viewTypes.map(function (x, i) {
                    var classes = 'thin';
                    if (x.key == view) {
                        classes += ' selected';
                    }
                    return _react.default.createElement(
                        'button',
                        { key: i, className: classes, onClick: function onClick(_) {
                                return selectView(x.key);
                            }, 'data-for': 'basicNavTooltip', 'data-place': 'bottom', 'data-tip': x.name || x.text },
                        x.text
                    );
                })
            ),
            _react.default.createElement(_npmReactTooltip.default, { id: 'basicNavTooltip', effect: 'solid' })
        );
    };

    exports.default = (0, _emberReactComponents.default)(comp);
});