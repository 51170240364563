define('matrix-frontend/pods/components/mx-text-input/component', ['exports', 'npm:numbro', 'matrix-frontend/utils/random', 'npm:jquery'], function (exports, _npmNumbro, _random, _npmJquery) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        format: '0.0',
        isEditing: true,
        editingOnRender: false,
        shouldSetFocus: true,
        shouldSetSelect: true,
        type: '',
        zeroFormat: '-',
        hasInput: false,
        startValue: null,

        current: Ember.computed(function () {
            return (0, _random.integer)(1, 999999);
        }),

        textValue: Ember.computed('value', function () {
            var textValue = this.get('value');
            var format = this.get('format');
            _npmNumbro.default.zeroFormat(this.get('zeroFormat'));

            if (this.get('type') === 'numeric') {
                textValue = _npmNumbro.default.unformat(textValue);

                if (isNaN(textValue) || textValue === null) {
                    textValue = 0;
                }
                format = format === '0,0' ? {
                    thousandSeparated: true,
                    mantissa: 0
                } : format;
                textValue = (0, _npmNumbro.default)(textValue).format(format);
            }
            return textValue;
        }),

        useValue: Ember.computed('value', function () {
            var value = this.get('value');
            return (0, _npmNumbro.default)(value).format({
                thousandSeparated: false,
                mantissa: 0
            });
        }),

        textValueNoFormat: Ember.computed('value', function () {
            var textValue = this.get('value');

            if (this.get('type') === 'numeric') {
                textValue = _npmNumbro.default.unformat(textValue);
                textValue = (0, _npmNumbro.default)(textValue).format('0.00');
                if (isNaN(textValue)) {
                    return 0;
                }
            }
            // console.log('textValueNoFormat', textValue)

            return textValue;
        }),

        nextTrigger: Ember.computed('next', function () {
            return (0, _npmJquery.default)('#mx-text-input-trigger-' + this.next);
        }),

        prevTrigger: Ember.computed('prev', function () {
            return (0, _npmJquery.default)('#mx-text-input-trigger-' + this.prev);
        }),

        goToNext: function goToNext() {
            this.nextTrigger.trigger('click');
        },
        goToPrev: function goToPrev() {
            this.prevTrigger.trigger('click');
        },
        didRender: function didRender() {
            if (this.isEditing === true && !this.hasInput) {
                this.setFocus();
                this.setSelect();
            }
            this.set('hasInput', this.isEditing);
        },
        didInsertElement: function didInsertElement() {
            this.set('isEditing', this.get('editingOnRender'));
        },
        setFocus: function setFocus() {
            if (this.shouldSetFocus) {
                var textInput = this.$('input[type=text]');
                textInput.focus();
            }
        },
        setSelect: function setSelect() {
            if (this.shouldSetSelect) {
                var textInput = this.$('input[type=text]');
                textInput.select();
            }
        },
        keyUp: function keyUp(e) {
            if (this.prevTrigger && e.originalEvent.keyCode === 37) {
                this.goToPrev();
            } else if (this.nextTrigger && e.originalEvent.keyCode === 39) {
                this.goToNext();
            }
        },
        keyDown: function keyDown(e) {
            var _this = this;

            if (this.prevTrigger && e.originalEvent.keyCode === 9 && e.originalEvent.shiftKey) {
                setTimeout(function () {
                    _this.goToPrev();
                }, 100);
            } else if (this.nextTrigger && e.originalEvent.keyCode === 9 && !e.originalEvent.shiftKey) {
                setTimeout(function () {
                    _this.goToNext();
                }, 100);
            }
        },


        actions: {
            setEdit: function setEdit(changeTo) {
                var isEditing = this.get('isEditing');

                if (isEditing) {
                    var value = this.get('textValueNoFormat');
                    this.set('value', value);
                }

                this.set('isEditing', isEditing ? false : true);

                if (this.onBlur && isEditing) {
                    this.onBlur(this.value, this.startValue);
                } else {
                    this.startValue = this.value;
                }
                // console.log(changeTo)
                // if (changeTo || changeTo === undefined) {
                //   if (this.get('isEditing') === false) {
                //     this.set('isEditing', true);
                //   }
                // } else {
                //   this.set('isEditing', false);
                // }
            }
        }
    });
});