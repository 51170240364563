define('matrix-frontend/pods/components/scheduler-sub-nav/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        currentPage: Ember.computed('page', function () {
            return this.get('page');
        }),

        menuItems: Ember.computed('label', function () {
            var label = this.get('label');

            var items = {
                employee: [{
                    id: 'employeeaddedit',
                    label: 'Add a new employee',
                    route: 'legacy.employeeaddedit',
                    model: false
                }, {
                    id: 'employeeroster',
                    label: 'Employee Roster',
                    route: 'legacy.employeeroster',
                    model: false
                }, {
                    id: 'employeeskills',
                    label: 'Skills',
                    route: 'legacy.employeeskills',
                    model: false
                }, {
                    id: 'employeeminmaxhours',
                    label: 'Min/Max Hours',
                    route: 'legacy.employeeminmaxhours',
                    model: false
                }, {
                    id: 'employeeavailability',
                    label: 'Availability',
                    route: 'legacy.employeeavailability',
                    model: false
                }, {
                    id: 'employeerehirelist',
                    label: 'Rehire Employee',
                    route: 'legacy.employeerehirelist'
                }],
                financial: [{
                    id: 'financialstorebudgets',
                    label: 'Store Budgets',
                    route: 'legacy.financialstorebudgets',
                    model: false
                }, {
                    id: 'tps',
                    label: 'TPS Report (Forecasts)',
                    route: 'reports.tps',
                    model: false
                }, {
                    id: 'financialprinttrackingsheet',
                    label: 'Print Tracking Sheet',
                    link: '/workforce/application/legacy/schedule/printTrackingSheet.jsf',
                    target: '_blank'
                }],
                schedule: [{
                    id: 'scheduleedit',
                    label: 'Edit Schedule',
                    route: 'legacy.scheduleedit',
                    model: false
                }, {
                    id: 'schedulegenerate',
                    label: 'Publish Schedule',
                    route: 'legacy.schedulegenerate',
                    model: false
                }, {
                    id: 'scheduleprint',
                    label: 'Print Schedule',
                    route: 'legacy.scheduleprint',
                    model: false
                }, {
                    id: 'scheduleemail',
                    label: 'Email Schedule',
                    route: 'legacy.scheduleemail',
                    model: false
                }],
                store: [{
                    id: 'storehours',
                    label: 'Hours of Operation',
                    route: 'legacy.storehours',
                    model: false
                }, {
                    id: 'attachments',
                    label: 'Attachments',
                    route: 'attachments.index',
                    model: false
                }, {
                    id: 'storecontactinfo',
                    label: 'Contact Info',
                    route: 'stores.edit',
                    model: true
                }]
            };

            return items[label];
        })
    });
});