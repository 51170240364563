define('matrix-frontend/actions/schedule-edit', ['exports', 'matrix-frontend/actions/_types', 'npm:moment', 'matrix-frontend/utils/backoff', 'matrix-frontend/utils/interactions', 'matrix-frontend/utils/date-helper'], function (exports, _types, _npmMoment, _backoff, _interactions, _dateHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.scheduleEditReload = undefined;


    // export const drawChart = () => dispatch => dispatch({type: types.SE_CHART_DRAW})

    // import {socketBackoff} from 'matrix-frontend/utils/backoff'
    var scheduleEditReload = exports.scheduleEditReload = function scheduleEditReload(store) {
        (0, _interactions.setStore)(store);

        // store.dispatch({
        //     type: types.CUBE_SET_PAGE_IN_PROGRESS,
        //     payload: {
        //         pageInProgress: 'scheduleEdit'
        //     }
        // });

        console.debug('~~scheduleEditReload~~');

        // const {weekEndDate, node} = store.getState().cube
        // const {clients} = store.getState().client
        var weekEndDate = store.getState().cube.weekEndDate;


        var displayStart = (0, _dateHelper.getClientWeekStartDate)((0, _npmMoment.default)(weekEndDate));
        var displayEnd = (0, _dateHelper.getClientWeekEndDate)((0, _npmMoment.default)(weekEndDate)).hours(24).minutes(0).seconds(0);

        // const currentClient = node && clients ? clients[node.clientCode] : null

        store.dispatch({
            type: _types.CUBE_GO_TO_WEEK
        });

        store.dispatch({
            type: _types.SE_TIME_DISPLAY,
            payload: {
                displayStart: displayStart,
                displayEnd: displayEnd
            }
        });

        store.dispatch({
            type: _types.SE_SET_PAINTBRUSH,
            payload: { activity: -1 }
        });

        store.dispatch({
            type: _types.SE_SMART_ASSIGN,
            payload: { mode: 0 }
        });

        (0, _backoff.generalBackoff)(function () {
            return store.getState().cube.node && store.getState().cube.node.nodeType !== 'store' && store.getState().cube.clientCode && store.getState().user.currentUser && store.getState().cube.weekEndDate && store.getState().websocket.currentSocket && store.getState().websocket.status == 1;
        }, function () {
            var _store$getState$cube = store.getState().cube,
                clientCode = _store$getState$cube.clientCode,
                node = _store$getState$cube.node,
                weekEndDate = _store$getState$cube.weekEndDate;

            var thisWeek = weekEndDate.format('YYYY-MM-DD');
            (0, _interactions.gatherReport)(clientCode, node.tag, 'actuallabor', 'day', thisWeek);
            (0, _interactions.gatherReport)(clientCode, node.tag, 'schedulehours', 'day', thisWeek);
            (0, _interactions.gatherReport)(clientCode, node.tag, 'taskhours', 'day', thisWeek);
        });

        (0, _backoff.generalBackoff)(function () {
            return store.getState().cube.node && store.getState().cube.node.nodeType === 'store' && store.getState().user.currentUser && store.getState().cube.weekEndDate;
            // && store.getState().websocket.status === 2;
        }, function () {
            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'store',
                    namespace: 'checkliststatus',
                    operation: 0,
                    include_channel: 2,
                    broadcast: false,
                    response_type: 'NODE_INTAKE',
                    extra: [store.getState().cube.weekEndDate.format('YYYY-MM-DD')]
                }
            }, 'retrieve checklist status: ' + store.getState().cube.node.clientCode + '.' + store.getState().cube.node.tag + '/' + store.getState().cube.weekEndDate.format('YYYY-MM-DD'));
            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'store',
                    namespace: 'employee',
                    operation: 0,
                    include_channel: 2,
                    broadcast: false,
                    extra: [store.getState().cube.weekEndDate.format('YYYY-MM-DD')],
                    params: {
                        active: true
                    },
                    response_type: 'NODE_INTAKE'
                }
            }, 'retrieve list of employees: ' + store.getState().cube.node.clientCode + '.' + store.getState().cube.node.tag + '/' + store.getState().cube.weekEndDate.format('YYYY-MM-DD'));
            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'store',
                    namespace: 'schedule',
                    operation: 0,
                    include_channel: 2,
                    broadcast: false,
                    response_type: 'SE_INTAKE',
                    extra: [store.getState().cube.weekEndDate.format('YYYY-MM-DD')]
                }
            }, 'retrieve schedule details');
            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'store',
                    namespace: 'hours',
                    operation: 0,
                    include_channel: 2,
                    broadcast: false,
                    response_type: 'NODE_INTAKE',
                    extra: [store.getState().cube.weekEndDate.format('YYYY-MM-DD')]
                }
            }, 'retrieve hours of operation: ' + store.getState().cube.node.clientCode + '.' + store.getState().cube.node.tag + '/' + store.getState().cube.weekEndDate.format('YYYY-MM-DD'));
            (0, _interactions.send)({
                header: 'do',
                body: {
                    service: 'store',
                    namespace: ['schedule', 'status'],
                    operation: 0,
                    include_channel: 2,
                    broadcast: false,
                    response_type: 'NODE_INTAKE',
                    extra: [store.getState().cube.weekEndDate.format('YYYY-MM-DD')]
                }
            }, 'retrieve schedule status');
        });
        // socketBackoff(
        //     () => {
        //         return store.getState().websocket.level === 'store';
        //     },
        //     () => {
        //         console.debug('~~DOING scheduleEditReload~~')
        //         send({
        //             header: 'do',
        //             body: {
        //                 service: 'store',
        //                 namespace: 'schedule',
        //                 operation: 0,
        //                 include_channel: 2,
        //                 broadcast: false,
        //                 response_type: 'SE_INTAKE',
        //                 extra: [weekEndDate.format('YYYY-MM-DD')]
        //             }
        //         }, 'register session on for websocket updates', true);
        //         // store.dispatch({type: types.SE_VALIDATE_SHIFTS});

        //         // store.dispatch({
        //         //     type: types.CUBE_SET_PAGE_IN_PROGRESS,
        //         //     payload: {
        //         //         pageInProgress: null
        //         //     }
        //         // });
        //     },
        //     'scheduleEditReload'
        // );
    };
    // import {doShiftValidation} from 'matrix-frontend/utils/validation'
});