define('matrix-frontend/components/schedule-chart', ['exports', 'react', 'ember-react-components', 'matrix-frontend/store'], function (exports, _react, _emberReactComponents, _store) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var SCALE = 30; // Minutes
    var SEGMENT = 15; // Minutes per segment
    var RATIO = SCALE / SEGMENT;
    var DEFAULT = {
        segments: [],
        scale: { max: 6, min: 0, height: 20 }
    };

    var generateData = function generateData(_ref) {
        var date = _ref.date,
            activities = _ref.activities,
            displayStart = _ref.displayStart,
            displayEnd = _ref.displayEnd,
            shifts = _ref.shifts,
            activitiesById = _ref.activitiesById,
            employeeFilters = _ref.employeeFilters;

        var segments = {};
        var optimalData = [];

        if (displayStart) {
            var currentMoment = displayStart.clone();
            var didBeginning = false;

            // NOTE: REGARDING WEEKLY CHART DISPLAY
            // To remove the weekly display of only 1 hour at a time,
            // remove the duration variable.
            var duration = date ? SEGMENT : SCALE;
            // const end = (date) ? displayEnd.clone().subtract(1, 'hour') : displayEnd;

            while (currentMoment.isBefore(displayEnd, 'minutes')) {
                var value = {
                    moment: currentMoment.clone(),
                    label: date && currentMoment.minute() % 4 === 0 || !date && currentMoment.minute() % SCALE === 0 && currentMoment.hour() % 24 === 0 && (currentMoment.day() !== 0 && didBeginning || currentMoment.day() === 0 && !didBeginning) ? {
                        time: currentMoment.format('h'),
                        ampm: currentMoment.format('a')
                    } : null,
                    activities: activities.reduce(function (a, v) {
                        if (!a[v.id]) {
                            a[v.id] = { unassigned: 0, assigned: 0 };
                        }
                        return a;
                    }, {})
                };

                segments.push(value);
                currentMoment.add(duration, 'minutes');

                if (!didBeginning) {
                    didBeginning = true;
                }
            }
            console.log('segments length', segments.length);

            var shiftData = Object.keys(shifts).map(function (x) {
                return shifts[x];
            }).filter(function (x) {
                return x.meta.date.isSame(date, 'day') || date === null;
            });

            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = shiftData[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var shift = _step.value;

                    var blocks = shift.blocks.filter(function (x) {
                        return employeeFilters && (!employeeFilters.skill || x.activityId === employeeFilters.skill);
                    });
                    var _iteratorNormalCompletion2 = true;
                    var _didIteratorError2 = false;
                    var _iteratorError2 = undefined;

                    try {
                        for (var _iterator2 = blocks[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                            var block = _step2.value;
                            var activityId = block.activityId;


                            var _currentMoment = block.start.clone();
                            while (_currentMoment.isBefore(block.end, 'minutes')) {
                                var assignIncrement = 0;
                                var unassignIncrement = 0;

                                var recurrences = date ? 1 : RATIO;
                                var currentHour = _currentMoment.clone().minutes(0);
                                var time = date ? _currentMoment.unix() : currentHour.unix();
                                if (!segments[time]) {
                                    segments[time] = {};
                                }
                                if (!segments[time][activityId]) {
                                    segments[time][activityId] = {
                                        unassigned: 0,
                                        assigned: 0
                                    };
                                }

                                var currentActivity = activitiesById[activityId];
                                if (!currentActivity) {
                                    console.error('could not find activity ' + activityId, activities);
                                }
                                if (currentActivity.shouldDisplay) {
                                    if (shift.meta.employeeId) {
                                        assignIncrement += 1 / recurrences;
                                    } else {
                                        unassignIncrement += 1 / recurrences;
                                    }
                                }
                                _currentMoment.add(15, 'minutes');
                                segments[time][activityId].assigned += assignIncrement;
                                segments[time][activityId].unassigned += unassignIncrement;
                                console.log(segments[time][activityId].assigned, segments[time][activityId].unassigned);
                            }
                        }
                    } catch (err) {
                        _didIteratorError2 = true;
                        _iteratorError2 = err;
                    } finally {
                        try {
                            if (!_iteratorNormalCompletion2 && _iterator2.return) {
                                _iterator2.return();
                            }
                        } finally {
                            if (_didIteratorError2) {
                                throw _iteratorError2;
                            }
                        }
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }
        }

        // console.log('segments length', segments.length)

        return [segments, []];
    };

    var makeChartData = function makeChartData(_ref2) {
        var activities = _ref2.activities,
            displayStart = _ref2.displayStart,
            displayEnd = _ref2.displayEnd,
            date = _ref2.date,
            shifts = _ref2.shifts,
            activitiesById = _ref2.activitiesById,
            employeeFilters = _ref2.employeeFilters;

        console.log('!!!makeChartData!!!');
        var segments = {};

        if (!activities || activities.length === 0) {
            return _extends({}, DEFAULT);
        }

        var _generateData = generateData({
            date: date,
            activities: activities,
            displayStart: displayStart,
            displayEnd: displayEnd,
            shifts: shifts,
            activitiesById: activitiesById,
            employeeFilters: employeeFilters
        }),
            _generateData2 = _slicedToArray(_generateData, 2),
            data = _generateData2[0],
            optimalData = _generateData2[1];

        var scale = {};

        var optimalPoints = optimalData.map(function (x) {
            return x.index + ',' + x.value;
        }).join(' ');

        var chartData = {
            segments: data,
            optimal: optimalPoints,
            scale: scale
        };

        return chartData;
    };

    var comp = function comp(props) {
        var hours = props.hours,
            currentStore = props.currentStore,
            activities = props.activities,
            activitiesById = props.activitiesById;

        var _store$getState = _store.store.getState(),
            date = _store$getState.cube.date,
            _store$getState$sched = _store$getState.scheduleEdit,
            displayStart = _store$getState$sched.displayStart,
            displayEnd = _store$getState$sched.displayEnd,
            shifts = _store$getState$sched.shifts,
            employeeFilters = _store$getState$sched.employeeFilters;

        console.log('employeeFilters', employeeFilters);

        var _useState = (0, _react.useState)(_extends({}, DEFAULT)),
            _useState2 = _slicedToArray(_useState, 2),
            chartData = _useState2[0],
            setChartData = _useState2[1];

        // Setup chartData
        (0, _react.useEffect)(function (_) {
            if (activities && displayStart && displayEnd && employeeFilters) {
                var cd = makeChartData(_extends({}, props, {
                    date: date,
                    displayStart: displayStart,
                    displayEnd: displayEnd,
                    shifts: shifts,
                    employeeFilters: employeeFilters
                }));
                console.log('cd made\t', cd);
                setChartData(cd);
            }
        }, [hours, displayStart && displayEnd && activities && employeeFilters]);
        // } else {
        //     useEffect(_ => {null})
        // }
        console.log('chartData.segments', chartData.segments);

        return _react.default.createElement(
            'div',
            { className: 'f1 chart-area' },
            date ? _react.default.createElement(
                'span',
                null,
                'date'
            ) : _react.default.createElement(
                'span',
                null,
                'nodate'
            ),
            _react.default.createElement(
                'div',
                { className: 'chart-area-section chart' },
                _react.default.createElement(
                    'svg',
                    {
                        width: '100%',
                        viewBox: '0 0 ' + (chartData.segments ? chartData.segments.length : 0) + ' ' + chartData.scale.max,
                        preserveAspectRatio: 'none'
                    },
                    chartData.segments.map(function (segment) {
                        var display = segment.activities && segment.activities.length > 0 ? segment.activities.map(function (activity, index) {
                            return _react.default.createElement('rect', {
                                fill: activity.fill,
                                width: '2',
                                height: activity.assigned,
                                y: chartData.scale.max - activity.start - activity.assigned,
                                x: '{index}'
                            });
                        }) : '';
                        return display;
                    }),
                    chartData.optimal ? _react.default.createElement('polyline', {
                        points: chartData.optimal,
                        'stroke-width': '0.15',
                        fill: 'transparent',
                        stroke: '#222'
                    }) : ''
                ),
                _react.default.createElement('div', { className: 'chart-scale' })
            )
        );
    };

    exports.default = (0, _emberReactComponents.default)(comp);
});