define('matrix-frontend/controllers/application', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        loadingService: Ember.inject.service(),

        loading: Ember.computed('loadingService.loading', function () {
            return this.get('loadingService').get('loading');
        }),

        actions: {
            transInController: function transInController(routeName) {
                this.transitionToRoute(routeName);
            }
        }
    });
});