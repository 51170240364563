define('matrix-frontend/reducers/reports', ['exports', 'matrix-frontend/actions/_types', 'npm:moment'], function (exports, _types, _npmMoment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var initialState = {
        report: null,
        view: null,
        subview: null,
        timeColumn: null,
        // hierarchies: {},
        // weeklyData: {},
        hourlyData: {}
    };

    var reports = function reports(state, action) {
        var type = action.type,
            payload = action.payload;


        switch (type) {
            case _types.RP_SET_REPORT:
                {
                    var report = payload.report;

                    return _extends({}, state, {
                        report: report
                    });
                }
            case _types.RP_SET_VIEW:
                {
                    // const {view} = payload
                    return _extends({}, state, payload);
                }
            case _types.RP_SET_TIMECOLUMN:
                {
                    var timeColumn = payload.timeColumn;

                    return _extends({}, state, {
                        timeColumn: timeColumn
                    });
                }
            // case types.RP_INTAKE_HIERARCHY: {
            //     const {results, meta} = payload
            //     const hierarchy = results

            //     let hierarchies = {...state.hierarchies}

            //     const fullTag = `${meta.channel[0]}.${hierarchy.tag}`
            //     hierarchies[fullTag] = hierarchy

            //     return {
            //         ...state,
            //         hierarchies,
            //     }
            // }
            // case types.RP_INTAKE_DATA: {
            //     const {results, meta} = payload
            //     const {
            //         report,
            //         week_end: weekEnd,
            //         client_code: clientCode,
            //         tag,
            //         description,
            //     } = meta

            //     if(description) {
            //         clearPendingSocketRequest(description)
            //     }

            //     let weeklyData = {...state.weeklyData}
            //     let weekData = weeklyData[weekEnd] || {}
            //     let reportData = weekData[report] || {
            //         alreadyGathered: {},
            //         results: {},
            //     }

            //     // mark as gathered for this tag
            //     reportData.alreadyGathered[`${clientCode}.${tag}`] = true

            //     // load report results
            //     Object.keys(results).forEach(tag => {
            //         reportData.results[`${clientCode}.${tag}`] = results[tag]
            //     })

            //     weekData[report] = reportData
            //     weeklyData[weekEnd] = weekData

            //     console.debug(weeklyData)
            //     return {
            //         ...state,
            //         weeklyData,
            //     }
            // }
            case _types.RP_INTAKE_HOURLY:
                {
                    var results = payload.results,
                        meta = payload.meta;
                    var channel = meta.channel,
                        extra = meta.extra,
                        params = meta.params;


                    var fullTag = channel.join('.');
                    var weekEnd = params.week_end_date;
                    var reportType = extra.reportType;


                    var getDataKey = function getDataKey() {
                        switch (reportType) {
                            case 'salesforecast':
                            case 'salesscheduleforecast':
                            case 'salesactual':
                                return 'sales';
                            case 'salesatrisk':
                                return 'at_risk';
                            case 'actuallabor':
                            case 'optimallabor':
                                return 'labor';
                            default:
                                return null;
                        }
                    };
                    var dataKey = getDataKey();

                    var hourlyData = _extends({}, state.hourlyData);
                    var weekData = hourlyData[weekEnd] || {};
                    var reportData = weekData[reportType] || {};

                    var weekEndMmt = _npmMoment.default.utc(weekEnd);

                    if (results.data != null) {
                        // docw: day of client week (0-6 DAY-DAY)
                        // dotw: day of the week (0-6 Sun-Sat)
                        var byHour = Array(24 * 7).fill(null);
                        var hasData = false;
                        results.data.forEach(function (dayData) {
                            var dayMmt = _npmMoment.default.utc(dayData.date);
                            var dotw = dayMmt.diff(weekEndMmt, 'days') + 6;
                            Object.keys(dayData[dataKey]).forEach(function (hour) {
                                byHour[dotw * 24 + parseInt(hour)] = dayData[dataKey][hour];
                                hasData = true;
                            });
                        });
                        // const byHour = [...Array(7).keys()].map(docw => {
                        //     const dayData = results.data[docw][dataKey]
                        //     return [...Array(24).keys()].map(hour => dayData[hour] === undefined ? 0 : dayData[hour])
                        // }).reduce((agg, x) => [...agg, ...x], [])

                        reportData[fullTag] = hasData ? byHour : null;
                    } else {
                        reportData[fullTag] = null;
                    }

                    weekData[reportType] = reportData;
                    hourlyData[weekEnd] = weekData;

                    return _extends({}, state, {
                        hourlyData: hourlyData
                    });
                }

            default:
                {
                    return state || initialState;
                }
        }
    };

    exports.default = reports;
});