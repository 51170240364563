define('matrix-frontend/reducers/schedule-edit', ['exports', 'matrix-frontend/actions/_types', 'npm:short-uuid', 'npm:moment', 'matrix-frontend/utils/cleaners', 'matrix-frontend/store', 'matrix-frontend/constants', 'matrix-frontend/utils/date-helper'], function (exports, _types, _npmShortUuid, _npmMoment, _cleaners, _store, _constants, _dateHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    // import optimal from './_optimal';

    var REMOVE_ACTIVITY = -1;
    var BREAK_ACTIVITY = 1;
    // const HOURS_SPAN = 2

    var initialState = {
        activeShift: null,
        atRiskData: {},
        assignableEmployeeIds: [],
        availableEmployeeIds: [],
        skilledEmployeeIds: [],
        chartDraw: 0,
        currentBrush: {},
        currentEditor: [],
        currentViewers: [],
        currentVersion: 0,
        expandedRow: null,
        lastExpandedRow: null,
        displayStart: null,
        displayEnd: null,
        closedAllDay: false,
        editModeLoading: false,
        editMode: false,
        saveMode: false,
        shiftSwap: false,
        smartAssign: 0,
        smartAssignSelect: false,
        smartAssignEmployee: null,
        smartAssignIgnoredShifts: new Set(),
        isSaving: false,
        inProcess: false,
        inProgress: false,
        isCaching: false,
        savingText: '',
        employeeFlags: {},
        showEmployeeFlagsId: null,
        employeeFilters: {},
        noSchedule: null,
        showFake: false,
        showEmployeeFilter: false,

        shifts: [],
        shiftsByEmployee: {},
        removedShifts: [],
        optimal: [],

        // TEMP to be removed
        activites: []
    };

    var getEmployeeRate = function getEmployeeRate(employeeId) {
        var rate = 0;
        var employees = _store.store.getState().employee;
        if (employees[employeeId]) {
            rate = employees[employeeId].rate;
        }
        return rate;
    };

    var makeShiftsByEmployee = function makeShiftsByEmployee(shifts) {
        var output = Object.keys(shifts).reduce(function (o, i) {
            var shift = shifts[i];
            if (shift.meta.employeeId) {
                if (!o[shift.meta.employeeId]) {
                    o[shift.meta.employeeId] = {};
                }

                o[shift.meta.employeeId][shift.meta.dotw] = shift;
            } else {
                o['unassigned'].push(shift);
            }
            return o;
        }, { unassigned: [] });
        return output;
    };

    var extractMoment = function extractMoment(date, id) {
        var offset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

        var regex = /employee-\d+-dotw-(\d)-time-(\d{1,2})\.(\d{2})/gm;
        var result = regex.exec(id);
        // const dotw = parseInt(date.format('d')) + 1
        var startTime = date.clone();

        if (result) {
            // const startDOTW = parseInt(result[1])
            // const diff = startDOTW - dotw
            // startTime.add(diff, 'day');
            startTime.hour(result[2]);
            startTime.minute(parseInt(result[3]) + offset);

            return startTime;
        }
        return false;
    };

    var schedule = function schedule(state, action) {
        var type = action.type,
            payload = action.payload;


        switch (type) {
            case _types.SE_RESET:
                {
                    return initialState;
                }

            case _types.ERROR:
                {
                    return _extends({}, state, {
                        isSaving: false,
                        inProcess: false,
                        // inProgress: false,
                        isCaching: false,
                        hasError: true
                    });
                }

            case _types.SE_COLLAPSE:
                {
                    var currentBrush = _extends({}, state.currentBrush, {
                        paintLength: 0
                    });
                    return _extends({}, state, {
                        lastExpandedRow: null,
                        expandedRow: null,
                        currentBrush: currentBrush
                    });
                }

            case _types.SE_CHART_DRAW:
                {
                    var chartDraw = state.chartDraw;

                    return _extends({}, state, {
                        chartDraw: chartDraw + 1
                    });
                }

            case _types.SE_SUNSET_SHIFT:
                {
                    var shifts = _extends({}, state.shifts);
                    var shift = payload.shift,
                        currentUser = payload.currentUser;

                    var s = _extends({}, shifts[shift.meta.id]);
                    var meta = _extends({}, s.meta, {
                        saved: false,
                        editedBy: currentUser.id,
                        editedByUsername: currentUser.username,
                        edited: (0, _npmMoment.default)(),
                        changedBy: currentUser.id,
                        changedByUsername: currentUser.username,
                        changed: (0, _npmMoment.default)(),
                        removed: true,
                        removedFrom: shift.meta.employeeId,
                        employeeId: null,
                        scheduleId: state.scheduleId
                    });
                    shifts[shift.meta.id] = _extends({}, s, {
                        meta: meta
                    });
                    var shiftsByEmployee = makeShiftsByEmployee(shifts);
                    return _extends({}, state, {
                        shifts: shifts,
                        shiftsByEmployee: shiftsByEmployee,
                        inProgress: true
                    });
                }
            case _types.SE_COMPILE_BRUSH:
                {
                    var date = payload.date,
                        _currentUser = payload.currentUser,
                        employeeRow = payload.employeeRow,
                        startMoment = payload.startMoment,
                        stopMoment = payload.stopMoment,
                        activityId = payload.activityId;

                    var _employeeRow$split = employeeRow.split('-'),
                        _employeeRow$split2 = _slicedToArray(_employeeRow$split, 2),
                        rowType = _employeeRow$split2[0],
                        id = _employeeRow$split2[1];

                    var _shifts = _extends({}, state.shifts);
                    var shiftsArr = Object.keys(_shifts).map(function (x) {
                        return _shifts[x];
                    });
                    var _shift = 0 === Number(id) ? null : rowType === 'unassigned' ? _shifts[id] : shiftsArr.find(function (x) {
                        return x.meta.employeeId === parseInt(id) && (0, _npmMoment.default)(x.meta.date).isSame(date, 'day');
                    });
                    var blocks = _shift ? [].concat(_toConsumableArray(_shift.blocks)) : [];
                    var _meta = _shift ? _extends({}, _shift.meta) : {};
                    var removedShifts = state.removedShifts;


                    var newBlock = {
                        activityId: activityId,
                        start: startMoment,
                        end: stopMoment,
                        justAdded: true
                    };

                    if (newBlock.end) {
                        blocks.push(newBlock);
                        blocks = blocks.sort(function (x, y) {
                            return (0, _npmMoment.default)(x.start).diff((0, _npmMoment.default)(y.start));
                        });

                        blocks = blocks.reduce(function (all, current) {
                            current = _extends({}, current);

                            var _all$slice = all.slice(-1),
                                _all$slice2 = _slicedToArray(_all$slice, 1),
                                previous = _all$slice2[0];

                            var toPush = [current];
                            current.end = (0, _npmMoment.default)(current.end);
                            current.start = (0, _npmMoment.default)(current.start);

                            current.end.seconds(0);
                            current.start.seconds(0);

                            if (previous) {
                                previous.end = (0, _npmMoment.default)(previous.end);
                                previous.start = (0, _npmMoment.default)(previous.start);
                                previous.end.seconds(0);
                                previous.start.seconds(0);
                                if (current.start <= previous.end) {
                                    var justAdded = current.justAdded || false;
                                    var exhauster = justAdded ? current : previous;
                                    var exhaustee = justAdded ? previous : current;
                                    var exhausted = exhauster.end >= exhaustee.end;

                                    if (current.activityId === previous.activityId) {
                                        if (justAdded === exhausted) {
                                            previous.end = current.end.clone();
                                        }
                                        toPush.pop();
                                    } else {
                                        if (justAdded && exhausted) {
                                            // The newly added shiftblock extends past
                                            // another shiftblock
                                            previous.end = current.start.clone();
                                        } else if (justAdded && !exhausted) {
                                            // The newly added shiftblock bifurcates
                                            // another shiftblock leaving an orphan
                                            // to become a new shiftblock
                                            var orphan = _extends({}, previous);

                                            previous.end = current.start.clone();
                                            orphan.start = current.end.clone();
                                            toPush.push(orphan);
                                        } else if (!justAdded && !exhausted) {
                                            // The newly created shiftblock shortens the
                                            // current shiftblock from the beginning
                                            current.start = previous.end.clone();
                                            current.start = previous.end.clone();
                                        } else if (!justAdded && exhausted) {
                                            // The newly created shiftblock engulfs
                                            // the entirety of the current shiftblock
                                            toPush.pop();
                                        }
                                    }
                                }
                            }

                            all = [].concat(_toConsumableArray(all), toPush).map(function (x) {
                                return _extends({}, x, {
                                    justAdded: false
                                });
                            });

                            return all;
                        }, []).filter(function (x) {
                            return x.end.diff(x.start) >= 15 * 60;
                        });

                        // TODO:
                        // Only add the edited information if there is an acitivty OTHER than shift extension
                        if (blocks.length > 0) {
                            if (_shift) {
                                var doShift = true;
                                _meta = _extends({}, _meta, {
                                    saved: false,
                                    editedBy: _currentUser.id,
                                    editedByUsername: _currentUser.username,
                                    edited: (0, _npmMoment.default)(),
                                    changedBy: _currentUser.id,
                                    changedByUsername: _currentUser.username,
                                    changed: (0, _npmMoment.default)()
                                });

                                blocks = blocks.filter(function (x) {
                                    return x.activityId !== REMOVE_ACTIVITY;
                                });
                                _shift = {
                                    meta: _meta,
                                    blocks: blocks
                                };
                                if (blocks.length === 0) {
                                    if (('' + _shift.meta.id).startsWith('mx')) {
                                        delete _shifts[_meta.id];
                                        doShift = false;
                                    }
                                    _shift.meta.removed = true;
                                    _shift.meta.removedFrom = _shift.meta.employeeId;
                                    _shift.meta.employeeId = null;
                                }

                                if (doShift) {
                                    _shifts[_meta.id] = _shift;
                                }
                            } else {
                                blocks = blocks.filter(function (x) {
                                    return x.activityId !== REMOVE_ACTIVITY;
                                });
                                if (blocks.length > 0) {
                                    var translator = (0, _npmShortUuid.default)(_npmShortUuid.default.constants.flickrBase58);
                                    var uuid = 'mx:' + translator.new();
                                    var dotw = (0, _dateHelper.getDayOfClientWeek)(date) + 1;
                                    var employeeId = parseInt(id);
                                    _shift = {
                                        meta: {
                                            id: uuid,
                                            date: date,
                                            dotw: dotw,
                                            employeeId: employeeId,
                                            saved: false,
                                            editedBy: _currentUser.id,
                                            editedByUsername: _currentUser.username,
                                            edited: (0, _npmMoment.default)(),
                                            changedBy: _currentUser.id,
                                            changedByUsername: _currentUser.username,
                                            changed: (0, _npmMoment.default)(),
                                            scheduleId: state.scheduleId,
                                            estimatedRate: getEmployeeRate(employeeId)
                                        },
                                        blocks: blocks
                                    };

                                    _shifts[uuid] = _shift;
                                }
                            }
                        } else {
                            removedShifts;
                        }
                    }

                    var _shiftsByEmployee = makeShiftsByEmployee(_shifts);
                    return _extends({}, state, {
                        shifts: _shifts,
                        removedShifts: removedShifts,
                        shiftsByEmployee: _shiftsByEmployee,
                        inProgress: true
                    });
                }

            case _types.SE_EXPAND_ROW:
                {
                    var rowId = action.rowId;

                    // const {expandedRow} = state
                    // const currentBrush = {
                    //     ...state.currentBrush,
                    // }

                    return _extends({}, state, {
                        expandedRow: rowId
                    });
                }

            case _types.SE_GO_TO_DAY:
                {
                    var displayStart = payload.displayStart,
                        displayEnd = payload.displayEnd,
                        closedAllDay = payload.closedAllDay;

                    return _extends({}, state, {
                        displayStart: displayStart,
                        displayEnd: displayEnd,
                        closedAllDay: closedAllDay,
                        smartAssign: 0
                    });
                }

            case _types.SE_INTAKE_PARTIAL:
                {
                    console.debug('SE_INTAKE_PARTIAL');
                    var results = payload.results;


                    if (results === null
                    //  ||
                    // (results.status &&
                    //     ScheduleStatus[results.status.toUpperCase()] ===
                    //         ScheduleStatus.HIDDEN)
                    ) {
                            return _extends({}, initialState, {
                                noSchedule: true,
                                displayStart: state.displayStart,
                                displayEnd: state.displayEnd
                            });
                        }

                    var _momentize = (0, _cleaners.momentize)(results),
                        scheduleId = _momentize.id,
                        currentEditor = _momentize.current_editor,
                        currentViewers = _momentize.current_viewers,
                        currentVersion = _momentize.current_version,
                        isSaving = _momentize.is_saving,
                        inProcess = _momentize.in_process,
                        isCaching = _momentize.is_caching,
                        inProgress = _momentize.in_progress,
                        notStarted = _momentize.not_started,
                        _shifts2 = _momentize.shifts,
                        published = _momentize.published,
                        status = _momentize.status,
                        _date = _momentize.date;

                    var scheduleDate = (0, _npmMoment.default)(_date);
                    var cutoff = (0, _npmMoment.default)().startOf('week');
                    var isLocked = scheduleDate.isBefore(cutoff);
                    var applyShifts = _shifts2 ? (0, _cleaners.clean_shifts)((0, _cleaners.clean_keys)(_shifts2)) : state.shifts;
                    var applyViewers = currentViewers ? currentViewers : state.currentViewers;
                    var applyEditor = currentEditor ? currentEditor : state.currentEditor;
                    var applyPublished = published !== null && published !== undefined ? published : state.published;
                    var applyCurrentVersion = currentVersion ? currentVersion : state.currentVersion;
                    var applyScheduleId = scheduleId ? scheduleId : state.scheduleId;
                    var applyInProgress = inProgress !== undefined ? inProgress : state.inProgress;
                    var applyStatus = status ? _constants.ScheduleStatus[status.toUpperCase()] : state.status;
                    var applyScheduleDate = scheduleDate ? scheduleDate : state.scheduleDate;
                    var applyNotStarted = notStarted !== null && notStarted !== undefined ? notStarted : state.notStarted;
                    var applyIsLocked = isLocked !== null && isLocked !== undefined ? isLocked : state.isLocked;
                    var applyIsSaving = isSaving !== null && isSaving !== undefined ? isSaving : state.isSaving;
                    var applyInProcess = inProcess !== null && inProcess !== undefined ? inProcess : state.inProcess;
                    var applyIsCaching = isCaching !== null && isCaching !== undefined ? isCaching : state.isCaching;

                    var _shiftsByEmployee2 = makeShiftsByEmployee(applyShifts);

                    return _extends({}, state, {
                        currentEditor: applyEditor,
                        currentViewers: applyViewers,
                        shifts: applyShifts,
                        published: applyPublished,
                        currentVersion: applyCurrentVersion,
                        scheduleId: applyScheduleId,
                        status: applyStatus,
                        notStarted: applyNotStarted,
                        scheduleDate: applyScheduleDate,
                        isLocked: applyIsLocked,
                        isSaving: applyIsSaving,
                        inProcess: applyInProcess,
                        isCaching: applyIsCaching,
                        inProgress: applyInProgress,
                        noSchedule: false,
                        shiftsByEmployee: _shiftsByEmployee2
                    });
                }

            case _types.SE_PAINTING:
                {
                    var mouseState = payload.mouseState;
                    var _date2 = payload.date;

                    var target = mouseState.target || mouseState.touches[0].target;
                    var paintMoment = extractMoment(_date2, target.id, 15);
                    // console.log('paintMoment', paintMoment, date)
                    var _activityId = mouseState.shiftKey ? REMOVE_ACTIVITY : mouseState.altKey ? BREAK_ACTIVITY : state.currentBrush.activityId;
                    var _currentBrush = _extends({}, state.currentBrush, {
                        currentState: mouseState,
                        paintMoment: paintMoment,
                        stopMoment: null,
                        paintLength: paintMoment.diff(state.currentBrush.startMoment, 'hours', true),
                        activityId: _activityId
                    });

                    return _extends({}, state, {
                        currentBrush: _currentBrush
                    });
                }

            case _types.SE_SET_ACTIVE_SHIFT:
                {
                    var activeShift = payload.activeShift;


                    return _extends({}, state, {
                        shiftSwap: false,
                        activeShift: activeShift
                    });
                }

            case _types.SE_SET_ASSISNABLE_EMPLOYEE_IDS:
                {
                    var employeeIds = payload.employeeIds;


                    return _extends({}, state, {
                        assignableEmployeeIds: employeeIds
                    });
                }

            case _types.SE_SET_AVAILABLE_EMPLOYEE_IDS:
                {
                    var _employeeIds = payload.employeeIds;


                    return _extends({}, state, {
                        availableEmployeeIds: _employeeIds
                    });
                }

            case _types.SE_SET_SKILLED_EMPLOYEE_IDS:
                {
                    var _employeeIds2 = payload.employeeIds;


                    return _extends({}, state, {
                        skilledEmployeeIds: _employeeIds2
                    });
                }

            case _types.SE_SET_PAINTBRUSH:
                {
                    var _activityId2 = payload.activityId;

                    var _currentBrush2 = _extends({}, state.currentBrush, {
                        activityId: _activityId2,
                        activityHolder: _activityId2
                    });

                    return _extends({}, state, {
                        currentBrush: _currentBrush2
                    });
                }

            case _types.SE_SET_SHIFTS:
                {
                    var payloadShifts = payload.shifts;


                    // iterate through shifts and compare their scheduleId with the current one
                    var _shifts3 = {};

                    for (var i in payloadShifts) {
                        var _shift2 = payloadShifts[i];
                        // check shift schedule consistency
                        if (_shift2.meta.scheduleId && state.scheduleId) {
                            // there's a scheduleId in the shift
                            if (_shift2.meta.scheduleId === state.scheduleId) {
                                // the shift matches this schedule
                                _shifts3[i] = _shift2;
                            }
                        } else {
                            // can't determine if the shift is from this schedule, so keep it
                            _shifts3[i] = _shift2;
                        }
                    }

                    var _shiftsByEmployee3 = makeShiftsByEmployee(_shifts3);
                    return _extends({}, state, {
                        shifts: _shifts3,
                        shiftsByEmployee: _shiftsByEmployee3
                    });
                }

            case _types.SE_SET_SMART_ASSIGN_EMPLOYEE:
                {
                    var employee = payload.employee;


                    return _extends({}, state, {
                        smartAssignEmployee: employee
                    });
                }

            case _types.SE_SMART_ASSIGN_IGNORE:
                {
                    var smartAssignIgnoredShifts = state.smartAssignIgnoredShifts;
                    var _shift3 = payload.shift;

                    var ignoredShifts = new Set([].concat(_toConsumableArray(smartAssignIgnoredShifts)));
                    ignoredShifts.add(_shift3.meta.id);

                    return _extends({}, state, {
                        smartAssignIgnoredShifts: ignoredShifts
                    });
                }

            case _types.SE_SET_SAR:
                {
                    var atRiskData = payload.atRiskData;


                    return _extends({}, state, {
                        atRiskData: atRiskData
                    });
                }

            case _types.SE_SET_STARTLEFT:
                {
                    var _mouseState = payload.mouseState;

                    var parentPos = Ember.$(_mouseState.target).parent().position();
                    var segmentPos = Ember.$(_mouseState.target).position();

                    return _extends({}, state, {
                        startLeft: parentPos.left + segmentPos.left
                    });
                }

            case _types.SE_SET_STARTTOP:
                {
                    var _mouseState2 = payload.mouseState;

                    var _parentPos = Ember.$(_mouseState2.target).parent().position();

                    return _extends({}, state, {
                        startTop: _parentPos.top
                    });
                }

            case _types.SE_SHIFTS_UPDATED:
                {
                    var _results = payload.results;


                    var _shifts4 = (0, _cleaners.clean_shifts)((0, _cleaners.clean_keys)(_results.reduce(function (o, i) {
                        o[i.meta.id] = _extends({}, i, {
                            errors: []
                        });
                        return (0, _cleaners.momentize)(o);
                    }, {})));

                    var _shiftsByEmployee4 = makeShiftsByEmployee(_shifts4);

                    return _extends({}, state, {
                        isSaving: false,
                        shifts: _shifts4,
                        shiftsByEmployee: _shiftsByEmployee4
                    });
                }

            case _types.SE_FLASH_SHIFTS:
                {
                    var _shifts5 = state.shifts;
                    var _currentUser2 = payload.currentUser;

                    Object.keys(_shifts5).forEach(function (key) {
                        var shift = _extends({}, _shifts5[key]);
                        shift.meta.employeeId = null;
                        shift.meta.editedBy = _currentUser2.id;
                        shift.meta.editedByUsername = _currentUser2.username;
                        shift.meta.edited = (0, _npmMoment.default)();
                        shift.meta.changedBy = _currentUser2.id;
                        shift.meta.changedByUsername = _currentUser2.username;
                        shift.meta.changed = (0, _npmMoment.default)();
                        shift.meta.saved = false;
                        shift.meta.scheduleId = state.scheduleId;
                    });
                    var _shiftsByEmployee5 = makeShiftsByEmployee(_shifts5);
                    return _extends({}, state, {
                        shifts: _shifts5,
                        shiftsByEmployee: _shiftsByEmployee5
                    });
                }

            case _types.SE_SMART_ASSIGN:
                {
                    var smartAssignSelect = state.smartAssignSelect;
                    var mode = payload.mode,
                        select = payload.select;

                    return _extends({}, state, {
                        smartAssign: mode,
                        smartAssignSelect: select ? select : smartAssignSelect
                    });
                }

            case _types.SE_START_PAINTING:
                {
                    var _mouseState3 = payload.mouseState,
                        _date3 = payload.date;

                    var _target = _mouseState3.target || _mouseState3.touches[0].target;
                    var _startMoment = extractMoment(_date3, _target.id);
                    // console.log(target)

                    var _currentBrush3 = _extends({}, state.currentBrush, {
                        activityId: state.currentBrush.activityHolder,
                        startState: _extends({}, _mouseState3),
                        currentState: _extends({}, _mouseState3),
                        startMoment: _startMoment,
                        stopMoment: null
                        // paintMoment: null
                    });

                    return _extends({}, state, {
                        currentBrush: _currentBrush3
                    });
                }

            case _types.SE_STOP_PAINTING:
                {
                    var _mouseState4 = payload.mouseState,
                        _date4 = payload.date;


                    var _stopMoment = extractMoment(_date4, _mouseState4.target.id, 15);

                    var _currentBrush4 = _extends({}, state.currentBrush, {
                        currentState: {},
                        stopMoment: _stopMoment,
                        paintMoment: null
                    });

                    return _extends({}, state, {
                        currentBrush: _currentBrush4
                    });
                }

            case _types.SE_SWAP_SHIFTS:
                {
                    var fromRecordState = payload.fromRecord,
                        toRecordState = payload.toRecord,
                        _employee = payload.employee,
                        _currentUser3 = payload.currentUser;

                    var fromRecord = _extends({}, fromRecordState);
                    var toRecord = _extends({}, toRecordState);

                    var fromSaved = toRecord && toRecord.meta ? fromRecord.meta.id === toRecord.meta.id ? fromRecord.meta.saved : !fromRecord.meta.employeeId && !toRecord.meta.employeeId : !toRecord.meta && !fromRecord.meta.employeeId && !_employee ? fromRecord.meta.saved : false;

                    var _shifts6 = _extends({}, state.shifts, _defineProperty({}, fromRecord.meta.id, _extends({}, state.shifts[fromRecord.meta.id], {
                        meta: _extends({}, state.shifts[fromRecord.meta.id].meta, {
                            employeeId: _employee ? _employee.id : null,
                            saved: fromSaved,
                            changedBy: _currentUser3.id,
                            changedByUsername: _currentUser3.username,
                            changed: (0, _npmMoment.default)()
                            // editedBy: currentUser.id,
                            // editedByUsername: currentUser.username,
                            // edited: new Date(),
                        })
                    })));
                    var _shiftsByEmployee6 = makeShiftsByEmployee(_shifts6);

                    var output = _extends({}, state, {
                        shiftSwap: true,
                        inProgress: true,
                        shifts: _shifts6,
                        shiftsByEmployee: _shiftsByEmployee6
                    });

                    if (toRecord && toRecord.meta) {
                        var areSame = fromRecord.meta.id === toRecord.meta.id;
                        // console.log('areSame?', areSame)
                        var toSaved = areSame ? toRecord.meta.saved : !fromRecord.meta.employeeId && !toRecord.meta.employeeId;
                        return _extends({}, output, {
                            shifts: _extends({}, output.shifts, _defineProperty({}, toRecord.meta.id, _extends({}, output.shifts[toRecord.meta.id], {
                                meta: _extends({}, output.shifts[toRecord.meta.id].meta, {
                                    employeeId: fromRecord.meta.employeeId,
                                    saved: toSaved,
                                    changedBy: _currentUser3.id,
                                    changedByUsername: _currentUser3.username,
                                    changed: (0, _npmMoment.default)()
                                    // editedBy: currentUser.id,
                                    // editedByUsername: currentUser.username,
                                    // edited: new Date(),
                                })
                            })))
                        });
                    }

                    return output;
                }

            case _types.SE_TIME_LEFT:
                {
                    var _displayStart = state.displayStart,
                        _displayEnd = state.displayEnd;

                    var currentDiff = _displayEnd.diff(_displayStart, 'hours');
                    var moveStep = Math.min(4, currentDiff - 1);
                    var moveBy = _displayStart.hours() - moveStep < 0 ? _displayStart.hours() : moveStep;

                    var newDisplayStart = _displayStart.clone().subtract(moveBy, 'hours');
                    var newDisplayEnd = _displayEnd.clone().subtract(moveBy, 'hours');

                    return _extends({}, state, {
                        displayStart: newDisplayStart,
                        displayEnd: newDisplayEnd
                    });
                }

            case _types.SE_TIME_RIGHT:
                {
                    var _displayStart2 = state.displayStart,
                        _displayEnd2 = state.displayEnd;

                    var _currentDiff = _displayEnd2.diff(_displayStart2, 'hours');
                    var _moveStep = Math.min(4, _currentDiff - 1);
                    var _moveBy = _displayEnd2.hours() + _moveStep > 24 ? 24 - _displayEnd2.hours() : _displayEnd2.hours() === 0 ? 0 : _moveStep;

                    var _newDisplayStart = _displayStart2.clone().add(_moveBy, 'hours');
                    var _newDisplayEnd = _displayEnd2.clone().add(_moveBy, 'hours');

                    return _extends({}, state, {
                        displayStart: _newDisplayStart,
                        displayEnd: _newDisplayEnd
                    });
                }

            case _types.SE_TIME_PLUS:
                {
                    var zoomStep = 4;
                    var _displayStart3 = state.displayStart,
                        _displayEnd3 = state.displayEnd;

                    var _currentDiff2 = _displayEnd3.diff(_displayStart3, 'hours');
                    var targetDiff = Math.floor(_currentDiff2 / zoomStep);

                    var _newDisplayStart2 = _displayStart3.clone().add(targetDiff, 'hours');
                    var _newDisplayEnd2 = _displayEnd3.clone().subtract(targetDiff, 'hours');

                    return _extends({}, state, {
                        displayStart: _newDisplayStart2,
                        displayEnd: _newDisplayEnd2
                    });
                }

            case _types.SE_TIME_MINUS:
                {
                    var _zoomStep = 4;
                    var _displayStart4 = state.displayStart,
                        _displayEnd4 = state.displayEnd;

                    // const currentDiff = displayEnd.diff(displayStart, 'hours');
                    var _targetDiff = _zoomStep;
                    var leftMove = _displayStart4.hours() - _targetDiff < 0 ? _displayStart4.hours() : _targetDiff;
                    var rightMove = _displayEnd4.hours() + _targetDiff > 24 ? 24 - _displayEnd4.hours() : _displayEnd4.hours() === 0 ? 0 : _targetDiff;

                    var _newDisplayStart3 = _displayStart4.clone().subtract(leftMove, 'hours');
                    var _newDisplayEnd3 = _displayEnd4.clone().add(rightMove, 'hours');

                    return _extends({}, state, {
                        displayStart: _newDisplayStart3,
                        displayEnd: _newDisplayEnd3
                    });
                }

            case _types.SE_TIME_DISPLAY:
                {
                    var _displayStart5 = payload.displayStart,
                        _displayEnd5 = payload.displayEnd;

                    return _extends({}, state, {
                        displayStart: _displayStart5,
                        displayEnd: _displayEnd5
                    });
                }

            case _types.SE_TOGGLE_EDIT_MODE:
                {
                    var editModeLoading = state.editModeLoading;

                    var _ref = payload || {},
                        force = _ref.force;

                    var _mode = force !== undefined ? force : !editModeLoading;
                    return _extends({}, state, {
                        editModeLoading: _mode
                    });
                }

            case _types.SE_TOGGLE_EMPLOYEE_FILTER:
                {
                    var showEmployeeFilter = state.showEmployeeFilter;

                    var _ref2 = payload || {},
                        _force = _ref2.force;

                    var _mode2 = _force !== undefined ? _force : !showEmployeeFilter;
                    return _extends({}, state, {
                        showEmployeeFilter: _mode2
                    });
                }

            case _types.SE_TOGGLE_EDIT_MODE_COMPLETE:
                {
                    var editMode = state.editMode;

                    var _ref3 = payload || {},
                        _force2 = _ref3.force;

                    var browser = bowser.detect(window.navigator.userAgent);
                    var _mode3 = browser.mobile ? false : _force2 !== undefined ? _force2 : !editMode;
                    return _extends({}, state, {
                        editModeLoading: false,
                        editMode: _mode3
                    });
                }

            case _types.SE_SET_EMPLOYEE_FILTER:
                {
                    var employeeFilters = state.employeeFilters;
                    var _type = payload.type,
                        value = payload.value;

                    var newFilters = _extends({}, employeeFilters);
                    newFilters[_type] = value;
                    return _extends({}, state, {
                        employeeFilters: newFilters,
                        showEmployeeFilter: false
                    });
                }

            case _types.SE_TOGGLE_SAVE_MODE:
                {
                    var saveMode = state.saveMode;

                    var _ref4 = payload || {},
                        _force3 = _ref4.force;

                    var _mode4 = _force3 !== undefined ? _force3 : !saveMode;
                    return _extends({}, state, {
                        saveMode: _mode4
                    });
                }

            // case types.SE_SET: {
            //     if (payload.results) {
            //         return {
            //             ...state,
            //             ...payload.results,
            //         }
            //     } else {
            //         return {
            //             ...state,
            //             ...payload,
            //         }
            //     }
            // }

            case _types.SE_SET:
                {
                    return _extends({}, state, payload);
                }

            case _types.SE_SET_RESULTS:
                {
                    var _results2 = (0, _cleaners.clean_keys)(payload.results);
                    return _extends({}, state, _results2);
                }

            default:
                {
                    return state || initialState;
                }
        }
    };

    exports.default = schedule;
});