define('matrix-frontend/serializers/v2-user', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTSerializer.extend({
        modelNameFromPayloadKey: function modelNameFromPayloadKey() {
            return 'v2-user';
        },

        payloadKeyFromModelName: function payloadKeyFromModelName() {
            return 'user';
        },

        normalizeQueryRecordResponse: function normalizeQueryRecordResponse(store, primaryModelClass, payload, id, requestType) {
            payload.roleIds = payload.roles ? payload.roles.reduce(function (x, y) {
                x.push(y.id);
                return x;
            }, []).toString() : '';
            delete payload.roles;
            return this._super(store, primaryModelClass, { user: payload }, id, requestType);
        },
        normalizeSaveResponse: function normalizeSaveResponse(store, primaryModelClass, payload, id, requestType) {
            return this._super(store, primaryModelClass, { user: payload }, id, requestType);
        },


        serialize: function serialize(snapshot, options) {
            var json = {};
            var self = this;
            options = options || {};

            snapshot.eachAttribute(function (name) {
                json[self.serverAttributeName(name)] = snapshot.attr(name);
            });

            // snapshot.eachRelationship(function(name, relationship) {
            //     if (relationship.kind === 'hasMany') {
            //         json[serverHasManyName(name)] = snapshot.hasMany(name, { ids: true });
            //     }
            // });

            if (options.includeId) {
                json.ID_ = snapshot.id;
            }

            return json;
        },

        serverAttributeName: function serverAttributeName(attribute) {
            return attribute.underscore();
        }
    });
});