define('matrix-frontend/pods/components/progress-bar/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        isComplete: Ember.computed('numerator', 'denominator', function () {
            return this.get('isEqual') && !this.get('isEmpty');
        }),
        isEmpty: Ember.computed('numerator', 'denominator', function () {
            return this.get('denominator') === 0;
        }),
        isEqual: Ember.computed('numerator', 'denominator', function () {
            return this.get('numerator') === this.get('denominator');
        }),
        percent: Ember.computed('numerator', 'denominator', function () {
            if (this.get('isEqual') && this.get('isEmpty')) {
                return 0;
            }
            return this.get('numerator') / this.get('denominator') * 100;
        }),
        secondaryPercent: Ember.computed('secondary', 'denominator', function () {
            if (this.get('isEqual') && this.get('isEmpty')) {
                return 0;
            }
            return this.get('secondary') / this.get('denominator') * 100;
        }),
        tertiaryPercent: Ember.computed('tertiary', 'denominator', function () {
            if (this.get('isEqual') && this.get('isEmpty')) {
                return 0;
            }
            return this.get('tertiary') / this.get('denominator') * 100;
        })
    });
});