define('matrix-frontend/middleware/index', ['exports', 'matrix-frontend/actions/_types', 'matrix-frontend/middleware/cube', 'matrix-frontend/middleware/schedule-edit', 'matrix-frontend/middleware/websocket', 'matrix-frontend/middleware/employee-panel', 'matrix-frontend/middleware/employee', 'matrix-frontend/middleware/client', 'matrix-frontend/middleware/node', 'matrix-frontend/middleware/user', 'matrix-frontend/middleware/checklist', 'matrix-frontend/middleware/reports', 'matrix-frontend/services/notification-service', 'matrix-frontend/services/modal-service'], function (exports, _types, _cube, _scheduleEdit, _websocket, _employeePanel, _employee, _client, _node, _user, _checklist, _reports, _notificationService, _modalService) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var foobar = function foobar(store) {
        return function (next) {
            return function (action) {
                var type = action.type,
                    payload = action.payload;

                var notificationService = new _notificationService.default();
                var modalService = new _modalService.default();

                if (type === _types.FOOBAR) {
                    console.log('FOOBAR');
                } else if (type === _types.ERROR) {
                    if (payload && payload.notice) {
                        notificationService.error('Error: Please let us know if this persists.');
                    }
                } else if (type === _types.SUCCESS) {
                    modalService.forceClose();
                    notificationService.success();
                } else if (type === _types.SAVED) {
                    modalService.forceClose();
                    notificationService.success('Saved');
                }

                next(action);
            };
        };
    };

    exports.default = [foobar, _checklist.default, _client.default, _cube.default, _employeePanel.default, _employee.default, _node.default, _user.default, _reports.default, _scheduleEdit.default, _websocket.default];
});