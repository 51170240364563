define('matrix-frontend/pods/legacy/financialprinttrackingsheet/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        redux: Ember.inject.service(),

        model: function model(params, transition) {
            var model = this._super(params, transition);
            var redux = this.get('redux');
            var weekEndDate = redux.getState().cube.weekEndDate.format('YYYY-MM-DD');
            if (transition.queryParams.storeId) {
                var url = '/workforce/application/legacy/schedule/printTrackingSheet.jsf?storeId=' + transition.queryParams.storeId + '&date=' + weekEndDate;
                window.location.replace(url);
            } else {
                return model;
            }
        }
    });
});