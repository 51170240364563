define('matrix-frontend/reducers/self-serve', ['exports', 'matrix-frontend/actions/_types', 'matrix-frontend/utils/cleaners'], function (exports, _types, _cleaners) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var initialState = {
        claim: {
            ok: null
        }
    };

    var schedule = function schedule(state, action) {
        var type = action.type,
            payload = action.payload;


        switch (type) {
            case _types.SS_CLAIM_INTAKE:
                {
                    // const {claim} = payload
                    return _extends({}, state, {
                        claim: (0, _cleaners.clean_keys)(payload)
                    });
                }

            default:
                {
                    return state || initialState;
                }
        }
    };

    exports.default = schedule;
});