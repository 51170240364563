define('matrix-frontend/components/payroll-worksheet-navigation-bar', ['exports', 'ember-react-components', 'react', 'matrix-frontend/store', 'matrix-frontend/actions/_types'], function (exports, _emberReactComponents, _react, _store, _types) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var setOpt = function setOpt(payload) {
        return _store.store.dispatch({
            type: _types.PW_SET_OPT,
            payload: payload
        });
    };

    var selectTimeColumn = function selectTimeColumn(timeColumn) {
        return _store.store.dispatch({
            type: _types.PW_SET_VIEW,
            payload: {
                timeColumn: timeColumn
            }
        });
    };

    var viewEnabled = function viewEnabled(nodeType, view) {
        if (nodeType === 'store' && view === 'summary') {
            return false;
        }
        return true;
    };

    var comp = function comp(props) {
        var node = props.node,
            timeSelectorVisible = props.timeSelectorVisible,
            columns = props.columns,
            timeColumn = props.timeColumn,
            view = props.view,
            selectView = props.selectView,
            viewTypes = props.viewTypes,
            todayIndex = props.todayIndex;


        return _react.default.createElement(
            _react.Fragment,
            null,
            _react.default.createElement('div', { className: 'left' }),
            timeSelectorVisible ? _react.default.createElement(
                'div',
                { className: 'button-group no-margin day-buttons no-print' },
                columns.map(function (tc, i) {
                    var classes = 'tiny thin secondary';
                    if (timeColumn == tc.key) {
                        classes += ' selected';
                    }
                    return _react.default.createElement(
                        'button',
                        { key: i, className: classes, onClick: function onClick(_) {
                                return selectTimeColumn(tc.key);
                            } },
                        tc.shortText
                    );
                })
            ) : null,
            _react.default.createElement(
                'div',
                { className: 'right report-select' },
                viewTypes.map(function (viewType, i) {
                    var classes = 'thin';
                    if (viewType.id == view) {
                        classes += ' selected';
                    }
                    var disabled = !viewEnabled(node.nodeType, viewType.id);
                    if (disabled) {
                        classes += ' disabled2';
                    }
                    return _react.default.createElement(
                        'button',
                        { key: i, className: classes, onClick: function onClick(_) {
                                return selectView(viewType.id);
                            } },
                        viewType.text
                    );
                })
            )
        );
    };

    exports.default = (0, _emberReactComponents.default)(comp);
});