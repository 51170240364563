define('matrix-frontend/pods/components/site-navigation/component', ['exports', 'ember-redux', 'matrix-frontend/utils/format-helpers', 'npm:lodash', 'matrix-frontend/utils/state'], function (exports, _emberRedux, _formatHelpers, _npmLodash, _state) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var stateToComputed = function stateToComputed(state) {
        return {
            clientCode: state.cube.clientCode,
            node: state.cube.node,
            stores: state.node.stores,
            hierarchies: state.node.hierarchies,
            weekEndDate: state.cube.weekEndDate,
            currentUser: state.user.currentUser,
            clients: state.client.clients
        };
    };

    var dispatchToActions = function dispatchToActions(dispatch) {};

    var Comp = Ember.Component.extend({
        saveService: Ember.inject.service(),
        stateService: Ember.inject.service(),
        // redux: service(),
        leftListHidden: true,
        rightListHidden: true,
        navLeftShowSubMenu: false,
        navLeftSelectedCategory: '',
        navLeftCategoryForPage: '',
        navLeftSelectedPageId: '',
        pageAllowsStoreHierarchyNavUpArrow: false,
        swipeLeftAction: 'navigateToNextSibling',
        swipeRightAction: 'navigateToPreviousSibling',
        pageNameToDisplay: '',

        init: function init() {
            this._super();
            var path = window.location.pathname;
            var self = this;
            // this.get('menuItems').forEach(function(category) {
            //     if (category.items.length === 0) {
            //         if (path.search(category.id) > 0) {
            //             self.setPageNameToDisplay(category.displayName)
            //             self.set('navLeftCategoryForPage', category.displayName)
            //         }
            //     } else {
            //         category.items.forEach(function(listItem) {
            //             if (path.search(listItem.id) > 0) {
            //                 self.set(
            //                     'pageAllowsStoreHierarchyNavUpArrow',
            //                     listItem.allowStoreHierarchyNavUpArrow,
            //                 )
            //                 self.setPageNameToDisplay(listItem.displayName)
            //                 self.set('navLeftCategoryForPage', category.displayName)
            //             }
            //         })
            //     }
            // })
        },

        currentClient: Ember.computed('clientCode', 'clients', function () {
            var clientCode = this.get('clientCode');
            var clients = this.get('clients');
            // console.info("currentClient")
            // console.info(clientCode, clients)
            if (clients && clientCode) {
                return clients[clientCode];
            }
        }),

        setPageNameToDisplay: function setPageNameToDisplay(name) {
            var currentName = this.get('pageNameToDisplay');
            if (name !== currentName) {
                this.set('pageNameToDisplay', name);
            }
        },


        currentUnitIsStore: Ember.computed('node', function () {
            return this.get('node.nodeType') === 'store';
        }),

        hierarchyNode: Ember.computed('node', function () {
            if (this.get('node.nodeType') === 'store') {
                return this.get('businessUnit.parent');
            } else {
                return this.get('businessUnit');
            }
        }),
        selectedNodeId: Ember.computed('node', function () {
            return '' + (this.get('node.nodeType') === 'store' ? this.get('node.storeId') : this.get('node.nodeId'));
        }),
        selectedUnitTitle: Ember.computed('node', function () {
            if (this.get('node.nodeType') === 'store') {
                var type = this.get('node.nodeType');
                var name = _formatHelpers.default.fixAllCaps(this.get('node.name'));
                return this.get('node.code').toUpperCase() + ' ' + name;
            }
            return this.get('node.name');
        }),

        menuItems: Ember.computed('businessUnit', 'session.user', 'currentClient', 'currentUser.roles', function () {
            var items = [];

            // DO NOT COMMENT OUT THIS LINE
            // SOME BS WITH EMBER REQUIRES THAT THIS IS RUN
            // SO THAT THE VALUE UPDATES
            var currentClient = this.get('currentClient.roles');
            // console.info(this.get('currentClient'))
            // console.info("UPDATE")
            if (this.session) {
                var USE_NEW_PW = (0, _state.getClientOption)('use_new_pw', 'bool');
                if (USE_NEW_PW) {
                    items.push({
                        displayName: 'Checklist',
                        id: 'new-checklist',
                        route: 'new-checklist',
                        allowStoreHierarchyNavUpArrow: true,
                        dynamic: false,
                        storeSelectionRequired: false,
                        display: true,
                        items: []
                    });
                } else {
                    items.push({
                        displayName: 'Checklist',
                        id: 'checklist',
                        route: 'legacy.checklist',
                        allowStoreHierarchyNavUpArrow: true,
                        dynamic: false,
                        storeSelectionRequired: false,
                        display: true,
                        items: []
                    });
                }

                // console.info(this.get('currentClient'))
                // console.info(this.get('currentUser'))
                //const HAS_OLD_REPORTS = hasScope('reports:old.view')
                var HAS_NEW_REPORTS = (0, _state.hasScope)('reports:new.view');
                if (HAS_NEW_REPORTS) {
                    items.push({
                        displayName: 'Reports',
                        id: 'new-reports',
                        route: 'new-reports',
                        allowStoreHierarchyNavUpArrow: true,
                        dynamic: false,
                        storeSelectionRequired: false,
                        display: true,
                        items: []
                    });
                }
                /* if(HAS_OLD_REPORTS) {
                    items.push({
                       // displayName: HAS_NEW_REPORTS ?/*  'Legacy Reports' :  */ //'Reports',
                /*   id: 'reports',
                  route: 'legacy.placeholder',
                  storeSelectionRequired: false,
                  display: true,
                  items: [
                      {
                          id: 'sales-equation',
                          displayName: 'Sales Equation',
                          route: 'reports.show',
                          allowStoreHierarchyNavUpArrow: true,
                          dynamic: true,
                      },
                      {
                          id: 'sales-vs-forecast',
                          displayName: 'Sales vs Forecast',
                          route: 'reports.show',
                          allowStoreHierarchyNavUpArrow: true,
                          dynamic: true,
                      },
                      {
                          id: 'hours-vs-optimal',
                          displayName: 'Hours vs Optimal',
                          route: 'reports.show',
                          allowStoreHierarchyNavUpArrow: true,
                          dynamic: true,
                      },
                  ],
                }) */
                //  } */

                // items.push({
                //     displayName: 'Store',
                //     id: 'stores',
                //     route: 'legacy.placeholder',
                //     storeSelectionRequired: false,
                //     display: true,
                //     items: [
                //         {
                //             id: 'storehours',
                //             displayName: 'Hours of Operation',
                //             route: 'legacy.storehours',
                //             allowStoreHierarchyNavUpArrow: true,
                //             dynamic: false,
                //         },
                //         {
                //             id: 'edit',
                //             displayName: 'Contact Info',
                //             route: 'stores.edit',
                //             allowStoreHierarchyNavUpArrow: true,
                //             dynamic: false,
                //         },
                //     ],
                // })
                items.push({
                    displayName: 'Store',
                    id: 'edit',
                    route: 'stores.edit',
                    allowStoreHierarchyNavUpArrow: true,
                    dynamic: false,
                    storeSelectionRequired: false,
                    display: true,
                    items: []
                });
                items.push({
                    displayName: 'Employees',
                    id: 'employees',
                    route: 'employees',
                    allowStoreHierarchyNavUpArrow: true,
                    dynamic: false,
                    storeSelectionRequired: false,
                    display: true,
                    hideFrom: 'legacy_employee',
                    items: []
                });
                items.push({
                    displayName: 'Employees',
                    id: '_employees',
                    route: 'legacy.placeholder',
                    storeSelectionRequired: false,
                    display: true,
                    requires: 'legacy_employee',
                    items: [{
                        id: 'employeeaddedit',
                        displayName: 'Add / Edit',
                        route: 'legacy.employeeaddedit',
                        allowStoreHierarchyNavUpArrow: true,
                        dynamic: false
                    }, {
                        id: 'employeeroster',
                        displayName: 'Roster',
                        route: 'legacy.employeeroster',
                        allowStoreHierarchyNavUpArrow: true,
                        dynamic: false
                    }, {
                        id: 'employeeskills',
                        displayName: 'Skills',
                        route: 'legacy.employeeskills',
                        allowStoreHierarchyNavUpArrow: true,
                        dynamic: false
                    }, {
                        id: 'employeeminmaxhours',
                        displayName: 'Min / Max Hours',
                        route: 'legacy.employeeminmaxhours',
                        allowStoreHierarchyNavUpArrow: true,
                        dynamic: false
                    }, {
                        id: 'employeeavailability',
                        displayName: 'Availability',
                        route: 'legacy.employeeavailability',
                        allowStoreHierarchyNavUpArrow: true,
                        dynamic: false
                    },
                    // {
                    //     id: 'employeeavailability',
                    //     displayName: 'Availability',
                    //     route: 'employees',
                    //     allowStoreHierarchyNavUpArrow: true,
                    //     dynamic: false,
                    //     view: 'availability'
                    // },
                    {
                        id: 'employeerehirelist',
                        displayName: 'Rehire',
                        route: 'legacy.employeerehirelist',
                        allowStoreHierarchyNavUpArrow: true,
                        dynamic: false
                    }, {
                        id: 'employeerehirestartdate',
                        displayName: 'Rehire',
                        route: 'legacy.employeerehirestartdate',
                        allowStoreHierarchyNavUpArrow: true,
                        dynamic: false
                    }]
                });
                items.push({
                    displayName: 'Schedule',
                    id: 'schedule',
                    route: 'schedule',
                    allowStoreHierarchyNavUpArrow: true,
                    dynamic: false,
                    storeSelectionRequired: false,
                    display: true,
                    items: []
                });

                if (USE_NEW_PW) {
                    items.push({
                        displayName: 'Payroll Worksheet',
                        id: 'new-payroll-worksheet',
                        route: 'new-payroll-worksheet',
                        allowStoreHierarchyNavUpArrow: true,
                        dynamic: false,
                        storeSelectionRequired: false,
                        display: true,
                        items: []
                    });
                } else {
                    items.push({
                        displayName: 'Payroll Worksheet',
                        id: 'tps',
                        route: 'reports.tps',
                        allowStoreHierarchyNavUpArrow: true,
                        dynamic: false,
                        storeSelectionRequired: false,
                        display: true,
                        items: []
                    });
                }

                items.push({
                    id: 'attachments',
                    displayName: 'Library',
                    route: 'attachments.index',
                    allowStoreHierarchyNavUpArrow: true,
                    dynamic: false,
                    storeSelectionRequired: false,
                    display: true,
                    items: []
                });

                // items.push({
                //     displayName: 'Financial',
                //     id: 'financial',
                //     route: 'legacy.placeholder',
                //     allowStoreHierarchyNavUpArrow: true,
                //     storeSelectionRequired: false,
                //     display: true,
                //     items: [
                //         {
                //             displayName: 'Payroll Worksheet',
                //             id: 'tps',
                //             route: 'reports.tps',
                //             allowStoreHierarchyNavUpArrow: true,
                //             dynamic: false,
                //         },
                //         {
                //             id: 'financialprinttrackingsheet',
                //             displayName: 'Print Tracking Sheet',
                //             route: 'legacy.financialprinttrackingsheet',
                //             allowStoreHierarchyNavUpArrow: true,
                //             storeSelectionRequired: true,
                //             dynamic: false,
                //         },
                //     ],
                // })

                // items.push({
                //   displayName: 'User Profile',
                //   id: 'user-profile',
                //   route: 'user-profile',
                //   allowStoreHierarchyNavUpArrow: true,
                //   dynamic: false,
                //   storeSelectionRequired: false,
                //     display: true,
                //   items: []
                // });
            }

            return items;
        }),

        itemsToExcludeFromNavMenu: ['employeerehirestartdate'], // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
        itemsToOpenInNewWindow: ['financialprinttrackingsheet'], // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
        currentPage: Ember.computed('page', 'menuItems', function () {
            var retVal = null;
            var path = window.location.pathname;
            var self = this;

            this.get('menuItems').forEach(function (category) {
                if (category.items.length === 0) {
                    if (path.search(category.id) > 0) {
                        retVal = category;
                        self.set('navLeftSelectedCategory', category.displayName);
                        self.set('navLeftSelectedPageId', category.id);
                        // self.setPageNameToDisplay(category.displayName)
                        self.set('navLeftCategoryForPage', category.id);
                        self.set('pageAllowsStoreHierarchyNavUpArrow', category.allowStoreHierarchyNavUpArrow);
                    }
                } else {
                    category.items.forEach(function (listItem) {
                        if (path.search(listItem.id) > 0) {
                            retVal = listItem;
                            self.set('navLeftSelectedCategory', category.displayName);
                            self.set('navLeftSelectedPageId', listItem.id);
                            self.set('pageAllowsStoreHierarchyNavUpArrow', listItem.allowStoreHierarchyNavUpArrow);
                            // self.setPageNameToDisplay(listItem.displayName);
                            self.set('navLeftCategoryForPage', category.id);
                        }
                    });
                }
            });

            return retVal;
        }),
        // setPageValues:  function(obj, value) {
        //   var self = Ember.page;
        //   self.set(obj, value);
        // },
        children: Ember.computed('businessUnit.{children,stores}', function () {
            var retVal = [];
            if (this.get('businessUnit.children.length')) {
                retVal = this.get('businessUnit.children').sortBy('name');
            } else if (this.get('businessUnit.stores.length')) {
                retVal = this.get('businessUnit.stores').sortBy('code');
            }
            return retVal;
        }),
        siblings: Ember.computed('businessUnit.parent{children,stores}', 'node', 'stores', 'hierarchies', function () {
            var node = this.get('node');
            var nodeType = node.nodeType === 'store' ? 'stores' : 'hierarchies';
            var nodes = this.get(nodeType);
            var nodeTag = node.clientCode + '.' + node.tag;
            var currentNode = node && nodes ? nodes[nodeTag] : null;

            if (currentNode && currentNode.parent) {
                var parentTag = node.clientCode + '.' + currentNode.parent.tag;
                var hierarchies = nodeType === 'stores' ? this.get('hierarchies') : nodes;
                var parentNode = hierarchies[parentTag];
                if (parentNode) {
                    var siblings = node.nodeType === 'store' ? parentNode.stores : parentNode.children;
                    var sortBy = node.nodeType === 'store' ? 'code' : 'name';
                    siblings = _npmLodash.default.sortBy(siblings, [sortBy]);
                    return siblings;
                }
            }
            return [];
        }),
        siblingUnits: Ember.computed('businessUnit.parent{children,stores}', 'node', 'stores', 'hierarchies', function () {
            var retVal = [];
            if (this.get('businessUnit.parent.children.length')) {
                retVal = this.get('businessUnit.parent.children').sortBy('name');
            } else if (this.get('businessUnit.parent.stores.length')) {
                retVal = this.get('businessUnit.parent.stores').sortBy('code');
            }

            return retVal;
        }),
        currentIndex: Ember.computed('node', 'siblings', function () {
            var node = this.get('node');
            var siblings = this.get('siblings');

            if (node && siblings) {
                return siblings.findIndex(function (x) {
                    return x.tag === node.tag;
                });
            }
            return 0;
        }),
        previousSibling: Ember.computed('siblings', function () {
            var siblings = this.get('siblings');
            var currentIndex = this.get('currentIndex');

            var previousSibling = null;
            if (currentIndex > 0) {
                previousSibling = siblings.objectAt(currentIndex - 1);
            }
            return previousSibling;
        }),
        nextSibling: Ember.computed('siblings', function () {
            var siblings = this.get('siblings');
            var currentIndex = this.get('currentIndex');

            var nextSibling = null;
            if (currentIndex < siblings.length - 1) {
                nextSibling = siblings.objectAt(currentIndex + 1);
            }
            return nextSibling;
        }),
        navUnits: Ember.computed('businessUnit', 'children', 'siblings', function () {
            var units = this.get('businessUnit.constructor.modelName') === 'store' ? this.get('siblingUnits') : this.get('children');
            var objs = units.map(this.createNavUnit);
            return objs.sortBy('name');
        }),
        previousNavUnit: Ember.computed('previousSibling', function () {
            var unit = this.get('previousSibling');
            return unit ? {
                id: unit.id,
                name: unit.name,
                type: unit.tag.startsWith('store') ? 'store' : 'hierarchy-node'
            } : null;
        }),
        nextNavUnit: Ember.computed('nextSibling', function () {
            var unit = this.get('nextSibling');
            return unit ? {
                id: unit.id,
                name: unit.name,
                type: unit.tag.startsWith('store') ? 'store' : 'hierarchy-node'
            } : null;
        }),
        parentNavUnit: Ember.computed('businessUnit', function () {
            return this.createNavUnit(this.get('businessUnit.parent'));
        }),
        createNavUnit: function createNavUnit(unit, extend) {
            if (unit) {
                // const children = unit.get('children');
                return {
                    id: unit.get('id'),
                    name: unit.get('constructor.modelName') === 'store' ? unit.get('code').toUpperCase() + ' ' + _formatHelpers.default.fixAllCaps(unit.get('name')) : unit.get('name'),
                    type: unit.get('constructor.modelName')
                };
            } else {
                return null;
            }
        },
        navigateToBusinessUnit: function navigateToBusinessUnit(unit) {
            var self = this;
            this.get('saveService').doAction(self.sendAction.bind(self), 'selectBusinessUnit', unit.id, unit.type);
            //     this.sendAction('selectBusinessUnit', unit.id, unit.type);
        },
        actions: {
            toggleListLeft: function toggleListLeft(pageName, category) {
                var navLeftListHidden = this.get('leftListHidden');
                this.set('leftListHidden', !navLeftListHidden);
                this.set('rightListHidden', true);
                if (pageName) {
                    this.set('pageNameToDisplay', pageName);
                }
                if (pageName !== undefined && category !== undefined) {
                    this.set('navLeftCategoryForPage', category);
                }

                if (navLeftListHidden) {
                    this.send('showHideNavMenuSubcategories', category !== undefined ? category : this.get('navLeftCategoryForPage'));
                }

                return false;
            },
            toggleListRight: function toggleListRight() {
                this.set('rightListHidden', !this.get('rightListHidden'));
                this.set('leftListHidden', true);
                return false;
            },
            closeMenu: function closeMenu() {
                this.set('rightListHidden', true);
            },

            selectBusinessUnit: function selectBusinessUnit(unit) {
                this.navigateToBusinessUnit(unit);
            },
            navigateToNextSibling: function navigateToNextSibling() {
                this.navigateToBusinessUnit(this.get('nextNavUnit'));
            },
            navigateToPreviousSibling: function navigateToPreviousSibling() {
                this.navigateToBusinessUnit(this.get('previousNavUnit'));
            },
            showHideNavMenuSubcategories: function showHideNavMenuSubcategories(category) {
                var selectedPageId = this.get('navLeftSelectedPageId');
                this.set('navLeftSelectedCategory', category);
                this.set('navLeftShowSubMenu', category !== selectedPageId);
                return false;
            }
        }
    });

    exports.default = (0, _emberRedux.connect)(stateToComputed, dispatchToActions)(Comp);
});