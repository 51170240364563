define('matrix-frontend/services/notification-service', ['exports', 'ember-cli-notifications/services/notification-messages-service'], function (exports, _notificationMessagesService) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _notificationMessagesService.default.extend({
        default: {
            // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
            autoClear: true,
            cssClasses: 'mx-notification'
        },

        defaultMessages: {
            // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
            saved: 'Saved.',
            success: 'Yay! Success.',
            error: 'Oops, something went wrong.',
            warning: 'Warning.',
            info: 'FYI.'
        },

        typeAliases: {
            // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
            saved: 'success'
        },

        init: function init() {
            this._super.apply(this, arguments);
        },
        mxNotify: function mxNotify(message, options, type) {
            message = message || this.defaultMessages[type];
            type = this.typeAliases[type] || type;
            var opts = Object.assign(this.default, options);
            var msg = Object.assign({
                message: message,
                type: type,
                cssClasses: 'mx-notification-' + type,
                position: 'top-right'
            }, opts);
            return this.addNotification(msg);
        },
        info: function info(message, options) {
            return this.mxNotify(message, options, 'info');
        },
        warning: function warning(message, options) {
            return this.mxNotify(message, options, 'warning');
        },
        error: function error(message, options) {
            return this.mxNotify(message, options, 'error');
        },
        success: function success(message, options) {
            return this.mxNotify(message, options, 'success');
        },
        saved: function saved(message, options) {
            return this.mxNotify(message, options, 'saved');
        },
        handleErrors: function handleErrors(payload) {
            var self = this;
            if (payload.errors) {
                payload.errors.forEach(function (msg) {
                    return self._handleErrorMsg(msg);
                });
            }
        },
        _handleErrorMsg: function _handleErrorMsg(msg) {
            var self = this;
            if (typeof msg === 'string' || msg instanceof String) {
                self.error(msg);
            } else {
                self.error(msg.title);
            }
        }
    });
});