define('matrix-frontend/helpers/numbro', ['exports', 'npm:numbro'], function (exports, _npmNumbro) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.numbro = numbro;

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    function numbro(params, hash) {
        var _params = _slicedToArray(params, 1),
            number = _params[0];

        var n = (0, _npmNumbro.default)(number);
        var currency = hash.currency ? hash.currency : false;

        if (isNaN(n)) {
            return 0;
        }

        var format = _extends({}, hash);
        if (format.currency !== undefined) {
            delete format.currency;
        }
        if (!format.forceAverage) {
            format.average = false;
        }
        return currency ? n.formatCurrency(format) : n.format(format);
    }

    exports.default = Ember.Helper.helper(numbro);
});